const IMG_SIZE = 150;

const styles = {
  container: {
    height: '100%', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
  img: {
    borderRadius: '25px',
    height: `${IMG_SIZE}px`,
    width: `${IMG_SIZE}px`
  },
  placeholder: { 
    height: `${IMG_SIZE + 50}px` 
  },
  dialogContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px'
  },
  imgContainer: {
    paddingRight: '20px'
  }
};

export default styles;