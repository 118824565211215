import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  dropContainer: {
    display: 'inline-block', 
    position: 'relative'
  },
  outerDrop: {
    border: 'dashed grey 4px',
    backgroundColor: 'rgba(255,255,255,.8)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0, 
    right: 0,
    zIndex: 9999
  },
  innerDrop: {
    position: 'absolute',
    top: '50%',
    right: 0,
    left: 0,
    textAlign: 'center',
    color: 'grey',
    fontSize: 36
  }
}));

export default useStyles;

