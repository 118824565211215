import palette from '../../styles/palette';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  indicator: {
    backgroundColor: palette.blueGreen
  },
  actionPlanComponent: {
    position: 'fixed',
    bottom: 0,
    right: 25,
    zIndex: 600
  }
}));

export default useStyles;
