import Network from './network';
import { getOrganizationId } from '../utils/auth.utils';

const ORGANIZATIONS = 'API/Organizations';
const USER = 'User';
const USERS = 'Users';
const INVITE = 'Invite';

function _getOrganizationUserUrl() {
  const organizationId = getOrganizationId();
  return `${ORGANIZATIONS}/${organizationId}/${USER}`;
}

function _getOrganizationUsersUrl() {
  const organizationId = getOrganizationId();
  return `${ORGANIZATIONS}/${organizationId}/${USERS}`;
}

export async function GetOrganization(userId, cancelSource) {
  let resp = await Network.Get(ORGANIZATIONS, { userId }, cancelSource);
  return resp.data;
}

export async function GetOrganizationUsers(cancelSource) {
  let resp = await Network.Get(_getOrganizationUsersUrl(), null, cancelSource);
  return resp.data.items;
}

export async function CreateOrganizationUser(user) {
  let resp = await Network.Post(_getOrganizationUserUrl(), user);
  return resp.data;
}

export async function RemoveOrganizationUser(userId) {
  let resp = await Network.Delete(_getOrganizationUserUrl() + '/' + userId );
  return resp.data;
}

export async function InviteUserToOrganization(organizationId, email, teamIds) {
  let resp = await Network.Post(`${ORGANIZATIONS}/${INVITE}`, { organizationId, email, teamIds });
  return resp.data;
}

export async function GetOrgActionItems(organizationId, cancelSource) {
  const url = `${ORGANIZATIONS}/${organizationId}/ActionItems`;
  let resp = await Network.Get(url, null, cancelSource);
  return resp.data.items;
}

export async function UpdateOrgUserAssignedTeams(orgId, userId, teams) {
  const url = `${ORGANIZATIONS}/${orgId}/User/${userId}/OrganizationUserTeams`;
  let resp = await Network.Put(url, teams);
  return resp.data;
}