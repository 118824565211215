import palette from '../../../styles/palette';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  pageContainer: {
    padding: '20px'
  },
  uploadForm: {
    padding: '20px 0'
  },
  transition: {
    transition: 'all .5s ease-in-out'
  },
  processingVideosText: {
    backgroundColor: palette.lightGray,
    fontStyle: 'italic',
    padding: '10px',
    flex: 1,
    margin: '10px'
  },
  noVideosText: {
    width: '25vw',
    padding: '25px'
  }
}));

export default useStyles;
