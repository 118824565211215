import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { EditorState, Modifier, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
import { draftjsToMd, mdToDraftjs } from 'draftjs-md-converter';

import 'draft-js/dist/Draft.css';
import 'draftail/dist/draftail.css';
import './editorStyles.css';

import { DraftailEditor } from 'draftail';

import useStyles from './styles';

export function createEditorState(text = '') {
  const rawData = mdToDraftjs(text);
  const contentState = convertFromRaw(rawData);
  return EditorState.createWithContent(contentState);
};

export function clearEditorState(editorState) {
  return EditorState.push(editorState, ContentState.createFromText(''));
}

export function loadMarkdown(editorState, newText) {
  const newContent = convertFromRaw(mdToDraftjs(newText));
  return EditorState.push(editorState, newContent);
}

export function appendTextToMarkdown(newText, editorState) {
  const currentContent = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();
  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    newText
  );
  return EditorState.push(editorState, newContent, 'insert-characters');
}

export function getPlainText(editorState) {
  if (!editorState) return ''; 
  return editorState.getCurrentContent().getPlainText();
}

const CustomTextEditor = forwardRef(({
  initialText = '',
  placeholder = null,
  editorState = null,
  editorHeight = 300,
  blockTypes = [],
  inlineStyles = [],
  onChange = () => {}
}, ref) => {

  const classes = useStyles({ height: editorHeight });

  const formatAndFireChange = useCallback((newEditorState) => {
    const plainText = getPlainText(newEditorState);
    const rawData = convertToRaw(newEditorState.getCurrentContent());
    const markdown = draftjsToMd(rawData);
    onChange(markdown, plainText, newEditorState);
  }, [onChange]);

  // initialize and change state if needed
  useEffect(() => {
    if (!editorState) formatAndFireChange(createEditorState(initialText));
  }, [editorState, initialText, formatAndFireChange]);

  useImperativeHandle(ref, () => ({
    replaceText: text => {
      const newState = appendTextToMarkdown(text, editorState);
      formatAndFireChange(newState);
    }
  }), [editorState, formatAndFireChange]);

  // the Draftail library requires that if we want to use the default toolbar
  // we do not pass in a prop, making this memo required if we ever want to 
  // get rid of it by passing in null
  const toolbarProps = useMemo(() => (
    blockTypes.length && inlineStyles.length ? {} : { topToolbar: null }
  ), [blockTypes, inlineStyles]);

  return (
    <div className={classes.container}>
      {
        editorState &&
        <DraftailEditor
          placeholder={placeholder}
          editorState={editorState}
          onChange={formatAndFireChange}
          blockTypes={blockTypes}
          inlineStyles={inlineStyles}
          { ...toolbarProps }
        />
      }
    </div>
  );
});

export default CustomTextEditor;