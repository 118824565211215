import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import logger from 'js-logger';
import axios from 'axios';


import { SetOrgActionItems } from '../../../../store/orgActionItems';
import { GetClientActivationsForOrg } from '../../../../network/clientActivationRequests';
import { GetOrgActionItems } from '../../../../network/organizationRequests';
import { ACTION_ITEMS } from '../../../../constants/organizations.constants';
import { addOrgActionTaken } from '../../../../store/orgActionsTaken';

const POLLING_FREQUENCY_MS = 30000;

function useOrgActionItems(organizationId) {
  const [actionItems, setActionItems] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState(null);
  let timeoutId = useRef(null);
  let prevActionItems = useRef([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchActionItems() {
      try {
        let [newActionItems, clientActivations] = await Promise.all([
          GetOrgActionItems(organizationId, cancelToken), 
          GetClientActivationsForOrg({ isActive: false }, cancelToken)
        ]);
        // pending clients aren't actually users 
        // will use their clientids instead so they can fit the general pattern
        let userIds = clientActivations.map(client => client.clientId);
        if (userIds.length > 0) {
          newActionItems.push({ action: ACTION_ITEMS.needsForms, userIds }); 
        }

        // if users are missing from the previous action items, 
        // it means the org took some action for the user
        const actionsTaken = prevActionItems.current.map(item => { 
          const newItem = newActionItems.filter(newItem => newItem.action === item.action);
          const userIds = newItem.length === 0
            ? item.userIds
            : item.userIds.filter(id => !newItem[0].userIds.includes(id));
          return userIds.length > 0
            ? { action: item.action, userIds }
            : null;
        });

        actionsTaken
          .filter(action => action != null)
          .forEach(action => action.userIds.forEach(userId => 
            dispatch(addOrgActionTaken(action.action, userId))));
        
        // need to set state so component can react to changes 
        // and ref so value is remembered between intervals
        prevActionItems.current = newActionItems;
        setActionItems(newActionItems);
        dispatch(SetOrgActionItems(newActionItems));
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        }
        logger.error(e);
        setError(e);
      }
      setInitialLoad(false);
      timeoutId.current = setTimeout(fetchActionItems, POLLING_FREQUENCY_MS);
    };
    fetchActionItems();

    return function() {
      timeoutId.current != null && clearTimeout(timeoutId.current);
      cancelToken.cancel();
    };
  }, [dispatch, organizationId]);

  return { actionItems, initialLoad, error };
};

export default useOrgActionItems;