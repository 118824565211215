export const TABLE_ROWS = [
  { title: 'IR', left: 'lowerQuarterInternalRotationLeftDegrees', right: 'lowerQuarterInternalRotationRightDegrees', rangeKey: 'lowerQuarterInternalRotation' },
  { title: 'ER', left: 'lowerQuarterExternalRotationLeftDegrees', right: 'lowerQuarterExternalRotationRightDegrees', rangeKey: 'lowerQuarterExternalRotation' },
  { title: 'T-Spine', left: 'tSpineRotationLeftDegrees', right: 'tSpineRotationRightDegrees', rangeKey: 'tSpineRotation' },
  { title: 'Scap', left: 'scapularRetractionLeftInches', right: 'scapularRetractionRightInches', rangeKey: 'scapularRetraction' },
  { title: 'Bend', left: 'sideBendLeftDegrees', right: 'sideBendRightDegrees', rangeKey: 'sideBend' }
];

export const SMALL_SCREEN_EDIT_ROWS = [
  { title: 'IR Left', key: 'lowerQuarterInternalRotationLeftDegrees' },
  { title: 'IR Right', key: 'lowerQuarterInternalRotationRightDegrees' },
  { title: 'ER Left', key: 'lowerQuarterExternalRotationLeftDegrees' },
  { title: 'ER Right', key: 'lowerQuarterExternalRotationRightDegrees' },
  { title: 'T-Spine Left', key: 'tSpineRotationLeftDegrees' },
  { title: 'T-Spine Right', key: 'tSpineRotationRightDegrees' },
  { title: 'Scap Left', key: 'scapularRetractionLeftInches' },
  { title: 'Scap Right', key: 'scapularRetractionRightInches' },
  { title: 'Bend Left', key: 'sideBendLeftDegrees' },
  { title: 'Bend Right', key: 'sideBendRightDegrees' }
];

export const CATEGORIES = {
  stiff: 'Stiff',
  tight: 'Tight',
  neutral: 'Neutral',
  loose: 'Loose',
  hypermobile: 'Hypermobile'
};

export const MOBILITY_COLORS = {
  [CATEGORIES.stiff]: '#0288d1',
  [CATEGORIES.tight]: '#80deea',
  [CATEGORIES.neutral]: '#e0e0e0',
  [CATEGORIES.loose]: '#ffd740 ',
  [CATEGORIES.hypermobile]: '#ff8f00'
};
