import palette from '../../styles/palette';

const styles = {
  lightOpacity: {
    opacity: 75
  },
  graphModal: {
    background: palette.white,
    padding: '20px',
    width: '75vw',
    height: '53.33vw',
    maxHeight: '90vh',
    maxWidth: '125vh',
    overflow: 'auto',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex'
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  circularProgress: {
    top: '50%',
    left: '50%',
    zIndex: 100,
    position: 'absolute',
    marginTop: '-23px',
    marginLeft: '-8px'
  },
  graphProgressContainer: {
    position: 'relative'
  },
  spinnerColor: {
    color: palette.coolGrey
  },
  noMotionsContainer: {
    position: 'relative', 
    width: '100%', 
    paddingTop: '56.25%'
  },
  noMotionsText: {
    position: 'absolute',
    backgroundColor: palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0, left: 0,
    bottom: 0, right: 0
  },
  closeButton: {
    position: 'absolute',
    right: '5%',
    marginTop: '6px',
    zIndex: 100
  }
};

export default styles;
