import React from 'react';
import {
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import startCase from 'lodash/startCase';
import keyBy from 'lodash/keyBy';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useStyles from './styles';
import { DRILL_VARIATIONS, LESSON_SEGMENT_TYPES } from '../templateEditor.constants';

function LessonSegmentsList({ 
  lessonSegments, 
  drills, 
  selectedLessonSegment, 
  onSegmentSelected,
  onItemsReordered,
  loading
}) {
  const classes = useStyles();
  const drillsById = keyBy(drills, 'id');

  const getPrimaryText = lessonSegment => {
    if (lessonSegment.type === LESSON_SEGMENT_TYPES.drill) {
      if (lessonSegment.drillId != null && drillsById[lessonSegment.drillId]) {
        return drillsById[lessonSegment.drillId].name;
      }
      return 'Drill';
    }
    return startCase(lessonSegment.type);
  };

  const getVariationText = lessonSegment => {
    return DRILL_VARIATIONS
      .filter(({ key }) => lessonSegment[key] != null)
      .map(({ key }) => `${startCase(key)}: ${startCase(lessonSegment[key])}`)
      .join('\n');
  };

  const onDragEnd = ({ source, destination }) => {
    if (destination == null || source.index === destination.index) {
      return;
    }

    onItemsReordered(source.index, destination.index);
  };

  return <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId='lesson-segments-droppable'>
      {(provided) => 
        <List 
          className={classes.list} 
          innerRef={provided.innerRef} 
          {...provided.droppableProps}
        >
          {!loading && lessonSegments?.map((lessonSegment, idx) => 
            <Draggable 
              draggableId={lessonSegment.id} 
              index={idx}
              key={lessonSegment.id}
            >
              {(provided) => 
                <ListItem 
                  selected={lessonSegment.id === selectedLessonSegment?.id}
                  onClick={() => onSegmentSelected(lessonSegment)}
                  {...provided.draggableProps}
                  innerRef={provided.innerRef}
                >
                  <div className={classes.listItemContents} {...provided.dragHandleProps}>
                    <ListItemText 
                      primary={getPrimaryText(lessonSegment)} 
                      secondary={getVariationText(lessonSegment)} 
                      secondaryTypographyProps={{ className: classes.drillVariationText }}
                    />
                  </div>
                </ListItem>
              }
            </Draggable>
          )}
          {provided.placeholder}
        </List>
      }
    </Droppable>
  </DragDropContext>;
}

export default LessonSegmentsList;