import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  checkboxFormGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  button: {
    margin: '5px 20px'
  },
  nameTextField: {
    width: '90%',
    marginLeft: '5%'
  }
}));

export default useStyles;
