import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import ConfirmationDialog from 'components/dialogs/confirmationDialog';
import { DatePicker } from '@material-ui/pickers';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import ClassYearSelector, { UNSELECTED_CLASS_YEAR } from 'components/classYearSelector';
import { PatchCaptureEventResult } from 'network/captureEventRequests';
import { format } from 'date-fns';
import { 
  UpdateUserMeasurements, 
  PatchUser, 
  UpdateOrganizationUserProperties, 
  DeleteOrganizationUserProperties,
  UpsertCommunicationChannel,
  DeleteCommunicationChannel
} from 'network/userRequests';
import classnames from 'classnames';
import UnitConverter from 'utils/unitConverter.utils';
import Logger from 'js-logger';
import { CommunicationChannelTypes } from 'constants/communicationChannel.constants';

const getEmailChannel = (userId, channels) => {
  let channel = channels?.find(x => x.type === CommunicationChannelTypes.EmailAddress);
  if (!channel) {
    channel = {
      id: undefined,
      type: CommunicationChannelTypes.EmailAddress,
      userId,
      value: ''
    };
  }
  return channel;
};

function UpdateUserFieldsDialog({ 
  open,
  user,
  measurement,
  captureEventResults,
  eventUser,
  communicationChannels,
  onCancel,
  onFieldsUpdated
}) {
  const classes = useStyles();

  const { weightKilograms, heightCentimeters } = measurement ?? {};
  const initialWeight = weightKilograms != null 
    ? Math.round(UnitConverter.kgToLb(weightKilograms)) 
    : null;

  const initialHeight = heightCentimeters != null 
    ? Math.round(UnitConverter.cmToIn(heightCentimeters)) 
    : null;

  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  // User Fields and Changes
  const [firstName, setFirstName] = useState(user.firstName ?? '');
  const [lastName, setLastName] = useState(user.lastName ?? '');
  const [dateOfBirth, setDateOfBirth] = useState(user.dateOfBirth);
  const [email, setEmail] = useState(user.emailAddress);
  const [classYear, setClassYear] = useState(user.highSchoolClassYear ?? UNSELECTED_CLASS_YEAR);

  const firstNameChanged = firstName?.trim() !== user.firstName;
  const lastNameChanged = lastName?.trim() !== user.lastName;
  const emailChanged = email.trim() !== user.emailAddress;
  const dateOfBirthChanged = dateOfBirth !== user.dateOfBirth;
  const classYearChanged = 
    (user.highSchoolClassYear ?? UNSELECTED_CLASS_YEAR).toString() !== classYear.toString();

  const changesToUserFields = 
    firstNameChanged
    || lastNameChanged
    || dateOfBirthChanged
    || emailChanged
    || classYearChanged;

  const classYearChangedToUnselected = 
    classYearChanged && classYear === UNSELECTED_CLASS_YEAR;

  // Measurement Fields and Changes
  const [weight, setWeight] = useState(initialWeight);
  const [height, setHeight] = useState(initialHeight);

  const weightChanged = weight?.toString() !== initialWeight?.toString();
  const heightChanged = height?.toString() !== initialHeight?.toString();

  const changesToMeasurements = weightChanged || heightChanged;

  // External Id Field and Changes
  const initialExternalId = user?.organizationUserProperties?.externalId ?? '';
  const [externalId, setExternalId] = useState(initialExternalId);

  const changesToExternalId = externalId.trim() !== initialExternalId;

  // Communication Channel Field and Changes
  const initialEmailChannel = getEmailChannel(user.userId, communicationChannels);
  const [emailChannel, setEmailChannel] = useState(initialEmailChannel);

  const changesToCommunicationEmail = initialEmailChannel.value !== emailChannel.value.trim();

  const upsertCommunicationChannel = async (updatedFields) => {
    if (!changesToCommunicationEmail) return;
    
    const trimmedValue = emailChannel.value.trim();

    if (trimmedValue === '' && emailChannel?.id) {
      await DeleteCommunicationChannel(user.userId, emailChannel.id);
      updatedFields.deletedCommunicationChannelId = emailChannel.id;
    } else {
      const channelToUpsert = { ...emailChannel, value: emailChannel.value.trim() };
      const upsertedChannel = await UpsertCommunicationChannel(user.userId, channelToUpsert);
      updatedFields.addedCommunicationChannel = upsertedChannel;
    }
  };

  const updateExternalId = async (updatedFields) => {
    if (!changesToExternalId) return; 

    const trimmedExternalId = externalId.trim();

    if (trimmedExternalId === '' && user?.organizationUserProperties?.id) {
      await DeleteOrganizationUserProperties(user.organizationUserProperties.id);
      updatedFields.externalId = null;
    } else {
      await UpdateOrganizationUserProperties(user.userId, { externalId: trimmedExternalId });
      updatedFields.externalId = trimmedExternalId;
    }
  };

  const createUserChangesList = () => {
    const changes = [];

    const addChange = (path, newValue) => {
      changes.push({
        path,
        op: 'replace',
        value: newValue
      });
    };

    firstNameChanged && addChange('firstName', firstName?.trim());
    lastNameChanged && addChange('lastName', lastName?.trim());
    emailChanged && addChange('emailAddress', email.trim());
    dateOfBirthChanged && addChange('dateOfBirth', dateOfBirth);
    if (classYearChanged) {
      addChange(
        'highSchoolClassYear',
        classYear === UNSELECTED_CLASS_YEAR
          ? null
          : classYear
      );
    }

    return changes;
  };

  const convertMeasurementValueToNumber = value => {
    return value === '' || isNaN(value) 
      ? 0 
      : parseFloat(value); 
  };

  const updateEventResultMeasurementIds = async measurementId => {
    return await Promise.all(
      captureEventResults.map(result => PatchCaptureEventResult(
        eventUser.captureEventId,
        eventUser.id,
        result.id,
        [{
          op: 'replace',
          path: '/measurementId',
          value: measurementId
        }]
      )));
  };

  const updateMeasurementFields = async (updatedFields) => {
    if (!changesToMeasurements) return null;
    const newMeasurement = {
      ...measurement, 
      id: undefined,
      startTime: (new Date()).toISOString()
    };

    if (weightChanged) {
      const newWeightValue = convertMeasurementValueToNumber(weight);
      newMeasurement.weightKilograms = UnitConverter.lbToKg(newWeightValue);
    }

    if (heightChanged) {
      const newHeightValue = convertMeasurementValueToNumber(height);
      newMeasurement.heightCentimeters = UnitConverter.inToCm(newHeightValue);
    }

    const updatedMeasurement = await UpdateUserMeasurements(user.userId, newMeasurement); 
    const updatedResults = await updateEventResultMeasurementIds(updatedMeasurement.id);

    updatedFields.measurement = updatedMeasurement;
    updatedFields.captureEventResults = updatedResults;
  };

  const updateUserFields = async (updatedFields) => {
    if (!changesToUserFields) return null;
    const changes = createUserChangesList();
    const updatedUser = await PatchUser(user.userId, changes);      
    updatedFields.user = {
      ...user,
      firstName: updatedUser.firstName,
      lastName: updatedUser.lastName,
      emailAddress: updatedUser.emailAddress,
      dateOfBirth: updatedUser.dateOfBirth,
      highSchoolClassYear: updatedUser.highSchoolClassYear
    };
  };

  const updateChangedFields = async () => {
    setUpdating(true);
    setError(false);
    try {
      const updatedFields = {};
      await updateUserFields(updatedFields);
      await updateMeasurementFields(updatedFields);
      await updateExternalId(updatedFields);
      await upsertCommunicationChannel(updatedFields);
      onFieldsUpdated(updatedFields);
      setUpdating(false);
    } catch (ex) {
      Logger.error('Error patching user in update user fields dialog', ex);
      setError('Error updating user, please contact dev.');
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (open) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setDateOfBirth(user.dateOfBirth);
      setEmail(user.emailAddress);
      setClassYear(user.highSchoolClassYear ?? UNSELECTED_CLASS_YEAR);
      setWeight(initialWeight);
      setHeight(initialHeight);
      setExternalId(user?.organizationUserProperties?.externalId ?? '');
      setEmailChannel(getEmailChannel(user.userId, communicationChannels));
    }
    setError(null);
  }, [open, user, initialWeight, initialHeight, communicationChannels]);

  const dateOfBirthUpdated = date => {
    // remove timezone information to match db
    const formattedDate = format(date, 'yyyy-MM-dd') + 'T00:00:00';
    setDateOfBirth(formattedDate);
  };

  const heightToFeetAndInchesLabel = heightCentimeters => {
    return heightCentimeters != null 
      ? `Height: ${Math.floor(heightCentimeters / 12)}'${Math.round(heightCentimeters % 12)}"`
      : 'Height';
  };

  const changesToAnyField = changesToUserFields 
    || changesToMeasurements 
    || changesToExternalId
    || changesToCommunicationEmail;

  const emailChannelUpdated = value => {
    setEmailChannel(prev => ({ ...prev, value }));
  };

  const showUseLoginButton = emailChannel?.value == null || emailChannel?.value?.trim() === '';

  return <ConfirmationDialog
    className={classes.dialog}
    title='Update User Fields'
    open={open}
    disableCancelOnLoad
    loading={updating}
    onConfirm={updateChangedFields}
    onCancel={onCancel}
    errorMessage={error}
    confirmDisabled={!changesToAnyField || classYearChangedToUnselected}
  >
    <div className={classes.fieldsContainer}>
      <TextField 
        variant='outlined' 
        label='First Name'
        className={classes.field}
        onChange={e => setFirstName(e.target.value)}
        value={firstName}
      />
      <TextField 
        variant='outlined' 
        label='Last Name'
        className={classes.field}
        onChange={e => setLastName(e.target.value)}
        value={lastName}
      />
      <TextField 
        variant='outlined' 
        label='Login Email'
        className={classes.field}
        onChange={e => setEmail(e.target.value)}
        value={email}
      />
      <TextField 
        variant='outlined' 
        label='Communication Email'
        className={classes.field}
        onChange={e => emailChannelUpdated(e.target.value)}
        value={emailChannel?.value ?? ''}
        InputProps={{
          endAdornment: showUseLoginButton && <Button 
            className={classes.useLoginButton}
            onClick={() => emailChannelUpdated(email.trim())}
          >
            Use Login Email
          </Button>
        }}
      />
      <TextField 
        variant='outlined' 
        label='External Id'
        className={classes.field}
        onChange={e => setExternalId(e.target.value)}
        value={externalId}
      />
      <DatePicker
        className={classes.field}
        label='Date of Birth'
        variant='inline'
        inputVariant='outlined'
        format='MM/dd/yy'
        disableFuture
        onChange={dateOfBirthUpdated}
        value={dateOfBirth}
      />
      <div className={classes.measurements}>
        <TextField 
          variant='outlined' 
          label='Weight'
          className={classnames(classes.field, classes.smallInput, classes.marginRight)}
          onChange={e => setWeight(e.target.value)}
          value={weight}
          type='number'
          InputProps={{
            endAdornment: <InputAdornment position='end'>lbs</InputAdornment>
          }}
        />
        <TextField 
          variant='outlined' 
          label={heightToFeetAndInchesLabel(height)}
          className={classnames(classes.field, classes.smallInput, classes.marginRight)}
          onChange={e => setHeight(e.target.value)}
          value={height}
          type='number'
          InputProps={{
            endAdornment: <InputAdornment position='end'>in</InputAdornment>
          }}
        />
        <ClassYearSelector 
          className={classnames(classes.field)}
          inputClassName={classes.smallInput}
          classYear={classYear}
          removeUnselectedOption={user.highSchoolClassYear != null}
          onChange={newClassYear => setClassYear(newClassYear)}
        />
      </div>
    </div>
  </ConfirmationDialog>;
};

export default UpdateUserFieldsDialog;