import React, { useState, useEffect, useMemo } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './dateRangeStyleOverrides.css';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ProgressButton from '../../../../components/progressButton';
import { PostCaptureEvent } from '../../../../network/captureEventRequests';
import { CaptureEventTypes } from 'constants/captureEvents.constants';
import Logger from 'js-logger';
import useInput from '../../../../utils/useInput';
import useStyles from './styles';
import { MenuItem, Select } from '@material-ui/core';

const INITIAL_DATE_RANGE = { startDate: null, endDate: null };

function CreateCaptureEvent({ onEventCreated, className }) {
  const [creatingEvent, setCreatingEvent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const classes = useStyles();

  const [dateSelectionRange, setDateSelectionRange] = useState(INITIAL_DATE_RANGE);
  const [captureEventType, setCaptureEventType] = useState(CaptureEventTypes.travelOrg);
  const [dateFocusedInput, setDateFocusedInput] = useState(null);
  const { startDate, endDate } = dateSelectionRange;
  const nameInput = useInput('', true);

  useEffect(() => {
    const clear = nameInput.clearValue;
    clear();
    setDateSelectionRange(INITIAL_DATE_RANGE);
    setLoading(false);
  }, [creatingEvent, nameInput.clearValue]);

  const saveDisabled = useMemo(() => 
    nameInput.value.trim() === '' || 
    startDate == null || 
    endDate == null, 
  [nameInput.value, startDate, endDate]);

  async function createCaptureEvent() {    
    setLoading(true);
    setError(false);
    try {
      const newEvent = await PostCaptureEvent(
        nameInput.value.trim(), 
        startDate.toDate(), 
        endDate.toDate(),
        captureEventType);
      onEventCreated(newEvent);
      setCreatingEvent(false);
    } catch (ex) {
      Logger.error('Error creating new capture event.', ex);
      setError(true);
    }
    setLoading(false);
  }

  return <div className={className}>
    <Collapse in={creatingEvent}>
      <Paper className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField 
              {...nameInput.bind}
              variant='outlined'
              label='Name' 
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangePicker 
              startDate={dateSelectionRange.startDate}
              startDateId='create-event-start-date'
              endDate={dateSelectionRange.endDate}
              endDateId='create-event-end-date'
              onDatesChange={setDateSelectionRange}
              focusedInput={dateFocusedInput}
              onFocusChange={setDateFocusedInput}
              isOutsideRange={() => false}
              minimumNights={0}
            />
          </Grid>

          <Grid item container spacing={1} justify='flex-end'>
            <Grid item >
              {error && <Typography color='error'>
                There was an error creating this event.
              </Typography>}
            </Grid>
            <Grid item>
              <Select
                value={captureEventType}
                onChange={(event) => setCaptureEventType(event?.target?.value)}
              >
                {Object.keys(CaptureEventTypes)
                  .filter(key => CaptureEventTypes[key] !== CaptureEventTypes.perfectGameEvent )
                  .map((key) => {
                    const value = CaptureEventTypes[key];
                    return <MenuItem value={value} key={key}>
                      {value}
                    </MenuItem>;
                  })}
              </Select>              
            </Grid>
            <Grid item>
              <Button onClick={() => setCreatingEvent(false)}>Cancel</Button>
            </Grid>
            <Grid item>
              <ProgressButton 
                onClick={createCaptureEvent} 
                color='primary'
                variant='outlined'
                showProgress={loading}
                disabled={saveDisabled}
              >
                Save
              </ProgressButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Collapse>

    <Collapse in={!creatingEvent}>
      <Button onClick={() => setCreatingEvent(true)} color='primary' variant='outlined'>
        New Capture Event
      </Button>
    </Collapse>
  </div>;
}

export default CreateCaptureEvent;