import { FormControl, FormHelperText, LinearProgress, Typography } from '@material-ui/core';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import EmailValidator from 'email-validator';
import { CreatePlayerForEvent } from 'network/captureEventRequests';
import React, { useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ProgressButton from 'components/progressButton';
import useStyles from './styles';
import UnitConverter from 'utils/unitConverter.utils';
import { prettyPrintHeight } from 'utils/user.utils';
import { range } from 'lodash';
import MuiAlert from '@material-ui/lab/Alert';
import { isReasonablePlayerWeight } from 'utils/validation.utils';
import useInput from 'utils/useInput';
import useInputFixValidation from './useInputFixValidation';
import Logger from 'js-logger';

const NONE = 'None';

const MIN_INCHES = 44;
const MAX_INCHES = 90;

const SignUpForCaptureEvent = (props) => {
  const classes = useStyles();

  const eventId = props?.match?.params?.eventId;
  const eventName = decodeURIComponent(props?.match?.params?.eventName);
  const [isLoading, setIsLoading] = useState(false);
  const [submitSucceeded, setSubmitSucceeded] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const entireFormValidate = () => {
    let hasErrors = false;
    requiredFields.forEach(field => {
      if(!field.validate()) {
        hasErrors = true;
      }
    });
    return !hasErrors;
  };

  const formHasErrors = () => {
    let hasErrors = false;
    requiredFields.forEach(field => {
      if(field.inError) {
        hasErrors = true;
      }
    });
    return !hasErrors;
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const valid = entireFormValidate();
    if(!valid) {
      setIsLoading(false);
      return;
    }

    try {
      const pgPlayer = {
        firstName: firstNameInput.value.trim(),
        lastName: lastNameInput.value.trim(),
        weight: weightInput.value, // lbs
        centimeters: UnitConverter.inToCm(heightInput.value), 
        meters: UnitConverter.inToCm(heightInput.value) / 100,
        bats: handednessInput.value,
        throws: handednessInput.value,
        playerEmail: playerEmailInput.value,
        dateOfBirth: dobInput.value,
        playerPhoneNumber: playerPhoneInput.value,
        highSchoolClassYear: hsClassInput.value,
        associatedContacts: [
          {
            Relationship: 'Parent',
            EmailAddress: parentEmailInput.value,
            PhoneNumber: parentPhoneInput.value
          }
        ]
      };

      await CreatePlayerForEvent(
        eventId, 
        pgPlayer
      );
      setSubmitSucceeded(true);
      setSubmitError(null);
    } catch (e) {
      setSubmitError('There was an error submitting, please try again.');
      Logger.error('Error submitting signup form', e);
    } finally {
      setIsLoading(false);
    }
  };

  const notZeroLength = x => (x.trim().length > 0);
  const notNone = x => x !== NONE;

  const firstNameInput = useInput({
    trimValue: false,
    validation: notZeroLength,
    validationMsg: 'Please enter a first name'
  });

  const lastNameInput = useInput({
    trimValue: false,
    validation: notZeroLength,
    validationMsg: 'Please enter a last name'
  });

  const dobInput = useInput({
    initialValue: NONE,
    validation: notNone,
    validationMsg: 'Required'
  });

  const heightInput = useInput({
    initialValue: NONE,
    validation: notNone,
    validationMsg: 'Required'
  });
  
  const weightInput = useInput({
    trimValue: true,
    validation: isReasonablePlayerWeight,
    validationMsg: 'Required'
  });

  const hsClassInput = useInput({
    initialValue: NONE,
    validation: notNone,
    validationMsg: 'Required'
  });
  
  const handednessInput = useInput({
    initialValue: NONE,
    validation: notNone,
    validationMsg: 'Required'
  });

  // Contact Info:
  let confirmPlayerEmailInput = {};
  const playerEmailInput = useInputFixValidation({
    trimValue: true,
    validation: email => {
      if(confirmPlayerEmailInput?.value !== '') {
        confirmPlayerEmailInput?.validate();
      }
      return EmailValidator.validate(email);
    },
    validationMsg: 'Please enter a valid email'
  });

  confirmPlayerEmailInput = useInputFixValidation({
    trimValue: true,
    validation: email => playerEmailInput.value === email,
    validationMsg: 'Please make sure emails match'
  });

  const playerPhoneInput = useInput({
    trimValue: true,
    validationMsg: 'Please enter a phone number'
  });

  let confirmParentEmailInput = {};
  const parentEmailInput = useInputFixValidation({
    trimValue: true,
    validation: email => {
      if(confirmParentEmailInput?.value !== '') {
        confirmParentEmailInput?.validate();
      }
      return EmailValidator.validate(email);
    },
    validationMsg: 'Please enter a valid email'
  });

  confirmParentEmailInput = useInputFixValidation({
    trimValue: true,
    validation: email => parentEmailInput.value === email,
    validationMsg: 'Please make sure emails match'
  });

  const parentPhoneInput = useInput({
    trimValue: true,
    validation: notZeroLength,
    validationMsg: 'Please enter a phone number'
  });

  const requiredFields = [
    firstNameInput,
    lastNameInput,
    dobInput,
    heightInput,
    weightInput,
    handednessInput,
    hsClassInput,

    playerEmailInput,
    confirmPlayerEmailInput,

    parentEmailInput,
    confirmParentEmailInput,
    parentPhoneInput
  ];

  if(submitSucceeded) {
    return <div className={classes.container}>
      <Typography variant='h3' className={classes.successText}>
        Thank you
      </Typography>
      <Typography>You are signed up for the event.</Typography>
    </div>;
  }

  if(isLoading) {
    return <div className={classes.loadingContainer}>
      <LinearProgress />
      <Typography variant='h6' className={classes.loadingText}>
        Loading...
      </Typography>
    </div>;
  }
  
  return <div className={classes.container}>
    <Typography variant='h4'>Sign Up For {eventName}</Typography> 
    <Typography variant='h6' className={classes.subheader}>
      1) Player Info
    </Typography>
    <Divider />
    <div className={classes.compactFormField}>
      <TextField
        {...firstNameInput.bind}
        onBlur={firstNameInput.validate}
        label={'Player First Name'}
        error={firstNameInput.inError}
        errorMsg={firstNameInput.errorMsg}
        disabled={isLoading}
        className={classes.textField}
      />
    </div>
    <div className={classes.compactFormField}>
      <TextField
        {...lastNameInput.bind}
        onBlur={lastNameInput.validate}
        label={'Player Last Name'}
        error={lastNameInput.inError}
        errorMsg={lastNameInput.errorMsg}
        disabled={isLoading}
        className={classes.textField}
      />
    </div>
  
    <div className={classes.formField}>
      <Typography>Date of Birth:</Typography>
      <FormControl error={dobInput.inError}>
        <DatePicker
          openTo='year'
          defaultValue={NONE}
          format='MM/dd/yyyy'
          {...dobInput.bind}
          onChange={(dob) => {
            dobInput.bind.onChange({ target: { value: dob }});
          }}
          disabled={isLoading}
        />
      </FormControl>
    </div>

    <div className={classes.formField}>
      <FormControl error={heightInput.inError}>
        <Select
          className={classes.height}
          defaultValue={NONE} 
          {...heightInput.bind}
          disabled={isLoading}
        >
          <MenuItem value={NONE}>Height</MenuItem>
          {range(MIN_INCHES, MAX_INCHES).map(heightInches => {
            return <MenuItem key={'height-' + heightInches} value={heightInches}>
              {prettyPrintHeight(heightInches)}
            </MenuItem>;
          })}
        </Select>
        {heightInput.inError && <FormHelperText >{heightInput.errorMsg}</FormHelperText>}
      </FormControl>
      
      <TextField
        inputProps={{ type: 'number' }}
        label={'Weight (lb)'}
        {...weightInput.bind}
        onBlur={weightInput.validate}
        error={weightInput.inError}
        errorMsg={weightInput.errorMsg}
        disabled={isLoading}
      />
    </div>

    <div className={classes.formField}>
      <FormControl error={handednessInput.inError}>
        <Select
          className={classes.handedness}
          defaultValue={NONE} 
          {...handednessInput.bind}
          disabled={isLoading}
        >
          <MenuItem value={NONE}>Handedness</MenuItem>
          <MenuItem value={'R'}>Right</MenuItem>
          <MenuItem value={'L'}>Left</MenuItem>
          <MenuItem value={'S'}>Switch</MenuItem>
        </Select>
        {handednessInput.inError && <FormHelperText >{handednessInput.errorMsg}</FormHelperText>}
      </FormControl>
      <FormControl error={hsClassInput.inError}>
        <Select 
          defaultValue={NONE} 
          {...hsClassInput.bind}
          disabled={isLoading}
        >
          <MenuItem value={NONE}>High School Class</MenuItem>
          {range(2035, 2020).map(x => x.toString()).map(year => {
            return <MenuItem key={'hs-' + year} value={year}>{year}</MenuItem>;
          })}
        </Select>
        {hsClassInput.inError && <FormHelperText >{hsClassInput.errorMsg}</FormHelperText>}
      </FormControl>
    </div>
    
    <Typography variant='h6' className={classes.subheader}>
      2) Contact Info
    </Typography>
    <Divider />


    <div className={classes.compactFormField}>
      <TextField
        {...playerEmailInput.bind}
        onBlur={playerEmailInput.validate}
        label={'Player Email Address'}
        inputProps={{ type: 'email' }}
        error={playerEmailInput.inError}
        errorMsg={playerEmailInput.errorMsg}
        disabled={isLoading}
        className={classes.textField}
      />
    </div>

    <div className={classes.compactFormField}>
      <TextField
        {...confirmPlayerEmailInput.bind}
        inputProps={{ type: 'email' }}
        onBlur={confirmPlayerEmailInput.validate}
        label={'Confirm Player Email'}
        error={confirmPlayerEmailInput.inError}
        errorMsg={confirmPlayerEmailInput.errorMsg}
        disabled={isLoading}
        className={classes.textField}
      />
    </div>

    <div className={classes.compactFormField}>
      <TextField
        {...playerPhoneInput.bind}
        inputProps={{ type: 'number' }}
        onBlur={playerPhoneInput.validate}
        label={'Player Phone Number'}
        error={playerPhoneInput.inError}
        errorMsg={playerPhoneInput.errorMsg}
        disabled={isLoading}
        className={classes.textField}
      />
    </div>
    <br />
    <div className={classes.compactFormField}>
      <TextField
        {...parentEmailInput.bind}
        onBlur={parentEmailInput.validate}
        inputProps={{ type: 'email' }}
        label={'Parent Email Address'}
        error={parentEmailInput.inError}
        errorMsg={parentEmailInput.errorMsg}
        disabled={isLoading}
        className={classes.textField}
      />
    </div>

    <div className={classes.compactFormField}>
      <TextField
        {...confirmParentEmailInput.bind}
        onBlur={confirmParentEmailInput.validate}
        inputProps={{ type: 'email' }}
        label={'Confirm Parent Email'}
        error={confirmParentEmailInput.inError}
        errorMsg={confirmParentEmailInput.errorMsg}
        disabled={isLoading}
        className={classes.textField}
      />
    </div>

    <div className={classes.compactFormField}>
      <TextField
        {...parentPhoneInput.bind}
        inputProps={{ type: 'number' }}
        onBlur={parentPhoneInput.validate}
        label={'Parent Phone Number'}
        error={parentPhoneInput.inError}
        errorMsg={parentPhoneInput.errorMsg}
        disabled={isLoading}
        className={classes.textField}
      />
    </div>
    <br />      
    {submitError != null && 
      <MuiAlert className={classes.compactFormField} severity='warning'>{ submitError }</MuiAlert>}
    {!formHasErrors() &&
      <MuiAlert severity='error'>Please review the form above and address the highlighted problems.</MuiAlert>}
    <ProgressButton className={classes.submitBtn}
      onClick={onSubmit}
      showProgress={isLoading}
    >Submit</ProgressButton>
  </div>;
};

export default SignUpForCaptureEvent;