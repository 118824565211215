import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(_=> ({
  contentContainer: {
    padding: '15px'
  },
  isMinimized: {
    position: 'absolute',
    left: '10px',
    paddingRight: '10px',
    top: 0,
    transform: 'translateX(-100%)',
    zIndex: -1,
    height: '100%',
    borderRadius: '5px'
  },
  container: {
    width: '275px',
    height: '100%',
    overflow: 'hidden'
  },
  accordion: {
    maxHeight: '50%',
    overflowY: 'auto'
  },
  accordionDetails: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  attributesList: {
    paddingTop: '0px'
  },
  expandedAttributes: {
    paddingBottom: '15px'
  },
  scheduleDate: {
    marginLeft: '20px',
    fontStyle: 'italic'
  }
}));