import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import SelectMotionView from '../selectMotionView';
import AnalystView from '../analystView';

import { UpdatePlayerDraft, RemovePlayerDraft } from '../../../../../store/assessments';
import { convertSectionsToObject, convertLabelsToObject } from '../../../../../utils/kAssessments.utils.js';

function AnalystFlow({
  topPadding,
  userId,
  orgId,
  onBack,
  onPublish
}) {

  const emptyAssessment = { labels: [], sections: [] };

  // set up two main vars for the hook
  const dispatch = useDispatch();
  const drafts = useSelector(state => state.assessments);
  const draft = drafts[userId] || null;
  const motionData = !draft ? null : (draft.motionData || null);

  const backToSelect = () => {
    // set the draft so that the userId has no data
    dispatch(RemovePlayerDraft(userId));
  };

  // vars to help state
  const hasMotion = Boolean(motionData);

  const convertDraft = (draft) => {
    draft.labels = convertLabelsToObject(draft.labels);
    draft.sections = convertSectionsToObject(draft.sections);
    return draft;
  };

  const dataSelected = (motion, draft) => {
    // see if just a motion is selected or if both are selected
    const assessment = draft || emptyAssessment;
    convertDraft(assessment);

    // assign motion data to the assessment
    assessment.motionData = motion;

    // save to state
    dispatch(UpdatePlayerDraft(userId, assessment));
  };

  const onSaved = (savedDraft) => {
    dataSelected(motionData, savedDraft);
  };

  const published = () => {
    // tell parent to go to coach view
    onPublish();

    // remove from state after first going to correct view
    // prevents select motion from automatically getting drafts on load
    dispatch(RemovePlayerDraft(userId));
  };

  const goBack = () => {
    onBack();

    // remove from state after first going to correct view
    // prevents select motion from automatically getting drafts on load
    dispatch(RemovePlayerDraft(userId));
  };

  return <div>
    {
      !hasMotion &&
          <SelectMotionView 
            onBack={goBack}
            userId={userId}
            orgId={orgId}
            onConfirmation={dataSelected}
          />
    }
    {
      hasMotion &&
          <AnalystView
            topPadding={topPadding}
            initialAssessment={draft}
            userId={userId}
            orgId={orgId}
            motionData={motionData}
            onBackToSelectMotion={backToSelect}
            onPublish={published}
            onSaved={onSaved}
          />
    }
  </div>;
}
  
export default AnalystFlow;