import { useState, useEffect } from 'react';
import axios from 'axios';
import Logger from 'js-logger';
import { GetContentFeedItems } from '../../network/contentFeedItemsRequests';

function useContentFeedItems(organizationId) {
  const [contentFeedItems, setContentFeedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    
    async function fetchData() {
      setLoading(true);
      try {
        var contentFeedItems = await GetContentFeedItems(organizationId, cancelSource);
        setContentFeedItems(contentFeedItems);
        setLoading(false);
      }
      catch (e) {
        if (!axios.isCancel(e)) {
          Logger.error('Error fetching content feed items', e);
          setError(true);
          setLoading(false);
        }
      }
    }
    fetchData();

    return cancelSource.cancel;
  }, [organizationId]);

  return [contentFeedItems, setContentFeedItems, loading, error];
}

export default useContentFeedItems;
