import React from 'react';
import useStyles from './styles';

import ReferenceTextArea from '../../referenceTextAreas';
import AssessmentGraphDisplay from '../../assessmentGraphDisplay';

const SECTIONS = [
  'Overview',
  'Key Points',
  'Sequence',
  'Shape',
  'Spacing',
  'Timing',
  'Peak Speeds',
  'Speed Gain',
  'Time to Contact',
  'Heel Strike',
  'First Move',
  'Contact',
  'Summary',
  'Focus Areas',
  'Action Items',
  'Drills',
  'Additional Notes'
];

function AssessmentView({
  userId,
  labels = {},
  sections = {},
  motionData,
  disable = false,
  editable = false,
  onSectionUpdate,
  onLabelSelected = () => {}
}) {

  const classes = useStyles();

  let formattedSections = SECTIONS.reduce((acc, name) => {
    if (editable) {
      acc.push({ name, text: (sections[name] && sections[name].text) || '' });
    } else if (sections[name] && sections[name].text) {
      acc.push({ name, text: sections[name].text });
    }
    return acc;
  }, []);

  return <div>
    <div className={classes.visuals}>
      <AssessmentGraphDisplay
        labels={labels}
        motionData={motionData} 
        userId={userId} />
    </div>

    <div className={classes.sections}>
      {
        formattedSections.map(({ name, text }, i) => {
          return <ReferenceTextArea 
            key={i}
            disable={disable}
            editable={editable}
            labels={labels} 
            header={name} 
            initialText={text}
            onTextUpdate={onSectionUpdate}
            onLabelSelected={onLabelSelected}
          />;
        })
      }
    </div>
  </div>;
}
  
export default AssessmentView;