import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import findIndex from 'lodash/findIndex';
import classnames from 'classnames';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import {
  Button,
  Paper,
  Tab,
  Tabs,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody, 
  Toolbar,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  Typography
} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import SearchIcon from '@material-ui/icons/Search';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { history } from 'store';
import { 
  ACTION_STATUSES, 
  EVENT_ASSIGNMENT_STATUSES,
  TOOLTIPS 
} from '../../../../constants/captureEvents.constants';
import useStyles from './styles';
import ExpandableUserDetails from './expandableUserDetails';
import EventUserMotionsReviewDialog from './eventUserMotionsReviewDialog';
import EventUserScoutPlayersDialog from './eventUserScoutPlayersDialog';
import EventUserVideoReview from './eventUserVideoReview';
import SearchBar from '../../../../components/searchBar';
import useInput from '../../../../utils/useInput';
import SortableHeaderCell from '../../../../components/sortableHeaderCell';
import { EventActions } from './eventActions';
import useEventUserSearchInput from './useEventUserSearchInput';
import EventUserMotionsAssignmentDialog from './eventUserMotionsAssignmentDialog';
import TrainingPlanToolsDialog from './trainingPlanToolsDialog';
import { CaptureEventTypes } from 'constants/captureEvents.constants';
import AddUsersDialog from './addUsersDialog';
import PurchaseStatusIcon from './purchaseStatusIcon';

const NO_CAPTURES = 'No Captures';

function TableCellWithInspection({ eventUser, onReview, children }) {
  const classes = useStyles();
  return <div className={classes.resultsCell}>
    { children }
    <IconButton 
      className={classes.inspectionIconContainer} 
      size='small' 
      onClick={() => onReview(eventUser)} >
      <SearchIcon className={classes.inspectionIcon} />
    </IconButton>
  </div>;
}

export const EVENT_USER_MODALS = {
  REVIEW_MOTIONS: 'reviewMotions',
  REVIEW_MOTION_ASSIGMENTS: 'reviewMotionAssigments',
  REVIEW_VIDEOS: 'reviewVideos',
  SCOUT_PLAYERS: 'scoutPlayers',
  TRAINING_PLAN_TOOLS: 'trainingPlanTools',
  ADD_USERS: 'addUsers'
};

const REVIEW_TABS = [
  { key: EVENT_USER_MODALS.REVIEW_MOTIONS, title: 'Motion Review' },
  { key: EVENT_USER_MODALS.REVIEW_MOTION_ASSIGMENTS, title: 'Assign Motions' },
  { key: EVENT_USER_MODALS.REVIEW_VIDEOS, title: 'Video Review' },
  { key: EVENT_USER_MODALS.SCOUT_PLAYERS, title: 'Scout Player' },
  { key: EVENT_USER_MODALS.TRAINING_PLAN_TOOLS, title: 'Training Plan Tools' }
];

function EventUsersTable({ 
  captureEvent, 
  captureEventUsers, 
  eventActions, 
  onNewAction,
  onUserClicked,
  expandedUserId,
  onDeleteUser
}) {
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const searchInput = useInput();
  const [sortedColumn, setSortedColumn] = useState({});
  const [addingUsers, setAddingUsers] = useState(false);

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { selectedEventUserId, modal: currentModal } = queryParams; 

  const setEventUserIdToReview = useCallback((newEventUserId, newModal) => {
    const pastParams = queryString.parse(location.search);
    let newParams = { ...pastParams, selectedEventUserId: newEventUserId, modal: newModal };
    newParams = pickBy(newParams, x => x != null);
    history.replace({ search: new URLSearchParams(newParams).toString() });
  }, [location]);

  const captureEventId = captureEvent.id;

  const eventUserToReview = useMemo(() => {
    const parsedId = parseInt(selectedEventUserId);
    if (isNaN(parsedId)) return null;
    return captureEventUsers.find(u => u.id === parsedId);
  }, [selectedEventUserId, captureEventUsers]);

  useEffect(() => {
    // reset on changing capture event id
    const clearValue = searchInput.clearValue;
    clearValue();
    setSortedColumn({});
  }, [captureEventId, searchInput.clearValue]);

  const [captureEventUsersByDate, captureDates] = useMemo(() => {
    const usersByDate = captureEventUsers.reduce((prev, cur) => {
      const { captureEventStatuses } = cur;
      if (captureEventStatuses == null || captureEventStatuses.length === 0) {
        const prevNoCaptures = NO_CAPTURES in prev ? prev[NO_CAPTURES] : [];
        return { ...prev, [NO_CAPTURES]: [ ...prevNoCaptures, cur] };
      }

      let acc = { ...prev };
      captureEventStatuses.forEach(({ captureDate }) => {
        if (!(captureDate in acc)) {
          acc[captureDate] = [];
        }
        acc[captureDate].push(cur);
      });
      return acc;

    }, {});

    const sortedKeys = orderBy(
      Object.keys(usersByDate).filter(x => x !== NO_CAPTURES), 
      x => new Date(x), 
      'desc');
    if (NO_CAPTURES in usersByDate) {
      sortedKeys.push(NO_CAPTURES);
    }
    
    setSelectedDate(prev => (prev == null || !sortedKeys.includes(prev)) && sortedKeys.length > 0 
      ? sortedKeys[0] 
      : prev);
    return [usersByDate, sortedKeys];
  }, [captureEventUsers]);

  if (!captureEventUsers) captureEventUsers = [];
  if (!eventActions) eventActions = [];

  const getTooltip = (eventuUser, actionType) => {
    const status = eventuUser[actionType];
    if (status == null) return null;
    var tooltipMsg = TOOLTIPS[actionType]?.[status];
    if (tooltipMsg == null) {
      tooltipMsg = status;
    }
    return tooltipMsg;
  };

  const actionStatusToIconHelper = (status) => {
    switch(status) {
      case ACTION_STATUSES.processing:
        return <HourglassEmptyIcon className={classes.processingIcon} />;
      case ACTION_STATUSES.failed:
        return <ErrorIcon className={classes.errorIcon}/>;
      case ACTION_STATUSES.completed:
        return <CheckCircleIcon className={classes.successIcon} />;
      case ACTION_STATUSES.needsReview:
        return <VisibilityIcon className={classes.reviewIcon} />;
      case ACTION_STATUSES.warning:
        return <WarningIcon className={classes.warningIcon} />;
      default:
        return null;
    }
  };

  function actionStatusToIcon(status, tooltip) {
    const IconEl = actionStatusToIconHelper(status);
    if (tooltip == null || IconEl == null) return IconEl;
    return <Tooltip title={tooltip} aria-label={tooltip}>
      {IconEl}
    </Tooltip>;
  };

  const onCellHeaderClicked = (key, sortFunction) => () => {
    setSortedColumn(prev => prev.key === key
      ? { ...prev, reverse: !prev.reverse }
      : { key, sortFunction, reverse: false });
  };

  const handleUserClick = (user) => {
    const selectedIndex = selectedUsers.indexOf(user);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelected);
  };

  const isUserSelected = (user) => selectedUsers.indexOf(user) !== -1;  
  const sortedUsers = useMemo(() => {
    const usersOnDate = selectedDate ? captureEventUsersByDate[selectedDate] : [];
    if (usersOnDate == null) return [];
    const { sortFunction, reverse } = sortedColumn;
    if (sortFunction == null) return usersOnDate;
    const sortedList = orderBy(usersOnDate, sortFunction);
    return reverse ? sortedList.reverse() : sortedList;
  }, [captureEventUsersByDate, selectedDate, sortedColumn]);

  const filteredUsers = useEventUserSearchInput(searchInput, sortedUsers);
  const selectedUserId = eventUserToReview && eventUserToReview.userId;
  const userIdx = findIndex(filteredUsers, user => user.userId === selectedUserId);

  const selectNextUser = (userIdx < filteredUsers.length - 1 && userIdx !== -1)
    ? () => setEventUserIdToReview(filteredUsers[userIdx + 1].id, currentModal)
    : null;
  const selectPrevUser = userIdx > 0
    ? () => setEventUserIdToReview(filteredUsers[userIdx - 1].id, currentModal)
    : null;

  const openNewView = (eventUser, modalName) => {
    setEventUserIdToReview(eventUser.id, modalName);
  };

  const closeModal = () => {
    setEventUserIdToReview(null, null);
  };

  const eventCanUploadUsers = captureEvent.type !== CaptureEventTypes.perfectGameEvent;

  return (
    <div>
      <Paper>
        <Toolbar className={classes.toolbar}>
          <div className={classes.headerLabels}>
            <SearchBar 
              {...searchInput.bind}
              onClear={searchInput.clearValue}
              placeholder='Filter Players'
            />
          </div>

          {eventCanUploadUsers &&
            <Button onClick={() => setAddingUsers(true)}>
              Upload Users
            </Button>
          }

          <Tabs 
            value={selectedDate} 
            onChange={(_, val) => setSelectedDate(val)}
          >
            {captureDates.map(date => <Tab key={date} value={date} label={date}/>)}
          </Tabs>
        </Toolbar>
        <TableContainer style={{ maxHeight: window.innerHeight * 0.8 }}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  <Tooltip title='Scout Player for Event'>
                    <SearchIcon />
                  </Tooltip>
                </TableCell>
                <SortableHeaderCell align='left' 
                  selected={sortedColumn.key === 'name'}
                  descending={sortedColumn.reverse}
                  onClick={onCellHeaderClicked(
                    'name', 
                    x => `${x.user.firstName} ${x.userlastName}`)}
                >
                  Name
                </SortableHeaderCell>
                <SortableHeaderCell align='right' 
                  selected={sortedColumn.key === 'email'}
                  descending={sortedColumn.reverse}
                  onClick={onCellHeaderClicked('email', x => x.user.emailAddress)}
                >
                  Email
                </SortableHeaderCell>
                <SortableHeaderCell align='right' 
                  selected={sortedColumn.key === 'externalId'}
                  descending={sortedColumn.reverse}
                  onClick={onCellHeaderClicked('externalId', x => x?.user?.organizationUserProperties?.externalId)}
                >
                  External Id
                </SortableHeaderCell>
    
                <SortableHeaderCell align='center'
                  selected={sortedColumn.key === 'motionsUploadedStatus'}
                  descending={sortedColumn.reverse}
                  onClick={onCellHeaderClicked('motionsUploadedStatus', x => x.motionsUploadedStatus)}
                >
                  Motions<br />Uploaded
                </SortableHeaderCell>
                <SortableHeaderCell align='center'
                  selected={sortedColumn.key === EventActions.createResults.key}
                  descending={sortedColumn.reverse}
                  onClick={onCellHeaderClicked(
                    EventActions.createResults.key, 
                    x => x.resultsCreatedStatus)}
                >
                  Motions<br />Reviewed
                </SortableHeaderCell>
                <SortableHeaderCell align='center'
                  selected={sortedColumn.key === EventActions.createResults.key}
                  descending={sortedColumn.reverse}
                  onClick={onCellHeaderClicked(
                    EventActions.createResults.key, 
                    x => x.motionsAssignedStatus)}
                >
                  Motions<br />Assigned
                </SortableHeaderCell>
                <SortableHeaderCell align='center'
                  selected={sortedColumn.key === 'videosReviewed'}
                  descending={sortedColumn.reverse}
                  onClick={onCellHeaderClicked('videosReviewed', x => x.videosReviewedStatus)}
                >
                  Videos<br />Reviewed
                </SortableHeaderCell>
                <TableCell className={classes.borderLeft}>PDP Tools</TableCell>
                <SortableHeaderCell align='center'
                  selected={sortedColumn.key === EventActions.applySync.key}
                  descending={sortedColumn.reverse}
                  onClick={onCellHeaderClicked(
                    EventActions.applySync.key, 
                    EventActions.applySync.sortPredicate)}
                >
                  Data<br />Synced
                </SortableHeaderCell>
                <SortableHeaderCell align='center'
                  selected={sortedColumn.key === 'eventAssignment'}
                  descending={sortedColumn.reverse}
                  onClick={onCellHeaderClicked('eventAssignment', x => x.eventAssignmentStatus)}
                >
                  Min Data<br />Requirement
                </SortableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((eventUser) => {
                const selected = isUserSelected(eventUser);
                const { eventAssignmentStatus } = eventUser;
                const userIsWarning = 
                  eventAssignmentStatus === EVENT_ASSIGNMENT_STATUSES.needsExternalId;
                const userIsError = 
                  eventAssignmentStatus === EVENT_ASSIGNMENT_STATUSES.multipleUsersConflict
                  || eventAssignmentStatus === EVENT_ASSIGNMENT_STATUSES.multipleEventsConflict;

                return <React.Fragment key={eventUser.id}> 
                  <TableRow 
                    selected={selected} 
                    onClick={() => handleUserClick(eventUser)}
                    className={classnames(classes.tableRow, {
                      [classes.warning]: userIsWarning,
                      [classes.error]: userIsError
                    })}
                  >
                    <TableCell align='center'>
                      <TableCellWithInspection 
                        eventUser={eventUser} 
                        onReview={eventUser =>
                          openNewView(eventUser, EVENT_USER_MODALS.SCOUT_PLAYERS)
                        }
                      />
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      <Button 
                        color='primary'
                        onClick={e => { e.stopPropagation(); onUserClicked(eventUser.userId); }}
                        className={classes.userNameButton}
                      >
                        {eventUser.user.firstName + ' ' + eventUser.user.lastName}
                      </Button>
                    </TableCell>
                    <TableCell align='right'>{eventUser.user.emailAddress}</TableCell>
                    <TableCell align='right'>{eventUser?.user?.organizationUserProperties?.externalId}</TableCell>
                    <TableCell align='center'>{actionStatusToIcon(eventUser.motionsUploadedStatus)}</TableCell>
                    <TableCell align='center'>
                      <TableCellWithInspection 
                        eventUser={eventUser} 
                        onReview={eventUser => 
                          openNewView(eventUser, EVENT_USER_MODALS.REVIEW_MOTIONS)
                        }
                      >
                        {actionStatusToIcon(eventUser.resultsCreatedStatus, getTooltip(eventUser, 'resultsCreatedStatus'))}
                      </TableCellWithInspection>
                    </TableCell>
                    <TableCell align='center'>
                      <TableCellWithInspection 
                        eventUser={eventUser} 
                        onReview={eventUser => 
                          openNewView(eventUser, EVENT_USER_MODALS.REVIEW_MOTION_ASSIGMENTS)
                        }
                      >
                        {actionStatusToIcon(eventUser.motionsAssignedStatus, getTooltip(eventUser, 'motionsAssignedStatus'))}
                      </TableCellWithInspection>
                    </TableCell>
                    <TableCell align='center'>
                      <TableCellWithInspection 
                        eventUser={eventUser} 
                        onReview={eventUser => 
                          openNewView(eventUser, EVENT_USER_MODALS.REVIEW_VIDEOS)
                        }
                      >
                        {actionStatusToIcon(eventUser.videosReviewedStatus, getTooltip(eventUser, 'videosReviewedStatus'))}
                      </TableCellWithInspection>
                    </TableCell>
                    <TableCell className={classes.borderLeft} align='center'>
                      <TableCellWithInspection 
                        eventUser={eventUser} 
                        onReview={eventUser => 
                          openNewView(eventUser, EVENT_USER_MODALS.TRAINING_PLAN_TOOLS)
                        }
                      />
                      <PurchaseStatusIcon purchaseStatus={eventUser.playerDevelopmentPlanStatus} />
                    </TableCell>
                    <TableCell align='center'>
                      {actionStatusToIcon(eventUser.launchMonitorDataSyncedStatus)}
                    </TableCell>
                    <TableCell align='center'>
                      {actionStatusToIcon(
                        eventUser.minimumDataRequirementStatus,
                        getTooltip(eventUser, 'minimumDataRequirementStatus'))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={12} className={classes.expandableDetailsCell}>
                      <ExpandableUserDetails 
                        expanded={expandedUserId === eventUser.userId} 
                        eventUser={eventUser}
                        onDeleteUser={onDeleteUser}
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <AddUsersDialog 
        open={addingUsers}
        onClose={() => setAddingUsers(false)}
        eventId={captureEventId}
      />

      <Dialog 
        open={currentModal != null && currentModal !== EVENT_USER_MODALS.ADD_USERS} 
        onClose={closeModal} 
        maxWidth='xl' 
        fullWidth
        classes={{ paper: classes.reviewDialog }}
      >
        <DialogTitle>
          <div className={classes.titleContainer}>
            <div className={classes.topLeftInfo}>
              {selectPrevUser && <IconButton onClick={selectPrevUser}><ChevronLeft /></IconButton>}
              <Typography variant='h5'>{eventUserToReview?.user.fullName}</Typography>
              {selectNextUser && <IconButton onClick={selectNextUser}><ChevronRight /></IconButton>}
            </div>

            <div>
              <Button
                className={classes.exitButton}
                onClick={closeModal}
                variant='outlined'
              >
                Close
              </Button>
            </div>
          </div>

          {currentModal && <Tabs 
            value={currentModal} 
            onChange={(_, newModal) => setEventUserIdToReview(selectedEventUserId, newModal)}
          >
            {REVIEW_TABS.map(({ key, title }) => <Tab value={key} key={key} label={title} />)}
          </Tabs>}
        </DialogTitle>

        {
          currentModal === EVENT_USER_MODALS.REVIEW_MOTIONS &&
          <EventUserMotionsReviewDialog 
            eventUser={eventUserToReview}
            onClose={closeModal}
            onResultsCreation={(newAction) => {
              onNewAction(newAction);
            }}
            onNextUser={selectNextUser}
            onPrevUser={selectPrevUser}
          />
        }
        {
          currentModal === EVENT_USER_MODALS.REVIEW_MOTION_ASSIGMENTS 
          && <EventUserMotionsAssignmentDialog 
            eventUser={eventUserToReview}
            onClose={closeModal}
          />
        }
        {
          currentModal === EVENT_USER_MODALS.REVIEW_VIDEOS &&
          <EventUserVideoReview 
            eventUser={eventUserToReview}
            onNextUser={selectNextUser}
          />
        }
        {
          currentModal === EVENT_USER_MODALS.SCOUT_PLAYERS &&
          <EventUserScoutPlayersDialog
            eventUser={eventUserToReview}
            captureEventId={captureEventId}
            onSelectUser={eventUserId => setEventUserIdToReview(eventUserId, currentModal)}
          />
        }
        {
          currentModal === EVENT_USER_MODALS.TRAINING_PLAN_TOOLS &&
          <TrainingPlanToolsDialog
            eventUser={eventUserToReview}
            captureEvent={captureEvent}
          />
        }
      </Dialog>
    </div>
  );
}

export default EventUsersTable;