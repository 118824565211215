import React, { useState } from 'react';
import classNames from 'classnames';
import ScatterPlot from '../scatterPlot';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import useStyles from './styles';

import { LOADING, ERROR, COMPLETE } from '../rechartsGraph.utils';

function ChartWithLegend({ userData, xAxis, yAxis }) {
  const classes = useStyles();
  const graphableUserData = userData.filter(x => x.graphData !== ERROR && x.graphData !== LOADING);
  const [ hoveringUserId, setHoveringUserId ] = useState(null);

  return <div className={classes.container}>
    <ScatterPlot 
      xAxis={xAxis}
      yAxis={yAxis}
      userData={graphableUserData}
      width={800}
      hoveringUserId={hoveringUserId}
      setHoveringUserId={setHoveringUserId}
    />
    
    <div>
      {userData.map(({ user, status, color }) => 
        <div key={user.userId} 
          className={classNames(
            classes.legendItem, 
            { [classes.hoveredItem]: user.userId === hoveringUserId }
          )} 
          onMouseEnter={() => setHoveringUserId(user.userId)}
          onMouseLeave={() => setHoveringUserId(null)}
        >
          <div className={classes.legendAdornmentContainer}>
            {status === LOADING && <CircularProgress size={12} style={{ color }}/>}
            {status === ERROR && <ErrorIcon color='error' />}
            {status === COMPLETE && 
              <div className={classes.legendMarker} style={{ backgroundColor: color }} />}
          </div>
          <Typography>{user.firstName} {user.lastName}</Typography>
        </div>)}
    </div>
  </div>;
}

export default ChartWithLegend;
