import React, { useState, useEffect } from 'react';
import KinematicDetailsDense from '../kinematicDetailsDense';

const DenseMultiCellPicker = React.memo(props => {
  const { initialColor = 'green', selectedMetricsChanged = () => {} } = props;
  const [selectedMetrics, setSelectedMetrics] = useState({});
  function metricClicked(metricName){
    const copyOfselectedMetrics = { ...selectedMetrics };
    if(selectedMetrics[metricName] == null){
      copyOfselectedMetrics[metricName] = [initialColor];
    } else {
      delete copyOfselectedMetrics[metricName];
    }
    selectedMetricsChanged(copyOfselectedMetrics);
    setSelectedMetrics(copyOfselectedMetrics);
  };

  useEffect(() => {
    const updatedMetrics = { ...selectedMetrics };
    Object.keys(updatedMetrics).forEach((key) => {
      updatedMetrics[key] = [initialColor];
    });
    setSelectedMetrics(updatedMetrics);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialColor]);
  
  return <KinematicDetailsDense 
    {...props}
    onMetricClick={metricClicked}
    enableHovering={true}
    metricsBackgroundColors={selectedMetrics}
  />;
});

export default DenseMultiCellPicker;