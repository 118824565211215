const styles = {
  outerContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  },
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px'
  },
  datePicker: {
    width: '90px',
    margin: '0 5px'
  },
  goBtn: {
    background: 'lightgreen',
    height: '40px',
    width: '40px',
    minWidth: '40px',
    borderRadius: '100%',
    marginLeft: '5px',
    marginTop: '8px'
  }
};

export default styles;