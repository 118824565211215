import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import ConfirmationDialog from 'components/dialogs/confirmationDialog';
import Logger from 'js-logger';
import { PURCHASE_STATUSES } from 'constants/captureEvents.constants';
import { Typography } from '@material-ui/core';
import PurchaseStatusIcon from '../../purchaseStatusIcon';
import classNames from 'classnames';
import { PatchEventUser } from 'network/captureEventRequests';

const PURCHASE_STATUS_DISPLAY = {
  [PURCHASE_STATUSES.notPurchased]: 'Not Purchased',
  [PURCHASE_STATUSES.purchased]: 'Purchased',
  [PURCHASE_STATUSES.created]: 'Created'
};

function DeliveryStatusDialog({ 
  open,
  eventUser,
  purchaseStatus,
  onCancel,
  onComplete
}) {
  const classes = useStyles();

  const [updating, setUpdating] = useState(false);
  const [newStatus, setNewStatus] = useState(purchaseStatus);
  const [error, setError] = useState(null);

  useEffect(() => {
    open && setNewStatus(purchaseStatus);
  }, [open, purchaseStatus]);

  const updateStatus = async () => {
    if (updating) return;
    setUpdating(true);

    try {
      await PatchEventUser(
        eventUser.captureEventId, 
        eventUser.id,
        [
          { 
            op: 'replace', 
            path: '/playerDevelopmentPlanStatus', 
            value: newStatus
          }
        ]
      );
      setUpdating(false);
      onComplete({
        ...eventUser,
        playerDevelopmentPlanStatus: newStatus
      });
    } catch(e) {
      Logger.error('Error when attempting to update a users plan delivery status', e);
      setError('There was an error updating. Please refresh and try again.');
      setUpdating(false);
    }
  };

  const cancel = () => {
    setNewStatus(purchaseStatus);
    onCancel();
  };

  const disabled = newStatus == null || newStatus === purchaseStatus;

  const DeliveryStatusOption = ({ statusOption }) => {
    const selected = statusOption === newStatus;
    return <div
      className={classNames(classes.option, { [classes.selected]: selected })} 
      onClick={() => setNewStatus(statusOption)}
    >
      <Typography>{ PURCHASE_STATUS_DISPLAY[statusOption] }</Typography>
      <PurchaseStatusIcon purchaseStatus={statusOption} />
    </div>;
  };


  return <ConfirmationDialog
    className={classes.dialog}
    title='Update Plan Delivery Status'
    open={open}
    disableCancelOnLoad
    loading={updating}
    onConfirm={updateStatus}
    onCancel={cancel}
    errorMessage={error}
    confirmDisabled={disabled}
  >
    <div className={classes.selectNewStatusContainer}>
      <DeliveryStatusOption statusOption={PURCHASE_STATUSES.notPurchased} />
      <DeliveryStatusOption statusOption={PURCHASE_STATUSES.purchased} />
      <DeliveryStatusOption statusOption={PURCHASE_STATUSES.created} />
      <Typography color='error'>{ error }</Typography>
    </div>
  </ConfirmationDialog>;
};

export default DeliveryStatusDialog;