import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  Grid,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Switch,
  Typography,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { 
  initializeEmptyTrainingPlan, 
  initializeEmptyTemplate, 
  switchNote,
  setMotionAttribute,
  fetchAndLoadTemplate,
  setIsEditingTemplate
} from '../../../store/trainingPlan';
import classnames from 'classnames';
import Logger from 'js-logger';
import useStyles from './styles';
import { GetMotionAttributes } from '../../../network/motionAttributeRequests';
import { GetTrainingPlanTemplates } from '../../../network/trainingPlanRequests';
import { ORGANIZATION_IDS } from '../../../constants/organizations.constants';
import { formatMMDD } from '../../../utils/formatting.utils';

function TrainingPlan({ open, fullScreen }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // using idx to keep track as new notes won't have an id
  const [motionAttributes, setMotionAttributes] = useState([]);
  const [trainingTemplates, setTrainingTemplates] = useState([]);
  const [errMsg, setErrMsg] = useState('');
  const trainingPlan = useSelector(state => state.trainingPlan);
  const currentPlayer = useSelector(state => state.currentPlayer);
  const orgId = useSelector(state => state.currentOrganization.id);

  const { isEditingTemplate } = trainingPlan;
  
  // if the component loads and there is already a 
  // selection made then we don't need to start expanded.
  const [attributesExpanded, setAttributesExpanded] = useState(
    trainingPlan.selectedMotionAttribute.id == null);

  useEffect(() => {
    // initialize an empty training plan if there is not one
    // already in progress in state.
    if (trainingPlan.notes.length === 0) {
      dispatch(initializeEmptyTrainingPlan(currentPlayer));
    }
  }, [trainingPlan, dispatch, currentPlayer]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchData() {
      try {
        const [attributes, templates] = await Promise.all([
          GetMotionAttributes(null, cancelToken),
          GetTrainingPlanTemplates(cancelToken)
        ]);
        const includedTypes = orgId === ORGANIZATION_IDS.perfectGame
          ? ['OnBaseU', 'PerfectGame']
          : ['OnBaseU'];
        
        const filteredAttributes = attributes.filter(
          attribute => includedTypes.includes(attribute.type));
        setMotionAttributes(filteredAttributes);
        setTrainingTemplates(templates);
      } catch (e) {
        Logger.error('Error getting training plan data', e);
        setErrMsg('There was a problem getting training plan data.');
      }
    }

    fetchData();
    return cancelToken.cancel;
  }, [orgId]);

  function selectAttribute(attr) {
    dispatch(setMotionAttribute(attr));
    setAttributesExpanded(false);
    loadTemplateOrDefault(attr.id, isEditingTemplate);
  }

  function loadTemplateOrDefault(attributeId, isTemplateMode) {
    // We need to pass in isTemplateMode instead of relying 
    // on the isEditingTemplate state because if it is currently being toggled, 
    // the state will be out of date until next render. 
    const templates = trainingTemplates.filter(template => 
      template.motionAttributeId === attributeId);
    const templateToLoad = templates.length > 0 ? templates[0] : null;

    if (templateToLoad) {
      if (isTemplateMode) {
        dispatch(fetchAndLoadTemplate(templateToLoad.id));
      } else {
        dispatch(fetchAndLoadTemplate(templateToLoad.id, null, currentPlayer));
      }
    } else {
      if (isTemplateMode) {
        dispatch(initializeEmptyTemplate(attributeId));
      } else {
        dispatch(initializeEmptyTrainingPlan(currentPlayer));  
      }
    }
  }

  function toggleTemplateEditing() {
    loadTemplateOrDefault(trainingPlan.selectedMotionAttribute.id, !isEditingTemplate);
    dispatch(setIsEditingTemplate(!isEditingTemplate));
  }

  if (!open) return null;

  return (
    <Paper 
      className={
        classnames(
          classes.container, 
          { [classes.isFull]: fullScreen }, 
          { [classes.isMinimized]: !fullScreen }
        )
      }
    >
      <div className={classes.contentContainer}>
        <Grid container>
          <Typography variant='h6'>Training Plan</Typography>
          <FormControl>
            <FormControlLabel
              control={
                <Switch 
                  checked={isEditingTemplate} 
                  onChange={toggleTemplateEditing}                  
                />
              }
              label='Edit Template'
            />
          </FormControl>
        </Grid>
        <Typography color='error'>{errMsg}</Typography>
      </div>
      <Accordion 
        expanded={attributesExpanded} 
        className={classnames(
          classes.accordion, 
          { [classes.expandedAttributes]: attributesExpanded }
        )}
      >
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setAttributesExpanded(prev => !prev)}
        >
          <Typography>
            {trainingPlan.selectedMotionAttribute.name || 'Select Attribute'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <List className={classes.attributesList}>
            {motionAttributes.map(attr => (
              <ListItem 
                key={`attr-${attr.id}`}
                button 
                onClick={() => selectAttribute(attr)}
              >
                <ListItemText>{attr.name}</ListItemText>
              </ListItem>
            ))} 
          </List>
        </AccordionDetails>
      </Accordion>
      <List>
        {attributesExpanded && 
          <ListItem disabled>
            <ListItemText>Week Selection</ListItemText>
          </ListItem> 
        }
        {!attributesExpanded && trainingPlan.notes.map((note, i) => (
          <ListItem 
            key={`note-${i}`} 
            button 
            onClick={() => dispatch(switchNote(i))}
            selected={i === trainingPlan.selectedNoteIdx}
            disabled={!trainingPlan.selectedMotionAttribute.id}
          >
            <ListItemText>
              Note {i + 1} 
              {i !== 0 && 
                <span className={classes.scheduleDate}>
                  {`(${formatMMDD(note.dateToSend)})`}
                </span>
              }
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default TrainingPlan;