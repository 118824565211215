import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  row: {
    width: '90%',
    marginBottom: '40px'
  },
  textarea: {
    width: '100%',
    padding: '5px'
  }
}));

export default useStyles;
