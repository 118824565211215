const styles = {
  card: { 
    padding: 20,
    margin: 20
  },
  button: {
    marginLeft: 15
  }
};

export default styles;
