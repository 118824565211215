import React, { useState } from 'react';
import { 
  Typography,
  Grid,
  Card
} from '@material-ui/core';
import { UndoFutureSubscriptionChanges } from '../../../network/purchaseRequests';
import styles from './styles';
import { products, PurchaseStatuses } from '../../../constants/purchases.constants';
import ProgressButton from '../../../components/progressButton';
import { getUpcomingSubscription } from '../../../utils/purchase.utils';
import logger from 'js-logger';

const UpcomingSubscription = ({ subscriptions, onUndoComplete }) => {
  const [ undoComplete, setUndoComplete ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ errorMsg, setErrorMsg ] = useState(null);

  const {
    outerSubscription,
    subscriptionDetails
  } = getUpcomingSubscription(subscriptions);

  if(outerSubscription == null) {
    return null;
  }

  const undoChanges = async () => {
    setIsLoading(true);
    try {
      let updatingItemIdx;
      outerSubscription.purchaseItems.forEach((item, idx) => {
        if (item.status === PurchaseStatuses.updateRequested) {
          updatingItemIdx = idx;
        }
      });
      await UndoFutureSubscriptionChanges(outerSubscription.id, updatingItemIdx);
      onUndoComplete();
      setUndoComplete(true);
    } catch (e) {
      setErrorMsg('Something went wrong with undo the change of plans. Please try again.');
      logger.error('Error undoing cancellation request', e);
    }
    setIsLoading(false);
  };

  const { publicName } = products[subscriptionDetails.productId];
  return <Grid container justify='center'>
    <Grid item xs={12} sm={8} md={8}>
      <Card style={styles.card}>
        <Grid container alignItems='center'>
          <Typography>
            {undoComplete 
              ? 'Your plan will no longer change on the next billing cycle'
              : `Your subscription will switch to ${publicName} ` +
                `(${subscriptionDetails.product.billingPeriod}) at end of billing cycle`
            }
          </Typography>
          {!undoComplete && <ProgressButton 
            variant='outlined' 
            color='primary' 
            style={styles.button} 
            onClick={undoChanges}
            showProgress={isLoading}
          >
            Undo Change of Plans
          </ProgressButton>
          }
        </Grid>
        <Typography color='error'>{errorMsg}</Typography>
      </Card>
    </Grid>
  </Grid>;
};

export default UpcomingSubscription;
