import makeStyles from '@material-ui/core/styles/makeStyles';
import Palette from '../../../../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  container: {
    cursor: 'pointer',
    margin: '5px',
    padding: '5px',
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: Palette.black,
    '&:hover': {
      backgroundColor: Palette.lightBlue
    }
  },
  selected: {
    borderWidth: '2px',
    borderColor: Palette.blueGreen
  }
}));

export default useStyles;
