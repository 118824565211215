import React, { useState, useMemo, useEffect } from 'react';
import useStyles from './styles';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import ErrorSnackbar from '../../../../../components/errorSnackbar';
import ProgressButton from '../../../../../components/progressButton';

import { 
  PatchSyncRecord, 
  DeleteSyncRecord
} from '../../../../../network/captureEventRequests';

import RecordRow from './../recordRow';
import Logger from 'js-logger';


function MergeDialog({
  open = false,
  syncId,
  eventId,
  selectedRecords,
  records,
  syncAttemptData,
  onClose,
  columns,
  onRecordMerged
}) {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [swap, setSwap] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSwap(false);
    setLoading(false);
  }, [open]);

  const recordsToMerge = useMemo(() => {
    return selectedRecords.map((recordId) => {
      return records.find(({ id }) => id === recordId);
    });
  }, [records, selectedRecords]);

  const firstRecord = recordsToMerge[swap ? 1 : 0];
  const overwriteRecord = recordsToMerge[swap ? 0 : 1];

  const mergedRecord = useMemo(() => {
    if (!firstRecord || !overwriteRecord) return null;

    const newRecord = { ...overwriteRecord };
    if (firstRecord.motionId) {
      newRecord.motionId = firstRecord.motionId;
      newRecord.motion = firstRecord.motion;
    } else if (firstRecord.launchMonitorId) {
      newRecord.launchMonitorId = firstRecord.launchMonitorId;
      newRecord.launchMonitorData = firstRecord.launchMonitorData;
      newRecord.rawData = firstRecord.rawData;
    }
    return newRecord;
  }, [firstRecord, overwriteRecord]);

  const swapOrder = () => setSwap(prevSwap => !prevSwap);

  const startMerging = async () => {
    setLoading(true);
    let updatedPatch = null;
    try {
      const operations = [
        {
          path: '/motionId',
          op: 'replace',
          value: mergedRecord.motionId
        },
        {
          path: '/launchMonitorId',
          op: 'replace',
          value: mergedRecord.launchMonitorId
        }
      ];
      updatedPatch = await PatchSyncRecord(eventId, syncId, mergedRecord.id, operations);
      const { motionId, launchMonitorId } = updatedPatch; 
      if (motionId) updatedPatch.motion = mergedRecord.motion;
      if (launchMonitorId) {
        updatedPatch.launchMonitorData = mergedRecord.launchMonitorData;
        updatedPatch.rawData = mergedRecord.rawData;
      }
      await DeleteSyncRecord(eventId, syncId, firstRecord.id);
      setLoading(false);
      onRecordMerged(updatedPatch, firstRecord.id);
      onClose();
    } catch (e) {
      if (updatedPatch) { // delete must have been the problem then
        Logger.error('Error after patching trying to delete first sync record', e);
        setError('Error: Record was merged successfully but orphan record was not deleted');
        setLoading(false);
        onRecordMerged(updatedPatch, null);
        onClose();
      } else { // patch record threw the exception
        Logger.error('Error merging two records in merge dialog with Patch', e);
        setError('There was an error syncing two records.');
        setLoading(false);
      }
    }
  };

  return <>
    <Dialog open={open} fullWidth>
      <DialogContent>
        <div className={classes.content}>
          <Button className={classes.button} color='primary' onClick={swapOrder}>Swap Order</Button>
          {
            firstRecord && overwriteRecord &&
            <TableContainer>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerStyle}>Synced</TableCell>
                    {
                      columns.map(({ header }) => {
                        return <TableCell 
                          key={header}
                          className={classes.headerStyle}
                        >
                          {header}
                        </TableCell>;
                      })
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RecordRow
                    syncAttemptData={syncAttemptData}
                    columns={columns} 
                    record={firstRecord} />

                  <TableRow>
                    <TableCell className={classes.rowMessage} colSpan={12}>
                      merges into
                    </TableCell>
                  </TableRow>

                  <RecordRow
                    syncAttemptData={syncAttemptData}
                    columns={columns} 
                    record={overwriteRecord} />

                  <TableRow>
                    <TableCell className={classes.rowMessage} colSpan={12}>
                      and will become
                    </TableCell>
                  </TableRow>
                  
                  <RecordRow 
                    syncAttemptData={syncAttemptData}
                    columns={columns} 
                    record={mergedRecord} />

                </TableBody>

              </Table>
            </TableContainer>
          }
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ProgressButton 
          disabled={loading}
          showProgress={loading}
          color='primary'
          variant='contained'
          onClick={startMerging}
        >
          Merge Records
        </ProgressButton>
        <Button 
          disabled={loading} 
          onClick={onClose} 
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>

    <ErrorSnackbar 
      message={error}
      onClose={() => setError(null)}
    />
  </>;
};

export default MergeDialog;