// Actions
const SET_SELECTED_METRICS = 'kdashboard/selectedGraphMetrics/set';
const ADD_SELECTED_METRICS = 'kdashboard/selectedGraphMetrics/add';
const REMOVE_SELECTED_METRICS = 'kdashboard/selectedGraphMetrics/remove';

// Reducer
const initialState = [];
export default function reducer(state = initialState, action = {}) {
  switch(action.type) {
    case SET_SELECTED_METRICS:
      return action.payload;
    case ADD_SELECTED_METRICS:
      return [...state, action.payload];
    case REMOVE_SELECTED_METRICS:
      return state.filter(metric => metric !== action.payload);
    default:
      return state;
  }
}

// Action Creators
export function setSelectedMetrics(metrics) {
  return {
    type: SET_SELECTED_METRICS,
    payload: metrics
  };
}

export function addSelectedMetric(metric) {
  return {
    type: ADD_SELECTED_METRICS,
    payload: metric
  };
}

export function removeSelectedMetric(metric) {
  return {
    type: REMOVE_SELECTED_METRICS,
    payload: metric
  };
}