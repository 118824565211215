import { makeStyles } from '@material-ui/core/styles';
import Palette from '../../../styles/palette';

export const pastDueColor = Palette.error;
export const stuckInProcessingColor = Palette.stripeRed;
export const lastDayColor = Palette.neutralLabelColor;

export default makeStyles(_ => ({
  container: {
    padding: '15px',
    marginTop: '15px'
  },
  title: {
    marginBottom: '15px'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  colorsStuckProcessing: {
    color: stuckInProcessingColor
  },
  colorsPastDue: {
    color: pastDueColor
  },
  colorsLastDay: {
    color: lastDayColor
  },
  statusCell: {
    padding: 0,
    minWidth: '175px'
  },
  iconSpacing: {
    marginRight: '15px'
  }
}));