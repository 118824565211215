import BodyPartsConstants from '../constants/bodyParts.constants';

const { pelvis: PELVIS, torso: TORSO, upperArm: UPPER_ARM, hand: HAND } = BodyPartsConstants;
const CORRECT_SEQUENCE = [PELVIS, TORSO, UPPER_ARM, HAND];

export const sequenceBodyPartsToNumbersFormatter = (sequenceStr) => {
  if (sequenceStr == null) return null;
  const sequenceArr = sequenceStr.split(',');
  // Convert to order of Pelvis-Torso-UpperArm-Hand and make 1-indexed
  return CORRECT_SEQUENCE.map(segment => sequenceArr.indexOf(segment) + 1).join('-');
};

