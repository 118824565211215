import React, { useState } from 'react';
import { 
  LinearProgress,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  CircularProgress
} from '@material-ui/core';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import round from 'lodash/round';
import { formatReadableDateLongTime } from '../../../../../../utils/formatting.utils';
import useVideoDownloadUrl from '../../../../../../utils/useVideoDownloadUrl';
import useStyles from './styles';

function MotionVideoGridItems({ 
  motions, 
  motionsLoading,
  motionsError,
  selectedMotionIds, 
  onSelectedMotionIdsChange 
}) {
  const classes = useStyles();

  const [previewMotion, setPreviewMotion] = useState(null);
  const { downloadUrl, loading: videoLoading, error: videoError } = 
    useVideoDownloadUrl(previewMotion?.video?.id);
  const motionMissingVideo = previewMotion != null && previewMotion?.video?.id == null;

  const getMotionSecondaryText = motion => {
    const handedness = motion.fullMotionMetrics.playerHandedness;
    const type = motion.motionType;
    const extraDetails = `${handedness} | ${type}`;
    const { ballspeedMph } = motion.launchMonitorData ?? {};
    return ballspeedMph == null ? 
      `${extraDetails}` : `${round(ballspeedMph, 1)} mph, ${extraDetails}`;
  };

  const getLabelId = motion => `motion-video-checklist-${motion.id}`;

  const onCheckboxChange = motionId => event => {
    const { checked } = event.target;
    onSelectedMotionIdsChange(checked
      ? [...selectedMotionIds, motionId]
      : selectedMotionIds.filter(x => x !== motionId));
  };

  return <Grid container spacing={2} className={classes.container}>
    <Grid item xs={4} className={classes.list}>
      {motionsLoading && <LinearProgress />}
      
      {!motionsLoading && motionsError && <Typography color='error'>
        Error loading motions with videos, please try again.
      </Typography>}

      <List>
        {motions.map(motion => <ListItem 
          key={motion.id}
          divider 
          selected={previewMotion?.id === motion.id}
        >
          <ListItemIcon>
            <Checkbox
              edge='start'
              checked={selectedMotionIds.includes(motion.id)}
              onChange={onCheckboxChange(motion.id)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': getLabelId(motion) }}
            />
          </ListItemIcon>
          <ListItemText
            id= {getLabelId(motion)}
            primary={formatReadableDateLongTime(motion.timestamp)}
            secondary={getMotionSecondaryText(motion)}
          />
          {motion.video != null && <ListItemSecondaryAction>
            <IconButton edge='end' onClick={() => setPreviewMotion(motion)}>
              <SlowMotionVideoIcon />
            </IconButton>
          </ListItemSecondaryAction>}
        </ListItem>)}
      </List>
    </Grid>
    <Grid item xs={8} className={classes.videoContainer}>
      {videoLoading && <CircularProgress />}

      {motionMissingVideo && <Typography>
        This motion does not have a video uploaded for it.
      </Typography>}

      {videoError && <Typography color='error'>
        Error loading video, please try again.
      </Typography>}

      {!videoLoading && !videoError && downloadUrl && <video 
        src={downloadUrl}
        controls
        className={classes.video}
      />}
    </Grid>
  </Grid>;
}

export default MotionVideoGridItems;
