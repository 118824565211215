import Palette from '../../styles/palette';

const styles = {
  outerContainer: {
    display: 'flex', 
    alignItems: 'center', 
    borderRadius: '5px', 
    height: '40px', 
    borderWidth: '1px', 
    borderStyle: 'solid',
    borderColor: Palette.lightGray,
    margin: '5px 0'
  },
  iconContainer: {
    padding: '0 8px', 
    backgroundColor: Palette.lightGray, 
    height: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    borderRadius: '5px 0 0 5px'
  },
  input: {
    outline: 'none', 
    border: 'none', 
    width: '90%',
    marginLeft: '10px',
    '&:disabled': { backgroundColor: 'white' },
    fontSize: '18px',
    height: '75%'
  },
  focused: { borderColor: Palette.darkGray },
  error: { borderColor: Palette.primaryRed },
  defaultSelect: { color: '#6f6f6f' },
  selectOption: { color: Palette.black }
};

export default styles;
