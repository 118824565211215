import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({ 
  iframeContainer: {
    width: '100%',
    height: '75vh',
    backgroundColor: 'black'
  },
  iframe: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black'
  }
}));

export default styles;
