import { useState, useCallback } from 'react';


/*
 This hook can be used to provide generate boiler plate
 needed for react when working with text field.

 All input params are optional. 
 
 If you provide a validation function it will be run when you call the 
 validation function that is returned in the return object.

 The function should take in a string and return a bool. The function will 
 be validated against the current value of the input and will set the inError property to true.

 To attach to an input element return the bind property.
 Example:
  const nameInput = useInput();
  return <TextField {...nameInput.bind}>;
*/
function useInput({
  initialValue = '',       // initial value of textfield
  trimValue = false,       // if true, automatically trims value on input change
  validation = () => true, // validation function to run when calling validate()
  validationMsg = ''      // stores a message that can be displayed if validation fails
} = {}){
  const [value, setValue] = useState(initialValue);
  const [inError, setInError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const clearValue = useCallback(() => setValue(''), []);
  
  const clearError = useCallback(() => {
    setInError(false);
    setErrorMsg('');
  }, []);

  const validate = useCallback(() => {
    clearError();
    const valid = validation(value);
    if (!valid) {
      setInError(true);
      setErrorMsg(validationMsg);
    }
    return valid;
  // We won't know the validation function passed in and
  // don't necessarily want to have all callers wrap with useCallback
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return {
    value,        // current value of 
    setValue,     // method that changes current value
    clearValue,   // clears out current value
    validate,     // call validation function against current value
    inError,      // bool indicating validation failed
    errorMsg,     // message to display when inError
    clearError,   // reset inError to false
    bind: {       // spread on input element to bind
      value,
      onChange: (e) => {
        let val = trimValue ? e.target.value.trim() : e.target.value;
        setValue(val);
      },
      error: inError,
      helperText: inError && validationMsg
    }
  };
}

export default useInput;