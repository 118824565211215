import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const FilterBoxItem = ({ count, title, gradientStart, gradientEnd, isSelected }) => {
  const classes = useStyles();
  const gradientKey = (gradientStart + gradientEnd).replace('#', '');
  return (
    <div>
      <div className={classNames(classes.svgBox, { [classes.hidden]: !isSelected })}>
        <svg width='100%' height='100%' viewBox='0 0 61 78' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path 
            d='M57.0359 67.5378C56.7496 73.1204 52.1396 77.5 46.5496 77.5L14.4504 77.5C8.86036 77.5 4.25042 73.1204 3.96413 67.5378L1.09233 11.5378C0.784517 5.53542 5.56833 0.500005 11.5785 0.500004L49.4214 0.500001C55.4317 0.5 60.2155 5.53542 59.9077 11.5378L57.0359 67.5378Z' 
            stroke={`url(#${gradientKey})`}
          />
          <defs>
            <linearGradient id={gradientKey} x1='30.5' y1='78' x2='30.5' y2='2.66639e-06' gradientUnits='userSpaceOnUse'>
              <stop stopColor={gradientStart}/>
              <stop offset='1' stopColor={gradientEnd} stopOpacity='0.63'/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <Typography className={classNames(classes.filterCount, { [classes.boldText]: isSelected })}>
        {count}
      </Typography>
      <Typography variant='caption' className={classNames(classes.filterCaption, { [classes.boldText]: isSelected })}>
        { // put a line break between each word
          title.split(' ').map((word, idx) => <span key={idx}>{word}<br/></span>)
        }
      </Typography>
    </div>   
  );
};

FilterBoxItem.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // the count to show at the top of the box
  title: PropTypes.string.isRequired, // each word will appear on a different line
  gradientStart: PropTypes.string.isRequired, // gradient color to start
  gradientEnd: PropTypes.string.isRequired, // gradient color to end 
  isSelected: PropTypes.bool // displays the gradient border if true
};

export default FilterBoxItem;