import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { 
  Grid, 
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import useNetworkRequest from '../../../../../../network/useNetworkRequest';
import addDays from 'date-fns/addDays';
import { 
  GetGoogleDriveTemplates,
  GetTrainingPlanTemplates,
  CreateTrainingPlan,
  GetTrainingPlan,
  GetTrainingPlans
} from '../../../../../../network/trainingPlanRequests';
import BodyParts from 'constants/bodyParts.constants.js';
import { SEGMENT_FOCUS } from 'constants/trainingPlans.constants';
import { PLAN_TYPES } from '../../../../../../constants/trainingPlans.constants';
import { NOTE_TYPES } from '../../../../../../constants/noteStatuses.constants';
import pick from 'lodash/pick';
import orderBy from 'lodash/orderBy';
import format from 'date-fns/format';
import Logger from 'js-logger';
import ProgressButton from '../../../../../../components/progressButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useStyles from './styles';
import EditingTrainingPlanWeeklyNotes from '../editingTrainingPlanWeeklyNotes';
import { CaptureEventResultAvailabilityStatus } from 'constants/captureEventResult.constants';
import { PatchCaptureEventResult } from 'network/captureEventRequests';
import { v4 as uuidv4 } from 'uuid';

const BODY_PARTS_TO_PROPERTIES = {
  [BodyParts.pelvis]: 'pelvisFocus',
  [BodyParts.torso]: 'torsoFocus',
  [BodyParts.upperArm]: 'upperArmFocus',
  [BodyParts.hand]: 'handFocus'
};

const DAYS_BETWEEN_NOTES = 7;

function CreateTrainingPlanComponent({ 
  eventUser,
  captureResult,
  selectedBodySegments,
  onResultUpdate = () => {}
}) {

  const classes = useStyles();
  const { user } = eventUser;

  const [creatingPlan, setCreatingPlan] = useState(false);
  const [creatingPlanError, setCreatingPlanError] = useState('');

  const [gTemplates, gTemplatesLoading, gTemplatesError] = 
    useNetworkRequest([], 'GetGoogleDriveTemplates', GetGoogleDriveTemplates);
  
  const [regularTemplates, regularTemplatesLoading, regularTemplatesError] = 
    useNetworkRequest([], 'GetTrainingPlanTemplates', GetTrainingPlanTemplates);
  const templates = useMemo( _ => [...gTemplates, ...regularTemplates], 
    [gTemplates, regularTemplates]);
  const templatesLoading = gTemplatesLoading || regularTemplatesLoading;
  const templatesError = gTemplatesError || regularTemplatesError;

  const [templatesForFocusSegment, setTemplatesForFocusSegment] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [editingProposedTrainingPlan, setEditingProposedTrainingPlan] = useState(false);

  const toggleEditingProposedTrainingPlanAndResetTemplate = () => {
    setEditingProposedTrainingPlan(prev => {
      const newValue = !prev;
      if (newValue === false) {
        // This will trigger a refetching when done editing to reset the template.
        setSelectedTemplate({ ...selectedTemplate });
      }
      return newValue;
    });
  };
  
  const getProposedTrainingPlan = useCallback(async cancelSource => {
    if (selectedTemplate?.id == null) return null;
    const template = await GetTrainingPlan(selectedTemplate.id, cancelSource);
    return template;
  }, [selectedTemplate]);

  const [
    proposedTrainingPlan, 
    loadingProposedTrainingPlan,
    errorLoadingProposedTrainingPlan,
    setProposedTrainingPlan
  ] = useNetworkRequest([], 'getProposedTrainingPlan', getProposedTrainingPlan);

  const getTrainingPlansForUser = useCallback(async cancelToken => {
    const params = {
      userId: user.userId,
      trainingPlanType: PLAN_TYPES.googleDriveTrainingPlan,
      subresources: [
        'TrainingPlanCaptureEventResults.CaptureEventResult.CaptureEventUser'
      ]
    };
    return await GetTrainingPlans(params, cancelToken);
  }, [user]);

  const [trainingPlans, trainingPlansLoading, trainingPlansError, setTrainingPlans] =
    useNetworkRequest([], 'GetTrainingPlansForUser', getTrainingPlansForUser);

  const loading = templatesLoading || trainingPlansLoading;
  const error = templatesError || trainingPlansError || errorLoadingProposedTrainingPlan;

  // Find if this is an existing training plan by checking if the current
  // result is included in the list of training plan capture event results
  const existingTrainingPlan = trainingPlans.find(
    x => x.initialCaptureEventResultId === captureResult.id
  );

  const existingTrainingPlanTemplate = existingTrainingPlan != null
    ? templates.find(x => x.id === existingTrainingPlan.templateId)
    : null;

  useEffect(() => {
    const templateMatches = template => {
      return Object.keys(BODY_PARTS_TO_PROPERTIES).every(bodyPart => {
        const property = BODY_PARTS_TO_PROPERTIES[bodyPart];
        const isSelected = selectedBodySegments.includes(bodyPart);
        const isFocused = template[property] === SEGMENT_FOCUS.primary;
        return isSelected === isFocused;
      });
    };
    
    // This makes sure to keep the same order of templates on load
    const orderedTemplates = loading || error
      ? null
      : orderBy(templates, ['name', 'id'], ['asc', 'asc']).filter(templateMatches);
    
    setTemplatesForFocusSegment(orderedTemplates);
  }, [loading, error, templates, selectedBodySegments]);

  useEffect(() => {
    if (templatesForFocusSegment != null && templatesForFocusSegment.length > 0) {
      setSelectedTemplate(templatesForFocusSegment[0]);
    } else {
      setSelectedTemplate(null);
    }
  }, [templatesForFocusSegment]);

  const createPlanFromTemplate = template => {
    const now = new Date();
    const bodySegments = selectedBodySegments.join(', ');
    const todaysDate = format(now, 'M/d');
    const planName = `${user.fullName} PDP ${bodySegments} ${todaysDate}`;

    const trainingPlan = {
      type: PLAN_TYPES.googleDriveTrainingPlan,
      userId: user.userId,
      endTimestamp: addDays(now, template.notes.length * DAYS_BETWEEN_NOTES),
      templateId: template.id,
      pelvisFocus: template.pelvisFocus,
      torsoFocus: template.torsoFocus,
      upperArmFocus: template.upperArmFocus,
      handFocus: template.handFocus,
      name: planName,
      initialCaptureEventResultId: captureResult.id,
      trainingPlanCaptureEventResults: [{
        id: uuidv4(),
        captureEventResultId: captureResult.id
      }]
    };

    const templateNotes = orderBy(template.notes, ['sequenceOrder'], ['asc']);

    trainingPlan.notes = templateNotes.map((note, i) => ({
      ...note,
      id: undefined,
      authorUserId: undefined,
      creationTimestamp: undefined,
      editedTimestamp: undefined,
      captureEventUserId:  eventUser?.id ?? undefined,
      userId: user.userId,
      dateToSend: addDays(now, i * DAYS_BETWEEN_NOTES),
      noteType: NOTE_TYPES.googleDrivePlanSection,
      assignedDrills: note.assignedDrills.map(assignedDrill => 
        ({ ...assignedDrill, id: undefined, drill: undefined })),
      noteVideos: note.noteVideos.map(video => pick(video, ['videoId'])),
      noteImages: note.noteImages.map(image => pick(image, ['imageId']))
    }));

    return trainingPlan;
  };

  const [confirmPlanCreation, setConfirmPlanCreation] = useState(false);

  const attemptToCreatePlan = async () => {

    const appAvailability = captureResult?.mobileAppAvailabilityStatus;

    if (appAvailability === CaptureEventResultAvailabilityStatus.notAvailable) {
      setConfirmPlanCreation(true);
    } else {
      createPlan();
    }
  };

  const createPlan = async () => {
    if (creatingPlan || !selectedTemplate || loadingProposedTrainingPlan) {
      return;
    }

    setCreatingPlan(true);
    setCreatingPlanError('');
    try {
      const newTrainingPlan = 
        await CreateTrainingPlan(createPlanFromTemplate(proposedTrainingPlan));
      setTrainingPlans(prev => [...prev, newTrainingPlan]);
      setConfirmPlanCreation(false);
    } catch (e) {
      setCreatingPlanError('There was an error creating a training plan');
      Logger.error('Error creating a training plan from a template', e);
    }
    setCreatingPlan(false);
  };

  const makeAvailableAndThenCreatePlan = async () => {
    if (creatingPlan || !selectedTemplate || loadingProposedTrainingPlan) return; 
    setCreatingPlan(true);
    setCreatingPlanError('');

    let patchError = false;
    try {
      const updatedResult = await PatchCaptureEventResult(
        eventUser?.captureEventId,
        eventUser?.id,
        captureResult.id,
        [{
          op: 'replace',
          path: '/mobileAppAvailabilityStatus',
          value: CaptureEventResultAvailabilityStatus.available
        }]
      );
      onResultUpdate(updatedResult);
    } catch (e) {
      setCreatingPlanError('There was an error updating availability status');
      Logger.error('Error updating availability status for result on create plan', e);
      patchError = true;
    }

    // skip creating a plan if there was an error making the result available
    if (!patchError) {
      try {
        const newTrainingPlan = 
          await CreateTrainingPlan(createPlanFromTemplate(proposedTrainingPlan));
        setTrainingPlans(prev => [...prev, newTrainingPlan]);

        // only remove dialog if both calls to the api succeed
        setConfirmPlanCreation(false);
      } catch (e) {
        setCreatingPlanError('Result is now available but the plan could not be created');
        Logger.error('Error creating a training plan ' + 
          'from a template after editing availability', e);
      }
    }

    // regardless of errors we still need to stop loading animations
    setCreatingPlan(false);
  };

  const handleTemplateSelected = e => {
    const templateId = e.target.value;
    const selectedTemplate = templates.find(x => x.id === templateId);
    setSelectedTemplate(selectedTemplate);
  };

  return <Grid container direction='column' alignItems='center'>
    <Grid item>
      <Typography color='error'>
        {error && 'There was an error loading templates and/or training plans'}
      </Typography>
    </Grid>
    <Grid item>
      <Typography color='error'>
        {selectedBodySegments.length > 0 
          && !selectedTemplate 
          && !existingTrainingPlan
          && 'A template for the body segment(s) selected does not exist.'}
      </Typography>
    </Grid>
    <Grid item>
      <Typography color='error'>{ creatingPlanError }</Typography>
    </Grid>
    <Grid item>
      {
        !existingTrainingPlan
          ? <div className={classes.createContainer}>
            {
              selectedTemplate != null && templatesForFocusSegment != null && 
                !editingProposedTrainingPlan && 
                <FormControl className={classes.dropdown}>
                  <InputLabel id='pdp-training-plan-select-label'>
                    Template Being Used
                  </InputLabel>
                  <Select
                    labelId='pdp-training-plan-select-label'
                    value={selectedTemplate?.id}
                    onChange={handleTemplateSelected}
                  >
                    {templatesForFocusSegment.map(({ id, name }) =>
                      <MenuItem value={id} key={id}>{name}</MenuItem>)}
                  </Select>
                </FormControl>
            }
            {editingProposedTrainingPlan && <Typography variant='p' className={classes.trainingPlanName}> 
              {proposedTrainingPlan?.name} 
            </Typography>}
            <ProgressButton
              showProgress={creatingPlan}
              disabled={
                !selectedTemplate 
                || !selectedBodySegments.length 
                || loading 
                || loadingProposedTrainingPlan 
              }
              color='primary'
              variant='contained'
              onClick={attemptToCreatePlan}
            >
              Create Plan
            </ProgressButton>
            {!loadingProposedTrainingPlan && proposedTrainingPlan != null && 
              <Button onClick={toggleEditingProposedTrainingPlanAndResetTemplate}>
                {!editingProposedTrainingPlan && 'Edit Plan'}
                {editingProposedTrainingPlan && 'Discard Changes'}
              </Button>}
          </div>
          : <div className={classes.completeContainer}>
            <div className={classes.planLinkContainer}>
              <CheckCircleIcon className={classes.icon} color='primary'/>
              <a href={existingTrainingPlan.link}
                target='_blank' 
                rel='noopener noreferrer'
              >
                GO TO TRAINING PLAN 
                ({existingTrainingPlanTemplate?.name ?? 'Template Name Not Found'})
              </a>
            </div>
          </div>
      }
    </Grid> 
    <Grid 
      item 
      container 
      xs={12}
      className={classes.editTrainingPlanContainer}
    >
      {editingProposedTrainingPlan &&
        <EditingTrainingPlanWeeklyNotes
          setTrainingPlan={setProposedTrainingPlan}
          trainingPlan={proposedTrainingPlan}
        />
      }
    </Grid>
    <Dialog 
      onClose={() => setConfirmPlanCreation(false)} 
      disableBackdropClick={creatingPlan}
      aria-labelledby='confirmation-dialog-mobile-app-results' 
      open={confirmPlanCreation}
    >
      <DialogTitle onClose={() => setConfirmPlanCreation(false)}>
        Result Is Not Available to View in App
      </DialogTitle>
            
      <DialogContent>
        <div>
          <Typography>
            Do you want to make that result available in the mobile app
            when you create the plan?
          </Typography>
          {creatingPlanError && <Typography color='error'>
            {creatingPlanError}
          </Typography>}
        </div>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          disabled={creatingPlan}
          onClick={() => setConfirmPlanCreation(false)} 
          color='primary'
        >
          Cancel
        </Button>

        <div>
          <ProgressButton 
            onClick={createPlan} 
            color='secondary' 
            showProgress={creatingPlan} 
            disabled={creatingPlan}
          >
            Just Create Plan
          </ProgressButton>

          <ProgressButton 
            onClick={makeAvailableAndThenCreatePlan} 
            color='primary' 
            showProgress={creatingPlan} 
            disabled={creatingPlan}
          >
            Make Available and Create Plan
          </ProgressButton>
        </div>
      </DialogActions>
    </Dialog>
  </Grid>;
};

export default CreateTrainingPlanComponent;