/* eslint-disable */
export const SEGMENTS = {
  pelvis: 'pelvis',
  torso: 'torso',
  arm: 'arm',
  hand: 'hand',
  body: 'body',
  ball: 'ball'
};

export const SEGMENTS_LIST = [
  SEGMENTS.pelvis,
  SEGMENTS.torso,
  SEGMENTS.arm,
  SEGMENTS.hand,
  SEGMENTS.body,
  SEGMENTS.ball
];

export const PEAK_SPEED_KEYS = {
  [SEGMENTS.pelvis]: 'peakSpeedPelvis',
  [SEGMENTS.torso]: 'peakSpeedTorso',
  [SEGMENTS.arm]: 'peakSpeedUpperArm',
  [SEGMENTS.hand]: 'peakSpeedHand',
};

export const MAX_SPEED_KEYS = {
  [SEGMENTS.pelvis]: 'maxSpeedPelvis',
  [SEGMENTS.torso]: 'maxSpeedTorso',
  [SEGMENTS.arm]: 'maxSpeedUpperArm',
  [SEGMENTS.hand]: 'maxSpeedHand',
};
