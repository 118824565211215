import { useCallback } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import orderBy from 'lodash/orderBy';
import useNetworkRequest from '../../../../../network/useNetworkRequest';
import { GetEventUsers } from '../../../../../network/captureEventRequests';

function EventUsersAutocomplete({ captureEventId, selectedEventUser, setSelectedEventUser }) {
  const eventUsersRequest = useCallback(async cancelSource => {
    const fetchedUsers = captureEventId == null 
      ? { items: [] }
      : await GetEventUsers(captureEventId, cancelSource);
    const orderedEventUsers = orderBy(
      fetchedUsers.items, 
      u => `${u.captureEventStatuses.length > 0 ? '1' : '0'} ` + 
        `${u.user.firstName} ${u.user.lastName}`); // alphabetical with uncaptured users first
    return orderedEventUsers.map(eventUser => ({ 
      ...eventUser, 
      group: eventUser.captureEventStatuses?.length > 0
        ? 'Captured'
        : 'Uncaptured' 
    }));
  }, [captureEventId]);
  const [eventUsers, loading] = useNetworkRequest([], 'GetCaptureEventUsers', eventUsersRequest);

  return <Autocomplete 
    id={`${captureEventId}-event-users`}
    disabled={captureEventId == null}
    loading={loading}
    fullWidth
    autoHighlight
    options={eventUsers}
    onChange={(_, value) => setSelectedEventUser(value)}
    value={selectedEventUser}
    getOptionLabel={option => `${option.user?.firstName} ${option.user?.lastName}`}
    groupBy={option => option.group}
    renderInput={params => <TextField 
      {...params} 
      label='User' 
      variant='outlined'
      inputProps={{ ...params.inputProps, 'data-lpignore': true }}
    />}
  />;
}

export default EventUsersAutocomplete;
