import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { currentPlayer } from './players';
import players from './players';
import { 
  currentSwingDetails, 
  currentSwingSummary, 
  currentKeyFrameData,
  currentMotionInfo
} from './swings';
import currentMode from './currentMode';
import motions from './motions';
import currentOrganization from './currentOrganization';
import notes from './notes';
import assessments from './assessments';
import motionAttributes from './motionAttributes';
import evaluations from './evaluations';
import evalReport from './evalReport';
import featurePermissions from './featurePermissions';
import actionPlan from './actionPlan';
import selectedGraphMetrics from './selectedGraphMetrics';
import teams from './teams';
import orgActionItems from './orgActionItems';
import orgActionsTaken from './orgActionsTaken';
import videoAnalyses from './videoAnalyses';
import analyzedMotionData from './analyzedMotionData';
import timeSyncStats from './timeSyncStats';
import captureEvents from './captureEvents';
import trainingPlan from './trainingPlan';
import playerPercentiles from './playerPercentiles';
import dataAnalysis from './dataAnalysis';

export default function rootReducer(history){
  return combineReducers({
    router: connectRouter(history),
    currentPlayer,
    players,
    evalReport,
    currentSwingDetails,
    currentSwingSummary,
    currentKeyFrameData,
    currentMotionInfo,
    currentMode,
    motions,
    evaluations,
    currentOrganization,
    notes,
    assessments,
    motionAttributes,
    featurePermissions,
    actionPlan,
    selectedGraphMetrics,
    teams,
    orgActionItems,
    orgActionsTaken,
    videoAnalyses,
    analyzedMotionData,
    timeSyncStats,
    captureEvents,
    trainingPlan,
    playerPercentiles,
    dataAnalysis
  });
};