import React, { useState } from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Paper
} from '@material-ui/core';
import round from 'lodash/round';
import useMotionsWithTag from '../useMotionsWithTag';
import SelectMetricsButton from '../selectMetricsButton';
import MathUtils from '../../../utils/math.utils';
import useStyles from './styles';

const MetricCell = ({ value }) => {
  const alpha = typeof value === 'number' ? value : 0;
  const classes = useStyles({ alpha });
  return <TableCell className={classes.metricCell}>{value}</TableCell>;
};

function CorrelationMatrix() {
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const motions = useMotionsWithTag();
  const classes = useStyles();

  function createKey(xMetric, yMetric) {
    return `${xMetric.label}-${yMetric.label}`;
  }

  function getCorrelationMatrixData() {
    const matrixData = {};
    for (let i = 0; i < selectedMetrics.length; i++) {
      for (let j = 0; j <= i; j++) {
        const xMetric = selectedMetrics[j];
        const yMetric = selectedMetrics[i];
        const key = createKey(xMetric, yMetric);
        if (j === i) {
          matrixData[key] = 1;
          continue;
        };
        const xData = Object.values(motions).map(xMetric.getValue);
        const yData = Object.values(motions).map(yMetric.getValue);

        const { r2 } = MathUtils.linearRegression(xData, yData);
        matrixData[key] = !isFinite(r2) ? '-' : round(r2, 4);
      }
    }
    return matrixData;
  }
  const matrixData = getCorrelationMatrixData();

  return <TableContainer component={Paper} className={classes.tableContainer}>
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant='h5'>Correlation Matrix</Typography>
      </Grid>
      <Grid item>
        <SelectMetricsButton onChange={setSelectedMetrics} selectedMetrics={selectedMetrics} />
      </Grid>

      <Grid item xs={12}>
        <Table size='small'>
          <TableBody>
            {selectedMetrics.map(rowMetric => <TableRow 
              key={`correlation=matrix-row-${rowMetric.label}`}
            >
              <TableCell className={classes.rowHeader}>
                <b>{rowMetric.label}</b>
              </TableCell>
              {selectedMetrics.map((colMetric) => <MetricCell
                key={`correlation-matrix-cell-${createKey(rowMetric, colMetric)}`}
                value={matrixData[createKey(colMetric, rowMetric)]}
              />)}
            </TableRow>)}
            <TableRow>
              <TableCell />
              {selectedMetrics.map(({ label }) => <TableCell 
                key={`correlation-matrix-col-head-${label}`}
              >
                <b>{label}</b>
              </TableCell>)}
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  </TableContainer>;
}

export default CorrelationMatrix;
