import { makeStyles } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import Palette from '../../../../../styles/palette';

export default makeStyles(() => ({
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: Palette.lightBlue
    },
    '& > *': {
      borderBottom: 'unset'
    }
  },
  attributeChip: {
    margin: '1px 2px',
    backgroundColor: amber[100]
  },
  boldText: {
    fontWeight: 'bold'
  }
}));