import Colors from '../../constants/colors.constants';

const styles = {
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputField: {
    maxWidth: '240px',
    margin: '5px 10px'
  },
  formInputBody: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    margin: '10px 10px'
  },
  iconButton: {
    color: 'white',
    background: '#3e50b5',
    margin: 10,
    padding: 5,
    borderRadius: '50%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)',
    '&:hover': { background: '#303f9f' }
  },
  formLabel: { color: Colors.lightGray },
  inputFile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1
  },
  inputLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  errorBorder: { 
    border: '1px solid red' 
  },
  button: {
    textTransform: 'none',
    margin: '5px 10px'
  },
  italic: {
    fontStyle: 'italic'
  },
  grayText: {
    color: 'gray'
  },
  header: {
    width: '100%', 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    marginLeft: '20px'
  },
  dialogActions: {
    display: 'flex', 
    justifyContent: 'center' 
  }
};

export default styles;
