import makeStyles from '@material-ui/core/styles/makeStyles';

const useClasses = makeStyles(() => ({
  dialogContent: { 
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'column' 
  },
  icon: { 
    color: 'green', 
    fontSize: 50 
  }
}));

export default useClasses;
