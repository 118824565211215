import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  fullWidthPadded: {
    width: '100%',
    margin: '10px 0'
  }
}));

export default useStyles;
