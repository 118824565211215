import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import styles from './styles';
import { withStyles } from '@material-ui/styles';

const SwingFlaws = ({ allFlaws, motionFlaws, updateFlaw, isDisabled, classes }) => {
  return (
    <Card className={classes.container}>
      <CardContent>
        <Grid container justify='space-between'>
          <Typography variant='h4'>Swing Flaws</Typography>
        </Grid>
        <Divider className={classes.divider}/>
        <Grid container>
          {allFlaws.map(attribute => (
            <Grid item key={attribute.id} xs={12} sm={4} lg={3}>
              <FormControlLabel 
                label={attribute.name}
                control={
                  <Checkbox 
                    disabled={isDisabled}
                    checked={motionFlaws.includes(attribute.id)}
                    onChange={() => updateFlaw(attribute.id)}
                  />
                }
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

SwingFlaws.propTypes = {
  allFlaws: PropTypes.arrayOf(PropTypes.object).isRequired,
  motionFlaws: PropTypes.arrayOf(PropTypes.number),
  updateFlaw: PropTypes.func,
  deleteMotion: PropTypes.func,
  isDisabled: PropTypes.bool
};

SwingFlaws.defaultProps = {
  isDisabled: false
};

export default withStyles(styles)(SwingFlaws);