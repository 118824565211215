import { useCallback } from 'react';
import keyBy from 'lodash/keyBy';
import { GetMotionsForUserInCurrentOrg } from '../../../../network/motionRequests';
import useNetworkRequest from '../../../../network/useNetworkRequest';
import { 
  LAUNCH_MONITOR,
  FULL_MOTION_METRICS
} from '../../../../constants/motionSubresources.constants';
import { GetVideos } from '../../../../network/videoRequests';
import { HOSTING_PROVIDER, VIDEO_TYPE } from '../../../../constants/video.constants';

function useResultMotionsWithVideosRequest(userId, captureEventResultId) {
  const motionsWithVideosRequest = useCallback(async (cancelToken) => {
    const subresources = [LAUNCH_MONITOR, FULL_MOTION_METRICS];
    const params = { subresources, captureEventResultId };
    const motionResp = await GetMotionsForUserInCurrentOrg(userId, params, cancelToken);
    const motions = motionResp.items;

    const motionIds = motions.map(x => x.id);
    const videos = await GetVideos({ 
      userId, 
      motionIds, 
      hostingProvider: HOSTING_PROVIDER.azureBlobStorage, 
      type: VIDEO_TYPE.motion
    });

    const videosByMotionId = keyBy(videos, 'motionId');
    return motions.map(x => ({ ...x, video: videosByMotionId[x.id] }));
  }, [captureEventResultId, userId]);
  return useNetworkRequest([], 'motionsWithVideosGet', motionsWithVideosRequest);
}

export default useResultMotionsWithVideosRequest;