// import badge images here
import MVPImg from './../../../assets/badges/mvp.png';
import MVP2Img from './../../../assets/badges/mvp-runner-up.png';
import CareerYearImg from './../../../assets/badges/career-year.png';
import ComebackImg from './../../../assets/badges/comeback.png';
import Fire1Img from './../../../assets/badges/fire-1.png';
import Fire2Img from './../../../assets/badges/fire-2.png';
import Fire3Img from './../../../assets/badges/fire-3.png';
import PotentialImg from './../../../assets/badges/potential.png';
import AllStarImg from './../../../assets/badges/all-star.png';
import SlumpImg from './../../../assets/badges/slump.png';

import sortBy from 'lodash/sortBy';

// helper functions for badge checking
const badgeNotEarned = () => ({ earned: false });
const badgeEarned = (interval, timestamp) => {
  return {
    earned: true,
    interval,
    timestamp
  };
}; 
const gameIsOver = endDate => new Date() >= endDate;
const checkCertainPlace = (player, leaderboard, endDate, rank) => {
  if (!gameIsOver(endDate) || player.rank !== rank) return badgeNotEarned();
  return badgeEarned(leaderboard.numberOfIntervals - 1, endDate);
};

const checkCertainInningsScored = (player, minInnings) => {
  const { innings, latestEvents } = player;
  let scoredCounter = 0;
  for (let i = 0; i < innings.length; i++) {
    const inningScore = innings[i];
    if (inningScore) {
      scoredCounter++;
      if (scoredCounter >= minInnings) {
        return badgeEarned(i, latestEvents[i]);
      }
    }
  }
  return badgeNotEarned();
};

// this will return the earliest mention of this
const comebackMechanic = ({ innings, latestEvents }, streak) => {
  let currentStreak = 0;
  for (let i = 0; i < innings.length; i++) {
    const inningScore = innings[i];
    if (inningScore) {
      if (currentStreak >= streak) return badgeEarned(i, latestEvents[i]);
      currentStreak = 0;
    } else currentStreak++;
  }
  return badgeNotEarned();
};

// will return earliest moment this happened
const hotStreakMeachanic = ({ innings, latestEvents }, streak) => {
  let currentStreak = 0;
  for (let i = 0; i < innings.length; i++) {
    const inningScore = innings[i];
    currentStreak = inningScore ? currentStreak + 1 : 0;
    if (currentStreak >= streak) return badgeEarned(i, latestEvents[i]);
  }
  return badgeNotEarned();
};

const slumpStreak = ({ innings }, lb, startDate, curInning, streak) => {
  let currentStreak = 0;
  // do not include the current inning idx, give them a chance to break a slump
  for (let i = 0; i < Math.min(curInning, innings.length); i++) {
    const inningScore = innings[i];
    currentStreak = !inningScore ? currentStreak + 1 : 0;
    if (currentStreak >= streak) {
      const dateAchieved = new Date(startDate);
      dateAchieved.setDate(startDate.getDate() + (i * lb.daysPerInterval));
      return badgeEarned(i, dateAchieved);
    }
  }
  return badgeNotEarned();
};


// badges to be checked with all information available
export const BADGES = [
  { 
    id: 'MVP',
    name: 'MVP',
    description: 'The player who scored the most runs at the end of a game',
    img: MVPImg,
    priority: 1,
    checkFn: (player, lb, { endDate }) => checkCertainPlace(player, lb, endDate, 1)
  },
  {
    id: 'MVP2',
    name: 'MVP Runner-Up',
    description: 'The player who scored the second-most runs at the end of a game',
    img: MVP2Img,
    priority: 2,
    checkFn: (player, lb, { endDate }) => checkCertainPlace(player, lb, endDate, 2)
  },
  { 
    id: 'Potential',
    name: 'This Kid Has Potential',
    description: 'Any player who scores runs for at least 3 innings in a game',
    img: PotentialImg,
    priority: 6,
    checkFn: player => checkCertainInningsScored(player, 3)
  },
  { 
    id: 'AllStar',
    name: 'All-Star',
    description: 'Any player who scores runs for at least 6 innings in a game',
    img: AllStarImg,
    priority: 4,
    checkFn: player => checkCertainInningsScored(player, 6)
  },
  { 
    id: 'CareerYear',
    name: 'Career Year',
    description: 'Any player who scores runs for every inning in a game',
    img: CareerYearImg,
    priority: 3,
    checkFn: (player, lb) => checkCertainInningsScored(player, lb.numberOfIntervals)
  },
  { 
    id: 'Comeback',
    name: 'Comeback Kid',
    description: 'Any player who scores runs after not scoring for two or more innings in a row',
    img: ComebackImg,
    priority: 7,
    checkFn: player => comebackMechanic(player, 2)
  },
  { 
    id: 'HeatingUp',
    name: 'Heating Up',
    description: 'Any player who scores runs for 2 innings in a row',
    img: Fire1Img,
    priority: 10,
    checkFn: player => hotStreakMeachanic(player, 2)
  },
  { 
    id: 'HotStreak',
    name: 'Hot Streak',
    description: 'Any player who scores runs for 4 innings in a row',
    img: Fire2Img,
    priority: 9,
    checkFn: player => hotStreakMeachanic(player, 4)
  },
  { 
    id: 'OnFire',
    name: 'On Fire',
    description: 'Any player scores runs for 6 innings in a row',
    img: Fire3Img,
    priority: 8,
    checkFn: player => hotStreakMeachanic(player, 6)
  },
  { 
    id: 'Slump',
    name: 'Slump',
    description: 'Any player who does not score runs for 3 or more innings in a row',
    img: SlumpImg,
    priority: 11,
    checkFn: (player, lb, { startDate, curInterval }) => 
      slumpStreak(player, lb, startDate, curInterval, 3)
  }
];

export const BADGES_MAP = BADGES.reduce((acc, badge) => {
  acc[badge.id] = badge;
  return acc;
}, {});


export function makeBadgesFeed(
  scores, 
  leaderboard, 
  curInterval, 
  startDate, 
  endDate
) {
  const unsortedFeed = scores.reduce((feed, score) => {
    BADGES.forEach(badge => {
      const { checkFn, id } = badge;
      const { earned, interval, timestamp } = checkFn(
        score, leaderboard, { curInterval, startDate, endDate, scores }
      );
      if (earned) {
        feed.push({
          id: score.userId,
          badgeId: id,
          interval,
          timestamp
        });
      }
    });
    return feed;
  }, []);

  // it is essential that this uses sortBy becasue sortBy is a stable sort ensuring
  // that badges like MVP are at the top of the feed since they were always inserted first
  return sortBy(
    unsortedFeed, 
    [
      b => b.timestamp, 
      b => 100 - BADGES_MAP[b.badgeId].priority
    ]).reverse();
}