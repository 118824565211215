import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const focusColor = red[600];
const borderWidth = '0.4vw';

export default makeStyles(_ => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  body: {
    width: '500px',
    marginRight: '75px',
    position: 'relative',
    marginTop: '40px'
  },
  percentileLabelContainer: ({ top }) => ({
    position: 'absolute',
    left: 0,
    top: `${top}%`,
    padding: '10%',
    borderWidth: borderWidth,
    borderColor: 'black',
    borderStyle: 'solid',
    backgroundColor: 'white',
    zIndex: 10
  }),
  boxLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    transform: 'translateY(-100%)',
    paddingBottom: `calc(${borderWidth} - 2px)`
  },
  boxLabelText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '20px'
  },
  percentileValue: {
    fontSize: '48px'
  },
  outer: {
    maxHeight: '100%',
    maxWidth: '100%',
    aspectRatio: '1',
    position: 'relative'
  },
  inner: {
    width: '100%', 
    height: '100%', 
    display: 'flex', 
    flexDirection: 'row'
  },
  buffer: {
    width: '15%'
  },
  imageContainer: {
    height: '100%', 
    width: '70%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  },
  image: {
    maxHeight: '100%', 
    maxWidth: '100%'
  },
  labelsContainer: {
    width: '15%', 
    height: '100%', 
    position: 'relative'
  },
  focusBorder: {
    borderColor: `${focusColor} !important`
  }
}));