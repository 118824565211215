// Colors
const yellow200 = '#FFF59D';
const deepOrange600 = '#F4511E';
const lime300 = '#DCE775';
const lightGreen500 = '#8BC34A';
const teal700 = '#00796B';
const cyan900 = '#006064';
const blueGrey50 = '#ECEFF1';
const blueGrey300 = '#90A4AE';
const blueGrey700 = '#455A64';
const grey900 = '#212121';

const red = '#FF5656';
const green = '#01DB3E';
const lightBlue = '#2E9BFF';
const brown = '#CBA977';

// These color scale is coming from the code within victory charts.
// It will allow lines on the graph to match the legend colors
// if no colors are provided by callers.
export const DefaultColorScale = [
  deepOrange600,
  yellow200,
  lime300,
  lightGreen500,
  teal700,
  cyan900,
  blueGrey50,
  blueGrey300,
  blueGrey700,
  grey900
];

export const TimeSeriesColorScale = [
  red,
  green,
  lightBlue,
  brown,
  teal700,
  blueGrey300,
  cyan900,
  lime300,
  grey900,
  deepOrange600,
  blueGrey700
];