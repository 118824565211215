import { unit } from 'mathjs';
import round from 'lodash/round';

export default class UnitConverter {
  static convert = (val, from, to) => {
    if (val == null) return null;
    return unit(val, from).toNumber(to);
  }

  static inToCm = (inches) => {
    if (inches == null) return null;
    return unit(inches, 'inch').toNumber('cm');
  }

  static cmToIn = (cm) => {
    if (cm == null) return null;
    return unit(cm, 'cm').toNumber('in');
  }

  static kgToLb = (kg) => {
    if (kg == null) return null;
    return unit(kg, 'kg').toNumber('lb');
  }

  static lbToKg = (lb) => {
    if (lb == null) return null;
    return unit(lb, 'lb').toNumber('kg');
  }

  static mphToMetersPerSecond = (mph) => {
    if (mph == null) return null;
    return unit(mph, 'mi/h').toNumber('m/s');
  }

  static metersPerSecondToMph = (ms) => {
    if (ms == null) return null;
    return unit(ms, 'm/s').toNumber('mi/h');
  }
}

export const ConvertSubresourcesForMotions = motions => {
  return motions.map(motion => {
    const { hittingDeviceSensor } = motion;
    if (hittingDeviceSensor && hittingDeviceSensor.deviceSpeedMetersPerSecond != null) {
      const deviceSpeedMph = round(UnitConverter.metersPerSecondToMph(
        hittingDeviceSensor.deviceSpeedMetersPerSecond), 5);
      return { ...motion, hittingDeviceSensor: { 
        ...hittingDeviceSensor, 
        deviceSpeedMph
      }};
    }
    return motion;
  });
};
