import React from 'react';

import MobileDependencies from '../../utils/softwareLicenses';
import SoftwareLicense from '../../components/softwareLicense';
import { Typography, Divider } from '@material-ui/core';

export default class LicensePage extends React.Component {
  render() {
    return (
      <div>
        <Typography variant='h5'>KGO Open Source Licenses</Typography>
        {MobileDependencies.map(dep => (
          <div>
            <SoftwareLicense
              dependencyName={dep.packageName}
              copyright={dep.copyright}
              url={dep.url}
              licenseName={dep.license.name}
              licenseText={dep.license.text}
            />
            <Divider varient='fullWidth'/>
          </div>
        ))}
      </div>
    );
  }
}