import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { getGraphKeys } from './rechartsGraph.utils';
import SelectPlayers from './selectPlayers';
import { TimeSeriesColorScale } from '../../../components/lineGraph/lineGraph.constants';
import ChartWithLegend from './chartWithLegend';
import useUsersMotionData from './useUsersMotionData';
import useStyles from './styles';
import { PERMISSIONS } from '../../../constants/permissions.constants';
import MotionFilterSelector from './motionFilterSelector';

function GraphSelect({ value, items, onChange }) {
  return <FormControl>
    <Select value={value} onChange={onChange}>
      {items.map(item => <MenuItem value={item} key={item.dataKey}>
        {item.name}
      </MenuItem>)}
    </Select>
  </FormControl>;
}

function Analytics({ location }) {
  const { currentPlayer, players, featurePermissions } = useSelector(({ 
    currentPlayer, 
    players, 
    featurePermissions 
  }) => ({ currentPlayer, players, featurePermissions }));
  const canAccessThirdPartyData = featurePermissions.includes(PERMISSIONS.canInputThirdPartyData);
  const canAccessLMData = featurePermissions.includes(PERMISSIONS.canHandleLaunchMonitorData);
  const playerIdMap = keyBy(players, 'userId');
  
  const graphKeys = useMemo(() => getGraphKeys(canAccessThirdPartyData, canAccessLMData), 
    [canAccessThirdPartyData, canAccessLMData]);
  const graphDataKeyMap = keyBy(graphKeys, 'dataKey');

  const { pathname, search } = location;
  const query = queryString.parse(search);

  const initialX = (query.xAxis != null && graphDataKeyMap[query.xAxis] != null)
    ? query.xAxis 
    : graphKeys[0].dataKey;
  const initialY = (query.yAxis != null && graphDataKeyMap[query.yAxis] != null)
    ? query.yAxis 
    : graphKeys[2].dataKey;

  const initialCompareTo = query.compareTo == null ? [] : Array.isArray(query.compareTo)
    ? query.compareTo 
    : [query.compareTo];
  const initialNumMotions = query.laterThan == null && query.earlierThan == null
    && query.numMotions == null ? 10 : query.numMotions;
  const [queryState, setQueryState] = useState({
    xAxis: initialX,
    yAxis: initialY,
    compareTo: initialCompareTo,
    laterThan: query.laterThan,
    earlierThan: query.earlierThan,
    numMotions: initialNumMotions
  });

  useEffect(() => {
    const newUrl = pathname + '?' + queryString.stringify(queryState, { skipNull: true });
    window.history.replaceState(queryState, '', newUrl);
  }, [queryState, pathname]);

  const classes = useStyles();
  
  const playerSelectionOptions = sortBy(
    players.filter(player => player.userId !== currentPlayer.userId),
    player => `${player.lastName} ${player.firstname}`);

  const selectedPlayers = queryState.compareTo
    .map(id => playerIdMap[id])
    .filter(x => x != null);
  const setSelectedPlayers = (players) => setQueryState(prev => ({ 
    ...prev, 
    compareTo: players.map(({ userId }) => userId) 
  }));

  const data = useUsersMotionData(
    [currentPlayer, ...selectedPlayers], 
    queryState.numMotions,
    queryState.laterThan,
    queryState.earlierThan,
    canAccessThirdPartyData);
  const userData = useMemo(() => Object.keys(data)
    .filter(userId => !isEmpty(data[userId].user))
    .map((userId, idx) => ({ 
      user: data[userId].user,
      status: data[userId].status,
      graphData: data[userId].payload,
      color: TimeSeriesColorScale[idx % TimeSeriesColorScale.length]
    })),
  [data]);

  function updateQueryParams(key, value) {
    setQueryState(prev => ({ ...prev, [key]: value }));
  }
  
  return <div className={classes.container}>
    <div className={classes.header}>
      <SelectPlayers 
        label='Compare to Other Players'
        players={playerSelectionOptions}
        selectedPlayers={selectedPlayers}
        onChange={setSelectedPlayers}
      />
      <MotionFilterSelector 
        onChange={newValues => setQueryState(prev => ({ ...prev, ...newValues }))}
        laterThan={queryState.laterThan}
        earlierThan={queryState.earlierThan}
        numMotions={queryState.numMotions}
      />
    </div>

    <div className={classes.graph}>
      <div className={classes.axisSelectContainer}>
        <GraphSelect value={graphDataKeyMap[queryState.xAxis]} 
          items={graphKeys} 
          onChange={e => updateQueryParams('xAxis', e.target.value.dataKey)} />
        <Typography className={classes.vs}>vs</Typography> 
        <GraphSelect value={graphDataKeyMap[queryState.yAxis]} 
          items={graphKeys} 
          onChange={e => updateQueryParams('yAxis', e.target.value.dataKey)} />
      </div>

      <ChartWithLegend 
        xAxis={graphDataKeyMap[queryState.xAxis]}
        yAxis={graphDataKeyMap[queryState.yAxis]} 
        userData={userData}
      />
    </div>
  </div>;
}

export default Analytics;
