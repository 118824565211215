import { formatMMDDYY } from '../../../utils/formatting.utils';
import UnitConverter from '../../../utils/unitConverter.utils';

export const LOADING = 'loading';
export const ERROR = 'error';
export const COMPLETE = 'complete';

const UNITS = {
  degrees: '°',
  seconds: 's',
  degreesPerSecond: '°/s',
  milesPerHour: 'mph',
  metersPerSecond: 'm/s',
  gs: 'g',
  inches: 'in'
};

const MOBILITY_KEYS = [
  {
    dataKey: 'moverType',
    name: 'Mover Type',
    type: 'category'
  },
  {
    dataKey: 'lowerQuarterExternalRotationLeftDegrees',
    name: 'ER Left',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'lowerQuarterExternalRotationRightDegrees',
    name: 'ER Right',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'lowerQuarterInternalRotationLeftDegrees',
    name: 'IR Left',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'lowerQuarterInternalRotationRightDegrees',
    name: 'IR Right',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'scapularRetractionLeftInches',
    name: 'Scap Left',
    type: 'number',
    unit: UNITS.inches
  },
  {
    dataKey: 'scapularRetractionRightInches',
    name: 'Scap Right',
    type: 'number',
    unit: UNITS.inches
  },
  {
    dataKey: 'sideBendLeftDegrees',
    name: 'Side Bend Left',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'sideBendRightDegrees',
    name: 'Side Bend Right',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'tSpineRotationLeftDegrees',
    name: 'T-Spine Rotation Left',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'tSpineRotationRightDegrees',
    name: 'T-Spine Rotation Right',
    type: 'number',
    unit: UNITS.degrees
  }
];

const GRAPH_KEYS = [
  {
    dataKey: 'name',
    name: 'Name',
    type: 'category'
  },
  {
    dataKey: 'date',
    name: 'Date',
    type: 'number',
    tickFormatter: d => formatMMDDYY(d),
    tooltipFormatter: d => formatMMDDYY(d)
  },
  {
    dataKey: 'xFactorStretch',
    name: 'X-Factor Stretch',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'heelStrikeToContactTime',
    name: 'HS to CON Time',
    type: 'number',
    unit: UNITS.seconds
  },
  {
    dataKey: 'heelStrikeToFirstMoveTime',
    name: 'HS to FM Time',
    type: 'number',
    unit: UNITS.seconds
  },
  {
    dataKey: 'firstMoveToContactTime',
    name: 'FM to CON Time',
    type: 'number',
    unit: UNITS.seconds
  },
  {
    dataKey: 'maxXFactor',
    name: 'Max X-Factor',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'maxXFactorFrame',
    name: 'Max X-Factor Frame',
    type: 'number'
  },
  {
    dataKey: 'speedGainTorsoToPelvis',
    name: 'Peak Speed Gain: Torso to Pelvis',
    type: 'number'
  },
  {
    dataKey: 'speedGainArmToTorso',
    name: 'Peak Speed Gain: Arm to Torso',
    type: 'number'
  },
  {
    dataKey: 'speedGainHandToArm',
    name: 'Peak Speed Gain: Hand to Arm',
    type: 'number'
  },
  {
    dataKey: 'peakSpeedPelvis',
    name: 'Peak Speed Pelvis',
    type: 'number',
    unit: UNITS.degreesPerSecond
  },
  {
    dataKey: 'peakSpeedTorso',
    name: 'Peak Speed Torso',
    type: 'number',
    unit: UNITS.degreesPerSecond
  },
  {
    dataKey: 'peakSpeedUpperArm',
    name: 'Peak Speed Upper Arm',
    type: 'number',
    unit: UNITS.degreesPerSecond
  },
  {
    dataKey: 'peakSpeedHand',
    name: 'Peak Speed Hand',
    type: 'number',
    unit: UNITS.degreesPerSecond
  },
  {
    dataKey: 'peakSpeedFramePelvis',
    name: 'Peak Speed Frame Pelvis',
    type: 'number'
  },
  {
    dataKey: 'peakSpeedFrameTorso',
    name: 'Peak Speed Frame Torso',
    type: 'number'
  },
  {
    dataKey: 'peakSpeedFrameUpperArm',
    name: 'Peak Speed Frame Upper Arm',
    type: 'number'
  },
  {
    dataKey: 'peakSpeedFrameHand',
    name: 'Peak Speed Frame Hand',
    type: 'number'
  },
  {
    dataKey: 'xFactorAtHeelStrike',
    name: 'HS X Factor',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'pelvisBendAtHeelStrike',
    name: 'HS Pelvic Bend',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'pelvisRotationAtHeelStrike',
    name: 'HS Pelvic Rotation',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'pelvisSideBendAtHeelStrike',
    name: 'HS Pelvic Side Bend',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'torsoBendAtHeelStrike',
    name: 'HS Torso Bend',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'torsoRotationAtHeelStrike',
    name: 'HS Torso Rotation',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'torsoSideBendAtHeelStrike',
    name: 'HS Torso Side Bend',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'xFactorAtFirstMove',
    name: 'FM X Factor',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'pelvisBendAtFirstMove',
    name: 'FM Pelvic Bend',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'pelvisRotationAtFirstMove',
    name: 'FM Pelvic Rotation',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'pelvisSideBendAtFirstMove',
    name: 'FM Pelvic Side Bend',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'torsoBendAtFirstMove',
    name: 'FM Torso Bend',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'torsoRotationAtFirstMove',
    name: 'FM Torso Rotation',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'torsoSideBendAtFirstMove',
    name: 'FM Torso Side Bend',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'xFactorAtContact',
    name: 'CON X Factor',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'pelvisBendAtContact',
    name: 'CON Pelvic Bend',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'pelvisRotationAtContact',
    name: 'CON Pelvic Rotation',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'pelvisSideBendAtContact',
    name: 'CON Pelvic Side Bend',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'torsoBendAtContact',
    name: 'CON Torso Bend',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'torsoRotationAtContact',
    name: 'CON Torso Rotation',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'torsoSideBendAtContact',
    name: 'CON Torso Side Bend',
    type: 'number',
    unit: UNITS.degrees
  }
];

const LAUNCH_MONITOR_KEYS = [
  {
    dataKey: 'ballspeedMph',
    name: 'Exit Velocity',
    type: 'number',
    unit: UNITS.milesPerHour
  }
];

const FORCE_PLATE_KEYS = [
  {
    dataKey: 'zBackPeakValue',
    name: 'Z-Back Peak Value',
    type: 'number'
  },
  {
    dataKey: 'zFrontPeakValue',
    name: 'Z-Front Peak Value',
    type: 'number'
  },
  {
    dataKey: 'xBackPeakValue',
    name: 'X-Back Peak Value',
    type: 'number'
  },
  {
    dataKey: 'xFrontPeakValue',
    name: 'X-Front Peak Value',
    type: 'number'
  },
  {
    dataKey: 'torqueBackPeakValue',
    name: 'Torque Back Peak Value',
    type: 'number'
  },
  {
    dataKey: 'torqueFrontPeakValue',
    name: 'Torque Front Peak Value',
    type: 'number'
  },
  {
    dataKey: 'xTiming',
    name: 'X-Timing',
    type: 'number'
  },
  {
    dataKey: 'heelLoad',
    name: 'Heel Load',
    type: 'category'
  }
];

const HITTING_DEVICE_SENSOR_KEYS = [
  {
    dataKey: 'deviceSpeedMetersPerSecond',
    name: 'Device Speed',
    type: 'number',
    unit: UNITS.milesPerHour,
    tickFormatter: x => UnitConverter.metersPerSecondToMph(x),
    tooltipFormatter: x => UnitConverter.metersPerSecondToMph(x)
  },
  {
    dataKey: 'timeToImpactSeconds',
    name: 'Time To Impact',
    type: 'number',
    unit: UNITS.seconds
  },
  {
    dataKey: 'attackAngleDegrees',
    name: 'Attack Angle',
    type: 'number',
    unit: UNITS.degrees
  },
  {
    dataKey: 'rotationalAccelerationGs',
    name: 'Rotational Acceleration',
    type: 'number',
    unit: UNITS.gs
  },
  {
    dataKey: 'verticalBatAngleDegrees',
    name: 'Vertical Bat Angle',
    type: 'number',
    unit: UNITS.degrees
  }
];

export function getGraphKeys(includeThirdPartyData, includeLaunchMonitor) {
  let keys = [...GRAPH_KEYS, ...MOBILITY_KEYS];
  if (includeLaunchMonitor) {
    keys = [...keys, ...LAUNCH_MONITOR_KEYS];
  }
  if (includeThirdPartyData) {
    keys = [...keys, ...HITTING_DEVICE_SENSOR_KEYS, ...FORCE_PLATE_KEYS ];
  }
  return keys;
}
