import React, { useRef, useEffect, useCallback, useState } from 'react';
import PelvisIcon from 'assets/sliderDemo/PelvisIcon.png';
import TorsoIcon from 'assets/sliderDemo/TorsoIcon.png';
import ArmIcon from 'assets/sliderDemo/ArmIcon.png';
import HandIcon from 'assets/sliderDemo/HandIcon.png';
import { Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import useStyles from './styles';

const BODY_TO_IMAGE_MAPPING = {
  Pelvis: PelvisIcon,
  Torso: TorsoIcon,
  UpperArm: ArmIcon,
  Hand: HandIcon
};

const PERCENT_OF_IMAGES = 0.8;
const PERCENT_OF_ARROWS = 1 - PERCENT_OF_IMAGES;

function TypicalSequence({ captureEventResult }) {
  const typicalSequence = captureEventResult?.typicalPeakSpeedSequence?.split(',');

  const containerRef = useRef();

  const [containerWidth, setContainerWidth] = useState(containerRef?.current?.clientWidth);

  const calculateContainerWidth = useCallback(() => {
    setContainerWidth(containerRef?.current?.clientWidth);
  }, []);

  useEffect(() => {
    calculateContainerWidth();
    window.addEventListener('resize', calculateContainerWidth);
    return () => window.removeEventListener('resize', calculateContainerWidth);
  }, [calculateContainerWidth]);

  const widthOfImage = (containerWidth ?? 0) * PERCENT_OF_IMAGES * (1 / 4);
  const widthOfArrow = (containerWidth ?? 0) * PERCENT_OF_ARROWS * (1 / 3);

  const classes = useStyles({
    widthOfArrow,
    widthOfImage
  });

  return <div className={classes.container} ref={containerRef}>
    {typicalSequence == null && <Typography variant='h5'>
      No Signature Sequence Found  
    </Typography>}
    {typicalSequence != null &&
      <div className={classes.sequenceContainer}>
        <div className={classes.imageContainer}>
          {typicalSequence.map((segment, idx) => <div key={segment} className={classes.segment}>
            <div className={classes.bodySegmentGraphic}>
              <img
                src={BODY_TO_IMAGE_MAPPING[segment]}
                className={classes.icon} 
                alt=''
              />
            </div>

            {idx !== typicalSequence.length - 1 && 
              <ArrowForwardIcon className={classes.arrow} fontSize='large' />}
          </div>)}
        </div>

        <div className={classes.labelContainer}>
          {typicalSequence.map((segment, idx) => {
            return <div className={classes.segmentLabel} key={segment}>
              <Typography noWrap variant='h6'>
                #{idx + 1} {segment === 'UpperArm' ? 'Arm' : segment}
              </Typography>
            </div>;
          })}
        </div>
      </div>}
  </div>;
};

export default TypicalSequence;
