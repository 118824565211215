import React, { useState } from 'react';
import { Badge, Box, Grid, Popover, Typography } from '@material-ui/core';
import TagIcon from '@material-ui/icons/LocalOfferOutlined';
import useStyles from './styles';

function TagInfo({ id, tags = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const color = tags.length > 0 ? tags[0].color : null;
  const classes = useStyles({ color });

  const popoverId = `tag-info-${id}`;

  const handleOpen = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return tags.length > 0 && <React.Fragment>
    <div 
      aria-owns={anchorEl ? popoverId : null}
      aria-haspopup={true}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <Badge 
        badgeContent={tags.length} 
        classes={{ badge: classes.badge, root: classes.badgeRoot }}
      >
        <TagIcon className={classes.icon} />
      </Badge>
    </div>

    <Popover
      id={popoverId}
      open={anchorEl != null}
      anchorEl={anchorEl}
      onClose={handleClose}
      disableRestoreFocus
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      className={classes.popover}
      classes={{ paper: classes.popoverPaper }}
    >
      {tags.map(tag => <Grid 
        container 
        key={`${popoverId}-${tag.tagName}`}
        alignItems='center'
      >
        <Grid item>
          <Box className={classes.tagColor} bgcolor={tag.color} />
        </Grid>
        <Grid item>
          <Typography>{tag.tagName}</Typography>
        </Grid>
      </Grid>)}
    </Popover>
  </React.Fragment>;
}

export default TagInfo;
