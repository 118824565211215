export const timingValues = [
  [
    {
      key: 'firstMoveToContactTime',
      title: 'Time to Contact (First Move to Contact)',
      units: 's',
      abbreviation: 'FM2CON',
      formatter: val => val.toFixed(3)
    }
  ],
  [
    {
      key: 'heelStrikeToContactTime',
      title: 'Heel Strike To Contact Time',
      units: 's',
      abbreviation: 'HS2CON',
      formatter: val => val.toFixed(3)
    }
  ],
  [
    {
      key: 'swingTime',
      title: 'Swing Time',
      units: 's',
      abbreviation: 'TTI',
      formatter: val => val.toFixed(2)
    }
  ],
  [
    {
      key: 'tempo',
      title: 'Tempo',
      abbreviation: 'TEMPO',
      formatter: val => `${val.toFixed(1)} : 1`
    }
  ]
];
