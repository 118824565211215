import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';

import LengthLimitedTextField from '../../../../../components/lengthLimitedTextField';
import useStyles from './styles';
import FormControlSelect from '../../../../../components/formControlSelect';
import { VIDEO_TYPE } from '../../../../../constants/video.constants';

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 500;

function VideoUploadDetails({ 
  file, 
  className,
  title, 
  description,
  videoType,
  onTitleChange, 
  onDescriptionChange,
  onVideoTypeChange,
  displayVideoTypeSelect
}) {
  const classes = useStyles();
  const blobUrl = useMemo(() => {
    URL.createObjectURL(file);
  }, [file]);

  return <Grid container spacing={2} alignItems='flex-end' className={className}>
    <Grid item xs={12} sm={7}>
      <LengthLimitedTextField
        variant='outlined' 
        value={title} 
        onChange={onTitleChange}
        label='Title (optional)'
        maxLength={MAX_TITLE_LENGTH}
        className={classNames(classes.marginTop, classes.textField)}
      />
      <LengthLimitedTextField multiline
        rows={4}
        variant='outlined' 
        value={description} 
        onChange={onDescriptionChange}
        label='Description (optional)'
        maxLength={MAX_DESCRIPTION_LENGTH}
        className={classNames(classes.marginTop, classes.textField)}
      />
      {displayVideoTypeSelect && <FormControlSelect 
        id={`video-type-select-${file.name}`}
        label='Video Type'
        items={[VIDEO_TYPE.drill, VIDEO_TYPE.content]}
        renderItem={item => <MenuItem key={item} value={item}>{item}</MenuItem>}
        className={classNames(classes.marginTop, classes.textField)}
        onChange={onVideoTypeChange}
        value={videoType}
      />}
    </Grid>
    <Grid item xs={5}>
      {blobUrl && <video className={classes.videoPreview} controls>
        <source src={blobUrl} type={file.type} />
      </video>}
    </Grid>
  </Grid>;
}

VideoUploadDetails.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired
};

export default VideoUploadDetails;
