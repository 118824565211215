import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import classNames from 'classnames';

function MetricCard({ title, value, label, children, className }) {
  const classes = useStyles();

  return <div className={classNames(classes.container, className)}>
    <div className={classes.innerContainer}>
      <div className={classes.item}>
        <Typography align='center' variant='h4' className={classes.title}>
          {title}
        </Typography>
      </div>
      <div className={classes.item}>
        <Typography align='center' variant='h2' className={classes.value}>
          {value != null ? value : '-'}
        </Typography>
        <Typography align='center' variant='h6'>{label}</Typography>
      </div>
    </div>

    {children && <div>
      {children}
    </div>}
  </div>;
}

export default MetricCard;
