import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { SEGMENTS_LIST } from '../playerPercentiles.constants';
import { orderBy, meanBy } from 'lodash';
import useStyles from './styles';
import classNames from 'classnames';

function PercentileTable({ beforeData, afterData, focusSegment }) {
  const classes = useStyles();
  
  const paddedColWidth = (afterData.length > 0 && beforeData.length > 0) ? 2 : 1;
  if (beforeData.length === 0 && afterData.length === 0) {
    return null;
  }

  function calculateAverages(items) {
    const percentileAverages = SEGMENTS_LIST.map(segment => ({ 
      segment, 
      value: meanBy(items, item => item.percentiles[segment]).toFixed(2) 
    }));
    const speedAverages = SEGMENTS_LIST.map(segment => ({
      segment,
      value: meanBy(items, item => item.speeds[segment]).toFixed(2)
    }));
    return [...percentileAverages, ...speedAverages];
  }

  const beforeAverages = calculateAverages(beforeData);
  const afterAverages = calculateAverages(afterData);
  const showDelta = beforeData.length > 0 && afterData.length > 0;
  const deltaData = afterAverages.map(({ segment, value }, idx) => ({
    segment,
    value: value - beforeAverages[idx].value
  }));
  const tableData = [
    { label: 'Before', items: beforeData, averages: beforeAverages },
    { label: 'After', items: afterData, averages: afterAverages }
  ];

  const FocusBorder = ({ segment }) => <div className={classNames({ 
    [classes.focusSegment]: focusSegment === segment 
  })} />;

  return <Table>
    <TableHead>
      <TableRow>
        <TableCell colSpan={paddedColWidth} />
        <TableCell colSpan={SEGMENTS_LIST.length} align='center'>
          Percentiles
        </TableCell>
        <TableCell 
          colSpan={SEGMENTS_LIST.length} 
          className={classes.leftBorderThick} 
          align='center'
        >
          Speeds
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={paddedColWidth} />
        {SEGMENTS_LIST.map((segment) => <TableCell key={`percentile_${segment}`}>
          {segment}
        </TableCell>)}
        {SEGMENTS_LIST.map((segment, idx) => <TableCell 
          key={`speed_${segment}`}
          className={classNames({ [classes.leftBorderThick]: idx === 0 })}
        >
          {segment}
        </TableCell>)}
      </TableRow>
    </TableHead>
    <TableBody>
      {tableData.map(({ label, items, averages }) => {
        if (items.length === 0) {
          return null;
        }

        const orderedItems = orderBy(items, 'timesetamp');
        return <React.Fragment key={`section_${label}`}>
          {orderedItems.map((item, idx) => <TableRow key={`row_${item.motionId}`}>
            {idx === 0 && paddedColWidth > 1 && <TableCell 
              rowSpan={items.length + paddedColWidth - 1}
              className={classes.bottomBorderThick}
            >
              {label}
            </TableCell>}

            <TableCell>{idx + 1}</TableCell>
            {SEGMENTS_LIST.map(segment => <TableCell key={`${segment}_percentile_${label}`}>
              {item.percentiles[segment]?.toFixed(1) ?? 'N/A'}
            </TableCell>)}
            {SEGMENTS_LIST.map((segment, segIdx) => <TableCell 
              key={`${segment}_speeds_${label}`}
              className={classNames({ [classes.leftBorderThick]: segIdx === 0 })}
            >
              {item.speeds[segment] != null ? Math.round(item.speeds[segment]) : 'N/A'}
            </TableCell>)}
          </TableRow>)}

          <TableRow className={classes.averagesRow}>
            <TableCell className={classes.bottomBorderThick}>Avg</TableCell>
            {averages.map(({ value, segment }, idx) => <TableCell 
              key={`avg_${segment}_${idx}_${label}`}
              className={classNames(
                classes.bottomBorderThick, 
                { [classes.leftBorderThick]: idx === SEGMENTS_LIST.length }
              )}
            >
              <FocusBorder segment={segment} />
              {value}
            </TableCell>)}
          </TableRow>
        </React.Fragment>;
      })}

      {showDelta && <TableRow className={classes.deltaRow}>
        <TableCell colSpan={paddedColWidth} size='small'>Delta</TableCell>
        {deltaData.map(({ segment, value }, idx) => <TableCell 
          key={`delta_${segment}_${idx}`}
          className={classNames({ 
            [classes.positiveItem]: value > 0, 
            [classes.negativeItem]: value < 0,
            [classes.leftBorderThick]: idx === SEGMENTS_LIST.length
          })}
        >
          <FocusBorder segment={segment} />
          {value > 0 && '+' }{value.toFixed(2)}
        </TableCell>)}
      </TableRow>}
    </TableBody>
  </Table>;
}

export default PercentileTable;