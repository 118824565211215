import Network from './network';
import { getOrganizationId } from 'utils/auth.utils';
import { PLAN_TYPES } from 'constants/trainingPlans.constants';
import { EXTERNAL_DOCUMENTS } from 'constants/trainingPlanSubresources.constants';
import { GetAccountAssociationsAsPrimary } from 'network/userRequests';

const Url = 'API/TrainingPlans';

export const GetTrainingPlans = async (params, cancelToken) => {
  params = { organizationId: getOrganizationId(), ...params };
  const resp = await Network.Get(Url, params, cancelToken);
  return resp.data.items;
};

export const GetTrainingPlanTemplates = async (cancelToken) => {
  const organizationId = getOrganizationId();
  const resp = await Network.Get(
    Url, { 
      organizationId, 
      trainingPlanType: PLAN_TYPES.template 
    }, 
    cancelToken);

  return resp.data.items;
};

export const GetGoogleDriveTemplates = async (cancelToken) => {
  const organizationId = getOrganizationId();
  const resp = await Network.Get(
    Url, { 
      organizationId, 
      trainingPlanType: PLAN_TYPES.googleDriveTemplate
    }, 
    cancelToken);

  return resp.data.items;
};

export const GetTrainingPlan = async (id, cancelToken) => {
  const resp = await Network.Get(`${Url}/${id}`, null, cancelToken);
  return resp.data;
};

export const CreateTrainingPlan = async (plan) => {
  const orgId = getOrganizationId();
  plan.organizationId = orgId;
  plan.notes.forEach(note => note.organizationId = orgId);
  const resp = await Network.Post(Url, plan);
  return resp.data;
};

export const GetLatestTrainingPlan = async ({ userId }) => {
  const sortedTrainingPlans = GetSortedTrainingPlans( { userId });
  if (sortedTrainingPlans.length > 0) {
    return sortedTrainingPlans[0];
  }    
  return;
};

export const GetSortedTrainingPlans = async ({ userId }) => {
  const trainingPlans = await GetTrainingPlans({
    userId,
    trainingPlanType: PLAN_TYPES.googleDriveTrainingPlan,
    subresources: [EXTERNAL_DOCUMENTS]
  });
  const sortedTrainingPlans = trainingPlans?.sort(
    (b, a) => (new Date(a?.creationTimestamp)).getTime() - 
    (new Date(b?.creationTimestamp)).getTime());

  return sortedTrainingPlans;
};

export const GetTrainingPlansForAssociatedAccounts = async ({ userId }) => {
  const associations = await GetAccountAssociationsAsPrimary(userId, ['associatedUser']);
  // Get latest training plan for each associated user:
  return await Promise.all(
    associations.map(async ({ associatedUser }) => {
      const trainingPlans = await GetSortedTrainingPlans(associatedUser);
      return {
        trainingPlans,
        user: associatedUser
      };
    })
  );
};

export const UpdateTrainingPlan = async(id, plan) => {
  const resp = await Network.Put(`${Url}/${id}`, plan);
  return resp.data;
};