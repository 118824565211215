import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Logger from 'js-logger';
import subMonths from 'date-fns/subMonths';
import axios from 'axios';
import StatsTile from '../../../../components/statsTile';
import StyledSelect from '../../../../components/styledSelect';
import { 
  GetFullMotionMetricsAverages, 
  GetLaunchMonitorAverages, 
  GetHittingDeviceSensorAverages 
} from '../../../../network/motionRequests';
import ErrorSnackbar from '../../../../components/errorSnackbar';
import { getMetrics } from './metrics.constants';

import useStyles from './styles';
import motionTypesConstants from '../../../../constants/motionTypes.constants';
import { PERMISSIONS } from '../../../../constants/permissions.constants';
import DateRangeSelector from '../../../../components/dateRangeSelector';

const _now = new Date();
const DROPDOWN_ITEMS = [
  { title: 'date range' },
  { title: 'all time', laterThan: null, earlierThan: _now },
  { title: 'last 3 months', laterThan: subMonths(_now, 3), earlierThan: _now },
  { title: 'last month', laterThan: subMonths(_now, 1), earlierThan: _now }
];

function SwingStatAverages({ userId }){
  const [selectedIdx, setSelectedIdx] = useState(1);
  const [dateRange, setDateRange] = useState({ laterThan: null, earlierThan: _now });
  const [error, setError] = useState(null);

  const initialData = { data: {}, loading: false };
  const [fullMotionMetrics, setFullMotionMetrics] = useState(initialData);
  const [launchMonitorData, setLaunchMonitorData] = useState(initialData);
  const [hittingDeviceSensor, setHittingDeviceSensor] = useState(initialData);
  const consolidatedData = { fullMotionMetrics, launchMonitorData, hittingDeviceSensor };

  const featurePermissions = useSelector(state => state.featurePermissions);
  const metrics = getMetrics(
    featurePermissions.includes(PERMISSIONS.canInputThirdPartyData),
    featurePermissions.includes(PERMISSIONS.canHandleLaunchMonitorData));

  const classes = useStyles();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    function fetchAllData() {
      fetchMotionSubresourceAverages(GetFullMotionMetricsAverages, setFullMotionMetrics);
      fetchMotionSubresourceAverages(GetLaunchMonitorAverages, setLaunchMonitorData);
      fetchMotionSubresourceAverages(GetHittingDeviceSensorAverages, setHittingDeviceSensor);
    }
  
    async function fetchMotionSubresourceAverages(networkCall, setData) {
      setData(prev => ({ ...prev, loading: true }));
      try {
        const params = {
          userId,
          count: null,
          laterThan: dateRange.laterThan !== null ? dateRange.laterThan.toISOString() : null,
          earlierThan: dateRange.earlierThan !== null ? dateRange.earlierThan.toISOString() : null,
          motionType: motionTypesConstants.baseball
        };
        const averages = await networkCall(params, cancelToken);
        const data = averages != null && 
                     averages.length > 0 && 
                     averages[0].data != null 
          ? averages[0].data 
          : {};
        setData({ data, loading: false });
      } catch (e) {
        if (axios.isCancel(e)) return;
        Logger.error(`Error loading averages data for subresource for user ${userId}`, e);
        setData(prev => ({ ...prev, loading: false }));
      }
    }

    fetchAllData();
    return cancelToken.cancel;
  }, [userId, dateRange]);

  function onSelectChange(e) {
    const idx = e.target.value;
    setSelectedIdx(idx);
    
    // if not date range
    if (idx > 0) {
      const { laterThan, earlierThan } = DROPDOWN_ITEMS[idx];
      setDateRange({ laterThan, earlierThan });
    } else {
      setDateRange(prev => ({ 
        laterThan: prev.laterThan == null ? new Date() : prev.laterThan, 
        earlierThan: prev.earlierThan == null ? new Date() : prev.earlierThan
      }));
    }
  }

  function handleDateChange(date, isLaterThan) {
    setDateRange(prev => ({ 
      laterThan: isLaterThan ? date : prev.laterThan,
      earlierThan: isLaterThan ? prev.earlierThan : date
    }));
  }

  return <div>
    <ErrorSnackbar message={error} onClose={() => setError(null)} />
    <div className={classes.header}>
      <Typography>Swing Stat Averages for </Typography>
      <StyledSelect 
        value={selectedIdx} 
        onChange={onSelectChange}
        className={classes.select}
      >
        {DROPDOWN_ITEMS.map((item, idx) => <MenuItem 
          key={`stat-date-select-${idx}`} 
          value={idx}> {item.title} </MenuItem>)}
      </StyledSelect>
      <div className={classes.dateRangeContainer}>
        {selectedIdx === 0 && <DateRangeSelector 
          fromDate={dateRange.laterThan}
          toDate={dateRange.earlierThan}
          handleDateChange={handleDateChange}
        />}
      </div>
    </div>
    <div className={classes.tilesContainer}>
      {metrics.map(metric => {
        const { data, loading } = consolidatedData[metric.subresource];
        const value = data[metric.key];
        const transformedValue = metric.transform ? metric.transform(value) : value;

        return <StatsTile key={metric.key}
          loading={loading}
          title={metric.title}
          value={transformedValue != null ? transformedValue : '-'}
          units={metric.units}
          className={classes.tile}
        />;
      })}
    </div>
  </div>;
}

export default SwingStatAverages;
