import React from 'react';
import {
  Paper,
  Typography
} from '@material-ui/core';
import CaptureEventsListSection from './captureEventsListSection';
import useStyles from './styles';
import SearchBar from 'components/searchBar';
import useInput from 'utils/useInput';

const NUM_EVENTS_TO_SHOW = 3;

function CaptureEventsList({ 
  selectedEventId,
  loading, 
  error, 
  currentEvents, 
  upcomingEvents, 
  pastEvents,
  onChange,
  selectedEvents = {},
  includeIds = false
}) {
  const classes = useStyles();
  const eventFilterInput = useInput();

  return <Paper className={classes.listContainer}>
    <SearchBar 
      {...eventFilterInput.bind} 
      onClear={eventFilterInput.clearValue} 
      placeholder='Filter Events' 
      className={classes.searchBar}
    />

    {error && <Typography color='error'>Error fetching events.</Typography>}
    <CaptureEventsListSection 
      includeIds={includeIds}
      header='Current Events'
      captureEvents={currentEvents}
      loading={loading}
      selectedEventId={selectedEventId}
      eventFilterText={eventFilterInput.value}
      onChange={onChange}
      selectedEvents={selectedEvents}
    />
    <CaptureEventsListSection 
      includeIds={includeIds}
      header='Upcoming Events'
      captureEvents={upcomingEvents}
      loading={loading}
      selectedEventId={selectedEventId}
      eventFilterText={eventFilterInput.value}
      onChange={onChange}
      numEventsToShow={NUM_EVENTS_TO_SHOW}
      selectedEvents={selectedEvents}
    />
    <CaptureEventsListSection 
      includeIds={includeIds}
      header='Past Events'
      captureEvents={pastEvents}
      loading={loading}
      selectedEventId={selectedEventId}
      eventFilterText={eventFilterInput.value}
      onChange={onChange}
      numEventsToShow={NUM_EVENTS_TO_SHOW}
      selectedEvents={selectedEvents}
    />
  </Paper>;
}

export default CaptureEventsList;
