import UnitConverter from '../../utils/unitConverter.utils';

export const thirdPartyValues = [
  // Launch Monitor
  [
    {
      key: 'ballspeedMph',
      title: 'Exit Velocity',
      units: 'mph',
      abbreviation: 'EV'
    }
  ],
  // Hitting Device Sensor
  [
    {
      key: 'attackAngleDegrees',
      title: 'Attack Angle',
      units: '°'
    },
    {
      key: 'deviceSpeedMetersPerSecond',
      title: 'Device Speed',
      units: 'mph',
      formatter: val => Math.round(UnitConverter.metersPerSecondToMph(val))
    },
    {
      key: 'timeToImpactSeconds',
      title: 'Time To Impact',
      units: 's'
    },
    {
      key: 'rotationalAccelerationGs',
      title: 'Rotational Acceleration',
      units: '°/s²'
    },
    {
      key: 'verticalBatAngleDegrees',
      title: 'Vertical Bat Angle',
      units: '°'
    }
  ],
  // Force Plate (column 1)
  [
    {
      key: 'torqueBackPeakValue',
      title: 'Torque Back Peak Value'
    },
    {
      key: 'torqueFrontPeakValue',
      title: 'Torque Front Peak Value'
    },
    {
      key: 'xBackPeakValue',
      title: 'X Back Peak Value'
    },
    {
      key: 'xFrontPeakValue',
      title: 'X Front Peak Value'
    }
  ],
  // Force Plate (column 2)
  [
    {
      key: 'zBackPeakValue',
      title: 'Z Back Peak Value'
    },
    {
      key: 'zFrontPeakValue',
      title: 'Z Front Peak Value'
    },
    {
      key: 'xTiming',
      title: 'X Timing'
    },
    {
      key: 'heelLoad',
      title: 'Heel Load'
    }
  ]
];