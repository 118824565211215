import { makeStyles } from '@material-ui/core/styles';

const BORDER_WIDTH_PX = 4;

const styles = makeStyles((theme, props) => ({
  columnContainer: {
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '16vw'
    },
    [theme.breakpoints.up('md')]: {
      width: '14vw'
    },
    [theme.breakpoints.up('lg')]: {
      width: '12vw'
    },
    [theme.breakpoints.up('xl')]: {
      width: '10vw'
    }
  },
  metricBoxContainer: props => ({
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: BORDER_WIDTH_PX + 'px',
    borderStyle: 'solid',
    borderColor: theme.palette.mode === 'light' ? 'black' : 'white',
    position: 'absolute',
    top: props?.top,
    height: '14%',
    width: `calc(100% - ${BORDER_WIDTH_PX * 2}px - 5%)`
  }),
  textContainer: props => ({
    color: props?.color
  }),
  units: {
    textAlign: 'center'
  }
}));

export default styles;
