import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import logger from 'js-logger';

import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import SubmitIcon from '@material-ui/icons/Publish';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import MinimizeIcon from '@material-ui/icons/Minimize';
import MaximizeIcon from '@material-ui/icons/Maximize';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/CheckCircle';
import NoteIcon from '@material-ui/icons/Note';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { 
  setActionPlanText,
  setActionPlanTextData, 
  openActionPlan, 
  minimizeActionPlan, 
  expandActionPlan, 
  closeActionPlan, 
  positions, 
  setActionPlanVideoIds,
  setActionPlanImages,
  setNoteTemplateId,
  setActionPlanDrills,
  setInTrainingPlanMode
} from '../../store/actionPlan';
import { saveTrainingPlan } from '../../store/trainingPlan';
import { removeUserActionItem } from '../../store/orgActionItems';
import { addOrgActionTaken } from '../../store/orgActionsTaken';
import TemplateDrawer from './templateDrawer';
import { CreateNote } from '../../network/notesRequests';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import VideoSelectionDialog from './videoSelectionDialog';
import ImageSelectionDialog from './imageSelectionDialog';
import DrillAssignmentDialog from './drillAssignmentDialog';
import { addNote } from '../../store/notes';
import { PERMISSIONS, TAB_PERMISSIONS } from '../../constants/permissions.constants';
import { ACTION_ITEMS } from '../../constants/organizations.constants';
import TEXT_TYPES from './../../constants/actionPlanTextTypes.constants';
import { UploadImage } from '../../network/imageRequests';
import DragAndDrop from './imageDragDrop';
import ActionPlanTextEditor from './actionPlanTextEditor';
import TrainingPlan from './trainingPlan';

const DRAWER_WIDTH = 275;
const ACTION_PLAN_PADDING = '2.5%';
const IMAGE_UPLOAD_SUCCESS = 'SUCCESS';
const IMAGE_UPLOAD_FAILURE = 'FAILURE';

class ActionPlanComponent extends React.Component {
  state = {
    postingNote: false,
    showSuccess: false,
    selectingVideos: false,
    selectingImages: false,
    selectingDrills: false,
    openTemplates: false,
    imageUploadSucess: false,
    imageUploadFailure: false,
    imageUploadStatus: null,
    error: null
  };

  editorRef = React.createRef();

  _isSaving = () => this.state.postingNote || this.props.isSavingTrainingPlan;

  _closeActionPlanWithConfirmation = () => {
    const confirmText = 'Are you sure you want to close this action plan? ' 
      + 'Any content added will not be saved.';
    const { textData, videoIds, closeActionPlan, images } = this.props;
    if (textData.formattedText.trim() !== '' || videoIds.length > 0 || images.length > 0) {
      if (!window.confirm(confirmText)) {
        return;
      }
    }
    
    // close the drawer when closing this as well
    this.setState({ openTemplates: false });
    closeActionPlan();
  }

  _noteIsValid = () => {
    if (!this.props.hasActionPlanChecks) return true;
    
    const failedChecks = [];
    const {
      videoIds,
      textData
    } = this.props;

    if (!videoIds || videoIds.length === 0) {
      failedChecks.push(' - No videos have been attached.');
    }

    // look for special template variables (any case of <<any_text>>)
    const variablesFound = textData.plainText.match(/<<[^\s]+>>/g);
    if (variablesFound) {
      const variableStr = variablesFound.join(', ');
      failedChecks.push(' - The following variables look ' +
        `like they need to be updated: ${variableStr}`);
    }

    if (failedChecks.length > 0) {
      const msg = 'There are potential issues with this action plan: \n' + 
        failedChecks.join('\n') + '\n' +
        'Do you still want to send the plan?';
      return window.confirm(msg);
    } else {
      return true;
    }
  }

  _openActionPlan = () => {
    const {
      openActionPlan,
      textData,
      setActionPlanTextData
    } = this.props;
    
    openActionPlan();
    
    if (textData.formattedText === '') {
      const user = this.props.currentPlayer;
      const intro = user && user.firstName 
        ? user.firstName.trim() + ',\n\n'
        : '';
      const editorState = {
        formattedText: intro,
        plainText: intro,
        textFormatType: null, 
        state: null
      };
      // need to use textdata instead of setting text
      // due to a race condition in the reducer that relies on
      // the editor component being mounted before adding text
      setActionPlanTextData(editorState);
    }
  }
  
  _createNewNote = async () => {
    if (this.props.inTrainingPlanMode) {
      this.props.saveTrainingPlan();
      return;
    }

    const { 
      currentPlayer, 
      textData, 
      videoIds, 
      closeActionPlan, 
      addNote, 
      removeUserActionItem,
      addOrgActionTaken,
      images,
      noteTemplateId,
      assignedDrills
    } = this.props;
    if (!this._noteIsValid()) return;

    this.setState({ postingNote: true });
    try {
      const noteVideos = videoIds.map(videoId => ({ videoId }));
      const noteImages = images.map(({ id }) => ({ 'imageId': id }) );
      const { formattedText, plainText, type } = textData;
      const note = await CreateNote({ 
        userId: currentPlayer.userId, 
        noteVideos, 
        noteImages,
        text: plainText,
        formattedText: TEXT_TYPES.PLAIN_TEXT === type ? null : formattedText,
        textFormatType: type,
        noteTemplateId,
        assignedDrills
      });
      addNote(note);
      removeUserActionItem(ACTION_ITEMS.needsActionPlan, currentPlayer.userId);
      addOrgActionTaken(ACTION_ITEMS.needsActionPlan, currentPlayer.userId);
      this.setState({ postingNote: false, showSuccess: true }, closeActionPlan);
    } catch (e) {
      logger.error('Error creating new note: ', e);
      this.setState({ postingNote: false, error: 'Error submitting. Please try again.' });
    }
  }

  _formatActionPlanInfoObject = (markdown, plainText, type, data = null) => {
    this.props.setActionPlanTextData({ formattedText: markdown, plainText, type, data });
  }

  _pasteTemplate = (template) => {
    const { text, noteTemplateVideos = [] } = template;
    const templateVideoIds = noteTemplateVideos.map(video => video.videoId);

    const {  
      setActionPlanVideoIds,
      setNoteTemplateId,
      videoIds
    } = this.props;

    // set the video ids
    // we will add on the the already selected video ids
    // we will filter out already selected ids so there are no duplicates
    setActionPlanVideoIds([...videoIds, ...templateVideoIds.filter(id => !videoIds.includes(id))]);
    setNoteTemplateId(template.id);
    this.editorRef.current && this.editorRef.current.replaceText(text);
  }

  _setSnackbarSucess = async (isSuccessful) => {
    this.setState({ imageUploadStatus: 
      isSuccessful ? IMAGE_UPLOAD_SUCCESS : IMAGE_UPLOAD_FAILURE });
  }

  _attemptUploadImage = async (file) => {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    try {
      // Check if file is image
      if (!validImageTypes.includes(file['type'])) {
        throw new Error('File uploaded was not an image');
      }
      const response = await UploadImage(this.props.currentPlayer.userId, file);
      return response;
    } 
    catch (e) {
      logger.error('Error uploading new image: ', e);
      this.setState({ error: `Error uploading image. ${e}` });
      return null;
    }
  }

  _imageUpload = async (files) => {
    if (files.length === 0) return;

    const { setActionPlanImages, images } = this.props;

    // Helper function handles file upload exceptions
    let newImages = await Promise.all(Array.from(files).map(this._attemptUploadImage));
    let successfulImages = newImages.filter(x => x != null);
    this._setSnackbarSucess(successfulImages.length === newImages.length);
    let combinedImages = images.concat(successfulImages);
    setActionPlanImages(combinedImages);
  }

  renderHeader = () => {
    const { 
      classes,
      currentPlayer,
      position,
      minimizeActionPlan, 
      expandActionPlan, 
      openActionPlan,
      hasInternalTooling
    } = this.props;

    const _onIconClick = (callback) => e => {
      if (this._isSaving()) {
        return;
      }
      e.stopPropagation();
      callback();
    };

    const _onTrainingIconClick = (e) => {
      e.stopPropagation();
      if (this.props.inTrainingPlanMode) {
        this.props.setInTrainingPlanMode(false);
      } else {
        this.setState({ openTemplates: false });
        this.props.setInTrainingPlanMode(true);
        this.props.expandActionPlan();
      }
    };

    const iconClassName = classNames(
      classes.headerIconButton, 
      { [classes.headerIconButtonEnabled]: !this._isSaving() });

    return <div className={classes.header}>
      <Typography style={{ flex: 1 }}>
        New {this.props.inTrainingPlanMode ? 'Training Plan ' : 'Action Plan '} 
        for {currentPlayer.firstName} {currentPlayer.lastName}
      </Typography>
      {hasInternalTooling && !this.props.inTrainingPlanMode &&
        <Tooltip title='Training Plan'>
          <AssignmentOutlinedIcon 
            onClick={_onTrainingIconClick} 
            className={classNames(
              iconClassName,
              classes.trainingPlanIcon,
              { [classes.mildYellowColor]: this.props.inTrainingPlanMode }
            )}
          />
        </Tooltip>
      }
      {(position === positions.OPEN || position === positions.EXPANDED) && 
        <Tooltip title='Minimize'>
          <MinimizeIcon 
            className={iconClassName} 
            onClick={_onIconClick(minimizeActionPlan)}
          />
        </Tooltip>
      }
      {position === positions.MINIMIZED && 
        <Tooltip title='Maximize'>
          <MaximizeIcon 
            className={iconClassName} 
            onClick={(e) => {
              const cb = this.props.inTrainingPlanMode
                ? expandActionPlan
                : openActionPlan;
              _onIconClick(cb)(e);
            }}
          />
        </Tooltip>
      }
      {!this.props.inTrainingPlanMode && (position === positions.EXPANDED
        ? <Tooltip title='Exit full-screen'>
          <FullscreenExitIcon className={iconClassName} 
            onClick={_onIconClick(openActionPlan)} />
        </Tooltip>
        : <Tooltip title='Full-screen'>
          <FullscreenIcon className={iconClassName} 
            onClick={_onIconClick(expandActionPlan)} />
        </Tooltip>)}
      {position !== positions.EXPANDED && <Tooltip title='Close'>
        <CloseIcon onClick={_onIconClick(this._closeActionPlanWithConfirmation)} 
          className={iconClassName} 
        />
      </Tooltip>}
    </div>;
  }

  renderTextField = (expanded = false, minimized = false) => <div className={
    classNames(
      this.props.classes.textFieldContainer,
      { [this.props.classes.fullContainer] : expanded }
    )
  }>
    {!minimized &&
    <div className={
      classNames(
        { [this.props.classes.fullDrawer] : expanded }
      )}>
      <TemplateDrawer
        open={this.state.openTemplates}
        width={DRAWER_WIDTH}
        fullScreen={expanded}
        onTemplateSelected={this._pasteTemplate}
        onClose={() => this.setState({ openTemplates: false })}
      />
    </div>}
    {!minimized && this.props.inTrainingPlanMode &&
      <div className={classNames({ [this.props.classes.fullRightDrawer] : expanded })}>
        <TrainingPlan
          open={this.props.inTrainingPlanMode}
          width={DRAWER_WIDTH}
          fullScreen={expanded}
          onClose={() => this.props.setInTrainingPlanMode(false)}
        />
      </div>
    }
    <ActionPlanTextEditor
      ref={this.editorRef}
      placeholder={'Write your player\'s new action plan here.'}
      textData={this.props.textData}
      onChange={this._formatActionPlanInfoObject}
      position={this.props.position}
      expanded={expanded}
      leftDrawerOpen={this.state.openTemplates}
      rightDrawerOpen={this.props.inTrainingPlanMode}
      padding={ACTION_PLAN_PADDING}
      drawerWidth={DRAWER_WIDTH}
    />
  </div>;

  renderFooter = (templatesOpen) => {
    const { 
      textData, 
      classes, 
      videoIds, 
      images, 
      canUploadDrillVideos, 
      assignedDrills,
      hasInternalTooling
    } = this.props;
    const { openTemplates } = this.state;
    const iconClassName = classNames(
      classes.footerIconButton,
      classes.flex,
      { [classes.footerIconButtonEnabled]:  !this._isSaving() });

    return (
      <div>
        <Typography color='error'>{this.props.trainingPlanError}</Typography>
        <div className={classes.footer}>
          <Button variant='contained' color='primary'
            onClick={this._createNewNote} 
            disabled={textData.plainText.trim().length === 0 || this._isSaving()}
          >
            <Typography>{this.props.isEditingTrainingPlanTemplate ? 'Save Template' : 'Submit'}</Typography>
            <SubmitIcon />
          </Button>
          {canUploadDrillVideos && <Badge badgeContent={videoIds.length} max={9} color='primary'>
            <div className={iconClassName}
              onClick={() => !this._isSaving() && this.setState({ selectingVideos: true })}
            >
              <VideoLibraryIcon />
              {!templatesOpen &&
                <Typography className={classes.buttonText}>Videos</Typography>}
            </div>
          </Badge>}
          <Badge badgeContent={images.length} color='primary'>
            <div className={iconClassName}
              onClick={() => !this._isSaving() && this.setState({ selectingImages: true })}
            >
              <ImageIcon />
              {!templatesOpen &&
                <Typography className={classes.buttonText}>Images</Typography>}
            </div>
          </Badge>
          <div 
            className={classNames(
              iconClassName, 
              { [this.props.classes.templateOpen] : openTemplates }
            )}
            onClick={() => 
              !this._isSaving() && this.setState(prev => ({ 
                openTemplates: !prev.openTemplates 
              }))
            }
          >
            <NoteIcon />
            {!templatesOpen &&
              <Typography className={classes.buttonText}>Templates</Typography>}
          </div>
          {hasInternalTooling && 
            <Badge badgeContent={assignedDrills.length} color='primary'>
              <div className={iconClassName}
                onClick={() => !this._isSaving() && this.setState({ selectingDrills: true })}
              >
                <SportsBaseballIcon />
                {!templatesOpen &&
                  <Typography className={classes.buttonText}>Drills</Typography>}
              </div>
            </Badge>
          }
          <div className={classes.rightAlignFlex}>
            <Tooltip title='Delete' placement='top'>
              <div className={iconClassName} 
                onClick={() => !this._isSaving() && this._closeActionPlanWithConfirmation()}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { 
      selectingVideos, 
      selectingImages,
      selectingDrills,
      showSuccess, 
      openTemplates, 
      imageUploadStatus 
    } = this.state;
    const { 
      className,
      classes,
      position,
      openActionPlan,
      setActionPlanVideoIds,
      videoIds,
      images,
      setActionPlanImages,
      setActionPlanDrills,
      hasInternalTooling,
      canUploadDrillVideos,
      currentPlayer,
      assignedDrills
    } = this.props;
    
    return <div className={className}>
      {position === positions.CLOSED && <Tooltip title='New Action Plan'>
        <Fab size='large' color='primary' className={classes.fab} onClick={this._openActionPlan} disabled={!currentPlayer}>
          <EditIcon />
        </Fab>
      </Tooltip>}
      <DragAndDrop handleDrop={this._imageUpload}>
        {(position === positions.OPEN || position === positions.MINIMIZED) && 
        <Paper 
          elevation={3} 
          classes={{ 
            root: classNames(
              classes.paper,
              { [classes.rootTemplateOpen]: openTemplates }
            ) 
          }}>
          {this.renderHeader()}
          <Collapse in={position === positions.OPEN} className={classes.collapse}>
            {this.renderTextField(false, position === positions.MINIMIZED)}
            {this.renderFooter(openTemplates)}
          </Collapse>
        </Paper>}
      </DragAndDrop>
      {position === positions.EXPANDED && 
        <Dialog open fullWidth maxWidth='lg' onClose={openActionPlan} classes={{ root: classes.dialog }}>
          <DialogTitle className={classes.dialogTitle}>
            {this.renderHeader()}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {this.renderTextField(true)}
            {this.renderFooter()}
          </DialogContent>
        </Dialog>}      

      {canUploadDrillVideos && selectingVideos && <VideoSelectionDialog open={selectingVideos} 
        onCancel={() => this.setState({ selectingVideos: false })}
        onSave={videoIds => this.setState({ selectingVideos: false }, 
          () => setActionPlanVideoIds(videoIds))}
        videoIds={videoIds}
        userId={currentPlayer.userId}
      />}
      {selectingImages && <ImageSelectionDialog open={selectingImages} 
        onCancel={() => this.setState({ selectingImages: false })}
        onSave={images => this.setState({ selectingImages: false }, 
          () => setActionPlanImages(images))}
        images={images}
        userId={currentPlayer.userId}
        attemptUploadImage={this._attemptUploadImage}
        setSnackbarSucess={this._setSnackbarSucess}
      />}
      {hasInternalTooling && selectingDrills && 
        <DrillAssignmentDialog
          onAssignment={drills => {
            // we need to keep any videos not assigned through
            // the drill screen, but handle any adjustments to drills
            // that were just made
            const newDrillVideoIds = [
              ...drills.map(drill => drill.cueVideoId),
              ...drills.map(drill => drill.drillVideoId)
            ];
            const prevDrillVideoIds = [
              ...assignedDrills.map(drill => drill.cueVideoId),
              ...assignedDrills.map(drill => drill.drillVideoId)
            ];
            const videoIdsMinusDrills = videoIds
              .filter(id => !prevDrillVideoIds.includes(id));
            const uniqueVideoIds = [...new Set([...videoIdsMinusDrills, ...newDrillVideoIds])];
            setActionPlanVideoIds(uniqueVideoIds);
            setActionPlanDrills(drills);
            this.setState({ selectingDrills: false });
          }}
          noteDrills={assignedDrills}
          onClose={() => this.setState({ selectingDrills: false })}
        />
      } 
      <Snackbar open={showSuccess} 
        onClose={() => this.setState({ showSuccess: false })} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={5000}
      >
        <SnackbarContent className={classes.snackbar}
          message={<span style={{ display: 'flex', alignItems: 'center' }}>
            <CheckIcon className={classes.paddedIcon}/>
            Action plan created.
          </span>}
        />
      </Snackbar>
      <Snackbar open={imageUploadStatus === IMAGE_UPLOAD_SUCCESS} 
        onClose={() => this.setState({ imageUploadStatus: null })} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={5000}
      >
        <SnackbarContent className={classes.snackbar}
          message={<span style={{ display: 'flex', alignItems: 'center' }}>
            <CheckIcon className={classes.paddedIcon}/>
            Image(s) Uploaded Successfully.
          </span>}
        />
      </Snackbar>
      <Snackbar open={imageUploadStatus === IMAGE_UPLOAD_FAILURE} 
        onClose={() => this.setState({ imageUploadStatus: null })} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={5000}
      >
        <SnackbarContent className={classes.snackbarFail}
          message={<span style={{ display: 'flex', alignItems: 'center' }}>
            <CancelIcon className={classes.paddedIcon}/>
            One or more images Failed to Upload.
          </span>}
        />
      </Snackbar>
    </div>;
  }
}

const mapStateToProps = state => {
  return { 
    ...state.actionPlan, 
    currentPlayer: state.currentPlayer,
    canUploadDrillVideos: state.featurePermissions.includes(TAB_PERMISSIONS.drillVideos),
    hasActionPlanChecks: state.featurePermissions.includes(PERMISSIONS.actionPlanChecks),
    hasInternalTooling: state.featurePermissions.includes(PERMISSIONS.internalTooling),
    isSavingTrainingPlan: state.trainingPlan.isSaving,
    inTrainingPlanMode: state.actionPlan.inTrainingPlanMode,
    isEditingTrainingPlanTemplate: state.trainingPlan.isEditingTemplate,
    savingTrainingPlanFailed: state.trainingPlan.saveFailure,
    trainingPlanError: state.trainingPlan.trainingPlanError
  };
};

const mapDispatchToProps = dispatch => {
  return { 
    setActionPlanText: text => dispatch(setActionPlanText(text)),
    setActionPlanTextData: actionPlan => dispatch(setActionPlanTextData(actionPlan)),
    setActionPlanVideoIds: videoIds => dispatch(setActionPlanVideoIds(videoIds)),
    setActionPlanImages: images => dispatch(setActionPlanImages(images)),
    setActionPlanDrills: drills => dispatch(setActionPlanDrills(drills)),
    setNoteTemplateId: (id) => dispatch(setNoteTemplateId(id)),
    setInTrainingPlanMode: (inMode) => dispatch(setInTrainingPlanMode(inMode)),
    openActionPlan: () => dispatch(openActionPlan()),
    closeActionPlan: () => dispatch(closeActionPlan()),
    minimizeActionPlan: () => dispatch(minimizeActionPlan()),
    expandActionPlan: () => dispatch(expandActionPlan()),
    addNote: note => dispatch(addNote(note)),
    removeUserActionItem: (actionItem, userId) => 
      dispatch(removeUserActionItem(actionItem, userId)),
    addOrgActionTaken: (actionItem, userId) => 
      dispatch(addOrgActionTaken(actionItem, userId)),
    saveTrainingPlan: () => dispatch(saveTrainingPlan())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles())(ActionPlanComponent));
