import palette from '../../../../styles/palette';

const placeholderStyles = { 
  color: palette.subtleWhite,
  fontStyle: 'italic'
};

const styles = {
  input: {
    width: '100%',
    color: palette.white,
    backgroundColor: palette.blueGray,
    border: 'none',
    outline: 'none',
    padding: '8px',

    '&::placeholder': placeholderStyles, // Chrome, Firefox, Opera, Safari 10.1+
    '&:::-ms-input-placeholder': placeholderStyles, // Microsoft Edge
    '&:-ms-input-placeholder': placeholderStyles /* Internet Explorer 10-11 */
  },
  inputFlex: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputContainer: {
    backgroundColor: palette.blueGray,
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  }
};

export default styles;