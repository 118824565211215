import palette from '../../../../styles/palette';

const styles = {
  marginTop: {
    marginTop: '10px'
  },
  fileInput: {
    marginRight: '10px',
    paddingRight: '18px',
    paddingLeft: '30px'
  },
  fileInputArrow: {
    color: palette.white,
    marginLeft: '10px'
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center'
  },
  submitButton: {
    marginRight: '12px',
    color: palette.white,
    backgroundColor: palette.bluePurple,
    '&:hover': {
      backgroundColor: palette.bluePurple,
      filter: 'brightness(90%)'
    }
  },
  collapse: {
    width: '100%'
  },
  formActionButtons: {
    display: 'flex',
    position: 'sticky',
    bottom: 0,
    backgroundColor: palette.subtleWhite,
    padding: '10px 0',
    zIndex: 2,
    marginTop: '10px'
  },
  uploadDetails: {
    padding: '10px 0',
    borderBottom: `1px solid ${palette.darkGray}`
  }
};

export default styles;
