import React, { useState, useEffect } from 'react';
import Logger from 'js-logger';
import classnames from 'classnames';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import { updateVideoAttributes } from '../../../../store/videoAnalyses';

import { KEY_FRAMES } from '../../../../constants/videoAnalysis.constants';

import KeyFrameDropdown from './keyFrameDropdown';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { CircularProgress, ButtonBase } from '@material-ui/core';

import { UpdateVideoAttributes } from '../../../../network/videoRequests';

function displayTime(seconds) {
  return `${seconds.toFixed(2)}s`;
}

function KeyFrameControls({
  analysisId = null,
  video = null,
  currentVideoTime = null,
  onTimeSelected = () => {}
}) {
  const [settingItems, setSettingItems] = useState([]);
  const [goToItems, setGoToItems] = useState([]);

  const [loadingSetItems, setLoadingSetItems] = useState({});
  const [loadingGoToItems, setLoadingGoToItems] = useState({}); 

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (!video) return;
    setLoadingGoToItems({});
    setLoadingSetItems({});
  }, [video]);

  useEffect(() => {
    if (!video) {
      return;
    }

    const attributes = video.videoAttributes;
    const { cameraAngle } = attributes;

    const keyFramesSet = [];
    const keyFramesNotSet = [];

    KEY_FRAMES.forEach((keyframe) => {
      const { key, views } = keyframe;
      if (!views.includes(cameraAngle)) return;
      const seconds = attributes[key];
      if (seconds === null) keyFramesNotSet.push(keyframe);
      else keyFramesSet.push({ ...keyframe, seconds });
    });

    setSettingItems(keyFramesNotSet);
    setGoToItems(keyFramesSet);

  }, [video]);

  const goToKeyFrame = (keyframe) => {
    if (!loadingGoToItems[keyframe.key]) {
      onTimeSelected(keyframe.seconds);
    }
  };

  const updateAttributes = (newVideoObj, attributeUpdated) => {
    dispatch(updateVideoAttributes(analysisId, newVideoObj.id, {
      [attributeUpdated]: newVideoObj.videoAttributes[attributeUpdated]
    }));
  };

  const removeKeyFrame = async (keyframe) => {
    if (!video || !keyframe.views.includes(video.videoAttributes.cameraAngle)) return;
    if (loadingGoToItems[keyframe.key]) return;

    setLoadingGoToItems(prev => ({ ...prev, [keyframe.key]: true }));
    try {
      const change = { path: `/${keyframe.key}`, op: 'remove' };
      const newVideoObj = await UpdateVideoAttributes(video.id, [ change ]);
      updateAttributes(newVideoObj, keyframe.key);
    } catch(e) {
      Logger.error('Error updating video attributes in setting keyFrames', e);
    }
    setLoadingGoToItems(prev => ({ ...prev, [keyframe.key]: false }));
  };

  const setKeyframe = async (keyframe, seconds) => {
    if (!video || !keyframe.views.includes(video.videoAttributes.cameraAngle)) return;
    if (loadingSetItems[keyframe.key]) return;

    setLoadingSetItems(prev => ({ ...prev, [keyframe.key]: true }));
    try {
      const change = {
        path: `/${keyframe.key}`, 
        op: 'replace', 
        value: seconds
      };
      const newVideoObj = await UpdateVideoAttributes(video.id, [ change ]);
      updateAttributes(newVideoObj, keyframe.key);
    } catch(e) {
      Logger.error('Error updating video attributes in setting keyFrames', e);
    }
    setLoadingSetItems(prev => ({ ...prev, [keyframe.key]: false }));
  };

  return <div className={classes.container}>
    <div className={classes.firstDropdown}>
      <KeyFrameDropdown title='Set Frame'>
        <div className={classes.setMenu}>
          <div className={classes.menuTitle}>Set Key Frame to {displayTime(currentVideoTime)}</div>
          {
            !settingItems.length &&
            <div className={classes.emptyMessage}>
              All Key Frames Are Currently Set For This Video
            </div>
          }
          {
            settingItems.length > 0 && settingItems.map((keyframe) => {
              const { key, name } = keyframe;
              const loading = loadingSetItems[key];
              return <ButtonBase
                key={key}
                className={classnames(
                  classes.item, 
                  classes.setItem, 
                  { [classes.loadingItem]: loading }
                )}
                onClick={() => setKeyframe(keyframe, currentVideoTime)}>
                <span>{ name }</span>
                { loading && <CircularProgress size={25}/> }
              </ButtonBase>;
            })
          }
        </div>
      </KeyFrameDropdown>
    </div>

    <KeyFrameDropdown title='Go To Frame'>
      <div className={classes.goMenu}>
        <div className={classes.menuTitle}>Go To Saved Key Frames</div>
        {
          !goToItems.length &&
          <div className={classes.emptyMessage}>No Key Frames Set For This Video</div>
        }
        {
          goToItems.length > 0 && goToItems.map((keyframeData) => {
            const { key, name, seconds } = keyframeData;
            const loading = loadingGoToItems[key];
            return <div
              key={key}
              className={classnames(
                classes.item,
                { [classes.loadingItem]: loading }
              )}>
              <ButtonBase
                onClick={() => goToKeyFrame(keyframeData)}
                className={classnames(classes.keyframeInfo, { [classes.loadingItem]: loading })}
              >
                <span className={classes.goToTitle}>{name}</span>
                <span>{displayTime(seconds)}</span>
              </ButtonBase>
              <div className={classes.goToActions}>
                {
                  loading && <CircularProgress size={25}/>
                }
                {
                  !loading &&
                  <IconButton
                    className={classes.removeAction} 
                    onClick={() => removeKeyFrame(keyframeData)}>
                    <CancelIcon />
                  </IconButton>
                }
              </div>
            </div>;
          })
        }
      </div>
    </KeyFrameDropdown>
  </div>;
}

export default KeyFrameControls;

