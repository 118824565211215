import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NativeSelect from '@material-ui/core/NativeSelect';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import DrawIcon from '@material-ui/icons/CreateOutlined';
import LineIcon from '@material-ui/icons/Remove';
import CircleIcon from '@material-ui/icons/LensOutlined';
import UndoIcon from '@material-ui/icons/Undo';
import ClearIcon from '@material-ui/icons/Clear';
import Logger from 'js-logger';
import sortBy from 'lodash/sortBy';
import queryString from 'query-string';

import { SaveAnnotatedVideo } from '../../../network/videoAnalysisRequests';
import { GetVideo } from '../../../network/videoRequests';
import { GetAllVideoAnalyses, UpdateAnalysis } from '../../../network/videoAnalysisRequests';
import { ANALYSIS_STATUS } from '../../../constants/videoAnalysis.constants';
import { CHARACTERISTIC_SEVERITY } from '../../../constants/videoAnalysis.constants';
import ErrorSnackbar from '../../../components/errorSnackbar';
import CanvasLayer from './canvasLayer';
import SHAPES from './shapes/constants';
import useStyles from './styles';
import VideoWithCustomControls from './videoWithCustomControls';
import { sizeToFit } from '../../../utils/dimensions.utils';
import VideoStatus from '../../../constants/video.constants';
import { ANALYSIS_TYPE } from '../../../constants/videoAnalysis.constants';
import { GetMotionAttributes } from '../../../network/motionAttributeRequests';
import useWindowSize from '../../../utils/useWindowSize';
import { getOrganizationId } from '../../../utils/auth.utils';
import AnalysesSelection from './analysesSelection';
import { history } from '../../../store';
import usePermissionsCheck from '../../../utils/usePermissionsCheck';
import { PERMISSIONS } from '../../../constants/permissions.constants';
import AnnotationStatus from './annotationStatus';
import AnalysisHistory from './analysisHistory';
import KeyFrameControls from './keyFrameControls';
import { 
  addVideoAnnotationToAnalysis, 
  setVideoAnalyses, 
  clearVideoAnalyses,
  updateVideoAnalysis
} from '../../../store/videoAnalyses';
import { setMotionAttributes } from '../../../store/motionAttributes';

import CharacteristicSelection from './characteristicSelection';
import CharacteristicSubmission from './characteristicSubmission';
import { orderBy } from 'lodash';

const MODE_BUTTONS = [
  { mode: SHAPES.freehand, icon: <DrawIcon /> },
  { mode: SHAPES.line, icon: <LineIcon /> },
  { mode: SHAPES.circle, icon: <CircleIcon /> }
];

const PLAYBACK_SPEEDS = [1, 0.75, 0.5, 0.25, 0.2, 0.15, 0.1];
const DEFAULT_PLAYBACK_SPEED = 1;
const MINIMUM_VIDEO_LENGTH = 0.2;

const CLEARED = 'cleared';

function VideoAnalysis({ match, location }) {
  const canvasRef = useRef();
  const videoRef = useRef();
  const videoContainerRef = useRef();

  const windowSize = useWindowSize();
  const [defaultVideoDimensions, setDefaultVideoDimensions] = useState(null);
  const [resizedVideoDimensions, setResizedVideoDimensions] = useState(null);
  
  const [playbackRate, setPlaybackRate] = useState(DEFAULT_PLAYBACK_SPEED);
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [videoStartTime, setVideoStartTime] = useState(0);
  const [videoEndTime, setVideoEndTime] = useState(0);
  const [videoStartInputValue, setVideoStartInputValue] = useState(0);
  const [videoEndInputValue, setVideoEndInputValue] = useState(0);
  const [originalVideoLength, setOriginalVideoLength] = useState(0);
  useEffect(() => {
    setVideoStartInputValue(videoStartTime);
    setVideoEndInputValue(videoEndTime);
  }, [videoStartTime, videoEndTime]);

  const [reviewAnnotation, setReviewAnnotation] = useState(null);
  const [selectedMotionAttribute, setSelectedMotionAttribute] = useState('');
  const [videos, setVideos] = useState([]);
  const [selectedVideoIdx, setSelectedVideoIdx] = useState('');
  const [videoDataUrl, setVideoDataUrl] = useState(null);
  const [videoFps, setVideoFps] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [drawMode, setDrawMode] = useState(SHAPES.line);
  const [color, setColor] = useState('#FF0000');
  const [drawHistory, setDrawHistory] = useState([]);

  const analyses = useSelector(state => state.videoAnalyses);
  const motionAttributes = useSelector(state => state.motionAttributes);
  const dispatch = useDispatch();
  const [currentAnalysis, setCurrentAnalysis] = useState(null);
  const [previousAttribute, setPreviousAttribute] = useState(null);
  const [saveErrMsg, setSaveErrMsg] = useState('');

  const hasToolingPermission = usePermissionsCheck(PERMISSIONS.internalTooling);

  const angle = useMemo(() => {
    return videos && selectedVideoIdx !== null && videos[selectedVideoIdx] ?
      videos[selectedVideoIdx].videoAttributes.cameraAngle :
      null;
  }, [videos, selectedVideoIdx]);

  const classes = useStyles({
    severity: reviewAnnotation ? reviewAnnotation.swingCharacteristicSeverity : null
  });

  const values = queryString.parse(location.search);
  const analysisIdQueryParam = parseInt(values.analysisId);
  const { userId } = match.params;

  const selectAnalysis = useCallback((analysis) => {
    let searchParams = new URLSearchParams(location.search);
    searchParams.set('analysisId', analysis.id);
    history.push({ 
      pathname: history.location.pathname, 
      search: searchParams.toString()
    });
  }, [location.search]);

  const showVideoUI = useMemo(() => {
    return currentAnalysis && 
           currentAnalysis.status !== ANALYSIS_STATUS.requested &&
           currentAnalysis.status !== ANALYSIS_STATUS.submitted;
  }, [currentAnalysis]);

  const statusMessage = useMemo(() => {
    if (!currentAnalysis) {
      return '';
    }
    switch (currentAnalysis.status) {
      case ANALYSIS_STATUS.requested:
        return 'Videos have been requested but are not yet submitted by the player.';
      case ANALYSIS_STATUS.submitted:
        return 'Videos have been submitted and are currently being ' + 
          'uploaded to the cloud. Videos will appear here soon.';
      default:
        return '';
    };
  }, [currentAnalysis]);

  useEffect(() => {
    // Fetches analyses and motion attributes on load
    const token = axios.CancelToken.source();
    async function fetchAnalyses() {
      const orgId = getOrganizationId();
      try {
        let [requestedAnalyses, requestedMotionAttributes] = await Promise.all([
          GetAllVideoAnalyses(userId, orgId, null, token),
          GetMotionAttributes({ type: 'OnBaseU' })
        ]);
        requestedAnalyses = sortBy(requestedAnalyses, a => new Date(a.creationTimestamp))
          .reverse()
          .map(analysis => ({ ...analysis, loading: true, error: false }));
        dispatch(setMotionAttributes(requestedMotionAttributes));
        dispatch(setVideoAnalyses(requestedAnalyses));

      } catch(e) {
        if (!axios.isCancel(e)) {
          Logger.error(e);
          setError('There was an error fetching analyses, please refresh.');
        }
      }
    }
    fetchAnalyses();

    return function() {
      token.cancel();
      dispatch(clearVideoAnalyses());
    };
  }, [userId, dispatch]);

  // Loads first analysis if query param isn't set
  let newSelectedAnalysis = analyses && analyses.length > 0 && !analysisIdQueryParam 
    ? analyses[0] 
    : null;
  useEffect(() => {
    if (newSelectedAnalysis) {
      selectAnalysis(newSelectedAnalysis);
    }
  }, [analysisIdQueryParam, newSelectedAnalysis, selectAnalysis]);
  
  // loads the current analysis and analysis videos from redux
  const currentAnalysisByQueryIdParam = analyses.find(x => x.id === analysisIdQueryParam);
  useEffect(() => {
    if (currentAnalysis && currentAnalysis.id === currentAnalysisByQueryIdParam) return;
    
    let prevAttribute = null;
    const orderedAnalyses = orderBy(analyses, analysis => analysis.editedTimestamp, ['desc']);
    var analysisIdx = orderedAnalyses.findIndex(analysis => analysis.id === analysisIdQueryParam);
    
    if (analysisIdx < orderedAnalyses.length - 1) {
      // Not all analyses get assigned an attribute to work on. 
      // Try to find the last assigned attribute. 
      const previousAnalyses = orderedAnalyses.slice(analysisIdx + 1);
      const prevAttributeAnalysis = previousAnalyses.find(analysis => 
        analysis.selectedMotionAttributeId);
      
      if (prevAttributeAnalysis) {
        prevAttribute = motionAttributes.find(attribute => 
          attribute.id === prevAttributeAnalysis.selectedMotionAttributeId);
      }
    }
    setPreviousAttribute(prevAttribute && prevAttribute.name);

    setCurrentAnalysis(currentAnalysisByQueryIdParam);
    if (currentAnalysisByQueryIdParam != null 
      && currentAnalysisByQueryIdParam.videoAnalysisVideos != null) 
    {
      setVideos(currentAnalysisByQueryIdParam.videoAnalysisVideos.map(join => join.video));
      if (currentAnalysisByQueryIdParam.videoAnalysisVideos.length > 0 
        && selectedVideoIdx === '') 
      {
        setSelectedVideoIdx(0);
      }
    };
  }, [
    analysisIdQueryParam, 
    currentAnalysisByQueryIdParam, 
    analyses, 
    motionAttributes, 
    selectedVideoIdx, 
    currentAnalysis
  ]);

  const containerRef = useRef();
  useEffect(() => {
    // resizes canvas when window size changes
    if (defaultVideoDimensions == null || !showVideoUI) return;
    const { clientWidth: maxWidth, offsetTop } = videoContainerRef.current;
    const maxHeight = window.innerHeight - offsetTop - 150;
    const resize = sizeToFit(
      defaultVideoDimensions.width, 
      defaultVideoDimensions.height, 
      maxWidth, 
      maxHeight);
    setResizedVideoDimensions(resize);
  }, [windowSize, defaultVideoDimensions, currentAnalysis, showVideoUI]);

  const selectedVideo = videos[selectedVideoIdx];
  const { selectedVideoId, status } = 
    selectedVideo ? { selectedVideoId: selectedVideo.id, status: selectedVideo.status } : {};

  useEffect(() => {

    if (!selectedVideoId || !status) {
      return;
    }

    // clear existing changes
    setPlaybackRate(1);
    setVideoStartTime(0);
    setVideoEndTime(0);
    setVideoDataUrl(null);
    
    // clear existing drawings
    if (canvasRef.current != null) {
      setDrawHistory([]);
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }

    // loads the selected video
    const cancelToken = axios.CancelToken.source();
    if (status !== VideoStatus.available){
      setVideoDataUrl(null);
      return;
    };

    async function getVideoBlob() {
      setError(null);
      setLoading(true);
      try {
        const data = await GetVideo(
          selectedVideoId, 
          { includeDownload: true }, 
          cancelToken);
        const { download } = data;
        const { width, height, downloadUrl, framesPerSecond } = download;
        setDefaultVideoDimensions({ width, height });
        setVideoDataUrl(downloadUrl);
        setVideoFps(framesPerSecond);
      }
      catch (e) {
        if (axios.isCancel(e)) return;
        Logger.error(e, `Error fetching download url and size for video ${selectedVideoId}`);
        setError('Error downloading selected video.');
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    }
    getVideoBlob();

    return () => cancelToken.cancel();
  }, [status, selectedVideoId]);

  const onNewCharacteristicChanged = useCallback((angle, characteristic) => {
    // set the angle first
    const angleIdx = videos.findIndex((v) => v.videoAttributes.cameraAngle === angle);
    setSelectedVideoIdx(angleIdx);

    // now we can set a characteristic
    setSelectedMotionAttribute(characteristic ? characteristic.name : '');

    setTimeout(() => {
      // Need to guard canvas here as this page renders slowly.
      // If user selects characteristic too soon, canvas may not be set up yet.
      if (canvasRef.current) {
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
    }, 0);

    setReviewAnnotation(null);
  }, [videos]);

  const onReviewClicked = useCallback((annotation, angle, characteristic) => {
    // this checks prevents syncing errors with equality checks in useEffects
    if (reviewAnnotation && annotation && reviewAnnotation.id === annotation.id) {
      return;
    }

    // if a review is clicked, we will clear what is there anyways without deleting history
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    // set state for review
    const angleIdx = videos.findIndex((v) => v.videoAttributes.cameraAngle === angle);
    setSelectedVideoIdx(angleIdx);
    setSelectedMotionAttribute(characteristic ? characteristic.name : '');

    // set the annotation to review
    setReviewAnnotation(annotation || null);
  }, [videos, reviewAnnotation]);

  const onClear = () => {
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setDrawHistory(prev => prev.length > 0 && prev[0] === CLEARED
      ? prev 
      : [CLEARED, ...prev]);
    setVideoEndTime(originalVideoLength);
    setVideoStartTime(0);
    setPlaybackRate(DEFAULT_PLAYBACK_SPEED);
  };

  const onItemDrawn = shape => {
    const ctx = canvasRef.current.getContext('2d');
    shape.drawOnCanvas(ctx);
    setDrawHistory(prev => prev.length > 0 && prev[0] === CLEARED
      ? [shape] 
      : [shape, ...prev]);
  };

  const onUndo = () => {
    if (drawHistory.length === 0) return;
    const ctx = canvasRef.current.getContext('2d');
    const updatedHistory = drawHistory.slice(1);
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    updatedHistory.forEach(shape => shape.drawOnCanvas(ctx));
    setDrawHistory(updatedHistory);
  };

  useEffect(() => {
    if (!canvasRef || !canvasRef.current) {
      return;
    }

    if (!reviewAnnotation) {
      return;
    }

    const { width, height } = resizedVideoDimensions;
    const ctx = canvasRef.current.getContext('2d');

    // clear what was there before
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    // add new overlay
    const image = new Image();
    image.onload = function() {
      ctx.drawImage(image, 0, 0, width, height);
    };
    image.src = `data:image/png;base64,${reviewAnnotation.overlayImage}`;

  }, [reviewAnnotation, resizedVideoDimensions]);

  const onSave = async (severity) => {
    if (selectedVideoIdx === '') {
      setError('Select a video to work on.');
      return;
    }
    if (selectedMotionAttribute === '') {
      setError('Select the swing characteristic being analyzed.');
      return;
    }

    if (Math.abs(videoStartTime - videoEndTime) < MINIMUM_VIDEO_LENGTH) {
      setError(`Please make sure your trimmed video is 
      at least ${MINIMUM_VIDEO_LENGTH} seconds.`);
      return;
    }

    const dataUrl = canvasRef.current.toDataURL();
    const overlayImageString = dataUrl.split(',')[1];
    const selectedVideo = videos[selectedVideoIdx];
    const body = { 
      sourceVideoId: selectedVideo.id, 
      overlayImage: overlayImageString, 
      playbackRate,
      startTimeSeconds: videoStartTime,
      stopTimeSeconds: videoEndTime,
      swingCharacteristic: selectedMotionAttribute,
      swingCharacteristicSeverity: severity,
      title: selectedMotionAttribute
    };
    try {
      setError(null);
      setLoading(true);
      let annotation = await SaveAnnotatedVideo(currentAnalysis.id, body);
      dispatch(addVideoAnnotationToAnalysis(annotation));
    }
    catch (e) {
      Logger.error(e, 'Error saving annotated video ' + selectedVideo.id);
      setError('Error saving annotated video, please try again.');
    }
    finally {
      setLoading(false);
    }
  };

  const onInputChange = setInputValue => e => {
    setInputValue(e.target.value);
  };

  const onInputBlur = setValue => e => {
    if (e.target.value.trim() !== '') {
      setValue(parseFloat(e.target.value));
    } else {
      setValue(0);
    }
  };

  const onInputKeyDown = e => {
    if(e.keyCode === 13 || e.which === 13){
      e.target.blur();
    } 
  };

  const setVideoEndTimeGuard = (setEndTimeFunction) => {
    return (newEndTime) => {
      if(newEndTime < videoStartTime) {
        setError('Make sure end time is after start time.');             
        return;
      }

      if (Math.abs(videoStartTime - newEndTime) < MINIMUM_VIDEO_LENGTH) {
        setError(`Make sure end time and start time are at least
         ${MINIMUM_VIDEO_LENGTH} seconds apart.`);          
        return;
      }

      if(newEndTime > originalVideoLength) {
        setEndTimeFunction(originalVideoLength);
        setError('That time exeeds the maximum length of the video.');
        return;
      }

      setEndTimeFunction(newEndTime);              
    };
  };

  const setVideoStartTimeGuard = (setStartTimeFunction) => {
    return (newStartTime) => {
      if(newStartTime > videoEndTime) {
        setError('Make sure start time is before end time.');      
        return;
      }

      if (Math.abs(videoEndTime - newStartTime) < MINIMUM_VIDEO_LENGTH) {
        setError(`Make sure start time and end time are at least
         ${MINIMUM_VIDEO_LENGTH} seconds apart.`);          
        return;
      }

      if(newStartTime < 0) {
        setStartTimeFunction(0);
        setError('Start time must be zero or greater.');
        return;
      }    

      setStartTimeFunction(newStartTime);
    };
  };

  const seekTime = useCallback((seconds) => {
    if (videoRef && videoRef.current) {
      videoRef.current.currentTime = seconds;
      setCurrentVideoTime(seconds);
    }
  }, [videoRef]);

  const onVideoTimeUpdate = useCallback(seconds => setCurrentVideoTime(seconds), []);
  const onVideoLoad = useCallback(duration => {
    setOriginalVideoLength(duration);
    setVideoEndTime(duration);
  }, []);

  const finishAnnotations = async () => {
    const analysisCopy = {
      ...currentAnalysis,
      status: ANALYSIS_STATUS.readyForActionPlan
    };
    setSaveErrMsg('');
    try {
      const updatedAnalysis = await UpdateAnalysis(analysisCopy.id, analysisCopy);
      dispatch(updateVideoAnalysis(updatedAnalysis));
    } catch(e) {
      Logger.error('Error when attempting to update analysis to readyForActionPlan', e);
      setSaveErrMsg('Error when finishing annotations. Please try again.');
    }
  };

  const notReadyToFinishStatuses = [
    ANALYSIS_STATUS.requested,
    ANALYSIS_STATUS.completed,
    ANALYSIS_STATUS.readyForActionPlan
  ];

  const showFinishButton = hasToolingPermission &&
    currentAnalysis && 
    currentAnalysis.videoAnnotations &&
    currentAnalysis.videoAnnotations.length > 0 &&
    !notReadyToFinishStatuses.includes(currentAnalysis.status); 

  const sourceVideoIsProcessing = videos[selectedVideoIdx] && 
    videos[selectedVideoIdx].status !== VideoStatus.available;

  return <div ref={containerRef}>
    <ErrorSnackbar message={error} onClose={() => setError(null)} />
    <div className={classes.selectionContainer}>
      <AnalysesSelection
        analyses={analyses} 
        selectedAnalysisId={analysisIdQueryParam || ''} 
        onClick={selectAnalysis}
        className={classes.analysisSelection}
      />
      
      {currentAnalysis && 
        <Typography variant='h6'>
          {currentAnalysis.type}
          {previousAttribute && currentAnalysis.type === ANALYSIS_TYPE.checkIn && 
          ` - ${previousAttribute}`}
        </Typography>
      }
    </div>

    {currentAnalysis && !showVideoUI &&
      <div className={classes.statusMessage}>
        <Typography align='center'>{statusMessage}</Typography>
      </div>
    }

    {showVideoUI && <div>
      <div className={classes.bodyContainer}>
        {!reviewAnnotation &&
          <div className={classes.drawingControlsContainer}>
            {MODE_BUTTONS.map(({ mode, icon }) => <Button key={mode}
              style={{ backgroundColor: drawMode === mode ? 'lightgray' : null }}
              onClick={() => setDrawMode(mode)}
            >
              {icon}
            </Button>)}
            <input type='color' value={color} onChange={e => setColor(e.target.value)} style={{ margin: '5px' }} />
            <Button title='undo' onClick={onUndo} disabled={drawHistory.length === 0}>
              <UndoIcon />
            </Button>
            <Button title='clear' 
              onClick={onClear} disabled={drawHistory.length === 0 || drawHistory[0] === CLEARED}
            >
              <ClearIcon />
            </Button>
          </div>
        }
        <div ref={videoContainerRef} className={classes.videoCanvasContainer}>
          {resizedVideoDimensions && <div className={classes.canvasContainer}>
            <canvas
              width={resizedVideoDimensions.width} height={resizedVideoDimensions.height}
              ref={canvasRef} 
            />
          </div>}
          {resizedVideoDimensions && !reviewAnnotation && videoDataUrl && 
            <div className={classes.canvasContainer}>
              <CanvasLayer 
                width={resizedVideoDimensions.width} 
                height={resizedVideoDimensions.height}
                className={classes.canvasOverlay}
                onDraw={onItemDrawn}
                mode={drawMode}
                color={color}
              />
            </div>
          }
          {loading && <div className={classes.circularProgressContainer}>
            <CircularProgress />  
          </div>}
          {sourceVideoIsProcessing && 
            <Typography>
              This source video is still processing. Please try again soon.
            </Typography>
          }
          {!sourceVideoIsProcessing && videoDataUrl && resizedVideoDimensions && 
           <div>
             <VideoWithCustomControls 
               ref={videoRef}
               dataUrl={videoDataUrl}
               videoFps={videoFps}
               videoHeight={resizedVideoDimensions.height}
               videoWidth={resizedVideoDimensions.width}
               disableTrimmingUI={Boolean(reviewAnnotation)}
               startTime={
                 reviewAnnotation ? 
                   reviewAnnotation.startTimeSeconds :
                   videoStartTime
               }
               endTime={
                 reviewAnnotation ? 
                   reviewAnnotation.stopTimeSeconds :
                   videoEndTime
               }
               currentVideoTime={currentVideoTime}
               setStartTime={setVideoStartTimeGuard(setVideoStartTime)}
               setEndTime={setVideoEndTimeGuard(setVideoEndTime)}
               playbackRate={
                 reviewAnnotation ? 
                   reviewAnnotation.playbackRate :
                   playbackRate
               }
               onVideoLoad={onVideoLoad}
               onVideoTimeUpdate={onVideoTimeUpdate}
             />
             {reviewAnnotation && 
              <div className={classes.reviewControls}>
                <KeyFrameControls
                  analysisId={currentAnalysis ? currentAnalysis.id : null}
                  currentVideoTime={currentVideoTime}
                  video={videos[selectedVideoIdx]}
                  onTimeSelected={seekTime}
                />
                <AnnotationStatus annotation={reviewAnnotation} />
              </div>
             }
             {!reviewAnnotation &&
             <div className={classes.trimmingControls}>
               <div className={classes.trimmingSetter}>
                 <label htmlFor='startTime'>Start:</label>
                 <input 
                   className={classes.trimmingUserInput}
                   name='startTime'
                   id='startTime'
                   type='number' 
                   step={0.01}
                   min={0}
                   max={videoEndTime}
                   value={videoStartInputValue} 
                   onChange={onInputChange(setVideoStartInputValue)} 
                   onBlur={onInputBlur(setVideoStartTimeGuard(setVideoStartTime))}
                   onKeyDown={onInputKeyDown}
                 />                 
               </div>

               <div className={classes.secondaryVideoControls}>
                 <div className={classes.playbackRateContainer}>
                   <NativeSelect
                     value={playbackRate}
                     onChange={e => setPlaybackRate(e.target.value)}
                     inputProps={{
                       name: 'playbackRate',
                       id:'playbackRate'
                     }}
                   >
                     {PLAYBACK_SPEEDS.map((value) => {
                       return <option key={`speed-${value}`} value={value}>{value}x</option>; 
                     })}
                   </NativeSelect>
                 </div>

                 {
                   videos && Boolean(videos.length) && selectedVideoIdx !== '' &&
                  <KeyFrameControls
                    analysisId={currentAnalysis ? currentAnalysis.id : null}
                    currentVideoTime={currentVideoTime}
                    video={videos[selectedVideoIdx]}
                    onTimeSelected={seekTime}
                  />
                 }
               </div>

               <div className={classes.trimmingSetter}>
                 <label htmlFor='endTime'>End:</label>   
                 <input
                   className={classes.trimmingUserInput}
                   name='endTime'
                   id='endTime'
                   type='number' 
                   step={0.01}
                   min={videoStartTime}
                   max={originalVideoLength}
                   value={videoEndInputValue} 
                   onChange={onInputChange(setVideoEndInputValue)} 
                   onBlur={onInputBlur(setVideoEndTimeGuard(setVideoEndTime))}
                   onKeyDown={onInputKeyDown}
                 />               
               </div>
             </div>}
             {reviewAnnotation && reviewAnnotation.swingCharacteristicSeverity &&
              <div className={classes.severityContainer}>
                <span><div className={classes.severityDot}></div></span>
                <span className={classes.severityText}>{reviewAnnotation.swingCharacteristic}</span>
                <span>&nbsp;is marked as&nbsp;</span>
                <span className={classes.severityText}>{
                  reviewAnnotation.swingCharacteristicSeverity === CHARACTERISTIC_SEVERITY.none ?
                    'Looks Good' : 'Needs Work'
                }</span>
              </div>}    
           </div>}
        </div>

        <div className={classes.selectionTools}>
          <div className={classes.characteristicSelection}>
            {currentAnalysis && currentAnalysis.videoAnalysisVideos && <CharacteristicSelection
              section={angle}
              reviewAnnotation={reviewAnnotation}
              characteristics={motionAttributes}
              characteristic={selectedMotionAttribute}
              analysis={currentAnalysis}
              onCharacteristicSelected={onNewCharacteristicChanged}
              onReviewSelected={onReviewClicked}
            />}
          </div>

          <CharacteristicSubmission 
            analysis={currentAnalysis}
            characteristic={selectedMotionAttribute}
            onSubmit={onSave}
            existingAnnotation={reviewAnnotation}
            loading={loading}
            onUpdate={(annotation) => setReviewAnnotation(annotation)}
          />

          {showFinishButton &&
            <Button 
              onClick={finishAnnotations}
              className={classes.finishedButton} 
              variant='contained' 
              color='primary'
            >
              Finished Annotating
            </Button>
          }
          <Typography color='error'>{saveErrMsg}</Typography>
        </div>
      </div>
    </div>}

    <AnalysisHistory 
      selectedAnalysisId={currentAnalysis && currentAnalysis.id}
      userId={userId}
      onChangeSelectedAnalysis={selectAnalysis}
    />
  </div>;
}

export default VideoAnalysis;
