import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import VideoListItem from './videoListItem';
import useStyles from './styles';
import SearchBar from '../../../components/searchBar';

function SelectableVideoList({ 
  sections, 
  selectedVideoIds, 
  onToggleSelection,
  onPreviewClicked,
  loading 
}) {
  const [searchText, setSearchText] = useState('');
  const filteredSections = useMemo(() => {
    const trimmedSearchText = searchText.trim().toLowerCase();
    if (trimmedSearchText === '') return sections;

    return sections.map(section => ({
      ...section,
      videos: section.videos.filter(video => 
        video.title && video.title.toLowerCase().includes(trimmedSearchText))
    }));
  }, [sections, searchText]);
  
  const classes = useStyles();

  return <div>
    <div className={classes.searchBarContainer}>
      <SearchBar 
        value={searchText} 
        onChange={e => setSearchText(e.target.value)} 
        onClear={() => setSearchText('')} 
      />
    </div>

    {loading && <div>
      <CircularProgress />
    </div>}

    {filteredSections.map((section, sectionIdx) => <div key={`section-${sectionIdx}`}>
      {section.header && section.videos.length > 0 && <React.Fragment>
        <Typography variant='h6' className={classes.header}>
          {section.header}
        </Typography>
        <Divider />
      </React.Fragment>}

      {section.videos.map(video => <VideoListItem 
        key={video.id}
        video={video}
        selected={selectedVideoIds.includes(video.id)}
        onToggleSelection={onToggleSelection}
        onPlayPreview={() => onPreviewClicked(video)}
        displayDate={section.displayDate}
      />)}
    </div>)}
  </div>;
}

SelectableVideoList.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string,
    videos: PropTypes.arrayOf(PropTypes.object)
  })),
  selectedVideoIds: PropTypes.array,
  onToggleSelection: PropTypes.func,
  onPreviewClicked: PropTypes.func,
  loading: PropTypes.bool
};

export default SelectableVideoList;
