import { getOrganizationId } from '../utils/auth.utils';
import Network from './network';
import orderBy from 'lodash/orderBy';
import { LESSON_TYPES } from '../constants/lessons.constants';

const URL = 'api/lessons';

export async function GetLessonTemplates(cancelSource) {
  const params = { 
    lessonType: LESSON_TYPES.template, 
    organizationId: getOrganizationId() 
  };
  return await GetLessons(params, null, cancelSource);
}

export async function GetPlayerLessons(cancelSource) {
  const params = { lessonType: LESSON_TYPES.playerLesson };
  return await GetLessons(
    params, 
    ['User', 'LessonSegments', 'LessonSegments.Drill'], 
    cancelSource);
}

export async function GetPopupCaptures(cancelSource) {
  const params = { lessonType: LESSON_TYPES.popUpCapture };
  return await GetLessons(
    params, 
    ['User', 'LessonSegments', 'LessonSegments.Drill'], 
    cancelSource);
}

async function GetLessons(params, subresources, cancelSource) {
  if (subresources) {
    params.subresources = subresources;
  }
  const resp = await Network.Get(URL, params, cancelSource);
  const templates = resp.data.items;
  return orderBy(templates, 'updatedTimestamp', 'desc');
}

export async function GetLesson(id, cancelSource) {
  const url = URL + '/' + id;
  const resp = await Network.Get(url, null, cancelSource);
  const lesson = resp.data;
  if (lesson.lessonSegments) {
    lesson.lessonSegments = orderBy(lesson.lessonSegments, 'order');
  }
  return lesson;
}

export async function CreateNewLessonTemplate(lessonTemplate) {
  const nowString = (new Date()).toISOString();
  const body = {
    ...lessonTemplate,
    organizationId: getOrganizationId(),
    lessonType: 'Template',
    state: 'Complete',
    startTimestamp: nowString,
    updatedTimestamp: nowString,
    lessonSegments: lessonTemplate.lessonSegments?.map((segment, order) => ({
      ...segment,
      order
    }))
  };
  const resp = await Network.Post(URL, body);
  return resp.data;
}

export async function UpdateLessonTemplate(id, lessonTemplate) {
  const url = URL + '/' + id;
  const resp = await Network.Put(url, lessonTemplate);
  const lesson = resp.data;
  if (lesson.lessonSegments) {
    lesson.lessonSegments = orderBy(lesson.lessonSegments, 'order');
  }
  return lesson;
}
