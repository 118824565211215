import React from 'react';
import { ButtonBase, Grid, ListItem, Paper, Typography } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import useStyles from './styles';

function PlayerListItem({ user, captureEventsMap, onEventPress }) {
  const classes = useStyles();
  const { captureEventUsers } = user;

  const attendedEvents = orderBy(captureEventUsers
    .map(eventUser => ({ 
      ...captureEventsMap[eventUser.captureEventId]?.item, 
      captureEventUserId: eventUser.id,
      userId: eventUser.userId
    }))
    .filter(x => x.id != null), 
  'startDate',
  'desc');

  return <ListItem divider>
    <Grid container spacing={1}>
      <Grid container item xs={12} spacing={1} alignItems='center'>
        <Grid item>
          <Typography variant='h6'>{user.fullName}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body2'>&nbsp;({user.emailAddress})</Typography>
        </Grid>
      </Grid>

      {attendedEvents.length === 0 && <Typography variant='caption'>
        No registered events.
      </Typography>}

      {attendedEvents.map(({ id, name, startDate, endDate, userId, captureEventUserId }) => 
        <Grid item key={captureEventUserId}>
          <ButtonBase onClick={() => onEventPress(id, userId, captureEventUserId)}>
            <Paper className={classes.paper}>
              <Typography>{name}</Typography>
              <Typography variant='caption'>{startDate} - {endDate}</Typography>
            </Paper>
          </ButtonBase>
        </Grid>)}
    </Grid>
  </ListItem>;
}

export default PlayerListItem;
