import React from 'react';
import TextField from '@material-ui/core/TextField';

function TemplateInput({ label, value, setValue, maxLength, ...props }) {
  return <TextField 
    label={label}
    value={value}
    onChange={e => { const { value } = e.target; setValue(value); }}
    variant='outlined'
    fullWidth
    helperText={`${value.length}/${maxLength}`}
    error={value.length > maxLength}
    {...props}
  />;
}

export default TemplateInput;