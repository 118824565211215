import { sequenceBodyPartsToNumbersFormatter } from '../../utils/sequence.utils';

export const sequenceValues = [
  // Peak Speed Sequence
  [
    {
      key: 'pelvisPeakSpeedSequence',
      title: 'Pelvis Peak Speed Sequence'
    },
    {
      key: 'torsoPeakSpeedSequence',
      title: 'Torso Peak Speed Sequence'
    },
    {
      key: 'upperArmPeakSpeedSequence',
      title: 'Upper Arm Peak Speed Sequence'
    },
    {
      key: 'handPeakSpeedSequence',
      title: 'Hand Peak Speed Sequence'
    }
  ],
  // Transition Sequence
  [
    {
      key: 'pelvisTransitionSequence',
      title: 'Pelvis Transition Sequence'
    },
    {
      key: 'torsoTransitionSequence',
      title: 'Torso Transition Sequence'
    },
    {
      key: 'upperArmTransitionSequence',
      title: 'Upper Arm Transition Sequence'
    },
    {
      key: 'handTransitionSequence',
      title: 'Hand Transition Sequence'
    }
  ]
];

export const sequenceSummaries = [
  {
    key: 'peakSpeedSequence',
    title: 'Peak Speed Sequence',
    formatter: sequenceBodyPartsToNumbersFormatter,
    abbreviation: 'PSS'
    
  },
  {
    key: 'transitionSequence',
    title: 'Transition Sequence',
    formatter: sequenceBodyPartsToNumbersFormatter,
    abbreviation: 'TS'
  }
];
