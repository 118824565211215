import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  listItemContents: {
    border: '1px solid gray',
    width: '100%',
    height: '100%',
    padding: '5px 10px',
    borderRadius: '5px'
  },
  drillVariationText: {
    whiteSpace: 'pre'
  },
  list: {
    width: '100%'
  }
}));

export default useStyles;
