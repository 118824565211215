// Actions
const SET_ITEMS = 'kdashboard/orgActionItems/set';
const REMOVE_USER = 'kdashboard/orgActionItems/remove';

// Reducer
const initialState = [];
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ITEMS:
      return action.items;
    case REMOVE_USER:
      return state.map(item => 
        item.action !== action.actionItem || !item.userIds.includes(action.userId)
          ? item
          : { ...item, userIds: item.userIds.filter(id => id !== action.userId) }
      );
    default: 
      return state;
  }
}

// Action Creators
export function SetOrgActionItems(items) {
  return { type: SET_ITEMS, items };
}

export function removeUserActionItem(actionItem, userId) {
  return { type: REMOVE_USER, actionItem, userId };
}