import palette from '../../../../styles/palette';
import { makeStyles } from '@material-ui/core';

export default makeStyles(_ => ({
  listItemContainer: {
    position: 'relative'
  },
  listItem: {
    color: palette.white,
    padding: '15px 15px 10px 0',
    marginLeft: '15%',
    cursor: 'pointer',
    position: 'relative',
    zIndex: 2
  },
  selectedListItem: {
    color: palette.deepBlue
  },
  selectedItemTop: {
    position: 'absolute',
    width: '100%',
    backgroundColor: palette.deepBlue,
    height: '10px',
    top: '-10px',
    borderRadius: '0 0 10px 0',
    zIndex: 2
  },  
  selectedItemBottom: {
    position: 'absolute',
    width: '100%',
    backgroundColor: palette.deepBlue,
    height: '5px',
    bottom: '-5px',
    borderRadius: '0 5px 0 0',
    zIndex: 2
  },
  selectedItemBackground: {
    backgroundColor: palette.subtleWhite,
    position: 'absolute',
    height: 'calc(100% + 15px)',
    width: '95%',
    top: '-10px',
    right: 0,

    clipPath: 'polygon(0 0, 10% 100%, 110% 110%, 110% 0)',
    '-webkit-clip-path': 'polygon(0 0, 10% 100%, 110% 110%, 110% 0)'
  },
  actionTakenText: {
    fontFize: '14px'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  icon: {
    color: palette.success,
    marginRight: '15px'
  }
}));
