import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(_ => ({
  videoContainer: {
    fontStyle: 'italic',
    textAlign: 'center',
    marginBottom: '50px',
    width: '400px',
    height: '225px'
  },
  noVideo: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  hasVideo: {
    marginTop: '10px'
  },
  formField: {
    width: '100%'
  },
  textfieldsContainer: {
    height: '600px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  videoImg: {
    width: '127px',
    height: '75px'
  },
  videoTitle: {
    marginLeft: '15px'
  },
  createButton: {
    height: '50px'
  },
  videoComponentWrapper: {
    marginBottom: '20px'
  },
  dialog: {
    height: 'calc(100% - 64px)'
  },
  search: {
    margin: '0px 20px 10px 20px'
  },
  cameraAngleSelect: {
    width: '100%'
  }
}));