import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  list: {
    height: '60vh'
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px'
  }
}));

export default useStyles;
