import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import classNames from 'classnames';
import log from 'js-logger';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useRecentMotions from './useRecentMotions';
import CustomCheckbox from './customCheckbox';
import MotionListItem from './motionListItem';
import ConfirmationDialog from './confirmationDialog';
import MotionDetailsPanel from './motionDetailsPanel';
import { PERMISSIONS } from '../../../constants/permissions.constants';
import { GetEvalList } from '../../../network/evalReportRequests';
import useIsMobile from '../../../utils/useIsMobile';

import useStyles from './styles';
import { FormControlLabel, CircularProgress } from '@material-ui/core';
import NoDataMessage from '../../../components/noDataMessage';
import Logger from 'js-logger';
import { GetMotionsForUserInCurrentOrg } from '../../../network/motionRequests';
import { 
  LAUNCH_MONITOR, 
  FULL_MOTION_METRICS, 
  FORCE_PLATE, 
  HITTING_DEVICE_SENSOR 
} from '../../../constants/motionSubresources.constants';
import { ConvertSubresourcesForMotions } from '../../../utils/unitConverter.utils';
import SelectedPercentileSpeeds from './selectedPercentileSpeeds';

const OLD_MOTIONS_PER_LOAD = 25;

function LiveCapture({ match }) {
  const isMobile = useIsMobile();

  const [ motions, setMotions ] = useState([]);
  const [ loadingOldMotions, setLoadingOldMotions ] = useState(false);
  const [ noMoreOldMotions, setNoMoreOldMotions ] = useState(false);
  const [ selectedMotion, setSelectedMotion ] = useState({});
  const [ checkedMotionIds, setCheckedMotionIds ] = useState([]);
  const checkedMotionIdsMissingEV = useMemo(() => {
    const motionIdMap = keyBy(motions, 'id');
    return checkedMotionIds
      .map(id => motionIdMap[id])
      .filter(m => m.launchMonitorData === null || m.launchMonitorData.ballspeedMph === null)
      .map(m => m.id);
  }, [motions, checkedMotionIds]);
  const [ confirmPush, setConfirmPush ] = useState(false);
  const [ alreadyPushedMotionIds, setAlreadyPushedMotionIds ] = useState([]);
  
  const { userId } = match.params;
  const { newMotionIds, firstRun } = useRecentMotions(setMotions, userId);
  const featurePermissions = useSelector(state => state.featurePermissions);
  const currentPlayer = useSelector(state => state.currentPlayer);
  const canHandleLMData = featurePermissions.includes(PERMISSIONS.canHandleLaunchMonitorData);
  const canPushToKGo = featurePermissions.includes(PERMISSIONS.kGoFeatureSet);
  const internalToolingEnabled = featurePermissions.includes(PERMISSIONS.internalTooling);
  const classes = useStyles();

  const now = useMemo(() => new Date(), []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchEvaluationMotions() {
      try {
        const evals = await GetEvalList(userId);
        const evalSwings = evals.map(({ swings }) => swings.map(x => x.motionId));
        setAlreadyPushedMotionIds(flatten(evalSwings));
      } catch (e) {
        if (axios.isCancel(e)) return;
        log.error(e, 'Unable to fetch eval swings for user ' + userId);
      }
    }
    if (canPushToKGo) {
      fetchEvaluationMotions();
    }

    return cancelToken.cancel;
  }, [userId, canPushToKGo]);

  
  useEffect(() => {
    // Select the first motion if on desktop. 
    // Only do this on first render / if no motion is already selected.
    if(motions.length > 0 && !isMobile && selectedMotion.id == null){
      onMotionListItemClick(motions[0])();
    }
  }, [motions, isMobile, selectedMotion.id]); 

  function onDataSaved(data, key) {
    setMotions(motions.map(m => m.id === data.motionId ? { ...m, [key]: data } : m));
    if (selectedMotion.id === data.motionId) {
      setSelectedMotion(m => ({ ...m, [key]: data }));
    }
  }

  function onMotionDeleted(motionId) {
    if (checkedMotionIds.includes(motionId)) {
      setCheckedMotionIds(prev => prev.filter(id => id !== motionId));
    }
    setMotions(motions.filter(m => m.id !== motionId));
    setSelectedMotion({});
  }

  function onPushToKGoClick() {
    if (!canPushToKGo || checkedMotionIds.length === 0 || checkedMotionIdsMissingEV.length > 0) {
      return;
    }
    setConfirmPush(true);
  }

  async function onLoadOldMotionsClick() {
    setLoadingOldMotions(true);
    const cancelToken = axios.CancelToken.source();
    try {
      let earlierThan = now.toISOString();
      if (motions.length > 0) {
        let earlierThanDate = new Date(motions[motions.length - 1].timestamp);
        earlierThan = earlierThanDate.toISOString();
      }
      let params = {
        earlierThan,
        count: OLD_MOTIONS_PER_LOAD,
        subresources: [LAUNCH_MONITOR, FULL_MOTION_METRICS, FORCE_PLATE, HITTING_DEVICE_SENSOR]
      };
      var pagedMotions = await GetMotionsForUserInCurrentOrg(userId, params, cancelToken);
      var oldMotions = ConvertSubresourcesForMotions(pagedMotions.items);
      setMotions(prev => [...prev, ...oldMotions]);
      if (oldMotions.length < OLD_MOTIONS_PER_LOAD) {
        setNoMoreOldMotions(true);
      }
    }
    catch (e) {
      if (!axios.isCancel(e)) {
        Logger.error(e, 'Error loading old motions.');
      }
    }
    setLoadingOldMotions(false);
  }

  const onCheckboxChanged = motion => e => {
    if (e.target.checked) {
      setCheckedMotionIds(prev => [...prev, motion.id]);
    } else {
      setCheckedMotionIds(prev => prev.filter(id => id !== motion.id));
    }
  };

  const onMotionListItemClick = motion => _ => {
    setSelectedMotion(motion);
  };

  const getCheckedMotions = () => motions.filter(m => checkedMotionIds.includes(m.id));

  function onPushComplete(motionIds) {
    setAlreadyPushedMotionIds(prev => [...prev, ...motionIds]);
    setCheckedMotionIds([]);
  }

  function renderCheckbox(motion) {
    const alreadyInKGo = alreadyPushedMotionIds.includes(motion.id);
    const checked = checkedMotionIds.includes(motion.id);
    const label = alreadyInKGo ? 'Already In KGo' : checked ? 'KGo' : 'Add to KGo';
    const error = checkedMotionIdsMissingEV.includes(motion.id);
    const labelClass = classNames(classes.grayText, { 
      [classes.itemChecked]: checked,
      [classes.itemError]: error
    });

    return <FormControlLabel className={classes.checkboxContainer} 
      control={alreadyInKGo ? <div /> : <CustomCheckbox
        onClick={e => e.stopPropagation()}
        onChange={onCheckboxChanged(motion)}
        checked={checkedMotionIds.includes(motion.id)}
        error={error}
      />}
      label={<Typography className={labelClass}>
        {label} {error && <i>(requires exit velocity)</i>}
      </Typography>}
      disabled={alreadyInKGo}
    />;
  }

  function renderMotionDetails(motion) {
    const motionDetailsPaneNode = <MotionDetailsPanel 
      key={motion.id}
      motion={motion}
      onSave={onDataSaved}
      onDeleted={onMotionDeleted}
      onClose={() => setSelectedMotion({})}
    />;
    
    return isMobile 
      ? <Dialog open fullScreen>
        {motionDetailsPaneNode}
      </Dialog>
      : motionDetailsPaneNode;
  }
  
  const playerName = currentPlayer && currentPlayer.firstName + ' ' + currentPlayer.lastName;

  return <div>
    <div className={classes.container}>
      <div className={classes.motionsList} style={{ width: isMobile ? '100%' : '30%' }}>
        <div className={classes.pushToKGoButton}>
          {canPushToKGo && <Button 
            color='primary' variant='contained'
            onClick={onPushToKGoClick} 
            disabled={checkedMotionIds.length === 0 || checkedMotionIdsMissingEV.length > 0}
            className={classes.uploadButton}
          >
            <ArrowUpIcon className={classes.uploadIcon} />
            <Typography>
              Push {checkedMotionIds.length} Motion{checkedMotionIds.length === 1 ? ' ' : 's '} 
              To KGo
            </Typography>
          </Button>}
          {checkedMotionIdsMissingEV.length > 0 && <Typography variant='body2' 
            className={classes.pushWarning}
          >
            {checkedMotionIdsMissingEV.length} motion{checkedMotionIdsMissingEV.length !== 1 && 's'}&nbsp;
            require{checkedMotionIdsMissingEV.length === 1 && 's'} exit velocity.
          </Typography>}
        </div>

        {firstRun ? <CircularProgress /> :
          motions.length === 0 
            ? <NoDataMessage>No recent swings for {playerName}</NoDataMessage>
            : motions.map(motion => <MotionListItem key={motion.id}
              onClick={onMotionListItemClick(motion)}
              selected={motion.id === selectedMotion.id}
              isNew={newMotionIds.includes(motion.id)}
              isMobile={isMobile}
              motion={motion}
              canEnterExitVelocity={canHandleLMData}
              onSave={data => onDataSaved(data, 'launchMonitorData')}
              startAdornment={canPushToKGo && renderCheckbox(motion)}
            />)}
        <div className={classes.loadMotionsContainer}>
          {loadingOldMotions 
            ? <CircularProgress />
            : noMoreOldMotions 
              ? <Typography>No more motions to load.</Typography>
              : <Fab variant='extended' 
                size='small' 
                color='primary' 
                onClick={onLoadOldMotionsClick}
              >
                Load More Motions
                <ExpandMoreIcon />
              </Fab>}
        </div>
      </div>
      
      <div className={classes.detailsContainer}>
        {internalToolingEnabled && <SelectedPercentileSpeeds />}
        <div className={classes.selectedMotionDetails}>
          {selectedMotion.id && renderMotionDetails(selectedMotion)}
        </div>
      </div>
    </div>

    {canPushToKGo && <ConfirmationDialog 
      open={confirmPush}
      motions={getCheckedMotions()}
      onClose={() => setConfirmPush(false)}
      updatePushedMotions={onPushComplete}
    />}
  </div>;
}

export default LiveCapture;
