import makeStyles from '@material-ui/core/styles/makeStyles';
import palette from '../../../../styles/palette';

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: 'bold'
  },
  loadContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  error: {
    color: palette.error,
    padding: '20px'
  }
}));

export default useStyles;
