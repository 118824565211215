import { useState, useEffect, useRef } from 'react';
import Logger from 'js-logger';
import axios from 'axios';
import { GetAnalyzedSwingData } from '../../../network/motionRequests';

export function useMotionsWithAnalyzedData(motionsById) {
  const analyzedDataCache = useRef({});
  const [motionsWithAnalyzedFrames, setMotionsWithAnalyzedFrames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();

    async function fetchAnalyzedFramesForMotion(motion) {
      if (analyzedDataCache.current[motion.id] != null) {
        const analyzedData = analyzedDataCache.current[motion.id];
        return { ...motion, analyzedData };
      }

      try {
        const analyzedData = await GetAnalyzedSwingData(motion.id, cancelSource);
        analyzedDataCache.current[motion.id] = analyzedData;
        return { ...motion, analyzedData };
      }
      catch (e) {
        Logger.error(e, 'Error fetching analyzed data for motion ' + motion.id);
      }
    }
    
    async function fetchData() {
      setLoading(true);
      const result = await Promise.all(
        Object.values(motionsById).map(fetchAnalyzedFramesForMotion));
      setMotionsWithAnalyzedFrames(result);
      setLoading(false);
    }

    fetchData();

    return () => cancelSource.cancel;
  }, [motionsById]);

  return [motionsWithAnalyzedFrames, loading];
}
