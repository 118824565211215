import makeStyles from '@material-ui/core/styles/makeStyles';
import palette from '../../../styles/palette';

const useStyles = makeStyles(() => ({
  skeleton: {
    margin: '20px'
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  paperContainer: {
    flex: 1
  },
  sendContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15px'
  },
  confirmation: {
    '&>div': {
      marginBottom: '10px'
    }
  },
  sendButton: {
    '&:hover': {
      backgroundColor: palette.fadedGrey
    }
  },
  send: {
    color: palette.deepBlue,
    width: '35px',
    height: '35px'
  },
  preview: {
    overflow: 'auto',
    maxHeight: '60vh'
  }
}));

export default useStyles;
