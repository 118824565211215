import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import log from 'js-logger';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Hidden,
  InputAdornment
} from '@material-ui/core';
import { round, isEqual } from 'lodash';
import ProgressButton from '../../../../components/progressButton';
import UnitConverter from '../../../../utils/unitConverter.utils';
import useStyles from './styles';
import FeetInchesSelect from './feetInchesSelect';
import { UpdateUserMeasurements } from '../../../../network/userRequests';
import { SetPlayerMeasurements, SetCurrentPlayer } from '../../../../store/players';
import ErrorSnackbar from '../../../../components/errorSnackbar';
import measurementKeys from './measurementKeys.constants';

const MeasurementsPanel = () => {
  const user = useSelector(state => state.currentPlayer);
  const dispatch = useDispatch();
  const classes = useStyles();
  
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [inputValues, setInputValues] = useState(measurementKeys.map(x => ({ ...x, value: '' })));

  const convertedMeasurements = useMemo(() => {
    if (user && user.measurements && user.measurements.length > 0) {
      const measurement = user.measurements[0];
      return measurementKeys.map(x => { 
        const value = UnitConverter.convert(measurement[x.key], x.dataUnits, x.displayUnits);
        return { ...x, value: value != null ? round(value, 3).toString() : '' };
      });
    }
    return measurementKeys.map(x => ({ ...x, value: '' }));
  }, [user]);
  useEffect(() => {
    setInputValues(convertedMeasurements);
  }, [convertedMeasurements]);

  function onInputValueChange(value, idx) {
    setInputValues(prev => {
      let newValues = [...prev];
      newValues[idx] = { ...prev[idx], value };
      return newValues;
    });
  };

  async function onSave() {
    if (!user) return;
    setSaving(true);
    try {
      const payload = inputValues.reduce((acc, { key, value, displayUnits, dataUnits }) => ({
        ...acc,
        [key]: value === '' ? null : UnitConverter.convert(value, displayUnits, dataUnits)
      }), {});
      const measurement = await UpdateUserMeasurements(user.userId, payload);
      const newMeasurements = [measurement, ...user.measurements];
      dispatch(SetCurrentPlayer({ ...user, measurements: newMeasurements }));
      dispatch(SetPlayerMeasurements(newMeasurements));
    } catch (e) {
      log.error('Error updating measurements for user ', user.userId, e);
      setError('Error updating measurements, please try again.');
    }
    setSaving(false);
  }

  return (
    <div className={classes.container}>
      <ErrorSnackbar message={error} onClose={() => setError(null) }/>
      <Card className={classes.card}>
        <CardContent>
          <Hidden xsDown>
            <Typography className={classes.title}>Measurements</Typography>
          </Hidden>
          <Grid container spacing={3} justify='center' alignItems='center' direction='column'>
            {inputValues.map((inputValue, idx) => <Grid item container 
              key={inputValue.key} 
              spacing={1}
            >
              <Grid item xs={6} sm={5} className={classes.labelContainer}>
                <Typography variant='h6' className={classes.label} align='right'>
                  {inputValue.title}
                </Typography>
                <Typography variant='h6' className={classes.label}>:</Typography>
              </Grid>
              <Grid item xs={6} sm={7}>
                {inputValue.showInFeet 
                  ? <FeetInchesSelect 
                    inches={inputValue.value} 
                    onChange={newVal => onInputValueChange(newVal, idx)} 
                    variant='outlined' />
                  : <TextField 
                    variant='outlined' 
                    className={classes.textField}
                    type='number'
                    value={inputValue.value}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>{inputValue.displayUnits}</InputAdornment>
                    }}
                    onChange={e => onInputValueChange(e.target.value, idx)} />}
              </Grid>
            </Grid>)}
            <Grid item container justify='flex-end'>
              <ProgressButton 
                showProgress={saving} 
                variant='contained'
                color='primary'
                onClick={onSave}
                disabled={isEqual(inputValues, convertedMeasurements)}
              >
                Update
              </ProgressButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default MeasurementsPanel;
