export const AUTH_CONFIG = {
  domain: 'login.kcloudconnect.com',
  clientId: 'OmFAGUTQ4ddiIqZhzW3yOLWBI7uUQd80',
  audience: 'https://www.kvest.azurewebsites.net',
  dbConnection: 'Username-Password-Authentication',
  logoutUrl: 'https://login.kcloudconnect.com/v2/logout',
  configurationBaseUrl: 'https://cdn.auth0.com',
  realm: 'Username-Password-Authentication'
};

export const ROLE_KEY = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
export const ALL_ROLES = 'All';
export const USER_ROLE = 'User';
export const ORG_ROLE = 'Organization';
export const ORG_ADMIN_ROLE = 'OrganizationAdmin';