const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '24px'
  },
  icon: { fontSize: '64px' }
};

export default styles;