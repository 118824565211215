import useScript from '../utils/useScript';

const GA_URL = 'https://www.googletagmanager.com/gtag/js';

function useGoogleAnalytics() {
  const key = process.env.REACT_APP_GA_BABE_RUTH_KEY;
  const fullScriptUrl = `${GA_URL}?id=${key}`;
  const innerHtml = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${key}');
  `;
  useScript(fullScriptUrl);
  useScript(undefined, undefined, false, innerHtml);
}

export default useGoogleAnalytics;