import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({ 
  textField: {
    width: '100%'
  },
  shiftedMarkdownTextField: ({ drawerWidth }) => ({
    paddingLeft: `calc(${drawerWidth}px)`
  }),
  shiftedRightMarkdownTextField: ({ drawerWidth }) => ({
    paddingRight: `calc(${drawerWidth}px)`
  })
}));

export default useStyles;