const styles = {
  container: {
    paddingTop: '10px'
  },
  screenTableContainer: {
    marginBottom: '20px'
  },
  newScreenContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  editButtonContainer: {
    marginTop: '15px'
  }
};

export default styles;