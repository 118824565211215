import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import { history } from 'store';
import orderBy from 'lodash/orderBy';
import useNetworkRequest from 'network/useNetworkRequest';
import { 
  CreateTrainingPlan, 
  GetGoogleDriveTemplates, 
  GetTrainingPlan, 
  GetTrainingPlanTemplates, 
  UpdateTrainingPlan 
} from 'network/trainingPlanRequests';
import TemplatesList from './templatesList';
import { PLAN_TYPES, SEGMENT_FOCUS } from 'constants/trainingPlans.constants';
import { getOrganizationId } from 'utils/auth.utils';
import { NOTE_TYPES } from 'constants/noteStatuses.constants';
import { GetAllDrills } from 'network/drillRequests';
import TemplateEditor from './templateEditor';
import useStyles from './styles';

const organizationId = getOrganizationId();
const NEW_STUB_TEMPLATE = {
  id: 'NEW_TEMPLATE',
  name: '',
  pelvisFocus: SEGMENT_FOCUS.none,
  torsoFocus: SEGMENT_FOCUS.none,
  upperArmFocus: SEGMENT_FOCUS.none,
  handFocus: SEGMENT_FOCUS.none,
  type: PLAN_TYPES.template,
  organizationId,
  notes: (new Array(4)).fill().map((_, idx) => ({
    organizationId,
    sequenceOrder: idx,
    noteType: NOTE_TYPES.template,
    text: '',
    assignedDrills: []
  }))
};

function TrainingPlanTemplates() {
  const classes = useStyles();

  const [selectedId, setSelectedId] = useState(null);
  const [templateUpdated, setTemplateUpdated] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState(false);

  useEffect(() => {
    setTemplateUpdated(false);
  }, [selectedId]);
  
  const getTemplatesRequest = useCallback(async cancelToken => {
    const [requestedTemplates, requestedGoogleDriveTemplates] = await Promise.all([
      GetTrainingPlanTemplates(cancelToken),
      GetGoogleDriveTemplates(cancelToken)
    ]);
    return orderBy(
      [...requestedTemplates, ...requestedGoogleDriveTemplates], 
      'creationTimestamp', 
      'desc');
  }, []);
  const [templates, templatesLoading, templatesError, setTemplates] = 
    useNetworkRequest([], 'getTrainingPlanTemplates', getTemplatesRequest);

  const templateNetworkRequest = useCallback(async cancelToken => {
    if (selectedId == null) {
      return null;
    }
    if (selectedId === NEW_STUB_TEMPLATE.id) {
      return NEW_STUB_TEMPLATE;
    }
    return await GetTrainingPlan(selectedId, cancelToken);
  }, [selectedId]);
  const [
    selectedTemplate, 
    selectedTemplateLoading, 
    selectedTemplateError, 
    setSelectedTemplate
  ] = useNetworkRequest(null, 'getSelectedTemplate', templateNetworkRequest);

  const [drills, drillsLoading, drillsError] = 
    useNetworkRequest({}, 'getDrills', GetAllDrills);

  const navigationCanContinue = () => !templateUpdated 
    || window.confirm('You have unsaved changes. Are you sure you want to continue?');

  const onBackClick = () => {
    if (navigationCanContinue()) {
      history.push('/player-development');
    }
  };

  const onTemplateSelected = templateId => {
    if (navigationCanContinue()) {
      setSelectedId(templateId);
    }
  };

  const onSelectedTemplateUpdated = template => {
    setTemplateUpdated(true);
    setSelectedTemplate(template);
  };

  const onSaveClick = async () => {
    setSaving(true);
    setSavingError(false);
    try {
      const templateToSave = { 
        ...selectedTemplate,
        externalDocuments: undefined,
        motionAttribute: undefined,
        notes: selectedTemplate.notes.map(note => ({
          ...note,
          assignedDrills: note.assignedDrills.map((assignedDrill, idx) => ({
            ...assignedDrill,
            sequenceNumber: idx + 1,
            drill: undefined
          }))
        }))
      };

      const isNewTemplate = templateToSave.id === NEW_STUB_TEMPLATE.id;

      const savedTemplate = isNewTemplate
        ? await CreateTrainingPlan({ ...templateToSave, id: undefined })
        : await UpdateTrainingPlan(templateToSave.id, templateToSave);

      setTemplateUpdated(false);
      setSelectedTemplate(savedTemplate);
      setSelectedId(savedTemplate.id);
      setTemplates(prev => isNewTemplate
        ? [savedTemplate, ...prev]
        : prev.map(x => x.id === savedTemplate.id ? savedTemplate : x));
    }
    catch (e) {
      setSavingError(true);
    }
    setSaving(false);
  };

  return <Grid container spacing={2} className={classes.container}>
    <Grid item xs={12}>
      <Paper className={classes.header}>
        <Button 
          color='primary' 
          variant='outlined'
          onClick={onBackClick}
        >
          Back
        </Button>
        <Typography variant='h3' className={classes.title}>Training Plan Templates</Typography>
      </Paper>
    </Grid>
    <Grid item xs={4} lg={3}>
      <Paper>
        {(templatesLoading || drillsLoading) && <LinearProgress />}
        {(templatesError || drillsError) && <Typography color='error'>
          Error loading templates or drills.
        </Typography>}
        <TemplatesList 
          templates={templates}
          loading={templatesLoading}
          error={templatesError}
          selectedId={selectedId}
          onTemplateSelected={onTemplateSelected}
          onNewTemplateClick={() => onTemplateSelected(NEW_STUB_TEMPLATE.id)}
        />
      </Paper>
    </Grid>
    <Grid item xs={8} lg={9}>
      {selectedTemplateLoading && <LinearProgress />}
      {selectedTemplateError && <Typography color='error'>Error loading template.</Typography>}
      {selectedTemplate && <TemplateEditor 
        template={selectedTemplate} 
        setTemplate={onSelectedTemplateUpdated} 
        drills={drills} 
        saving={saving}
        savingError={savingError}
        onSave={onSaveClick}
        saveDisabled={!templateUpdated}
      />}
    </Grid>
  </Grid>;
}

export default TrainingPlanTemplates;
