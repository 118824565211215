import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  Dot,
  Line,
  Scatter,
  XAxis,
  YAxis
} from 'recharts';
import { formatMMDD } from '../../../../../utils/formatting.utils';
import palette from '../../../../../styles/palette';

function ProgressGraph({ xAxisKey, yAxisKey, yAxisDomain, converter, data, getDataForDateRange }) {
  const [itemsOnDate, setItemsOnDate] = useState([]);
  
  async function onItemClick(item) {
    const { startTimestamp, endTimestamp, timestamp } = item;
    const dataForDateRange = await getDataForDateRange(startTimestamp, endTimestamp, timestamp);
    if (dataForDateRange == null) return;
    const convertedData = dataForDateRange.map(x => ({ 
      timestamp, 
      [yAxisKey]: converter(x[yAxisKey]) 
    }));
    setItemsOnDate(convertedData);
  }

  return <ResponsiveContainer width='100%' aspect={16.0 / 9.0}>
    <ComposedChart 
      onClick={() => setItemsOnDate([])}
      margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
    >
      <CartesianGrid stroke={palette.lightGray} />
      <XAxis 
        dataKey={xAxisKey} 
        type='number'
        name='Time'
        domain = {['auto', 'auto']}
        axisLine={false}
        tickFormatter={timeStr => formatMMDD(timeStr)} 
      />
      <YAxis dataKey={yAxisKey} type='number' axisLine={false} domain={yAxisDomain} />
      <Line isAnimationActive={false} 
        type='linear' 
        data={data} 
        dataKey={yAxisKey} 
        stroke={palette.deepBlue}
        strokeWidth={2}
        connectNulls 
        dot={{ 
          r: 4, 
          strokeWidth: 1, 
          onClick: item => onItemClick(item.payload), 
          style: { cursor: 'pointer' }
        }}
      />
      <Scatter 
        type='monotone' 
        data={itemsOnDate} 
        dataKey={yAxisKey} 
        stroke={palette.midnightBlue}
        fill={palette.midnightBlue}
        shape={<Dot r={3}/>}
        onClick={onItemClick}
      />
    </ComposedChart>
  </ResponsiveContainer>;
}

ProgressGraph.defaultProps = {
  yAxisDomain: ['dataMin', 'dataMax'],
  converter: x => x
};

ProgressGraph.propTypes = {
  xAxisKey: PropTypes.string.isRequired,
  yAxisKey: PropTypes.string.isRequired,
  yAxisDomain: PropTypes.array,
  converter: PropTypes.func,
  data: PropTypes.array.isRequired,
  getDataForDateRange: PropTypes.func.isRequired
};

export default ProgressGraph;
