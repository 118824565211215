import React from 'react';
import MediaQuery from 'react-responsive';
import {
  IconButton,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Typography,
  Grid,
  DialogActions,
  Button
} from '@material-ui/core';
import { LockOutlined, PersonOutline, DateRange, ArrowBack } from '@material-ui/icons';
import isEqual from 'date-fns/isEqual';

import AuthorizationPageWrapper from '../authorizationPageWrapper';
import PopupForm from '../popupForm';
import IconTextField from '../iconTextField';
import ProgressButton from '../progressButton';
import SignUpError from './signUpError';
import SignUpInfo from './signUpInfo';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Styles from './styles';
import Eula from '../eula';
import { CalculateAge, FlagEmailAsUnderage, EmailFlaggedAsUnderage } from '../../utils/user.utils';

class CreateAccountPage extends React.Component {
  FIRST_NAME = 'firstName';
  LAST_NAME = 'lastName';
  PASSWORD = 'password';
  VERIFY_PASSWORD = 'verifyPassword';
  DATE_OF_BIRTH = 'dateOfBirth';
  VERIFY_DATE_OF_BIRTH = 'verifyDateOfBirth';
  GENDER = 'gender';

  GENDER_OPTIONS = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Other', value: 'Other' }
  ];

  state = {
    displayEula: false,
    showPassword: false,
    verifyUnder13: false,
    isUnder13: EmailFlaggedAsUnderage(this.props.email),
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    verifyDateOfBirth: '',
    gender: '',
    password: '',
    verifyPassword: '',
    mismatchedPasswordsError: false,
    mismatchedBirthdayError: false,
    missingNameError: false,
    tooShortError: false,
    dateOfBirthError: false,
    genderError: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.email !== this.props.email) {
      this.setState({ isUnder13: EmailFlaggedAsUnderage(this.props.email) });
    }
  }

  validateFields = () => {
    if (this.state.firstName.trim().length === 0 || 
       this.state.lastName.trim().length === 0) {
      this.setState({ missingNameError: true });
    }
    else if (this.state.gender === '') {
      this.setState({ genderError: true });
    }
    else if (!Date.parse(this.state.dateOfBirth)) {
      this.setState({ dateOfBirthError: true });
    }
    else if (this.state.password.length < 6) {
      this.setState({ tooShortError: true });
    }
    else if (this.state.verifyPassword !== this.state.password) {
      this.setState({ mismatchedPasswordsError: true });
    }
    else {
      return true;
    }
    return false;
  }

  handleChange = (e, key) => {
    const { value } = e.target;
    // Only allow for numberical, /, or - characters in date of birth field
    if (key === this.DATE_OF_BIRTH && !value.match(/^[0-9/-]*$/)) {
      return;
    }
    let text = value;
    
    const errors = { 
      mismatchedPasswordsError: false, 
      missingNameError: false, 
      tooShortError: false,
      dateOfBirthError: false,
      genderError: false,
      mismatchedBirthdayError: false
    };
    this.setState({ ...errors, [key]: text });
  }

  displayEula = () => {
    if(!this.validateFields()) {
      return;
    }
    
    const age = CalculateAge(this.state.dateOfBirth);
    if (age < 13)
    {
      this.setState({ verifyUnder13: true });
    } else {
      this.setState({ displayEula: true });
    }
  }

  formSubmit = () => {
    const { firstName, lastName, password, dateOfBirth, gender } = this.state;
    this.setState(
      { displayEula: false },
      () => this.props.onSubmit(firstName, lastName, password, dateOfBirth, gender)
    );
  }

  buildTextField = (placeholder, key, selectData) => {
    const { classes, isLoading } = this.props;
    const autoFocus = key === this.FIRST_NAME;

    let icon, error, type;
    switch (key) {
      case this.PASSWORD:
      case this.VERIFY_PASSWORD:
        type = this.state.showPassword ? '' : 'password';
        icon = <LockOutlined className={classes.inputIcon}/>;
        error = this.state.mismatchedPasswordsError;
        break;
      case this.FIRST_NAME:
      case this.LAST_NAME:
        icon = <PersonOutline className={classes.inputIcon}/>;
        error = this.state.missingNameError;
        break;
      case this.GENDER:
        icon = <PersonOutline className={classes.inputIcon}/>;
        error = this.state.genderError;
        break;
      case this.DATE_OF_BIRTH:
        icon = <DateRange className={classes.inputIcon} />;
        break;
      default:
        break;
    }

    return (
      <IconTextField
        value={this.state[key]}
        selectData={selectData}
        autoFocus={autoFocus}
        placeholder={placeholder} 
        onChange={(e) => this.handleChange(e, key)}
        type={type}
        icon={icon}
        error={error}
        disabled={isLoading}
      />
    );
  }

  submitBirthdayVerification = () => {
    const { dateOfBirth, verifyDateOfBirth } = this.state;
    if (!isEqual(new Date(dateOfBirth), new Date(verifyDateOfBirth))) {
      this.setState({ mismatchedBirthdayError: true });
    } else {
      FlagEmailAsUnderage(this.props.email);
      this.setState({ isUnder13: true });
    }
  }

  renderVerifyDateOfBirthPopup = () => {
    const { state } = this;
    return <PopupForm title='Verify Your Birthday' open>
      <div className={this.props.classes.innerContainer}>
        <FormControl error={state.mismatchedBirthdayError}>
          <IconTextField autoFocus 
            value={state[this.VERIFY_DATE_OF_BIRTH]}
            placeholder='Birthday (MM/DD/YYYY)'
            onChange={(e) => this.handleChange(e, this.VERIFY_DATE_OF_BIRTH)}
            icon={<DateRange className={this.props.classes.inputIcon} />}
            error={state.mismatchedBirthdayError}
          />
          {state.mismatchedBirthdayError && <FormHelperText>Birthdays must match.</FormHelperText>}
        </FormControl>
      </div>
      <DialogActions>
        <Button onClick={() => this.setState({ verifyUnder13: false })}>Back</Button>
        <Button color='primary' onClick={this.submitBirthdayVerification}>Submit</Button>
      </DialogActions>
    </PopupForm>;
  }

  renderErrorPopup = (title, error, allowReturn = true) => {
    return <PopupForm title={title} open={true}>
      <Typography variant='h6' className={this.props.classes.innerContainer}>
        {error}
      </Typography>
      {allowReturn && <div className={this.props.classes.flexColumn}>
        <IconButton onClick={this.props.resetError}>
          <ArrowBack />
        </IconButton>
      </div>}
    </PopupForm>;
  }

  registrationForm = () => {
    const { classes, isLoading, hasError, isPlayer } = this.props;

    if (hasError) {
      return this.renderErrorPopup(
        'Error During Registration', 
        'Sorry there was an error. Have you already registered with this account?');
    }
    if (this.state.verifyUnder13) {
      return this.renderVerifyDateOfBirthPopup();
    }

    const genderOptions = [
      { label: `${this.props.isPlayer ? 'Player ' : ''}Gender`, value: '' }, 
      ...this.GENDER_OPTIONS
    ];

    return <div>
      <SignUpError hidden={this.state.mismatchedPasswordsError}> 
        Passwords must match
      </SignUpError>
      <SignUpError hidden={this.state.missingNameError}>
        Please input your first and last names.
      </SignUpError>
      <SignUpError hidden={this.state.dateOfBirthError}>
        Input Birthday as MM/DD/YYYY.
      </SignUpError>
      <SignUpError hidden={this.state.genderError}>
        Please select an option for gender.
      </SignUpError>

      <div className={classNames(classes.flexColumn, classes.innerContainer)}>
        <div className={classes.prompt}>
          Create an account for <br/>
          {this.props.email}
        </div>

        <Grid container spacing={1} className={classes.inputContainer}>
          <Grid item md={6} xs={12}>{this.buildTextField(`${isPlayer ? 'Player ' : ''}First Name`, this.FIRST_NAME)}</Grid>
          <Grid item md={6} xs={12}>{this.buildTextField(`${isPlayer ? 'Player ' : ''}Last Name`, this.LAST_NAME)}</Grid>
          <Grid item md={6} xs={12}>{this.buildTextField('Gender', this.GENDER, genderOptions)}</Grid>
          <Grid item md={6} xs={12}>{this.buildTextField(`${isPlayer ? 'Player ' : ''}Birthday (MM/DD/YYYY)`, this.DATE_OF_BIRTH)}</Grid>
          <Grid item md={6} xs={12}>{this.buildTextField('Password', this.PASSWORD)}</Grid>
          <Grid item md={6} xs={12}>{this.buildTextField('Verify Password', this.VERIFY_PASSWORD)}</Grid>
        </Grid>

        <div className={classes.flexColumn}>
          <SignUpInfo error={this.state.tooShortError}>
            Passwords must be at least 6 characters
          </SignUpInfo>
          <div>
            <FormControlLabel className={classes.showPasswordCheckbox}
              control={
                <Checkbox 
                  checked={this.state.showPassword}
                  onChange={() => this.setState({ showPassword: !this.state.showPassword })}
                  color='primary'
                />
              }
            />
            <span>Show Password</span>
          </div>
        </div>
      </div>
      <ProgressButton className={classes.submitBtn}
        onClick={this.displayEula}
        showProgress={isLoading}
        progressColor='white'>
        Next
      </ProgressButton>
    </div>;
  }

  render() {
    if (this.state.isUnder13)
    {
      return (
        <AuthorizationPageWrapper>
          {this.renderErrorPopup(
            'Unable To Create Account ',
            'Cloud accounts cannot be created at this time for users under the age of 13. Talk to your coach about how to create your account. ' + 
            'If you are seeing this in error, reach out to support@k-vest.com.',
            false
          )}
        </AuthorizationPageWrapper>
      );
    }

    if (this.props.expiredToken) 
    {
      return (
        <AuthorizationPageWrapper>
          {this.renderErrorPopup(
            'Expired Link',
            'The link used to create an account has expired. Ask your coach to resend an activation email from the client details page in KCoach.',
            false
          )}
        </AuthorizationPageWrapper>
      );
    }
 
    return <MediaQuery minWidth={768}>
      {isDesktop => <div>{isDesktop
        ? <AuthorizationPageWrapper>
          <PopupForm title={'Sign Up'} open={true}>
            {this.registrationForm()}           
          </PopupForm>
        </AuthorizationPageWrapper>
        : <div>
          {this.registrationForm()}
        </div>}
      <Eula 
        open={this.state.displayEula} 
        acceptEula={this.formSubmit}
        closeEula={() => this.setState({ displayEula: false })}
        submittingEula={false}
      />
      </div>}
    </MediaQuery>;
  }
}

export default withStyles(Styles)(CreateAccountPage);