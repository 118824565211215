import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import {
  FormControlLabel, 
  Checkbox,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js';

import AuthorizationPageWrapper from '../../components/authorizationPageWrapper';
import PopupForm from '../../components/popupForm';
import ProgressButton from '../../components/progressButton';
import AuthenticationService from '../../services/authentication.js';
import { SetUserPassword } from '../../network/userRequests.js';
import { history } from '../../store.js';
import LegalLinks from '../../components/legalLinks/index.js';
import { filterRedirectSearchParameters, logNewUserIn } from 'utils/auth.utils.js';
import useInput from 'utils/useInput.js';
import PasswordTextField from './PasswordTextField.js';
import useNetworkRequest from 'network/useNetworkRequest.js';
import { GetActionToken } from 'network/actionTokenRequests.js';
import { CATEGORIES } from 'services/analytics.js';
import useTrackPageEvents from 'services/useTrackPageEvents.js';
import { TRAINING_PLAN_ROOT } from 'constants/routePaths.constants.js';

const MIN_PASSWORD_LENGTH = 6;
const PAGE_NAME = 'Set Password';

function SetPassword({ match, location, classes }) {
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);

  const passwordInput = useInput();
  const verifyPasswordInput = useInput();
  
  const authenticationService = new AuthenticationService();
  const passwordTokenString = match.params.token;
  const passwordTokenDetails = authenticationService.decodeJwtToken(passwordTokenString);
  const { email: emailAddress, primarysid: passwordTokenId, userId } = passwordTokenDetails;

  const getRedirectUrl = useCallback(() => {
    const { pathname, search } = location?.state?.from ?? {};
  
    if (pathname == null) {
      return '/';
    }
    return pathname + filterRedirectSearchParameters(search);
  }, [location]);

  const isTrainingPlanFlow = () => {
    return getRedirectUrl().indexOf(TRAINING_PLAN_ROOT) >= 0;
  };
  
  useTrackPageEvents({ 
    eventCategory: CATEGORIES.AUTH,
    userId, 
    url: match?.url,
    pageName: PAGE_NAME,
    userEmail: emailAddress, 
    miscData: useMemo(() => ({
      redirectUrl: getRedirectUrl() 
    }), [getRedirectUrl])
  });
  
  const getActionTokenRequest = useCallback(
    cancelToken => GetActionToken(passwordTokenId, passwordTokenString, cancelToken), 
    [passwordTokenId, passwordTokenString]);
  const [actionToken, tokenLoading, tokenError] = useNetworkRequest({}, 'GetActionToken', getActionTokenRequest);
  const actionTokenUsed = actionToken.used;
  const actionTokenExpired = actionToken.expirationTimestamp 
    && new Date(actionToken.expirationTimestamp) < new Date();

  const loading = submitting || tokenLoading;

  useEffect(() => {
    setPasswordMismatchError(false);
    setPasswordLengthError(false);
  }, [passwordInput.value, verifyPasswordInput.value]);

  const validatePasswords = () => {    
    if (passwordInput.value.length < MIN_PASSWORD_LENGTH) {
      setPasswordLengthError(true);
      return false;
    } else if (passwordInput.value !== verifyPasswordInput.value) {
      setPasswordMismatchError(true);
      return false;
    }
    return true;
  };

  const submitPasswordChange = async () => {
    if (!validatePasswords() || loading) return;
    setSubmitting(true);
    try {
      const { userId } = passwordTokenDetails;
      const user = await SetUserPassword(userId, passwordInput.value, passwordTokenString);
      logNewUserIn(user);
      history.push(getRedirectUrl());
    } catch (e) {
      setSubmitting(false);
    }
  };

  if (!loading && (actionTokenUsed || actionTokenExpired || tokenError)) {
    let errorMessage = 'The previous link has expired, please log in to continue.';
    if (actionTokenUsed) {
      errorMessage = 'Please log in to continue.';
    }
    else if (tokenError) {
      errorMessage = 'The previous link is invalid, please log in to continue.';
    }

    return <Redirect to={{
      pathname: '/login',
      search: '?email=' + encodeURIComponent(emailAddress),
      state: { 
        ...location.state, 
        errorMessage
      }
    }} />;
  }

  return (
    <AuthorizationPageWrapper>
      <PopupForm open showPGAndKMotionHeader={isTrainingPlanFlow()}>
        <div className={classes.formContainer}>
          <div className={classes.verticalMargin}>
            <Typography align='center' variant='h5'>Set Password</Typography>
          </div>
          <div className={classes.fullWidth}>
            <Typography align='left' className={classes.paddingHorizontal}>
              <b>USERNAME: </b> {emailAddress}
            </Typography>
          </div>

          <PasswordTextField 
            {...passwordInput.bind}
            helperText={`Password must contain at least ${MIN_PASSWORD_LENGTH} characters.`}
            autoFocus
            placeholder='Password'
            disabled={loading}
            type={showPassword ? 'text' : 'password'}
            classes={classes}
            error={passwordLengthError}
          />
          <PasswordTextField 
            {...verifyPasswordInput.bind}
            helperText='Passwords must match'
            placeholder='Verify Password'
            disabled={loading}
            type={showPassword ? 'text' : 'password'}
            classes={classes}
            error={passwordMismatchError}
          />

          <FormControlLabel
            control={
              <Checkbox 
                checked={showPassword}
                onChange={() => setShowPassword(prev => !prev)}
                color='primary'
              />
            }
            label='Show Password'
          />
          
          <LegalLinks className={classes.legalLinks} />

          <ProgressButton 
            className={classes.submitButton}
            onClick={submitPasswordChange}
            showProgress={loading}
            progressColor='white'
          >
            Submit
          </ProgressButton>
        </div>
      </PopupForm>
    </AuthorizationPageWrapper>
  );
  // }
}

export default withStyles(styles)(SetPassword);