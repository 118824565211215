import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  linearProgress: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0
  }
}));

export default useStyles;
