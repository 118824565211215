import React from 'react';

import ContentFeedItems from './contentFeedItems';
import ScheduledContent from './scheduledContent';

import useContentFeedItems from './useContentFeedItems';
import { getOrganizationId } from '../../utils/auth.utils';
import useScheduledContentDeliveries from './useScheduledContentDeliveries';

import useStyles from './styles';

function ContentFeedManagement() {
  const classes = useStyles();
  const organizationId = getOrganizationId();

  const [
    contentFeedItems, 
    setContentFeedItems, 
    loading, 
    error
  ] = useContentFeedItems(organizationId);

  const [
    deliveries,
    setDeliveries,
    deliveriesLoading, 
    deliveriesError
  ] = useScheduledContentDeliveries(organizationId);

  const itemCreated = (item) => {
    setContentFeedItems(prev => [item, ...prev]);
  };

  const newContentScheduled = (newDelivery) => {
    setDeliveries(prev => [newDelivery, ...prev]);
  };

  return <div className={classes.container}>
    <div className={classes.scheduled}>
      <h3>Scheduled Content</h3>
      <ScheduledContent 
        contentFeedItems={contentFeedItems}
        contentFeedLoading={loading}
        contentFeedError={error}
        deliveries={deliveries}
        deliveriesLoading={deliveriesLoading}
        deliveriesError={deliveriesError}
      />
    </div>
    <div>
      <h3>Content Feed Items</h3>
      <ContentFeedItems
        loading={loading}
        error={error}
        contentFeedItems={contentFeedItems}
        onSetContentFeedItems={itemCreated}
        onContentScheduled={newContentScheduled}
      />
    </div>
  </div>;
}

export default ContentFeedManagement;
