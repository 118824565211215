import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function FormControlSelect({
  id,
  value,
  onChange,
  items,
  renderItem,
  className,
  label,
  renderValue,
  variant
}) {
  return <FormControl className={className}>
    {label && <InputLabel id={id}>{label}</InputLabel>}
    <Select 
      labelid={id}
      value={value}
      onChange={e => onChange(e.target.value)}
      renderValue={renderValue}
      variant={variant}
    >
      {items.map(renderItem)}
    </Select>
  </FormControl>;
}

FormControlSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  items: PropTypes.array,
  renderItem: PropTypes.func.isRequired,
  label: PropTypes.string,
  renderValue: PropTypes.func,
  variant: PropTypes.string
};

export default FormControlSelect;
