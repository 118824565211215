import React from 'react';
import styles from './styles';
import MonthlyPrize from '../../../assets/monthlyPrize.png';
import { ORGANIZATION_IDS } from '../../../constants/organizations.constants';

const ContestHero = ({ organizationId }) => {
  if (organizationId !== ORGANIZATION_IDS.babeRuth) { 
    return null;
  }
  return <div style={styles.root}>
    <h2 style={styles.title}> Win Prizes by Training </h2>
    <div style={styles.images}>
      <span style={styles.prizeHolder}> 
        <div style={styles.prizeTitle}> Monthly Prize </div>
        <a href='https://www.baberuthleagueapp.com/in-app-BRL-PSC-Contest'>
          <img src={MonthlyPrize} style={styles.prizeImage} alt='' />
        </a>
      </span>
    </div>

    <div>
      <p style={styles.extraInfo}>
        Each run you score earns you a raffle ticket 
        for a chance to win the Monthly Prize 
      </p>
    </div>
    <div>
      <h3 style={styles.learnMore}>
        Learn more:&nbsp;
        <a style={styles.link} href='https://www.baberuthleagueapp.com/in-app-BRL-PSC-Contest'>here</a>.
      </h3>
    </div>
  </div>;
};

export default ContestHero;
