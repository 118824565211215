import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    color: 'var(--checked-color)',
    '&$checked': {
      color: 'var(--checked-color)'
    }
  },
  checked: {}
}));

export default useStyles;

