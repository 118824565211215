import { useCallback } from 'react';
import useScript from '../utils/useScript';

const KLAVIYO_SCRIPT_URL = 'https://static.klaviyo.com/onsite/js/klaviyo.js';

function useKlaviyo() {
  const key = process.env.REACT_APP_KLAVIYO_PUBLIC_KEY;
  const fullScriptUrl = `${KLAVIYO_SCRIPT_URL}?company_id=${key}`;
  useScript(fullScriptUrl);

  const track = useCallback((eventName, properties) => {
    window._learnq = window._learnq || [];
    window._learnq.push(['track', eventName, properties]);
  }, []);

  const identify = useCallback((user, customProps = {}, checkForEmailDivergence = false) => {
    const { 
      emailAddress, 
      firstName, 
      lastName, 
      gender, 
      dateOfBirth, 
      userId,
      kGoExperienceInfo
    } = user || {};
    const experience = kGoExperienceInfo ? kGoExperienceInfo.kGoExperience : null;
    const kGoOrgId = kGoExperienceInfo ? kGoExperienceInfo.organizationId : null;

    let properties = {
      '$email': emailAddress,
      '$first_name': firstName,
      '$last_name': lastName,
      'Gender': gender,
      'Date of Birth': dateOfBirth,
      'KMI UserId': userId,
      'KGO Experience': experience,
      'KGO Experience Organization Id': kGoOrgId
    };

    Object.keys(properties)
      .forEach(key => properties[key] == null && delete properties[key]);

    window._learnq = window._learnq || [];
    if (checkForEmailDivergence && window._learnq.identify && emailAddress)
    {
      const existingEmail = window._learnq.identify().$email;
      if (existingEmail 
        && existingEmail.toLowerCase().trim() !== emailAddress.toLowerCase().trim()) 
      {
        properties['Diverged From Email'] = existingEmail;
        const divergedToProperties = { '$email': existingEmail, 'Diverged To Email': emailAddress };
        window._learnq.push(['identify', divergedToProperties]);
      }
    }

    window._learnq.push(['identify', properties]);
  }, []);

  const setProfileProperty = useCallback((key, value) => {
    window._learnq = window._learnq || [];
    var email = window._learnq.identify ? window._learnq.identify().$email : null;
    if (email == null) return;
    window._learnq.push(['identify', { '$email': email, [key]: value }]);
  }, []);

  return { track, identify, setProfileProperty };
}

export default useKlaviyo;