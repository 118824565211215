export const ACTION_ITEMS = {
  needsActionPlan: 'NeedsActionPlan',
  needsCapture: 'NeedsCapture',
  needsForms: 'NeedsForms'
};

export const ORGANIZATION_IDS = {
  perfectGame: '1d550fe2-de63-48fe-9e9a-a5ae1e76bdf9',
  kmotion: '7a054be5-3284-47f9-a2ea-093a1007d62c',
  babeRuth: '4467ce54-8c44-4558-830b-c0875f20d978'
};