import sortBy from 'lodash/sortBy';

export function getMobilityScreenForDate(timestamp, screens) {
  let sortedScreens = sortBy(screens, 'startTimestamp');
  let screen = screens.length > 0 ? sortedScreens[0] : {};
  for (var i = 0; i < sortedScreens.length; i++) {
    if (timestamp < sortedScreens[i].endTimestamp) break;
    screen = sortedScreens[i];
  }
  return screen;
};
