import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
  CircularProgress,
  Typography,
  Link,
  Paper,
  withStyles
} from '@material-ui/core';
import ErrorSnackbar from '../../../components/errorSnackbar';
import { 
  RemoveOrganizationUser, 
  InviteUserToOrganization, 
  GetOrganizationUsers,
  UpdateOrgUserAssignedTeams
} from '../../../network/organizationRequests';
import { GetAllTeams } from '../../../network/teamRequests';
import InvitationDialog from './invitationDialog';
import { getOrganizationId, getUserId } from '../../../utils/auth.utils';
import styles from './styles';
import AssignTeams from './assignTeams';
import { validateEmail } from 'utils/validation.utils';

class OrganizationUsers extends React.Component {
  state = {
    loadingUser: false,
    users: [],
    teams: [],
    emailAddress: '',
    errorMsg: null,
    sendingInvitation: false,
    showInvitationDialog: false,
    invitationSent: false,
    hoveringUserId: null,
    deletingUserId: null,
    userBeingAssignedTeams: null
  }

  componentDidMount() {
    this._loadUsers();
  }

  _onTextFieldChange = (key, event) => {
    this.setState({ [key]: event.target.value });
  }

  _loadUsers = async () => {
    try {
      this.setState({ loadingUsers: true });
      const [allOrgUsers, teams] = await Promise.all([
        GetOrganizationUsers(),
        GetAllTeams(getOrganizationId())
      ]);
      const userId = getUserId();
      this.setState({ 
        users: allOrgUsers.filter(x => x.userId !== userId),
        teams, 
        loadingUsers: false 
      });
    } catch (e) {
      this.setState({ loadingUsers: false });
    }
  }

  _invite = async (email, teams) => {
    this.setState({ sendingInvitation: true });
    if (!validateEmail(email)) {
      this.setState({ 
        sendingInvitation: false,
        errorMsg: 'The email address you entered is not valid.'
      });
      return;
    }
    try {
      var teamIds = teams.map(team => team.id);
      await InviteUserToOrganization(getOrganizationId(), email, teamIds);
      this.setState({ invitationSent: true, sendingInvitation: false });
      this._loadUsers();
      setTimeout(() => {
        this.setState({ invitationSent: false, showInvitationDialog: false, emailAddress: '' });
      }, 1500);
    } catch (e) {
      this.setState({ 
        sendingInvitation: false,
        errorMsg: 'Error submitting invitation, please try again.'
      });
    }
  }

  _removeAccount = async (userId) => {
    this.setState({ deletingUserId: userId });
    try {
      await RemoveOrganizationUser(userId);
      var users = this.state.users.filter(x => x.userId !== userId);
      this.setState({ deletingUserId: null, users });
    } catch (e) {
      this.setState({ deletingUserId: null, errorMsg: 'Error removing user.' });
    }
  }

  _submitTeams = async ({ user, selectedTeams }) => {
    const organizationId = getOrganizationId();
    try {
      const teams = selectedTeams.map(team => ({
        organizationId,
        userId: user.userId,
        teamId: team.id
      }));
      const resp = await UpdateOrgUserAssignedTeams(organizationId, user.userId, teams);
      this.setState(prevState => ({
        ...prevState,
        users: prevState.users.map(existingUser => {
          if (existingUser.userId === user.userId) {
            existingUser.organizationUserTeams = resp.items;
          }
          return existingUser;
        }),
        userBeingAssignedTeams: null
      }));
    } catch (e) {
      this.setState({ 
        userBeingAssignedTeams: null, 
        errorMsg: 'There was an error assigning teams for your user. Please try again.'
      });
    }
  }

  render () {
    const {
      users,
      emailAddress, 
      errorMsg,
      hoveringUserId,
      deletingUserId,
      sendingInvitation,
      invitationSent,
      userBeingAssignedTeams,
      teams
    } = this.state;

    const { classes } = this.props; 

    return <div>
      <ErrorSnackbar 
        message={errorMsg}
        onClose={() => this.setState({ errorMsg: null })}
      />
      <InvitationDialog 
        open={this.state.showInvitationDialog}
        close={() => this.setState({ showInvitationDialog: false, emailAddress: '' })}
        invite={this._invite}
        email={emailAddress}
        onEmailChange={({ target }) => this.setState({ emailAddress: target.value })}
        submitting={sendingInvitation}
        sent={invitationSent}
        teams={teams}
      />
      <Grid container justify='space-between' className={classes.title}>
        <Typography variant='h5' className={classes.teamsTitle}>Organization Users</Typography>
        <Button 
          variant='contained' 
          color='primary' 
          onClick={() => this.setState({ showInvitationDialog: true })}
        >
          Invite User
        </Button>
      </Grid>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Teams Asssigned</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow 
                key={user.userId}
                onMouseEnter={() => this.setState({ hoveringUserId: user.userId })}
                onMouseLeave={() => this.setState({ hoveringUserId: null })}
              >
                <TableCell>
                  <Link href={`mailto:${user.emailAddress}`}>{user.emailAddress}</Link>
                </TableCell>
                <TableCell>
                  {user.firstName}
                </TableCell>
                <TableCell>
                  {user.lastName}
                </TableCell>
                <TableCell>
                  <Button 
                    color='primary' 
                    variant='outlined' 
                    onClick={() => this.setState({ userBeingAssignedTeams: user })}
                  >
                    Assign Teams
                  </Button>
                </TableCell>
                <TableCell>
                  {deletingUserId === user.userId 
                    ? <CircularProgress color='secondary' size={32}/>
                    : <Button 
                      onClick={() => this._removeAccount(user.userId)} 
                      style={{ 
                        visibility: deletingUserId === null && hoveringUserId === user.userId 
                          ? null 
                          : 'hidden' 
                      }}
                      color='secondary'
                    >
                      Remove
                    </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <AssignTeams 
        user={userBeingAssignedTeams} 
        teams={teams}
        onCancel={() => this.setState({ userBeingAssignedTeams: null })}
        onSubmit={this._submitTeams}
      />
    </div>;
  }
}

export default withStyles(styles)(OrganizationUsers);