import 'react-app-polyfill/ie11';
import 'react-dates/initialize';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import App from './pages/app';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Analytics from './services/analytics';

import './index.css';

Analytics.init();
const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          <App />
        </div>
      </MuiPickersUtilsProvider>
    </ConnectedRouter>
  </Provider>,
  target
);