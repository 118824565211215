import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import VideoPlayer from '../../../../../components/videoPlayer';
import VideoUploadDialog from './videoUploadDialog';
import useStyles from './styles';
import VideoSelectionDialog from './videoSelectionDialog';

function VideosDisplay({ eventResult, user, eventUser, className }) {
  const { videos, motionType, handedness } = eventResult;
  const [selectedVideoFile, setSelectedVideoFile] = useState(null);
  const [selectingVideo, setSelectingVideo] = useState(false);
  const classes = useStyles();

  const defaultTitle = `${user.firstName} ${user.lastName}`;

  const onVideoSelectionDialogClose = videoFile => {
    setSelectedVideoFile(videoFile);
    setSelectingVideo(false);
  };

  return <div className={className}>
    <Grid container justify='space-evenly' alignItems='center' spacing={2}>
      <Grid item>
        <Typography>Type: <b>{motionType}</b></Typography>
      </Grid>
      <Grid item>
        <Typography>Handedness: <b>{handedness}</b></Typography>
      </Grid>
      <Grid item>
        <Button 
          variant='outlined' 
          color='primary' 
          className={classes.videoFileInput}
          onClick={() => setSelectingVideo(true)}
        >
          {`${videos.length > 0 ? 'Replace' : 'Upload'} ${motionType} Videos`}
        </Button>
      </Grid>
    </Grid>
    {videos.length === 0 &&
      <Typography variant='h6'>No videos for this result uploaded yet.</Typography>
    }
    {videos.map(video =>
      <div className={classes.videoContainer} key={video.id}> 
        <VideoPlayer 
          src={video.url} 
          title={video.id}
        />
      </div>
    )}

    <VideoSelectionDialog 
      open={selectingVideo}
      onClose={onVideoSelectionDialogClose}
      userId={user.userId}
      captureEventId={eventUser.captureEventId}
      captureEventResult={eventResult}
    />

    <VideoUploadDialog 
      open={selectedVideoFile != null}
      onClose={() => setSelectedVideoFile(null)}
      videoFile={selectedVideoFile}
      captureEventResult={eventResult}
      userId={user.userId}
      defaultTitle={defaultTitle}
      eventUser={eventUser}
    />
  </div>;
};

export default VideosDisplay;
