import makeStyles from '@material-ui/core/styles/makeStyles';
import { red, yellow, green, grey } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  percentileCell: {
    borderRadius: '5px',
    padding: '1px',
    minWidth: '40px'
  },
  fail: {
    backgroundColor: red[200]
  },
  needsWork: {
    backgroundColor: yellow[200]
  },
  pass: {
    backgroundColor: green[200]
  },
  focus: {
    backgroundColor: grey[600]
  }
}));

export default useStyles;