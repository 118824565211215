import React, { useState } from 'react';
import { 
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button
} from '@material-ui/core';
import useNetworkRequest from '../../network/useNetworkRequest';
import { GetLessonTemplates } from '../../network/lessonsRequests';
import TemplateEditor from './templateEditor';
import { GetAllDrills } from '../../network/drillRequests';
import useStyles from './styles';
import { BODY_SEGMENT_FOCUSES } from './templateEditor/templateEditor.constants';
import { SEGMENT_FOCUS } from 'constants/trainingPlans.constants.js';

function LessonTemplates() {
  const classes = useStyles();

  const [
    drills,
    drillsLoading,
    drillsError
  ] = useNetworkRequest([], 'getDrills', GetAllDrills);
  const [
    templates,
    templatesLoading,
    templatesError,
    setTemplates
  ] = useNetworkRequest([], 'getLessonTemplates', GetLessonTemplates);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const loading = templatesLoading || drillsLoading;
  const error = templatesError || drillsError;

  const getTemplateLabel = template => {
    const focuses = BODY_SEGMENT_FOCUSES
      .filter(({ key }) => template[key] === SEGMENT_FOCUS.primary)
      .map(({ label }) => label);
    if (focuses.length > 0) {
      return focuses.join(' - ');
    }
    return 'Lesson Template';
  };

  const onLessonTemplateSaved = template => {
    setTemplates(prev => {
      let updated = false;
      const updatedTemplates = prev.map(item => {
        if (item.id === template.id) {
          updated = true;
          return template;
        }
        return item;
      });
      return updated ? updatedTemplates : [template, ...prev];
    });
    setSelectedTemplate(template);
  };

  return <div>
    <Typography variant='h3'>Lesson Templates</Typography>
    <Grid container spacing={1}>
      {error && <Grid item xs={12}>
        <Typography color='error'>
          There was an error fetching existing lesson templates and drills. 
          Please refresh to try again.
        </Typography>
      </Grid>}
      <Grid item xs={3}>
        <Paper>
          {!loading && <div className={classes.newTemplateButtonContainer}>
            <Button 
              onClick={() => setSelectedTemplate({ lessonSegments: [] })}
              color='primary'
              variant='outlined'
            >
              New Template
            </Button>
          </div>}
          <List>
            {templates.map(template => <ListItem 
              key={template.id} 
              divider 
              button
              selected={template.id === selectedTemplate?.id}
              onClick={() => setSelectedTemplate(template)}
            >
              <ListItemText 
                primary={template.name}
                secondary={getTemplateLabel(template)}
              />
            </ListItem>)}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={9}>
        <Paper>
          {selectedTemplate && <TemplateEditor 
            template={selectedTemplate} 
            drills={drills} 
            onCancel={() => setSelectedTemplate(null)}
            onSave={onLessonTemplateSaved}
          />}
        </Paper>
      </Grid>
    </Grid>
  </div>;
}

export default LessonTemplates;