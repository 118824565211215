import { ACTION_STATUSES } from '../../../../constants/captureEvents.constants';
import {
  PostEventAction
} from '../../../../network/captureEventRequests';

/* These actions should all follow the same pattern
 * so callers can use them without know the details
 * of the particular action.
 * 
 * display: name to show for the action
 * isUserBased: action that is supposed to affect an array of users
 * sortFunction: sorting function that takes in an array of users and 
 *               sorts based on what makes sense for that action.
 * selectUsers: function that returns all users eligible for the action
 * 
 */

const TOTAL_PLAYERS_ALLOWED_TO_PURCHASE = 100;

export const EventActions = {
  applySync: {
    display: 'Apply Sync',
    key: 'applySync',
    isUserBased: false,
    sortFunction: (eventUsers) => [eventUsers, []],
    sortPredicate: eventUser => eventUser.launchMonitorDataSyncedStatus,
    selectUsers: (eventUsers) => [],
    postAction: async (eventId, syncAttemptId) => {
      await PostEventAction(eventId, 'ApplySync', { syncAttemptId });
    }
  },
  createResults: {
    display: 'Create Results',
    isUserBased: true,
    key: 'createResults',
    postAction: async (eventId, userIds, params = {}) => {
      await PostEventAction(eventId, 'CreateResults', { userIds, ...params });
    },
    sortFunction: (users) => sortUsersWithConditions(users, [
      u => u.resultsCreatedStatus === ACTION_STATUSES.notStarted 
        && u.launchMonitorDataSyncedStatus === ACTION_STATUSES.completed
    ]),
    sortPredicate: eventUser => eventUser.resultsCreatedStatus,
    selectUsers: (users) => userSelection(users,
      u => u.resultsCreatedStatus === ACTION_STATUSES.notStarted 
        && u.launchMonitorDataSyncedStatus === ACTION_STATUSES.completed
    )
  },
  create3sActionPlan: {
    display: 'Send 3s Action Plans',
    isUserBased: true,
    postAction: async (eventId, userIds) => {
      await PostEventAction(eventId, 'Create3SDescriptionActionPlan', { userIds });
    },
    sortFunction: (users) => sortUsersWithConditions(users, [
      u => u.initialActionPlanSentStatus === ACTION_STATUSES.notStarted 
          && u.resultsCreatedStatus === ACTION_STATUSES.completed
    ]),
    sortPredicate: eventUser => eventUser.initialActionPlanSentStatus,
    selectUsers: (users) => userSelection(users,
      u => u.initialActionPlanSentStatus === ACTION_STATUSES.notStarted 
          && u.resultsCreatedStatus === ACTION_STATUSES.completed
    )
  },
  enablePurchase: {
    display: 'Enable for puchasing',
    isUserBased: true,
    postAction: async (eventId, userIds) => {
      await PostEventAction(eventId, 'EnablePurchasing', { userIds });
    },
    sortFunction: (users) => sortUsersWithConditions(users, [
      u => u.initialActionPlanSentStatus === ACTION_STATUSES.completed
    ]),
    sortPredicate: eventUser => eventUser.paidProductEnabled,
    selectUsers: (users) => {
      var existingUsers = users.filter(user => user.paidProductEnabled);
      return userSelection(users,
        u => u.initialActionPlanSentStatus === ACTION_STATUSES.completed 
        && !u.paidProductEnabled
      ).slice(0, TOTAL_PLAYERS_ALLOWED_TO_PURCHASE - existingUsers.length);
    }
  }
};

/* Since the statuses are not simple booleans, this function
 * will allow you to start with a series of conditions.
 */
function sortUsersWithConditions(users, conditions) {
  let usersToSelect = [];

  function testUserAgainstConditions(user) {
    for (var i = 0; i < conditions.length; i++) {
      if (conditions[i](user)) {
        if (i === 0) {
          usersToSelect.push(user);
        }
        return i;
      }
    }
    // no conditions hit
    return conditions.length;
  }
  return [...users].sort((a, b) => testUserAgainstConditions(a) - testUserAgainstConditions(b));
}

function userSelection(users, condition) {
  return users.filter(user => condition(user));
}

export const AllActions = [
  EventActions.applySync, 
  EventActions.createResults,
  EventActions.create3sActionPlan,
  EventActions.enablePurchase
];