import React from 'react';
import Logger from 'js-logger';
import sortBy from 'lodash/sortBy';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import DropdownCarousel from '../../../../components/dropdownCarousel';
import { GetMotionVideos } from '../../../../network/videoRequests';

import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import VideoPlayer from '../../../../components/videoPlayer';
import StyledFileInput from '../../../../components/styledFileInput';
import { history } from '../../../../store';

const AVAILABLE = 'Available';

class VideoCarousel extends React.Component {
  state = {
    currentIdx: '',
    loading: true,
    videos: []
  }

  _cancelToken = axios.CancelToken.source();

  componentDidMount() {
    this._fetchVideos();
  }

  componentWillUnmount() {
    this._cancelToken.cancel();
  }

  _fetchVideos = async () => {
    const { userId } = this.props;
    try {
      const videos = await GetMotionVideos(userId, AVAILABLE, this._cancelToken);
      this.setState({ 
        videos: sortBy(videos, x => x.creationTimestamp).reverse(), 
        loading: false,
        currentIdx: videos.length > 0 ? 0 : ''
      });
    } catch (e) {
      if (!axios.isCancel(e)) {
        Logger.error(`Error fetching videos for user ${userId}.`, e);
        this.setState({ loading: false });
      }
    }
  }

  _onFileSelection = files => {
    const { userId } = this.props;
    history.push({ 
      pathname: `/player-development/player/${userId}/video`, 
      state: { initialFiles: files }
    });
  }

  render () {
    const { currentIdx, loading, videos } = this.state;
    const { classes, hideUploadVideoButton = false } = this.props;
    const video = videos[currentIdx];

    return <DropdownCarousel
      items={videos.map(x => {
        const dateString = (new Date(x.creationTimestamp)).toLocaleString();
        const noTitle = (x.title == null || x.title === '');
        return <div> 
          <Typography className={classNames({ [classes.untitled]: noTitle })}>
            {noTitle ? 'Untitled' : x.title} 
          </Typography>
          {dateString} </div>;      
      })}
      currentIdx={currentIdx}
      onChange={idx => this.setState({ currentIdx: idx })}
    >
      {loading 
        ? <div className={classes.progress}><CircularProgress /></div>
        : videos.length > 0 && video
          ? <VideoPlayer title={video.id} src={video.url} options={{ loop: true }} />
          : <div className={classes.aspectRatioContainer}>
            <Paper className={classes.noVideosContainer}>
              <Typography className={classes.noVideosLabel}>
                This player does not have any motion videos.
              </Typography>
              {
                !hideUploadVideoButton && 
                <StyledFileInput multiple
                  className={classes.fileInput}
                  name='video_data'
                  accept='video/*'
                  label='Upload Videos'
                  onChange={this._onFileSelection}
                />
              }
            </Paper>
          </div>
      }
    </DropdownCarousel>;
  }
}

export default withStyles(styles)(VideoCarousel);