import { decimalFormatter } from '../../utils/formatting.utils';

export const speedValues = [
  // True Speeds
  [
    {
      key: 'truePeakSpeedPelvis',
      title: 'True Speed Pelvis',
      formatter: decimalFormatter,
      abbreviation: 'TPSP'
    },
    {
      key: 'truePeakSpeedTorso',
      title: 'True Speed Torso',
      formatter: decimalFormatter,
      abbreviation: 'TPST'
    }
  ],
  // Peak Speeds
  [
    {
      key: 'peakSpeedPelvis',
      title: 'Peak Speed Pelvis',
      units: 'deg/s',
      abbreviation: 'PSP'
    },
    {
      key: 'peakSpeedTorso',
      title: 'Peak Speed Torso',
      units: 'deg/s',
      abbreviation: 'PST'
    },
    {
      key: 'peakSpeedUpperArm',
      title: 'Peak Speed Upper Arm',
      units: 'deg/s',
      abbreviation: 'PSUA'
    },
    {
      key: 'peakSpeedLowerArm',
      title: 'Peak Speed Lower Arm',
      units: 'deg/s',
      abbreviation: 'PSLA'
    },
    {
      key: 'peakSpeedHand',
      title: 'Peak Speed Hand',
      units: 'deg/s',
      abbreviation: 'PSH'
    }
  ],
  // Speed Gains
  // NOTE: these are backwards from displayed to user vs stored in db:
  [
    {
      key: 'speedGainTorsoToPelvis',
      title: 'Speed Gain Pelvis to Torso',
      formatter: decimalFormatter,
      abbreviation: 'SGPT'
    },
    {
      key: 'speedGainArmToTorso',
      title: 'Speed Gain Torso to Arm',
      formatter: decimalFormatter,
      abbreviation: 'SGTA'
    },
    {
      key: 'speedGainHandToArm',
      title: 'Speed Gain Arm Hand',
      formatter: decimalFormatter,
      abbreviation: 'SGAH'
    }
  ]
];