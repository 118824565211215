const MotionTypes = {
  golf: 'Golf',
  golfLeadWrist: 'GolfLeadWrist',
  baseball: 'Baseball',
  baseballPitch: 'BaseballPitch',
  fitness: 'Fitness'
};

export default MotionTypes;

export const MotionAttributes = {
  onBaseU: 'OnBaseU',
  ebt: 'EBT',
  speed: 'Speed',
  sequence: 'Sequence',
  stretch: 'Stretch',
  perfectGame: 'PerfectGame'
};