import React, { useCallback } from 'react';
import { CircularProgress, Dialog, DialogContent, Typography } from '@material-ui/core';
import useNetworkRequest from '../../../../network/useNetworkRequest';
import { GetAnalyzedSwingData } from '../../../../network/motionRequests';
import KinematicSequenceGraphDark from '../../../../components/kinematicSequenceGraphDark';
import KinematicDetailsDense from '../../../../components/kinematicDetailsDense';

function MotionInfoDialog({ open, motion, onClose }) {
  const analyzedFramesRequest = useCallback(async cancelToken => {
    if (motion?.id == null) {
      return [];
    }
    return await GetAnalyzedSwingData(motion.id, cancelToken);
  }, [motion?.id]);
  const [
    analyzedFrames, 
    loading, 
    error
  ] = useNetworkRequest([], 'getAnalyzedFrames', analyzedFramesRequest);

  return <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
    <DialogContent>
      {loading && <CircularProgress />}
      {error && <Typography color='error'>Error loading graph data</Typography>}
      {!loading && !error && motion != null && <>
        <KinematicSequenceGraphDark 
          motion={motion}
          analyzedFrames={analyzedFrames}
          fullMotionMetrics={motion.fullMotionMetrics}
        />
        <KinematicDetailsDense 
          motion={motion}
          userId={motion.userId}
        />
      </>}
    </DialogContent>
  </Dialog>;
}

export default MotionInfoDialog;
