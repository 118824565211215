import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { PopupHeaderStyles } from './styles';

import KMotionLogo from '../../assets/KMotionLogo.png';
import PGAndKMotionLogo from '../../assets/pgtechPlusKmotion.png';

class PopupHeader extends React.Component {
  render() {
    const { classes, showPGAndKMotionHeader } = this.props;
    const Logo = showPGAndKMotionHeader ? PGAndKMotionLogo : KMotionLogo;
    return (
      <DialogTitle className={classes.outerContainer} id='scroll-dialog-title'>
        <img src={Logo} alt='K-Motion' height={60} />
        <div className={classes.noPadding} style={{ textAlign: 'center' }}>
          {this.props.title}
        </div>
      </DialogTitle>
    );
  }
}

PopupHeader.propTypes = { 
  title: PropTypes.string,
  showPGAndKMotionHeader: PropTypes.bool
};

export default withStyles(PopupHeaderStyles)(PopupHeader);