import { makeStyles } from '@material-ui/core';
import Palette from '../../../styles/palette';
const useStyles = makeStyles(theme => ({
  tableContainer: {
    padding: theme.spacing(2)
  },
  rowHeader: {
    whiteSpace: 'nowrap',
    position: 'sticky',
    left: 0,
    backgroundColor: Palette.white
  },
  metricCell: ({ alpha }) => ({
    backgroundColor: 'rgba(77,171,245,' + alpha + ')'
  })
}));

export default useStyles;
