import { useCallback, useEffect, useState } from 'react';
import {
  LinearProgress, 
  Tabs, 
  Tab, 
  Paper,
  useMediaQuery
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { TRAINING_PLAN_SECTIONS as SECTIONS, FULL_PLAN_SECTION, TRAINING_PLAN_DOCUMENT_TYPE } from 'constants/trainingPlans.constants';
import useStyles from './styles';
import { GetTrainingEvents, CreateTrainingEvent } from 'network/trainingEventRequests';
import { GetTrainingPlansForAssociatedAccounts } from 'network/trainingPlanRequests';
import { maxBy } from 'lodash';
import { TRAINING_EVENT_TYPES } from 'constants/trainingEvents.constants';
import { NOTE_STATUSES } from 'constants/noteStatuses.constants';
import ProgressButton from 'components/progressButton';
import Logger from 'js-logger';
import axios from 'axios';
import Analytics, { TRAINING_LOGGED, CATEGORIES } from 'services/analytics';
import { getUserId } from 'utils/auth.utils';
import GoogleDocIFrame from 'components/googleDocIFrame';
import TrainingPlanMenu from './trainingPlanMenu';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import classNames from 'classnames';
import { history } from 'store/';
import Leaderboard from './leaderboard';

const LARGE_DISPLAY_CUTOFF = '675px';

/**
 * @param {func} setActivePlanSection, called to change plan section.
 *  Ideally it is wrapped by useCallback since it is used as a depenency. 
 */
const TrainingPlan = ({ 
  user,
  trainingPlan,
  activePlanSection,
  setActivePlanSection, 
  pageName 
}) => {
  // Used since sometimes the gdoc appears blank at first:
  const [showSpinner, setShowSpinner] = useState(true);
  const [creatingTrainingEvent, setCreatingTrainingEvent] = useState(false);
  const [otherPlansUserCanView, setOtherPlansUserCanView] = useState([]);

  const activeSectionUrl = SECTIONS[activePlanSection]?.extractUrl(trainingPlan);

  const [todaysTrainingEvents, setTodaysTrainingEvents] = useState(null);
  const [fetchingTrainingEvents, setFetchingTrainingEvents] = useState(false);

  const userId = trainingPlan?.userId;

  const hasTrainedToday = todaysTrainingEvents != null
    ? todaysTrainingEvents.length > 0
    : false;

  const isLargeDisplay = useMediaQuery(`(min-width:${LARGE_DISPLAY_CUTOFF})`);

  const classes = useStyles({ hasTrainedToday });
  const tabClass = isLargeDisplay ? undefined : classes.tabSmall;
  useEffect(() => {
    if (!userId) return;
    
    const cancelToken = axios.CancelToken.source();
    async function fetchTrainingEvents() {
      setFetchingTrainingEvents(true);
      try {
        // This call will return all events that happened today since the call
        // will get the start and end of day when querying
        const now = (new Date()).toISOString();
        const params = { earlierThan: now, laterThan: now };
        const todaysEvents = await GetTrainingEvents(userId, params, cancelToken);
        setTodaysTrainingEvents(todaysEvents);
      } catch (ex) {
        if (axios.isCancel(ex)) return;
        Logger.error(`Error fetching training events for user ${userId}`, ex);
      }
      setFetchingTrainingEvents(false);
    }
    fetchTrainingEvents();
    return cancelToken.cancel;
  }, [userId]);

  useEffect(() => {
    if(!user) return;
    (async () => {
      const associatedLatestTrainingPlans = 
        await GetTrainingPlansForAssociatedAccounts({ userId: user.userId });
      setOtherPlansUserCanView(associatedLatestTrainingPlans);
    })();
  }, [user]);

  const isInvalidSection = useCallback((planSection) => {
    return SECTIONS[planSection] == null;
  }, []);

  const handleFrameLoading = useCallback(() => {
    setShowSpinner(false);
  }, [setShowSpinner]);
  
  const switchTabs = useCallback((sectionKey) => {
    setActivePlanSection?.(sectionKey);    
  }, [setActivePlanSection]);

  useEffect(() => {
    if(isInvalidSection(activePlanSection)) {
      switchTabs(FULL_PLAN_SECTION.key);
    }
  }, [activePlanSection, setActivePlanSection, switchTabs, isInvalidSection]);

  useEffect(() => {
    setShowSpinner(true);
  }, [activePlanSection, setActivePlanSection]);

  const logTrainingEvent = async () => {
    if (creatingTrainingEvent || hasTrainedToday) return;
    setCreatingTrainingEvent(true);
    try {
      const sentNotes = trainingPlan.notes.filter(x => x.status === NOTE_STATUSES.sent);
      const latestSentNote = maxBy(sentNotes, x => x.sequenceOrder);
      const newEvent = await CreateTrainingEvent({
        userId,
        timestamp: new Date(),
        noteId: latestSentNote.id,
        type: TRAINING_EVENT_TYPES.Logged
      });
      setTodaysTrainingEvents(prev => [...prev, newEvent]);

      Analytics.track({ 
        eventName: TRAINING_LOGGED, 
        pageName,
        userId: getUserId(),
        userEmail: user?.emailAddress,
        miscData: { 
          trainingPlanId: trainingPlan.id,
          noteId: latestSentNote.id,
          traingPlanUserId: trainingPlan.userId
        },
        eventCategory: CATEGORIES.TRAINING_PLAN 
      });

    } catch (ex) {
      Logger.error(
        `Error creating training event for training plan ${trainingPlan?.id}`, ex);
    }

    setCreatingTrainingEvent(false);
  };
  
  const isSpreadSheet = 
    SECTIONS[activePlanSection]?.documentType === TRAINING_PLAN_DOCUMENT_TYPE.googleSheet;

  const navigateToLatestTrainingPlans = () => {
    history.push('/all-training-plans');
  };
  const onSeeOtherPlans = 
    otherPlansUserCanView?.length > 0 ? navigateToLatestTrainingPlans : undefined;

  return <div className={classes.root}>

    <div className={classes.topBarHolder}>
      <Paper className={classes.topBar}>
        <Tabs
          value={activePlanSection}
          indicatorColor='primary'
          textColor='primary'
          onChange={(_, value) => switchTabs(value)}
          aria-label='training--plan-tabs'
        >
          {Object.keys(SECTIONS).map(
            sectionKey => { 
              const { title } = SECTIONS[sectionKey];
              return <Tab className={tabClass} key={title} label={title} value={sectionKey} />;
            })}
        </Tabs>

        {showSpinner && <div className={classes.spinner}>
          <LinearProgress className={classes.spinner} /></div>}

        <div className={classes.rightSideButtons}>
          <ProgressButton
            className={classNames(
              classes.logTrainingButton,
              {
                [classes.anchoredTrainingButton]: isLargeDisplay,
                [classes.floatingTrainingButton]: !isLargeDisplay
              }
            )}
            disabled={fetchingTrainingEvents}
            showProgress={creatingTrainingEvent}
            color='primary'
            variant='contained'
            disableElevation={hasTrainedToday}
            endIcon={
              hasTrainedToday
                ? <CheckBoxIcon />
                : <CheckBoxOutlineBlankIcon />
            }
            onClick={logTrainingEvent}
          >
            Log Daily Training
          </ProgressButton>

          <TrainingPlanMenu user={user} pageName={pageName} onSeeOtherPlans={onSeeOtherPlans}/>
        </div>
      </Paper>
    </div>

    {SECTIONS[activePlanSection]?.leaderboard 
      ? <Leaderboard user={user} trainingPlan={trainingPlan} onLoad={handleFrameLoading} />
      : <GoogleDocIFrame 
        isSpreadsheet={isSpreadSheet} 
        url={activeSectionUrl} 
        onLoad={handleFrameLoading} 
      />}
  </div>;
};

TrainingPlan.propTypes = {
  trainingPlan: PropTypes.object,
  activePlanSection: PropTypes.string,
  setActivePlanSection: PropTypes.func
};

export default TrainingPlan;