/* 
  This state stores org actions taken against users.
  It is used to identify users that show up in org actions
  where the data has changed and they no longer need actions.

  The reason we cannot simply just remove them from the org action state
  is that they might need to still be displayed on the screen.

  State is an array of objects with actionItem and userId properties.
*/

// Actions
const ADD_ACTION = 'kdashboard/orgActionsTaken/add';
const CLEAR_ACTIONS = 'kdashboard/orgActionsTaken/clear';

// Reducer
const initialState = [];
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_ACTION:
      return [...state, action.payload];
    case CLEAR_ACTIONS:
      return initialState;  
    default: 
      return state;
  }
}

// Action Creators
export function addOrgActionTaken(actionItem, userId) {
  return { type: ADD_ACTION, payload: { actionItem, userId }};
}

export function clearOrgActionsTaken() {
  return { type: CLEAR_ACTIONS };
}

