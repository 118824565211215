import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ProgressButton from '../../../../components/progressButton';
import { ANALYSIS_TYPE } from '../../../../constants/videoAnalysis.constants';
import { PERMISSIONS } from '../../../../constants/permissions.constants';
import useStyles from './styles';

function RequestModal({ 
  open, 
  onCancel, 
  onSelection, 
  user, 
  loading
}) {
  const classes = useStyles();
  const includeCheckins = useSelector(state => 
    state.featurePermissions.includes(PERMISSIONS.internalTooling));
  const playerName = user && user.firstName ? user.firstName : 'the player';

  return (
    <Dialog onClose={onCancel} aria-labelledby='confirmation-dialog' open={open}>
      <DialogTitle onClose={onCancel}>
        Request New Videos?
        <IconButton onClick={onCancel} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
            
      <DialogContent>
        <Typography>{`Requesting videos to analyze will notify ${playerName} ` +
          'and allow them to upload videos from their mobile device.'}
        </Typography>
      </DialogContent>

      <DialogActions className={classes.analysisTypeButtonsContainer}>
        <ProgressButton 
          onClick={() => onSelection(ANALYSIS_TYPE.fullAnalysis)} 
          color='primary'
          variant='contained' 
          showProgress={loading}
          className={classes.analysisTypeButton}
        >
          {includeCheckins ? 'Full Analysis' : 'Confirm'}
        </ProgressButton>
        {includeCheckins && <ProgressButton 
          onClick={() => onSelection(ANALYSIS_TYPE.checkIn)} 
          color='primary'
          variant='contained' 
          showProgress={loading}
          className={classes.analysisTypeButton}
        >
          Check In
        </ProgressButton>}
      </DialogActions>
    </Dialog>
  );
}

RequestModal.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object,
  onSelection: PropTypes.func.isRequired, // this func will be passed the analysis type selected
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default RequestModal;
