import { Checkbox } from '@material-ui/core';
import useStyles from './styles';

function ColoredCheckbox({ color, style, classes, ...props }) {
  const internalClasses = useStyles();
  return <Checkbox
    {...props}
    style={{ '--checked-color': color, ...style }}
    classes={{ ...internalClasses, ...classes }}
  />;
}

export default ColoredCheckbox;
