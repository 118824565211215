import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import {
  DialogTitle,
  DialogContent,
  Typography,
  Grid
} from '@material-ui/core';
import ErrorSnackbar from '../../../../../components/errorSnackbar';
import VideoCarousel from '../../../../../pages/playerDataTabs/overview/videoCarousel';
import useInput from '../../../../../utils/useInput';
import SearchBar from '../../../../../components/searchBar';
import classnames from 'classnames';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import KinematicCarousel from '../../../../../components/kinematicCarousel';
import { TAB_PERMISSIONS } from '../../../../../constants/permissions.constants';
import useEventUserSearchInput from '../useEventUserSearchInput';

function EventUserScoutPlayersDialog({
  eventUser,
  captureEventId,
  onSelectUser
}) {
  const classes = useStyles();
  const searchInput = useInput();
  const featurePermissions = useSelector(state => state.featurePermissions);

  const {
    error: captureEventsError,
    items: captureEvents
  } = useSelector(({ captureEvents }) => captureEvents);

  const {
    error: captureEventError,
    item: captureEvent
  } = captureEvents[captureEventId] || {};

  const [error, setError] = useState(null);

  useEffect(() => {
    if (captureEventsError || captureEventError) {
      setError('There was an error loading event data.');
    }
  }, [captureEventsError, captureEventError]);

  const eventUsers = captureEvent ? captureEvent.captureEventUsers : null;
  const currentEventUserId = eventUser ? eventUser.id : null;
  const eventName = captureEvent ? captureEvent.name : 'No capture event selected';

  useEffect(() => {
    // reset on changing capture event id
    const clearValue = searchInput.clearValue;
    clearValue();
  }, [captureEventId, searchInput.clearValue]);

  const filteredEventUsers = useEventUserSearchInput(searchInput, eventUsers);

  const { user, userId } = eventUser;

  return <>
    <>
      <DialogTitle disableTypography>
        <div className={classes.titleContainer}>
          <div className={classes.topLeftInfo}>
            <span className={classes.eventName}>{ eventName }</span>
            <span className={classes.divider}> - </span>
            <span className={classes.playerName}>{ user.firstName } { user.lastName }</span>
          </div>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.content}>
          <div className={classes.sidebar}>
            <div className={classes.searchBar}>
              <SearchBar
                {...searchInput.bind}
                onClear={searchInput.clearValue}
                placeholder='Filter Players'
              />
            </div>
            <SimpleBar className={classes.playersSidebar}>
              {
                filteredEventUsers.map(((eventUser) => {
                  const { id, userId, user } = eventUser;
                  const { firstName, lastName } = user;
                  const selected = eventUser.id === currentEventUserId;
                  return <div
                    key={userId}
                    className={classnames(
                      classes.player,
                      { [classes.selected]: selected }
                    )}
                    onClick={() => onSelectUser(id)}>
                    <Typography align='right'>
                      { firstName } { lastName }
                    </Typography>
                  </div>;
                }))
              }
            </SimpleBar>
          </div>

          <div className={classes.dataContainer}>
            <SimpleBar className={classes.dataScroll}>
              <Grid container spacing={5}>
                <Grid item xs={12} lg={6} xl={5}>
                  <KinematicCarousel key={userId} userId={userId} />
                </Grid>
                {featurePermissions.includes(TAB_PERMISSIONS.video) && 
                  <Grid item xs={12} lg={6} xl={5}>
                    <div className={classes.videoContainer}>
                      <VideoCarousel 
                        key={userId} 
                        userId={userId}
                        hideUploadVideoButton
                      />
                    </div>
                  </Grid>}
              </Grid>            
            </SimpleBar>
          </div>
        </div>
      </DialogContent>
    </>

    <ErrorSnackbar 
      message={error}
      onClose={() => setError(null)}
    />
  </>;
};

export default EventUserScoutPlayersDialog;