import React, { 
  useState, 
  useEffect, 
  useRef, 
  useCallback 
} from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Grid
} from '@material-ui/core';
import SelectionList from '../../../components/selectionList';
import useStyles from './styles'; 

const MAX_ABBREVIATION_LENGTH = 4;

const EditTeam = ({ team, players, onCancel, onSubmit, onDelete }) => {
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [teamName, setTeamName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [isNameValidationError, setIsNameValidationError] = useState(false);
  const [isAbbreviationValidationError, setIsAbbreviationValidationError] = useState(false);
  const classes = useStyles();
  let containerRef = useRef();

  useEffect(() => {
    const teamPlayers = team && team.userTeams
      ? team.userTeams.map(user => user.userId)
      : [];
    setSelectedPlayers(players.filter(p => teamPlayers.includes(p.userId)));
    setTeamName(team ? team.name : '');
    setAbbreviation((team && team.abbreviation) ? team.abbreviation : '');
  }, [team, players]);

  const goToTop = useCallback(() => {
    containerRef && containerRef.current && containerRef.current.scrollIntoView();
  }, []);

  useEffect(() => {
    isNameValidationError && goToTop();
  }, [isNameValidationError, goToTop]);

  useEffect(() => {
    setIsAbbreviationValidationError(
      abbreviation != null && abbreviation.length > MAX_ABBREVIATION_LENGTH
    );
  }, [abbreviation]);

  const abbreviationInputChanged = useCallback((e) => setAbbreviation(e.target.value), []);

  const submitClick = () => {
    if (teamName == null || teamName.trim() === '') {
      setIsNameValidationError(true);
      return;
    }

    if (abbreviation != null && abbreviation.length > MAX_ABBREVIATION_LENGTH) {
      setIsAbbreviationValidationError(true);
      goToTop();
      return;
    }

    setIsNameValidationError(false);
    setIsAbbreviationValidationError(false);
    onSubmit({ teamName, selectedPlayers, abbreviation });
  };

  const deleteClick = () => {
    if (window.confirm(`Are you sure you want to delete ${teamName}?`)) {
      onDelete();
    }
  };

  const cancelClick = () => {
    setIsNameValidationError(false);
    setIsAbbreviationValidationError(false);
    onCancel();
  };

  return (
    <Dialog open={team != null} fullWidth maxWidth='lg'>
      <div className={classes.dialog} ref={containerRef}>
        <DialogTitle>
          <Grid container justify='flex-start'>
            <TextField
              className={classes.textFieldMargins}
              error={isNameValidationError}
              variant='outlined'
              label='Team Name'
              defaultValue={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              helperText={isNameValidationError ? 'Name is required' : null}
            />
            <TextField
              error={isAbbreviationValidationError}
              variant='outlined'
              label='Abbreviation (Optional)'
              defaultValue={abbreviation}
              value={abbreviation}
              onChange={abbreviationInputChanged}
              helperText={
                isAbbreviationValidationError ? 
                  `${MAX_ABBREVIATION_LENGTH} character limit` : 
                  null
              }
            />
            {team && team.id && 
              <Button variant='contained'color='secondary' onClick={deleteClick}>
                Delete Team
              </Button>
            }
          </Grid>
        </DialogTitle>
        <SelectionList
          items={players}
          selectedItems={selectedPlayers}
          onChange={players => setSelectedPlayers(players)}
          columnWidths={{ xs: 4 }}
          getLabel={x => `${x.firstName} ${x.lastName}`}
          getValue={x => x.userId}
        />
        <DialogActions>
          <Button onClick={cancelClick}>Cancel</Button>
          <Button onClick={submitClick} color='primary'>Submit</Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default EditTeam;