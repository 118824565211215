import { decimalFormatter } from '../../utils/formatting.utils';

export const heelStrikePositionValues = [
  // Bend
  [
    {
      key: 'pelvisBendAtHeelStrike',
      title: 'Pelvis Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'HSPB'
    },
    {
      key: 'torsoBendAtHeelStrike',
      title: 'Torso Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'HSTB'
    }
  ],
  // Side Bend
  [
    {
      key: 'pelvisSideBendAtHeelStrike',
      title: 'Pelvis Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'HSPSB'
    },
    {
      key: 'torsoSideBendAtHeelStrike',
      title: 'Torso Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'HSTSB'

    }
  ],
  // Rotation
  [
    {
      key: 'pelvisRotationAtHeelStrike',
      title: 'Pelvis Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'HSPR'
    },
    {
      key: 'torsoRotationAtHeelStrike',
      title: 'Torso Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'HSTR'
    }
  ],
  // X-Factor
  [
    {
      key: 'xFactorAtHeelStrike',
      title: 'X-Factor',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'HSXF'
    }
  ]
];

export const firstMovePositionValues = [
  // Bend
  [
    {
      key: 'pelvisBendAtFirstMove',
      title: 'Pelvis Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'FMPB'
    },
    {
      key: 'torsoBendAtFirstMove',
      title: 'Torso Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'FMTB'
    }
  ],
  // Side Bend
  [
    {
      key: 'pelvisSideBendAtFirstMove',
      title: 'Pelvis Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'FMPSB'
    },
    {
      key: 'torsoSideBendAtFirstMove',
      title: 'Torso Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'FMTSB'
    }
  ],
  // Rotation
  [
    {
      key: 'pelvisRotationAtFirstMove',
      title: 'Pelvis Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'FMPR'
    },
    {
      key: 'torsoRotationAtFirstMove',
      title: 'Torso Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'FMTR'
    }
  ],
  // X-Factor
  [
    {
      key: 'xFactorAtFirstMove',
      title: 'X-Factor',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'FMXF'
    }
  ]
];

export const contactPositionValues = [
  // Bend
  [
    {
      key: 'pelvisBendAtContact',
      title: 'Pelvis Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'CONPB'
    },
    {
      key: 'torsoBendAtContact',
      title: 'Torso Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'CONTB'

    }
  ],
  // Side Bend
  [
    {
      key: 'pelvisSideBendAtContact',
      title: 'Pelvis Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'CONPSB'
    },
    {
      key: 'torsoSideBendAtContact',
      title: 'Torso Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'CONTSB'
    }
  ],
  // Rotation
  [
    {
      key: 'pelvisRotationAtContact',
      title: 'Pelvis Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'CONPR'

    },
    {
      key: 'torsoRotationAtContact',
      title: 'Torso Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'CONTR'
    }
  ],
  // X-Factor
  [
    {
      key: 'xFactorAtContact',
      title: 'X-Factor',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'CONXF'

    }
  ]
];

export const addressPositionValues = [
  // Bend
  [
    {
      key: 'pelvisBendAtAddress',
      title: 'Pelvis Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'ADDPB'
    },
    {
      key: 'torsoBendAtAddress',
      title: 'Torso Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'ADDTB'

    }
  ],
  // Side Bend
  [
    {
      key: 'pelvisSideBendAtAddress',
      title: 'Pelvis Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'ADDPSB'
    },
    {
      key: 'torsoSideBendAtAddress',
      title: 'Torso Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'ADDTSB'
    }
  ],
  // Rotation
  [
    {
      key: 'pelvisRotationAtAddress',
      title: 'Pelvis Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'ADDPR'

    },
    {
      key: 'torsoRotationAtAddress',
      title: 'Torso Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'ADDTR'
    }
  ],
  // X-Factor
  [
    {
      key: 'xFactorAtAddress',
      title: 'X-Factor',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'ADDXF'

    }
  ]
];

export const topPositionValues = [
  // Bend
  [
    {
      key: 'pelvisBendAtTop',
      title: 'Pelvis Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'TOPPB'
    },
    {
      key: 'torsoBendAtTop',
      title: 'Torso Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'TOPTB'

    }
  ],
  // Side Bend
  [
    {
      key: 'pelvisSideBendAtTop',
      title: 'Pelvis Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'TOPPSB'
    },
    {
      key: 'torsoSideBendAtTop',
      title: 'Torso Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'TOPTSB'
    }
  ],
  // Rotation
  [
    {
      key: 'pelvisRotationAtTop',
      title: 'Pelvis Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'TOPPR'

    },
    {
      key: 'torsoRotationAtTop',
      title: 'Torso Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'TOPTR'
    }
  ],
  // X-Factor
  [
    {
      key: 'xFactorAtTop',
      title: 'X-Factor',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'TOPXF'

    }
  ]
];

export const impactPositionValues = [
  // Bend
  [
    {
      key: 'pelvisBendAtImpact',
      title: 'Pelvis Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'IMPPB'
    },
    {
      key: 'torsoBendAtImpact',
      title: 'Torso Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'IMPTB'

    }
  ],
  // Side Bend
  [
    {
      key: 'pelvisSideBendAtImpact',
      title: 'Pelvis Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'IMPPSB'
    },
    {
      key: 'torsoSideBendAtImpact',
      title: 'Torso Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'IMPTSB'
    }
  ],
  // Rotation
  [
    {
      key: 'pelvisRotationAtImpact',
      title: 'Pelvis Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'IMPPR'

    },
    {
      key: 'torsoRotationAtImpact',
      title: 'Torso Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'IMPTR'
    }
  ],
  // X-Factor
  [
    {
      key: 'xFactorAtImpact',
      title: 'X-Factor',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'IMPXF'

    }
  ]
];


export const ballReleasePositionValues = [
  // Bend
  [
    {
      key: 'pelvisBendAtBallRelease',
      title: 'Pelvis Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'BRPB'
    },
    {
      key: 'torsoBendAtBallRelease',
      title: 'Torso Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'BRTB'

    }
  ],
  // Side Bend
  [
    {
      key: 'pelvisSideBendAtBallRelease',
      title: 'Pelvis Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'BRPSB'
    },
    {
      key: 'torsoSideBendAtBallRelease',
      title: 'Torso Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'BRTSB'
    }
  ],
  // Rotation
  [
    {
      key: 'pelvisRotationAtBallRelease',
      title: 'Pelvis Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'BRPR'

    },
    {
      key: 'torsoRotationAtBallRelease',
      title: 'Torso Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'BRTR'
    }
  ],
  // X-Factor
  [
    {
      key: 'xFactorAtBallRelease',
      title: 'X-Factor',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'BRXF'

    }
  ]
];

export const maxLoadPositionValues = [
  // Bend
  [
    {
      key: 'pelvisBendAtPeakPelvisSpeed',
      title: 'Pelvis Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'PPSPB'
    },
    {
      key: 'torsoBendAtPeakPelvisSpeed',
      title: 'Torso Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'PPSTB'

    }
  ],
  // Side Bend
  [
    {
      key: 'pelvisSideBendAtPeakPelvisSpeed',
      title: 'Pelvis Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'PPSPSB'
    },
    {
      key: 'torsoSideBendAtPeakPelvisSpeed',
      title: 'Torso Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'PPSTSB'
    }
  ],
  // Rotation
  [
    {
      key: 'pelvisRotationAtPeakPelvisSpeed',
      title: 'Pelvis Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'PPSPR'

    },
    {
      key: 'torsoRotationAtPeakPelvisSpeed',
      title: 'Torso Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'PPSTR'
    }
  ],
  // X-Factor
  [
    {
      key: 'xFactorAtPeakPelvisSpeed',
      title: 'X-Factor',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'PPSXF'

    }
  ]
];

export const peakPelvisSpeedPositionValues = [
  // Bend
  [
    {
      key: 'pelvisBendAtMaxLoad',
      title: 'Pelvis Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'MLPB'
    },
    {
      key: 'torsoBendAtMaxLoad',
      title: 'Torso Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'MLTB'

    }
  ],
  // Side Bend
  [
    {
      key: 'pelvisSideBendAtMaxLoad',
      title: 'Pelvis Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'MLPSB'
    },
    {
      key: 'torsoSideBendAtMaxLoad',
      title: 'Torso Side Bend',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'MLTSB'
    }
  ],
  // Rotation
  [
    {
      key: 'pelvisRotationAtMaxLoad',
      title: 'Pelvis Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'MLPR'

    },
    {
      key: 'torsoRotationAtMaxLoad',
      title: 'Torso Rotation',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'MLTR'
    }
  ],
  // X-Factor
  [
    {
      key: 'xFactorAtMaxLoad',
      title: 'X-Factor',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'MLXF'

    }
  ]
];