import React, { useState } from 'react';
import videoStatuses from '../../../../constants/video.constants';
import VideoPreviewDialog from '../../../../components/dialogs/videoPreviewDialog';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

const {
  available,
  uploadingError,
  processingError,
  stuckInProcessing
} = videoStatuses;

const isReady = ({ analyzedVideo }) => {
  if(analyzedVideo == null) {
    return false;
  }
  return analyzedVideo.status === available;
};

const errorProcessing = ({ analyzedVideo }) => {
  if(analyzedVideo == null) {
    return true;
  }
  const { status } = analyzedVideo;
  return status === uploadingError || 
    status === stuckInProcessing ||
    status === processingError;
};

const AnnotationStatus = ({ annotation }) => {
  const [previewVideo, setPreviewVideo] = useState(null);
  if(annotation == null) {
    return null;
  }

  const showPreviewVideo = () => {
    const { analyzedVideo } = annotation;
    setPreviewVideo(analyzedVideo);
  };

  let content;

  if(isReady(annotation)) {
    content = <div>            
            Your video is done processing and available:
      <Button color='primary'onClick={showPreviewVideo}>here</Button>.
    </div>;
  }
  else if(errorProcessing(annotation)) {
    content = <Typography> 
            There was an error with your video, you can try Redoing it.
    </Typography>;
  }
  else {
    content = <Typography>
            Your video is still processing. Please check back later.
    </Typography>;
  } 

  return <Card style={styles.root}>
    <VideoPreviewDialog 
      open={previewVideo != null}
      onClose={() => setPreviewVideo(null)}
      video={previewVideo}
    />
    <CardContent>  
      {content}
    </CardContent>
  </Card>;
};

export default AnnotationStatus;