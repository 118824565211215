import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import keyBy from 'lodash/keyBy';
import transform from 'lodash/transform';
import { 
  Box,
  Button,
  CircularProgress,
  Paper, 
  Grid, 
  List, 
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  IconButton, 
  Typography,
  useTheme
} from '@material-ui/core'; 
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { formatShortDateWithSeconds } from '../../../utils/formatting.utils';
import useNetworkRequest from '../../../network/useNetworkRequest';
import { GetAnalyzedSwingData } from '../../../network/motionRequests';
import KinematicSequenceGraphDark from '../../../components/kinematicSequenceGraphDark';
import KinematicDetailsDense from '../../../components/kinematicDetailsDense';
import useKeyDown from '../../../utils/useKeyDown';
import ColoredCheckbox from '../../../components/coloredCheckbox';
import ProgressButton from '../../../components/progressButton';
import TagInfo from './tagInfo';

function MotionSelectionView({
  motions,
  motionsLoading,
  selectedMotions,
  onSelectedMotionsChange,
  motionsEarlierThan, 
  setMotionsEarlierThan,
  pageSize,
  currentPage,
  totalPages,
  onCancel,
  onSave,
  saveDisabled,
  saveLoading,
  onNext,
  onPrevious,
  includeUserName,
  checkboxColor,
  actions
}) {
  const [detailedMotion, setDetailedMotion] = useState(null);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

  const { tags } = useSelector(state => state.dataAnalysis);
  const tagsByMotionId = transform(tags, (result, tag) => {
    const { motionIds, ...rest } = tag;
    motionIds.forEach(motionId => {
      (result[motionId] || (result[motionId] = [])).push(rest);
    });
  }, {});

  const shiftKeyDown = useKeyDown('Shift');
  const theme = useTheme();

  const analyzedFramesRequest = useCallback(async cancelToken => {
    if (detailedMotion?.id == null) {
      return [];
    }
    return await GetAnalyzedSwingData(detailedMotion.id, cancelToken);
  }, [detailedMotion?.id]);
  const [
    analyzedFrames, 
    loading
  ] = useNetworkRequest([], 'getAnalyzedFrames', analyzedFramesRequest);

  const getMotionSecondaryText = motion => {
    const { 
      peakSpeedPelvis, 
      peakSpeedTorso, 
      peakSpeedUpperArm, 
      peakSpeedHand 
    } = motion.fullMotionMetrics ?? {};
    const { ballspeedMph } = motion.launchMonitorData ?? {};
    const values = [
      peakSpeedPelvis, 
      peakSpeedTorso, 
      peakSpeedUpperArm, 
      peakSpeedHand, 
      ballspeedMph
    ];

    return values.map(value => value ?? 'N/A').join('—') + 'mph';
  };

  const getMultipleMotionSelection = (idx, lastSelectedIdx) => {
    const startIdx = Math.min(idx, lastSelectedIdx);
    const endIdx = startIdx === idx ? lastSelectedIdx : idx;
    return keyBy(motions.slice(startIdx, endIdx + 1), 'id');
  };

  const onCheckboxChange = (motion, checked, idx) => {
    if (checked) {
      const motionsToAdd = lastSelectedIndex != null && shiftKeyDown
        ? getMultipleMotionSelection(idx, lastSelectedIndex)
        : { [motion.id]: motion };
      onSelectedMotionsChange(prev => ({ ...prev, ...motionsToAdd }));
      setLastSelectedIndex(idx);
    }
    else {
      onSelectedMotionsChange(prev => {
        const newSelectedMotions = { ...prev };
        delete newSelectedMotions[motion.id];
        return newSelectedMotions;
      });
      setLastSelectedIndex(null);
    }
  };

  const getMotionItemPrimaryText = motion => {
    let text = formatShortDateWithSeconds(motion.timestamp);
    if (includeUserName && motion.user) {
      text += ` - ${motion.user.firstName} ${motion.user.lastName}`;
    }
    return text;
  };

  return <Grid container spacing={1} onClick={() => setLastSelectedIndex(null)}>
    <Grid item xs={5}>
      <Paper>
        {setMotionsEarlierThan && <Box paddingLeft={2}>
          <KeyboardDatePicker  
            label='Motions On or Before:'
            disableToolbar
            disableFuture
            id='motion-selection-after-picker'
            variant='inline'
            format='MM/dd/yy'
            margin='dense'
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            value={motionsEarlierThan}
            onChange={setMotionsEarlierThan}
          />
        </Box>}

        {!motionsLoading 
          ? <List dense>
            {motions.map((motion, idx) => <ListItem 
              key={motion.id} 
              button 
              onClick={() => setDetailedMotion(motion)}
              selected={motion.id === detailedMotion?.id}
              divider
            >
              <ListItemIcon>
                <ColoredCheckbox 
                  edge='start'
                  checked={selectedMotions[motion.id] != null}
                  tabIndex={-1}
                  disableRipple
                  onChange={(_, checked) => onCheckboxChange(motion, checked, idx)}
                  onClick={e => { e.stopPropagation(); }}
                  color={checkboxColor ?? theme.palette.primary.main}
                />
              </ListItemIcon>
              <ListItemText 
                primary={getMotionItemPrimaryText(motion)}
                secondary={getMotionSecondaryText(motion)}
              />
              <ListItemSecondaryAction>
                <TagInfo tags={tagsByMotionId[motion.id]} />
              </ListItemSecondaryAction>
            </ListItem>)}
          </List>
          : Array.from(Array(pageSize).keys()).map(idx => 
            <Skeleton 
              key={`loading-skeleton-${idx}`} 
              height={58}
            />)}

        <Grid container>
          <Grid item xs={2}>
            {onPrevious && <IconButton 
              disabled={motionsLoading} 
              onClick={onPrevious}
            >
              <ArrowBack />
            </IconButton>}
          </Grid>
          <Grid item xs={8} container justify='center' alignItems='center'>
            <Typography align='center'>{currentPage} / {totalPages}</Typography>
          </Grid>
          <Grid item xs={2} container justify='flex-end'>
            {onNext && <IconButton 
              disabled={motionsLoading} 
              onClick={onNext}
            >
              <ArrowForward />
            </IconButton>}
          </Grid>
        </Grid>
      </Paper>
    </Grid>

    <Grid item xs={7}>
      <Box display='flex' flexDirection='column' height='100%'>
        <Box flex={1}>
          {detailedMotion && <Paper>
            {loading 
              ? <CircularProgress />
              : <div>
                <KinematicSequenceGraphDark 
                  motion={detailedMotion}
                  analyzedFrames={analyzedFrames}
                  fullMotionMetrics={detailedMotion.fullMotionMetrics}
                />
                <KinematicDetailsDense motion={detailedMotion} userId={detailedMotion.userId} />
              </div>}
          </Paper>}
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          {actions && <Box p={1}>
            {actions}
          </Box>}
          <Box p={1}>
            <Button onClick={onCancel} variant='outlined'>
              Cancel
            </Button>
          </Box>
          <Box p={1}>
            <ProgressButton 
              onClick={onSave}
              variant='outlined'
              color='primary'
              disabled={saveDisabled}
              showProgress={saveLoading}
            >
              Save
            </ProgressButton>
          </Box>
        </Box>
      </Box>
    </Grid>
  </Grid>;
}

export default MotionSelectionView;
