import { makeStyles } from '@material-ui/styles';
import palette from '../../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  container: () => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center' 
  }),
  labels: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    overflow: 'auto',
    paddingTop: '6px'
  },
  labelName: {
    whiteSpace: 'pre'
  },
  button: {
    backgroundColor: palette.mediumGray,
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.darkGray,
      filter: 'brightness(80%)'
    },
    marginRight: '15px'
  },
  buttonText: {
    fontSize: '14px',
    color: 'black',
    whiteSpace: 'nowrap'
  },
  labelTagContainer: {
    marginRight: '10px',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignSelf: 'flex-start'
  },
  removeLabelButton: {
    borderRadius: '100%',
    width: '16px',
    height: '16px',
    backgroundColor: palette.mediumGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginLeft: '3px'
  }
}));

export default useStyles;