import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import palette from '../../styles/palette';

class ProgressBar extends React.Component {
  render() {
    const { classes, progress, width, label, foreground, background } = this.props;
    return <div 
      className={classes.container} 
      style={{ width: `${width}px`, backgroundColor: background }}
    >
      <Typography align='center' className={classes.bar}>
        { label || `${progress.toFixed(0)}%`}
      </Typography>
      <div 
        className={classes.bar} 
        style={{ width: `${progress}%`, backgroundColor: foreground, zIndex: 1 }}
      />
    </div>;
  }
}

ProgressBar.defaultProps = {
  width: 200,
  foreground: palette.lightGray,
  background: palette.transparent
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  label: PropTypes.string, // When provided, overrides the progress percentage displayed
  width: PropTypes.number,
  foreground: PropTypes.string, // color of progress bar
  background: PropTypes.string // color of background of progress bar
};

export default withStyles(styles)(ProgressBar);