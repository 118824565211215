import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { Fab, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import ErrorSnackbar from '../../../../components/errorSnackbar';
import StyledSelect from '../../../../components/styledSelect';

import useStyles from './styles';
import { formatMMDDYY } from '../../../../utils/formatting.utils';
import useMobilityScreens from './useMobilityScreens';
import useMetricDefinitionsMap from './useMetricDefinitionsMap';
import { TABLE_ROWS, CATEGORIES, MOBILITY_COLORS } from './constants';

const LEGEND_ITEMS = [
  CATEGORIES.stiff, CATEGORIES.tight, CATEGORIES.neutral, CATEGORIES.loose, CATEGORIES.hypermobile
];

function MobilityScreenTable({ onAddNew, className, userId }) {
  const [ selectedIdx, setSelectedIdx ] = useState(0);
  const [ displayedError, setDisplayedError ] = useState(null);
  const { 
    mobilityScreens, 
    loading: loadingMobilityScreens, 
    error: mobilityScreensError 
  } = useMobilityScreens(userId);
  const { 
    metricDefinitionsMap, 
    loading: loadingMetricDefinitions, 
    error: metricDefinitionsError 
  } = useMetricDefinitionsMap('mobility');
  const classes = useStyles();

  useEffect(() => {
    if (metricDefinitionsError || mobilityScreensError) {
      setDisplayedError('Error loading mobility screen data. Please try again.');
    } else {
      setDisplayedError(null);
    }
  }, [mobilityScreensError, metricDefinitionsError]);

  function getMobilityCategory(value, rangeKey) {
    const definition = metricDefinitionsMap[`Mobility.${rangeKey}`];
    if (definition == null || definition.ranges == null || value == null) return null;
    for (var idx in definition.ranges) {
      var range = definition.ranges[idx];
      if (value > range.start && value <= range.end) return range.level;
    }
    return null;
  }

  const loading = loadingMobilityScreens || loadingMetricDefinitions;
  const hideData = loading || mobilityScreens == null || mobilityScreens[selectedIdx] == null;

  function renderDataCell(value, rangeKey) {
    const color = MOBILITY_COLORS[getMobilityCategory(value, rangeKey)];
    return <div className={classes.dataCellContents} style={{ '--borderColor': color }}>
      {hideData ? '-' : value}
    </div>;
  }
  
  return <div className={classNames(classes.container, className)}>
    <ErrorSnackbar message={displayedError} onClose={() => setDisplayedError(null)} />
    <Paper className={classes.paper}>
      <div className={classes.selectContainer}>
        {loading 
          ? <CircularProgress size={30} />
          : <StyledSelect 
            value={selectedIdx} 
            onChange={e => setSelectedIdx(e.target.value)}
          >
            {mobilityScreens.map((screen, idx) => <MenuItem value={idx} key={screen.id}>
              {formatMMDDYY(screen.startTimestamp)}
            </MenuItem>)}
            {mobilityScreens.length === 0 && <MenuItem value={0}>-</MenuItem>}
          </StyledSelect>}
      </div>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.moverTypeCell}>
              {hideData ? '-' : <span>{mobilityScreens[selectedIdx].moverType}<br />Mover</span>}
            </TableCell>
            <TableCell className={classes.headerCell}>Left</TableCell>
            <TableCell className={classes.headerCell}>Right</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {TABLE_ROWS.map(row => {
            const leftValue = hideData ? null : mobilityScreens[selectedIdx][row.left];
            const rightValue = hideData ? null : mobilityScreens[selectedIdx][row.right];

            return <TableRow key={row.title}>
              <TableCell className={classes.titleCell}>
                {row.title}
              </TableCell>
              <TableCell className={classes.dataCell}>
                {renderDataCell(leftValue, row.rangeKey)}
              </TableCell>
              <TableCell className={classes.dataCell}>
                {renderDataCell(rightValue, row.rangeKey)}
              </TableCell>
            </TableRow>;
          })}
        </TableBody>
      </Table>
      <div className={classes.legendContainer}>
        <div className={classes.legend}>
          {LEGEND_ITEMS.map((item, idx) => <div key={item}
            className={classNames(classes.legendItem, { 
              [classes.firstLegendItem]: idx === 0, 
              [classes.lastLegendItem]: idx === LEGEND_ITEMS.length - 1 
            })} 
            style={{ '--color': MOBILITY_COLORS[item] }} />)}
        </div>
        <div className={classes.legendLabels}>
          <Typography variant='body2'>{CATEGORIES.stiff}</Typography>
          <Typography variant='body2'>{CATEGORIES.hypermobile}</Typography>
        </div>
        { onAddNew &&
          <Grid container justify='space-evenly' direction='row'>
            <Typography className={classes.addScreenText}>Add New Mobility Data</Typography>
            <Fab 
              size='small' 
              color='primary' 
              aria-label='add' 
              onClick={onAddNew}
            >
              <AddIcon />
            </Fab>
          </Grid>
        }
      </div>
    </Paper>
  </div>;
}

MobilityScreenTable.propTypes = {
  userId: PropTypes.string.isRequired, // user to show mobility data for
  // if provided will display button to create new mobility screens and fire when clicked
  onAddNew: PropTypes.func,
  className: PropTypes.string // class name to apply to the top level component div
};

export default MobilityScreenTable;
