import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  pageContainer: {
    paddingTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  orgUserTitle: {
    marginBottom: '10px'
  },
  orgUserPaper: {
    padding: '20px'
  },
  teams: {
    marginTop: '25px'
  }
}));

export default useStyles;