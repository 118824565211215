import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import {
  Button,
  DialogActions, 
  DialogContent, 
  DialogTitle,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import Logger from 'js-logger';
import CaptureEventsAutocomplete from './captureEventsAutocomplete';
import EventUsersAutocomplete from './eventUsersAutocomplete';
import MotionVideoGridItems from './motionVideoGridItems';
import useEventMotionRequest from './../useEventMotionsRequest';
import useMotionsWithVideos from '../useMotionsWithVideos';
import ProgressButton from '../../../../../components/progressButton';
import { MigrateMotions, PatchEventUser } from '../../../../../network/captureEventRequests';
import { ACTION_STATUSES } from '../../../../../constants/captureEvents.constants';
import useStyles from './styles';
const PG_URL = 'https://www.perfectgame.org';
const PG_EVENT_ROSTER_URL = `${PG_URL}/Events/Rosters.aspx?event=`;
const PG_PLAYER_PROFILE_URL = `${PG_URL}/Players/Playerprofile.aspx?ID=`;

function EventUserMotionsAssignmentDialog({ 
  eventUser,
  onClose
}) {
  const classes = useStyles();
  const { userId, captureEventId, user, motionsAssignedStatus } = eventUser;
  const showWarning = motionsAssignedStatus === ACTION_STATUSES.needsReview;
  const [initialMotions] 
  = useEventMotionRequest(captureEventId, userId);
  const [motions, setMotions] = useState([]);

  const { items: captureEventsMap } = useSelector(state => state.captureEvents);
  const captureEvents = Object.values(captureEventsMap)
    .map(({ item }) => item)
    .filter(x => x != null);
  const currentEvent = captureEventsMap[eventUser.captureEventId].item;

  const [
    selectedEvent, 
    setSelectedEvent
  ] = useState(captureEventsMap[eventUser.captureEventId]?.item);
  const [selectedEventUser, setSelectedEventUser] = useState(null);
  const [selectedMotionIds, setSelectedMotionIds] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submittingErrorMsg, setSubmittingErrorMsg] = useState(null);

  useEffect(() => {
    setMotions(sortBy(initialMotions, x => x.timestamp).reverse());
  }, [initialMotions]);

  const selectedEventId = selectedEvent?.id;
  const selectedUserId = selectedEventUser?.userId;

  useEffect(() => { setSelectedEventUser(null); }, [selectedEventId]);

  let [motionsWithVideos, loading, error] = useMotionsWithVideos(eventUser.userId, motions);
  const migrateDisabled = selectedEventId == null 
    || selectedUserId == null 
    || selectedMotionIds.length === 0;

  const onMigrateSubmit = async () => {
    if (migrateDisabled) return;

    try {
      setSubmitting(true);
      await MigrateMotions(selectedEventId, selectedUserId, selectedMotionIds);
      onClose();
    } catch (e) {
      setSubmittingErrorMsg('Error migrating motions, please try again.');
      Logger.error(e, `Error migrating motions for event ${selectedEventId} ` +
        `with user ${selectedUserId} and motions ${JSON.stringify(selectedMotionIds)}.`);
    }
    setSubmitting(false);
  };

  const confirmMotionsBelongToCurrentPlayer = async () => {
    setSubmitting(true);
    try {
      await PatchEventUser(
        eventUser.captureEventId, 
        eventUser.id,
        [
          { 
            op: 'replace', 
            path: '/motionsAssignedStatus', 
            value: ACTION_STATUSES.completed
          }
        ]
      );
    } catch(e) {
      setSubmittingErrorMsg('Error verifying motions belong to this user.');
      Logger.error(e, `Error updating motionsAssignedStatus  ${selectedEventId} ` +
      `with user ${selectedUserId} and motions ${JSON.stringify(selectedMotionIds)}.`);
    }
    setSubmitting(false);
    onClose();
  };

  const selectedUserExternalId = selectedEventUser?.user?.organizationUserProperties?.externalId;
  return <>
    {showWarning && 
    <DialogContent>
      Motions flagged for being far apart. Please verify: 
      <Button 
        className={classes.buttonBase}
        onClick={confirmMotionsBelongToCurrentPlayer}>
        Yes, All Motions Belong to 1 player
      </Button>
      or
    </DialogContent>
    }
    <DialogTitle> Migrate Motions from "{user?.fullName}" to: </DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CaptureEventsAutocomplete 
            captureEvents={captureEvents}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            currentEvent={currentEvent}
          />

          {selectedEvent?.externalId != null && <Link 
            href={PG_EVENT_ROSTER_URL + selectedEvent.externalId} 
            target='_blank' 
            rel='noopener noreferrer'
          >
            View Event Roster
          </Link>}
        </Grid>
        <Grid item xs={6}>
          <EventUsersAutocomplete 
            captureEventId={selectedEvent?.id}
            selectedEventUser={selectedEventUser}
            setSelectedEventUser={setSelectedEventUser}
          />
            
          {selectedUserExternalId != null && <Link 
            href={PG_PLAYER_PROFILE_URL + selectedUserExternalId} 
            target='_blank' 
            rel='noopener noreferrer'
          >
            View Player Profile
          </Link>}
        </Grid>
      </Grid>
        
      <MotionVideoGridItems 
        motions={motionsWithVideos}
        motionsLoading={loading}
        motionsError={error}
        selectedMotionIds={selectedMotionIds}
        onSelectedMotionIdsChange={setSelectedMotionIds}
      />
    </DialogContent>

    <DialogActions>
      <Typography color='error'>{submittingErrorMsg}</Typography>
      <Button onClick={onClose} disabled={submitting}>
        Cancel
      </Button>
      <ProgressButton
        onClick={onMigrateSubmit}
        color='primary'
        showProgress={submitting}
        disabled={migrateDisabled}
      >
        Start Transfer
      </ProgressButton>
    </DialogActions>
  </>;
}

export default EventUserMotionsAssignmentDialog;
