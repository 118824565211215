import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';

const IN_PER_FT = 12;

function FeetInchesSelect({ inches, onChange, maxFeet, ...props }) {
  const classes = useStyles();
  const feet = inches === '' ? '' : Math.floor(inches / IN_PER_FT);
  const remainingInches = inches === '' ? '' : inches % IN_PER_FT;
  
  const onSelectionChange = isFeet => e => {
    const { value } = e.target;
    if (value === '') {
      onChange('');
    } else {
      const newValue = isFeet 
        ? (IN_PER_FT * value) + remainingInches
        : (IN_PER_FT * feet) + value;
      onChange(newValue.toString());
    }
  };

  return <Grid container spacing={2}>
    <Grid item xs>
      <Select value={feet} 
        onChange={onSelectionChange(true)} 
        className={classes.select}
        {...props}
      >
        <MenuItem value=''></MenuItem>
        {range(maxFeet + 1).map(val => <MenuItem value={val} key={val}>
          <div className={classes.labelContainer}>
            <Typography>{val}</Typography>
            <Typography className={classes.units}>ft</Typography>
          </div>
        </MenuItem>)}
      </Select>
    </Grid>
    <Grid item xs>
      <Select value={remainingInches} 
        onChange={onSelectionChange(false)} 
        className={classes.select}
        {...props}
      >
        <MenuItem value=''></MenuItem>
        {range(IN_PER_FT).map(val => <MenuItem value={val} key={val}>
          <div className={classes.labelContainer}>
            <Typography>{val}</Typography>
            <Typography className={classes.units}>in</Typography>
          </div>
        </MenuItem>)}
      </Select>
    </Grid>
  </Grid>;
};

FeetInchesSelect.defaultProps = {
  maxFeet: 7
};

FeetInchesSelect.propTypes = {
  inches: PropTypes.string,
  onChange: PropTypes.func.isRequired, // called with the new inches value as a string
  maxFeet: PropTypes.number, // max number of feet to display as an option
  props: PropTypes.object // props to be passed down to individual select components
};

export default FeetInchesSelect;
