import React, { useState, useMemo } from 'react';
import useStyles from './styles';
import classNames from 'classnames';
import { ListItem, IconButton } from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/Forward';
import EditIcon from '@material-ui/icons/Edit';

const MAX_CHARACTER_LENGTH = 125; 

function TemplateItem({ 
  data,
  onClick = () => {},
  onEditClick = () => {}
}) {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  const { title, text = '', noteTemplateVideos } = data;

  const videoListText = useMemo(() => {
    if (!noteTemplateVideos || !noteTemplateVideos.length) {
      return 'No Videos Attached';
    }
    return 'Drill Videos: ' + noteTemplateVideos.map(v => v.video.title).join(', ');
  }, [noteTemplateVideos]);

  const shortenedText = useMemo(() => {
    if (text.length <= MAX_CHARACTER_LENGTH) {
      return text;
    }

    let endIdx = text.indexOf(' ', MAX_CHARACTER_LENGTH);
    if (endIdx === null) {
      endIdx = text.length;
    }

    return text.substring(0, endIdx) + '...';
  }, [text]);
  
  return <ListItem className={classes.listItem}
    onClick={() => onClick(data)} 
    onMouseEnter={() => setHovered(true)} 
    onMouseLeave={() => setHovered(false)}
  >
    <div className={classes.container} onClick={() => onClick(data)} 
      onMouseEnter={() => setHovered(true)} 
      onMouseLeave={() => setHovered(false)}>
      <div className={classes.header}>
        <div className={classes.title}>{title}</div>
        <ForwardIcon />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.text}>{shortenedText}</div>
        <div className={classNames({ [classes.hidden]: !hovered })}>
          <IconButton color='primary' onClick={e => { e.stopPropagation(); onEditClick(data); }}>
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.drills}>{videoListText}</div>
    </div>
  </ListItem>;
};

export default TemplateItem;