import { makeStyles } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import palette from '../../../../../styles/palette';

export default makeStyles(() => ({
  dialog: {
    minWidth: '95vw',
    minHeight: '95vh'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > span': {
      fontSize: '20px'
    }
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative'
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  loading: {
    width: '100%',
    height: '100%',
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  topLeftInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '18px'
  },
  exitButton: {
    marginRight: '15px'
  },
  deleted: {
    fontSize: '14px',
    color: palette.error,
    marginBottom: '10px'
  },
  dataContainer: {
    paddingLeft: '10px'
  },
  exitVeloContents: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  exitVeloContainer: {
    paddingTop: '20px'
  },
  input: {
    margin: '0 10px'
  },
  exitVeloText: {
    fontSize: 'larger',
    paddingLeft: '3px'
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  visuals: {
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px'
  },
  previousButton: {
    marginRight: '10px'
  },
  resultsMsg: {
    padding: '0 10px',
    color: palette.success
  },
  badMotionLabel: {
    margin: '20px'
  },
  warningIcon: {
    color: palette.red,
    marginLeft: '100px'
  },
  deleteVelo: {
    marginLeft: '10px'
  },
  attributeChip: {
    margin: '1px 2px',
    backgroundColor: amber[100]
  },
  migrateButtonContainer: {
    position: 'absolute',
    bottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.white
  },
  dialogContent: {
    position: 'relative'
  }
}));