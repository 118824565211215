import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import classnames from 'classnames';
import '../index.css';

import { useStyles, HeaderTableCell, CustomTableRow, BodyTableCell } from '../styles';
import { InningsTableCell } from './styles';

const InningsTable = ({ 
  leaderboard,
  scores, 
  curDay 
}) => {
  const classes = useStyles();

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <CustomTableRow>
              <BodyTableCell></BodyTableCell>
              {Array.from({ length: leaderboard.numberOfIntervals }, (_, i) => (
                <HeaderTableCell key={i}>
                  {i + 1 === curDay
                    ? <div className={classes.selectedCell}>{i + 1}</div>
                    : <div>{i + 1}</div>
                  }
                </HeaderTableCell>
              ))}
            </CustomTableRow>
          </TableHead>
          {scores &&
            <TableBody>
              {scores.map((player) => (
                <CustomTableRow 
                  className={classnames({ [classes.selectedRow]: player.selected })} 
                  key={player.userId}>
                  <BodyTableCell></BodyTableCell>
                  {player.innings.map((score, i) => (
                    <InningsTableCell 
                      className={classnames(classes.cell, { [classes.selected]: player.selected })} 
                      key={i}>
                      {i + 1 <= curDay 
                        ? <div className={classes.numberBackground}>{score}</div>
                        : <div className={classes.numberBackground}>&nbsp;</div>
                      }
                    </InningsTableCell>
                  ))}
                </CustomTableRow>
              ))}
            </TableBody>
          }
        </Table>
      </TableContainer>
    </div>
  );
};

export default InningsTable;