import React, { useState } from 'react';
import useStyles from './styles';

import { Paper } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

function KeyFrameDropdown({
  title = '',
  children
}) {
  const [hovered, setHovered] = useState(false);

  const classes = useStyles();
  return (
    <Paper
      elevation={1}
      className={classes.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <div className={classes.dropUp}>
        <div className={classes.title}>{title}</div>
        <ArrowDropUpIcon className={classes.icon}/>
      </div>

      {
        hovered &&
        <Paper elevation={3} className={classes.menu}>
          { children }
        </Paper>
      }
    </Paper>);
}

export default KeyFrameDropdown;

