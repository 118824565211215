import React from 'react';
import { PURCHASE_STATUSES } from 'constants/captureEvents.constants';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useStyles from './styles';
import { Tooltip } from '@material-ui/core';

function PurchaseStatusIcon({ purchaseStatus }) {
  const classes = useStyles();
  switch(purchaseStatus) {
    case PURCHASE_STATUSES.notPurchased:
      return null;
    case PURCHASE_STATUSES.purchased:
      return <Tooltip title={'Purchased. Needs Delivery.'} aria-label={'Purchased'}>
        <VisibilityIcon className={classes.reviewIcon} />
      </Tooltip>;
    case PURCHASE_STATUSES.created:
      return <Tooltip title={'Plan Delivered'} aria-label={'Plan Delivered'}>
        <CheckCircleIcon className={classes.successIcon} />
      </Tooltip>;
    default:
      return null;
  };
};

export default PurchaseStatusIcon;