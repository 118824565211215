import Network from './network';

const URL = 'Api/Purchases';

export async function CreateStripePurchase(
  userId
  , productId
  , purchasePlatformSku
  , paymentMethodId
  , last4DigitsOfCard
  , promoCode
) {

  const resp = await Network.Post(URL, { 
    userId,
    paymentMethodId,
    last4DigitsOfCard,
    purchasingPlatform: 'Stripe',
    promoCode,
    purchaseItems: [
      { productId, purchasePlatformSku }
    ]
  });
  return resp.data;
};

export async function ChangeSubscription(
  userId
  , productId
  , purchasePlatformSku
  , paymentMethodId
  , subscriptionId
  , subscriptionIndex
  , cancelToken
) {
  const operations = [
    {
      'op': 'replace',
      'path': `/purchaseItems/${subscriptionIndex}/status`,
      'value': 'UpdateRequested'
    }, 
    {
      'op': 'add',
      'path': '/purchaseItems/-',
      'value': {
        'productId' : Number(productId),
        paymentMethodId,
        purchasePlatformSku,
        'status': 'AwaitingUpdate'
      }
    }
  ];

  const resp = await Network.Patch(URL + '/' + subscriptionId, operations, cancelToken);
  return resp.data;
};


export async function UpgradeSubscription(
  userId
  , productId
  , purchasePlatformSku
  , paymentMethodId
  , subscriptionId
  , subscriptionIndex
  , cancelToken
) {

  const operations = [
    {
      'op': 'replace',
      'path': `/purchaseItems/${subscriptionIndex}/status`,
      'value': 'Canceled'
    }, 
    {
      'op': 'add',
      'path': '/purchaseItems/-',
      'value': {
        'productId' : Number(productId),
        paymentMethodId,
        purchasePlatformSku,
        'status': 'Active'
      }
    }, 
    {
      'op': 'replace',
      'path': '/paymentMethodId',
      'value': paymentMethodId
    }
  ];

  const resp = await Network.Patch(URL + '/' + subscriptionId, operations, cancelToken);
  return resp.data;

};

export async function GetPurchases(userId, cancelToken) {
  const resp = await Network.Get(URL, { 
    userId
  }, cancelToken);
  return resp.data.items;
}

export async function CancelSubscription(subscriptionId, subscriptionIndex, cancelToken) {
  const operations = [
    {
      'op': 'replace',
      'path': `/purchaseItems/${subscriptionIndex}/status`,
      'value': 'CancellationRequested'
    }
  ];
  const resp = await Network.Patch(URL + '/' + subscriptionId, operations, cancelToken);
  return resp.data;
}

export async function UndoFutureSubscriptionChanges(subscriptionId, itemIdx) {
  const operations = [
    {
      op: 'replace',
      path: `/purchaseItems/${itemIdx}/status`,
      value: 'Active'
    }
  ];
  const resp = await Network.Patch(URL + '/' + subscriptionId, operations);
  return resp.data;
}

export async function GetProducts(userId, promoCode, cancelToken) {
  const resp = await Network.Get(URL + '/Products', { 
    userId,
    promoCode
  }, cancelToken);
  return resp.data.items;
};

