import React from 'react';
import PropTypes from 'prop-types';
import { 
  FormControl,
  FilledInput,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';

import DateRangeSelector from '../dateRangeSelector';

import { withStyles } from '@material-ui/core/styles';
import Styles from './styles';

class SelectorWithDate extends React.Component {
  render() {
    const {
      name,
      classes,
      value,
      dropdownValues,
      fromDate,
      toDate,
      handleDateChange,
      submitDateRange,
      handleFilterChange
    } = this.props;


    return (
      <div className={classes.centerItems}>
        <DateRangeSelector
          hidden={value !== 'date'}
          fromDate={fromDate}
          toDate={toDate}
          handleDateChange={handleDateChange}
          submitDateRange={submitDateRange}
        />
        <FormControl variant='filled' className={classes.paddingLeft} >
          <InputLabel htmlFor={name}>Filter By</InputLabel>
          <Select
            onChange={handleFilterChange} 
            value={value}
            input={<FilledInput name={name} id={name} />}
          >
            <MenuItem value='date'>Date</MenuItem>
            {dropdownValues.map((item, idx) => 
              <MenuItem key={idx} value={item.value}>{item.displayName}</MenuItem> 
            )}
          </Select>
        </FormControl>
      </div>
    );
  }
}

SelectorWithDate.propTypes = {
  name: PropTypes.string.isRequired, //Used to set the ID of the input so that the label can be connected to it
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  dropdownValues: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    displayName: PropTypes.string
  })).isRequired,
  fromDate: PropTypes.object.isRequired,
  toDate: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  submitDateRange: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired
};

export default withStyles(Styles)(SelectorWithDate);