import React from 'react';
import useStyles from './styles';

import { Paper } from '@material-ui/core';

function ButtonContainer({ 
  children, 
  onClick = () => {} 
}) {
  const classes = useStyles();
  return (
    <Paper elevation={1}>
      <div className={classes.container} onClick={onClick}>
        {children}
      </div>
    </Paper>
  );
}

export default ButtonContainer;