import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  CircularProgress
} from '@material-ui/core';

class ProgressButton extends React.Component {
  renderInnerHtml(showProgress, children, progressColor) {
    if (showProgress) {
      return (
        <CircularProgress size={20} style={{ color: progressColor }}/>
      );
    }
    return children;
  }

  render() {
    const { showProgress, disabled, children, progressColor, ...rest } = this.props;
    return (
      <Button disabled={showProgress || disabled}
        {...rest}>
        {this.renderInnerHtml(showProgress, children, progressColor)}
      </Button>
    );
  }
}

ProgressButton.propTypes = {
  showProgress: PropTypes.bool,
  onClick: PropTypes.func, // Should be provided unless the button type is submit
  progressColor: PropTypes.string
};

export default ProgressButton;