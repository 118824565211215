import React from 'react';
import PropTypes from 'prop-types';
import { VictoryChart, VictoryScatter, VictoryTheme, VictoryLabel } from 'victory';
import Pallete from '../../styles/palette';

function TimeSyncStatsGraph({
  title,     // the title to display at the top of graph
  offsetList // the offsetList for a single sensor
}) {

  let graphData = offsetList.map((offset, idx) => ({ x: idx, y: offset }));

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      height={190}
      width={190}
      padding={{ left: 50, top: 50, right: 10, bottom: 10 }}
    >
      <VictoryLabel text={title} x={100} y={15} />
      <VictoryScatter
        style={{ data: { fill: Pallete.primaryRed }}}
        data={graphData}
      />
    </VictoryChart>
  );
};

TimeSyncStatsGraph.propTypes = {
  title: PropTypes.string,
  offsetList: PropTypes.arrayOf(PropTypes.number)
};

export default TimeSyncStatsGraph;