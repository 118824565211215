import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  completeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  createContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end'
  },
  dropdown: {
    marginRight: '15px'
  },
  planLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: '5px'
  },
  editTrainingPlanContainer: {
    width: '100%'
  },
  trainingPlanName: {
    margin: '10px'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

export default useStyles;
