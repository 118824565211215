import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import logger from 'js-logger';
import PrivateRoute from '../../auth/privateRoute';
import Login from '../login';
import CreateAccount from '../createAccount';
import Forbidden from '../forbidden';
import ClientActivationRequests from '../clientActivationRequests';
import Header from '../../components/header';
import Licenses from '../licenses';
import Styles from './styles';
import { ALL_ROLES, USER_ROLE, ORG_ROLE, ORG_ADMIN_ROLE } from '../../constants/auth.constants';

import Home from '../home';
import KMotionRedirect from '../kmotionRedirect';
import EulaPage from '../../components/eula/eulaText';
import ReactGA from 'react-ga';
import VimeoVideo from '../vimeoVideoPlayer';
import SetPassword from '../setPassword';
import KGoRedirect from '../kgoRedirect';
import ApiDocs from '../apiDocs';
import PlayerDevelopmentDashboard from '../playerDevelopmentDashboard';
import { setFeaturePermissionsAsync } from '../../utils/auth.utils';
import { setLoggingContext } from '../../utils/logging.utils';
import { PERMISSIONS } from '../../constants/permissions.constants';
import VerifyEmail from '../verifyEmail';
import Leaderboard from '../leaderboards';
import Purchase from '../purchase';
import PurchaseForm from '../purchase/paymentForm';
import PublicAccountCreation from '../publicAccountCreation';
import PurchaseHeader from '../../components/purchaseHeader';
import CaptureEventsHome from '../captureEvents/captureEventsHome';
import DataAnalysis from '../dataAnalysis';
import AllTrainingPlans from 'pages/allTrainingPlans';
import TrainingPlanById from 'pages/trainingPlanById';
import ROUTE_PATHS from 'constants/routePaths.constants';
import TrainingPlanManagement from 'pages/trainingPlanManagement';
import IframeHack from 'pages/iframeHack';
import TrainingPlanTemplates from 'pages/trainingPlanTemplates';
import SignUpForCaptureEvent from 'pages/signUpForCaptureEvent';

class App extends React.Component {
  state = {
    permissionsLoaded: false
  } 

  async componentDidMount() {
    ReactGA.pageview(window.location.href);
    logger.useDefaults();
    setLoggingContext('stage', process.env.REACT_APP_LOGGING_STAGE);
    await setFeaturePermissionsAsync();
    this.setState({ permissionsLoaded: true });
  }

  getHeader = () => {
    const props = this.props;
    if(props.location.pathname.indexOf('/vimeoVideo') >= 0 || 
      props.location.pathname.indexOf('/kgo-redirect') >= 0 ||
      props.location.pathname.indexOf('/licenses') >= 0 ||
      props.location.pathname.indexOf('/end-user-license-agreement') >= 0 ||
      props.location.pathname.indexOf('/player-development') >= 0 ||
      props.location.pathname.indexOf('/leaderboards') >= 0 ||
      props.location.pathname.indexOf('/api-docs') >= 0 ||
      props.location.pathname.indexOf('/content-feed-management') >= 0 ||
      props.location.pathname.indexOf('/capture-events') >= 0 ||
      props.location.pathname.indexOf('/data-analysis') >= 0 ||
      props.location.pathname.indexOf('/training-plans') >= 0 ||
      props.location.pathname.indexOf('/iframe') >= 0 ||
      props.location.pathname.indexOf('/all-training-plans') >= 0 ||
      props.location.pathname.indexOf('/signup-for-capture') >= 0 
    ) {
      return null;
    }

    if (props.location.pathname.indexOf('/purchase') >= 0 ||
      props.location.pathname.indexOf('/public-account-creation') >= 0) {
      return <PurchaseHeader />;
    }

    return props.location.pathname.indexOf('/eval/') >= 0 ?
      <div style={{ paddingTop: '50px' }}>
        <Header pathname={props.location.pathname} /> </div> : 
      <Header pathname={props.location.pathname} />;
  }

  getHeaderStyles = () => {
    const props = this.props;
    return props.location.pathname.indexOf('/eval/') >= 0 ||
    props.location.pathname.indexOf('/vimeoVideo') >= 0 ||
    props.location.pathname.indexOf('/player-development') >= 0 ||
    props.location.pathname.indexOf('/progress-dashboard') >= 0 ||
    props.location.pathname.indexOf('/leaderboards') >= 0 ||
    props.location.pathname.indexOf('/public-account-creation') >= 0 ||
    props.location.pathname.indexOf('/purchase') >= 0 ||
    props.location.pathname.indexOf('/api-docs') >= 0 ||
    props.location.pathname.indexOf('/content-feed-management') >= 0 ||
    props.location.pathname.indexOf('/capture-events') >= 0 ||
    props.location.pathname.indexOf('/data-analysis') >= 0 ||
    props.location.pathname.indexOf('/all-training-plans') >= 0 ||
    props.location.pathname.indexOf('/iframe') >= 0 ||
    props.location.pathname.indexOf('/training-plans') >= 0 ||
    props.location.pathname.indexOf('/signup-for-capture') >= 0 
      ? null : Styles.mainContainer;
  }

  render () {
    return !this.state.permissionsLoaded 
      ? <div></div>
      : <div>
        {this.getHeader()}
        <main style={this.getHeaderStyles()}>
          <Switch>            
            <Route exact path='/vimeoVideo' component={VimeoVideo} />
            <Route exact path='/kcoach-landing' component={KMotionRedirect} />
            <Route exact path='/kcoach-landing/golf' component={KMotionRedirect} />
            <Route exact path='/kcoach-landing/baseball' component={KMotionRedirect} />
            <Route exact path='/kgo-redirect' component={KGoRedirect} />

            <Route exact path='/login' component={Login} />
            <Route exact path='/register/:token' component={CreateAccount}/>
            <Route exact path='/signup-for-capture/:eventId/:eventName' component={SignUpForCaptureEvent}/>
            <Route exact path='/signup-for-capture/:eventId' component={SignUpForCaptureEvent}/>
            <Route exact path='/verify-email/:token' component={VerifyEmail}/>
            <Route exact path='/set-password/:token' component={SetPassword} />
            <Route exact path='/licenses' component={Licenses} />
            <Route exact path='/end-user-license-agreement' component={EulaPage} />

            <Route exact path='/leaderboards' component={Leaderboard}/>
            <PrivateRoute exact roles={[USER_ROLE]} path='/purchase' component={Purchase}/>
            <PrivateRoute exact roles={[USER_ROLE]} path='/purchase/:productId' component={PurchaseForm}/>
            <PrivateRoute exact 
              roles={[USER_ROLE, ORG_ADMIN_ROLE, ORG_ROLE]} 
              path={ROUTE_PATHS.TRAINING_PLAN_BY_ID} 
              component={TrainingPlanById}/>

            <Route exact path='/public-account-creation' component={PublicAccountCreation} />

            <PrivateRoute exact roles={[ALL_ROLES]} path='/' component={Home}/>
            <PrivateRoute exact roles={[ALL_ROLES]} path='/forbidden' component={Forbidden} />

            {/* Player Development Dashboard Base */}
            
            {/* 
                Need to include this one outside of the PlayerDevelopmentDashboard as it 
                removes the sidebar and styling of the normal dashboard 
            */}
            <PrivateRoute roles={[USER_ROLE, ORG_ADMIN_ROLE, ORG_ROLE]}
              exact
              path='/player-development/training-plans'
              component={TrainingPlanManagement} />

            <PrivateRoute roles={[USER_ROLE, ORG_ADMIN_ROLE, ORG_ROLE]}
              exact
              path='/player-development/training-plan-templates'
              component={TrainingPlanTemplates} />

            <PrivateRoute roles={[ORG_ROLE]} path='/player-development' component={PlayerDevelopmentDashboard} />
            <PrivateRoute exact 
              roles={[ORG_ROLE]}
              featurePermission={PERMISSIONS.apiAvailable} 
              path='/api-docs' 
              component={ApiDocs} />

            <PrivateRoute roles={[ORG_ADMIN_ROLE, ORG_ROLE]} 
              path='/capture-events'
              component={CaptureEventsHome} />

            <PrivateRoute roles={[ORG_ADMIN_ROLE, ORG_ROLE]} 
              path='/data-analysis'
              component={DataAnalysis} />

            <PrivateRoute roles={[USER_ROLE, ORG_ADMIN_ROLE, ORG_ROLE]}
              path='/all-training-plans'
              component={AllTrainingPlans} />
              
            <Route 
              path='/iframe'
              component={IframeHack} />

            {/* User only routes */}
            <PrivateRoute exact roles={[USER_ROLE]} path='/client-activation' component={ClientActivationRequests} />
          </Switch>
        </main>
      </div>;
  }
}


export default withRouter(App);
