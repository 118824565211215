import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

function useCurrentPlayerName(defaultName = 'this player') {
  const [name, setName] = useState(defaultName);
  const currentPlayer = useSelector(state => state.currentPlayer);
  useEffect(() => {
    if (currentPlayer != null) {
      let fullName = [currentPlayer.firstName, currentPlayer.lastName]
        .filter(x => x != null)
        .join(' ');
      if (fullName != null && fullName.trim() !== '') {
        setName(fullName);
      }
    }
  }, [currentPlayer]);

  return name;
};

export default useCurrentPlayerName;
