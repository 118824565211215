import { useEffect, useState } from 'react';
import axios from 'axios';
import { GetMotion } from '../../../../network/motionRequests';
import { FULL_MOTION_METRICS } from '../../../../constants/motionSubresources.constants';

export default function useUpdatedFullMotionMetrics(id) {
  const [ fullMotionMetrics, setFullMotionMetrics ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const NUM_RETRIES = 3;
  const RETRY_DELAY_MS = 2000;

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const retryIntervalIds = [];

    // Keep track of intervals to ensure that all are cleared on unmount
    function fetchDataRetry(retries) {
      retryIntervalIds.push(setInterval(() => fetchData(retries), RETRY_DELAY_MS));
    }

    async function fetchData(retries = NUM_RETRIES) {
      setLoading(true);
      try {
        const motion = await GetMotion(id, { subresources: [ FULL_MOTION_METRICS ] }, cancelToken);
        if (motion?.fullMotionMetrics?.maxSpeedPelvis == null) {
          if (retries > 1) {
            fetchDataRetry(retries - 1);
          }
          else {
            const secondsElapsed = NUM_RETRIES * RETRY_DELAY_MS / 1000;
            setError(`Full motion metrics not updated after ${secondsElapsed} seconds.`);
          }
        } else {
          setFullMotionMetrics(motion.fullMotionMetrics);
        }
      } catch (e) {
        if (axios.isCancel(e)) return;
        if (retries > 1) {
          fetchDataRetry(retries - 1);
          return;
        }
        setError(e);
      }
      setLoading(false);
    };
    fetchData();

    return function() {
      cancelToken.cancel();
      retryIntervalIds.forEach(id => id != null && clearInterval(id));
    };
  }, [id]);

  return { fullMotionMetrics, loading, error };
}
