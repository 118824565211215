import { useEffect, useState } from 'react';
import EXIT_VELO_PERCENTILES_BY_CLASS from './ExitVelocityClassPercentiles.json';
import EXIT_VELO_PERCENTILES_BY_CLASS_SOFTBALL from './ExitVelocityClassPercentilesSoftball.json';

export const useProjectedPercentiles = (playerPercentiles) => {
  const defaultEfficiency = playerPercentiles?.bodySpeed ?? 0;
  const [sliderEfficiency, setSliderEfficiency] = useState(defaultEfficiency);
  const [projectedPercentiles, setProjectedPercentiles] = useState(playerPercentiles);

  useEffect(() => {
    setSliderEfficiency(defaultEfficiency);
  }, [defaultEfficiency, playerPercentiles]);

  useEffect(() => {
    const efficiencyDiff = sliderEfficiency - defaultEfficiency;
    const denominator = efficiencyDiff > 0 ? 100 - defaultEfficiency : defaultEfficiency;
    let percentageChange = 0;
    if (denominator !== 0) {
      percentageChange = efficiencyDiff / denominator;
    }

    const projectValue = (value) => {
      if (value == null) {
        return 0;
      }

      const x = percentageChange > 0 ? 100 - value : value;
      const projectedValue = Math.round(value + (x * percentageChange));
      return projectedValue;
    };
    
    setProjectedPercentiles({
      ...playerPercentiles,
      bodySpeed: sliderEfficiency,
      ballSpeed: playerPercentiles?.ballSpeed ?? 0,
      pelvisSpeed: projectValue(playerPercentiles?.pelvisSpeed),
      torsoSpeed: projectValue(playerPercentiles?.torsoSpeed),
      upperArmSpeed: projectValue(playerPercentiles?.upperArmSpeed),
      handSpeed: projectValue(playerPercentiles?.handSpeed)
    });
  }, [defaultEfficiency, sliderEfficiency, playerPercentiles]);

  return { 
    sliderEfficiency, 
    setSliderEfficiency, 
    projectedPercentiles,
    initialEfficiency: defaultEfficiency
  };
};

export const SPORTS = {
  BASEBALL: 'Baseball',
  SOFTBALL: 'Softball'
};

export const CLASS_YEAR_SLOPES = {
  '2030': 0.0910,
  '2029': 0.0920,
  '2028': 0.0930,
  '2027': 0.1031,
  '2026': 0.1097,
  '2025': 0.0872,
  '2024': 0.1435,
  '2023': 0.1506
};

export const CLASS_YEAR_SLOPES_SOFTBALL = {
  '2030': 0.0522,
  '2029': 0.0522,
  '2028': 0.0522,
  '2027': 0.0522,
  '2026': 0.0522,
  '2025': 0.0522,
  '2024': 0.0522,
  '2023': 0.0522
};

export const GetClassYearSlopes = (sport = SPORTS.BASEBALL) => {
  if (sport === SPORTS.SOFTBALL) {
    return CLASS_YEAR_SLOPES_SOFTBALL;
  } else {
    return CLASS_YEAR_SLOPES;
  }
};

const getExitVeloPercentilePerClass = (exitVelo, sport, classYear) => {
  const evMap = sport === SPORTS.BASEBALL
    ? EXIT_VELO_PERCENTILES_BY_CLASS
    : EXIT_VELO_PERCENTILES_BY_CLASS_SOFTBALL;
  const evsForClass = evMap[classYear];
  if(evsForClass == null) {
    return null;
  }
  // Get the raw evs:
  const classEVMin = Math.min(Object.keys(evsForClass).map(x => Number(x)));
  const classEVMax = Math.max(Object.keys(evsForClass).map(x => Number(x)));
  if(exitVelo < classEVMin) return 0;
  if(exitVelo > classEVMax) return 100;
  return Math.round(evsForClass[exitVelo]);
};

export const projectExitVelo = (
  ballspeedMph, 
  swingEfficiencyDelta, 
  classYear, 
  sport
) => {
  const slope = GetClassYearSlopes(sport)[classYear];
  const rawEVDelta = swingEfficiencyDelta * slope;
  const newExitVelo = Math.round(ballspeedMph + rawEVDelta);

  const newPercentilePerClass = 
    getExitVeloPercentilePerClass(newExitVelo, sport, classYear);
  const originalPercentilePerClass = 
    getExitVeloPercentilePerClass(ballspeedMph, sport, classYear);
  const percentilePerClassDelta = newPercentilePerClass - originalPercentilePerClass;

  return {
    newExitVelo,
    rawEVDelta,
    newPercentilePerClass,
    originalPercentilePerClass,
    percentilePerClassDelta
  };
};
