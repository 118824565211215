export const PERMISSIONS = {
  canDownloadMotionCsv: 'CanDownloadMotionCsv',
  canDownloadSummariesCsv: 'CanDownloadSummaryCsv',
  playerDevelopmentDashboard: 'PlayerDevelopmentDashboard',
  newDashboardVisible: 'NewDashboardVisible',
  canInputThirdPartyData: 'CanInputThirdPartyData',
  canHandleLaunchMonitorData: 'CanHandleLaunchMonitorData',
  kGoFeatureSet: 'KGoFeatureSet',
  apiAvailable: 'ApiAvailable',
  internalTooling: 'InternalTooling',
  contentFeedEnabled: 'ContentFeedEnabled',
  markdownEnabled: 'MarkdownEnabled',
  actionPlanChecks: 'ActionPlanChecks',
  subscriptionPromoActive: 'SubscriptionPromoActive'
};

export const TAB_PERMISSIONS = {
  overview: 'DashboardTabVisible:Overview',
  actionPlans: 'DashboardTabVisible:ActionPlans',
  progress: 'DashboardTabVisible:Progress',
  liveCapture: 'DashboardTabVisible:LiveCapture',
  video: 'DashboardTabVisible:Video',
  mobility: 'DashboardTabVisible:Mobility',
  analytics: 'DashboardTabVisible:Analytics',
  swingDetails: 'DashboardTabVisible:SwingDetails',
  drillVideos: 'DashboardTabVisible:DrillVideos',
  accountInfo: 'DashboardTabVisible:AccountInfo',
  kAssessments: 'DashboardTabVisible:KAssessments',
  videoAnalysis: 'DashboardTabVisible:VideoAnalysis'
};
