import { useCallback } from 'react';
import keyBy from 'lodash/keyBy';
import useNetworkRequest from '../../../../network/useNetworkRequest';
import { GetVideos } from '../../../../network/videoRequests';
import { HOSTING_PROVIDER, VIDEO_TYPE } from '../../../../constants/video.constants';

function useMotionsWithVideos(userId, motions) {
  const motionVideosRequest = useCallback(async (cancelToken) => {
    const motionIds = motions.map(x => x.id);
    const videos = await GetVideos({ 
      userId, 
      motionIds, 
      hostingProvider: HOSTING_PROVIDER.azureBlobStorage, 
      type: VIDEO_TYPE.motion
    }, cancelToken);

    const videosByMotionId = keyBy(videos, 'motionId');
    return motions.map(x => ({ ...x, video: videosByMotionId[x.id] }));
  }, [userId, motions]);
  return useNetworkRequest([], 'GetMotionVideos', motionVideosRequest);
}

export default useMotionsWithVideos;
