import Network from './network';
import formatISO from 'date-fns/formatISO';
import { getOrganizationId, getUserId } from '../utils/auth.utils';

const URL = 'API/NoteTemplates';

export async function GetTemplates(organizationId, cancelToken) {
  const resp = await Network.Get(URL, { organizationId }, cancelToken);
  return resp.data.items;
};

export async function CreateNoteTemplate(template) {
  const body = {
    ...template,
    organizationId: getOrganizationId(),
    authorUserId: getUserId(),
    creationTimestamp: formatISO(new Date())
  };
  var resp = await Network.Post(URL, body);
  return resp.data;
};

export async function UpdateNoteTemplate(template) {
  const body = {
    ...template,
    editedTimestamp: formatISO(new Date())
  };
  var resp = await Network.Put(`${URL}/${template.id}`, body);
  return resp.data;
}

export async function DeleteNoteTemplate(id) {
  var resp = await Network.Delete(`${URL}/${id}`);
  return resp.data;
}

