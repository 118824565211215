import { useEffect, useState } from 'react';
import axios from 'axios';
import Logger from 'js-logger';
import { GetMobilityScreens } from '../../../../network/userRequests';

function useMobilityScreens(userId) {
  const [ mobilityScreens, setMobilityScreens ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    let cancelToken = axios.CancelToken.source();
    async function fetchData() {
      setLoading(true);
      try {
        var data = await GetMobilityScreens(userId, cancelToken);
        setMobilityScreens(data);
      } catch (e) {
        if (axios.isCancel(e)) return;
        const err = 'Error getting mobility screen data, please try again.'; 
        Logger.error(err, e);
        setError(err);
      }
      setLoading(false);
    }
    fetchData();

    return () => cancelToken.cancel();
  }, [userId]);

  return { mobilityScreens, loading, error };
}

export default useMobilityScreens;