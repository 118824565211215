import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import EventUserDetails from './eventUserDetails';

function ExpandableUserDetails({ eventUser, expanded, onDeleteUser }) {
  return <Collapse unmountOnExit in={expanded}>
    <EventUserDetails eventUser={eventUser} onDeleteUser={onDeleteUser}/>
  </Collapse>;
}

export default ExpandableUserDetails;