import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import { getUserId, getOrganizationId } from '../../../utils/auth.utils';
import Plan from '../../playerDataTabs/actionPlans/plan';
import TEXT_TYPES from './../../../constants/actionPlanTextTypes.constants';
import CreateContentFeedItemDialog from './createContentFeedItemDialog';
import VideoPreviewDialog from '../../../components/dialogs/videoPreviewDialog';
import SendIcon from '@material-ui/icons/Send';
import ConfirmationDialog from './../../../components/dialogs/confirmationDialog';
import logger from 'js-logger';
import { PostScheduledContentDelivery } from '../../../network/scheduledContentDeliveriesRequests';
import { v4 as uuidv4 } from 'uuid';
import { TRIGGER_TYPES } from '../../../constants/scheduledContentDeliveries.constants';
import ReactMarkdown from 'react-markdown';
import useStyles from './styles';

function ContentFeedItems({
  onContentScheduled,
  loading, 
  error,
  contentFeedItems,
  onSetContentFeedItems
}) {
  const organizationId = getOrganizationId();
  const [creatingItem, setCreatingItem] = useState(false);
  const [previewingVideo, setPreviewingVideo] = useState(null);
  const [contentItem, setContentItem] = useState(null);
  const [scheduling, setScheduling] = useState(false);
  const [postDeliveryError, setPostDeliveryError] = useState(null);
  const [uuid, setUuid] = useState(null);

  const classes = useStyles();

  function onNewItemCreated(item) {
    onSetContentFeedItems(item);
    setCreatingItem(false);
  }

  useEffect(() => {
    setUuid(contentItem ? uuidv4() : null);
  }, [contentItem]);

  async function scheduleContent() {
    if (scheduling) return;
    setPostDeliveryError(null);
    setScheduling(true);
    const delivery = {
      contentFeedItemId: contentItem.id,
      triggerType: TRIGGER_TYPES.MANUAL,
      creatorUserId: getUserId(),
      organizationId,
      idempotencyKey: uuid
    };

    try {
      const newDelivery = await PostScheduledContentDelivery(delivery);
      setScheduling(false);
      onContentScheduled(newDelivery);
      setContentItem(null);
    } catch (e) {
      logger.error('Error posting scheduled content delivery', e);
      setPostDeliveryError('There was an error posting a new content delivery');
      setScheduling(false);
    }
  }

  function cancelled() {
    setContentItem(null);
    setPostDeliveryError(null);
  }

  return <>
    <Button variant='outlined' color='primary' onClick={() => setCreatingItem(true)}>
      Create New Content Feed Item
    </Button>

    <CreateContentFeedItemDialog 
      open={creatingItem}
      onClose={() => setCreatingItem(false)}
      organizationId={organizationId}
      onNewItemCreated={onNewItemCreated}
    />

    <VideoPreviewDialog 
      open={previewingVideo != null}
      onClose={() => setPreviewingVideo(null)}
      video={previewingVideo}
    />

    <ConfirmationDialog 
      open={contentItem != null}
      onCancel={cancelled}
      onConfirm={scheduleContent}
      title='Confirm Sending New Content Item'
      loading={scheduling}
    >
      {
        contentItem &&
        <div className={classes.confirmation}>
          <div className={classes.preview}>
            {
              contentItem.textFormatType === TEXT_TYPES.MARKDOWN 
                ? <ReactMarkdown source={contentItem.text} />
                : contentItem.text
            }
          </div>
          <div>
            { contentItem.contentFeedItemVideos.length } Videos Attached
          </div>
          {
            postDeliveryError &&
            <div>
              <Typography color='error'>
                {postDeliveryError}
              </Typography>
            </div>
          }
        </div>
      }
    </ConfirmationDialog>

    <List>
      {!loading && error && <ListItem>
        <Paper className={classes.paperContainer}>
          <Typography color='error' className={classes.skeleton}>
            There was an error loading content feed items. Refresh to try again.
          </Typography>
        </Paper>
      </ListItem>}
      {loading
        ? Array.from(Array(5).keys()).map(idx => <ListItem key={`feed-item-loading-${idx}`}>
          <Paper className={classes.paperContainer}>
            <Skeleton type='text' className={classes.skeleton} />
          </Paper>
        </ListItem>)
        : contentFeedItems.map(contentFeedItem => <ListItem key={contentFeedItem.id}>
          <div className={classes.contentItem}>
            <Paper className={classes.paperContainer}>
              <Plan
                title={contentFeedItem.title}
                text={contentFeedItem.text} 
                textFormatType={contentFeedItem.textFormatType}
                noteVideos={contentFeedItem.contentFeedItemVideos}
                creationTimestamp={contentFeedItem.creationTimestamp}
                onClickVideo={setPreviewingVideo}
              />
            </Paper>
            <div className={classes.sendContainer}>
              <IconButton className={classes.sendButton} 
                onClick={() => setContentItem(contentFeedItem)}>
                <SendIcon className={classes.send}/>
              </IconButton>
            </div>
          </div>
        </ListItem>)}
    </List>
  </>;
}

export default ContentFeedItems;