import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  visuals: {
    width: '100%',
    marginBottom: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  sections: {
    // space so the action plan doesn't overwrite the labels on the right
    marginBottom: '100px'
  }
}));

export default useStyles;