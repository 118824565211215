import { makeStyles } from '@material-ui/core/styles';
import Palette from 'styles/palette';

export default makeStyles(() => ({
  reviewIcon: {
    color: Palette.warning
  },
  successIcon: {
    color: Palette.success
  }
}));
