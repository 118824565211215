// Actions
export const SET_CURRENT_SWING = 'swings/set';
export const SET_CURRENT_SUMMARY = 'summary/set';
export const SET_CURRENT_MOTION_INFO = 'motion/set';
export const SET_KEY_FRAME_DATA = 'keyframedata/set';

// Reducer
const initialDetails = [];
const initialSummary = {};
const initialMotionInfo = {};
const initialKeyFrameData = [];

export const currentSwingDetails = (state = initialDetails, action) => {
  switch(action.type) {
    case SET_CURRENT_SWING:
      return action.swingDetails;
    default:
      return state;
  }
};

export const currentSwingSummary = (state = initialSummary, action) => {
  switch(action.type) {
    case SET_CURRENT_SUMMARY:
      return action.swingSummary;
    default:
      return state;
  }
};

export const currentMotionInfo = (state = initialMotionInfo, action) => {
  switch(action.type) {
    case SET_CURRENT_MOTION_INFO:
      return action.motionInfo;
    default:
      return state;
  }
};

export const currentKeyFrameData = (state = initialKeyFrameData, action) => {
  switch(action.type) {
    case SET_KEY_FRAME_DATA:
      return action.keyFrameData;
    default:
      return state;
  }
};

// Action Creators
export function setCurrentSwingDetails (swingDetails) {
  return { type: SET_CURRENT_SWING, swingDetails };
}

export const setCurrentSwingSummary = (swingSummary) => {
  return { type: SET_CURRENT_SUMMARY, swingSummary };
};

export const setCurrentMotionInfo = (motionInfo) => {
  return { type: SET_CURRENT_MOTION_INFO, motionInfo };
};

export const setKeyFrameData = (keyFrameData) => {
  return { type: SET_KEY_FRAME_DATA, keyFrameData };
};
