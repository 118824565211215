// Actions
const SET_NOTES = 'kdashboard/notes/set';
const ADD_NOTE = 'kdashboard/notes/add';
const ADD_NOTES = 'kdashboard/notes/addMultiple';
const UPDATE_NOTE = 'kdashboard/notes/update';
const SET_LOADING_NOTES = 'kdashboard/notes/setLoading';
const SET_ERROR = 'kdashboard/notes/setError';

// Reducer
const initialState = { loading: false, error: false, data: [] };
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NOTES:
      return { ...initialState, data: action.notes };
    case ADD_NOTE:
      return { ...initialState, data: [action.note, ...state.data] };
    case ADD_NOTES:
      return { ...initialState, data: [...action.notes, ...state.data] };
    case UPDATE_NOTE:
      return { 
        ...initialState, 
        data: state.data.map(note => note.id === action.note.id ? action.note : note) 
      };
    case SET_LOADING_NOTES:
      return { ...state, error: false, loading: action.payload };
    case SET_ERROR:
      return { ...state, loading: false, error: action.payload };
    default: 
      return state;
  }
}

// Action Creators
export function setNotes(notes) {
  return { type: SET_NOTES, notes };
}

export function addNote(note) {
  return { type: ADD_NOTE, note };
}

export function addNotes(notes) {
  return { type: ADD_NOTES, notes };
}

export function updateNote(note) {
  return { type: UPDATE_NOTE, note };
}

export function setLoadingNotes(loading = true) {
  return { type: SET_LOADING_NOTES, payload: loading };
}

export function setNotesError(error = true) {
  return { type: SET_ERROR, payload: error };
}