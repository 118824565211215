import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Link
} from '@material-ui/core';
import AppStoreIcon from '../../../assets/apple-store.png';
import PlayStoreIcon from '../../../assets/google-play.png';
import KGoAppStoreIcon from '../../../assets/KGoAppStoreIcon.png';
import useTrackingServices, { EVENT_NAMES } from '../../../services/useTrackingServices';
import useStyles from './styles';

function HowToGetStarted() {
  const classes = useStyles();
  const tracking = useTrackingServices();
  
  const onAppStoreClick = platform => {
    tracking.track(EVENT_NAMES.appStoreClicked, { Platform: platform });
  };

  return (
    <div>
      <Typography variant='h6'>
        To get started follow these steps:
      </Typography>
      <List>
        <ListItem>
          <Grid container direction='column'>
            <Grid item container>
              <div className={classes.firstStep}>
                <ListItemText className={classes.downloadText}>
                  1. Download the KGo app
                </ListItemText>
                <img src={KGoAppStoreIcon} alt='' className={classes.img} />
              </div>
            </Grid>
            <Grid item container alignItems='center' spacing={2} className={classes.storesContainer}>
              <Grid item xs={6} lg={4}>
                <Link href='https://apps.apple.com/us/app/kgo/id1462670999' 
                  target='_blank'
                  rel='noopener'
                  onClick={() => onAppStoreClick('iOS')}
                  className={classes.storeIcon}
                >
                  <img src={AppStoreIcon} alt='apple store' className={classes.storeImg}/>
                </Link>
              </Grid>
              <Grid item xs={6} lg={4}>
                <Link href='https://play.google.com/store/apps/details?id=com.kmotion.kgo.prod&hl=en_US' 
                  target='_blank'
                  rel='noopener'
                  onClick={() => onAppStoreClick('Android')}
                  className={classes.storeIcon}
                >
                  <img src={PlayStoreIcon} alt='play store' className={classes.storeImg}/>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <ListItemText>
            2. Open KGo on your phone and log in with the same user 
                name and password you used earlier.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            3. Once you log in, you’ll see a welcome message 
                and video with tips on how to get started
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
}

export default HowToGetStarted;