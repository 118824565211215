import React from 'react';
import logger from 'js-logger';
import differenceInYears from 'date-fns/differenceInYears';
import { GetClientActivations, PatchActivatedClients } from '../../network/clientActivationRequests';
import RequestPage from '../../components/clientActionPage';
import { GetMachineRegistrations } from '../../network/registrationRequests';
import { getUserId } from '../../utils/auth.utils';
import { GetUser } from '../../network/userRequests';

const COPPA_AGE_CUTOFF = 13; 

class ClientActivationRequests extends React.Component {
  state = {
    data: [],
    acceptedActivations: [],
    displayEula: false,
    submittingEula: false,
    underCoppaCutoff: false
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData = async () => {
    try {
      const userId = getUserId();
      const [user, activations] = await Promise.all([
        GetUser(userId), 
        GetClientActivations(userId)
      ]);
      let underCoppaCutoff = false;
      if (user.dateOfBirth != null) {
        const age = differenceInYears(new Date(), new Date(user.dateOfBirth));
        underCoppaCutoff = age < COPPA_AGE_CUTOFF;
      }
      this.setState(
        { data: activations.filter(activation => !activation.active), underCoppaCutoff },
        this._loadRegistrationData);
    } catch (err) {
      logger.error(err);
    }
  }

  async _loadRegistrationData() {
    try {
      let machineKeys = this.state.data.map(activation => activation.machineKey);
      let registrations = await GetMachineRegistrations({ machineKeys: machineKeys });
        
      var updatedActivations = this.state.data.map(act => {
        let reg = registrations.find(x => { 
          return x.machineKey === act.machineKey;
        });
        if (reg != null) {
          return { ...act, ...reg };
        } else {
          return act;
        }
      });

      this.setState({ data: updatedActivations });
    } catch (ex) {
      logger.error(ex);
    }
  }

  toggleActivated = (idx) => {
    var dataCopy = [...this.state.data];
    var itemCopy = { ...dataCopy[idx] };
    itemCopy.active = !itemCopy.active;
    dataCopy[idx] = itemCopy;
    this.setState({ data: dataCopy });
  }

  closeEula = () => {
    this.setState({ submittingEula: false, displayEula: false });
  }

  acceptEula = () => {
    this.setState({ submittingEula: true });
    let patchesCompleted = 0;
    this.state.acceptedActivations.forEach(async activation => {
      try {
        await PatchActivatedClients(activation.id, [{ op:'replace', path:'/active', value:1 }]);
        if (++patchesCompleted === this.state.acceptedActivations.length) {
          this._loadData();
          this.closeEula();
        }
      } catch (e) {
        logger.error(e);
        this.setState({ submittingEula: false });
      }
    });
  }

  submitForm = () => {
    if (this.state.underCoppaCutoff) {
      return;
    }
    
    var data = this.state.data
      .filter((elem) => elem.active);
    if (data.length === 0) {
      return;
    }

    //display popup here and do below on popup completion (alter state)
    this.setState({ displayEula: true, acceptedActivations: data });
  }

  render() {
    return (
      <RequestPage {...this.state} 
        toggle={this.toggleActivated} 
        submit={this.submitForm}
        acceptEula={this.acceptEula}
        closeEula={this.closeEula}
      />
    );
  }
}

export default ClientActivationRequests;