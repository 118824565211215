import { GetAnalyzedSwingData } from '../network/motionRequests';

// Actions
const REQUEST = 'kdashboard/analyzedData/request';
const ADD = 'kdashboard/analyzedData/add';
const ERROR = 'kdashboard/analyzedData/error';

// Reducers
function analyzedData(
  state = {
    isFetching: false,
    error: null,
    frames: []
  },
  action
) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case ADD:
      return { ...state, isFetching: false, frames: action.frames };
    case ERROR:
      return { ...state, isFetching: false, error: action.error };
    default:
      return state;
  }
}

const initialState = {};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
    case ADD:
    case ERROR:
      return { 
        ...state, 
        [action.motionId]: analyzedData(state[action.motionId], action) 
      };
    default: 
      return state;
  }
}

// Action Creators
export function RequestAnalyzedData(motionId) {
  return { 
    type: REQUEST, 
    motionId
  };
}

export function AddAnalyzedData(motionId, analyzedData) {
  return { 
    type: ADD, 
    motionId,
    frames: analyzedData
  };
}

export function SetAnalyzedDataError(motionId, error) {
  return { 
    type: ERROR, 
    motionId,
    error: error
  };
}

// Thunks
const NUM_RETRIES = 3;
const RETRY_DELAY_MS = 2000;

export function fetchAnalyzedData(motionId) {
  return function (dispatch, getState) {
    // no need to request if the data is already in redux
    if (!getState().analyzedMotionData[motionId]) {
      function fetchDataRetry(retries) {
        setInterval(() => fetchData(retries), RETRY_DELAY_MS);
      }

      async function fetchData(retries = NUM_RETRIES) {
        dispatch(RequestAnalyzedData(motionId));
        try {
          const data = await GetAnalyzedSwingData(motionId);
          dispatch(AddAnalyzedData(motionId, data));
        } catch (e) {
          if (e.response && e.response.status === 404 && retries > 1) {
            fetchDataRetry(retries - 1);
            return;
          }
          dispatch(SetAnalyzedDataError(motionId, e));
        }
      };

      fetchData();
    }
  };
}