import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  header: {
    fontSize: '18px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    padding: '5px 0',
    '&:hover': {
      backgroundColor: palette.lightGray
    },
    marginBottom: '10px'
  },
  selectedVideo: {
    boxSizing: 'border-box',
    borderBottom: `2px solid ${palette.midnightBlue}`
  },
  message: {
    marginBottom: '15px'
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  tag: {
    marginRight: '5px',
    marginBottom: '10px'
  }
}));

export default useStyles;

  
