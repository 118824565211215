import Network from './network';
import { getOrganizationId } from '../utils/auth.utils';

const Url = 'Api/Drills';

export const GetAllDrills = async (cancelToken) => {
  const orgId = getOrganizationId();
  const resp = await Network.Get(Url + `?organizationId=${orgId}`, null, cancelToken);
  return resp.data.items;
};

export const GetDrill = async (id, cancelToken) => {
  const resp = await Network.Get(Url + `/${id}`, null, cancelToken);
  return resp.data;
};

export const UpdateDrill = async drill => {
  const resp = await Network.Put(Url + `/${drill.id}`, drill);
  return resp.data;
};

export const CreateDrill = async drill => {
  const resp = await Network.Post(Url, drill);
  return resp.data;
};

export const GetEquipment = async (cancelToken) => {
  const resp = await Network.Get(Url + '/Equipment', null, cancelToken);
  return resp.data;
};