import Network from './network';
import queryString from 'query-string';
import keyBy from 'lodash/keyBy';
import MotionUtils from '../utils/motion.utils';
import { getOrganizationId } from '../utils/auth.utils'; 
import * as MotionSubresources from '../constants/motionSubresources.constants';
import mapKeys from 'lodash/mapKeys';
import { pascalCase } from 'pascal-case';

const MOTION_PREFIX = 'API/Motions/';
const CONDENSED_ANALYZED_DATA = '/CondensedAnalyzedFrames';
const DOWNLOAD = '/Download';
const FULL_MOTION_METRICS = '/FullMotionMetrics';
const SWING_SUMMARIES = 'SwingSummaries/Baseball';
const GOLF_SUMMARY = 'SwingSummaries/Golf';
const FORCE_PLATE = '/ForcePlate';
const HITTING_DEVICE_SENSOR = '/HittingDeviceSensor';
const LAUNCH_MONITOR = '/LaunchMonitorData';
const MOTION_ATTRIBUTES = '/Attributes';
const AVERAGE = '/Average';
const TIME_SYNC_STATS = '/TimeSyncStats';
const PERCENTILES = 'Percentiles';

const getAnalyzedDataUrl = (motionId) => {
  return MOTION_PREFIX + motionId + CONDENSED_ANALYZED_DATA;
};

const getFullMotionMetricsUrl = (motionId) => {
  return MOTION_PREFIX + motionId + FULL_MOTION_METRICS;
};

// The motion endpoint puts a 100 item limit on items returned.
// If you need to get all items, this will loop through calls 
// in order to return all results. The return value will be the array
// of motions without the paging property.
export const GetAllMotionsBeyondLimit = async (params, cancelToken) => {
  let moreAvailable = true;
  const motions = [];
  let startIndex = 0;
  const pagingCount = 100;

  while (moreAvailable) {
    const resp = await Network.Get(
      MOTION_PREFIX,
      { ...params, startIndex, count: pagingCount },
      cancelToken
    );
    const { items, pagination } = resp.data;
    motions.push(...items);
    moreAvailable = pagination.startIndex + pagination.count < pagination.totalCount;
    if (moreAvailable) startIndex += pagingCount;
  }
  return motions;
};

export const PatchMotion = async (motionId, operations) => {
  await Network.Patch(MOTION_PREFIX + motionId, operations);
};

export const PatchBaseballFullSwingMetrics = async (url, operations) => {
  let resp = await Network.Patch(url, operations);
  return resp.data;
};

export const GetMotionsForUserInCurrentOrg = async (userId, params, cancelToken) => {
  var organizationId = getOrganizationId();
  let resp = await Network.Get(MOTION_PREFIX, { userId, organizationId, ...params }, cancelToken);
  return resp.data;
};

export const GetMotionsForUser = async (userId, params, cancelToken) => {
  let resp = await Network.Get(MOTION_PREFIX, { userId, ...params }, cancelToken);
  return resp.data;
};

export const GetMotionsForOrg = async (organizationId, params, cancelToken) => {
  let resp = await Network.Get(MOTION_PREFIX, { organizationId, ...params }, cancelToken);
  return resp.data;
};

export const GetMotion = async (id, params, cancelToken) => {
  let resp = await Network.Get(MOTION_PREFIX + id, params, cancelToken);
  return resp.data;
};

export const DeleteMotion = async (id) => {
  await Network.Delete(MOTION_PREFIX + id);
};

export const GetAnalyzedSwingData = async (id, cancelToken) => {
  let resp = await Network.Get(getAnalyzedDataUrl(id), null, cancelToken);
  return resp.data.items;
};

export const GetBaseballSummary = async (id) => {
  let resp = await Network.Get(getFullMotionMetricsUrl(id));
  return resp.data;
};

export const GetFullMotionMetricsAverages = async (params, cancelToken) => {
  const url = MOTION_PREFIX + FULL_MOTION_METRICS.substring(1) + AVERAGE;
  var resp = await Network.Get(url, params, cancelToken);
  return resp.data.items;
};

export const GetForcePlate = async id => {
  const url = MOTION_PREFIX + id + FORCE_PLATE;
  let resp = await Network.Get(url);
  return resp.data.items;
};

export const GetForcePlateAverages = async (params, cancelToken) => {
  const url = MOTION_PREFIX + FORCE_PLATE.substring(1) + AVERAGE;
  var resp = await Network.Get(url, params, cancelToken);
  return resp.data.items;
};

export const PutForcePlate = async (id, data) => {
  const url = MOTION_PREFIX + id + FORCE_PLATE;
  return await Network.Put(url, data);
};

export const GetHittingDeviceSensor = async id => {
  const url = MOTION_PREFIX + id + HITTING_DEVICE_SENSOR;
  let resp = await Network.Get(url);
  return resp.data.items;
};

export const GetTimeSyncStats = async id => {
  const url = MOTION_PREFIX + id + TIME_SYNC_STATS;
  let resp = await Network.Get(url);
  return resp.data;
};

export const GetHittingDeviceSensorAverages = async (params, cancelToken) => {
  const url = MOTION_PREFIX + HITTING_DEVICE_SENSOR.substring(1) + AVERAGE;
  var resp = await Network.Get(url, params, cancelToken);
  return resp.data.items;
};

export const PutHittingDeviceSensor = async (id, data) => {
  const url = MOTION_PREFIX + id + HITTING_DEVICE_SENSOR;
  return await Network.Put(url, data);
};

export const GetLaunchMonitor = async id => {
  const url = MOTION_PREFIX + id + LAUNCH_MONITOR;
  let resp = await Network.Get(url);
  return resp.data;
};

export const GetLaunchMonitorAverages = async (params, cancelToken) => {
  const url = MOTION_PREFIX + LAUNCH_MONITOR.substring(1) + AVERAGE;
  var resp = await Network.Get(url, params, cancelToken);
  return resp.data.items;
};

export const PutLaunchMonitor = async (id, data) => {
  const url = MOTION_PREFIX + id + LAUNCH_MONITOR;
  return await Network.Put(url, data);
};

export const PutMotionAttributes = async(id, data) => {
  const url = MOTION_PREFIX + id + MOTION_ATTRIBUTES;
  return await Network.Put(url, data);
};

export const GetMotionSubresources = async (motion, subresourceKeys) => {
  const links = keyBy(motion.links, 'rel');
  return await Promise.all(subresourceKeys.map(async key => {
    if (links[key] == null) return;
    let resp = await Network.Get(links[key].href);
    return resp.data;
  }));
};

export const GetGolfSwingSummaries = async params => {
  var url = MOTION_PREFIX + GOLF_SUMMARY;
  let resp = await Network.Get(url, params);
  return resp.data.items;
};


export const GetPercentiles = async (weightKg, speeds) => {
  var url = MOTION_PREFIX + PERCENTILES;
  const pascalCaseSpeeds = mapKeys(speeds, (_, key) => pascalCase(key));
  let resp = await Network.Get(url, { weightKg, ...pascalCaseSpeeds });
  return resp.data;
};


export const GetMotionDataAsCsv = (id) => {
  // this downloads a file by hitting a response and is different
  // than a normal network call that would do something based on the response.
  var url = process.env.REACT_APP_KCLOUD_BASE_URL + MOTION_PREFIX + id + DOWNLOAD;
  const response = { file: url };
  window.open(response.file);
};

export const DownloadSummaryDataAsCsv = (params) => {
  // this downloads a file by hitting a response and is different
  // than a normal network call that would do something based on the response.
  var queryParamString = '?' + queryString.stringify(params);
  var url = process.env.REACT_APP_KCLOUD_BASE_URL + MOTION_PREFIX 
            + SWING_SUMMARIES + DOWNLOAD + queryParamString;
  const response = { file: url };
  window.open(response.file);
};

export const LoadAllMotionData = async (motionId, user, cancelToken) => {

  const [ motion, analyzedData ] = await Promise.all([
    GetMotion(motionId, {
      subresources: [
        MotionSubresources.FULL_MOTION_METRICS,
        MotionSubresources.LAUNCH_MONITOR, 
        MotionSubresources.FORCE_PLATE, 
        MotionSubresources.HITTING_DEVICE_SENSOR, 
        MotionSubresources.ATTRIBUTES, 
        MotionSubresources.KEY_FRAME_DATA
      ] 
    }, cancelToken),
    GetAnalyzedSwingData(motionId, cancelToken)
  ]);
  const { 
    truePeakSpeedPelvis, 
    truePeakSpeedTorso 
  } = MotionUtils.getTrueSpeeds(user, motion);
  const keyFrameDataMap = MotionUtils.constructKeyFramePositionsMap(motion.keyFrameData);
  
  const { 
    peakSpeedFramePelvis, 
    peakSpeedFrameTorso, 
    peakSpeedFrameUpperArm, 
    peakSpeedFrameHand, 
    transitionFramePelvis,
    transitionFrameTorso,
    transitionFrameUpperArm,
    transitionFrameHand
  } = motion.fullMotionMetrics;

  const peakSpeedSequence = MotionUtils.calculateSequences(
    peakSpeedFramePelvis,
    peakSpeedFrameTorso,
    peakSpeedFrameUpperArm,
    peakSpeedFrameHand,
    'PeakSpeedSequence'
  );
  const transitionSequence = MotionUtils.calculateSequences(
    transitionFramePelvis,
    transitionFrameTorso,
    transitionFrameUpperArm,
    transitionFrameHand,
    'TransitionSequence'
  );
  
  return { 
    motion: {
      ...motion,
      fullMotionMetrics: { 
        ...motion.fullMotionMetrics, 
        truePeakSpeedPelvis, 
        truePeakSpeedTorso 
      }
    },
    keyFrameDataMap,
    analyzedData,
    flawIds: motion.attributes.map(({ motionAttributeId }) => motionAttributeId),
    sequenceData: { ...peakSpeedSequence, ...transitionSequence }
  };

};