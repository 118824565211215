import React from 'react';
import { useSelector } from 'react-redux';
import {
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  ListItemText
} from '@material-ui/core';
import { formatShortDateWithSeconds } from '../../../../utils/formatting.utils';
import useStyles from './styles';

function TagSubheader({ tagName, color }) {
  const classes = useStyles({ color });
  return <ListSubheader className={classes.tagSubheader}>
    {tagName}
  </ListSubheader>;
}

function TaggedMotionSelect({ motion, onChange, id }) {
  const { tags, motions, users } = useSelector(state => state.dataAnalysis);
  const classes = useStyles();
  
  const MenuItemText = ({ motionId }) => {
    const itemMotion = motions[motionId];
    const { firstName, lastName } = users[itemMotion?.userId] ?? {};
    const timestamp = itemMotion?.timestamp != null 
      ? formatShortDateWithSeconds(itemMotion.timestamp) 
      : null;
    
    return <ListItemText 
      primary={`${firstName} ${lastName}`}
      secondary={timestamp}
    />;
  };

  return <FormControl className={classes.formControl}>
    <Select 
      defaultValue='' 
      id={id} 
      value={motion?.id ?? ''} 
      onChange={e => onChange(motions[e.target.value])}
    >
      <MenuItem value=''>
        <em>Select Motion</em>
      </MenuItem>
      {Object.values(tags).map(({ tagName, color, motionIds }) => [
        <TagSubheader key={`select-section-${id}-${tagName}`} tagName={tagName} color={color} />,
        ...motionIds.map(motionId => 
          <MenuItem value={motionId} key={`${id}-${tagName}-${motionId}`}>
            <MenuItemText motionId={motionId} />
          </MenuItem>)
      ])}
    </Select>
  </FormControl>;
}

export default TaggedMotionSelect;
