import { makeStyles } from '@material-ui/styles';
import Colors from '../../constants/colors.constants';
import palette from '../../styles/palette';

export default makeStyles(theme => ({
  clientsContainer: {
    paddingTop: '75px'
  },
  createAccountButton: {
    background: Colors.primaryBlueGreen,
    color: 'white',
    borderRadius: '3px',
    padding: '3px 15px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    '&:hover': { background: Colors.primaryBlueGreenLightTint }
  },
  item: {
    padding: '10px',
    margin: '5px 0'
  },
  tableRow: { 
    display: 'flex', 
    flexDirection: 'row'
  },
  searchBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: '10px'
  },
  header: {
    color: palette.darkGray,
    margin: '0px 8px',
    whiteSpace: 'nowrap'
  }
}));