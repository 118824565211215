import React, { useMemo } from 'react';
import useStyles from './styles';
import classnames from 'classnames';

import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import SyncIcon from '@material-ui/icons/Sync';

import {
  TableCell,
  TableRow,
  Checkbox
} from '@material-ui/core';

function RecordRow({ 
  record,
  syncAttemptData,
  columns, 
  onClick, 
  selected 
}) {
  const classes = useStyles();

  const { id } = record;
  const clickable = onClick != null;

  const synced = useMemo(() => {
    return record.motionId != null && record.launchMonitorId != null;
  }, [record]);

  return <TableRow
    className={classnames(
      { [classes.clickableRow]: clickable }
    )}
    onClick={clickable ? () => onClick(id) : null}
  >
    {
      clickable &&
      <TableCell>
        <Checkbox checked={selected || false}/>
      </TableCell>
    }
    <TableCell>
      {
        synced ?
          <SyncIcon className={classes.synced} /> 
          :
          <SyncProblemIcon className={classes.notSynced} />
      }
    </TableCell>
    {
      columns.map(({ header, displayFn }) => {
        return <TableCell key={header}>
          { 
            displayFn(record, syncAttemptData) 
          }
        </TableCell>;
      })
    }
  </TableRow>;
};

export default RecordRow;