import { useState } from 'react';
import { 
  Button, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  Divider,
  Grid,
  List, 
  ListItem, 
  ListItemText, 
  TextField,
  Typography
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { GetAllTagGroups, CreateTagGroup, UpdateTagGroup } from '../../../../../network/tagGroups.requests';
import useNetworkRequest from '../../../../../network/useNetworkRequest';
import useStyles from './styles';
import { getUserId } from '../../../../../utils/auth.utils';

function SaveTagsDialog({ open, currentTags, onClose }) {
  const [tagGroupName, setTagGroupName] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [tagGroups] = useNetworkRequest([], 'getTagGroups', GetAllTagGroups);
  const classes = useStyles();

  const userTagGroups = tagGroups.filter(group => group.creatorId === getUserId());
  const generateTagsForApi = () => Object.keys(currentTags).map((name, idx) => {
    const tagId = uuidv4();
    return {
      id: tagId,
      name,
      order: idx,
      color: currentTags[name].color,
      tagsMotions:  currentTags[name].motionIds.map(motionId => ({
        motionId,
        tagId
      }))
    };        
  });

  const updateTagGroup = async () => {
    setErrMsg('');
    const updatedGroup = {
      ...selectedGroup,
      tags: generateTagsForApi()
    };

    try {
      await UpdateTagGroup(updatedGroup);
      onClose();
    } catch (e) {
      setErrMsg('Error trying to save group.');
    }
  };

  const createTagGroup = async () => {
    setErrMsg('');
    const group = {
      name: tagGroupName,
      id: uuidv4(),
      tags: generateTagsForApi()
    };

    try {
      await CreateTagGroup(group);
      onClose();
    } catch (e) {
      setErrMsg('Error trying to save group.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Save Tag Group</DialogTitle>
      <DialogContent>
        <Typography>{errMsg}</Typography>
        <div className={classes.newGroupContainer}>
          <TextField 
            variant='outlined' 
            value={tagGroupName} 
            onChange={e => setTagGroupName(e.target.value)}
            label='New Group'
          />
          <Button variant='contained' color='primary' onClick={createTagGroup}>
            Submit
          </Button>
        </div>
        <Divider className={classes.divider} />
        <Typography variant='h6'>Update Existing Group</Typography>
        <List>
          {userTagGroups.map(group => (
            <ListItem
              key={group.id} 
              button 
              onClick={() => setSelectedGroup(group)} 
              selected={selectedGroup === group}
            >
              <ListItemText>{group.name}</ListItemText>
            </ListItem>
          ))}
        </List>
        <Grid container justify='space-evenly'>
          <Button onClick={onClose}>Cancel</Button>
          <Button 
            color='primary' 
            variant='contained' 
            disabled={selectedGroup == null}
            onClick={updateTagGroup}
          >
            Submit
          </Button>
        </Grid>
      </DialogContent>

    </Dialog>
  );
};

export default SaveTagsDialog;