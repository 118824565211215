import makeStyles from '@material-ui/core/styles/makeStyles';
import Palette from '../../../../../../styles/palette';

const useStyles = makeStyles(() => ({
  container: {
    height: '60vh',
    marginTop: '10px'
  },
  list: {
    overflowY: 'auto',
    maxHeight: '100%'
  },
  video: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    backgroundColor: Palette.black
  },
  videoContainer: {
    height: '100%'
  }
}));

export default useStyles;
