import Shape from './shape';
import SHAPES from './constants';

class Freehand extends Shape {
  shape = SHAPES.freehand;

  constructor(coordinates, color, lineWidth = 1) {
    super();
    this.coordinates = coordinates;
    this.color = color;
    this.lineWidth = lineWidth;
  }

  drawOnCanvas(ctx) {
    ctx.fillStyle = this.color;
    // adjust to not draw fractions of pixels and keep dots centered
    const adjustedLineWidth = this.lineWidth % 2 === 0 ? this.lineWidth + 1 : this.lineWidth;
    const offset = (adjustedLineWidth - 1) / 2;
    this.coordinates.forEach(({ x, y }) => {
      ctx.fillRect(x - offset, y - offset, adjustedLineWidth, adjustedLineWidth);
    });
  }
};

export default Freehand;
