import { Checkbox, Typography } from '@material-ui/core';
import { CaptureEventResultAvailabilityStatus } from 'constants/captureEventResult.constants';
import Logger from 'js-logger';
import { PatchCaptureEventResult } from 'network/captureEventRequests';
import { useState } from 'react';
import { CaptureEventTypes } from 'constants/captureEvents.constants';
import useStyles from './styles';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import ConfirmationDialog from 'components/dialogs/confirmationDialog';
import VIDEO_STATUSES from 'constants/video.constants';

const MOBILE_APP_AVAILABILITY_STATUS = 'mobileAppAvailabilityStatus';
const PROFILE_AVAILABILITY_STATUS = 'perfectGameProfileAvailabilityStatus';

const EditAvailability = ({ 
  captureEvent, 
  captureEventResult, 
  user, 
  onEditSuccess = () => {} 
}) => {
  
  const classes = useStyles();

  const captureEventId = captureEvent?.id;

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const profileStatus = captureEventResult?.perfectGameProfileAvailabilityStatus;
  const appStatus = captureEventResult?.mobileAppAvailabilityStatus;

  const profileAvailable = CaptureEventResultAvailabilityStatus.available === profileStatus;
  const appAvailable = CaptureEventResultAvailabilityStatus.available === appStatus;

  const isShowcaseEvent = captureEvent.type === CaptureEventTypes.perfectGameEvent;

  const externalId = user?.organizationUserProperties?.externalId;
  const externalIdExists = externalId != null;

  // Operators can always make a result unavailable (regardless if there is an external Id Present).
  // Operators cannot make a result available if the extenal id is missing.
  const profilePushMissingData = !externalIdExists && !profileAvailable;

  const disableProfileAvailabilityToggle = isShowcaseEvent || profilePushMissingData;

  const disableMessage = (() => {
    if (isShowcaseEvent) {
      return 'Profile Push Availability Cannot Be Changed for Showcases';
    }

    if (profilePushMissingData) {
      return 'Profile Data Push Cannot Be Made Available Without an PG External Id';
    }

    return null;
  })();

  const noVideoWarningMessage = (() => {
    if (!captureEventResult) {
      return '';
    }
    const allVideos = captureEventResult?.videos ?? [];
    const availableVideos = allVideos.filter(x => x.status === VIDEO_STATUSES.available);
    if (allVideos.length === 0) {
      return 'WARNING: THERE IS NO VIDEO FOR THIS RESULT';
    }

    // There is a video since it passed the previous check 
    // but it is not yet or may never be available to stream
    if (availableVideos.length === 0) {
      const videoStatus = allVideos[0].status;
      return `WARNING: VIDEO IS NOT READY WITH STATUS "${videoStatus}"`;
    }

    return null;
  })();

  const confirmationMessage = profileAvailable
    ? 'This will mark this player\'s data as not available!'
    : 'A profile push usually requires a purchase!';

  const updateAvailabilityStatus = async (column, newAvailabilityStatus) => {
    setSaving(true);
    setError(null);
    let updatedResult = null;
    try {
      updatedResult = await PatchCaptureEventResult(
        captureEventId,
        captureEventResult.captureEventUserId,
        captureEventResult.id,
        [{
          op: 'replace',
          path: '/' + column,
          value: newAvailabilityStatus
        }]
      );
    } catch(e) {
      setError('There was an error');
      Logger.error(
        `Error updating ${column} for result`, captureEventId, captureEventResult, e);
    } finally {
      setSaving(false);
    }

    return updatedResult;
  };

  const confirmAppAvailabilityChange = async () => {
    if (saving) {
      return;
    }

    const updatedStatus = appAvailable
      ? CaptureEventResultAvailabilityStatus.notAvailable
      : CaptureEventResultAvailabilityStatus.available;

    const updatedResult = await updateAvailabilityStatus(
      MOBILE_APP_AVAILABILITY_STATUS,
      updatedStatus
    );

    onEditSuccess(updatedResult);
  };

  const confirmProfileAvailabilityChange = async () => { 
    if (disableProfileAvailabilityToggle || saving) return;

    const updatedStatus = profileAvailable
      ? CaptureEventResultAvailabilityStatus.notAvailable
      : CaptureEventResultAvailabilityStatus.available;
    
    const updatedResult = await updateAvailabilityStatus(
      PROFILE_AVAILABILITY_STATUS, 
      updatedStatus
    );
    
    setOpenConfirmation(false);
    onEditSuccess(updatedResult);
  };

  const cancelConfirmation = () => {
    setOpenConfirmation(false);
    setError(false);
  };

  const WarningMessage = ({ style = null, text }) => <div 
    className={classNames(classes.messageContainer, style)}
  >
    <WarningIcon className={classes.icon} />
    <Typography className={classes.message}>{text}</Typography>
  </div>;

  return <div className={classes.container}>
    {disableMessage 
      && <WarningMessage style={classes.disabledMessageText} text={disableMessage} />}

    {noVideoWarningMessage 
      && <WarningMessage style={classes.videoWarningText} text={noVideoWarningMessage} />}

    <div className={classes.toggleContainer}>
      <Checkbox 
        checked={profileAvailable}
        disabled={disableProfileAvailabilityToggle}
        onClick={() => setOpenConfirmation(true)}
      />
      
      <Typography className={
        classNames({ [classes.disabledText]: disableProfileAvailabilityToggle || saving })
      }>
        Show on PG Profile
      </Typography>
    </div>

    <div className={classes.toggleContainer}>
      <Checkbox 
        checked={appAvailable}
        disabled={saving}
        onClick={confirmAppAvailabilityChange}
      />
      <Typography className={classNames({ [classes.disabledText]: saving })}>
        Show in Mobile App
      </Typography>
    </div>

    <ConfirmationDialog 
      open={openConfirmation}
      title='Confirm Profile Data Availability Change'
      loading={saving}
      onConfirm={confirmProfileAvailabilityChange}
      onCancel={cancelConfirmation}
      errorMessage={error}
      disableCancelOnLoad
      confirmDisabled={disableProfileAvailabilityToggle}
    >
      <div className={classes.confirmationContainer}>
        <Typography>
          Are you sure you want to change this player's availability? 
        </Typography>
        <Typography>
          { confirmationMessage }
        </Typography>
      </div>
    </ConfirmationDialog>
  </div>;
};

export default EditAvailability;