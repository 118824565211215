import { makeStyles } from '@material-ui/core/styles';
import Palette from 'styles/palette';

const useStyles = makeStyles(_ => ({
  container: {
    backgroundColor: Palette.lightBlueGray,
    borderRadius: 24,
    padding: '24px'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  item: {
    flex: 1
  },
  title: {
    whiteSpace: 'pre'
  },
  value: {
    fontSize: 72,
    whiteSpace: 'pre'
  }
}));

export default useStyles;
