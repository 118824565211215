import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { history } from '../../store';
import Gear from './Gear';

import palette from '../../styles/palette';
import useStyles from './styles';
import { Logout, hasOrgAdminRole } from '../../utils/auth.utils';
import { PERMISSIONS, TAB_PERMISSIONS } from '../../constants/permissions.constants';

function SettingsMenu({ className }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const featurePermissions = useSelector(state => state.featurePermissions);
  
  const showAssociateExternalUserIds = featurePermissions.includes(PERMISSIONS.apiAvailable);
  const canDownloadData = featurePermissions.includes(PERMISSIONS.canDownloadMotionCsv)
    || featurePermissions.includes(PERMISSIONS.canDownloadSummariesCsv);
  const canUploadDrills = featurePermissions.includes(TAB_PERMISSIONS.drillVideos);
  const hasVideoAnalysis = featurePermissions.includes(TAB_PERMISSIONS.videoAnalysis);
  const hasInternalTooling = featurePermissions.includes(PERMISSIONS.internalTooling);

  const openMenu = e => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  
  function downloadMotionsClick() {
    closeMenu();
    history.push('/player-development/download-motions');
  }

  function organizationManagementClick() {
    closeMenu();
    history.push('/player-development/organization-management');
  }

  const associateExternalUserIdsClick = () => {
    closeMenu();
    history.push('/player-development/associate-external-userids');
  };

  function drillVideosClick() {
    closeMenu();
    history.push('/player-development/drill-videos');
  }

  function videoAnalysisClick() {
    closeMenu();
    history.push('/player-development/video-analysis');
  }

  function swingStreamClick() {
    closeMenu();
    history.push('/player-development/swing-stream');
  }

  function captureEventsClick() {
    closeMenu();
    history.push('/capture-events');
  }

  function contentFeedManagementClick() {
    closeMenu();
    history.push('/player-development/content-feed-management');
  }

  function drillCreationClick() {
    closeMenu();
    history.push('/player-development/drills');
  }

  function lessonTemplateClick() {
    closeMenu();
    history.push('/player-development/lesson-templates');
  }

  function dataAnalysisClick() {
    closeMenu();
    history.push('/data-analysis');
  }

  function trainingPlansClick() {
    closeMenu();
    history.push('/player-development/training-plans');
  }

  function trainingPlanTemplatesClick() {
    closeMenu();
    history.push('/player-development/training-plan-templates');
  }

  return <div className={className}>
    <Paper
      aria-controls='settings-menu'
      name='settings-menu-icon'
      aria-haspopup='true'
      onClick={openMenu}
      className={classes.paper}
    >
      <Gear size={24} color={palette.white} />
    </Paper>
    <Menu
      id='settings-menu'
      anchorEl={anchorEl}
      name='settings-menu'
      keepMounted
      open={Boolean(anchorEl)}
      onClose={closeMenu}
      classes={{ paper: classes.menu }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {canDownloadData && <MenuItem 
        className={classes.menuItem} 
        onClick={downloadMotionsClick}
      >
        Download Motions
      </MenuItem>
      }
      {hasOrgAdminRole() && <MenuItem 
        className={classes.menuItem} 
        onClick={organizationManagementClick}
      >
        Organization Management
      </MenuItem>}

      {hasOrgAdminRole() && showAssociateExternalUserIds && <MenuItem 
        className={classes.menuItem} 
        onClick={associateExternalUserIdsClick}
      >
        Add External User Ids
      </MenuItem>}
      {canUploadDrills && <MenuItem 
        className={classes.menuItem} 
        onClick={drillVideosClick}
      >
        Upload Drill Videos
      </MenuItem>}
      {hasVideoAnalysis && <MenuItem
        className={classes.menuItem}
        onClick={videoAnalysisClick}
      >
        Video Analysis
      </MenuItem>
      }
      {hasInternalTooling && <MenuItem
        className={classes.menuItem}
        onClick={swingStreamClick}
      >
        Swing Stream
      </MenuItem>
      }
      {hasInternalTooling && <MenuItem
        className={classes.menuItem}
        onClick={captureEventsClick}
      >
        Capture Events
      </MenuItem>
      }
      {hasInternalTooling && <MenuItem
        className={classes.menuItem}
        onClick={contentFeedManagementClick}
      >
        Content Feed Management
      </MenuItem>
      }
      {hasInternalTooling && 
        <MenuItem 
          className={classes.menuItem} 
          onClick={drillCreationClick}
        >
          Drill Creation
        </MenuItem>
      }
      {hasInternalTooling && <MenuItem 
        className={classes.menuItem} 
        onClick={lessonTemplateClick}
      >
        Lesson Templates
      </MenuItem>
      }
      {hasInternalTooling && <MenuItem 
        className={classes.menuItem} 
        onClick={dataAnalysisClick}
      >
        Data Analysis
      </MenuItem>}
      {hasInternalTooling && <MenuItem 
        className={classes.menuItem} 
        onClick={trainingPlansClick}
      >
        Training Plans
      </MenuItem>}
      {hasInternalTooling && <MenuItem 
        className={classes.menuItem} 
        onClick={trainingPlanTemplatesClick}
      >
        Training Plan Templates
      </MenuItem>}
      <MenuItem className={classes.menuItem} onClick={() => Logout()}>
        Logout
      </MenuItem>
    </Menu>
  </div>;
}

export default SettingsMenu;
