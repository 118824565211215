import Auth0Lock from 'auth0-lock';
import logger from 'js-logger';
import { history } from '../store';
import { AUTH_CONFIG } from '../constants/auth.constants';
import Logo from '../assets/KMotionLogo.png';
import { setUserInfo, setSession, filterRedirectSearchParameters } from '../utils/auth.utils';

export default class Auth {
  lockOptions = {
    configurationBaseUrl: AUTH_CONFIG.configurationBaseUrl,
    closable: false,
    auth: {
      audience: AUTH_CONFIG.audience,
      redirect: false,
      responseType: 'token id_token',
      params: { scope: 'openid profile email' }
    },
    avatar: null,
    theme: {
      primaryColor: '#c20f2f',
      logo: Logo
    },
    languageDictionary: { title: 'Log In' },
    allowedConnections:  ['Username-Password-Authentication']
  }

  constructor(email, from, flashMessage) {
    this.flashMessage = flashMessage;
    this.lock = new Auth0Lock(AUTH_CONFIG.clientId, AUTH_CONFIG.domain, {
      ...this.lockOptions,
      prefill: { email: email }
    });

    this.lock.on('authorization_error', function(error) {
      if (error && error.code === 'invalid_user_password') return;
      logger.error(error);
    });

    this.lock.on('authenticated', async function(authResult) {
      setSession(authResult);

      // the substring removed the auth| prefix that auth0 puts in the userId
      let userId = authResult.idTokenPayload.sub.split('|')[1];
      await setUserInfo(userId);

      // navigate to the home page
      if (from == null || from.pathname == null) {
        history.push('/');
      } else {
        history.push(from.pathname + filterRedirectSearchParameters(from.search));
      }
      this.hide();
    });
  }

  showLogin = () => {
    this.lock.show({ allowSignUp: false, flashMessage: this.flashMessage });
  }
}