import Network from './network';

const URL = 'API/ContentFeedItems';

export async function GetContentFeedItems(organizationId, cancelSource) {
  var res = await Network.Get(URL, { organizationId }, cancelSource);
  return res.data.items;
}

export async function CreateContentFeedItem(contentFeedItem) {
  var res = await Network.Post(URL, contentFeedItem);
  return res.data;
}