import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { 
  Button, 
  Dialog, 
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography, 
  Grid, 
  Select, 
  MenuItem 
} from '@material-ui/core';
import { CreateAnalysis, UpdateAnalysis } from '../../../../network/videoAnalysisRequests';
import { UpdateVideoAttributes } from '../../../../network/videoRequests';
import { ANALYSIS_STATUS, ANALYSIS_TYPE } from '../../../../constants/videoAnalysis.constants';
import { CAMERA_ANGLE } from '../../../../constants/video.constants';
import { getOrganizationId } from '../../../../utils/auth.utils';
import ProgressButton from '../../../../components/progressButton';
import useStyles from './styles';
import Logger from 'js-logger';

function CreateAnalysisModal({ userVideos }) {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [catcherVideoId, setCatcherVideoId] = useState(null);
  const [faceOnVideoId, setFaceOnVideoId] = useState(null);
  const [handedness, setHandedness] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isError, setIsError] = useState(false);
  const userId = useSelector(state => state.currentPlayer.userId);

  const toggleModal = () => setShowModal(prev => !prev);

  async function createAnalysis() {
    setIsCreating(true);
    setIsError(false);
    try {
      await Promise.all([
        UpdateVideoAttributes(catcherVideoId, [{
          op: 'replace',
          path: '/cameraAngle',
          value: CAMERA_ANGLE.catchersView
        }, {
          op: 'replace',
          path: '/handedness',
          value: handedness
        }]),
        UpdateVideoAttributes(faceOnVideoId, [{
          op: 'replace',
          path: '/cameraAngle',
          value: CAMERA_ANGLE.faceOn
        }, {
          op: 'replace',
          path: '/handedness',
          value: handedness
        }])
      ]);
      const analysis = await CreateAnalysis(
        userId, 
        getOrganizationId(), 
        ANALYSIS_TYPE.fullAnalysis);
      analysis.videoAnalysisVideos = [
        { videoId: catcherVideoId, videoAnalysisId: analysis.id },
        { videoId: faceOnVideoId, videoAnalysisId: analysis.id }
      ];
      // If videos are not ready yet, this status could cause an issue.
      // However, this are various race conditions involved and since this is
      // only for internal tooling, we will ignore potential issues and say it is ready.
      analysis.status = ANALYSIS_STATUS.readyForAnnotation;
      await UpdateAnalysis(analysis.id, analysis);
      toggleModal();

    } catch(e) {
      Logger.error('Error creating analysis video', e);
      setIsError(true);
    }
    setIsCreating(false);
  }

  return (
    <div className={classes.container}>
      <Button 
        variant='contained' 
        color='primary' 
        className={classes.button} 
        onClick={toggleModal}
      >
        Create Analysis
      </Button>
      <Dialog
        open={showModal}
        onClose={toggleModal}
        className={classes.modal}
        fullWidth
      >
        <DialogTitle>Create analysis</DialogTitle>
        <DialogContent>
          <Grid container direction='row'>
            <Grid item xs={4}>
              <Typography className={classes.label}>Front view video</Typography>
              <Typography className={classes.label}>Catcher's view video</Typography>
              <Typography className={classes.label}>Handedness</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select 
                className={classes.select} 
                value={faceOnVideoId} 
                onChange={e => setFaceOnVideoId(e.target.value)}
              >
                {userVideos
                  .filter(video => video.id !== catcherVideoId)
                  .map(video => (
                    <MenuItem key={`f-${video.id}`} value={video.id}>{video.title}</MenuItem>
                  ))}
              </Select>
              <Select 
                className={classes.select} 
                value={catcherVideoId} 
                onChange={e => setCatcherVideoId(e.target.value)}
              >
                {userVideos
                  .filter(video => video.id !== faceOnVideoId)
                  .map(video => (
                    <MenuItem key={`f-${video.id}`} value={video.id}>{video.title}</MenuItem>
                  ))}
              </Select>
              <Select 
                className={classes.select} 
                value={handedness} 
                onChange={e => setHandedness(e.target.value)}
              >
                {['Right', 'Left'].map(hand => (
                  <MenuItem key={`h-${hand}`} value={hand}>{hand}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          {isError && <Typography color='error'>Error creating analysis. Contact dev team.</Typography>}
        </DialogContent>
        <DialogActions>
          <ProgressButton
            showProgress={isCreating}
            variant='contained'
            color='primary'
            disabled={!(catcherVideoId && faceOnVideoId && handedness) || isCreating} 
            onClick={createAnalysis}
          >
            Create Analysis
          </ProgressButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateAnalysisModal;