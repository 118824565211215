import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';

export const APP_LOADED = 'App Loaded';
export const VIDEO_PLAYED = 'Video Played';
export const VIDEO_FINISHED = 'Video Finished';

export const SUBSCRIPTIONS_LOADED = 'Subscriptions Loaded';
export const SUBSCRIPTION_PLAN_CLICKED = 'Subscription clicked';

export const TIME_ON_PAGE = 'Time on Page';
export const VIEWED_PAGE = 'Viewed Page'; 
export const LOGGED_OUT = 'Logged Out';

export const TRAINING_LOGGED = 'Training Logged';

export const CATEGORIES = {
  GENERAL: 'General',
  VIDEO: 'video',
  PURCHASES: 'purchases',
  TRAINING_PLAN: 'Training Plans',
  AUTH: 'Authentication '
};

const PROJECTS = {
  KGO: 'kgo',
  PG: 'pg'
};

mixpanel.init(process.env.REACT_APP_MIXPANEL_KGO_API_TOKEN, null, PROJECTS.KGO);
mixpanel.init(process.env.REACT_APP_MIXPANEL_PG_API_TOKEN, null, PROJECTS.PG);
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

const Analytics = {
  PROJECTS,

  init: () => {
    ReactGA.event({ category: CATEGORIES.GENERAL, action: APP_LOADED });
  },
  setUserId: userId => {
    ReactGA.set({ userId });
    ReactGA.ga('set', 'dimension2', userId);
  },
  setOrganizationName: organizationName => {
    ReactGA.ga('set', 'dimension1', organizationName);
  },
  reset: () => {
    Object.values(PROJECTS).forEach(project => mixpanel[project].reset());
  },

  startTimer: (eventName, project = PROJECTS.PG) => {
    mixpanel[project].time_event(eventName);
  },

  track: ({ 
    eventName = 'general', 
    userId = 'anonymous',
    pageName = '',
    miscData = {}, 
    userEmail = 'anonymous@k-vest.com', 
    eventCategory = 'Generic',
    project = PROJECTS.PG
  }, options) => {
    const mixPanelOptions = options?.sendAsBeacon ? { 
      transport: 'sendBeacon',
      send_immediately: true
    } : {}; 
    mixpanel[project].track(eventName, {
      distinct_id: userId,
      userIdSecondary: userId,
      ...miscData,
      userEmail,
      pageName,
      eventCategory
    },
    mixPanelOptions);

    ReactGA.set({ userId: userId });
    ReactGA.event({
      category: eventCategory,
      action: `Event by: ${userId}`,
      label: JSON.stringify(miscData)
    });
  }
};

export default Analytics;