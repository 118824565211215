import React from 'react';
import { Paper } from '@material-ui/core';
import useStyles from './styles';

function SelectableTag({ 
  children, 
  data, 
  selected = false,
  Icon = null,
  onClick
}) {
  const severity = data.annotation ? data.annotation.swingCharacteristicSeverity : null;
  const classes = useStyles({ selected, severity });
  return (
    <Paper 
      elevation={3}
      className={classes.root}
      onClick={() => { onClick(data); }}
    >
      { Icon && <Icon className={classes.icon} /> }
      <div className={classes.container}>
        { children }
      </div>
    </Paper>
  );
}

export default SelectableTag;