import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class StatsTile extends React.Component {
  render() {
    const { 
      classes,
      className,
      title, 
      value, 
      units,
      loading
    } = this.props;
    return <div className={className}>
      <div className={classes.titleContainer}> {title} </div>
      {<div className={classes.valueContainer}> {loading 
        ? <CircularProgress className={classes.circularProgress} size={25}/>
        : <Typography className={classes.valueText}> 
          {value} {units} 
        </Typography>}
      </div>}
    </div>;
  }
}

export default withStyles(styles)(StatsTile);