import Network from './network';
import { getOrganizationId } from '../utils/auth.utils';

const URL = 'Api/CaptureEvents/';

export const Subresources = {
  Measurement: 'Measurement',
  CaptureEvent: 'CaptureEventUser.CaptureEvent',
  Videos: 'Videos'
};

export const GetCaptureEvents = async (cancelToken) => {
  const organizationId = getOrganizationId();
  const resp = await Network.Get(URL, { organizationId }, cancelToken);
  return resp.data.items;
};

export const GetCaptureEvent = async (id, cancelToken) => {
  const resp = await Network.Get(URL + id, null, cancelToken);
  return resp.data;
};

export async function GetCaptureEventResults(userId, cancelToken) {
  const subresources = [
    Subresources.Measurement, 
    Subresources.CaptureEvent, 
    Subresources.Videos
  ];
  const organizationId = getOrganizationId();
  const params = { 
    userId, 
    subresources, 
    threeMotionOrganizationId: organizationId,
    organizationId
  };
  const resp = await Network.Get('Api/CaptureEventResults', params, cancelToken);
  return resp.data.items;
};


export const GetEventUsers = async (id, cancelToken) => {
  const resp = await Network.Get(URL + id + '/CaptureEventUsers', null, cancelToken);
  return resp.data;
};

export const PostCaptureEvent = async (name, startDate, endDate, type) => {
  const organizationId = getOrganizationId();
  const body = {
    name,
    startDate,
    endDate,
    type,
    organizationId
  };
  const resp = await Network.Post(URL, body);
  return resp.data;
};

export const PostEventAction = async (captureEventId, actionType, payload) => {
  const body = {
    captureEventId,
    actionType,
    payload
  };
  const resp = await Network.Post(`${URL}${captureEventId}/Actions`, body);
  return resp.data;
};

export const CreatePlayerForEvent = async (captureEventId, params) => {
  var resp = await Network.Post(`${URL}${captureEventId}/Player`, params);
  return resp.data;
};


export const GetSyncAttempt = async (eventId, syncId, cancelToken) => {
  const url = URL + eventId + '/RawDataSyncAttempts/' + syncId;
  const resp = await Network.Get(url, null, cancelToken);
  return resp.data;
};

export const PostEventUserCsv = async (eventId, file) => {
  const data = new FormData();
  data.append('file', file);
  const resp = await Network.Post(URL + eventId + '/EventUserCsvUpload', data);
  return resp.data;
};

export const PostSyncAttempt = async (eventId, file, serialCode, source, optional = {}) => {
  const data = new FormData();
  data.append('file', file);
  data.append('serialCode', serialCode);
  data.append('source', source);

  // append optional parameters
  const { startDate, endDate, adjustableMinutes, adjustableSeconds, syncThreshold } = optional;
  if (startDate) data.append('startDate', startDate);
  if (endDate) data.append('endDate', endDate);
  if (adjustableMinutes != null) data.append('kCoachTimeAdjustmentMinutes', adjustableMinutes);
  if (adjustableSeconds != null) data.append('kCoachTimeAdjustmentSeconds', adjustableSeconds); 
  if (syncThreshold != null) data.append('TimeSyncThresholdSeconds', syncThreshold);

  const resp = await Network.Post(URL + eventId + '/RawDataSyncAttempts', data);
  return resp.data;
};

export const PatchSyncAttempt = async (eventId, syncId, operations) => {
  const resp = await Network.Patch(
    `${URL}${eventId}/RawDataSyncAttempts/${syncId}`, 
    operations
  );
  return resp.data;
};

export const PatchSyncRecord = async (eventId, syncId, recordId, operations) => {
  const resp = await Network.Patch(
    `${URL}${eventId}/RawDataSyncAttempts/${syncId}/SyncRecords/${recordId}`, 
    operations
  );
  return resp.data;
};

export const PatchEventUser = async (eventId, eventUserId, operations) => {
  const resp = await Network.Patch(
    `${URL}${eventId}/CaptureEventUsers/${eventUserId}`,
    operations
  );
  return resp.data;
};

export const DeleteSyncRecord = async (eventId, syncId, recordId) => {
  const url = `${URL}${eventId}/RawDataSyncAttempts/${syncId}/SyncRecords/${recordId}`;
  const resp = await Network.Delete(url);
  return resp.data;
};

export const DeleteCaptureEventUser = async (eventId, eventUserId) => {
  await Network.Delete(`${URL}${eventId}/CaptureEventUsers/${eventUserId}`);
};

export const MigrateMotions = async (eventId, userId, motionIds) => {
  await Network.Post(`${URL}${eventId}/MigrateMotions`, { userId, motionIds });
};

export const PatchCaptureEventResult = async (
  eventId,
  eventUserId,
  resultId,
  patchDoc
) => {
  const url = `${URL}${eventId}` + 
    `/CaptureEventUsers/${eventUserId}` +
    `/CaptureEventResults/${resultId}`;
  const resp = await Network.Patch(url, patchDoc);
  return resp.data;
};

export const GetCaptureEventLeaderboard = async (organizationId, captureEventId, cancelSource) => {
  const params = { captureEventId, organizationId };
  const resp = await Network.Get(URL + 'Leaderboard', params, cancelSource);
  return resp.data.items;
};

export const RefreshPerfectGameProfile = async (playerExternalId, captureEventExternalId) => {
  const params = { playerExternalId, captureEventExternalId };
  const resp = await Network.Get(URL + 'RefreshPerfectGameProfile', params);
  return resp.data;
};

export const DownloadRosterEmails = async (captureEventIds, cancelSource) => {
  const params = { captureEventIds };
  const resp = await Network.Get(
    URL + 'RosterEmailLists', 
    params, 
    cancelSource,
    null,
    'arraybuffer');
  return resp;
};
