const styles = {
  root: {
    overflow: 'auto',
    height: '100%'
  },
  headerCell: {
    position: 'sticky',
    background: 'white',
    top: '0',
    padding: '4px 56px 4px 24px',
    lineHeight: '1rem'
  },
  bodyCell: {
    background: 'white',
    padding: '4px 56px 4px 24px'
  },
  sticky: { position: 'sticky' },
  hiZIndex: { zIndex: 3 },
  medZIndex: { zIndex: 2 },
  loZIndex: { zIndex: 1 }
};

export default styles;
