import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CheckedIcon from '@material-ui/icons/CheckCircleOutline';
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { withStyles } from '@material-ui/core/styles';
import palette from '../../../styles/palette';

const checkboxStyles = _theme => ({
  root: {
    '&$checked': {
      color: 'var(--checked-color)'
    }
  },
  checked: {}
});

function CustomCheckbox({ error, ...props }) {
  return <Checkbox
    icon={<UncheckedIcon />}
    checkedIcon={<CheckedIcon />}
    color='default'
    style={{ '--checked-color': error ? palette.error : palette.success }}
    {...props}
  />;
}

CustomCheckbox.propTypes = {
  error: PropTypes.bool
};

export default withStyles(checkboxStyles)(CustomCheckbox);
