/* This component will be used to calculate and show 
 * deceleration rates for a given motion, so that internal users
 * can validate our calculations before officially moving them to 
 * the backend. Only users that are logged in that are in a hard-coded
 * whitelist will be able to view this table.
*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import log from 'js-logger';
import { find, keyBy, maxBy } from 'lodash';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import { GetAnalyzedSwingData } from '../../network/motionRequests';
import motionTypesConstants from '../../constants/motionTypes.constants';
import palette from '../../styles/palette';
import { getUserId } from '../../utils/auth.utils';

const BODY_SEGMENTS = ['pelvis', 'torso', 'upperArm', 'hand'];
const USER_ID_WHITELIST = [
  '5e69583c9d4c1d0dafaadcb5', // Logan, NYEB
  '5d83b2dfc129250d4a62f4f3', // newyorkempirebaseball@k-vest.com
  '5c9ba6c1c9e28f110042562c', // elitebaseballtraining@k-vest.com
  '5c37ed9decebe756178e676d', // cloud@k-vest.com,
  '5d5edd655ee8210c429261d8', // orioles@k-vest.com
  '5e33315ceb8bff0ed862a2ce', // kcrawford@orioles.com 
  '5ed5a4e2e353410ce5c4b5fe'  // perfectgame@k-vest.com
];
const SHOW_DECEL = USER_ID_WHITELIST.includes(getUserId());

function calculateAvgDecel(
  segment, 
  maxSpeed, 
  maxSpeedFrameNumber, 
  contactFrameNumber, 
  analyzedFrames
) {
  if (contactFrameNumber === maxSpeedFrameNumber) return 0;

  let contactFrame = find(analyzedFrames, x => x.frameNumber === contactFrameNumber);
  if (contactFrame == null) return null;

  let contactSpeed = contactFrame[segment + 'RotationalVelocity'];
  if (contactSpeed == null || maxSpeed == null) return null;
  let timeElapsed = (contactFrameNumber - maxSpeedFrameNumber) / 200;
  const avgDecel = -1 * (contactSpeed - maxSpeed) / timeElapsed;

  return Math.max(0, Math.round(avgDecel));
}

function calculateMaxDecel(segment, maxSpeedFrame, contactFrame, analyzedFrames) {
  const rotationalVelocityKey = segment + 'RotationalVelocity';
  const framesByNumber = keyBy(analyzedFrames, 'frameNumber');
  let maxDecel = 0;
  let maxDecelFrame = 0;
  let maxInterpolatedDecel = 0;
  let maxInterpolatedDecelFrame = 0;

  for(let i = maxSpeedFrame; i < contactFrame; i++) {
    let prevFrame = framesByNumber[i - 1];
    let curFrame = framesByNumber[i];
    let nextFrame = framesByNumber[i + 1];
    if (prevFrame == null || curFrame == null || nextFrame == null) continue;
    let prevSpeed = prevFrame[rotationalVelocityKey];
    let curSpeed = curFrame[rotationalVelocityKey];
    let nextSpeed = nextFrame[rotationalVelocityKey];
    if (prevSpeed == null || curSpeed == null || nextSpeed == null) continue;
    let decel = (-1 * (nextSpeed - curSpeed) / (1 / 200));
    let interpolatedDecel = (-1 * (nextSpeed - prevSpeed) / (2 / 200)); // two frames elapsed
    if (decel > maxDecel) {
      maxDecel = decel;
      maxDecelFrame = i;
    }
    if (interpolatedDecel > maxInterpolatedDecel) {
      maxInterpolatedDecel = interpolatedDecel;
      maxInterpolatedDecelFrame = i;
    }
  }
  return { 
    max: Math.round(maxDecel), 
    interpolatedMax: Math.round(maxInterpolatedDecel),
    frame: maxDecelFrame,
    interpolatedFrame: maxInterpolatedDecelFrame
  };
}

function calculateMaxSpeeds(heelStrikeFrame, contactFrame, analyzedFrames) {
  const trimmedFrames = analyzedFrames.filter(x => 
    x.frameNumber >= heelStrikeFrame && x.frameNumber <= contactFrame);
  
  const maxFrames = BODY_SEGMENTS.reduce((acc, segment) => {
    const rotationalVeloKey = segment + 'RotationalVelocity';
    const maxFrame = maxBy(trimmedFrames, rotationalVeloKey);
    return { ...acc, [segment]: {
      maxSpeedFrame: maxFrame.frameNumber, 
      maxSpeed: maxFrame[rotationalVeloKey] 
    }};
  }, {});

  return maxFrames;
}

function calculateDecelerationMetrics(motion, analyzedFrames) {
  if (motion == null || motion.fullMotionMetrics == null || analyzedFrames == null) {
    return {};
  }
  if (motion.motionType !== motionTypesConstants.baseball) return {};
  const { fullMotionMetrics } = motion;
  const { heelStrikeFrame, contactFrame, startFrame } = fullMotionMetrics;
  const maxSpeeds = calculateMaxSpeeds(heelStrikeFrame, contactFrame, analyzedFrames);

  let data = {};
  BODY_SEGMENTS.forEach(segment => {
    const { maxSpeedFrame, maxSpeed } = maxSpeeds[segment];
    data[segment] = {
      ...calculateMaxDecel(segment, maxSpeedFrame, contactFrame, analyzedFrames),
      average: calculateAvgDecel(segment, maxSpeed, maxSpeedFrame, contactFrame, analyzedFrames)
    };
    data[segment].time = (data[segment].frame - startFrame) * 1000 / 200; // convert frame to ms
    data[segment].interpolatedTime = (data[segment].interpolatedFrame - startFrame) * 1000 / 200;
  });
  return data;
}

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: palette.lightBlue
    }
  }
}))(TableRow);

function DecelerationTableContents({ motion }) {
  const [data, setData] = useState({});
  useEffect(() => {
    if (motion == null) return;
    let token = axios.CancelToken.source();
    async function fetchAndCalculate() {
      try {
        const analyzedData = await GetAnalyzedSwingData(motion.id, token);
        setData(calculateDecelerationMetrics(motion, analyzedData));
      } catch (e) {
        if (!axios.isCancel(e)) {
          log.error(e, 'Error fetching timeseries data for ' + motion.id);
        }
      }
    }
    fetchAndCalculate();

    return token.cancel;
  }, [motion]);

  return <Paper>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Deceleration</TableCell>
          <TableCell>Pelvis</TableCell>
          <TableCell>Torso</TableCell>
          <TableCell>Arm</TableCell>
          <TableCell>Hand</TableCell>
          <TableCell>Pelvis + Torso Avg</TableCell>
          <TableCell>All Segments Avg</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <StyledTableRow>
          <TableCell>Average</TableCell>
          {BODY_SEGMENTS.map(segment => <TableCell key={'avg-' + segment}>
            {data[segment] && data[segment].average}
          </TableCell>)}
          {data.pelvis && data.torso && <TableCell>
            {Math.round((data.pelvis.average + data.torso.average) / 2)}
          </TableCell>}
          {data.pelvis && data.torso && data.upperArm && data.hand && <TableCell>
            {Math.round((data.pelvis.average + 
              data.torso.average + 
              data.upperArm.average + 
              data.hand.average) / 4)}
          </TableCell>}
        </StyledTableRow>

        <StyledTableRow>
          <TableCell>Max</TableCell>
          {BODY_SEGMENTS.map(segment => <TableCell key={'max-' + segment}>
            {data[segment] && data[segment].max}
          </TableCell>)}
          {data.pelvis && data.torso && <TableCell>
            {Math.round((data.pelvis.max + data.torso.max) / 2)}
          </TableCell>}
          {data.pelvis && data.torso && data.upperArm && data.hand && <TableCell>
            {Math.round((data.pelvis.max + 
              data.torso.max + 
              data.upperArm.max + 
              data.hand.max) / 4)}
          </TableCell>}
        </StyledTableRow>

        <StyledTableRow>
          <TableCell>Interpolated Max</TableCell>
          {BODY_SEGMENTS.map(segment => <TableCell key={'interp-max-' + segment}>
            {data[segment] && data[segment].interpolatedMax}
          </TableCell>)}
          {data.pelvis && data.torso && <TableCell>
            {Math.round((data.pelvis.interpolatedMax + data.torso.interpolatedMax) / 2)}
          </TableCell>}
          {data.pelvis && data.torso && data.upperArm && data.hand && <TableCell>
            {Math.round((data.pelvis.interpolatedMax + 
              data.torso.interpolatedMax + 
              data.upperArm.interpolatedMax + 
              data.hand.interpolatedMax) / 4)}
          </TableCell>}
        </StyledTableRow>
        <StyledTableRow>
          <TableCell>Max Frame</TableCell>
          {BODY_SEGMENTS.map(segment => <TableCell key={'frame-' + segment}>
            {data[segment] && data[segment].frame}
          </TableCell>)}
        </StyledTableRow>
        <StyledTableRow>
          <TableCell>Interpolated Max Frame</TableCell>
          {BODY_SEGMENTS.map(segment => <TableCell key={'interp-frame-' + segment}>
            {data[segment] && data[segment].interpolatedFrame}
          </TableCell>)}
        </StyledTableRow>


        <StyledTableRow>
          <TableCell>Max Time</TableCell>
          {BODY_SEGMENTS.map(segment => <TableCell key={'time-' + segment}>
            {data[segment] && data[segment].time}ms
          </TableCell>)}
        </StyledTableRow>
        <StyledTableRow>
          <TableCell>Interpolated Max Time</TableCell>
          {BODY_SEGMENTS.map(segment => <TableCell key={'interp-time-' + segment}>
            {data[segment] && data[segment].interpolatedTime}ms
          </TableCell>)}
        </StyledTableRow>
      </TableBody>
    </Table>
  </Paper>;
}

function DecelerationTable(props) {
  return SHOW_DECEL && <DecelerationTableContents {...props} />; 
}

export default DecelerationTable;
