import React from 'react';

import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  withStyles
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { formatDate } from '../../utils/formatting.utils';

import Styles from './styles';

class ClientActivationTable extends React.Component {
  state = { focusedId: null }

  handleToggle = (e, idx) => {
    e.stopPropagation();
    this.props.toggle(idx);
  }

  handleRowFocus(id) {
    var focusedId = id === this.state.focusedId ? null : id;
    this.setState({ focusedId });
  }

  render() {
    const { data, classes } = this.props; 

    return (
      <div>
        <ExpansionPanel expanded={false}>
          <ExpansionPanelSummary expandIcon={null}>
            <Typography variant='h6' className={classes.checkboxCell}>Activate</Typography>
            <Typography variant='h6' className={classes.textCell}>Created At</Typography>
            <Typography variant='h6' className={classes.textCell}>Machine Owner</Typography>
          </ExpansionPanelSummary>
        </ExpansionPanel>
        {data.map((activationRequest, idx) => 
          <ExpansionPanel key={activationRequest.clientId}
            expanded={this.state.focusedId === activationRequest.clientId}
            onClick={() => this.handleRowFocus(activationRequest.clientId)}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <div className={classes.checkboxCell}>
                <Checkbox
                  checked={activationRequest.active}
                  onClick={(e) => this.handleToggle(e, idx)}
                  color='primary'
                />
              </div>
              <Typography className={classes.textCell}>
                {formatDate(activationRequest.timestamp)}
              </Typography>
              <Typography className={classes.textCell}>{activationRequest.fullName}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant='caption'>
                Machine registered from <b>{activationRequest.city}, {activationRequest.state}</b>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel> 
        )}
      </div>
    );
  }
}

export default withStyles(Styles)(ClientActivationTable);