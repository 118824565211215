export const CaptureEventResultTypes = {
  automatic: 'Automatic',
  manual: 'Manual'
};

export const CaptureEventResultAvailabilityStatus = {
  available: 'Available',
  notAvailable: 'NotAvailable'
};


export const CaptureEventResultAvailabilityStatusPrettyName = {
  Available: 'Available',
  NotAvailable: 'NotAvailable'
};