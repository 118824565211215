import React, { useState, useEffect } from 'react';
import useStyles from './styles';

import { Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';

import { GetAssessmentWithMotion, GetAssessments } from '../../../../../network/kAssessmentRequests';

import AssessmentView from '../assessmentView';
import StickyHeader from '../../assessmentStickyHeader';
import CoachHeader from '../../assessmentHeaderContent/coachHeader';

import { convertSectionsToObject, convertLabelsToObject } from '../../../../../utils/kAssessments.utils.js';
import { formatDateWithSeconds } from '../../../../../utils/formatting.utils';

import Logger from 'js-logger';


function CoachView({
  orgId,
  userId,
  topPadding,
  startAssessment
}) {

  const classes = useStyles({ topPadding });

  const [assessments, setAssessments] = useState(null);
  const [idx, setIdx] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [motionData, setMotionData] = useState(null);
  
  // loading states
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isLoadingOne, setIsLoadingOne] = useState(false);

  // navigation and features
  const [selectedLabel, setSelectedLabel] = useState(null);


  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (orgId && userId) {
      const getAssessments = async () => {
        setIsLoadingAll(true);
        try {
          const assessments = await GetAssessments(userId, orgId, cancelToken);
          setAssessments(assessments);
          setIsLoadingAll(false);
        } catch (e) {
          if (!axios.isCancel(e)) {
            Logger.error('Error with listing assessments', e);
            setAssessments(null);
            setIsLoadingAll(false);
          }
        }
      };
      getAssessments();
    }
    return cancelToken.cancel;
  }, [userId, orgId]);

  useEffect(() => {
    setIdx(!assessments || !assessments.length ? null : 0);
  }, [assessments]);

  useEffect(() => {
    if (idx === null || assessments === null || !assessments.length) {
      return;
    }

    const cancelToken = axios.CancelToken.source();

    // get assessment at the idx specificed
    const getAssessment = async (id) => {
      
      // when we get a new assessment, unload data from last assessment
      setIsLoadingOne(true);
      setAssessment(null);
      setMotionData(null);

      try {
        const data = await GetAssessmentWithMotion(id, cancelToken);
        setIsLoadingOne(false);
        if (!data) {
          return;
        }
  
        const { assessment, motionData } = data;
        setAssessment(assessment);
        setMotionData(motionData);
      } catch (e) {
        if (!axios.isCancel(e)) {
          Logger.error('Error with loading a published assessment', e);
        }
      }
    };

    getAssessment(assessments[idx].id);

    return cancelToken.cancel;

  }, [idx, assessments]);

  // if param is false, then it goes toNext
  const setNewIdx = (toPrevious) => {
    let newIdx;
    if (toPrevious) {
      newIdx = Math.max(idx - 1, 0);
    } else {
      newIdx = Math.min(idx + 1, assessments.length - 1);
    }
    setIdx(newIdx);
  };

  const onLabelSelected = (label) => {
    setSelectedLabel(Object.assign({}, label));
  };

  // vars to help display certain information
  const noAssessments = Boolean(assessments !== null && !assessments.length);
  const displayAssessments = Boolean(assessments !== null && assessments.length);
  const disableButton = Boolean(!assessments || !assessments.length || isLoadingOne);

  // convert stats
  const formattedLabels = assessment ? convertLabelsToObject(assessment.labels) : {};
  const formattedSections = assessment ? convertSectionsToObject(assessment.sections) : {};

  // title for sticky header
  const assessmentTitle = (() => {
    if (isLoadingAll || isLoadingOne) return 'Loading...';
    if (!assessment) return 'No Assessment Selected';
    return 'Assessment from ' + formatDateWithSeconds(assessment.creationTimestamp);
  })();

  return <div>
    <StickyHeader 
      labels={formattedLabels} 
      label={selectedLabel}
      showSave={false}
      motionData={motionData}
      userId={userId} >
      <CoachHeader 
        title={assessmentTitle} 
        onCreateAssessment={startAssessment} />
    </StickyHeader>

    <div className={classes.viewMargin}>
      {
      // put up loading symbol
        (assessments === null || isLoadingOne) &&
            <CircularProgress 
              className={classes.circularProgress} 
              size={45}
            />
      }

      {
      // assessment array is loaded
      // display buttons no matter what
        assessments !== null &&
            <div className={classes.chooseIdContainer}>
              <Button
                disabled={disableButton || idx <= 0}
                onClick={() => {setNewIdx(true);}}>Previous</Button>
              <Button
                disabled={disableButton || idx >= assessments.length - 1}
                onClick={() => {setNewIdx(false);}}>Next</Button>
            </div>
      }

      {
        noAssessments && !isLoadingAll &&
            <div>
                There are no assessments to view for this player at the moment.
            </div>
      }

      {
        displayAssessments && !isLoadingOne && assessment &&
            <AssessmentView 
              motionId={assessment.motionId}
              sections={formattedSections}
              labels={formattedLabels}
              userId={userId}
              onLabelSelected={onLabelSelected}
              editable={false}
              motionData={motionData}
            />
      }
    </div>

  </div>;
}
  
export default CoachView;