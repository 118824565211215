import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './styles';

import axios from 'axios';
import Logger from 'js-logger';
import classNames from 'classnames';
import { sortBy, some } from 'lodash';

import { 
  Paper, 
  IconButton, 
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import Clear from '@material-ui/icons/Clear';

import { GetTemplates } from '../../../network/noteTemplateRequests';
import SearchBar from '../../../components/searchBar';
import TemplateCreationDialog from '../templateCreationDialog';
import TemplateItem from './templateItem';


function TemplateDrawer({ 
  open = false,
  fullScreen = false,
  width,
  onClose = () => {},
  onTemplateSelected = () => {}
}) {
  const classes = useStyles({ fullScreen, width });
  const [searchText, setSearchText] = useState('');
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(false);

  // dialog states
  const [dialogOpen, setDialogOpen] = useState({ open: false, data: null });

  const orgId = useSelector(state => state.currentOrganization.id);

  const templateSubmitted = (template, updatingTemplate = false) => {
    if (updatingTemplate) {
      setTemplates(
        prevTemplates => sortBy(
          prevTemplates.map(t => t.id === template.id ? template : t), 
          t => t.title.toLowerCase())
      );
    } else {
      setTemplates(
        prevTemplates => sortBy([...prevTemplates, template], t => t.title.toLowerCase())
      );
    }
  };

  const templateDeleted = id => {
    setTemplates(prev => prev.filter(template => template.id !== id));
  };

  useEffect(() => {
    const lowerSearchText = searchText.trim().toLowerCase();

    if (!lowerSearchText) {
      setFilteredTemplates(templates);
      return;
    }

    // we should filter if there is something in the search bar
    const filtered = templates.filter(({ title, text, noteTemplateVideos = [] }) => {
      if (title.toLowerCase().includes(lowerSearchText)) return true;
      if (text.toLowerCase().includes(lowerSearchText)) return true;
      return some(noteTemplateVideos, v => v.video.title.toLowerCase().includes(lowerSearchText));
    });
    setFilteredTemplates(filtered);
  }, [searchText, templates]);

  useEffect(() => {
    if (orgId && open) {
      const cancelToken = axios.CancelToken.source();
      const getTemplates = async () => {
        try {
          setTemplatesLoading(true);
          const templates = await GetTemplates(orgId, cancelToken);
          setTemplatesLoading(false);
          setTemplates(sortBy(templates, t => t.title.toLowerCase()));
        } catch (e) {
          if (!axios.isCancel(e)) {
            Logger.error('Error getting list of templates in action plan', e);
            setTemplatesLoading(false);
          }
        }
      };
      getTemplates();
      return cancelToken.cancel;
    }
  }, [open, orgId]);

  return <>
    {open &&
    <Paper 
      className={
        classNames(
          classes.container, 
          { [classes.isFull]: fullScreen }, 
          { [classes.isMinimized]: !fullScreen }
        )
      }
      elevation={fullScreen ? 0 : 3}
    >
      <div className={classes.templateContainer}>
        <div className={classes.buttons}>
          <Button 
            className={classes.addTemplateAction}
            onClick={() => setDialogOpen({ open: true })}
          >
            <AddCircle className={classes.addIcon}/>
            <Typography>Add New Template</Typography>
          </Button>
          <IconButton onClick={onClose}>
            <Clear />
          </IconButton>
        </div>

        <div className={classes.searchContainer}>
          <SearchBar
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onClear={() => setSearchText('')}
          />
        </div>

        <div className={classes.templateList}>
          {templatesLoading &&
          <div className={classes.loadingContainer}>
            <CircularProgress size={45}/>
          </div>}

          {!templatesLoading && filteredTemplates.length === 0 &&
          <div className={classes.loadingContainer}>There are no templates saved</div>}

          {!templatesLoading && filteredTemplates.length > 0 &&
            filteredTemplates.map((template, idx) => {
              return <TemplateItem 
                key={idx} 
                data={template}
                onClick={onTemplateSelected}
                onEditClick={data => setDialogOpen({ open: true, data })}
              />;
            })}
        </div>

      </div>
    </Paper>}
    <TemplateCreationDialog
      open={dialogOpen.open}
      existingTemplate={dialogOpen.data}
      onClose={() => setDialogOpen({ open: false })}
      onSubmitted={templateSubmitted}
      onDeleted={templateDeleted}
    />
  </>;
};

export default TemplateDrawer;