import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import Palette from 'styles/palette';

export const getBackgroundColorForPercentile = (value, markLow = false) => {
  const { percentileRankingColors } = Palette;

  if (markLow) return percentileRankingColors.low; 
  if (value == null || value < 0) return Palette.white;

  const LOW_CUTOFF = 39;
  const HIGH_CUTOFF = 69;

  if (value >= 0 && value <= LOW_CUTOFF) {
    return percentileRankingColors.low;
  }

  if (value <= HIGH_CUTOFF) {
    return percentileRankingColors.average;
  }

  return percentileRankingColors.high;
};

function MetricBox({ value, units, top, color, percentile }) {
  const classes = useStyles({ top, color });
  return <Box className={classes.metricBoxContainer}>
    <div className={classes.textContainer}>
      <Typography variant='h3' textAlign='center'>
        {value != null ? Math.round(value) : '-'}
        {percentile && '%'}
      </Typography>
      {units && <Typography className={classes.units}>{units}</Typography>}
    </div>
  </Box>;
}

function MetricsColumn({ 
  playerPercentiles = {}, 
  ballspeedMph,
  header 
}) {
  const classes = useStyles();
  const {
    pelvisSpeed,
    torsoSpeed,
    upperArmSpeed,
    handSpeed,
    ballSpeed
  } = playerPercentiles;

  return <div className={classes.columnContainer}>
    {header}
    <MetricBox 
      value={ballspeedMph} 
      units={'MPH'} 
      top='5%' 
      color={getBackgroundColorForPercentile(ballSpeed)} 
    />
    <MetricBox 
      value={handSpeed} 
      top='25%' 
      color={getBackgroundColorForPercentile(handSpeed)} 
      percentile
    />
    <MetricBox 
      value={upperArmSpeed} 
      top='45%' 
      color={getBackgroundColorForPercentile(upperArmSpeed)} 
      percentile
    />
    <MetricBox 
      value={torsoSpeed} 
      top='65%' 
      color={getBackgroundColorForPercentile(torsoSpeed)} 
      percentile
    />
    <MetricBox 
      value={pelvisSpeed} 
      top='85%' 
      color={getBackgroundColorForPercentile(pelvisSpeed)} 
      percentile
    />
  </div>;
}

export default MetricsColumn;