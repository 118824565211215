import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(_ => ({
  storeImg: {
    width: '100%',
    maxWidth: '250px'
  },
  storesContainer: {
    marginTop: '15px',
    marginBottom: '15px',
    justifyContent: 'space-evenly'
  },
  img: {
    height: '35px',
    marginLeft: '35px'
  },
  firstStep: {
    display: 'inline-flex'
  }
}));