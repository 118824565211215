import React, { useState } from 'react';
import { Typography, Divider, Grid, Dialog, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { history } from '../../../../store';
import { Logout, hasOrgAdminRole } from '../../../../utils/auth.utils';
import useStyles from './styles';

const FilterByPanelMobile = () => {
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();

  const organizationManagementClick = () => {
    setShowDialog(false);
    history.push('/player-development/organization-management');
  };

  const drillVideosClick = () => {
    setShowDialog(false);
    history.push('/player-development/drill-videos');
  };
  
  return (
    <Grid 
      container 
      className={classes.container} 
      spacing={2} 
      direction='column' 
      justify='center' 
      alignItems='center'
    >
      <Grid 
        item 
        container 
        direction='row' 
        justify='flex-start' 
        onClick={() => history.push('/player-development/pending-clients')}
      >
        <Grid item>
          <Divider orientation='vertical' className={classes.pendingClientDivider}/>
        </Grid> 
        <Grid item>
          <Typography className={classes.mobileText}>
            Pending <br/> Players
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction='row' justify='flex-start' onClick={() => setShowDialog(true)}>
        <Grid item>
          <Divider orientation='vertical' className={classes.settingsDivider}/>
        </Grid>
        <Grid item>
          <Typography className={classes.mobileText}>Settings</Typography>
        </Grid>
      </Grid>
      <Dialog open={showDialog} fullScreen className={classes.dialogContainer}>
        <Grid container direction='column' className={classes.dialogContents} alignItems='flex-start' spacing={2}>
          <Grid container direction='row' alignItems='center' justify='space-between' className={classes.closeRow}>
            <Typography variant='caption'>Settings</Typography>
            <CloseIcon onClick={() => setShowDialog(false)}/>
          </Grid>
          {hasOrgAdminRole() && 
            <Grid item>
              <Button onClick={organizationManagementClick}>Organization Management</Button>
            </Grid>
          }
          <Grid item>
            <Button onClick={drillVideosClick}>Upload Drill Videos</Button>
          </Grid>        
          <Grid item>
            <Button onClick={() => Logout()}>Log out</Button>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default FilterByPanelMobile;