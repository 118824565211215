import keyBy from 'lodash/keyBy';
import { 
  FULL_MOTION_METRICS, 
  KEY_FRAME_DATA, 
  LAUNCH_MONITOR 
} from '../../../constants/motionSubresources.constants';
import MotionUtils from '../../../utils/motion.utils';
import { 
  MAX_SPEED_KEYS, PEAK_SPEED_KEYS 
} from '../../playerDataTabs/playerPercentiles/playerPercentiles.constants';
import { 
  calculatePercentileTableData 
} from '../../playerDataTabs/playerPercentiles/playerPercentiles.utils';
import { 
  KEY_FRAME_POSITIONS, 
  MAX_SPEEDS_PERCENTILES_KEY, 
  PEAK_SPEEDS_PERCENTILES_KEY, 
  PLAYER_WEIGHT 
} from '../dataAnalysis.constants';

export const MOTION_REQUEST_PARAMS = { 
  subresources: [LAUNCH_MONITOR, FULL_MOTION_METRICS, KEY_FRAME_DATA] 
};

// returns a new copy of motionMap with percentiles added
export const addPercentilesAndKeyFramePositionsToMotions = async (
  motionsMap, // an object where each key is the motion id and value is the motion
  playerWeight // parsed float of a players weight
) => {
  const motions = Object.values(motionsMap);
  const peakSpeedPercentiles = await calculatePercentileTableData(
    motions, 
    playerWeight, 
    PEAK_SPEED_KEYS);
  const maxSpeedPercentiles = await calculatePercentileTableData(
    motions, 
    playerWeight, 
    MAX_SPEED_KEYS);
  
  const peakSpeedPercentileMap = keyBy(peakSpeedPercentiles, 'motionId');
  const maxSpeedPercentileMap = keyBy(maxSpeedPercentiles, 'motionId');

  const motionsWithPercentiles = { ...motionsMap };
  Object.keys(motionsMap).forEach(motionId => {
    const motion = motionsMap[motionId];
    const keyFramePositions = MotionUtils.constructKeyFramePositionsMap(motion[KEY_FRAME_DATA]);
    motionsWithPercentiles[motionId][KEY_FRAME_POSITIONS] = keyFramePositions;

    motionsWithPercentiles[motionId][PLAYER_WEIGHT] = playerWeight;
    motionsWithPercentiles[motionId][PEAK_SPEEDS_PERCENTILES_KEY] = 
      peakSpeedPercentileMap[motionId]?.percentiles ?? {};
    motionsWithPercentiles[motionId][MAX_SPEEDS_PERCENTILES_KEY] = 
      maxSpeedPercentileMap[motionId]?.percentiles ?? {};
  });
  return motionsWithPercentiles;
};
