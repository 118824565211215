import React from 'react';
import { history } from '../../store';
import { Typography, Button, withStyles, Grid } from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';

import Styles from './styles';

class Forbidden extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container direction='column' alignItems='center' spacing={1}>
        <Grid item>
          <MoodBad className={classes.icon} />
        </Grid>
        <Grid item>
          <Typography variant='h4'>Uh oh! Looks like you aren't allowed to access this page.</Typography>
        </Grid>
        <Grid item>
          <Button color='primary' variant='contained' onClick={()=>history.push('/')}>
            Take me home
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(Styles)(Forbidden);