import React from 'react';
import { PropTypes } from 'prop-types';
import {
  CircularProgress,
  Grid,
  Popover
} from '@material-ui/core';

import SelectorWithDate from '../selectorWithDate';
import { withStyles } from '@material-ui/core/styles';
import Styles from './styles';
import classNames from 'classnames';
import StickyTable from '../stickyTable';
import ProgressButton from '../progressButton';
import ErrorSnackbar from '../errorSnackbar';

class DonwloadMotions extends React.Component {    
  constructor(props) {
    super(props);
    
    this.state = {
      tab: 0, 
      anchorElement: null,
      focusedSwingId: null
    };
  }

  buildSummariesWithDetailButton = () => {
    return this.props.summaries.map((summary) => {
      return {
        ...summary,
        detailButton: (
          <div>      
            <Popover id='detail-popup'
              open={this.state.anchorElement != null}
              anchorEl={this.state.anchorElement}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'center', horizontal: 'right' }}
              onClose={this._detailPopupCloseHandler}
            >             
            </Popover>
          </div>
        )
      };
    });
  }

  buildInfoListWithDetailButton = () => {
    return this.props.summaryUtils.list.concat({
      key: 'detailButton',
      displayName: '',
      formatter: (item) => item
    });
  }

  renderData(isLoading) {
    if (isLoading) {
      return (
        <div className={this.props.classes.spinnerContainer}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <StickyTable 
        leftStickyColumnCount={3} 
        rightStickyColumnCount={1} 
        data={this.buildSummariesWithDetailButton()} 
        infoList={this.buildInfoListWithDetailButton()}/>
    );
    
  }

  render() {
    const { 
      classes, 
      filter,
      showDownloadMotions,
      showDownloadSummaries,
      handleFilterChange,
      handleDateChange, 
      submitDateRange,
      errorMsg,
      onSnackbarClose,
      downloadSummariesCsv,
      summaries,
      downloadingMotions,
      downloadMotionCsvs,
      loadingData
    } = this.props;

    return (
      <div >
        <ErrorSnackbar 
          message={errorMsg}
          onClose={onSnackbarClose}
        />
        <Grid container className={classes.headerContainer}>
          <Grid item className={classes.fullHeight}>
            <Grid container 
              spacing={2} 
              className={classNames(classes.fullHeight, classes.rightActionContainer)}
            >
              <Grid item className={classes.fullHeight} style={{ display: 'flex' }}>
                <SelectorWithDate
                  name='summary-filter'
                  value={filter.value}
                  fromDate={filter.fromDate}
                  toDate={filter.toDate}
                  handleDateChange={handleDateChange}
                  submitDateRange={submitDateRange}
                  handleFilterChange={handleFilterChange}
                  dropdownValues={[
                    { value: 5, displayName: 'Last 5 Swings' },
                    { value: 10, displayName: 'Last 10 Swings' },
                    { value: 15, displayName: 'Last 15 Swings' }
                  ]}
                />
              </Grid>

              {showDownloadSummaries &&
                <Grid item>
                  <ProgressButton
                    showProgress={false}
                    onClick={downloadSummariesCsv}
                    variant='contained'
                    className={classes.downloadBtn}
                    disabled={summaries.length === 0}
                  >
                    Download Summary CSV
                  </ProgressButton>
                </Grid>
              }

              {showDownloadMotions && <Grid item>
                <ProgressButton 
                  showProgress={downloadingMotions}
                  onClick={downloadMotionCsvs}
                  variant='contained'
                  className={classes.downloadBtn}
                  disabled={summaries.length === 0}
                >
                  Download Motion CSVs
                </ProgressButton>
              </Grid>}
            </Grid>
          </Grid>
        </Grid>
        
        <div className={classes.tableContainer}>
          {this.renderData(loadingData)}
        </div>
      </div>
    );
  }
}

DonwloadMotions.propTypes = {
  loadingData: PropTypes.bool.isRequired,
  showDownloadMotions: PropTypes.bool.isRequired,
  showDownloadSummaries: PropTypes.bool.isRequired,
  summaries: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired, 
  submitDateRange: PropTypes.func.isRequired,
  summaryUtils: PropTypes.object.isRequired,
  downloadMotionCsvs: PropTypes.func,
  downloadSummariesCsv: PropTypes.func,
  downloadingMotions: PropTypes.bool,
  deleteMotion: PropTypes.func.isRequired,
  onSnackbarClose: PropTypes.func.isRequired,
  errorMsg: PropTypes.string
};

export default withStyles(Styles)(DonwloadMotions);