import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  container: {
    padding: '50px'
  },
  content: {
    marginTop: '30px'
  },
  plusIcon: {
    alignSelf: 'start',
    display: 'flex',
    justifyContent: 'center'
  }
});