import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

const EditingTrainingPlanWeeklyNotes = ({ setTrainingPlan, trainingPlan }) => {
  const classes = useStyles();
  const existingNotes = trainingPlan?.notes ?? [];
  
  const updateNote = (index, newText) => {
    setTrainingPlan( prev => { 
      const notes = prev?.notes?.map((currentNote, currentIndex) => {
        if (currentIndex === index) {
          return { ...currentNote, text: newText };
        } else {
          return currentNote;
        }
      });
      return { ...prev, notes };
    });
  };
  
  return existingNotes.map( (note, index) => <Grid 
    xs={12}
    key={`note-edit-${note?.id}`} 
    item className={classes.row}
  >
    <Typography variant='h6'>Week {index + 1}</Typography>
    <TextareaAutosize
      className={classes.textarea}
      onChange={({ target }) => {
        const { value } = target;
        updateNote(index, value);
      }}
      aria-label='minimum height'
      minRows={3}
      value={note.text} 
    />
  </Grid>);
};

export default EditingTrainingPlanWeeklyNotes;