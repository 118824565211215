import React from 'react';
import { Button } from '@material-ui/core';
import sortBy from 'lodash/sortBy';
import useMotionsWithTag from '../useMotionsWithTag';
import { CSV_COLUMNS } from './export.utils';

function ExportButton(props) {
  const motions = useMotionsWithTag({});

  const onExportClick = () => {
    const headers = CSV_COLUMNS.map(x => x.label);
    const sortedMotions = sortBy(Object.values(motions), 'timestamp');
    const rows = sortedMotions
      .map(motion => CSV_COLUMNS.map(({ getValue }) => getValue(motion)));
    const fileContents = [headers, ...rows].map(row => row.join(',')).join('\n');

    var element = document.createElement('a');
    element.setAttribute(
      'href', 
      'data:text/csv;charset=utf-8,' + encodeURIComponent(fileContents));
    element.setAttribute('download', 'Data_Analysis.csv');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return <Button
    onClick={onExportClick} 
    color='secondary' 
    variant='outlined'
    {...props}
  >
    Export CSV
  </Button>;
}

export default ExportButton;
