import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 30
  },
  compactFormField: {
    marginTop: 5,
    marginBottom: 10
  },
  formField: {
    marginTop: 20
  },
  submitBtn: {
    marginTop: 20
  },
  subheader: {
    marginTop: 30
  },
  textField: {
    width: '80vw',
    maxWidth: 300
  },
  handedness: {
    marginRight: 10
  },
  successText: {
    padding: 5,
    paddingLeft: 10
  },
  loadingText: {
    padding: 5,
    paddingLeft: 15
  },
  height: {
    paddingTop: 16,
    marginRight: 10
  }
}));

export default useStyles;