import palette from '../../styles/palette';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_theme => ({
  body: {
    margin: '0 15px 10px 5px'
  },
  scrollArea: {
    height: '100vh'
  },
  settingsMenu: {
    position: 'absolute', 
    zIndex: 10, 
    right: '2%' 
  },
  document: {
    background: palette.subtleWhite
  }
}));

export default useStyles;
