import { useCallback } from 'react';
import { norm } from 'mathjs';
import { GetAnalyzedSwingData } from '../../../network/motionRequests';
import useNetworkRequest from '../../../network/useNetworkRequest';

export const useMotionSimilarityCalculation = (motionId1, motionId2, key) => {
  const [analyzedData1, loading1, error1] = useAnalyzedData(motionId1);
  const [analyzedData2, loading2, error2] = useAnalyzedData(motionId2);

  const getMetricData = analyzedData => analyzedData?.map(x => x[key]);
  const metricData1 = getMetricData(analyzedData1);
  const metricData2 = getMetricData(analyzedData2);
  const canCalculateSimilarity = metricData1 != null && metricData2 != null;

  const { similarityScore, offset } = canCalculateSimilarity 
    ? MotionSimilarity.computeSimilarity(metricData1, metricData2)
    : {};
  const offsetGraphData = canCalculateSimilarity
    ? MotionSimilarity.offsetGraphableData(metricData1, metricData2, offset)
    : [[], []];

  return {
    loading: loading1 || loading2,
    error: error1 || error2,
    similarityScore,
    offsetGraphData
  };
};

export const useAnalyzedData = motionId => {
  const requestCallback = useCallback(
    async cancelSource => motionId == null 
      ? null 
      : await GetAnalyzedSwingData(motionId, cancelSource),
    [motionId]);
  return useNetworkRequest(null, 'GetAnalyzedData-' + motionId, requestCallback);
};

export class MotionSimilarity {
  static _calculatePadding(offset, len1, len2) {
    const prepad1 = offset < 0 ? -offset : 0;
    const postpad1 = offset > len1 - len2 ? offset + len2 - len1 : 0; 

    const prepad2 = offset < 0 ? 0 : offset;
    const postpad2 = offset < len1 - len2 ? len1 - offset - len2 : 0;
    
    return { prepad1, postpad1, prepad2, postpad2 };
  }

  static _getPaddedArrays(array1, array2, offset) {
    const len1 = array1.length;
    const len2 = array2.length;
    const { 
      prepad1, 
      postpad1, 
      prepad2, 
      postpad2 
    } = this._calculatePadding(offset, len1, len2);

    const zeros = n => new Array(n).fill(0);

    // zero pad each array
    const array1Padded = zeros(prepad1).concat(array1).concat(zeros(postpad1));
    const array2Padded = zeros(prepad2).concat(array2).concat(zeros(postpad2));
    
    return [array1Padded, array2Padded];
  }

  static _orderArraysByLength(array1, array2) {
    // swap arrays, if needed, such that array1 is always the longer array
    let longer = array1;
    let shorter = array2;
    if (longer.length < shorter.length) {
      longer = array2;
      shorter = array1;
    }

    return [longer, shorter];
  }

  // This calculates a similarity score (0 to 1) from two lists of numbers by finding
  // the optimal amount to shift the arrays relative to eachother to minimize their differences.
  // This is useful for comparing a specific timeseries metric from two motions.
  static computeSimilarity(data1, data2) {
    const [array1, array2] = this._orderArraysByLength(data1, data2);

    const len1 = array1.length;
    const len2 = array2.length;

    const normArray = [];
    for (let offset = -len2; offset < len1 + 1; offset++) {
      const [array1Padded, array2Padded] = this._getPaddedArrays(array1, array2, offset);

      const paddedDifference = array1Padded.map((x, idx) => x - array2Padded[idx]);
      normArray.push(norm(paddedDifference));
    }

    const array1Norm = norm(array1);
    const array2Norm = norm(array2);

    const similarityScoreNumerator = array1Norm + array2Norm - Math.min(...normArray);
    const similarityScoreDenominator = array1Norm + array2Norm;
    const similarityScore = similarityScoreNumerator / similarityScoreDenominator;

    const argmin = arr => arr.indexOf(Math.min(...arr));
    // need to subtract len2, since offset starts iterating from a value of -len2
    const offsetWhenAligned = argmin(normArray) - len2;
    return { similarityScore, offset: offsetWhenAligned };
  }

  static offsetGraphableData(data1, data2, offset) {
    const [array1, array2] = this._orderArraysByLength(data1, data2);
    return this._getPaddedArrays(array1, array2, offset);
  }
}
