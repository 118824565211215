import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  holder: {
    flex: 1
  },
  rootDesktop: {
    width: '100%',
    height: '100%'
  },
  rootMobile: {
    width: '130vw',
    height: '100%'
  }

}));

export default styles;