import React, { useState, useEffect, useMemo } from 'react';
import useStyles from './styles';
import axios from 'axios';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button, 
  CircularProgress,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';

import { SyncStatus } from '../../../../constants/captureEvents.constants';
import SyncRecordsTable from './syncRecordsTable';
import ErrorSnackbar from '../../../../components/errorSnackbar';
import ProgressButton from '../../../../components/progressButton';
import ConfirmationDialog from '../../../../components/dialogs/confirmationDialog';
import MergeDialog from './mergeDialog';
import classnames from 'classnames';
import { formatMonthDayTime } from './../../../../utils/formatting.utils';

import { GetSyncAttempt, PatchSyncAttempt } from '../../../../network/captureEventRequests';
import { EventActions } from './../../../captureEvents/captureEventDetails/eventUsersTable/eventActions';
import Logger from 'js-logger';

function NotAvailableMessage({ children }) {
  const classes = useStyles();
  return <span className={classes.notAvailable}>
    { children }
  </span>;
};

const columns = [
  {
    header: 'Motion Timestamp',
    displayFn: ({ motion }, { kCoachTimeAdjustmentHours }) => {
      if (motion) {
        const addedHours = kCoachTimeAdjustmentHours || 0;
        // remove timezone offsets since pocket radar and trackman have no time zone info
        const timestamp = new Date(motion.localTimestamp.slice(0, -6));
        timestamp.setHours(timestamp.getHours() + addedHours);
        return formatMonthDayTime(timestamp);
      } else {
        return <NotAvailableMessage>No Motion Timestamp</NotAvailableMessage>;
      }
    }
  },
  {
    header: 'Serial Code',
    displayFn: ({ motion }) => {
      if (motion) {
        return motion.serialCode;
      } else {
        return <NotAvailableMessage>No Serial Code</NotAvailableMessage>;
      }
    }
  },
  {
    header: 'Radar Timestamp',
    displayFn: ({ rawData }) => {
      if (rawData) {
        // pocket radar does not supply accurate timezone info
        return formatMonthDayTime(rawData.SyncingTimestamp.slice(0, -1));
      } else {
        return <NotAvailableMessage>No Radar Timestamp</NotAvailableMessage>;
      }
    }
  },
  {
    header: 'Ball Speed',
    displayFn: ({ launchMonitorData }) => {
      if (launchMonitorData) {
        return `${launchMonitorData.ballspeedMph} MPH`;
      } else {
        return <NotAvailableMessage>No Radar Data</NotAvailableMessage>;
      }
    }
  }
];

const checkSync = ({ motionId, launchMonitorId }) => motionId != null && launchMonitorId != null;
const filters = [
  {
    name: 'Show All',
    filterFn: () => true
  },
  {
    name: 'Only Show Synced',
    filterFn: record => checkSync(record)
  },
  {
    name: 'Only Show Not Synced',
    filterFn: record => !checkSync(record)
  }
];

function SyncRecordDialog({
  open = false,
  syncAttempt = null,
  onSyncComplete,
  onSyncRejected,
  onClose
}) {
  const classes = useStyles();
  const [syncAttemptWithRecords, setSyncAttemptWithRecords] = useState(null);
  const [recordsLoading, setRecordsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [mergeDialogOpen, setMergeDialogOpen] = useState(false);
  const [updatingSyncStatus, setUpdatingSyncStatus] = useState(false);

  const [confirmSync, setConfirmSync] = useState(false);
  const [confirmReject, setConfirmReject] = useState(false);

  const [filterObj, setFilterObj] = useState(filters[0]);

  const loading = updatingSyncStatus || recordsLoading;

  const reset = () => {
    setSyncAttemptWithRecords(null);
    setError(null);
    setRecordsLoading(false);
    setSelectedRecords([]);
  };

  useEffect(() => {
    // reset information
    if (!syncAttempt) {
      reset();
      return;
    }

    setRecordsLoading(true);
    const cancelToken = axios.CancelToken.source();
    const getRecords = async () => {
      try {
        const { captureEventId, id } = syncAttempt;
        const syncAttemptWithRecords = await GetSyncAttempt(captureEventId, id, cancelToken);
        setSyncAttemptWithRecords(syncAttemptWithRecords);
      } catch(e) {
        if (!axios.isCancel(e)) {
          Logger.error('Error attempting to get sync attempt records in dialog', e);
          setError('There was an error getting sync records');
        } 
      }

      setRecordsLoading(false);
    };

    getRecords();
    return cancelToken.cancel;
  }, [syncAttempt]);

  const applySync = async () => {
    setUpdatingSyncStatus(true);
    setConfirmSync(false);
    try {
      const { id, captureEventId } = syncAttempt;
      await EventActions.applySync.postAction(captureEventId, id);
      onSyncComplete();
    } catch (e) {
      Logger.error('Error applying sync', e);
      setError('There was an error applying the sync');
    }
    setUpdatingSyncStatus(false);
  };

  const rejectSync = async () => {
    setUpdatingSyncStatus(true);
    setConfirmReject(false);
    try {
      const { captureEventId, id } = syncAttempt;
      const changes = [
        {
          path: 'status',
          op: 'replace',
          value: SyncStatus.REJECTED
        }
      ];
      await PatchSyncAttempt(captureEventId, id, changes);
      onSyncRejected();
    } catch (e) {
      Logger.error('Error rejecting sync', e);
      setError('There was an error rejecting the sync');
    }
    setUpdatingSyncStatus(false);
  };

  const handleRecordClicked = (recordId) => {
    if (loading) return;
    setSelectedRecords(prevSelected => {
      const selectedIdx = prevSelected.indexOf(recordId);
      if (selectedIdx !== -1) { // remove it
        return [...prevSelected.slice(0, selectedIdx), ...prevSelected.slice(selectedIdx + 1)];
      }
      return [...prevSelected, recordId];
    });
  };

  const handleFilterChange = (e) => {
    setFilterObj(filters.find(({ name }) => name === e.target.value));
  };

  const recordUpdated = (newRecord, deletedRecordId = null) => {
    setSyncAttemptWithRecords(prevSync => {
      const records = prevSync.syncRecords.reduce((acc, record) => {
        // skip deleted record
        if (deletedRecordId !== record.id) {
          acc.push(record.id === newRecord.id ? newRecord : record);
        }
        return acc;
      }, []);
      return { ...prevSync, syncRecords: records };
    });
    setSelectedRecords([]);
  };

  const filteredRecords = useMemo(() => {
    if (!syncAttemptWithRecords) return null;
    return syncAttemptWithRecords.syncRecords.filter(filterObj.filterFn);
  }, [syncAttemptWithRecords, filterObj.filterFn]);

  // deselect whenever this changes
  useEffect(() => {
    setSelectedRecords([]);
  }, [filteredRecords]);

  const readOnly = !syncAttempt || syncAttempt.status !== SyncStatus.COMPLETE;

  return <>
    <Dialog classes={{ paper: classes.dialog }} open={open} fullWidth>
      <DialogTitle disableTypography>
        <div className={classes.recordActions}>
          <div className={classes.actions}>
            <FormControl className={classes.formControl}>
              <Select
                disabled={loading}
                labelId='filterRecords'
                value={filterObj.name}
                onChange={handleFilterChange}
              >
                {
                  filters.map(({ name }) => {
                    return <MenuItem key={name} value={name}>{name}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
            <Button 
              color='primary'
              disabled={loading || selectedRecords.length === 0}
              onClick={() => setSelectedRecords([])}>
              Deselect All
            </Button>
          </div>
          {!readOnly && 
            <div className={classes.actions}>
              <Button 
                color='primary'
                variant='contained'
                disabled={loading || selectedRecords.length !== 2} 
                onClick={() => setMergeDialogOpen(true)} 
              >
                Merge Two Records
              </Button>
              <div 
                className={
                  classnames(classes.recordCounter, 
                    { [classes.mergeCanBeDone]: selectedRecords.length === 2 })
                }>
                {selectedRecords.length}/2
              </div>
            </div>
          }
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          {
            recordsLoading &&
            <div className={classes.loading}>
              <CircularProgress size={30}/>
            </div>
          }

          {
            !recordsLoading && filteredRecords &&
            <SyncRecordsTable
              records={filteredRecords}
              syncAttemptData={syncAttemptWithRecords}
              selectedRecords={selectedRecords}
              onRecordClicked={handleRecordClicked}
              columns={columns}
            />
          }
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.dialogActions}>
          {!readOnly && 
            <Button 
              className={classes.rejectButton}
              disabled={loading} 
              onClick={() => setConfirmReject(true)} 
            >
              Reject
            </Button>
          }
          <div>
            <Button 
              disabled={updatingSyncStatus} 
              onClick={onClose} 
            >
              Cancel
            </Button>
            {!readOnly && 
              <ProgressButton
                className={classes.syncButton}
                disabled={recordsLoading}
                showProgress={loading}
                color='primary'
                variant='contained'
                onClick={() => setConfirmSync(true)}
              >
                Complete Sync
              </ProgressButton>
            }
          </div>
        </div>
      </DialogActions>
    </Dialog>

    {
      syncAttemptWithRecords &&
      filteredRecords &&
      <MergeDialog 
        open={mergeDialogOpen}
        syncId={syncAttemptWithRecords ? syncAttemptWithRecords.id : null}
        eventId={syncAttemptWithRecords ? syncAttemptWithRecords.captureEventId : null}
        selectedRecords={selectedRecords}
        records={filteredRecords}
        syncAttemptData={syncAttemptWithRecords}
        columns={columns}
        onRecordMerged={recordUpdated}
        onClose={() => setMergeDialogOpen(false)}
      />
    }

    <ErrorSnackbar 
      message={error}
      onClose={() => setError(null)}
    />

    <ConfirmationDialog 
      title='Complete this Sync?'
      open={confirmSync}
      onConfirm={applySync}
      onCancel={() => setConfirmSync(false)} >
      Are you ready to apply your changes and finalize the sync?
    </ConfirmationDialog>

    <ConfirmationDialog 
      title='Reject This Sync Attempt?'
      open={confirmReject}
      onConfirm={rejectSync}
      onCancel={() => setConfirmReject(false)} >
      Are you sure you want to reject this sync attempt?
    </ConfirmationDialog>
  </>;
};

export default SyncRecordDialog;