import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import log from 'js-logger';
import { findIndex } from 'lodash';
import queryString from 'query-string';
import { 
  GetMotionsForUserInCurrentOrg, 
  LoadAllMotionData,
  PutMotionAttributes,
  DeleteMotion
} from '../../../network/motionRequests';
import { FULL_MOTION_METRICS } from '../../../constants/motionSubresources.constants';
import MOTION_TYPES from '../../../constants/motionTypes.constants';
import styles from './styles';
import KinematicSequenceGraphDark from '../../../components/kinematicSequenceGraphDark';
import { DenseTable } from '../../../components/kinematicDetailsDense';
import { GetMotionAttributes } from '../../../network/motionAttributeRequests';
import SwingPanel from './swingPanel';
import SwingFlaws from './swingFlaws';
import ErrorSnackbar from '../../../components/errorSnackbar';
import { getGraphableMetrics, MetricKeys } from './swingDetails.constants';
import { setSelectedMetrics } from '../../../store/selectedGraphMetrics';
import { history } from '../../../store';
import NoDataMessage from '../../../components/noDataMessage';
import usePagedEndpoint from '../../../utils/usePagedEndpoint';
import { GetKeyFramesCoordinates } from '../../../utils/motionGraph.utils';
import ConfigurableTimeseriesGraph from '../../../components/configurableTimeseriesGraph';
import DecelerationTable from '../../../components/kinematicCarousel/decelerationTable';
import { PERMISSIONS } from 'constants/permissions.constants';

const SwingDetails = ({ match, location, classes }) => {
  const [ currentMotion, setCurrentMotion ] = useState(null);
  const [ isLoadingMotion, setIsLoadingMotion ] = useState(false);
  const [ isSavingFlaws, setIsSavingFlaws ] = useState(false);
  const [ attributes, setAttributes ] = useState([]);
  const [ errMsg, setErrMsg ] = useState(null);
  const [ isFetchingInitialData, setIsFetchingInitialData ] = useState(true);

  // This can't be state as it's used as a cache and is both used and updated 
  // in effects which would cause infinite loops due to dependencies.
  const detailedMotionData = useRef({});

  const user = useSelector(state => state.currentPlayer);
  const permissions = useSelector(state => state.featurePermissions);

  const dispatch = useDispatch();
  const { userId } = match.params;
  const MOTIONS_PER_PAGE = 15;

  const motionsRequest = useCallback((params, cancel) => {
    return GetMotionsForUserInCurrentOrg(userId, params, cancel);
  }, [userId]);
  const memoParams = useMemo(() => ({ subresources: [ FULL_MOTION_METRICS ] }), []);
  
  const values = queryString.parse(location.search);
  const selectedMotionId = values.motionId;
  const queryPage = Number.parseInt(values.page);
  const pageNumber = queryPage ? queryPage : 1;

  const graphableMetrics = getGraphableMetrics(detailedMotionData.current[selectedMotionId] 
    ? currentMotion.motionType 
    : null);

  const pagedMotionData = usePagedEndpoint(
    motionsRequest,
    memoParams,
    MOTIONS_PER_PAGE,
    pageNumber
  );

  const showFlaws = currentMotion 
    && currentMotion.motionType === MOTION_TYPES.baseball 
    && permissions.includes(PERMISSIONS.kGoFeatureSet);

  useEffect(() => {
    // Runs once at first render
    const _cancelToken = axios.CancelToken.source();
    async function fetchInitialData() {
      try {
        const attrs = await GetMotionAttributes();
        setAttributes(attrs);
      } catch(e) {
        if (!axios.isCancel(e)) {
          setErrMsg('Error when trying to get motions. Please try again.');
        }
      } finally {
        setIsFetchingInitialData(false);
      }
    }
    fetchInitialData();

    return _cancelToken.cancel;
  }, [dispatch]);

  const _selectMotion = useCallback((motion) => {
    if (motion == null) return;

    // reset selected graph metrics if motion type has changed
    if (currentMotion == null || currentMotion.motionType !== motion.motionType) {
      dispatch(setSelectedMetrics([
        MetricKeys.pelvisBend, 
        MetricKeys.pelvisSideBend
      ]));
    }

    let searchParams = new URLSearchParams(location.search);
    searchParams.set('motionId', motion.id);
    history.push({ 
      pathname: history.location.pathname, 
      search: searchParams.toString()
    });
  }, [location.search, currentMotion, dispatch]);

  const _setPage = (pageNum) => {
    let searchParams = new URLSearchParams(location.search);
    searchParams.set('page', pageNum);
    history.push({ 
      pathname: history.location.pathname, 
      search: searchParams.toString()
    });
  };

  useEffect(() => {
    // Will select the first motion in the list on initial render
    if (currentMotion == null && 
      !pagedMotionData.isInitialFetching 
      && pagedMotionData.items.length > 0
    ) {
      _selectMotion(pagedMotionData.items[0]);
    }
  }, [currentMotion, _selectMotion, pagedMotionData]);

  useEffect(() => {
    // Loads a motion after one has been selected via the url changing
    if (selectedMotionId == null || user.measurements == null) return;

    async function loadMotion() {
      if (!detailedMotionData.current[selectedMotionId]) {
        try {
          setIsLoadingMotion(true);
          const motionData = await LoadAllMotionData(selectedMotionId, user);
          detailedMotionData.current = { 
            ...detailedMotionData.current, 
            [selectedMotionId]: motionData 
          };
          setCurrentMotion(motionData.motion);
          setIsLoadingMotion(false);
        } catch (e) {
          if (!axios.isCancel(e)) {
            log.error(e);
            setIsLoadingMotion(false);
          } 
        }
      } else {
        setCurrentMotion(detailedMotionData.current[selectedMotionId].motion);
      }
    }    
    loadMotion();
  }, [user, selectedMotionId]);

  const _deleteMotion = async () => {
    if (!window.confirm('Are you sure you want to delete this motion. ' +  
      'It will no longer show on this page or within the player app.')){
      return;
    }

    try {
      let deletedMotionIdx = findIndex(pagedMotionData.items, 
        motion => motion.id === currentMotion.id);
      await DeleteMotion(currentMotion.id);
      pagedMotionData.refreshData();
      // motions will still contain the deleted motion
      // refreshData is async and you can't get the updated state from awaiting
      const motions = pagedMotionData.items;

      if (motions.length === 1 || deletedMotionIdx < 0) {
        // do nothing, the page will refresh based on the state change 
        // and the message about having no motions will show
      }
      else if (deletedMotionIdx >= motions.length - 1) {
        // if the motion is the very last one, then select the previous one
        _selectMotion(motions[motions.length - 2]);
      } else {
        // otherwise select the next motion in the list
        _selectMotion(motions[deletedMotionIdx + 1]);
      }
    } catch (e) {
      log.error(e);
      setErrMsg('There was a problem deleting the motion please try again.');
    }
  };

  const getKeyFrameLines = () => {
    const currentMotionDoesNotHaveData = currentMotion?.fullMotionMetrics == null
      || !detailedMotionData.current[currentMotion.id];
    
    if (currentMotionDoesNotHaveData) {
      return;
    }
    
    const currentFrames = detailedMotionData.current[currentMotion.id].analyzedData;
    return GetKeyFramesCoordinates(
      currentMotion, 
      currentFrames, 
      currentMotion.fullMotionMetrics);
  };

  const _updateSwingFlaws = async (attributeId) => {
    const motionData = detailedMotionData.current[currentMotion.id];
    if (!motionData) return;

    const currentFlaws = motionData.flawIds;

    let updatedFlaws;
    if (currentFlaws.includes(attributeId)) {
      updatedFlaws = currentFlaws.filter(id => id !== attributeId);
    } else {
      updatedFlaws = [...currentFlaws, attributeId];
    }

    try {
      setIsSavingFlaws(true);
      const data = updatedFlaws.map(motionAttributeId => ({ motionAttributeId }));
      await PutMotionAttributes(currentMotion.id, data);
      const updatedMotionData = { ...motionData, flawIds: updatedFlaws };
      detailedMotionData.current = { 
        ...detailedMotionData.current, 
        [currentMotion.id]: updatedMotionData 
      };
    } catch(e) {
      log.error(e);
    } finally {
      setIsSavingFlaws(false);
    }
  };

  const motionData = currentMotion ? detailedMotionData.current[currentMotion.id] : {};
  const currentFlaws = currentMotion && detailedMotionData.current[currentMotion.id]
    ? detailedMotionData.current[currentMotion.id].flawIds
    : [];

  const playerHasNoMotions = !pagedMotionData.isInitialFetching 
    && !pagedMotionData.isFetching 
    && pagedMotionData.items.length === 0;

  if ((isFetchingInitialData || pagedMotionData.isInitialFetching) && !currentMotion) {
    return <CircularProgress />;
  } else {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={10} lg={2} className={classes.swingPanelGrid}>
          <SwingPanel
            pagedMotionData={pagedMotionData}
            setPage={_setPage}
            selectedMotionId={selectedMotionId} 
            onMotionSelected={motion => _selectMotion(motion)}
            deleteMotion={_deleteMotion}
          />
        </Grid>
        {playerHasNoMotions && 
          <NoDataMessage>
            This player does not have any motions captured yet.
          </NoDataMessage>
        }
        {!playerHasNoMotions && 
          <Grid item xs={12} lg={9}>
            <Grid container direction='row' spacing={1}>
              <Grid item lg={12} xl={6}>
                {isLoadingMotion && 
                  <CircularProgress 
                    className={classes.circularProgress} 
                    size={45}
                  />
                }
                <div>
                  <KinematicSequenceGraphDark 
                    motion={currentMotion}
                    analyzedFrames={motionData.analyzedData}
                    fullMotionMetrics={motionData.motion && motionData.motion.fullMotionMetrics}
                  />
                </div>
                <div>
                  <DenseTable 
                    motionType={motionData.motion ? motionData.motion.motionType : ''} 
                    allData={motionData.motion  
                      ? { 
                        ...motionData.motion.fullMotionMetrics, 
                        ...motionData.keyFrameDataMap, 
                        ...motionData.motion.launchMonitorData 
                      } : {}}
                  /> 
                  <DecelerationTable motion={motionData.motion} />
                </div>
              </Grid>
              <Grid item lg={12} xl={6} className={classes.graphColumn}>
                <Paper>
                  {isLoadingMotion && 
                    <CircularProgress 
                      className={classes.circularProgress} 
                      size={45}
                    />
                  }
                  <ConfigurableTimeseriesGraph 
                    motions={[{ 
                      ...currentMotion, 
                      analyzedData: detailedMotionData.current[currentMotion?.id]?.analyzedData 
                    }]}
                    graphableMetrics={graphableMetrics}
                    disabled={isLoadingMotion}
                    verticalLines={getKeyFrameLines()}
                  />
                </Paper>
              </Grid>
              {showFlaws &&
                <Grid item xs={12}>
                  <SwingFlaws
                    isDisabled={isLoadingMotion || isSavingFlaws}
                    allFlaws={attributes}
                    motionFlaws={currentFlaws}
                    updateFlaw={_updateSwingFlaws}
                  />
                </Grid>
              }
            </Grid>
          </Grid>
        }
        <ErrorSnackbar 
          message={errMsg} 
          onClose={() => setErrMsg(null)}
        />
      </Grid>
    );
  }
};

export default withStyles(styles)(SwingDetails);