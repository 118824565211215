import React from 'react';
import { 
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography
} from '@material-ui/core';
import { BillingPeriods } from '../../../constants/purchases.constants';
import PriceComponent from './priceComponent';
import useStyles from './styles';

function Plan(props) {
  const {
    publicName,
    extendedDescription,
    standardPrice,
    promotionalPrice,
    upgradePrice,
    billingPeriod,
    onClick
  } = props;

  const classes = useStyles();
    
  return (
    <Card elevation={4} onClick={onClick}>
      <CardHeader
        title={publicName}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
        className={classes.cardHeader}       
      />
      <CardContent>
        <PriceComponent 
          billingPeriod={BillingPeriods[billingPeriod]}
          standardPrice={standardPrice}
          promotionalPrice={promotionalPrice}
          upgradePrice={upgradePrice}
        />
        <ul>
          {extendedDescription.map((line) => (
            <Typography component='li' variant='subtitle1' align='left' key={line}>
              {line}
            </Typography>
          ))}
        </ul>
        <Typography>
          You can train one plan or many over time; 
          improve your swing in the short-term and over the long-term.
        </Typography>
      </CardContent>
      <CardActions>
        <Button fullWidth variant={'contained'} color='primary'>
          Buy Now!
        </Button>
      </CardActions>
    </Card>
  );
};

export default Plan;