import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '50px'
  },
  sliderDemoCancel: {
    position: 'absolute',
    right: '20px',
    top: '10px'
  },
  edgerThumbnail: {
    maxWidth: '100%',
    marginTop: '10px',
    marginBottom: '5px'
  },
  subtitle: {
    lineHeight: 1.334,
    letterSpacing: '0em',
    fontSize: '24px',
    fontWeight: 700
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px'
  },
  sequenceContainer: {
    marginTop: '15px'
  },
  eventName: {
    fontWeight: 'bold'
  },
  showcaseText: {
    marginTop: '10px'
  },
  playerData: {
    marginTop: '20px'
  },
  motionData: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '20px'
  },
  videoContainer: {
    paddingLeft: '20px'
  },
  statSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  motionDataSection: {
    marginBottom: '40px'
  },
  subtext: {
    marginTop: '5px'
  },
  labelAndValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    fontWeight: 'bold'
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  speedValues: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingTop: '10px'
  },
  logo: {
    maxHeight: 100
  },
  rankings: {
    borderTop: '3px solid #ddd',
    borderBottom: '3px solid #ddd',
    marginTop: 20,
    marginBottom: 40
  },
  rankingsHeader: {
    marginTop: 10,
    marginBottom: 10
  },
  classInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  classOf: {
    marginRight: 5
  },
  evStatsHeaders: {
    fontSize: '1.2rem',
    color: 'dimgray',
    textAlign: 'center',
    fontWeight: '500',
    textTransform: 'uppercase',
    paddingTop: 7,
    paddingBottom: 7,
    marginTop: 10,
    borderTop: '1px solid #ddd',
    borderBottom: '1px solid #ddd'
  },
  evStats: {
    fontSize: '1.3rem',
    textAlign: 'center',
    fontWeight: '500',
    textTransform: 'uppercase',
    paddingTop: 7,
    paddingBottom: 20
  },
  evLabel: {
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  },
  mph: {
    marginLeft: 3,
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: 'dimgray'
  }
}));

export default useStyles;