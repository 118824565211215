import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_theme => ({
  select: {
    width: '100%'
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  units: {
    margin: '0 5px',
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

export default useStyles;
