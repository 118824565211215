const styles = {
  progress: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  untitled: {
    fontStyle: 'italic'
  },
  aspectRatioContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
    height: 0
  },
  noVideosContainer: {
    position: 'absolute',
    width: '100%', 
    height: '100%', 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center'
  },
  noVideosLabel: {
    fontSize: 16, 
    fontStyle: 'italic', 
    margin: 10 
  }
};

export default styles;
