import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/WarningRounded';
import SelectionList from '../../../components/selectionList';
import styles from './styles';
import { makeStyles } from '@material-ui/core'; 

function TeamAssignment({ userName = 'user', initialSelectedTeams = [], teams, onChange }) {
  const [selectedTeams, setSelectedTeams] = useState(initialSelectedTeams);
  const [
    userCanSeeAllPlayers, 
    setUserCanSeeAllPlayers
  ] = useState(initialSelectedTeams.length === 0);
  const classes = makeStyles(_ => styles)();

  useEffect(() => { 
    onChange(selectedTeams); 
    if (selectedTeams.length === 0) {
      setUserCanSeeAllPlayers(true);
    }
  }, [selectedTeams, onChange]);

  const handleSeeAllPlayersSwitched = () => {
    setUserCanSeeAllPlayers(prev => {
      if (!prev) {
        setSelectedTeams([]);
      }
      return !prev;
    });
  };

  const handleTeamSelection = teams => {
    if (teams.length > 0 && userCanSeeAllPlayers) {
      setUserCanSeeAllPlayers(false);
    }
    setSelectedTeams(teams);
  };

  const showSwitchErrorMsg = selectedTeams.length === 0 && !userCanSeeAllPlayers;

  return <div className={classes.teamAssignmentContainer}>
    <FormControlLabel
      control={
        <Switch
          checked={userCanSeeAllPlayers}
          onChange={handleSeeAllPlayersSwitched}
          color='primary'
        />
      }
      label={
        `Allow ${userName} to see all teams and players ` + 
        '(must select at least one team otherwise)'}
      className={classes.switch}
    />
    <div className={classNames(classes.warning, { [classes.hidden]: !showSwitchErrorMsg })} >
      <WarningIcon className={classes.warningIcon} fontSize='large' />
      <Typography variant='button'>
        If no teams are selected, {userName} will be able to see all teams and all players.
      </Typography>
    </div>
    <SelectionList
      items={teams}
      selectedItems={selectedTeams}
      onChange={handleTeamSelection}
      columnWidths={{ xs: 4 }}
      getLabel={team => `${team.name}`}
      getValue={team => team.id}
      hideSelectAll
    />
  </div>;
};

TeamAssignment.propTypes = {
  userName: PropTypes.string,  // name to display for user
  initialSelectedTeams: PropTypes.arrayOf(PropTypes.object),  // initially selected teams for a user
  onChange: PropTypes.func.isRequired,  // passes back new list of selected teams                
  teams: PropTypes.arrayOf(PropTypes.object)  // all teams in the organization
};

export default TeamAssignment;