import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import CaptureEventListItem from './captureEventListItem';
import useStyles from './styles';

function CaptureEventsListSection({ 
  captureEvents, 
  selectedEventId, 
  header, 
  onChange, 
  loading,
  eventFilterText,
  numEventsToShow,
  selectedEvents = {},
  includeIds = false
}) {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  let filteredEvents = captureEvents;
  const filterText = eventFilterText.trim();
  if (filterText != null && filterText !== '') {
    filteredEvents = filteredEvents
      .filter(x => x.name.toLowerCase().includes(filterText.toLowerCase()));
  }
  if (!showAll && numEventsToShow != null) {
    filteredEvents = filteredEvents.slice(0, numEventsToShow);
  }

  return <div>
    <Typography variant='h6'>{header}</Typography>
    
    <List>
      {filteredEvents.map(captureEvent => <React.Fragment key={captureEvent.id}>
        <Divider />
        <CaptureEventListItem 
          includeId={includeIds}
          captureEvent={captureEvent}
          selected={captureEvent.id === selectedEventId}
          button
          onClick={() => onChange(captureEvent)}
          multipleSelected={selectedEvents[captureEvent.id]}
        />
      </React.Fragment>)}

      {loading && [...Array(3).keys()].map(idx => <React.Fragment key={`event-loading-${idx}`}>
        <Divider />
        <ListItem>
          <ListItemText 
            primary={<Skeleton variant='text' />}
            secondary={<Skeleton variant='text' />}
          />
        </ListItem>
      </React.Fragment>)}

      {!loading && captureEvents?.length === 0 && <>
        <Divider />
        <ListItem>
          <Typography variant='caption'>No {header}</Typography>
        </ListItem>
      </>}
    </List>

    {numEventsToShow != null && captureEvents.length > numEventsToShow &&
      <div className={classes.showAllWrapper}>
        <Button color='primary' onClick={() => setShowAll(prev => !prev)}>
          {showAll ? 'Hide' : 'Show all'} {header}
        </Button>
      </div>
    }
  </div>;
}

export default CaptureEventsListSection;
