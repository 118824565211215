import { useCallback } from 'react';
import useNetworkRequest from 'network/useNetworkRequest';
import { GetTrainingPlans } from 'network/trainingPlanRequests';
import { NOTE_STATUSES } from 'constants/noteStatuses.constants';
import { orderBy } from 'lodash';

function useTrainingPlanSentNotes(trainingPlan) {
  const { id, userId, organizationId, type } = trainingPlan ?? {};
  const getSentNotes = useCallback(async cancelToken => {
    if (id == null || userId == null || organizationId == null) {
      return [];
    }
    const trainingPlans = await GetTrainingPlans({ 
      userId,
      organizationId,
      trainingPlanType: type,
      subresources: [ 'notes.Notifications.CommunicationChannel' ]
    }, cancelToken);
    const selectedTrainingPlan = trainingPlans.find(x => x.id === id);
    if (selectedTrainingPlan == null) {
      return [];
    }
    const sentNotes = selectedTrainingPlan
      ?.notes
      ?.filter(x => x.status === NOTE_STATUSES.sent) ?? [];

    return orderBy(sentNotes, 'sequenceOrder');
  }, [id, userId, organizationId, type]);
  
  return useNetworkRequest([], 'GetSentNotes', getSentNotes);
}

export default useTrainingPlanSentNotes;