import makeStyles from '@material-ui/core/styles/makeStyles';
import Palette from '../../../../styles/palette';

const useStyles = makeStyles(() => ({
  outerContainer: {
    width: '100%'
  },
  innerContainer: {
    border: '1px solid ' + Palette.gray, 
    padding: '15px', 
    margin: '15px', 
    borderRadius: '8px'
  },
  drillAutocomplete: {
    width: '80%',
    paddingBottom: '15px'
  },
  variationSelect: {
    width: 'calc(80% - 30px)',
    marginLeft: '30px',
    marginTop: '5px',
    paddingBottom: '10px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px'
  }
}));

export default useStyles;
