import React, { useState, useCallback } from 'react';
import { Paper, Grid, Typography, CircularProgress } from '@material-ui/core';
import { 
  CartesianGrid, 
  Scatter,
  Line,
  ComposedChart,
  XAxis, 
  YAxis, 
  Label,
  Cell
} from 'recharts';
import round from 'lodash/round';
import orderBy from 'lodash/orderBy';
import { GetAnalyzedSwingData } from '../../../network/motionRequests';
import { ANALYSIS_METRICS } from '../dataAnalysis.constants';
import KinematicSequenceGraphDark from '../../../components/kinematicSequenceGraphDark';
import KinematicDetailsDense from '../../../components/kinematicDetailsDense';
import useNetworkRequest from '../../../network/useNetworkRequest';
import useMotionsWithTag from '../useMotionsWithTag';
import AxisSelection from './axisSelection';
import MathUtils from '../../../utils/math.utils';
import useStyles from './styles';

const METRICS = [
  {
    label: 'Tag',
    type: 'category',
    getValue: motion => motion.tag?.tagName
  },
  ...ANALYSIS_METRICS
];

function ScatterPlot() {
  const motions = useMotionsWithTag();
  const [hoveredTag, setHoveredTag] = useState();
  const [clickedMotion, setClickedMotion] = useState();
  const [xMetric, setXMetric] = useState(
    METRICS.find(metric => metric.label === 'Peak Speed Body Percentile'));
  const [yMetric, setYMetric] = useState(
    METRICS.find(metric => metric.label === 'Exit Velocity'));

  const tagColor = hoveredTag ? hoveredTag.color : '#fff';
  const classes = useStyles({ color: tagColor });

  const orderedMotions = orderBy(Object.values(motions), motion => motion.timestamp);
  let data = orderedMotions.map(motion => ({
    x: xMetric.getValue(motion),
    y: yMetric.getValue(motion),
    id: motion.id,
    fill: motion.tag?.color,
    tag: motion.tag,
    motion 
  }));

  const getBestFitLineData = () => {
    const xValues = data.map(item => item.x);
    const yValues = data.map(item => item.y);
    const { slope, intercept, r2 } = MathUtils.linearRegression(xValues, yValues);

    const minX = Math.min(...xValues);
    const maxX = Math.max(...xValues);
    const bestFitLineData = [
      { x: minX, yBestFit: intercept + (slope * minX) },
      { x: maxX, yBestFit: intercept + (slope * maxX) }
    ];
    return { bestFitLineData, r2 };
  };
  
  const analyzedFramesRequest = useCallback(async cancelToken => {
    if (clickedMotion?.id == null) {
      return [];
    }
    return await GetAnalyzedSwingData(clickedMotion.id, cancelToken);
  }, [clickedMotion]);
  
  const [
    analyzedFrames,
    loading
  ] = useNetworkRequest([], 'getAnalyzedFrames', analyzedFramesRequest);
  
  const { bestFitLineData, r2 } = getBestFitLineData();
  data = data.concat(bestFitLineData);

  return <Paper>
    <Grid container spacing={1} justify='center' alignItems='center'>
      <Grid item>
        <AxisSelection 
          id='x-axis-scatter-data-analysis'
          value={xMetric}
          options={METRICS}
          onChange={setXMetric}
          label='X Axis'
        />
      </Grid>
      <Grid item>
        <Typography>vs</Typography>
      </Grid>
      <Grid item>
        <AxisSelection 
          id='y-axis-scatter-data-analysis'
          value={yMetric}
          options={METRICS}
          onChange={setYMetric}
          label='Y Axis'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography align='center'>R<sup>2</sup>: {round(r2, 4)}</Typography>
      </Grid>

      <Grid container item xs={12} justify='center'>
        <ComposedChart width={800} height={500} margin={{ top: 10, bottom: 20 }} data={data}>
          <CartesianGrid />
          <XAxis 
            name='x' 
            dataKey='x'
            type={xMetric.type ?? 'number'} 
            allowDecimals={false} 
            allowDuplicatedCategory={false}
            domain={xMetric.domain}
          >
            <Label value={xMetric.label} dy={20} />
          </XAxis>
          <YAxis 
            name='y' 
            dataKey='y' 
            type={yMetric.type ?? 'number'} 
            allowDecimals={false} 
            allowDuplicatedCategory={false}
            domain={yMetric.domain}
          >
            <Label angle={-90} value={yMetric.label} dx={-10} />
          </YAxis>

          <Scatter isAnimationActive={false}>
            {data.map((entry, idx) => <Cell 
              isAnimationActive={false}
              key={`scatter-cell-${entry.id}-${idx}`}
              fill={entry.fill}
              onMouseDown={()=> {setClickedMotion(entry.motion);}}
              onMouseOver={()=> {setHoveredTag(entry.tag);}}
              onMouseOut={()=> {setHoveredTag();}}
            />)}
          </Scatter>
          <Line
            isAnimationActive={false}
            dataKey='yBestFit' dot={false} 
          />
        </ComposedChart>
      </Grid>
      <Grid container item xs={12} justify='center'>
        <div
          className={classes.tagColorInfo}
        />
        <Typography>{hoveredTag?.tagName}</Typography> 
      </Grid>
    </Grid>

    {clickedMotion && <Paper>
      {loading
        ? <CircularProgress />
        : <div>
          <KinematicSequenceGraphDark
            motion={clickedMotion}
            analyzedFrames={analyzedFrames}
            fullMotionMetrics={clickedMotion.fullMotionMetrics}
          />
          <KinematicDetailsDense motion={clickedMotion} userId={clickedMotion.userId} />
        </div>}
    </Paper>}
  </Paper>;
}

export default ScatterPlot;
