import { makeStyles } from '@material-ui/core/styles';
import palette from '../../styles/palette';

export default makeStyles(_ => ({
  container: {
    padding: '3%',
    paddingTop: '20px'
  },
  card: {
    padding: 20,
    marginTop: '20px'
  },
  qaInfo: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  qaEntry: {
    textAlign: 'left',
    margin: 10
  },
  logoutButton: {
    textAlign: 'center'
  },
  lockIcon: {
    fontSize:'medium',
    marginRight: '5px'
  },
  crossedOutText: {
    'textDecoration': 'line-through'
  },
  paymentArea: {
    marginTop: 20
  },
  ccContainer: {
    marginTop: 20,
    marginBottom: 20
  },
  title: {
    marginBottom: 20
  },
  errorMsg: {
    color: palette.error,
    textAlign: 'center',
    marginBottom: '15px'
  },
  paymentFormContainer: {
    margin: 'auto',
    marginTop: '30px',
    maxWidth: '650px',
    padding: '20px'
  },
  promoWarning: {
    textAlign: 'center',
    marginTop: '25px'
  },
  center: {
    margin: 'auto'
  },
  subscriptionsButton: {
    width: '225px'
  },
  subscriptionIcon: {
    marginRight: '10px'
  },
  promoContainer: {
    marginTop: '25px'
  },
  discountText: {
    marginRight: '70px'
  },
  discountAppliedText: {
    marginRight: '70px'
  },
  submitButton: {
    marginTop: '15px',
    marginBottom: '15px',
    height: '50px'
  },
  howItWorks: {
    fontSize: 'larger'
  },
  underline: {
    textDecoration: 'underline'
  },
  howToGetStartedContainer: {
    padding: 20,
    margin: 'auto',
    maxWidth: '1000px'
  }
}));

export const StripeCardStyle = {
  base: {
    fontSize: '16px',
    color: palette.stripeBase,
    fontFamily:
    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
    '::placeholder': {
      color: '#a0aec0'
    }
  },
  invalid: {
    color: palette.stripeRed
  }
};