import React, { useState, useEffect } from 'react';
import { 
  Button,
  Typography,
  Container,
  Grid,
  CircularProgress,
  Card,
  Slide
} from '@material-ui/core';
import logger from 'js-logger';
import queryString from 'query-string';
import useStyles from './styles';
import { history } from '../../store';
import PlanComponent from './plan';
import { products } from '../../constants/purchases.constants';
import { GetProducts, GetPurchases } from '../../network/purchaseRequests';
import { GetUser } from '../../network/userRequests';
import { getUserId } from '../../utils/auth.utils';
import { Logout, IsLoggedIn } from '../../utils/auth.utils';
import CurrentSubscription from './currentSubscription';
import { getSubscriptions, getActiveSubscriptionAndPath } from '../../utils/purchase.utils';
import Analytics, { CATEGORIES, SUBSCRIPTIONS_LOADED } from '../../services/analytics';
import UpcomingSubscription from './upcomingSubscription';
import HowToGetStarted from './howToGetStarted';
import useTrackingServices, { EVENT_NAMES } from '../../services/useTrackingServices';

const PaymentHome = ({ location }) => {
  const tracking = useTrackingServices();

  const [updatesCount, setUpdatesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [user, setUser] = useState(null);
  const [showBulkProducts, setShowBulkProducts] = useState(false);

  const subscriptions = getSubscriptions(purchases);
  const { 
    outerSubscriptionId,
    indexOfPurchaseItem,
    subscriptionDetails
  } = getActiveSubscriptionAndPath(subscriptions);
  const userId = getUserId();
  const classes = useStyles();

  const userOrg = user && user.kGoExperienceInfo && user.kGoExperienceInfo.organizationId;
  
  const query = queryString.parse(location.search);
  const justPurchased = query.purchaseComplete;

  const { track, identify } = tracking;

  useEffect(() => {
    identify(user);
  }, [identify, user]);

  useEffect(() => {
    track(EVENT_NAMES.productsPageVisited);
  }, [track, userId]);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const [allProducts, allPurchases, user] = await Promise.all([
          GetProducts(userId),
          GetPurchases(userId),
          GetUser(userId)
        ]);
        setAvailableProducts(allProducts);
        setPurchases(allPurchases);
        setUser(user);
      } catch(e) {
        logger.error(e);
      }
      
      Analytics.track({ 
        eventName: SUBSCRIPTIONS_LOADED, 
        userId, 
        miscData: {}, 
        eventCategory: CATEGORIES.PURCHASES,
        project: Analytics.PROJECTS.KGO
      });

      setIsLoading(false);
    };
    fetchProducts();
  }, [updatesCount, userId]);

  if(isLoading) {
    return <CircularProgress />;
  }

  const currentProducts = availableProducts.map((product) => {
    const { id } = product;
    const localProductInfo = products[id];
    if(localProductInfo == null) {
      // this would be a product that is not available through web
      return null;
    }
    return {
      ...localProductInfo,
      ...product
    };
  }).filter((product) => product != null)
    .sort((a, b) => { return a.orderingWeight - b.orderingWeight;});

  const bulkProducts = currentProducts.filter(product => product.isBulkProduct);
  const singleProducts = currentProducts.filter(product => !product.isBulkProduct);

  const onUndoChanges = () => {
    setTimeout(() => {
      setUpdatesCount(prev => prev + 1);
    }, 1000 * 5);
  };

  const onLogoutClicked = () => {
    track(EVENT_NAMES.logoutClicked);
    Logout(`/public-account-creation?showLogin=true&organizationId=${userOrg}`);
  };

  const removeQueryParams = ()=> {
    history.push('/purchase');
  };

  const currentlySubscribed = subscriptionDetails != null;
  const currentPlanName = subscriptionDetails && subscriptionDetails.product
    ? subscriptionDetails.product.name 
    : null;

  const getProductCards = (products) => {
    return (
      <>
        {products.map(product => (
          <Grid item xs={12} sm={6} md={5} key={product.id}>
            <PlanComponent
              {...product}
              onClick={() => {
                track(EVENT_NAMES.planClicked, {
                  Plan: product.name, 
                  Upgrade: currentlySubscribed,
                  'Current Plan': currentPlanName
                });
                history.push(`/purchase/${product.id}`);
              }}
            />
          </Grid> 
        ))}
      </>
    );
  };
  
  return (
    <div className={classes.container}>
      {!currentlySubscribed && !justPurchased &&
        <Container maxWidth='md' component='main'>
          {!showBulkProducts &&
            <Slide direction='left' in={!showBulkProducts} >
              <Grid container spacing={5} alignItems='flex-end' justify='center'>
                {getProductCards(singleProducts)}
              </Grid>
            </Slide>
          }
          {showBulkProducts &&
            <Slide direction='right' in={showBulkProducts}>
              <Grid container spacing={5} alignItems='flex-end' justify='center'>
                {getProductCards(bulkProducts)}
              </Grid>
            </Slide>
          }
          <br />
          {currentProducts.length > 0 && bulkProducts.length > 0 &&
            <Grid container justify='center'>
              <Grid item xs={8}>
                <Typography>
                  {showBulkProducts
                    ? 'Purchase a single analysis and check in.'
                    : 'Recieve a discount by purchasing three or six 3-week programs.'
                  }
                  <Button 
                    color='primary' 
                    variant='text' 
                    onClick={() => setShowBulkProducts(prev => !prev)}
                    className={classes.underline}
                  >
                    CLICK HERE
                  </Button>
                  <br />
                  Programs do not expire. Use them when you are ready.
                </Typography>
              </Grid>
            </Grid>
          }
        </Container>
      }
      {justPurchased &&
        <Container maxWidth='md' component='main'>
          <Button color='primary' onClick={removeQueryParams}>
            Purchase additional products
          </Button>
        </Container>
      }
      {purchases.length > 0 &&
        <div>
          <br />
          <Card className={classes.howToGetStartedContainer}>
            <HowToGetStarted />
          </Card>
        </div>
      }

      <CurrentSubscription 
        activeSubscriptionDetails={subscriptionDetails}
        outerSubscriptionId={outerSubscriptionId}
        indexOfPurchaseItem={indexOfPurchaseItem}
        onUndoComplete={onUndoChanges}
        upgradeProducts={currentProducts}
      />

      <UpcomingSubscription 
        subscriptions={subscriptions} 
        onUndoComplete={onUndoChanges}
      />
      <Grid container className={classes.qaInfo} justify='center' alignItems='center'>
        <Grid item xs={12} sm={8} md={8} lg={6}>
          <Card className={classes.card}>
            <Typography variant='h3' component='h3' align='center'>
              How it works:
            </Typography>
            <ul className={classes.howItWorks}>
              <li>
                You send videos of your swing through the app to your expert coach. 
                Takes less than two minutes.
              </li>
              <li>
                Via the app, your coach sends you a scouting report highlighting your strengths
                and an area of opportunity to improve your swing right now.
              </li>
              <li>
                After that, each week for the next three weeks, your coach will send 
                you a personalized training plan through the app. Train as often as you 
                like on your own schedule. The program includes a video explaining what 
                to do and another to follow as you train.
              </li>
              <li>
                You can log your training on the app to earn
                points on a leaderboard with other players.
              </li>
              <li>
                At the end of three weeks, you send your coach new videos of your swing
                so they can provide you with feedback on your progress.
              </li>
            </ul>
          </Card>
        </Grid>
      </Grid>

      { IsLoggedIn() && <Grid item xs={12} container alignItems='center' justify='center'>
        <Button 
          onClick={onLogoutClicked} 
          className={classes.logoutButton}
        >
            Logout
        </Button>
      </Grid>
      }
    </div>
  );
};
export default PaymentHome;

