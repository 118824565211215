import Shape from './shape';
import SHAPES from './constants';

class Line extends Shape {
  shape = SHAPES.line;

  constructor(startX, startY, endX, endY, color, lineWidth = 1) {
    super();
    this.startX = startX;
    this.startY = startY;
    this.endX = endX;
    this.endY = endY;
    this.color = color;
    this.lineWidth = lineWidth;
  }

  drawOnCanvas(ctx) {
    ctx.beginPath();
    ctx.moveTo(this.startX, this.startY);
    ctx.lineTo(this.endX, this.endY);
    ctx.lineWidth = this.lineWidth;
    ctx.strokeStyle = this.color;
    ctx.stroke();
  }
};

export default Line;
