import React, { useState, useEffect } from 'react';
import useStyles from './styles';

import CharacteristicSection from './characteristicSelectionSection';
import { VIEWS, VIEWS_TO_CHARACTERISTICS } from '../../../../constants/videoAnalysis.constants';

function CharacteristicSelection({ 
  characteristics = [],
  analysis = null,
  reviewAnnotation,
  section = null,
  characteristic = null,
  /* section is one of the two angles, characteristic is an object with name, id and type
    If characteristic is null, that means only the header was selected 
  */
  onCharacteristicSelected = (angle, characteristic) => {},

  /* annotation is the object from analysis, angels is one of two angles,
    characteristic is an object with name, id and type.
    If values are null, that means the header was selected only
  */
  onReviewSelected = (annotation, angle, characteristic) => {}
}) {

  const classes = useStyles();

  // sections/views
  const [faceOn, setFaceOn] = useState([]);
  const [catchersView, setCatchersView] = useState([]);
  const [reviewed, setReviewed] = useState([]);

  // selection states
  const [selected, setSelected] = useState(null);

  // callback for components
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectCharacteristicAndSection = (sectionId, characteristic) => {

    // if reviewed, select the first reviewed
    if (sectionId === VIEWS.REVIEWED.id && !characteristic) {
      characteristic = reviewed[0];
    }

    let formatted = characteristic 
      ? characteristics.find(c => c.id === characteristic.parentId) : null;

    if (sectionId === VIEWS.REVIEWED.id) {
      onReviewSelected(
        characteristic && characteristic.annotation, 
        characteristic && characteristic.view, 
        formatted
      );
    } else {
      onCharacteristicSelected(sectionId, formatted);
    }
  };

  // needed to trigger re-rerender since changing prop alone won't work
  // converts the top down so it's in the id form we need
  useEffect(() => {
    if (!characteristic || characteristic === '' || !section) {
      setSelected(null);
    } else {

      // we should find the id for this characteristic
      let selectedCharacteristic;
      const { FACE_ON, CATCHER, REVIEWED } = VIEWS;
      const sectionArray = { 
        [FACE_ON.id]: faceOn, 
        [CATCHER.id]: catchersView
      }[section];

      if (reviewAnnotation) {
        // look in review
        selectedCharacteristic = reviewed.find((c) => c.annotation.id === reviewAnnotation.id);
      } else {
        selectedCharacteristic = sectionArray.find((c) => c.name === characteristic);
      }

      if (selectedCharacteristic) {
        setSelected(selectedCharacteristic.id);
      } else {
        // trigger a call to change to an appropriate selection
        // and start the process over from the top-down

        // was just in the review screen, so try to stay in review screen
        if (reviewAnnotation && reviewed.length) {
          selectCharacteristicAndSection(REVIEWED.id, reviewed[0]);
        } else if (reviewAnnotation) {
          // default to face on if no reviews are left
          selectCharacteristicAndSection(FACE_ON.id, faceOn[0] || null);
        } else {
          // go to section, at very least correct video
          selectCharacteristicAndSection(section, sectionArray[0] || null);
        }
      }
    }
  }, [
    characteristic, 
    faceOn, catchersView, reviewed, 
    section, reviewAnnotation, selectCharacteristicAndSection
  ]);

  const videoAnalysisVideos = analysis ? analysis.videoAnalysisVideos : null;
  const videoAnnotations = analysis ? analysis.videoAnnotations : null;

  // loading callbacks
  useEffect(() => {

    // update the angles
    if (!characteristics || !videoAnalysisVideos || !videoAnnotations) {
      return;
    }

    // convert analysis to annotations
    const videoViews = videoAnalysisVideos.reduce((acc, info) => {
      acc[info.videoId] = info.video.videoAttributes.cameraAngle;
      return acc;
    }, {});

    const annotations = videoAnnotations.map((annotation) => {
      const { swingCharacteristic, sourceVideoId } = annotation;
      return {
        view: videoViews[sourceVideoId],
        data: characteristics.find(({ name }) => name === swingCharacteristic),
        annotation
      };
    });

    // convert characteristics to correct sections
    const catcherIds = VIEWS_TO_CHARACTERISTICS[VIEWS.CATCHER.id];
    const faceOnIds = VIEWS_TO_CHARACTERISTICS[VIEWS.FACE_ON.id];

    const catcherCharacteristics = [];
    const faceOnCharacteristics = [];
    const reviewedCharacteristics = [];

    // makes a copy and attaches appropriate id 
    const makeCopy = (c) => {
      // makes it so sections can share the same characteristic
      const copy = Object.assign({}, c);
      copy.parentId = c.id;
      copy.id = Symbol(c.id);
      return copy;
    };

    const saveIfReviewed = (c, angle) => {
      const reviewed = annotations.find(({ view, data }) => {
        return c.id === data.id && view === angle;
      });
      if (reviewed) {
        const { annotation, view } = reviewed;
        reviewedCharacteristics.push(makeCopy({ ...c, annotation, view }));
      }
      return reviewed;
    };

    characteristics.forEach((c) => {
      if (catcherIds.includes(c.id) && !saveIfReviewed(c, VIEWS.CATCHER.id)) {
        catcherCharacteristics.push(makeCopy(c));
      }
      if (faceOnIds.includes(c.id) && !saveIfReviewed(c, VIEWS.FACE_ON.id)) {
        faceOnCharacteristics.push(makeCopy(c));
      }
    });

    setFaceOn(faceOnCharacteristics);
    setCatchersView(catcherCharacteristics);
    setReviewed(reviewedCharacteristics);

  }, [characteristics, videoAnalysisVideos, videoAnnotations]);

  // change section if there is a reviewed annotation
  const selectedSection = reviewAnnotation ? VIEWS.REVIEWED.id : section;

  return (
    <>
      <div className={classes.title}>Swing Characteristics</div>
      <div className={classes.section}>
        <CharacteristicSection
          items={faceOn}
          sectionId={VIEWS.FACE_ON.id}
          title={VIEWS.FACE_ON.name}
          onClick={selectCharacteristicAndSection}
          selected={selected}
          selectedSection={selectedSection}
        />
      </div>

      <div className={classes.section}>
        <CharacteristicSection
          items={catchersView}
          sectionId={VIEWS.CATCHER.id}
          title={VIEWS.CATCHER.name}
          onClick={selectCharacteristicAndSection}
          selected={selected}
          selectedSection={selectedSection}
        />
      </div>

      {
        Boolean(reviewed.length) &&
        <CharacteristicSection
          analysis={analysis}
          items={reviewed}
          sectionId={VIEWS.REVIEWED.id}
          title={VIEWS.REVIEWED.name}
          onClick={selectCharacteristicAndSection}
          selected={selected}
          selectedSection={selectedSection}
        />
      }

    </>
  );
}

export default CharacteristicSelection;