import React, { useState } from 'react';
import lowerCase from 'lodash/lowerCase';
import { Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const generateText = (name, weight, focusSegment) => `Hi ${name.trim()},

Thanks so much for coming through the PG Tech hitting cage yesterday. Below is a hitting report 
based on the swings you took. In this report, we look at the speeds you generate with 4 segments 
of your body (Pelvis, Torso, Arm, and Hand) as well as your overall body speed (an average of 
those 4 segment speeds). We also look at the exit velo of the ball. 

For each speed and the exit velo, we calculate your percentile relative to other players with 
your same body weight (${weight.trim()} lbs). 

Your hitting report shows how energy is transferred from the ground, up through your body, and 
ultimately into the ball in your exit velocity. The body speed percentiles give us a sense of 
where you may be leaking energy and losing potential mph off your exit velo. Based on your 
percentiles, focusing on speeding up your ${lowerCase(focusSegment)} is the best way to improve 
the energy transfer and attempt to find more exit velocity.

We hope this is helpful, and if you have any questions or feedback to share, please email us 
anytime. 

Thanks,
The PG-Tech Team`;

function GenerateReportButton({ name, weight, focusSegment, ...props }) {
  const [textCopied, setTextCopied] = useState(false);

  const onClick = () => {
    const text = generateText(name, weight, focusSegment);
    navigator.clipboard.writeText(text);
    setTextCopied(true);
  };

  return <>
    <Button variant='contained' onClick={onClick} color='primary' {...props}>
      Generate Report
    </Button>
    <Snackbar 
      open={textCopied} 
      autoHideDuration={5000} 
      onClose={() => setTextCopied(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert variant='filled' severity='success'>
        Hitting report text has been copied to your clipboard and is ready to paste.
      </Alert>
    </Snackbar>
  </>;
}

export default GenerateReportButton;
