import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardActions,
  CardContent,
  Typography
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { PatchUser } from '../../network/userRequests';
import { SetCurrentPlayer } from '../../store/players';
import IconTextField from '../../components/iconTextField';
import ProgressButton from '../../components/progressButton';
import useInput from '../../utils/useInput';
import useStyles from './styles';
import Logger from 'js-logger';

function AddPlayerDetails({ onComplete }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const firstNameInput = useInput({
    trimValue: true,
    validation: name => name.trim().length > 0,
    validationMsg: 'Please enter a first name'
  });
  const lastNameInput = useInput({
    trimValue: true,
    validation: name => name.trim().length > 0,
    validationMsg: 'Please enter a last name'
  });
  const genderInput = useInput();
  const [dob, setDob] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.currentPlayer);

  // the date picker can't use useInput but this wrapper will
  // make it fit in nicely with the other field validation
  const dobField = {
    value: dob,
    validate: () => dob instanceof Date && !isNaN(dob),
    clearError: () => {}
  };

  const fields = [firstNameInput, lastNameInput, dobField, genderInput];
  const allInputFilled = fields.every(field => field.value);

  function fieldsAreValid() {
    fields.forEach(field => field.clearError());
    const results = fields.map(field => field.validate());
    return results.every(isSuccess => isSuccess);
  }

  const GENDER_OPTIONS = [
    { label: 'Select Gender', value: null },
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Other', value: 'Other' }
  ];

  async function updateUser(e) {
    e.preventDefault();
    if (!fieldsAreValid()) return;
    setIsUpdating(true);
    let updatedUser;
    try {
      const changes = [{
        op: 'replace',
        path: '/firstName',
        value: firstNameInput.value
      }, {
        op: 'replace',
        path: '/lastName',
        value: lastNameInput.value
      }, {
        op: 'replace',
        path: '/dateOfBirth',
        value: dob
      }, {
        op: 'replace',
        path: '/gender',
        value: genderInput.value
      }];
      updatedUser = PatchUser(user.userId, changes);
      dispatch(SetCurrentPlayer(updatedUser));
    } catch(e) {
      // Don't want to prevent a user from purchasing if we have an error. 
      // Log it and continue on.
      Logger.error('Error when updating user details', e);
    }
    setIsUpdating(false);
    onComplete(updatedUser || user);
  }

  return (
    <form onSubmit={updateUser}>
      <CardContent>
        <Typography>
          Enter details about your player
        </Typography>
        <br />
        <IconTextField
          {...firstNameInput.bind}
          autoFocus
          placeholder='First Name' 
          icon={<PersonOutlineIcon />}
          error={firstNameInput.inError}
          errorMsg={firstNameInput.errorMsg}
          disabled={isUpdating}
        />
        <IconTextField
          {...lastNameInput.bind}
          placeholder='Last Name' 
          icon={<PersonOutlineIcon />}
          error={lastNameInput.inError}
          errorMsg={lastNameInput.errorMsg}
          disabled={isUpdating}
        />
        <IconTextField
          {...genderInput.bind}
          selectData={GENDER_OPTIONS}
          placeholder='Gender' 
          icon={<PersonOutlineIcon />}
          error={genderInput.inError}
          disabled={isUpdating}
        />
        <KeyboardDatePicker
          autoOk
          disableFuture
          value={dob}
          onChange={setDob}
          disableToolbar
          variant='inline'
          inputVariant='outlined'
          InputAdornmentProps={{ position: 'start' }}
          format='MM/dd/yyyy'
          margin='normal'
          placeholder='Birthday (MM/DD/YYYY)'
          disabled={isUpdating}
          className={classes.datePicker}
        />
      </CardContent>
      <CardActions className={classes.cardActions}>
        <ProgressButton 
          showProgress={isUpdating}
          variant='contained' 
          color='primary' 
          className={classes.nextButton}
          type='submit'
          disabled={!allInputFilled}
        >
          Save
        </ProgressButton>
      </CardActions>
    </form>
  );
}

export default AddPlayerDetails;