import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { 
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { TimeSeriesColorScale } from '../../lineGraph/lineGraph.constants';
import { addSelectedMetric, removeSelectedMetric } from '../../../store/selectedGraphMetrics';

const MetricSelection = ({ 
  allMetrics, 
  isDisabled, 
  classes, 
  useMetricColors,
  onLabelMouseEnter,
  onLabelMouseLeave
}) => {
  const selectedMetricKeys = useSelector(state => state.selectedGraphMetrics);
  const dispatch = useDispatch();

  const changeMetric = metricKey => {
    if (selectedMetricKeys.includes(metricKey)) {
      dispatch(removeSelectedMetric(metricKey));
    } else {
      dispatch(addSelectedMetric(metricKey));
    }
  };

  const getCheckboxColor = metricKey => {
    const colorIdx = selectedMetricKeys.indexOf(metricKey);
    return colorIdx === -1 ? 'black' : TimeSeriesColorScale[colorIdx % selectedMetricKeys.length];
  };

  return (
    <Paper className={classes.container}>
      <Grid container spacing={0}>
        {allMetrics.map(metric => (
          <Grid item key={metric.key} xs={12} sm={4} lg={2}>
            <FormControlLabel 
              className={onLabelMouseEnter ? classes.formControlLabel : null}
              onMouseEnter={() => onLabelMouseEnter && onLabelMouseEnter(metric.key)}
              onMouseLeave={onLabelMouseLeave}
              label={<Typography className={classes.label}>{metric.displayName}</Typography>}
              control={
                <Checkbox
                  disabled={isDisabled}
                  checked={selectedMetricKeys.includes(metric.key)}
                  onChange={() => changeMetric(metric.key)}
                  style ={{
                    color: useMetricColors ? getCheckboxColor(metric.key) : 'black'
                  }}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

MetricSelection.propTypes = {
  allMetrics: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    displayName: PropTypes.string
  })).isRequired,
  isDisabled: PropTypes.bool
};

export default withStyles(styles)(MetricSelection);