import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  container: {
    position: 'absolute',
    left: '50px',
    zIndex: 0
  },
  loading: {
    position: 'absolute',
    left: '6px',
    top: '6px'
  }
}));

export default useStyles;