import _ from 'lodash';
import { PERMISSIONS } from '../constants/permissions.constants';
import store from '../store';

export const getDownloadPermissionsForCurrentOrg = () => {
  // feature permissions get loaded in app.js so 
  // they will be in state whenever this method gets called.
  const state = store.getState();

  let showDownloadMotions = _.includes(state.featurePermissions, 
    PERMISSIONS.canDownloadMotionCsv);
  let showDownloadSummaries = _.includes(state.featurePermissions, 
    PERMISSIONS.canDownloadSummariesCsv);  
  return { showDownloadMotions, showDownloadSummaries };
};
