import Palette from '../../styles/palette';

const styles = {
  mainContainer: {
    position: 'absolute', 
    top: '64px', 
    bottom: '0', 
    left: 0, 
    right: 0, 
    padding: '25px', 
    backgroundColor: Palette.backgroundColor
  }
};

export default styles;