import Network from './network';

const URL = 'API/TrainingEvents';

export const GetTrainingEvents = async (userId, params, cancellationToken) => {
  const resp = await Network.Get(URL, { userId, ...params }, cancellationToken);
  return resp.data.items;
};

export const CreateTrainingEvent = async trainingEvent => {
  const resp = await Network.Post(URL, trainingEvent);
  return resp.data;
};