import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import { formatMonthDayTime } from '../../../../../../../utils/formatting.utils';

function MotionCard({ motion, selected, onClick }) {
  const classes = useStyles();
  const className = classNames(classes.container, { [classes.selected]: selected });

  return <Grid item className={className} onClick={onClick}>
    <Typography>{formatMonthDayTime(motion.timestamp)}</Typography>
    <Typography align='center'>
      {motion.launchMonitorData != null
        ? `${motion.launchMonitorData.ballspeedMph} mph`
        : 'N/A'}
    </Typography>
  </Grid>;
}

MotionCard.propTypes = {
  motion: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default MotionCard;
