import React from 'react';
import { ButtonBase, CircularProgress } from '@material-ui/core';
import { Save } from '@material-ui/icons';

import useStyles from './styles';

import ButtonContainer from '../buttonContainer';

function SaveButton({ onClick, saving }) {
  const classes = useStyles();
  const startSaving = () => {
    if (!saving) {
      onClick();
    }
  };

  return (
    <div className={classes.container}>
      <ButtonBase disableRipple={saving}>
        <ButtonContainer onClick={startSaving}>
          {
            !saving &&
            <Save />
          }
          {
            saving &&
            <div className={classes.loading}>
              <CircularProgress size={30} />
            </div>
          }
        </ButtonContainer>
      </ButtonBase>
    </div>
  );
}

export default SaveButton;