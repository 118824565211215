import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../styles/palette';
import { CHARACTERISTIC_SEVERITY } from '../../../constants/videoAnalysis.constants';

const useStyles = makeStyles(_ => ({
  selectContainer: {
    width: '100%', 
    padding: '10px', 
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  motionSelectContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  motionSelectFormControl: {
    minWidth: '300px'
  },
  attributeSelectFormControl: {
    minWidth: '250px'
  },
  motionSelectTimestamp: {
    fontSize: 'small',
    color: palette.darkGray
  },
  bodyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '70px'
  },
  drawingControlsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  videoCanvasContainer: {
    flexGrow: 1,
    margin: '0 10px',
    position: 'relative'
  },
  canvasContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2
  },
  reviewControls: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '15px'
  },
  secondaryVideoControls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap'
  },
  playbackRateContainer: {
    marginRight: '7px'
  },
  canvasOverlay: {
    zIndex: 3
  },
  circularProgressContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 4,
    background: 'rgba(120, 120, 120, 0.3)'
  },
  savedLabel: {
    color: palette.success,
    padding: '10px'
  },
  analysesListContainer: {
    marginTop: '75px',
    marginRight: '20px'
  },
  requestButton: {
    marginTop: '20px',
    width: '100%'
  },
  statusMessage: {
    margin: '50px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  analysisSelection: {
    marginTop: '15px',
    marginLeft: '15px',
    marginBottom: '15px'
  },
  selectionTools: {
    width: '40%',
    maxWidth: '700px',
    minWidth: '300px',
    padding: '0 10px'
  },
  selectionContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  characteristicSelection: {
    marginBottom: '30px'
  },
  trimmingControls: {
    display:'flex',
    justifyContent: 'space-between'
  },
  trimmingSetter: {
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  trimmingUserInput: {
    margin: 5,
    width: 80,
    outline: 'none',
    border: 0,
    background: 'rgba(0, 0, 0, 0)',
    textAlign: 'center'
  },
  severityContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span': {
      whiteSpace: 'nowrap'
    }
  },
  severityText: {
    fontWeight: 'bold'
  },
  severityDot: ({ severity }) => ({
    width: '15px',
    height: '15px',
    borderRadius: '100%',
    border: `1px solid ${palette.darkGray}`,
    backgroundColor: severity === CHARACTERISTIC_SEVERITY.none ? 
      palette.subtleGreen : palette.subtleRed,
    marginRight: '5px'
  }),
  trimmingButton: {
    marginBotton: 10
  },
  finishedButton: {
    marginTop: '150px'
  }
}));

export default useStyles;
