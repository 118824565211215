import makeStyles from '@material-ui/core/styles/makeStyles';
import Palette from 'styles/palette';

export default makeStyles(() => ({
  paperContainer: {
    width: '100%',
    height: '100%',
    paddingBottom: '20px'
  },
  paperContent: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '20px'
  },
  status: {
    padding: 10
  },
  dateContainer: {
    display: 'flex'
  },
  serialCodeChip: {
    margin: '2px 3px'
  },
  qrHeader: {
    paddingBottom: 15
  },
  qrContainer: {
    backgroundColor: 'white',
    padding: 20
  },
  qrCode: {
    minHeight: '50vh',
    width: '100%' 
  },
  rosterRefreshStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '25px'
  },
  successRefresh: {
    color: Palette.success
  }
}));
