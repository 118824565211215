import palette from '../../styles/palette';

const styles = {
  videoCard: {
    maxWidth: '350px',
    paddingBottom: '10px'
  },
  thumbnail: {
    width: '295px',
    height: '166px',
    padding: '20px',
    display: 'inline-block'
  },
  videoTitle: {
    marginTop: '10px'
  },
  playIconContainer: {
    position: 'absolute'
  },
  hidden: {
    visibility: 'hidden'
  },
  playButton: {
    fontSize: '96px',
    color: 'white'
  },
  opaque: {
    opacity: 0.20,
    backgroundColor: palette.gray
  },
  subHeader: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  deleteButton: {
    padding: '5px',
    float: 'right'
  }
};

export default styles;
