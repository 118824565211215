import React from 'react';
import PropTypes from 'prop-types';
import logger from 'js-logger';
import { connect } from 'react-redux';
import MobilityScreenTable from '../overview/mobilityScreenTable';
import { 
  Grid,
  Fade
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { SetCurrentPlayer, SetPlayerMeasurements } from '../../../store/players';
import { PutMobilityScreen } from '../../../network/userRequests';
import MeasurementsPanel from './measurementsPanel';
import styles from './styles';
import NewMobilityScreen from './newMobilityScreen';
import ErrorSnackbar from '../../../components/errorSnackbar';

class Mobility extends React.Component {
  state = {
    savingMeasurements: false,
    waistInches: '',
    shoulderInches: '',
    editingScreens: false,
    errMsg: null
  };

  _saveScreenData = async (screen) => {
    const { userId } = this.props.match.params;
    try {
      await PutMobilityScreen(userId, screen);
      this.setState({ editingScreens: false });
    } catch (e) {
      const errMsg = 'Something went wrong when saving the mobility screen. Please try again.';
      logger.error(errMsg, e);
      this.setState({ errMsg });
    }
  }

  render() {
    const { userId } = this.props.match.params;
    const { classes } = this.props;
    const { editingScreens, errMsg } = this.state;
    const transitionDurationMs = 400;

    return (
      <Grid container justify='space-evenly' className={classes.container}>
        <ErrorSnackbar message={errMsg} onClose={() => this.setState({ errMsg: null })}/>
        <Grid item xs={12} lg={4}>
          <Fade 
            in={!editingScreens}
            unmountOnExit
            timeout={transitionDurationMs}
            exit={false}
          >
            <div className={classes.screenTableContainer}>
              <MobilityScreenTable 
                userId={userId}
                onAddNew={() => this.setState({ editingScreens: !editingScreens })} 
              />
            </div>
          </Fade>
          <Fade 
            in={editingScreens} 
            timeout={transitionDurationMs}
            exit={false}
            unmountOnExit
          >
            <div className={classes.newScreenContainer}>
              <NewMobilityScreen 
                onSave={this._saveScreenData} 
                onCancel={() => this.setState({ editingScreens: false })}
              />
            </div>
          </Fade>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div className={classes.screenTableContainer}>  
            <MeasurementsPanel
              key={this.props.user.measurements}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

Mobility.propTypes = {
  user: PropTypes.object  
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => dispatch(SetCurrentPlayer(user)),
    setPlayerMeasurements: measurement => dispatch(SetPlayerMeasurements(measurement))
  };
};

const mapStateToProps = state => {
  return {
    user: state.currentPlayer
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Mobility));