import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateRangeSelector from '../../../../components/dateRangeSelector';
import { isValidDateString } from '../../../../utils/formatting.utils';
import useStyles from './styles';

const DATE = 'date';
const PICKER_ITEMS = [
  { value: DATE, label: 'Date' },
  { value: 10, label: 'Last 10 swings' },
  { value: 20, label: 'Last 20 swings' },
  { value: 30, label: 'Last 30 swings' }
];

function MotionFilterSelector({ onChange, laterThan, earlierThan, numMotions }) {
  const classes = useStyles();
  const initialState = {
    value: numMotions != null ? numMotions : DATE,
    laterThan: isValidDateString(laterThan) ? new Date(laterThan) : new Date(),
    earlierThan: isValidDateString(earlierThan) ? new Date(earlierThan) : new Date()
  };
  const [state, setState] = useState(initialState);
  useEffect(() => setState({
    value: numMotions != null ? numMotions : DATE,
    laterThan: isValidDateString(laterThan) ? new Date(laterThan) : new Date(),
    earlierThan: isValidDateString(earlierThan) ? new Date(earlierThan) : new Date()
  }), [numMotions, laterThan, earlierThan]);

  function onSelectChange(e) {
    const { value } = e.target;
    if (value === DATE) {
      setState(prev => ({ ...initialState, value: DATE }));
    } else {
      setState(prev => ({ ...prev, value }));
      onChange({ laterThan: null, earlierThan: null, numMotions: value });
    }
  }

  function handleDateChange(date, isLaterThan) {
    if (isLaterThan) {
      setState(prev => ({ ...prev, laterThan: date }));
    } else {
      setState(prev => ({ ...prev, earlierThan: date }));
    }
  }

  function submitDateRangeChange() {
    onChange({ 
      numMotions: null, 
      laterThan: state.laterThan != null ? state.laterThan.toISOString() : null, 
      earlierThan: state.earlierThan != null ? state.earlierThan.toISOString() : null 
    });
  }

  return <div className={classes.container}>
    <FormControl>
      <Select value={state.value} onChange={onSelectChange}>
        {PICKER_ITEMS.map(item => <MenuItem value={item.value} key={item.value}>
          {item.label}
        </MenuItem>)}
      </Select>
    </FormControl>
    <DateRangeSelector hidden={state.value !== DATE}
      fromDate={state.laterThan}
      toDate={state.earlierThan}
      handleDateChange={handleDateChange}
      submitDateRange={submitDateRangeChange}
    />
  </div>;
};

export default MotionFilterSelector;