import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  Grid,
  IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import styles from './styles';
import VideoStatus from '../../constants/video.constants';
import { formatDateWithWrittenMonth } from '../../utils/formatting.utils';

/* Video cards can be used to display videos available.
 * They will display thumbnails instead of iframe or video elements.
 */
const VideoCard = ({ video, includeDate, onClick, classes, onDelete }) => {
  const [ showPlayButton, setShowPlayButton ] = useState(false);
  const isProcessing = video.status !== VideoStatus.available;

  return (
    <Card className={classes.videoCard}>
      <CardActionArea 
        onClick={() => !isProcessing && onClick(video)}
        onMouseEnter={() => setShowPlayButton(true)}
        onMouseLeave={() => setShowPlayButton(false)}
      >
        <Grid container direction='column' justify='center' alignItems='center'>
          <Grid item xs={12}>
            {video.thumbnailUrl
              ? <CardMedia 
                image={video.thumbnailUrl}
                className={classNames(classes.thumbnail)}
              />
              : <div className={classNames(classes.thumbnail, classes.opaque)}></div>
            }
            
          </Grid>
          <Grid item container alignItems='center' direction='column' zeroMinWidth xs={12}>
            <Typography variant='h6' noWrap className={classes.videoTitle}>{video.title}</Typography>
            {includeDate && 
              <Typography noWrap className={classes.subHeader}>{
                formatDateWithWrittenMonth(video.creationTimestamp)}
              </Typography>
            }
          </Grid>
          <div className={classNames(
            classes.playIconContainer, 
            { [classes.hidden]: !showPlayButton || isProcessing })}
          >
            <PlayArrowIcon className={classes.playButton}/>
          </div>
          <div className={classNames(
            classes.playIconContainer, 
            { [classes.hidden]: !isProcessing })}
          >
            <Typography variant='h5'>Processing...</Typography>
          </div>
        </Grid>
      </CardActionArea>
      {onDelete && 
        <Grid item>
          <IconButton className={classes.deleteButton} onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      }
    </Card>
  );
};

VideoCard.propTypes = {
  video: PropTypes.object.isRequired, // The video object that gets return from the api
  includeDate: PropTypes.bool, // If true, will display the date of the video underneath the title
  onClick: PropTypes.func // Handler for the event clicks. The video clicked will be passed into the method.
};

export default withStyles(styles)(VideoCard);