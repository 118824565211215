import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  sequenceContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    maxWidth: '100%'
  },
  segment: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  segmentLabel: {
    width: ({ widthOfImage }) => widthOfImage + 'px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  bodySegmentGraphic: {
    display: 'flex',
    width: ({ widthOfImage }) => widthOfImage + 'px'
  },
  arrow: {
    width: ({ widthOfArrow }) => widthOfArrow + 'px'
  }
}));

export default useStyles;