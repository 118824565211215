import { startCase } from 'lodash';

const styles = {
  LAUNCH_MONITOR: [
    'ballspeedMph'
  ],
  HITTING_DEVICE_SENSOR: [
    'attackAngleDegrees', 
    'deviceSpeedMph', 
    'timeToImpactSeconds',
    'rotationalAccelerationGs',
    'verticalBatAngleDegrees'
  ],
  FORCE_PLATE: [
    'torqueBackPeakValue', 
    'torqueFrontPeakValue', 
    'xBackPeakValue', 
    'xFrontPeakValue', 
    'zBackPeakValue', 
    'zFrontPeakValue', 
    'xTiming', 
    'heelLoad'
  ]
};

export default styles;

export const TitleFormatter = {
  default: startCase,
  rotationalAccelerationGs: () => 'Rotational Accel G\'s',
  verticalBatAngleDegrees: () => 'Vertical Bat Angle'
};
