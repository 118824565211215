import Network from './network';

const DELIVERIES = 'API/ScheduledContentDeliveries';

export const GetScheduledContentDeliveries = async (organizationId, cancelToken) => {
  const resp = await Network.Get(DELIVERIES, { organizationId }, cancelToken);
  return resp.data.items;
};

export const GetScheduledContentDelivery = async (id, cancelToken) => {
  const resp = await Network.Get(DELIVERIES + '/' + id, null, cancelToken);
  return resp.data;
};

export const PostScheduledContentDelivery = async (delivery) => {
  const resp = await Network.Post(DELIVERIES, delivery);
  return resp.data;
};