import { makeStyles } from '@material-ui/styles';
import palette from '../../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  container: {
    display: 'flex',
    width: '40px',
    height: '40px',
    backgroundColor: palette.lightGray,
    border: `1px solid ${palette.mediumGray}`,
    borderRadius: '2px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&>*': {
      width: '60%'
    }
  }
}));

export default useStyles;