import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { 
  Dialog,
  DialogTitle,
  DialogActions,
  Button
} from '@material-ui/core';
import TeamAssignment from './teamAssignment';
import keyBy from 'lodash/keyBy';
import styles from './styles';
import { makeStyles } from '@material-ui/core'; 


const AssignTeams = ({ user, teams, onCancel, onSubmit }) => {
  const [selectedTeams, setSelectedTeams] = useState([]);
  const classes = makeStyles(_ => styles)();

  useEffect(() => {
    const teamsById = keyBy(teams, team => team.id);
    const userTeams = user 
      ? user.organizationUserTeams
        .map(userTeam => teamsById[userTeam.teamId])
        .filter(team => team != null)
      : [];
    setSelectedTeams(userTeams);
  }, [user, teams]);

  const submitClick = () => {
    onSubmit({ user, selectedTeams });
  };

  const cancelClick = () => {
    onCancel();
  };

  return (
    <Dialog open={user != null} fullWidth maxWidth='lg'>
      <div className={classes.dialog}>
        <DialogTitle>
          Team Assignment
        </DialogTitle>
        <TeamAssignment 
          userName={user ? user.firstName : undefined} 
          initialSelectedTeams={selectedTeams} 
          teams={teams}
          onChange={setSelectedTeams} />
        <DialogActions>
          <Button onClick={cancelClick}>Cancel</Button>
          <Button onClick={submitClick} color='primary'>Submit</Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

AssignTeams.propTypes = {
  user: propTypes.object, // the dialog will not show if user is null
  teams: propTypes.arrayOf(propTypes.object), // all teams in the organization
  onSubmit: propTypes.func, // will pass object with user and selectedTeams
  onCancel: propTypes.func // function called when cancel button clicked
};

export default AssignTeams;