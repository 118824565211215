import { makeStyles } from '@material-ui/styles';
import palette from '../../../../styles/palette';

const useStyles = makeStyles(_theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px'
  },
  hoveredItem: {
    backgroundColor: palette.lightGray,
    border: `1px solid ${palette.darkGray}`,
    borderRadius: '5px'
  },
  legendAdornmentContainer: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    marginRight: '10px'
  },
  legendMarker: {
    width: '12px', 
    height: '12px', 
    borderRadius: '100%'
  }
}));

export default useStyles;
