import React from 'react';
import propTypes from 'prop-types';
import Player from '@vimeo/player';
import palette from '../../styles/palette';

class VimeoPlayer extends React.Component {
  player = null;
  divRef = React.createRef();

  componentDidMount() {
    this._loadVideo();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      await this.player.unload();
      this._loadVideo();
    }
  }

  _loadVideo = () => {
    const {
      url, 
      onPlay, 
      shouldPlay, 
      loop, 
      showTitle, 
      showByline, 
      color 
    } = this.props;
    
    this.player = new Player(this.divRef.current, {
      url,
      loop,
      title: showTitle,
      byline: showByline,
      responsive: true,
      color
    });

    this.player.on('play', onPlay);
    shouldPlay && this.player.play();
  }

  render() {
    return <div ref={this.divRef} />;
  }
}

VimeoPlayer.defaultProps = {
  onPlay: () => {},
  loop: true,
  showTitle: false,
  showByline: false,
  color: palette.vimeoPlayerColor
};

VimeoPlayer.propTypes = {
  id: propTypes.number.isRequired,
  url: propTypes.string.isRequired,
  shouldPlay: propTypes.bool,
  onPlay: propTypes.func,
  loop: propTypes.bool,
  showTitle: propTypes.bool,
  showByline: propTypes.bool,
  color: propTypes.string
};

export default VimeoPlayer;
