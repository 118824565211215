import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import logger from 'js-logger';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { PERMISSIONS } from '../../../constants/permissions.constants';
import useStyles from './styles';
import ProgressButton from '../../../components/progressButton';
import { SendSetPasswordEmail, PatchUser } from '../../../network/userRequests';
import { UpdateCurrentPlayer } from '../../../store/players';
import { Button, Grid, TextField } from '@material-ui/core';
import { formatMMDDYY } from '../../../utils/formatting.utils';
import Logger from 'js-logger';
import OrgProperties from './orgProperties';

const EMAIL_STATE = {
  SENDING: 'sending',
  SENT: 'sent',
  ERROR: 'error'
};

function AccountInfo ({ className }) {
  const classes = useStyles();
  const { currentPlayer, featurePermissions } = useSelector(({ 
    currentPlayer, featurePermissions
  }) => ({ currentPlayer, featurePermissions }));
  const canSendSetPasswordEmail = useMemo(
    () => featurePermissions.includes(PERMISSIONS.kGoFeatureSet), 
    [featurePermissions]);
  const canEditEmail = featurePermissions.includes(PERMISSIONS.internalTooling);
  const [emailState, setEmailState] = useState(null);
  const [inEdit, setInEdit] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [updateFailed, setUpdateFailed] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();

  async function sendPassworSetEmail() {
    const { userId } = currentPlayer;
    setEmailState(EMAIL_STATE.SENDING);
    try {
      await SendSetPasswordEmail(userId);
      setEmailState(EMAIL_STATE.SENT);
    } catch (e) {
      setEmailState(EMAIL_STATE.ERROR);
      logger.error(e, `Error sending set password email for user ${userId}.`);
    }
  }

  function toggleEdit() {
    if (!inEdit) {
      setFirstName(currentPlayer.firstName);
      setLastName(currentPlayer.lastName);
      setEmailAddress(currentPlayer.emailAddress);
      setUpdateFailed(false);
    }
    setInEdit(prev => !prev);
  };

  async function updatePlayer() {
    let changes = [];
    if (firstName !== currentPlayer.firstName) {
      changes.push({
        op: 'replace',
        path: '/firstName',
        value: firstName
      });
    }
    if (lastName !== currentPlayer.lastName) {
      changes.push({
        op: 'replace',
        path: '/lastName',
        value: lastName
      });
    }
    if (emailAddress !== currentPlayer.emailAddress) {
      changes.push({
        op: 'replace',
        path: '/emailAddress',
        value: emailAddress
      });
    }
    setIsUpdating(true);
    try {
      const updatedUser = await PatchUser(currentPlayer.userId, changes);
      dispatch(UpdateCurrentPlayer(updatedUser));
      setUpdateFailed(false);
      setInEdit(false);
    } catch(e) {
      Logger.error(`Error when updating a playername: ${e}`);
      setUpdateFailed(true);
    }
    setIsUpdating(false);
  }

  const canUpdateName = firstName !== currentPlayer.firstName || 
    lastName !== currentPlayer.lastName ||
    emailAddress !== currentPlayer.emailAddress;

  return <div className={classNames(classes.container, className)}>
    <Grid container>
      <div>
        <Typography className={classes.header} variant='h4' component='h4' > 
          Player Info
        </Typography>
        <Grid container>
          <Typography>First Name:</Typography>
          {inEdit 
            ? <TextField 
              size='small' 
              variant='outlined' 
              value={firstName} 
              onChange={(e) => setFirstName(e.target.value)}
            />
            : <Typography>{currentPlayer.firstName}</Typography>
          }
          
        </Grid>
        <Grid container>
          <Typography>Last Name:</Typography>
          {inEdit 
            ? <TextField 
              size='small' 
              variant='outlined' 
              value={lastName} 
              onChange={(e) => setLastName(e.target.value)}
            />
            : <Typography>{currentPlayer.lastName}</Typography>
          }
        </Grid>
        <Grid container>
          <Typography>Email:</Typography>
          {inEdit && canEditEmail
            ? <TextField 
              size='small' 
              variant='outlined' 
              value={emailAddress} 
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            : <Typography>{currentPlayer.emailAddress}</Typography>
          }
        </Grid>
        <Typography> 
          DOB: {currentPlayer.dateOfBirth 
            ? formatMMDDYY(currentPlayer.dateOfBirth) 
            : 'N/A' } 
        </Typography>
        <Grid container>
          <Button variant='outlined' color='primary' onClick={toggleEdit}>
            {inEdit ? 'Cancel' : 'Edit'}
          </Button>
          {inEdit &&  
            <ProgressButton 
              onClick={updatePlayer} 
              disabled={!canUpdateName || isUpdating} 
              variant='outlined' 
              color='primary'
              className={classes.saveButton}
              showProgress={isUpdating}
            >
              Save
            </ProgressButton>
          }
          {updateFailed && <Typography color='error'>The update failed, please try again.</Typography>}
        </Grid>
        
        {canSendSetPasswordEmail && <div className={classes.sendEmailContainer}>
          {emailState === EMAIL_STATE.SENT 
            ? <Typography className={classes.sentEmailMessage} variant='button'>
              Set Password Email Sent!
            </Typography>
            : <div className={classes.sendEmailButtonContainer}>
              <ProgressButton 
                showProgress={emailState === EMAIL_STATE.SENDING} 
                disabled={emailState === EMAIL_STATE.SENT}
                color='primary'
                variant='outlined'
                onClick={sendPassworSetEmail}
              >
                Send Password Set Email
              </ProgressButton>
              {emailState === EMAIL_STATE.ERROR && 
                <Typography 
                  className={classNames(classes.sentEmailMessage, classes.errorText)} 
                  variant='button'
                >
                  Error sending email, please try again.
                </Typography>}
            </div>}
        </div>}
      </div>
      <OrgProperties
        userId={currentPlayer.userId}
        organizationUserProperties={currentPlayer.organizationUserProperties}      
      />
    </Grid>
  </div>;
};


export default AccountInfo;
