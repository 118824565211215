import palette from '../../../../styles/palette';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  planContainer: {
    background: palette.white,
    padding: '20px',
    paddingTop: '8px',
    marginBottom: '15px',
    borderRadius: '4px'
  },
  timeLabel: {
    textAlign: 'right',
    color: palette.almostBlackBlue
  },
  text: {
    whiteSpace: 'pre-wrap'
  },
  footer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    color: palette.almostBlackBlue
  },
  expandDrillVideos: {
    cursor: 'pointer',
    '&:hover': {
      color: palette.black
    }
  },
  expandIcon: {
    transform: 'translateY(25%)'
  },
  drillsCollapse: {
    display: 'flex',
    overflowX: 'auto',
    alignItems: 'flex-end'
  },
  videoCard: {
    margin: '5px'
  },
  pendingStatus: {
    color: palette.darkGray
  }
}));

export default useStyles;