import React, { 
  useMemo
} from 'react';

import useStyles from './styles';
import { BADGES_MAP } from './../badgeUtils';
import { CustomCircularProgress } from './../../styles';

const MAX_FEED_NUMBER = 5;

function BadgeNotification({
  badge,
  idMap = {}
}) {
  const classes = useStyles();
  const badgeInfo = BADGES_MAP[badge.badgeId];
  const data = idMap[badge.id] || null;
  return <>
    {
      data && badgeInfo &&
      <div className={classes.badgeNotification}>
        <img alt={badgeInfo.name} className={classes.badgeImg} src={badgeInfo.img} />
        <div className={classes.message}>
          <span className={classes.bold}>{data.name}</span>
          <span>&nbsp;recieved&nbsp;</span>
          <span className={classes.bold}>{badgeInfo.name}!</span>
        </div>
      </div>
    }
  </>;
}

function BadgeMiniFeed({
  badgesFeed = null,
  idMap = null,
  curInterval = null,
  loading = false,
  leaderboardStarted = false,
  onOpenBadgeModal = () => {}
}) {
  const classes = useStyles();

  const badgesEarnedToday = useMemo(() => {
    if (badgesFeed == null || !idMap || !curInterval) return null;
    return badgesFeed.filter(({ id, interval }) => {
      // only get badges aquired for the current user
      if (!idMap[id] || !idMap[id].selected) return false;
      // only get badges for the current day
      return interval === curInterval;
    });
  }, [badgesFeed, idMap, curInterval]);

  return (
    <div className={classes.container}>
      {
        loading && <CustomCircularProgress/>
      }
      {
        !loading && !leaderboardStarted &&
        <div className={classes.newBadges}>
          Check back for badges once this Game has started!
        </div>
      }
      {
        !loading && badgesFeed && leaderboardStarted &&
        <div onClick={() => onOpenBadgeModal()} className={classes.container}>
          {
            badgesEarnedToday && badgesEarnedToday.length > 0 &&
            <div 
              onClick={() => onOpenBadgeModal(false)}
              className={classes.newBadges}>
              <div>
                {
                  badgesEarnedToday.map(({ badgeId }, idx) => {
                    const { img, name } = BADGES_MAP[badgeId];
                    return <img key={idx} alt={name} className={classes.badgeImg} src={img} />;
                  })
                }
              </div>
              <div>You Earned Badges Today!</div>
            </div>
          }
          <div className={classes.feed}>
            {
              badgesFeed && badgesFeed.length <= 0 &&
              <div>No Badges Earned So Far This Game</div>
            }

            {
              badgesFeed && badgesFeed.length > 0 &&
              <>
                {
                  badgesFeed.slice(0, MAX_FEED_NUMBER).map((badge, idx) => {
                    return <BadgeNotification
                      key={idx}
                      badge={badge}
                      idMap={idMap}
                    />;
                  })
                }
              </>
            }

          </div>
          <div className={classes.seeAll}>
            <span>See All Badges</span>
          </div>
        </div>
      }
    </div>
  );
};

export default BadgeMiniFeed;