import React from 'react';
import WarningIcon from '@material-ui/icons/WarningRounded';
import ErrorIcon from '@material-ui/icons/ErrorRounded';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { ANALYSIS_STATUS } from '../../constants/videoAnalysis.constants';
import {
  pastDueColor,
  stuckInProcessingColor,
  lastDayColor
} from './tables/styles';

// using styles as this isn't a component that would use style hooks
export const Icons = {
  lastDayIcon: <WarningIcon style={{ color: lastDayColor }} />,
  pastDueIcon: <ErrorIcon style={{ color: pastDueColor }} />,
  contactDevIcon: <PriorityHighIcon style={{ color: stuckInProcessingColor }} />
};

export const SCHEDULING_STATUS = {
  pastDue: 'Past due',
  contactDev: 'Contact dev',
  lastDay: 'Due today'
};

const SchedulingStatusToIcon = {
  [SCHEDULING_STATUS.pastDue]: Icons.pastDueIcon,
  [SCHEDULING_STATUS.lastDay]: Icons.lastDayIcon,
  [SCHEDULING_STATUS.contactDev]: Icons.contactDevIcon 
};

export const getSchedulingStatusForAnalysis = (analysis) => {
  let status;
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let dueDate = new Date(analysis.dueDate);
  dueDate.setHours(0, 0, 0, 0);

  if (analysis.status === ANALYSIS_STATUS.submitted && 
    (today - dueDate >= 1)) {
    status = SCHEDULING_STATUS.contactDev;
  }
  else if (today > dueDate) {
    status = SCHEDULING_STATUS.pastDue;
  }
  else if (today === dueDate) {
    status = SCHEDULING_STATUS.lastDay;
  }
  return status;
};

export const getIconForAnalysis = (analysis) => {
  const status = getSchedulingStatusForAnalysis(analysis);
  return SchedulingStatusToIcon[status];
};

export const statusToLabel = {
  [ANALYSIS_STATUS.submitted]: 'Processing',
  [ANALYSIS_STATUS.annotated]: 'Has Annotation',
  [ANALYSIS_STATUS.readyForAnnotation]: 'Needs Annotation',
  [ANALYSIS_STATUS.needsActionPlan]: 'Needs Action Plan'
};