import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  listItemContents: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default useStyles;
