import Palette from '../../../styles/palette';

const styles = {
  container: {
    padding: '10px'
  },
  label: {
    fontSize: '13px'
  },
  formControlLabel: {
    '&:hover': {
      backgroundColor: Palette.lightGray
    }
  }
};

export default styles;
