import { makeStyles } from '@material-ui/styles';
import palette from '../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  listItem: {
    padding: 0,
    margin: 0,
    cursor: 'pointer'
  },
  container: {
    width: '100%', 
    '&:hover': {
      backgroundColor: palette.lightGray
    },
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px'
  },
  title: {
    fontWeight: 'bold'
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    width: '100%',
    marginBottom: '7px',
    fontSize: '13px',
    textAlign: 'left'
  },
  drills: {
    width: '100%',
    textAlign: 'left',
    fontStyle: 'italic',
    fontSize: '13px'
  },
  hidden: {
    visibility: 'hidden'
  }
}));

export default useStyles;