import React, { useState } from 'react';
import { connect } from 'react-redux';
import Logger from 'js-logger';
import startCase from 'lodash/startCase';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { PutHittingDeviceSensor, PutForcePlate, DeleteMotion } from '../../../../network/motionRequests';
import KinematicSequenceGraphDark from '../../../../components/kinematicSequenceGraphDark';
import { PERMISSIONS } from '../../../../constants/permissions.constants';
import DeviceKeysConstants from '../../../../constants/DeviceKeys.constants';
import DataInput from './dataInput';
import ErrorSnackbar from '../../../../components/errorSnackbar';
import UnitConverter from '../../../../utils/unitConverter.utils';
import useAnalyzedMotionData from './useAnalyzedMotionData';
import useStyles from './styles';
import { formatMonthDayTime } from '../../../../utils/formatting.utils';
import useConstantlySaveOnUpdate from '../../../../utils/useConstantlySaveOnUpdate';
import BodyParts from '../../../../constants/bodyParts.constants';
import MotionTypes from '../../../../constants/motionTypes.constants';
import StatsTile from '../../../../components/statsTile';
import useUpdatedFullMotionMetrics from './useUpdatedFullMotionMetrics';

function MotionDetailsPanel({ motion, onSave, onDeleted, onClose, featurePermissions }) {
  const { analyzedMotionData, loading, error: analyedDataErr } = useAnalyzedMotionData(motion.id);
  const { fullMotionMetrics: updatedFullMotionMetrics } = useUpdatedFullMotionMetrics(motion.id);
  const [savingForcePlate, setSavingForcePlate] = useState(null);
  const [savingHittingDeviceSensor, setSavingHittingDeviceSensor] = useState(false);

  const [error, setError] = useState(null);
  const canInputThirdPartyData = featurePermissions.includes(PERMISSIONS.canInputThirdPartyData);
  const showSpeedTiles = featurePermissions.includes(PERMISSIONS.internalTooling);
  const classes = useStyles();
  
  const [hittingDeviceData, setHittingDeviceData] = useState({});
  const [forcePlateData, setForcePlateData] = useState({});

  const isPitching = motion.motionType === MotionTypes.baseballPitch;
  const bodySegments = [
    BodyParts.pelvis, 
    BodyParts.torso, 
    BodyParts.upperArm,
    isPitching ? BodyParts.lowerArm : BodyParts.hand
  ];
  const fullMotionMetrics = updatedFullMotionMetrics ?? motion.fullMotionMetrics;

  async function forcePlateToServer() {
    const data = forcePlateData;
    setSavingForcePlate(true);
    try {
      const resp = await PutForcePlate(motion.id, data);
      const id = resp.data ? resp.data.id : null;
      onSave({ ...data, id }, 'forcePlate');
    } catch (e) {
      const errorStr = 'Error saving force plate data';
      setError(errorStr);
      Logger.error(`${errorStr}: ${e}`);
    }
    setSavingForcePlate(false);
  }

  const setLastUpdatedDateForForcePlate = useConstantlySaveOnUpdate(forcePlateToServer);

  async function saveForcePlate(data) {
    setForcePlateData(data);
    setLastUpdatedDateForForcePlate(new Date());
  }

  async function hittingDeviceToServer() {
    setSavingHittingDeviceSensor(true);
    const data = hittingDeviceData;
  
    try {
      let convertedData = { ...data };
      const { deviceSpeedMph } = data;
      if (deviceSpeedMph !== null) {
        convertedData.deviceSpeedMetersPerSecond = UnitConverter.mphToMetersPerSecond(
          deviceSpeedMph);
      }
      const resp = await PutHittingDeviceSensor(motion.id, convertedData);
      const id = resp.data ? resp.data.id : null;
      onSave({ ...data, id }, 'hittingDeviceSensor');
    } catch (e) {
      const errorStr = 'Error saving hitting device sensor data';
      setError(errorStr);
      Logger.error(`${errorStr}: ${e}`);
    }
    setSavingHittingDeviceSensor(false);
  }

  const setLastUpdatedDateForHittingDeviceSensor = useConstantlySaveOnUpdate(hittingDeviceToServer);

  async function saveHittingDeviceSensor(data) {
    setHittingDeviceData(data);
    setLastUpdatedDateForHittingDeviceSensor(new Date());
  };

  async function deleteMotion() {
    const { id } = motion;
    if (!window.confirm('Are you sure you want to delete this motion?')) return;
    try {
      await DeleteMotion(id);
      onDeleted(id);
    } catch (e) {
      Logger.error(`Error deleting motion with id ${id}: ${e}`);
      setError('There was an error deleting this motion, try again.');
    }
  }

  return <div className={classes.container}>
    <ErrorSnackbar message={error} onClose={() => setError(null)} />
    <div className={classes.headerContainer}>
      <div className={classes.headerContents}>
        <Typography variant='h5'>{formatMonthDayTime(motion.timestamp)}</Typography>
        <IconButton onClick={onClose} color='default'>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider orientation='horizontal' className={classes.divider}/>
    </div>
    {canInputThirdPartyData && <Grid container spacing={2}>

      <Grid item xs={12} md={4} lg={6}>
        <DataInput
          title={savingHittingDeviceSensor ? 'Saving...' : 'Hitting Device Sensor'}
          motionId={motion.id}
          keys={DeviceKeysConstants.HITTING_DEVICE_SENSOR}
          data={motion.hittingDeviceSensor}
          onChange={saveHittingDeviceSensor}
        />
      </Grid>
      <Grid item xs={12} md={8} lg={6}>
        <DataInput
          title={savingForcePlate ? 'Saving...' : 'Force Plate'}
          motionId={motion.id}
          keys={DeviceKeysConstants.FORCE_PLATE}
          data={motion.forcePlate}
          onChange={saveForcePlate}
        />
      </Grid>
    </Grid>}

    {showSpeedTiles && ['max', 'peak'].map(prefix => <div 
      key={prefix}
      className={classes.tilesContainer}
    >
      <Typography>{startCase(prefix)} Speeds:</Typography>
      {bodySegments.map(segment => <StatsTile 
        key={`${prefix}_${segment}`}
        title={segment}
        value={fullMotionMetrics[prefix + 'Speed' + segment]}
        className={classes.statsTile}
      />)}
    </div>)}

    <div className={classes.graphContainer}>
      {loading 
        ? <CircularProgress />
        : analyedDataErr 
          ? <Typography>
            Error loading kinematic sequence data at this time. Please try again later.
          </Typography>
          : <KinematicSequenceGraphDark 
            motion={motion}
            analyzedFrames={analyzedMotionData}
            fullMotionMetrics={fullMotionMetrics}
            showCrosshairs={false}
          />}
    </div>
    <div>
      <Button onClick={deleteMotion} color='secondary'>Delete</Button>
    </div>
  </div>;
}

const mapStateToProps = ({ featurePermissions }) => {
  return {
    featurePermissions
  };
};

export default connect(mapStateToProps)(MotionDetailsPanel);
