import React, { useEffect, useMemo, useState } from 'react';
import { 
  Dialog, 
  DialogContent, 
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
  Typography,
  Slider,
  Switch,
  createMuiTheme, 
  ThemeProvider
} from '@material-ui/core';
import useStyles from './styles';
import { round } from 'lodash';
import PG_LOGO_DARK from 'assets/pgtech-logo-black.png';
import CloseIcon from '@material-ui/icons/Close';
import PerfectGameProfileExample from './perfectGameProfileExample';
import { SPORTS } from 'constants/modes.constants';
import { projectExitVelo, useProjectedPercentiles } from 'utils/playerPercentiles/playerPercentiles.utils';
import SwingSnapshotAvatar from 'components/SwingSnapshotAvatar';
import MetricCard from './MetricCard';
import Palette from 'styles/palette';
import ClassYearSelector, { UNSELECTED_CLASS_YEAR } from 'components/classYearSelector';

const MISSING_DATA_PLACEHODLER = '--';

function SliderDemoDialog({ open, onClose, result, user, captureEvent }) {
  const classes = useStyles();
  const [sport, setSport] = useState(SPORTS.BASEBALL);

  const [isLightTheme, setIsLightTheme] = useState(false);
  const theme = useMemo(() => createMuiTheme({
    palette: {
      type: isLightTheme ? 'light' : 'dark',
      primary: {
        main: Palette.brightBlue
      },
      background: {
        paper: isLightTheme ? 'white' : Palette.black
      }
    }
  }), [isLightTheme]);

  const [classYear, setClassYear] = useState(user?.highSchoolClassYear ?? UNSELECTED_CLASS_YEAR);
  const [openProfile, setOpenProfile] = useState(false);

  const [showDeltas, setShowDeltas] = useState(false);

  const maxExitVelo = result?.ballspeedMph ?? 0;
  const avgExitVelo = result?.averageBallspeedMph ?? 0;
  const {
    sliderEfficiency,
    setSliderEfficiency,
    projectedPercentiles,
    initialEfficiency
  } = useProjectedPercentiles(result?.playerPercentiles);

  const swingEfficiencyDelta = sliderEfficiency - initialEfficiency; 
  const {
    newExitVelo: newMaxExitVelo,
    rawEVDelta: newMaxEVDelta,
    newPercentilePerClass: newMaxEVPercentile,
    percentilePerClassDelta: newMaxEVPercentileDelta,
    originalPercentilePerClass
  } = projectExitVelo(maxExitVelo, swingEfficiencyDelta, classYear, sport);
  const {
    newExitVelo: newAvgExitVelo,
    newPercentilePerClass: newAvgEVPercentile
  } = projectExitVelo(avgExitVelo, swingEfficiencyDelta, classYear, sport);

  useEffect(() => {
    setClassYear(user?.highSchoolClassYear ?? UNSELECTED_CLASS_YEAR);
  }, [user]);

  const plusMinusSign = (value) => value >= 0 ? '+' : '';
  const updateSport = e => setSport(e.target.value);

  const close = () => {
    setShowDeltas(false);
    onClose();
  };

  const sportSelector = () => {    
    return <FormControl className={classes.classYearSelector} variant='outlined'>
      <InputLabel id='choose-sport-label'>Sport</InputLabel>
      <Select
        labelId='choose-sport-label'
        value={sport}
        label='Sport'
        onChange={updateSport}
      >
        {
          Object.values(SPORTS).map(sport => {
            return <MenuItem key={sport} value={sport}>{sport}</MenuItem>;
          })
        }
      </Select>
    </FormControl>;
  };

  return <>
    <ThemeProvider theme={theme}>
      <Dialog classes={{ root: classes.dialog }} open={open} onClose={onClose} fullScreen>
        <DialogContent>
          {classYear === UNSELECTED_CLASS_YEAR && <div className={classes.container}>
            <Grid container>
              <Grid item xs={12} className={classes.logoContainer}>
                <img alt='logo' src={PG_LOGO_DARK} className={classes.logo} />
              </Grid>
              <Grid className={classes.section} item xs={12}>
                <div>Please select the player's class year: </div>
                <div className={classes.classYear}>
                </div>
                <div className={classes.classYear}>
                  {sportSelector()}
                  <ClassYearSelector 
                    sport={sport}
                    classYear={classYear}
                    onChange={newClassYear => setClassYear(newClassYear)}
                  />
                </div>
              </Grid>
            </Grid>
          </div>}

          {classYear !== UNSELECTED_CLASS_YEAR && <div>
            <div className={classes.header}>
              <div className={classes.controls}>
                {sportSelector()}
                <ClassYearSelector 
                  sport={sport}
                  classYear={classYear}
                  onChange={newClassYear => setClassYear(newClassYear)}
                />
                <Button
                  variant='outlined'
                  onClick={() => setOpenProfile(true)}
                >
                  Profile
                </Button>
                <Grid item className={classes.deltasPicker}>
                  <Typography>Show Change:</Typography>
                  <Switch
                    disabled={result == null}
                    checked={showDeltas}
                    onClick={e => setShowDeltas(e?.target?.checked)}
                    color='primary'
                  />
                </Grid>
                <Grid item className={classes.deltasPicker}>
                  <Typography>Light Theme:</Typography>
                  <Switch
                    checked={isLightTheme}
                    onClick={e => setIsLightTheme(e?.target?.checked)}
                    color='primary'
                  />
                </Grid>
              </div>

              <IconButton
                onClick={close}
                aria-label='close'
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className={classes.container}>
              <Grid container>
                <Grid item xs={8} md={7} lg={6}>
                  <SwingSnapshotAvatar 
                    height='80vh'
                    data={[{
                      playerPercentiles: {
                        ...projectedPercentiles,
                        ballSpeed: newAvgEVPercentile
                      },
                      ballspeedMph: newAvgExitVelo,
                      header: <Typography variant='h6' textAlign='center'>
                        Avg Exit Velo
                      </Typography>
                    }]}
                  />
                </Grid>
                <Grid items xs={4} md={5} lg={6}>
                  <MetricCard 
                    title={'Max Exit\nVelo'}
                    value={showDeltas 
                      ? `${plusMinusSign(round(newMaxEVDelta, 1))}${round(newMaxEVDelta, 1)}`
                      : Math.max(0, newMaxExitVelo)}
                    label='MPH'
                    className={classes.metricCard}
                  />

                  <MetricCard 
                    title={'Percentile\nRanking'}
                    value={showDeltas 
                      ? `${plusMinusSign(newMaxEVPercentileDelta)}` + 
                  `${isNaN(newMaxEVPercentileDelta) 
                    ? MISSING_DATA_PLACEHODLER : newMaxEVPercentileDelta}%`
                      : (isNaN(newMaxEVPercentile) ? MISSING_DATA_PLACEHODLER : newMaxEVPercentile) + '%'}
                    label={`Class of ${classYear}`}
                    className={classes.metricCard}
                  />

                  <MetricCard
                    title={'Swing\nEfficiency'}
                    value={showDeltas
                      ? `${plusMinusSign(swingEfficiencyDelta)}${swingEfficiencyDelta}%`
                      : `${sliderEfficiency}%`}
                    className={classes.metricCard}
                  >
                    <Slider
                      value={sliderEfficiency}
                      onChange={(_, val) => setSliderEfficiency(val)}
                      classes={{ 
                        rail: classes.sliderTrack, 
                        track: classes.sliderTrack, 
                        thumb: classes.sliderThumb 
                      }}
                    />
                  </MetricCard>
                  
                  <div className={classes.resetButtonContainer}>
                    <Button 
                      onClick={() => setSliderEfficiency(initialEfficiency)}
                      title='Reset'
                      variant='contained'
                      color='primary'
                      size='large'
                      disabled={initialEfficiency === sliderEfficiency}
                      className={classes.resetButton}
                    >
                      Reset
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>}
        </DialogContent>
      </Dialog>
      <Dialog 
        className={classes.dialog}
        open={openProfile} 
        onClose={() => setOpenProfile(false)} 
        fullScreen
      >
        <DialogContent>
          <PerfectGameProfileExample 
            onBack={() => setOpenProfile(false)} 
            captureEvent={captureEvent}
            captureEventResult={result}
            evPercentileByClass={originalPercentilePerClass}
            sport={sport}
            classYear={classYear}/>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  </>;
};

export default SliderDemoDialog;