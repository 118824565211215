import { Container, Grid } from '@material-ui/core';
import React from 'react';
import MotionRow from './motionRow';

const MotionsList = ({ motionsWithUser = [] }) =>{
  return <Container>
    <Grid container >
      {motionsWithUser.map(({ user, ...motion }) => <MotionRow
        key={`motion-el-${motion.id}`} 
        motion={motion} 
        user={user} />)}
    </Grid>
  </Container>;
};

export default MotionsList;