import React from 'react';
import { 
  Badge,
  Grid,
  Paper, 
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Typography
} from '@material-ui/core';
import { formatMMDDYY } from '../../../utils/formatting.utils';
import { ANALYSIS_STATUS } from '../../../constants/videoAnalysis.constants';
import { getIconForAnalysis } from '../analysisUtils';
import useStyles from './styles';

function ActionPlanTable({ analyses, navigateToAnalysis }) {
  const classes = useStyles();
  
  const actionPlanAnalyses = analyses.filter(analysis => 
    analysis.status === ANALYSIS_STATUS.readyForActionPlan);
  
  return (
    <Paper className={classes.container}>
      <div className={classes.titleContainer}>
        <Badge badgeContent={actionPlanAnalyses.length} color='secondary'>
          <Typography className={classes.title} variant='h5'>Need Action Plans</Typography>
        </Badge>
      </div>
      <Table size='small' aria-label='annotations table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='left'>Date Due</TableCell>
            <TableCell align='left'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actionPlanAnalyses.map((analysis) => {
            return (
              <TableRow key={analysis.id}>
                <TableCell component='th' scope='row'>{analysis.playerName}</TableCell>
                <TableCell align='left' size='medium'>
                  <Grid container justify='space-evenly'>
                    <Grid item>{getIconForAnalysis(analysis)}</Grid>
                    <Grid item><Typography>{formatMMDDYY(analysis.dueDate)}</Typography></Grid>
                  </Grid>
                </TableCell>
                <TableCell align='left'>
                  <Button 
                    color='primary' 
                    onClick={() => navigateToAnalysis(analysis.userId, analysis.id)}
                  >
                    Create
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default ActionPlanTable;