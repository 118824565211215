import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class StyledFileInput extends React.Component {
  inputRef = React.createRef();
  
  _onCancel = () => {
    this.inputRef.current.value = null;
    this.props.onChange(null);
  }
  
  render() {
    const {
      classes,
      className,
      name,
      accept,
      label,
      multiple,
      leftAdornment,
      rightAdornment,
      disabled
    } = this.props;

    return <div className={classes.flexAlignCenter}>
      <input ref={this.inputRef}
        type='file' 
        name={name}
        id={name}
        multiple={multiple}
        className={classes.fileInput}
        accept={accept}
        onClick={e => e.target.value = null}
        onChange={e => this.props.onChange(Array.from(e.target.files))}
        disabled={disabled}
      />
      <label htmlFor={name} className={classNames([classes.fileInputLabel, className])}>
        {leftAdornment}
        <Typography variant='body1'>{label}</Typography>
        {rightAdornment}
      </label>
    </div>;
  }
};

StyledFileInput.defaultProps = {
  onChange: () => {}
};

StyledFileInput.propTypes = {
  name: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func, // called with an array of the selected files
  leftAdornment: PropTypes.node,
  rightAdornment: PropTypes.node,
  disabled: PropTypes.bool
};

export default withStyles(styles)(StyledFileInput);
