import { makeStyles } from '@material-ui/styles';
import palette from '../../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  container: {
    paddingLeft: '10px',
    paddingTop: '10px'
  },
  motionContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    maxWidth: '500px'
  },
  button: {
    backgroundColor: palette.bluePurple,
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.bluePurple,
      filter: 'brightness(80%)'
    },
    display: 'inline-block'
  },
  selectedMotionText: {
    marginBottom: '20px'
  },
  buttonText: {
    fontSize: '14px',
    padding: '1px'
  }
}));

export default useStyles;
