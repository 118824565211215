
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { mobileFontSize, desktopFontSize } from '../styles';

export const InningsTableCell = withStyles(() => ({
  root: {
    borderBottom: '0px',
    padding: '0px 3px 0px 3px',
    fontSize: `calc(${mobileFontSize}px + (${desktopFontSize} - ${mobileFontSize})\
      * ((100vw - 300px) / (1600 - 300)))`
  }
}))(TableCell);