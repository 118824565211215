import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  timeGraph: {
    marginTop: '10px'
  },
  green: {
    color: 'green'
  },
  red: {
    color: 'red'
  }
});