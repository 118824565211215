import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  lessonsHeader: {
    marginBottom: '10px'
  },
  lessonsList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 750
  },
  lessonUser: {
    backgroundColor: 'inherit'
  },
  lessonItem: {
    backgroundColor: 'inherit',
    padding: 0
  }
}));

export default useStyles;