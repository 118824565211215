import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import palette from '../../styles/palette';
import TableRow from '@material-ui/core/TableRow';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import './index.css';

/* 
 * Scaling math 
 * calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width])
 *       / ([maximum viewport width] - [minimum viewport width])));
 */

const pageColor = palette.kmobileBackgroundColor;
export const accentColor = palette.white;
const numberBackgroundColor = palette.fadedGrey;
const selectedNumberBackgroundColor = palette.leaderboardSelectedNumberBackground;
const numberBackgroundOutlineColor = palette.blueGray;
const selectedNumberBackgroundOutlineColor = palette.leaderboardSelectedNumberOutlineBackground;

export const mobileFontSize = 14;
export const desktopFontSize = 26;
const rowHeight = 'calc(60px + (60 - 60) * ((100vw - 300px) / (1600 - 300)))';

export const calculateFontSize = (addedSize = 0) => {
  const finalMobileSize = mobileFontSize + addedSize;
  const finalDesktopSize = desktopFontSize + addedSize;
  return `calc(${finalMobileSize}px + (${finalDesktopSize} - ${finalMobileSize})\
    * ((100vw - 300px) / (1600 - 300)))`;
};

export const useStyles = makeStyles(() => ({
  pageContainer: {
    backgroundColor: pageColor,
    height: '100vh',
    width: '100vw',
    overflow: 'auto'
  },
  leaderboardContainer: {
    padding: '10px 0 10px 0',
    paddingLeft: '3%',
    paddingRight: '3%'
  },
  border: {
    padding: '10px',
    border: `1px solid ${accentColor}`,
    boxSizing: 'border-box',
    width: '100%'
  },
  topContainer: {
    width: '100%',
    color: accentColor,
    marginTop: '20px',
    marginBottom: '40px'
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 0px 10px 0px'
  },
  numberBackground: {
    display: 'inlineBlock',
    fontFamily: 'digitalFont',
    width: 'calc(16px + (40 - 15) * ((100vw - 300px) / (1600 - 300)))',
    textAlign: 'center',
    margin: 'auto',
    boxSizing: 'border-box'
  },
  scrollableContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    border: `1px solid ${accentColor}`,
    boxSizing: 'border-box',
    width: '45%',
    marginTop: '-1px',
    overflowX: 'auto',
    overflowY: 'hidden'
  },
  playerTable: {
    float: 'left',
    width: '55%'
  },
  runsTable: {
    width: '35%'
  },
  leaderboardHeading: {
    padding: '10px'
  },
  centeredProgress: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%'
  },
  leaderboardSubHeading: {
    color: accentColor,
    fontSize: calculateFontSize(),
    textAlign: 'center',
    fontWeight: 'bold'
  },
  selectedCell: {
    display: 'inlineBlock',
    textAlign: 'center',
    margin: 'auto',
    border: `${accentColor} solid 1px`
  },
  nameCellContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tablePagination: {
    color: palette.white,
    fontSize: calculateFontSize(),
    '& div': {
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    }
  },
  image: {
    marginTop: '15px',
    width: 'calc(200px + (300 - 200) * ((100vw - 300px) / (1600 - 300)))',
    height: 'calc(200px + (300 - 200) * ((100vw - 300px) / (1600 - 300)))'
  },
  imgContainer: {
    display:'flex',
    justifyContent:'center',
    width:'100%'
  },
  selectedRow: {
    backgroundColor: palette.whiteBackgroundHighlight
  },
  // useful for the classnames library, adding the 'selected' class
  // by itself does not work correctly for some reason
  selected: {},
  cell: {
    '&$selected': {
      color: palette.kmobileBackgroundColor
    },
    color: palette.white,
    '& > $numberBackground': {
      backgroundColor: numberBackgroundColor,
      border: `1px solid ${numberBackgroundOutlineColor}`
    },
    '&$selected > $numberBackground': {
      backgroundColor: selectedNumberBackgroundColor,
      border: `1px solid ${selectedNumberBackgroundOutlineColor}`
    }
  },
  pinned: {
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
    paddingBottom: '50px'
  },
  userRow: {
    marginBottom: '15px'
  },
  maxScoreNote: {
    fontSize: calculateFontSize(-3),
    margin: '10px 0 10px 0',
    color: palette.white
  }
}));

export const HeaderTableCell = withStyles(() => ({
  root: {
    borderBottom: '0px',
    padding: '0px',
    color: accentColor,
    fontSize: calculateFontSize(),
    textAlign: 'center',
    textTransform: 'uppercase'
  }
}))(TableCell);

export const BodyTableCell = withStyles(() => ({
  root: {
    borderBottom: '0px',
    textAlign: 'center',
    padding: '0px 5px 0px 7px',
    fontSize: calculateFontSize(),
    position: 'relative'
  }
}))(TableCell);

export const CustomTableRow = withStyles(() => ({
  root: {
    padding: '0px',
    height: rowHeight
  }
}))(TableRow);

export const WhiteRadio = withStyles({
  root: {
    color: accentColor,
    '&$checked': {
      color: accentColor,
      fontSize: calculateFontSize(50)
    },
    fontSize: calculateFontSize(50)
  },
  checked: {}
})((props) => <Radio color='primary' {...props} />);

export const CustomFormControlLabel = withStyles({
  root: {
    color: accentColor
  },
  label: {
    fontSize: calculateFontSize()
  }
})((props) => <FormControlLabel color='primary' {...props} />);

export const CustomCircularProgress = withStyles({
  root: {
    color: accentColor
  }
})((props) => <CircularProgress color='primary' {...props} />);

