import makeStyles from '@material-ui/core/styles/makeStyles';
import palette from '../../../../../styles/palette';

const useStyles = makeStyles(() => ({
  container: {
    border: `1px solid ${palette.mediumGray}`,
    borderRadius: '3px',
    padding: '5px',
    display: 'flex',
    flexDirectiion: 'row',
    marginBottom: '10px'
  },
  syncInfoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '&>div': {
      marginBottom: '3px'
    },
    padding: '3px 3px 0 3px'
  },
  canClick: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.lightGray
    }
  },
  actionContainer: {
    width: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: '5px'
  },
  property: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px'
  },
  propertyData: {
    marginLeft: '5px'
  },
  bold: {
    fontWeight: 'bold'
  },
  reviewContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  reviewMark: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  actionItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '&>div': {
      marginBottom: '20px'
    }
  },
  acceptedIcon: {
    color: palette.green,
    width: '30px',
    height: '30px'
  },
  badIcon: {
    color: palette.error,
    width: '30px',
    height: '30px'
  },
  forwardIcon: {
    width: '30px',
    height: '30px',
    marginLeft: '5px'
  }
}));

export default useStyles;