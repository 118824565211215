import { makeStyles } from '@material-ui/core/styles';
import Palette from 'styles/palette';

const useStyles = makeStyles(() => ({
  playerContainer: {
    padding: '10px',
    border: `${Palette.mediumGray} 1px solid`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: Palette.lightGray
    },
    minWidth: '400px'
  },
  selected: {
    backgroundColor: Palette.subtleGreen,
    '&:hover': {
      backgroundColor: Palette.subtleGreen
    }
  },
  info: {
    display: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  name: {
    marginBottom: '2px',
    textAlign: 'left'
  },
  email: {
    fontStyle: 'italic',
    fontSize: '14px',
    textAlign: 'left'
  },
  icon: {
    color: Palette.success,
    marginLeft: '50px'
  }
}));

export default useStyles;