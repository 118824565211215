import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../styles/palette';
import { calculateFontSize } from './../styles';

const useClasses = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '10px'
  },
  salaryEarnedToday: {
    fontWeight: 'bold',
    fontSize: calculateFontSize(2),
    textAlign: 'center',
    color: 'white',
    marginBottom: '20px'
  },
  levelSelection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  contentContainer: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: calculateFontSize(3),
    alignItems: 'center'
  },
  levelName: {
    fontSize: calculateFontSize(6),
    marginBottom: '10px'
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px'
  },
  labelString: {
    fontWeight: 'bold',
    fontSize: calculateFontSize(-2),
    textAlign: 'center'
  },
  value: {
    fontSize: calculateFontSize(),
    textAlign: 'center'
  },
  icon: {
    '&.Mui-disabled': {
      color: palette.fadedGrey
    },
    color: 'white'
  },
  earningsButton: {
    margin: 'auto',
    marginTop: '20px',
    border: '1px solid white',
    padding: '5px',
    borderRadius: '5px',
    color: 'white',
    display: 'inline-block',
    textAlign: 'center'
  }
}));

export default useClasses;