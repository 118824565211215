import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    padding:'10px 0'
  },
  select: {
    width: '200px'
  },
  modalBody: {
    padding: '20px'
  },
  button: {
    height: '100%'
  },
  label: {
    padding: '6px 0 7px'
  }
}));