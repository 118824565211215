import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { Typography, Grid } from '@material-ui/core';
import { getOrganizationId } from '../../../../utils/auth.utils';
import FilterOptions from './filter.constants.js';
import FilterBoxItem from './filterBoxItem.js';
import useStyles from './styles';
import useOrgActionItems from './useOrgActionItems';


const FilterByPanelDesktop = ({ totalPlayersCount, onFilterChange, className }) => {
  const [filterOption, setFilterOption] = useState(FilterOptions.all);
  const classes = useStyles();
  const { initialLoad } = useOrgActionItems(getOrganizationId());
  const actionItems = useSelector(state => state.orgActionItems);

  const changeFilter = (filterOption) => {
    if (initialLoad) return;
    setFilterOption(filterOption);
    const actionItem = find(actionItems, item => item.action === filterOption);
    const userIds = actionItem ? actionItem.userIds : [];
    onFilterChange({ filterOption, userIds });
  };

  const getActionItemUserIdCount = (filterOption) => {
    const actionItem = find(actionItems, item => item.action === filterOption);
    return actionItem ? actionItem.userIds.length : '-';
  };

  return (
    <div className={className}>
      <Typography className={classes.filterTitle}>FILTER BY</Typography>
      <Grid container>
        <Grid 
          item xs={6} lg={3}
          className={classes.filterItemContainer} 
          onClick={() => changeFilter(FilterOptions.all)}
        >
          <FilterBoxItem 
            count={totalPlayersCount}
            title='All Players'
            gradientStart='#C9F1FE'
            gradientEnd='#D2E7E2'
            isSelected={filterOption === FilterOptions.all}
          />
        </Grid>
        <Grid 
          item xs={6} lg={3}
          className={classes.filterItemContainer} 
          onClick={() => changeFilter(FilterOptions.needsForms)}
        >
          <FilterBoxItem
            count={getActionItemUserIdCount(FilterOptions.needsForms)}
            title='Pending Players'
            gradientStart='#D83737'
            gradientEnd='#ffcc16'
            isSelected={filterOption === FilterOptions.needsForms}
          />
        </Grid>
        <Grid 
          item xs={6} lg={3}
          className={classes.filterItemContainer} 
          onClick={() => changeFilter(FilterOptions.needsCapture)}
        >
          <FilterBoxItem
            count={getActionItemUserIdCount(FilterOptions.needsCapture)}
            title='Awaiting Capture'
            gradientStart='#6BFFB8'
            gradientEnd='#16c6ff'
            isSelected={filterOption === FilterOptions.needsCapture}
          />
        </Grid>
        <Grid 
          item xs={6} lg={3}
          className={classes.filterItemContainer} 
          onClick={() => changeFilter(FilterOptions.needsActionPlan)}
        >
          <FilterBoxItem
            count={getActionItemUserIdCount(FilterOptions.needsActionPlan)}
            title='Awaiting Action Plan'
            gradientStart='#776BFF'
            gradientEnd='#16c6ff'
            isSelected={filterOption === FilterOptions.needsActionPlan}
          />
        </Grid>
      </Grid>
    </div>
  );
};

/*
  onChange will be called every time a filter changes.
  It will send an object that looks like this:
  {
    filterOption: name of the FilterOption selected,
    userIds: the user ids of the players that fall in that filter
  }
*/
FilterByPanelDesktop.propTypes = {
  totalPlayersCount: PropTypes.number.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func 
};

export default FilterByPanelDesktop;