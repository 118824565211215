import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tagSubheader: props => ({
    border: '3px solid ' + props.color
  }),
  formControl: {
    width: '200px'
  }
}));

export default useStyles;
