import palette from '../../../../styles/palette';
import { makeStyles } from '@material-ui/styles';

export default makeStyles(_ => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    width: '100%', 
    height: '100%', 
    padding: '10px', 
    maxWidth: '400px'
  },
  selectContainer: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  select: {
    color: '#617277',
    '&:before': {
      borderBottom: 'none'
    },
    '&:after': {
      borderBottom: 'none'
    }
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  dataCell: {
    borderBottom: 'none',
    padding: '4px'
  },
  dataCellContents: {
    padding: '5px',
    margin: '1px 0',
    fontWeight: '600',
    fontSize: '18px',
    border: '3px solid var(--borderColor)',
    borderRadius: '100%',
    width: '25px',
    height: '25px',
    display: 'flex',
    justifyContent: 'center'
  },
  titleCell: {
    fontSize: '18px',
    whiteSpace: 'nowrap',
    fontWeight: '300',
    borderBottom: 'none',
    padding: '8px'
  },
  moverTypeCell: {
    color: palette.black,
    fontWeight: '600',
    fontSize: '14px',
    width: '40%',
    whiteSpace: 'nowrap',
    verticalAlign: 'bottom',
    padding: '4px'
  },
  headerCell: {
    color: palette.black,
    padding: '4px',
    verticalAlign: 'bottom'
  },
  legendContainer: {
    padding: '10px 10px 3px 10px'
  },
  legend: {
    display: 'flex'
  },
  legendItem: {
    backgroundColor: 'var(--color)',
    flexGrow: '1',
    height: '10px'
  },
  firstLegendItem: {
    borderRadius: '5px 0 0 5px'
  },
  lastLegendItem: {
    borderRadius: '0 5px 5px 0'
  },
  legendLabels: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '15px'
  },
  editButtonContainer: {
    padding: '35px 20px 5px 20px'
  },
  editDivider: {
    marginBottom: '20px',
    marginTop: '20px'
  },
  editRadioTitle: {
    marginTop: '15px',
    marginBottom: '10px'
  },
  editRadioContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  editSmallScreenInput: {
    padding: '10px'
  },
  addScreenText: {
    display: 'flex',
    alignItems: 'center'
  },
  editContainer: {
    padding: '20px',
    maxWidth: '350px'
  },
  moverTypeContainer: {
    marginTop: '15px'
  }
}));
