import { makeStyles } from '@material-ui/styles';
import palette from '../../../styles/palette';

const useStyles = makeStyles(_theme => ({
  container: {
    display: 'flex',
    position: 'relative'
  },
  motionsList: {
    width: '30%',
    position: 'relative'
  },
  detailsContainer: {
    marginLeft: '8px',
    position: 'sticky',
    width: '70%',
    height: 'calc(100vh - 80px)',
    left: '30%',
    top: '66px',
    display: 'flex',
    flexDirection: 'column'
  },
  selectedMotionDetails: {
    backgroundColor: 'white',
    border: '2px solid darkblue',
    borderRadius: '5px',
    flex: 1
  },
  pushToKGoButton: {
    position: 'sticky',
    top: '58px',
    padding: '5px 0',
    backgroundColor: palette.subtleWhite,
    width: '100%',
    zIndex: 200
  },
  grayText: {
    color: palette.gray
  },
  itemChecked: {
    color: palette.success
  },
  itemError: {
    color: palette.error
  },
  checkboxContainer: {
    paddingLeft: '10px'
  },
  uploadButton: {
    display: 'flex', 
    textTransform: 'none'
  },
  uploadIcon: {
    border: '1px solid', 
    borderRadius: '100%', 
    marginRight: '16px'
  },
  pushWarning: {
    marginLeft: '10px',
    paddingTop: '2px',
    color: palette.mediumGray
  },
  loadMotionsContainer: {
    paddingTop: '10px'
  }
}));

export default useStyles;
