import React, { useState, useEffect } from 'react';
import ConfirmationDialog from 'components/dialogs/confirmationDialog';
import { Typography, ButtonBase } from '@material-ui/core';
import classnames from 'classnames';
import useStyles from './styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { GetMotionsForUserInCurrentOrg } from 'network/motionRequests';
import { MigrateMotions } from 'network/captureEventRequests';
import Logger from 'js-logger';

function MigrateMotionsToPlayerDialog({
  open = false,
  currentUser,
  playerAccounts = [],
  captureEventId,
  onCancel = () => {},
  onComplete = () => {}
}) {
  const classes = useStyles();

  const [migrating, setMigrating] = useState(false);
  const [error, setError] = useState(null);

  const [selectedPlayerUserId, setSelectedPlayerUserId] = useState(null);

  useEffect(() => {
    if (open) {
      setSelectedPlayerUserId(playerAccounts[0]?.associatedUser?.userId ?? null);
    } else {
      setError(null);
    }
  }, [playerAccounts, open]);

  const migrateMotions = async () => {
    if (selectedPlayerUserId == null || migrating) return;
    setMigrating(true);
    setError(false);

    try {
      const params = { captureEventId };
      const motionResp = await GetMotionsForUserInCurrentOrg(currentUser.userId, params);
      const motions = motionResp.items;
      const motionIds = motions.map(x => x.id);

      await MigrateMotions(captureEventId, selectedPlayerUserId, motionIds);

      setMigrating(false);
      onComplete();
    } catch (ex) {
      Logger.error(`Error migrating motions to parent ${currentUser.userId} to ` 
        + `player ${selectedPlayerUserId}`, ex);
      setError('There was an error getting or migrating motions to player. Please contact dev.');
      setMigrating(false);
    }
  };

  const cancelled = () => {
    setError(false);
    onCancel();
  };

  return <ConfirmationDialog 
    open={open}
    loading={migrating}
    onConfirm={migrateMotions}
    onCancel={cancelled}
    title='Migrate Motion to Child'
    errorMessage={error}
    disableCancelOnLoad
  >
    <div>
      {playerAccounts.map(({ associatedUser }) => {
        const { userId, fullName, emailAddress } = associatedUser;
        const selected = selectedPlayerUserId === userId;
        return <ButtonBase 
          className={classnames([
            classes.playerContainer,
            { [classes.selected]: selected }
          ])} 
          key={userId}
          onClick={() => setSelectedPlayerUserId(userId)}
          disabled={migrating}
        >
          <div className={classes.info}>
            <Typography className={classes.name}>{ fullName }</Typography>
            <Typography className={classes.email}>{ emailAddress }</Typography>
          </div>

          {selected && <CheckCircleIcon className={classes.icon} />}
        </ButtonBase>;
      })}
    </div>
  </ConfirmationDialog>;
}

export default MigrateMotionsToPlayerDialog;