import Auth0 from 'auth0-js';
import { AUTH_CONFIG } from '../constants/auth.constants';
import { setSession, setUserInfo, getUserId } from '../utils/auth.utils';
import { history } from '../store';

export default class AuthenticationService {
  webAuth = new Auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId
  });

  loginProgrammatically = (username, password, onError, onCompleteRoute, onComplete) => {
    this.webAuth.client.login({
      realm: AUTH_CONFIG.realm,
      username,
      password,
      audience: AUTH_CONFIG.audience,
      scope: 'openid profile email'
    }, async (err, authResult) => {
      if (err) {
        onError(err);
      } else {            
        setSession(authResult);
        const userId = getUserId();
        await setUserInfo(userId);
        if (onComplete) {
          onComplete(userId);
        }
        if (onCompleteRoute) {
          history.push(onCompleteRoute);
        }
      }
    });
  }

  resetPassword = (email, onError, onSuccess) => {
    this.webAuth.changePassword({
      connection: 'Username-Password-Authentication',
      email
    }, async (err) => {
      if (err) {
        onError(err);
      } else {
        onSuccess();
      }
    });
  }

  decodeJwtToken = token => {
    const tokenString = atob(decodeURI(token).split('.')[1]);
    return JSON.parse(tokenString);
  }
}