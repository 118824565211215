import { makeStyles } from '@material-ui/core/styles';
import Palette from 'styles/palette';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px'
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  message: {
    marginLeft: '5px',
    fontWeight: 'bold'
  },
  disabledText: {
    opacity: 0.7
  },
  icon: {
    color: Palette.warning
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  confirmationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  disabledMessageText: {
    marginTop: '10px'
  },
  videoWarningText: {
    marginBottom: '10px',
    marginTop: '10px'
  }
}));

export default useStyles;
