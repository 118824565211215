import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { 
  Card,
  Grid,
  Stepper,
  Step,
  StepLabel
} from '@material-ui/core';
import queryString from 'query-string';
import { history } from '../../store';
import { IsLoggedIn, getUserId } from '../../utils/auth.utils';
import { GetUser } from '../../network/userRequests';
import CreateAccountStep from './createAccountStep';
import AddPlayerDetails from './addPlayerDetails';
import useStyles from './styles';
import SignIn from './signIn';
import { SetCurrentPlayer } from '../../store/players';
import Logger from 'js-logger';
import useTrackingServices, { EVENT_NAMES } from '../../services/useTrackingServices';

function PublicAccountCreation({ location }) {
  var tracking = useTrackingServices();

  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoggedIn = IsLoggedIn();

  let { 
    organizationId, 
    activeStep, 
    showLogin, 
    _em_key: encodedEmail 
  } = queryString.parse(location.search);
  activeStep = Number(activeStep) || 0;
  showLogin = showLogin && showLogin.toLowerCase() === 'true';

  let decodedEmail = null;
  try {
    if (encodedEmail) { 
      decodedEmail = (atob(encodedEmail.trim()));
    }
  } catch (e) {
    Logger.error(e, 'Error decoding email: ' + encodedEmail);
  }

  useEffect(() => {
    if (decodedEmail != null && activeStep === 0) { 
      tracking.identify({ emailAddress: decodedEmail });
    }
  }, [decodedEmail, activeStep, tracking]);

  useEffect(() => {
    var eventName = showLogin 
      ? EVENT_NAMES.loginPageViewed 
      : EVENT_NAMES.accountCreationPageViewed;
    tracking.track(eventName);
  }, [showLogin, tracking]);

  const goToStep = useCallback((step) => history.push({
    search: '?' + new URLSearchParams({ 
      organizationId, 
      activeStep: step, 
      showLogin 
    }).toString()
  }), [organizationId, showLogin]);

  useEffect(() => {
    async function fetchUser() {
      if (isLoggedIn) {
        const userId = getUserId();
        try {
          const user = await GetUser(userId);
          dispatch(SetCurrentPlayer(user));
          if (!user.firstName) {
            // second step is acct details
            goToStep(1);
          } else {
            chooseProducts();
          }
        } catch (e) {
          Logger.error('Error when attempting to fetch user on new acct creation', e);
        }
      }
    }
    fetchUser();
  }, [isLoggedIn, dispatch, goToStep]);

  const onAccountCreated = user => {
    tracking.identify(user, null, true);
    tracking.track(EVENT_NAMES.accountCreated);
    goToStep(activeStep + 1);
  };

  const onDetailsEntered = user => {
    tracking.identify(user);
    tracking.track(EVENT_NAMES.detailsEntered);

    chooseProducts();
  };

  const toggleLogin = (shouldShowLogin) => history.push({ 
    search: '?' + new URLSearchParams({ 
      organizationId, 
      activeStep, 
      showLogin: shouldShowLogin
    }).toString()
  });

  const chooseProducts = () => history.push('/');

  const accountCreationSteps = [
    { 
      title: 'Create Account', 
      component: (
        <CreateAccountStep 
          organizationId={organizationId} 
          onComplete={onAccountCreated} 
          onShowLogin={() => toggleLogin(true)}
        />
      ) 
    },
    { 
      title: 'Add Player Details', 
      component: <AddPlayerDetails onComplete={onDetailsEntered} /> 
    },
    { title: 'Choose Plan', component: null }
  ];

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={4} className={classes.cardContainer}>
        <Card className={classes.loginCard} elevation={4}>
          {showLogin
            ? <SignIn showAcctCreation={() => toggleLogin(false)} />
            : <div>
              <Stepper activeStep={activeStep}>
                {accountCreationSteps.map(step => (
                  <Step key={step.title}>
                    <StepLabel>{step.title}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {accountCreationSteps[activeStep].component}
            </div>
          }
        </Card>
      </Grid>
    </Grid>
  );
}

export default PublicAccountCreation;