import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUserId } from 'utils/auth.utils';
import logger from 'js-logger';
import { Card, CardActionArea, CardActions, CardContent, 
  CircularProgress, Container, Paper, Typography } from '@material-ui/core';
import { GetSortedTrainingPlans, GetTrainingPlansForAssociatedAccounts } from 'network/trainingPlanRequests';
import { FULL_PLAN_SECTION } from 'constants/trainingPlans.constants';
import { GetUser } from 'network/userRequests';
import { generatePath } from 'react-router';
import ROUTE_PATHS from 'constants/routePaths.constants';
import useStyles from './styles';

const AllTrainingPlans = ({ history }) => {
  const classes = useStyles();

  const userId = getUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [plansUserCanView, setPlansUserCanView] = useState([]);

  const planUrl = ( { id } ) => {
    return generatePath(
      ROUTE_PATHS.TRAINING_PLAN_BY_ID, 
      { trainingPlanId: id, planSection: FULL_PLAN_SECTION.key });
  };

  useEffect(() => {
    const fetchTrainingPlans = async () => {
      setIsLoading(true);
      try {
        const user = await GetUser(userId);
        const ownTrainingPlans = await GetSortedTrainingPlans(user);
        const associatedLatestTrainingPlans = await GetTrainingPlansForAssociatedAccounts(user);
        if (associatedLatestTrainingPlans.length > 0 || 
          ownTrainingPlans > 1) {
          const allPlans = [ 
            { user, trainingPlans:ownTrainingPlans }, 
            ...associatedLatestTrainingPlans];
          setPlansUserCanView(allPlans);
        } else if (ownTrainingPlans?.length === 1) {
          // If user has their own plan only, just forward them to that plan.
          // This is done because right now users login 
          // are pointed to this page & seems like a better experience
          // if you only have 1 plan and it's your own:
          history.replace(planUrl(ownTrainingPlans[0])); 
        }
      } catch(e) {
        logger.error(' "Error getting latest training plans"', e);
      }
      
      setIsLoading(false);
    };
    fetchTrainingPlans();
  }, [userId, history]);

  if(isLoading) {
    return <CircularProgress />;
  }  

  if (plansUserCanView.length > 0) {
    return <Container className={classes.container}>
      <Paper elevation={0}>
        <Typography variant='h4'>Training Plans Available:</Typography>
        {plansUserCanView.map((entry) => {
          const { trainingPlans, user } = entry;
          const { userId } = user;
          return <Card key={'user-' + userId}>
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                {user?.firstName} {user?.lastName}
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                Email: {user?.emailAddress}
              </Typography>
              { trainingPlans?.map(( trainingPlan, index ) => { 
                const creationDate = new Date(trainingPlan?.creationTimestamp);
                return <CardActionArea key={ userId + '-training-plan-link-' + index }>
                  <Link to={planUrl(trainingPlan)}>
                    <CardActions>
                      {index + 1})  
                      View Plan ({creationDate.toDateString()})
                    </CardActions>
                  </Link>
                </CardActionArea>;})}
            </CardContent>
          </Card>;
        })}
      </Paper>
    </Container>;
  }
  return <div>
    <Typography variant='h2'>You have no training plans.</Typography>
  </div>;
};
export default AllTrainingPlans;