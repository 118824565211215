import { Grid, Typography, Divider } from '@material-ui/core';
import { formatShortDateWithSeconds } from '../../../../../../utils/formatting.utils';

const MotionRow = ({ motion, user }) => {
  const { timestamp, launchMonitorData } = motion;
  const { ballspeedMph } = launchMonitorData ?? {};

  return <Grid container>
    <Grid item xs={4}>
      <Typography>{user.firstName} {user.lastName}</Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography align='center'>
        {ballspeedMph != null ? `${ballspeedMph} mph` : '-'} 
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Typography variant='caption'>
        {formatShortDateWithSeconds(timestamp)}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
  </Grid>;
};

export default MotionRow;