import { useEffect, useState } from 'react';

function useKeyDown(targetKey, onPress) {
  const [keyDown, setKeyDown] = useState(false);
  
  useEffect(() => {
    const onPressCallback = ({ key }) => {
      if (key === targetKey) {
        setKeyDown(true);
        onPress != null && onPress();
      }
    };
    window.addEventListener('keydown', onPressCallback);
    
    const onKeyUpCallback = ({ key }) => {
      if (key === targetKey) {
        setKeyDown(false);
      }
    };
    window.addEventListener('keyup', onKeyUpCallback);
    
    return () => {
      window.removeEventListener('keydown', onPressCallback);
      window.removeEventListener('keyup', onKeyUpCallback);
    };
  }, [targetKey, onPress]);

  return keyDown;
};

export default useKeyDown;
