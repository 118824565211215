import { makeStyles } from '@material-ui/core/styles';

const TITLE_PADDING = '5px';
const SECTION_PADDING = '25px';

const THUMB_SIZE = 24;
const TRACK_SIZE = 8;

const useStyles = makeStyles(() => ({
  dialog: {
    width: '100vw',
    height: '100vh'
  },
  dialogContent: {
    position: 'relative'
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: TITLE_PADDING,
    paddingRight: TITLE_PADDING,
    paddingBottom: '15px'
  },
  logo: {
    maxHeight: 100
  },
  rail: {
    height: TRACK_SIZE + 'px',
    borderRadius: '4px'
  },
  track: {
    height: TRACK_SIZE + 'px',
    borderRadius: '4px'
  },  
  thumb: {
    width: THUMB_SIZE + 'px',
    height: THUMB_SIZE + 'px',
    marginLeft: (THUMB_SIZE / 2 * -1) + 'px',
    marginTop:  '-8px'
  },
  deltasPicker: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20
  },
  logoContainer: {
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    marginBottom: '50px'
  },
  classYear: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: SECTION_PADDING,
    paddingRight: SECTION_PADDING
  },
  classYearSelector: {
    marginRight: '25px',
    minWidth: '100px'
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: SECTION_PADDING,
    paddingRight: SECTION_PADDING
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  swingEfficiencyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  halfWidth: {
    width: '50%'
  },
  metricCard: {
    margin: '20px'
  },
  metricsLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  resetButtonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  resetButton: {
    borderRadius: '24px',
    width: '50%',
    color: 'white'
  },
  sliderTrack: {
    height: 6,
    borderRadius: 3
  },
  sliderThumb: {
    height: 24,
    width: 24,
    marginTop: -9,
    marginLeft: -9
  }
}));

export default useStyles;
