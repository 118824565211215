const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  img: {
    flexShrink: 0,
    minWidth: '100%',
    minHeight: '100vh'
  }
};

export default styles;