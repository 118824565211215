import React from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress'; 
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

function ActionPlanSummary() {
  const classes = useStyles();
  const { loading, data } = useSelector(state => state.notes);
  const note = data.length > 0 ? data[0] : null;

  return <div>
    <Typography className={classes.header}>
      Latest Action Plan 
    </Typography>
    <SimpleBar className={classes.contentArea}>
      {loading && <CircularProgress />}
      {(!loading && note == null) &&
        <div> This player does not have an action plan yet.</div>}
      {note != null && <div> 
        <div className={classes.text}>
          {note.text}
        </div> 
        <div className={classes.miscInfo}>
          <Typography>
            by  {note.author.firstName} {note.author.lastName}
          </Typography>
          <Typography>
            on {(new Date(note.creationTimestamp)).toLocaleString()}
          </Typography>
        </div>
      </div> }
    </SimpleBar>
  </div>;
}

export default ActionPlanSummary; 
