import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import useStyles from './styles';
import classnames from 'classnames';
import Palette from '../../styles/palette';

const ToggleButtonGroup = ({
  options,
  onSelect,
  selectedIndex,
  primaryColor = Palette.white,
  secondaryColor = Palette.deepBlue,
  renderItem
}) => {
  const classes = useStyles({ primaryColor, secondaryColor });
  return (
    <div className={classes.container}>
      {
        options.map((option, index) => {
          return <ButtonBase
            onClick={() => onSelect(index, option)}
            key={`${index}`}
            className={
              classnames(
                classes.tabContainer, 
                /* 
                  A normal approach with a container div with a border causes
                  suboptimal rendering in Chrome and Edge. Appying the border-radius
                  to the start and end tabs do not cause these rendering problems.
                */
                { 
                  [classes.selected]: selectedIndex === index,
                  [classes.leftTab]: index === 0,
                  [classes.rightTab]: index === options.length - 1
                }
              )
            }
          >
            { renderItem(option) }
          </ButtonBase>;
        })
      }
    </div>
  );
};

ToggleButtonGroup.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  renderItem: PropTypes.func.isRequired
};

export default ToggleButtonGroup;