export const BODY_SEGMENT_FOCUSES = [
  { label: 'Pelvis', key: 'pelvisFocus', color: 'red' },
  { label: 'Torso', key: 'torsoFocus', color: 'green' },
  { label: 'Upper Arm', key: 'upperArmFocus', color: 'lightblue' },
  { label: 'Hand', key: 'handFocus', color: 'brown' }
];

export const LESSON_SEGMENT_TYPES = {
  drill: 'Drill',
  checkpointMotions: 'CheckpointMotions'
};

export const DRILL_VARIATIONS = [
  {
    key: 'stride',
    values: ['Long', 'Short', 'Open', 'Closed']
  },
  {
    key: 'ballDirection',
    values: ['Pull', 'Push']
  },
  {
    key: 'teePosition',
    values: [
      'InsideOutFront',
      'Inside',
      'InsideBehind',
      'OutFront',
      'Behind',
      'OutsideOutFront',
      'Outside',
      'OutsideBehind'
    ]
  },
  {
    key: 'teeHeight',
    values: ['High', 'Low']
  },
  {
    key: 'destabalizedBase',
    values: ['Front', 'Back', 'Both']
  },
  {
    key: 'incomingPitchAngle',
    values: [
      'Inside45Degrees',
      'Away45Degrees'
    ]
  }
];
