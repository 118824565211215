import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { SignUpErrorStyles } from './styles';

class SignUpError extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      this.props.hidden ? (
        <div className={classes.outerContainer}>
          <div className={classes.innerContainer}>
            <div className={classes.exclamation}>
              !
            </div>
            <span className={classes.messageContainer}>
              {this.props.children}
            </span>
          </div>
        </div>
      ) : null
    );
  }
}

SignUpError.propTypes = { hidden: PropTypes.bool };

export default withStyles(SignUpErrorStyles)(SignUpError);