import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '10px',
    padding: '15px'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  textFieldContainer: {
    paddingRight: '10px'
  }
}));

export default useStyles;