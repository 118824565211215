// Actions
const SET_VIDEO_ANALYSES = 'kdashboard/video-analyses/setAll';
const CLEAR_VIDEO_ANALYSES = 'kdashboard/video-analyses/clear';
const ADD_VIDEO_ANALYSIS = 'kdashboard/video-analysis/add';
const DELETE_VIDEO_ANALYSIS = 'kdashboard/video-analysis/delete';
const UPDATE_VIDEO_ANALYSIS = 'kdashboard/video-analysis/update';
const ADD_ANNOTATION_TO_ANALYSIS = 'kdashboard/video-analysis/annotation/add';
const REMOVE_ANNOTATION_FROM_ANALYSIS = 'kdashboard/video-analysis/annotation/remove';
const UPDATE_VIDEO_ATTRIBUTES = 'kdashboard/video-analysis/attributes/update';
const UPDATE_ANNOTATION = 'kdashboard/video-analysis/annotation/update';


// Reducer
const initialState = [];
export default function reducer(state = initialState, action = {}) {
  switch(action.type) {
    case SET_VIDEO_ANALYSES:
      return action.payload;
    case CLEAR_VIDEO_ANALYSES:
      return initialState;
    case ADD_VIDEO_ANALYSIS:
      return [action.payload, ...state];
    case DELETE_VIDEO_ANALYSIS:
      return state.filter(x => x.id !== action.payload);
    case UPDATE_VIDEO_ANALYSIS:
      return state.map(analysis => analysis.id !== action.payload.id
        ? analysis
        : action.payload);
    case ADD_ANNOTATION_TO_ANALYSIS:
      return state.map(analysis => analysis.id !== action.payload.videoAnalysisId
        ? analysis
        : { ...analysis, videoAnnotations: [action.payload, ...analysis.videoAnnotations] });
    case UPDATE_ANNOTATION:
      return state.map(analysis => analysis.id !== action.payload.videoAnalysisId
        ? analysis
        : { 
          ...analysis, 
          videoAnnotations: analysis.videoAnnotations.map(annotation => {
            return annotation.id === action.payload.id ? action.payload : annotation;
          })
        }); 
    case REMOVE_ANNOTATION_FROM_ANALYSIS:
      const { annotationId, analysisId } = action.payload;
      return state.map(analysis => analysis.id !== analysisId
        ? analysis
        : { 
          ...analysis, 
          videoAnnotations: analysis.videoAnnotations.filter(x => x.id !== annotationId) 
        });
    case UPDATE_VIDEO_ATTRIBUTES:
      let { curAnalysisId, curVideoId, updatedAttributes } = action.payload;
      return state.map(analysis => analysis.id !== curAnalysisId ? analysis : {
        ...analysis,
        videoAnalysisVideos: analysis.videoAnalysisVideos.map((videoObj) => {
          const { video } = videoObj;
          return curVideoId !== video.id ? videoObj : {
            ...videoObj,
            video: { ...video, videoAttributes: { ...video.videoAttributes, ...updatedAttributes }}
          };
        })
      });
    default:
      return state;
  }
}


// Action Creators
export function setVideoAnalyses(videoAnalyses) {
  return {
    type: SET_VIDEO_ANALYSES,
    payload: videoAnalyses
  };
}

export function clearVideoAnalyses() {
  return { type: CLEAR_VIDEO_ANALYSES };
}

export function addVideoAnalysis(analysis) {
  return {
    type: ADD_VIDEO_ANALYSIS,
    payload: analysis
  };
}

export function deleteVideoAnalysis(analysisId) {
  return {
    type: DELETE_VIDEO_ANALYSIS,
    payload: analysisId
  };
}

export function updateVideoAnalysis(analysis) {
  return {
    type: UPDATE_VIDEO_ANALYSIS,
    payload: analysis
  };
}

export function addVideoAnnotationToAnalysis(annotation) {
  return {
    type: ADD_ANNOTATION_TO_ANALYSIS,
    payload: annotation
  };
}

export function updateVideoAnnotation(annotation) {
  return { type: UPDATE_ANNOTATION, payload: annotation };
}

export function removeVideoAnnotationFromAnalysis(annotationId, analysisId) {
  return {
    type: REMOVE_ANNOTATION_FROM_ANALYSIS,
    payload: { annotationId, analysisId }
  };
}

export function updateVideoAttributes(curAnalysisId, curVideoId, updatedAttributes) {
  return {
    type: UPDATE_VIDEO_ATTRIBUTES,
    payload: { curAnalysisId, curVideoId, updatedAttributes }
  };
}
