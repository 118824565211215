import { 
  Checkbox, 
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import BodyParts from 'constants/bodyParts.constants';
import useStyles from './styles';

function BodySegmentSelection({
  onBodyPartChecked = () => {},
  disabled = false,
  bodyPartsFocused = []
}) {
  const classes = useStyles();
  return <Grid container alignItems='center'>
    <Typography className={classes.focusLabel}>Focus Area: </Typography>
    {Object.values(BodyParts).filter(part => part !== BodyParts.lowerArm).map(part => 
      <FormControlLabel
        key={part}
        disabled={disabled}
        label={part}
        control={
          <Checkbox
            checked={bodyPartsFocused.includes(part)}
            onChange={() => onBodyPartChecked(part)}
          />}
      />)}
  </Grid>;
};

export default BodySegmentSelection;