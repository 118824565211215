import { KGoExperience } from '../constants/user.constants';
import differenceInYears from 'date-fns/differenceInYears';
const UNDERAGE_EMAILS = 'underageEmails';

export const CalculateAge = (birthday) => {
  return differenceInYears(new Date(), new Date(birthday));
};

export const prettyPrintHeight = (totalInches) => {
  const feet = Math.floor(totalInches / 12);
  const inches = totalInches % 12;
  return `${feet}'${inches}"`;
};
const _getUnderageEmails = () => {
  try {
    var underageEmailsString = localStorage.getItem(UNDERAGE_EMAILS);
    return JSON.parse(underageEmailsString);
  } catch {
    localStorage.removeItem(UNDERAGE_EMAILS);
  }
};

export const EmailFlaggedAsUnderage = email => {
  var underageEmails = _getUnderageEmails();
  if (underageEmails == null) return false;
  if (underageEmails.includes(email)) return true;
  return false;
};

export const FlagEmailAsUnderage = email => {
  var underageEmails = _getUnderageEmails();
  if (underageEmails == null) underageEmails = [];
  underageEmails.push(email);
  localStorage.setItem(UNDERAGE_EMAILS, JSON.stringify(underageEmails));
};

export const isBabeRuthExperience = user => {
  return user && user.kGoExperienceInfo && 
    user.kGoExperienceInfo.kGoExperience === KGoExperience.BABE_RUTH;
};

export const isPerfectGameExperience = user => {
  return user && user.kGoExperienceInfo && 
    user.kGoExperienceInfo.kGoExperience === KGoExperience.PERFECT_GAME;
};
