import palette from '../../styles/palette';
import { makeStyles } from '@material-ui/core/styles';

const sidePadding = '5%';

const styles = makeStyles(() => ({
  header: {
    height: '20vw',
    maxHeight: '150px',
    minHeight: '65px',
    backgroundColor: palette.publicAccountBannerColor,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  leftLogo: {
    position: 'absolute',
    left: sidePadding
  },
  rightLogo: {
    position: 'absolute',
    right: sidePadding
  },
  babeRuthImg: {
    height: '80%',
    width: 'auto'
  },
  KGoLogo: {
    height: '50%',
    width: 'auto'
  },
  PSCLogo: {
    height: '85%',
    width: 'auto'
  }
}));

export default styles;