import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import SwingStatAverages from './swingStatAverages';
import MobilityScreenTable from './mobilityScreenTable';

import KinematicCarousel from '../../../components/kinematicCarousel';
import ActionPlanSummary from './actionPlanSummary';
import VideoCarousel from './videoCarousel';

import useStyles from './styles';
import MediaQuery from 'react-responsive';
import { TAB_PERMISSIONS } from '../../../constants/permissions.constants';

function Overview({ match }) {
  const { userId } = match.params;
  const featurePermissions = useSelector(state => state.featurePermissions);
  const classes = useStyles();

  return <Grid container spacing={5}>
    <Grid item xs={12} lg={6} xl={5}>
      <KinematicCarousel userId={userId} />
    </Grid>
    {featurePermissions.includes(TAB_PERMISSIONS.video) && 
      <Grid item xs={12} lg={6} xl={5}>
        <div className={classes.tempVideoContainer}>
          <VideoCarousel userId={userId} />
        </div>
      </Grid>}
    {featurePermissions.includes(TAB_PERMISSIONS.mobility) &&
      <Grid item md={12} lg={3} xl={2} className={classes.mobilityContainer}>
        <MobilityScreenTable userId={userId} />
      </Grid>}
    <MediaQuery minWidth={1920}>
      {isDesktop => isDesktop ? <Grid> 
        <SwingStatAverages userId={userId} />
      </Grid> : <Grid item lg={9} xl={6}> 
        <SwingStatAverages userId={userId} />
      </Grid>
      } 
    </MediaQuery>
    
    {featurePermissions.includes(TAB_PERMISSIONS.actionPlans) && 
      <Grid item className={classes.devPlan}>
        <ActionPlanSummary userId={userId} />
      </Grid>}
  </Grid>;
}

export default Overview;
