const styles = {
  pageContainer: {
    padding: '25px'
  },
  header: {
    marginTop: '20px'
  },
  videoGrid: {
    marginTop: '15px'
  },
  searchBar: {
    width: '335px'
  },
  uploadButton: {
    marginLeft: '30px'
  },
  videoModal: {
    width: '60%',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    margin: 'auto'
  }
};

export default styles;
