import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid
} from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import useInput from '../../../../utils/useInput';
import MotionSelectionView from '../../motionSelectionDialog/motionSelectionView';
import ColorPicker from '../../../../components/colorPicker';

const INITIAL_TAG = {
  color: '#000000', // hex code for black for color picker
  selected: false,
  motionIds: []
};
const PAGE_SIZE = 10;

function TagEditorDialog({ 
  open,
  tagName,
  tag,
  motionsWithUser,
  onSave,
  onCancel
}) {
  const { setValue: setTagNameValue, ...tagNameInput } = useInput();
  const [updatedTag, setUpdatedTag] = useState(tag ?? INITIAL_TAG);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (open) {
      setTagNameValue(tagName ?? '');
      setUpdatedTag(tag ?? INITIAL_TAG);
    }
    setPage(1);
  }, [open, tagName, tag, setTagNameValue]);

  const getSelectedMotions = () => {
    return updatedTag.motionIds.reduce((acc, motionId) => ({
      ...acc,
      [motionId]: motionsWithUser[motionId]
    }), {});
  };

  const onSelectedMotionsChange = onChange => {
    const existingSelectedMotions = getSelectedMotions();
    const updatedSelectedMotions = onChange(existingSelectedMotions);
    const motionIds = Object.keys(updatedSelectedMotions);
    setUpdatedTag(prev => ({ ...prev, motionIds }));
  };

  const onTagColorChange = color => {
    setUpdatedTag(prev => ({ ...prev, color }));
  };

  const motionItems = orderBy(Object.values(motionsWithUser), 'timestamp', 'desc');
  const startIdx = PAGE_SIZE * (page - 1);
  const endIdx = startIdx + PAGE_SIZE;
  const hasNext = endIdx < motionItems.length;
  const hasPrevious = startIdx > 0;
  const itemsForPage = motionItems.slice(startIdx, startIdx + PAGE_SIZE);

  return <Dialog open={open} fullWidth maxWidth='xl'>
    <DialogTitle>
      <Grid container spacing={2} alignItems='center'>
        <Grid item>
          <TextField 
            variant='outlined'
            label='Tag Name'
            {...tagNameInput.bind} 
          />
        </Grid>
        <Grid item>
          <ColorPicker 
            color={updatedTag.color} 
            onChange={onTagColorChange} 
            id={`tag-${tagName}-color-picker`}
          />
        </Grid>
      </Grid>
    </DialogTitle>
    <DialogContent>
      <MotionSelectionView 
        motions={itemsForPage}
        selectedMotions={getSelectedMotions()}
        onSelectedMotionsChange={onSelectedMotionsChange}
        pageSize={PAGE_SIZE}
        currentPage={page}
        totalPages={Math.ceil(motionItems.length / (PAGE_SIZE * 1.0))}
        onCancel={onCancel}
        onSave={() => onSave(tagNameInput.value.trim(), updatedTag)}
        saveDisabled={tagNameInput.value.trim() === ''}
        includeUserName
        onNext={hasNext ? () => setPage(prev => prev + 1) : null}
        onPrevious={hasPrevious ? () => setPage(prev => prev - 1) : null}
        checkboxColor={updatedTag.color}
      />
    </DialogContent>
  </Dialog>;
}

export default TagEditorDialog;
