import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import useStyles from './styles';

function VideoPreviewDialog({ open, onClose, video }) {  
  const classes = useStyles();

  return <Dialog fullWidth maxWidth='lg' 
    open={open} 
    onClose={onClose} 
    onEscapeKeyDown={onClose}
  >
    <div className={classes.iframeContainer}>
      {video &&
        <iframe 
          title='Video Preview' 
          src={`${video.url}?autoplay=1&loop=1`} 
          allow='autoplay; fullscreen'
          allowFullScreen
          frameBorder={0}
          className={classes.iframe}
        />
      }
    </div>
  </Dialog>;
}

VideoPreviewDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  video: PropTypes.shape({
    url: PropTypes.string.isRequired
  })
};

export default VideoPreviewDialog;
