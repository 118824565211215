import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  teamsTitle: {
    marginBottom: '15px'
  },
  dialog: {
    padding: '20px'
  },
  textFieldMargins: {
    marginRight: '10px',
    marginBottom: '10px'
  }
}));

export default useStyles;