import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import Logger from 'js-logger';
import { BODY_SEGMENT_FOCUSES } from './templateEditor.constants';
import { SEGMENT_FOCUS } from '../../../constants/trainingPlans.constants';
import { mapOrderAndNextTemplateSegmentId } from './templateEditor.utils';
import useStyles from './styles';
import LessonSegmentsList from './lessonSegmentsList';
import SegmentEditor from './segmentEditor';
import { CreateNewLessonTemplate, GetLesson, UpdateLessonTemplate } from '../../../network/lessonsRequests';
import useNetworkRequest from '../../../network/useNetworkRequest';
import ProgressButton from '../../../components/progressButton';

function TemplateEditor({ template, drills, onCancel, onSave }) {
  const classes = useStyles();
  const [lessonTemplate, setLessonTemplate] = useState(template);
  const [selectedLessonSegment, setSelectedLessonSegment] = useState(null);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => { setErrorMessage(null); }, [lessonTemplate, selectedLessonSegment]);

  const lessonTemplateRequest = useCallback(
    async cancelSource => template.id != null ? GetLesson(template.id, cancelSource) : null,
    [template.id]);
  const [
    requestedTemplate,
    templateLoading
  ] = useNetworkRequest(template, 'GetLessonTemplate', lessonTemplateRequest);

  useEffect(() => {
    requestedTemplate && setLessonTemplate(requestedTemplate);
  }, [requestedTemplate]);

  useEffect(() => {
    setLessonTemplate(template);
    setSelectedLessonSegment(null);
  }, [template]);

  const hasPrimarySegmentFocusAssigned = () => BODY_SEGMENT_FOCUSES
    .filter(({ key }) => lessonTemplate[key] === SEGMENT_FOCUS.primary)
    .length > 0;

  const canSave = !templateLoading
    && lessonTemplate.lessonSegments?.length > 0 
    && hasPrimarySegmentFocusAssigned()
    && lessonTemplate.name?.trim() !== '';

  const onFocusSegmentChange = (key, checked) => {
    const value = checked ? SEGMENT_FOCUS.primary : SEGMENT_FOCUS.none;
    setLessonTemplate(prev => ({ ...prev, [key]: value }));
  };

  const onTemplateNameChange = e => {
    setLessonTemplate(prev => ({ ...prev, name: e.target.value }));
  };

  const onSegmentUpdated = lessonSegment => {
    setLessonTemplate(prev => {
      let update = lessonSegment.id != null;
      let lessonSegments;
      if (update) {
        lessonSegments = prev.lessonSegments
          .map(x => x.id === lessonSegment.id ? lessonSegment : x);
      }
      else {
        lessonSegments = [...prev.lessonSegments, { 
          ...lessonSegment, 
          id: uuidv4(),
          order: prev.lessonSegments.length
        }];
      }
      return { 
        ...prev, 
        lessonSegments: mapOrderAndNextTemplateSegmentId(lessonSegments) 
      };
    });
    setSelectedLessonSegment(null);
  };

  const onSegmentDeleted = lessonSegment => {
    setLessonTemplate(prev => {
      const filteredSegments = prev.lessonSegments
        .filter(segment => segment.id !== lessonSegment.id);
      return {
        ...prev,
        lessonSegments: mapOrderAndNextTemplateSegmentId(filteredSegments)
      };
    });
    setSelectedLessonSegment(null);
  };

  const onLessonSegmentsReordered = (fromIndex, toIndex) => {
    setLessonTemplate(lessonTemplate => {
      let segments = [...lessonTemplate.lessonSegments];
      const item = segments[fromIndex];
      segments.splice(fromIndex, 1);
      segments.splice(toIndex, 0, item);
      segments = mapOrderAndNextTemplateSegmentId(segments);
      return { ...lessonTemplate, lessonSegments: segments };
    });
  };

  const saveLessonTemplate = async () => {
    setSaving(true);
    setErrorMessage(null);
    try {
      let savedTemplate = { ...lessonTemplate, name: lessonTemplate.name?.trim() };
      savedTemplate = savedTemplate.id == null
        ? await CreateNewLessonTemplate(savedTemplate)
        : await UpdateLessonTemplate(savedTemplate.id, savedTemplate);
      onSave(savedTemplate);
    }
    catch (e) {
      if (e.response?.status === 400 || e.response?.status === 409) {
        setErrorMessage(e.response?.data);
      }
      else {
        Logger.error(e, 'Error creating new lesson template');
        setErrorMessage('Error saving lesson template. Please try again.');
      }
    }
    setSaving(false);
  };

  return <Grid container spacing={1}>
    <Grid item xs={2}>
      <Button 
        variant='contained' 
        onClick={onCancel}
        className={classes.button}
      >
        Cancel
      </Button>
    </Grid>
    <Grid item xs={8}>
      <Typography color='error'>{errorMessage}</Typography>
    </Grid>
    <Grid container item xs={2} justify='flex-end'>
      <Grid item>
        <ProgressButton 
          variant='contained' 
          color='primary'
          showProgress={saving}
          onClick={saveLessonTemplate}
          disabled={!canSave}
          className={classes.button}
        >
          Save
        </ProgressButton>
      </Grid>
    </Grid>

    <Grid item xs={4} sm={5}>
      <TextField
        label='Template Name'
        className={classes.nameTextField}
        value={lessonTemplate.name ?? ''}
        onChange={onTemplateNameChange}
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
    <Grid item xs={8} sm={7}>
      <FormGroup className={classes.checkboxFormGroup}>
        {BODY_SEGMENT_FOCUSES.map(({ label, key, color }) => <FormControlLabel 
          key={key}
          label={label}
          control={<Checkbox 
            checked={lessonTemplate[key] === SEGMENT_FOCUS.primary} 
            onChange={e => onFocusSegmentChange(key, e.target.checked)}
            style={{ color }}
          />}
        />)}
      </FormGroup>
    </Grid>

    <Grid container item xs={5} alignItems='center' direction='column'>
      <LessonSegmentsList 
        lessonSegments={lessonTemplate?.lessonSegments} 
        drills={drills}
        selectedLessonSegment={selectedLessonSegment}
        onSegmentSelected={setSelectedLessonSegment}
        onItemsReordered={onLessonSegmentsReordered}
        loading={templateLoading}
      />
      <Button 
        variant='outlined' 
        color='primary' 
        onClick={() => setSelectedLessonSegment({})}
      >
        Add Segment
      </Button>
    </Grid>

    <Grid item xs={7}>
      {selectedLessonSegment && <SegmentEditor 
        segment={selectedLessonSegment}
        drills={drills}
        onCancel={() => setSelectedLessonSegment(null)}
        onSave={onSegmentUpdated}
        onDelete={onSegmentDeleted}
      />}
    </Grid>

  </Grid>;
}

export default TemplateEditor;