import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeTag, setTag, updateTag } from '../../../store/dataAnalysis';
import MotionTagsList from './motionTagsList';

const SelectedMotionsList = () =>{
  const { motions, users, tags } = useSelector(state => state.dataAnalysis);
  const dispatch = useDispatch();

  const motionsWithUser = { ...motions };
  Object.keys(motionsWithUser).forEach(motionId => {
    const motion = motions[motionId];
    motionsWithUser[motionId].user = users[motion.userId];
  });

  const onTagCreate = (tagName, tag) => {
    dispatch(setTag(tagName, tag));
  };

  const onTagChange = (oldTagName, newTagName, tag) => {
    dispatch(updateTag(oldTagName, newTagName, tag));
  };
 
  const onTagDelete = (tagName, tag) => {
    dispatch(removeTag(tagName, tag));
  };

  return <MotionTagsList 
    motionsWithUser={motionsWithUser} 
    tags={tags}
    onTagCreate={onTagCreate}
    onTagChange={onTagChange}
    onTagDelete={onTagDelete}
  />;
};

export default SelectedMotionsList;