import React from 'react';
import useStyles from './styles';

import CreateSyncAttemptComponent from './createSyncAttempt';
import SyncAttempts from './syncAttempts';

function CaptureEventSyncComponent({ captureEvent = null }) {
  const classes = useStyles();
  return <>
    {
      captureEvent &&
      <div className={classes.container}>
        <CreateSyncAttemptComponent captureEvent={captureEvent} />
        <div className={classes.syncList}>
          <SyncAttempts captureEvent={captureEvent}/> 
        </div>
      </div>
    }
  </>;
};

export default CaptureEventSyncComponent;