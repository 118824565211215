import Logger from 'js-logger';
import { 
  GetCaptureEvents, 
  GetCaptureEvent, 
  GetEventUsers
} from '../network/captureEventRequests';
import keyBy from 'lodash/keyBy';

// Actions
const REQUEST_CAPTURE_EVENTS = 'kdashboard/captureEvents/items/request';
const ERROR_REQUESTING_CAPTURE_EVENTS = 'kdashboard/captureEvents/items/error';
const SET_CAPTURE_EVENTS = 'kdashboard/captureEvents/items/set';
const REQUEST_SINGLE_CAPTURE_EVENT = 'kdashboard/captureEvents/items/single/request';
const ERROR_REQUESTING_SINGLE_CAPTURE_EVENT = 'kdashboard/captureEvents/items/single/error';
const SET_SINGLE_CAPTURE_EVENT = 'kdashboard/captureEvents/items/single/set';
const ADD_SINGLE_SYNC_ATTEMPT = 'kdashboard/captureEvents/items/single/syncAttempt/add';
const UPDATE_EVENT_USER = 'kdashboard/captureEvents/items/single/eventUser/update';
const ADD_EVENT_USER = 'kdashboard/captureEvents/items/single/eventUser/add';
const REMOVE_EVENT_USER = 'kdashboard/captureEvents/items/single/eventUser/remove';
const SET_EVENT_RESULT_VIDEOS = 'kdashboard/captureEvents/items/eventUser/eventResult/videos/set';

// Reducer
const initialListState = { 
  loading: false, 
  error: false, 
  items: {}
};

export default function reducer(state = initialListState, action) {
  switch(action.type) {
    case REQUEST_CAPTURE_EVENTS:
      return { ...state, error: false, loading: true };
    case ERROR_REQUESTING_CAPTURE_EVENTS :
      return { ...state, loading: false, error: true };
    case SET_CAPTURE_EVENTS:
      return { 
        loading: false, 
        error: false, 
        items: keyBy(action.payload.map(item => {
          const existingItem = state.items[item.id]
            ? state.items[item.id].item
            : null;
          let { captureEventUsers, eventSerialCodes } = item;
          // don't overwrite subresources of existing item
          if (existingItem != null) {
            if (captureEventUsers == null || captureEventUsers.length === 0) {
              captureEventUsers = existingItem.captureEventUsers;
            }
            if (eventSerialCodes == null || eventSerialCodes.length === 0) {
              eventSerialCodes = existingItem.eventSerialCodes;
            }
          }
          return {
            loading: false,
            error: false,
            item: {
              ...item,
              captureEventUsers,
              eventSerialCodes
            }
          };
        }), x => x.item.id)
      };
    case SET_SINGLE_CAPTURE_EVENT:
      return { 
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: {
            loading: false,
            error: false,
            item: action.payload
          }
        }
      };
    case ADD_SINGLE_SYNC_ATTEMPT:
      const item = state.items[action.payload.captureEventId].item;
      const syncAttempts = [action.payload, ...(item.rawDataSyncAttempts || [])];
      return { 
        ...state,
        items: {
          ...state.items,
          [action.payload.captureEventId]: {
            loading: false,
            error: false,
            item: {
              ...item,
              rawDataSyncAttempts: syncAttempts
            }
          }
        }
      };
    case UPDATE_EVENT_USER:
      const captureEvent = state.items[action.payload.captureEventId].item;
      const updatedUsers = captureEvent.captureEventUsers.map(user => 
        user.id === action.payload.id ? action.payload : user);
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.captureEventId]: {
            loading: false,
            error: false,
            item: {
              ...captureEvent,
              captureEventUsers: updatedUsers
            }
          }
        }
      };
    case ADD_EVENT_USER:
      const addCaptureEvent = state.items[action.payload.captureEventId].item;
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.captureEventId]: {
            loading: false,
            error: false,
            item: {
              ...addCaptureEvent,
              captureEventUsers: [...addCaptureEvent.captureEventUsers, action.payload]
            }
          }
        }
      };
    case REMOVE_EVENT_USER:
      const removeCaptureEvent = state.items[action.payload.captureEventId].item;
      const removeUpdatedUsers = removeCaptureEvent.captureEventUsers
        .filter(user => user.id !== action.payload.captureEventUserId);
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.captureEventId]: {
            loading: false,
            error: false,
            item: {
              ...removeCaptureEvent,
              captureEventUsers: removeUpdatedUsers
            }
          }
        }
      };
    case REQUEST_SINGLE_CAPTURE_EVENT:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: {
            ...(state.items[action.payload.id] || {}),
            loading: true,
            error: false
          }
        }
      };
    case ERROR_REQUESTING_SINGLE_CAPTURE_EVENT:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: {
            ...(state.items[action.payload.id] || {}),
            loading: false,
            error: true
          }
        }
      };
    case SET_EVENT_RESULT_VIDEOS:
      const { 
        captureEventId, 
        captureEventUserId, 
        captureEventResultId, 
        videos
      } = action.payload;
      const setResultVideoEvent = state.items[captureEventId].item;
      const setResultVideoUsers = setResultVideoEvent.captureEventUsers.map(eventUser => {
        return eventUser.id !== captureEventUserId
          ? eventUser
          : {
            ...eventUser,
            captureEventResults: eventUser.captureEventResults
              .map(eventResult => eventResult.id !== captureEventResultId
                ? eventResult
                : {
                  ...eventResult,
                  videos
                })
          };
      });

      return {
        ...state,
        items: {
          ...state.items,
          [captureEventId]: {
            ...state.items[captureEventId],
            item: {
              ...state.items[captureEventId].item,
              captureEventUsers: setResultVideoUsers
            }
          }
        }
      };
    default:
      return state;
  }
}

// Action Creators
function requestCaptureEvents() {
  return { type: REQUEST_CAPTURE_EVENTS };
}

function errorRequestingCaptureEvents() {
  return { type: ERROR_REQUESTING_CAPTURE_EVENTS };
}

function setCaptureEvents(captureEvents) {
  return { type: SET_CAPTURE_EVENTS, payload: captureEvents };
}

export function setSingleCaptureEvent(captureEvent) {
  return { type: SET_SINGLE_CAPTURE_EVENT, payload: captureEvent };
}

export function addSingleSyncAttempt(syncAttempt) {
  return { type: ADD_SINGLE_SYNC_ATTEMPT, payload: syncAttempt };
}

export function updateEventUser(eventUser) {
  return { type: UPDATE_EVENT_USER, payload: eventUser };
}

export function removeEventUser(eventUserId, eventId) {
  return { type: REMOVE_EVENT_USER, payload: {
    captureEventUserId: eventUserId,
    captureEventId: eventId
  }};
}

export function addEventUser(eventUser) {
  return { type: ADD_EVENT_USER, payload: eventUser };
}

export function fetchCaptureEvents() {
  return dispatch => {
    dispatch(requestCaptureEvents());

    function handleSuccess(captureEvents) {
      dispatch(setCaptureEvents(captureEvents));
    }

    function handleError(err) {
      Logger.error('Error fetching capture events:', err);
      dispatch(errorRequestingCaptureEvents());
    }

    GetCaptureEvents().then(handleSuccess, handleError);
  };
}

function requestCaptureEvent(captureEventId) {
  return { type: REQUEST_SINGLE_CAPTURE_EVENT, payload: { id: captureEventId }};
}

function errorRequestingCaptureEvent(captureEventId) {
  return { type: ERROR_REQUESTING_SINGLE_CAPTURE_EVENT, payload: { id: captureEventId }};
}

export function fetchCaptureEvent(id) {
  return dispatch => {
    dispatch(requestCaptureEvent(id));

    function handleSuccess(responses) {
      responses[0].captureEventUsers = responses[1].items;
      dispatch(setSingleCaptureEvent(responses[0]));
    }

    function handleError(err) {
      Logger.error(`Error fetching capture event with id ${id}:`, err);
      dispatch(errorRequestingCaptureEvent(id));
    }

    Promise.all([
      GetCaptureEvent(id),
      GetEventUsers(id)
    ]).then(handleSuccess, handleError);
  };
}

export function setEventResultVideos(
  captureEventId,
  captureEventUserId,
  captureEventResultId,
  videos
) {
  return {
    type: SET_EVENT_RESULT_VIDEOS,
    payload: {
      captureEventId,
      captureEventUserId,
      captureEventResultId,
      videos
    }
  };
}
