import { makeStyles } from '@material-ui/styles';
import palette from '../../../../styles/palette';

export default makeStyles(_ => ({
  container: {
    color: palette.white,
    width: 'fit-content',
    marginBottom: '16px'
  },
  pendingClientDivider: {
    background: '#6EA0AB',
    marginLeft: '5px',
    marginRight: '5px',
    width: '2px'
  },
  settingsDivider: {
    background: '#636363',
    marginLeft: '5px',
    marginRight: '5px',
    width: '2px'
  },
  mobileText: {
    fontSize: '0.8rem'
  },
  dialogContainer: {
    margin: '5%'
  },
  dialogContents: {
    padding: '30px'
  },
  closeRow: {
    color: palette.mediumGray,
    marginBottom: '15px'
  }
}));