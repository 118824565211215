import React from 'react';
import useStyles from './styles';

import { CircularProgress } from '@material-ui/core';
import LabelViewer from '../../../../components/labelViewer';

import { convertLabelsToArray } from '../../../../utils/kAssessments.utils.js';

function AssessmentGraphDisplay({ 
  motionData, 
  userId,
  label = null,
  labels = {}
}) {
  const classes = useStyles();
  const exists = Boolean(motionData);

  const labelsArr = convertLabelsToArray(labels);

  if (!labelsArr.length) {
    label = null;
  } else if (
    (!label && labelsArr.length) || 
    (!labelsArr.some((labelObj) => labelObj.id === label))
  ) {
    label = labelsArr[0].id;
  }

  return <div className={classes.container}>
    {
      exists &&
            <LabelViewer
              labelData={labelsArr}
              selectedLabelIdInitial={label}
              userId={userId}
              {...motionData} 
            />
    }

    {
      !exists &&
            <CircularProgress 
              className={classes.circularProgress} 
              size={45}
            />
    }
  </div>;
}

export default AssessmentGraphDisplay;