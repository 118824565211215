import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

class LengthLimitedTextField extends React.Component {
  render() {
    const { maxLength, error, value, className, ...rest } = this.props;
    return <FormControl error={error} className={className}>
      <TextField 
        error={error}
        value={value}
        {...rest}
      />
      <FormHelperText>{maxLength - value.length} characters remaining.</FormHelperText>
    </FormControl>;
  }
}

LengthLimitedTextField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
  error: PropTypes.bool
};

export default LengthLimitedTextField;
