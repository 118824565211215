import Logger from 'js-logger';
import { GetOrganization } from '../network/organizationRequests';
import { getUserId } from '../utils/auth.utils';

// Actions
const SET_CURRENT_ORGANIZATION = 'kdashboard/currentOrganization/set';
const REQUEST_CURRENT_ORGANIZATION = 'kdashboard/currentOrganization/request';
const ERROR_REQUESTING_CURRENT_ORGANIZTION = 'kdashboard/currentOrganization/error';

// Reducer
const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_ORGANIZATION:
      return action.org;
    case REQUEST_CURRENT_ORGANIZATION:
      return { ...state, loading: true, error: false };
    case ERROR_REQUESTING_CURRENT_ORGANIZTION:
      return { ...state, loading: false, error: true };
    default: 
      return state;
  }
}

// Action Creators
export function setCurrentOrganization(org) {
  return { type: SET_CURRENT_ORGANIZATION, org };
}

function requestCurrentOrganization() {
  return { type: REQUEST_CURRENT_ORGANIZATION };
}

function errorRequestingCurrentOrganization() {
  return { type: ERROR_REQUESTING_CURRENT_ORGANIZTION };
}

export function fetchCurrentOrganization() {
  return dispatch => {
    dispatch(requestCurrentOrganization());
    const currentUserId = getUserId();

    function onSuccess(organization) {
      dispatch(setCurrentOrganization({ loading: false, error: false, ...organization }));
    }

    function onError(err) {
      dispatch(errorRequestingCurrentOrganization());
      Logger.error(`Error fetching current organization for user ${currentUserId}.`, err);
    }

    GetOrganization(currentUserId).then(onSuccess, onError);
  };
}