const MODES = {
  golf: 'Golf',
  baseball: 'Baseball',
  baseballPitch: 'Pitching'
};

export const SPORTS = {
  BASEBALL: 'Baseball',
  SOFTBALL: 'Softball'
};

export default MODES;


