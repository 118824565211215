import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import log from 'js-logger';

import AnalysisHistoryListItem from './analysisHistoryListItem';
import useStyles from './styles';
import ErrorSnackbar from '../../../../components/errorSnackbar';
import VideoPreviewDialog from '../../../../components/dialogs/videoPreviewDialog';
import { CreateAnalysis } from '../../../../network/videoAnalysisRequests';
import { getOrganizationId } from '../../../../utils/auth.utils';
import { addVideoAnalysis } from '../../../../store/videoAnalyses';
import RequestModal from './requestModal';
import { orderBy } from 'lodash';
import { ANALYSIS_STATUS, ANALYSIS_TYPE } from '../../../../constants/videoAnalysis.constants';

function AnalysisHistory({ 
  userId, 
  selectedAnalysisId,
  onChangeSelectedAnalysis
}) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [creatingNewAnalysis, setCreatingNewAnalysis] = useState(false);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [error, setError] = useState(null);
  const { user, analyses } = useSelector(state => ({
    user: state.currentPlayer,
    analyses: state.videoAnalyses
  }));
  const dispatch = useDispatch();
  const classes = useStyles();

  const createAnalysisRequest = async (type) => {
    setCreatingNewAnalysis(true);
    try {
      const orgId = getOrganizationId();
      const newAnalysis = await CreateAnalysis(userId, orgId, type);
      setShowConfirmationDialog(false);
      dispatch(addVideoAnalysis(newAnalysis));
      onChangeSelectedAnalysis(newAnalysis);
    } catch(e) {
      log.error(e);
      setError('There was an error when trying to request new videos. Please try again.');
    } finally {
      setCreatingNewAnalysis(false);
    }
  };

  const analysisTimestamp = (analysis) => analysis.editedTimestamp 
    ? analysis.editedTimestamp 
    : analysis.creationTimestamp;

  const filteredAnalysis = orderBy(analyses, analysis => analysisTimestamp(analysis), ['desc'])
    .filter(analysis => analysis.status !== ANALYSIS_STATUS.requested);
  const credits = analyses.filter(analysis => analysis.status === ANALYSIS_STATUS.requested);
  const numAnalysisCredits = credits
    .filter(credit => credit.type === ANALYSIS_TYPE.fullAnalysis)
    .length;
  const numCheckInCredits = credits
    .filter(credit => credit.type === ANALYSIS_TYPE.checkIn)
    .length;

  return <div className={classes.container}>
    <ErrorSnackbar message={error} onClose={() => setError(null)} />
    <RequestModal  
      open={showConfirmationDialog}
      user={user}
      onCancel={() => setShowConfirmationDialog(false)}
      onSelection={createAnalysisRequest}
      loading={creatingNewAnalysis}
    />

    <VideoPreviewDialog 
      open={previewVideo != null}
      onClose={() => setPreviewVideo(null)}
      video={previewVideo}
    />

    <Typography align='center' variant='h5'>Analysis History</Typography>
    <div className={classes.creditsContainer}>
      <Typography>Analysis Credits: {numAnalysisCredits}</Typography>
      <Typography className={classes.checkInCredits}>
        Check In Credits: {numCheckInCredits}
      </Typography>
    </div>
    <Button 
      variant='contained' 
      color='primary' 
      className={classes.requestButton} 
      onClick={() => setShowConfirmationDialog(true)}
    >
      Request Swing Videos
    </Button>
    <List className={classes.list}>
      {filteredAnalysis && filteredAnalysis.map(analysis => 
        <React.Fragment key={`container-${analysis.id}`}>
          <AnalysisHistoryListItem 
            key={analysis.id} 
            selected={analysis.id === selectedAnalysisId}
            analysis={analysis}
            timestamp={analysisTimestamp(analysis)}
            status={analysis.status}
            onClick={() => onChangeSelectedAnalysis(analysis)}
            onPreviewVideoClick={setPreviewVideo}
          />
          <Divider key={`analysis-divider-${analysis.id}`}/>
        </React.Fragment>)
      }
    </List>
  </div>;
}

AnalysisHistory.propTypes = {
  userId: PropTypes.string.isRequired,
  selectedAnalysisId: PropTypes.number,
  onChangeSelectedAnalysis: PropTypes.func.isRequired
};

export default AnalysisHistory;
