import { makeStyles } from '@material-ui/styles';
import palette from '../../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  container: {
    position: 'absolute',
    zIndex: 3
  },
  graphIconContainer: {
    display: 'flex',
    width: '40px',
    height: '40px',
    backgroundColor: palette.lightGray,
    border: `1px solid ${palette.mediumGray}`,
    borderRadius: '2px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& img': {
      width: '60%'
    }
  },
  graphPopupContainer: {
    padding: '0 10px 10px 10px',
    border: `1px solid ${palette.mediumGray}`,
    borderRadius: '2px',
    maxHeight: 'calc(100vh - 65px - 70px)',
    overflow: 'auto'
  },
  cancelButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(-15%, -15%)',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    borderRadius: '100%',
    backgroundColor: palette.lightGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${palette.mediumGray}`,
    boxSizing: 'border-box'
  }
}));

export default useStyles;