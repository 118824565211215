import palette from '../../../styles/palette';

const styles = {
  selectedMotion: {
    borderWidth: '0 0 2px 0',
    borderStyle: 'solid',
    borderColor: palette.blueGreen
  },

  circularProgress: {
    top: '50%',
    left: '50%',
    zIndex: 100,
    position: 'absolute',
    marginTop: '-23px',
    marginLeft: '-8px'
  },
  graphPanel: {
    marginTop: '5px'
  },
  swingPanelGrid: {
    minWidth: '300px'
  }
};

export default styles;