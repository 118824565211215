import React, { useEffect, useState } from 'react';
import logger from 'js-logger';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import {
  Button,
  Paper,
  Grid,
  Typography
} from '@material-ui/core';
import uniqBy from 'lodash/uniqBy';
import { formatMMDDYY } from '../../utils/formatting.utils';
import CreateAccountDialog from '../../components/createAccountDialog';
import { GetClientActivationsForOrg } from '../../network/clientActivationRequests';
import SearchBar from '../../components/searchBar';
import { addPlayer } from '../../store/players';
import { removeUserActionItem } from '../../store/orgActionItems';
import useStyles from './styles';
import { ACTION_ITEMS } from '../../constants/organizations.constants';

const PendingClients = () => {
  const [clientActivations, setClientActivations] = useState([]);
  const [filteredClientActivations, setFilteredClientActivations] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [creatingAccountEmail, setCreatingAccountEmail] = useState(null);
  const [creatingClientId, setCreatingClientId] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchClients() {
      try {
        var clientActivations = await GetClientActivationsForOrg(cancelToken);
        var inactiveClients = uniqBy(clientActivations, 'clientEmail')
          .filter(x => !x.active);
        setClientActivations(inactiveClients);
        setFilteredClientActivations(inactiveClients);
        setSearchText('');
      } 
      catch (e) {
        if (axios.isCancel(e)) return;
        logger.error(`Error: ${e}`);
      }
    }
    fetchClients();
    return cancelToken.cancel;
  }, []);

  const _onSearchTextClear = () => {
    setSearchText('');
    setFilteredClientActivations(clientActivations);
  };

  const _onSearchTextChange = e => {
    const { value } = e.target;
    setSearchText(value);
    const filteredClients = value.trim === '' 
      ? clientActivations
      : clientActivations.filter(client =>
        client.clientEmail.toLowerCase().includes(value.toLowerCase()) 
        || (client.clientName || '').toLowerCase().includes(value.toLowerCase())
      );
    setFilteredClientActivations(filteredClients);
  };

  const _onSubmit = (clientId, newUser) => {
    _clearAccountCreation();

    const newClients = clientActivations
      .filter(x => x.clientId !== clientId);
    setClientActivations(newClients);
    setFilteredClientActivations(newClients);

    dispatch(addPlayer(newUser));
    dispatch(removeUserActionItem(ACTION_ITEMS.needsForms, clientId));
  };

  const _clearAccountCreation = () => {
    setCreatingAccountEmail(null);
    setCreatingClientId(null);
  };

  const _setAccountCreation = (email, clientId) => {
    setCreatingAccountEmail(email);
    setCreatingClientId(clientId);
  };

  return (
    <div className={classes.clientsContainer}>
      <div>
        <CreateAccountDialog 
          open={creatingAccountEmail != null} 
          email={creatingAccountEmail} 
          clientId={creatingClientId}
          onClose={_clearAccountCreation}
          onSubmit={_onSubmit}
        />
        <div className={classes.searchBarContainer}>
          <SearchBar 
            value={searchText}
            onChange={_onSearchTextChange}
            onClear={_onSearchTextClear}
            variant='outlined'
          />
        </div>
        {filteredClientActivations.map(client => <Paper className={classes.item} key={client.id}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className={classes.tableRow}>
                <Typography className={classes.header}>Email:</Typography>
                <Typography>{client.clientEmail}</Typography>
              </div>
            </Grid>
            {client.clientName && <Grid item xs={12} md={3}>
              <div className={classes.tableRow}>
                <Typography className={classes.header}>Name:</Typography>
                <Typography>{client.clientName}</Typography>
              </div>
            </Grid>}
            <Grid item xs={12} md={3}>
              <div className={classes.tableRow}>
                <Typography className={classes.header}>Date Created:</Typography>
                <Typography>{formatMMDDYY(client.timestamp)}</Typography>
              </div>
            </Grid>
          </Grid>
          <Button 
            color='primary'
            onClick={() => _setAccountCreation(client.clientEmail, client.clientId)}
          >
            Create Account
          </Button>
        </Paper>)}
      </div>
    </div>
  );
};

export default PendingClients;