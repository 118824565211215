import { useState } from 'react';
import {
  Grid,
  Typography 
} from '@material-ui/core';
import { groupBy, keyBy, orderBy, flatMap } from 'lodash';
import { GetPlayerLessons } from '../../../network/lessonsRequests';
import { GetPopupCaptures } from '../../../network/lessonsRequests';
import useNetworkRequest from '../../../network/useNetworkRequest';
import { formatMMDD } from '../../../utils/formatting.utils';
import useStyles from './styles';
import { GetAllMotionsBeyondLimit } from '../../../network/motionRequests';
import { 
  addPercentilesAndKeyFramePositionsToMotions, 
  MOTION_REQUEST_PARAMS 
} from './motionSelection.utils';
import { useDispatch } from 'react-redux';
import { setTag, setUserMotions } from '../../../store/dataAnalysis';
import { getRandomColor } from '../../../styles/palette';
import ProgressButton from '../../../components/progressButton';
import Logger from 'js-logger';
import ListWithSubheader from './listWithSubheader';
import { SEGMENT_TYPES } from '../../../constants/lessons.constants';

function LessonSelection({ onClose }) {
  const [lessonsSelected, setLessonsSelected] = useState([]);
  const [lessons] = useNetworkRequest([], 'getPlayerLessons', GetPlayerLessons);
  const [popupCaptures] = useNetworkRequest([], 'getPopupCaptures', GetPopupCaptures);
  const [isImporting, setIsImporting] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();

  const toggleLessonSelection = (lesson) => {
    if (lessonsSelected.indexOf(lesson) === -1) {
      setLessonsSelected(prev => [...prev, lesson]);
    } else {
      setLessonsSelected(prev => prev.filter(x => x !== lesson));
    }
  };

  const importLessons = async () => {
    setIsImporting(true);
    setErrMsg('');

    // there isn't a filter specifically for lessons so we'll get all motions
    // for the user and filter client side for each lesson.
    // For people taking lessons, the lesson motions would account for most
    // of the motions in our system anyways.
    const lessonsByUserId = groupBy(lessonsSelected, lesson => lesson.userId);
    const multipleUsers = lessonsByUserId.length > 1;
    try {
      Object.keys(lessonsByUserId).forEach(async userId => {
        const userMotions = await GetAllMotionsBeyondLimit({ ...MOTION_REQUEST_PARAMS, userId });
        const motionsBySegmentId = groupBy(userMotions, motion => motion.lessonSegmentId);
        
        lessonsByUserId[userId].forEach(async lesson => {
          const lessonMotions = flatMap(
            lesson.lessonSegments,
            segment => motionsBySegmentId[segment.id]
          ).filter(motion => motion != null);
  
          const analysisMotions = await addPercentilesAndKeyFramePositionsToMotions(
            keyBy(lessonMotions, motion => motion.id),
            lesson.playerWeightPounds
          );
          dispatch(setUserMotions(lesson.user, analysisMotions));

          orderBy(lesson.lessonSegments, segment => parseInt(segment.order))
            .filter(segment => ![
              SEGMENT_TYPES.physicalScreen,
              SEGMENT_TYPES.bodySegmentFocusSelection].includes(segment.type))
            .forEach(segment => {
              const segmentName = segment.drill?.name ?? segment.type;
              let tagName = multipleUsers ? `${lesson.user.firstName} ` : '';
              tagName += `${segment.order}. ${segmentName}`;
              
              dispatch(setTag(tagName, {
                color: getRandomColor(),
                motionIds: motionsBySegmentId[segment.id]?.map(motion => motion.id) ?? [] 
              }));
            });
        });
      });
      onClose();
    } catch(e) {
      Logger.error('Error during lesson import', e);
      setErrMsg('Error importing lessons, try again.');
      setIsImporting(false);
    }
  };

  const lessonsByUserName = groupBy(
    [...lessons, ...popupCaptures], 
    ({ user }) => `${user.firstName} ${user.lastName}`);

  return (
    <div>
      <Typography color='error'>{errMsg}</Typography>
      <Grid container justify='space-between' className={classes.lessonsHeader}>
        <Typography variant='h6'>Import Lessons</Typography>
        <ProgressButton 
          showProgress={isImporting} 
          variant='contained' 
          color='primary' 
          onClick={importLessons}
        >
          Import
        </ProgressButton>
      </Grid>
      <ListWithSubheader
        listObject={lessonsByUserName}
        onToggleCheckbox={toggleLessonSelection}
        selectedItems={lessonsSelected}
        displayFn={lesson => formatMMDD(lesson.startTimestamp)}
      />
    </div>
  );
};

export default LessonSelection;