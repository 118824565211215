import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 20px'
  }
}));

export default useStyles;
