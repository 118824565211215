import UnitConverter from './unitConverter.utils';
import BodyParts from '../constants/bodyParts.constants';
import MotionTypes from '../constants/motionTypes.constants';
import camelCase from 'lodash/camelCase';
import { GetMotion } from '../network/motionRequests';
import logger from 'js-logger';

import { 
  LAUNCH_MONITOR, 
  FORCE_PLATE, 
  HITTING_DEVICE_SENSOR,
  KEY_FRAME_DATA,
  FULL_MOTION_METRICS,
  ATTRIBUTES
} from '../constants/motionSubresources.constants';

export default class MotionUtils {
  static getTrueSpeeds = (user, motion) => {
    const timestamp = new Date(motion.timestamp);
    const { measurements } = user;
    for (var ii = 0; ii < measurements.length; ii++) {
      const measurement = measurements[ii];
      const startTime = new Date(measurements[ii].startTime);
      if (timestamp > startTime || ii === measurements.length - 1) {
        const { waistSizeCentimeters, shoulderSizeCentimeters } = measurement;
        const waistIn = UnitConverter.cmToIn(waistSizeCentimeters);
        const shoulderIn = UnitConverter.cmToIn(shoulderSizeCentimeters);
        const { fullMotionMetrics } = motion;
        if (fullMotionMetrics == null ||
            fullMotionMetrics.peakSpeedPelvis == null ||
            fullMotionMetrics.peakSpeedTorso == null) {
          return {}; 
        }
        return { 
          truePeakSpeedPelvis: waistIn * 0.001 * fullMotionMetrics.peakSpeedPelvis,
          truePeakSpeedTorso: shoulderIn * 0.001 * fullMotionMetrics.peakSpeedTorso
        };
      }
    }
    return {};
  }

  static getBodyPartsForMotionType = motionType => {
    // all motions have torso and pelvis
    let bodyParts = [BodyParts.pelvis, BodyParts.torso];

    switch(motionType) {
      case MotionTypes.baseball:
      case MotionTypes.golf:
        bodyParts = [...bodyParts, BodyParts.upperArm, BodyParts.hand];
        break;
      case MotionTypes.baseballPitch:
        bodyParts = [...bodyParts, BodyParts.lowerArm, BodyParts.upperArm];
        break;
      case MotionTypes.golfLeadWrist:
        bodyParts = [...bodyParts, BodyParts.lowerArm, BodyParts.hand];
        break;
      default:
        break;
    }
    return bodyParts;
  }

  static constructKeyFramePositionsMap = keyFrameData => {
    const keyFormatter = (segment, metric, frame) => 
      camelCase(segment + metric + 'At' + frame);
    var keyFrameMap = keyFrameData.reduce((acc, cur) => {
      const { bodySegment, keyFrameName, bend, sideBend, rtnJcs, xFactor } = cur;
      const bendKey = keyFormatter(bodySegment, 'Bend', keyFrameName);
      const sideBendKey = keyFormatter(bodySegment, 'SideBend', keyFrameName);
      const rotationKey = keyFormatter(bodySegment, 'Rotation', keyFrameName);
      const xFactorKey = keyFormatter('', 'xFactor', keyFrameName);
      return { 
        ...acc, 
        [bendKey]: bend, 
        [sideBendKey]: sideBend, 
        [rotationKey]: rtnJcs, 
        [xFactorKey]: xFactor
      };
    }, {});

    const { xFactorAtHeelStrike, xFactorAtFirstMove } = keyFrameMap;
    if (xFactorAtHeelStrike != null && xFactorAtFirstMove != null) {
      keyFrameMap.xFactorStretch = xFactorAtHeelStrike - xFactorAtFirstMove;
    }
    return keyFrameMap;
  };

  static calculateSequences = (pelvisFrame, torsoFrame, armFrame, handFrame, keySuffix) => {
    let segmentFrames = [
      { segment: 'pelvis', frame: pelvisFrame },
      { segment: 'torso', frame: torsoFrame }, 
      { segment: 'upperArm', frame: armFrame }, 
      { segment: 'hand', frame: handFrame } 
    ];
    segmentFrames.sort((a, b) => a.frame - b.frame);
    segmentFrames[0].sequence = 1;
    let result = { [segmentFrames[0].segment + keySuffix]: 1 };
    for(var ii = 1; ii < segmentFrames.length; ii++) {
      const { frame } = segmentFrames[ii];
      const prev = segmentFrames[ii - 1];
      segmentFrames[ii].sequence = frame === prev.frame ? prev.sequence : prev.sequence + 1;
      result[segmentFrames[ii].segment + keySuffix] = segmentFrames[ii].sequence;
    }
    return result;
  }

  // addMotion: function to add motion to state
  // evaluations: the evaluations to retrieve data for
  // motions: the current motions in state
  // user: the user the motions belong to
  static loadMotions = async ({ addMotion, evaluations, motions, user } ) =>{ 
    const populateMotion = async motionId => {
      try {
        let motion = await GetMotion(motionId, {
          subresources: [
            LAUNCH_MONITOR, 
            FORCE_PLATE, 
            HITTING_DEVICE_SENSOR, 
            FULL_MOTION_METRICS, 
            KEY_FRAME_DATA,
            ATTRIBUTES
          ] 
        });
        const { fullMotionMetrics, keyFrameData, ...restOfMotion } = motion;
        const { 
          truePeakSpeedPelvis, 
          truePeakSpeedTorso 
        } = MotionUtils.getTrueSpeeds(user, motion);
        addMotion({
          ...restOfMotion, 
          fullSwingMetrics: {
            ...fullMotionMetrics,
            truePeakSpeedPelvis,
            truePeakSpeedTorso
          }, 
          keyFrames: keyFrameData
        });
      } catch (err) {
        logger.error(`Error loading motion ${motionId}: ${err}`);
      }
    };

    let allMotionIds = evaluations.flatMap(e => e.swings.map(swing => swing.motionId));
    let motionsInState = motions.map(motion => motion.id);
    let missingMotions = allMotionIds.filter(id => !motionsInState.includes(id));
    await Promise.all(missingMotions.map(id => populateMotion(id)));
  }
}
