import React from 'react';

import Hero from '../../assets/Hero.jpg';

import { withStyles } from '@material-ui/core/styles';
import Styles from './styles';

class AuthorizationPageWrapper extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <img src={Hero} className={classes.img} alt='' />
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(Styles)(AuthorizationPageWrapper);