import React from 'react';
import {
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import sortBy from 'lodash/sortBy';
import { Skeleton } from '@material-ui/lab';

function DrillSelection({ drills, onSelect, selectedDrill, isLoading, ...containerProps }) {
  const sortedDrills = sortBy(drills, drill => drill.name);
  const drillItems = sortedDrills.map((drill, idx) => (
    <ListItem 
      key={`${drill.id}-${idx}`} 
      button 
      selected={selectedDrill === drill}
      onClick={() => onSelect(drill)}
    >
      <ListItemText>{drill.name}</ListItemText>
    </ListItem>
  ));

  const loadingItems = new Array(5).fill().map((_, i) => (
    <ListItem key={`drill-selection-${i}`}>
      <Skeleton width={150} />
    </ListItem>
  )); 

  return (
    <div {...containerProps}>
      <List>
        {isLoading ? loadingItems : drillItems}
      </List>
    </div>
  );
}

export default DrillSelection;