import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { 
  Paper, 
  Table, 
  TableHead, 
  TableRow, 
  TableCell, 
  TableBody 
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import Styles from './styles';
import classNames from 'classnames';

class StickyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leftStickyCellWidths: [],
      rightStickyCellWidths: []
    };

    this.leftStickyCellRefs = [];
    for(var i = 0; i < this.props.leftStickyColumnCount; i++) {
      this.leftStickyCellRefs.push(React.createRef());
    }

    this.rightStickyCellRefs = [];
    for(i = 0; i < this.props.rightStickyColumnCount; i++) {
      this.rightStickyCellRefs.push(React.createRef());
    }

    this.testRef = React.createRef();
  }

  componentDidMount() {
    var leftStickyCellWidths = this.leftStickyCellRefs.map((ref) => 
      ReactDOM.findDOMNode(ref.current).clientWidth 
    );
    var rightStickyCellWidths = this.rightStickyCellRefs.map((ref) =>
      ReactDOM.findDOMNode(ref.current).clientWidth
    );

    this.setState({
      leftStickyCellWidths: leftStickyCellWidths,
      rightStickyCellWidths: rightStickyCellWidths
    });
  }

  renderStickyRow = (rowKey, isHeader = false) => {
    const { classes } = this.props;

    var totalLeftWidth = 0;
    var totalRightWidth = this.state.rightStickyCellWidths
      .slice(1)
      .reduce((a, b) => a + b, 0);

    return (
      <TableRow key={rowKey}>
        {this.props.infoList.map((item, colNum) => {
          var styles = {};
          var isStickyLeft = false;
          var isStickyRight = false;

          var leftIdx = colNum;
          var rightIdx = colNum + 
            (this.state.rightStickyCellWidths.length - this.props.infoList.length);

          if (leftIdx < this.state.leftStickyCellWidths.length) {
            styles.left = totalLeftWidth;
            totalLeftWidth += this.state.leftStickyCellWidths[leftIdx];
            isStickyLeft = true;
          } 
          else if (rightIdx >= 0) {
            styles.right = totalRightWidth;
            totalRightWidth -= this.state.rightStickyCellWidths[rightIdx + 1];
            isStickyRight = true;
          }

          var hiZIndex = false;
          var medZIndex = false;
          var loZIndex = false;
          if (isHeader && (isStickyLeft || isStickyRight)) {
            hiZIndex = true;
          } else if (isHeader) {
            medZIndex = true;
          } else if (isStickyLeft || isStickyRight) {
            loZIndex = true;
          }

          var className = classNames({
            [classes.sticky]: isStickyLeft || isStickyRight,
            [classes.headerCell]: isHeader,
            [classes.bodyCell]: !isHeader,
            [classes.hiZIndex]: hiZIndex,
            [classes.medZIndex]: medZIndex,
            [classes.loZIndex]: loZIndex
          });

          var text = item.displayName;
          if (!isHeader) {
            text = item.formatter(this.props.data[rowKey][item.key]);
          }

          return (
            <TableCell
              className={className}
              style={styles}
              key={colNum}
            >
              {text}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  renderHeaderRow = () => {
    if (this.state.leftStickyCellWidths.length > 0
      || this.state.rightStickyCellWidths.length > 0) {
      return this.renderStickyRow('headerRow', true);
    } else {
      return (
        <TableRow>
          {this.props.infoList.map((obj, i) => {
            var ref;
            var leftIndex = i;
            var rightIndex = i + this.props.rightStickyColumnCount - this.props.infoList.length;
            if (leftIndex < this.props.leftStickyColumnCount) {
              ref = this.leftStickyCellRefs[leftIndex];
            } else if (rightIndex < this.props.rightStickyColumnCount) {
              ref = this.rightStickyCellRefs[rightIndex];
            }
            return (
              <TableCell
                ref={ref}
                className={this.props.classes.headerCell} 
                key={i}
              >
                {obj.displayName}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
  }

  render() {
    return (
      <Paper className={this.props.classes.root}>
        <Table>
          <TableHead>
            {this.renderHeaderRow()}
          </TableHead>
          <TableBody>
            {this.props.data.map((row, i) =>
              this.renderStickyRow(i)
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

StickyTable.propTypes = {
  leftStickyColumnCount: PropTypes.number.isRequired,
  rightStickyColumnCount: PropTypes.number.isRequired,
  infoList: PropTypes.array.isRequired
};

export default withStyles(Styles)(StickyTable);