import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Paper,
  Box,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Dialog
} from '@material-ui/core';
import {
  Warning as WarningIcon,
  Error as ErrorIcon
} from '@material-ui/icons';
import startCase from 'lodash/startCase';
import { history } from 'store';
import useUpcomingPlanNotes, { DUE_STATUS } from './useUpcomingPlanNotes';
import useStyles from './styles';
import { formatMMDDYY } from 'utils/formatting.utils';
import { NOTE_STATUSES } from 'constants/noteStatuses.constants';
import ConfirmationDialog from 'components/dialogs/confirmationDialog';
import { bodyPartsFocusedForResults } from 'utils/trainingPlan.utils';
import { GetAccountAssociations, GetCommunicationChannels } from 'network/userRequests';
import { CommunicationChannelTypes } from 'constants/communicationChannel.constants';
import useNetworkRequest from 'network/useNetworkRequest';
import ResendNotes from './resendNotes';

function TrainingPlanManagement() {
  const classes = useStyles();

  const [confirmDeliveryNote, setConfirmDeliveryNote] = useState(null);
  const [selectedTrainingPlan, setSelectedTrainingPlan] = useState(null);
  const {
    upcomingNotes,
    updateNoteStatus,
    updatingNoteIds,
    erroredNoteIds,
    loading,
    error,
    clearErroredNoteIds
  } = useUpcomingPlanNotes();

  const fetchDeliveryEmails = useCallback(async (cancelToken) => {
    if (confirmDeliveryNote == null) return [];

    const [channels, associations] = await Promise.all([
      GetCommunicationChannels(confirmDeliveryNote.userId, cancelToken), 
      GetAccountAssociations(confirmDeliveryNote.userId, ['user'], cancelToken)]);
    const channelEmails = channels
      .filter(channel => channel.type === CommunicationChannelTypes.EmailAddress)
      .map(channel => channel.value);
    const associatedEmails = associations.map(association => association.user.emailAddress);
    return [...channelEmails, ...associatedEmails];
  }, [confirmDeliveryNote]);

  const [
    deliveryEmails, 
    fetchingEmails, 
    fetchEmailError,
    setDeliveryEmails
  ] = useNetworkRequest([], 'FetchDeliveryEmails', fetchDeliveryEmails);

  const noDeliveryEmails = !fetchingEmails && !fetchEmailError && deliveryEmails.length === 0;

  useEffect(() => {
    if (confirmDeliveryNote == null) {
      clearErroredNoteIds();
      setDeliveryEmails([]);
      return;
    }
  }, [confirmDeliveryNote, clearErroredNoteIds, setDeliveryEmails]);

  const onConfirmDelivery = async note => {
    const result = await updateNoteStatus(note, NOTE_STATUSES.sent);
    if (result) {
      setConfirmDeliveryNote(null);
    }
  };

  const DueStatusIcon = ({ dueStatus }) => {
    switch (dueStatus) {
      case DUE_STATUS.PAST_DUE:
        return <ErrorIcon className={classes.pastDueIcon} />;
      case DUE_STATUS.DUE_TODAY:
        return <WarningIcon className={classes.dueIcon} />;
      case DUE_STATUS.UPCOMING:
        return <WarningIcon className={classes.upcomingIcon} />;
      default:
        return null;
    }
  };

  const DueStatusCountItem = ({ dueStatus, countAll = false }) => {
    const label = countAll ? 'Total' : startCase(dueStatus);
    const count = countAll 
      ? upcomingNotes.length 
      : upcomingNotes.filter(x => x.dueStatus === dueStatus).length;

    return <Grid container item spacing={1} xs justify='flex-end'>
      <Grid item><DueStatusIcon dueStatus={dueStatus} /></Grid>
      <Grid item><Typography>{label}: {count}</Typography></Grid>
    </Grid>;
  };

  const UpcomingNoteRow = ({ note }) => { 
    const formattedDate = formatMMDDYY(note.dateToSend);
    const { trainingPlan } = note;
    const { initialCaptureEventResult } = trainingPlan;
    
    return <TableRow>
      <TableCell>{note.trainingPlan.name}</TableCell>
      <TableCell>{note.trainingPlan.template?.name}</TableCell>
      <TableCell>{note.sequenceOrder + 1}</TableCell>
      <TableCell>{formattedDate}</TableCell>
      <TableCell><DueStatusIcon dueStatus={note.dueStatus} /></TableCell>
      <TableCell>
        <Button 
          variant='outlined'
          color='secondary'
          onClick={() => {
            setSelectedTrainingPlan(trainingPlan);
          }}
        >
          View 
        </Button>
      </TableCell>
      <TableCell alt='Primary Focus'>
        {bodyPartsFocusedForResults(trainingPlan)?.join(',')}
      </TableCell>
      <TableCell alt='Capture Event'>
        {initialCaptureEventResult?.captureEventUser?.captureEvent?.name}
      </TableCell>
      <TableCell align='right'>
        <Button 
          variant='outlined'
          color='secondary'
          onClick={() => setConfirmDeliveryNote(note)}
        >
          Deliver Section
        </Button>
      </TableCell>
    </TableRow>;
  };

  return <div className={classes.background}>
    <Box className={classes.container}>
      <Paper className={classes.headerWrapper}>
        <Grid container spacing={3} alignItems='center'>
          <Grid item>
            <Button 
              variant='outlined' 
              color='primary' 
              onClick={() => history.push('/player-development')}
            >
              Back
            </Button>

          </Grid>
          <Grid item>
            <Button 
              variant='outlined' 
              color='primary' 
              onClick={() => history.push('/capture-events')}
            >
              Capture Events
            </Button>
    
          </Grid>
          <Grid item>
            <Typography variant='h4'>Training Plans</Typography>
          </Grid>
          <DueStatusCountItem dueStatus={DUE_STATUS.PAST_DUE} />
          <DueStatusCountItem dueStatus={DUE_STATUS.DUE_TODAY} />
          <DueStatusCountItem dueStatus={DUE_STATUS.UPCOMING} />
          <DueStatusCountItem countAll />
        </Grid>
      </Paper>

      <Paper className={classes.tableWrapper}>
        {loading && <LinearProgress />}
        {error && <Typography color='error' align='center'>
          Error loading training plans, please refresh to try again.
        </Typography>}
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Plan</TableCell>
              <TableCell>Template</TableCell>
              <TableCell>Week</TableCell>
              <TableCell>Due</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Notification</TableCell>
              <TableCell>Focus Segment</TableCell>
              <TableCell>Capture Event</TableCell>
              <TableCell alt='Delivery Confirmation'/>
            </TableRow>
          </TableHead>
          <TableBody>
            {upcomingNotes.map(note => <UpcomingNoteRow key={note.id} note={note} />)}
          </TableBody>
        </Table>
      </Paper>
    </Box>

    <Dialog 
      onClose={() => {
        setSelectedTrainingPlan(null);
      }}
      aria-labelledby='confirmation-dialog' 
      open={selectedTrainingPlan != null}>
      <div className={classes.resendNotesContainer}>
        <Typography> Resend Notes: </Typography>
        {loading ? <CircularProgress /> : null}
        <ResendNotes 
          trainingPlan={selectedTrainingPlan}
          updateNoteStatus={updateNoteStatus}
        />
        <Button onClick={() => {
          setSelectedTrainingPlan(null);
        }}>Close</Button>
      </div>
    </Dialog>
    
    <ConfirmationDialog
      open={confirmDeliveryNote != null}
      onCancel={() => setConfirmDeliveryNote(null)}
      onConfirm={() => onConfirmDelivery(confirmDeliveryNote)}
      loading={updatingNoteIds.has(confirmDeliveryNote?.id)}
      disableCancelOnLoad
      errorMessage={erroredNoteIds.has(confirmDeliveryNote?.id)
        && 'Error delivering note, please try again.'}
      confirmDisabled={fetchingEmails || noDeliveryEmails}
    >
      <Typography>
        Are you sure you want to send week{confirmDeliveryNote?.sequenceOrder + 1} of
        plan {confirmDeliveryNote?.trainingPlan.name}? 
        <p><b>This will send an email to the following email addresses we have on file:</b></p>
        {fetchingEmails && <CircularProgress />}
        <List>
          {deliveryEmails.map(email => (
            <ListItem key={email}>
              <ListItemText primary={email}/>
            </ListItem>
          ))}
        </List>
        {fetchEmailError && <Typography color='error'>Error fetching emails to deliver to.</Typography>}
        {noDeliveryEmails && <Typography color='error'>
          No delivery emails associated with this user. Please add a delivery email or associated
          parent accounts to the user in the PDP tools.
        </Typography>}
      </Typography>
    </ConfirmationDialog>
  </div>;
}

export default TrainingPlanManagement;
