import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

// taken from https://css-tricks.com/snippets/javascript/random-hex-color/
export const getRandomColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);

const Palette = {
  transparent: 'transparent',
  superLightGrey: '#f3f3f3',
  primaryRed: '#c20f2f',
  appBackground: 'whitesmoke',
  midnightBlue: '#3f51b5',
  extraLightGrey: '#dadada',
  lightGray: '#eee',
  mediumGray: '#BBBBBB',
  darkGray: '#888888',
  hoverBlack: 'rgb(30, 30, 30)',
  white: 'white',
  black: 'black',
  error: '#c20f2f',
  errorLessSevere: '#FFE3E3',
  warning: amber[400],
  subtleGreen: '#BCFECB',
  subtleRed: '#ffbaba',
  extraLightGray: '#F4F4F4',
  wateredDownMustard: '#FFF0BA',
  deepBlue: '#162243',
  brightBlue: '#33a0fd',
  lightenedDeepBlue: '#636F90',
  lightBlue: '#e8fafd',
  blueGray: '#242C4A',
  lightBlueGray: '#3E506A',
  subtleWhite: '#F5F7F8',
  gray: '#617277',
  almostBlackBlue: '#193444',
  blueGreen: '#00A5BC',
  bluePurple: '#425AD9',
  coolGrey: 'rgb(196, 199, 218)',
  success: green[600],
  vimeoPlayerColor: '4f6bff', // no #Hash is important
  positiveLabelColor: '#4bd412',
  neutralLabelColor: '#ffd84b',
  negativeLabelColor: '#ffbaba',
  green: 'green',
  red: 'red',
  fadedGrey: 'rgb(34, 40, 49, 0.3)',
  toolbarSelectedBackground: '#e8f0fe',
  toolbarSelectedIcon: '#1a73e8',
  stripeRed: '#9e2146',
  stripeBase: '#32325d',
  publicAccountBannerColor: 'rgb(0,85,158)',
  kmobileBackgroundColor: '#132739',
  whiteBackgroundHighlight: '#E9E9E9',
  leaderboardSelectedNumberBackground: '#DBDBDB',
  leaderboardSelectedNumberOutlineBackground: '#BABABA',
  percentileRankingColors: {
    low: '#FF5656', // red
    average: '#FFF5A0', // yellow
    high: '#83FF6F' // green
  }
};

export default Palette;
