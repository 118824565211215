import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { formatMMDDYY } from 'utils/formatting.utils';
import CheckCircle from '@material-ui/icons/CheckCircle';
import useStyles from './styles';

function CaptureEventListItem({ 
  captureEvent, 
  multipleSelected = false, 
  includeId = false,
  ...listItemProps 
}) {
  const classes = useStyles();
  const isProcessing = captureEvent.status === 'Processing';

  return <ListItem {...listItemProps}>
    <ListItemText 
      primary={captureEvent.name}
      secondary={<Typography className={classes.itemSecondaryContainer}>
        <Typography component='span' variant='body2'>
          {formatMMDDYY(captureEvent.startDate)} - {formatMMDDYY(captureEvent.endDate)}
          {includeId && captureEvent?.id != null && <i>{' | [ID: ' + captureEvent?.id + ']'}</i>}
        </Typography>
        {isProcessing && <Typography component='span' variant='caption'>Processing...</Typography>}
        {multipleSelected 
          && <div className={classes.selected}>
            <CheckCircle className={classes.check} />
          </div>
        }
      </Typography>}
    />
  </ListItem>;
}

export default CaptureEventListItem;
