export const PERCENTILES_LABELS = {
  avgExitVelo: 'Avg. Exit Velo',
  peakExitVelo: 'Peak Exit Velo',
  exitVeloPercentile: 'Exit Velo Percentile',
  pelvisSpeedPercentile: 'Pelvis Speed Percentile',
  torsoSpeedPercentile: 'Torso Speed Percentile',
  armSpeedPercentile: 'Arm Speed Percentile',
  handSpeedPercentile: 'Hand Speed Percentile',
  bodySpeedPercentile: 'Body SpeedPercentile'
};

export const PERCENTILE_THRESHOLDS = {
  LOWER: 40,
  UPPER: 70
};
