import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import Palette from '../../../../styles/palette';

const TrimmerControls = withStyles({ 
  thumb: {
    height: 8,
    width: 8,
    borderRaidus: 0,
    opacity: 0.0,
    backgroundColor: Palette.black,
    border: '1px solid currentColor',
    marginTop: -4,
    marginLeft: -4
  },

  mark: {
    backgroundColor: Palette.black,
    height: 25,
    width: 8,
    marginLeft: -4,
    marginTop: -15
  },

  rail: {
    height: 0,
    opacity: 0.0
  },

  track: {
    height: 0
  }

})(Slider);

export default TrimmerControls;