import Network from './network';
import queryString from 'query-string';

const SWING_SUMMARIES = 'API/Motions/SwingSummaries/Baseball';
const PITCHING_SUMMARIES = 'API/Motions/SwingSummaries/Pitching';
const DOWNLOAD_SUFFIX = '/Download';

export const GetBaseballSwingSummaries = async params => {
  let resp = await Network.Get(SWING_SUMMARIES, params);
  return resp.data.items;
};

export const GetPitchingSummaries = async params => {
  let resp = await Network.Get(PITCHING_SUMMARIES, params);
  return resp.data.items;
};

export const DownloadBaseballSummaryDataAsCsv = (params) => {
  // this downloads a file by hitting a response and is different
  // than a normal network call that would do something based on the response.
  var queryParamString = '?' + queryString.stringify(params);
  var url = SWING_SUMMARIES + DOWNLOAD_SUFFIX + queryParamString;
  return Network.Get(url);
};

export const DownloadPitchingSummaryDataAsCsv = (params) => {
  // this downloads a file by hitting a response and is different
  // than a normal network call that would do something based on the response.
  var queryParamString = '?' + queryString.stringify(params);
  var url = PITCHING_SUMMARIES + DOWNLOAD_SUFFIX + queryParamString;
  return Network.Get(url);
};

export const DownloadMotionsAsZip = (params, cancelSource) => {
  return Network.Get(
    SWING_SUMMARIES + '/Motions/Download', 
    params, 
    cancelSource,
    null,
    'arraybuffer');
};

export const DownloadBaseballSwingCsv = motionId => {
  var url = 'API/Motions/' + motionId + '/Download';
  return Network.Get(url);
}; 