import { makeStyles } from '@material-ui/core/styles';
import { yellow, green, red } from '@material-ui/core/colors';
import Palette from 'styles/palette';

const useStyles = makeStyles(theme => ({
  tablesContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  table: {
    padding: '10px',
    margin: '20px 10px',
    borderCollapse: 'collapse'
  },
  tableBody: {
    '& > tr > *': {
      padding: '5px 20px',
      border: '1px solid black',
      minWidth: '50px',
      fontWeight: 'bold'
    }
  },
  updateUserFieldButton: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  deliveryStatusButton: {
    marginLeft: '15px'
  },
  fail: {
    backgroundColor: red[500]
  },
  needsWork: {
    backgroundColor: yellow[500]
  },
  pass: {
    backgroundColor: green[500]
  },
  primaryHeaderRow: {
    backgroundColor: Palette.black,
    color: Palette.white
  },
  secondaryHeaderRow: {
    backgroundColor: Palette.lightGray
  },
  createPlan: {
    padding: '0 10px 0 10px'
  },
  playerResults: {
    marginTop: '50px',
    flexDirection: 'column',
    alignItems: 'center'
  },
  clientTitle: {
    display: 'flex',
    flexDirection: 'row'
  },
  showSliderDemoButton: {
    marginLeft: '25px'
  },
  pastPlansContainer: {
    display: 'flex'
  },
  pastPlans: {
    marginLeft: theme.spacing(2)
  },
  tableLabel: {
    fontWeight: 'bold',
    textAlign: 'right',
    paddingRight: '12px'
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  alignRight: {
    justifyContent: 'flex-end'
  },
  pgProfileButtons: {
    paddingTop: '15px',
    paddingBottom: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  refreshProfileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

export default useStyles;
