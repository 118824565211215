// Actions
const UPDATE_PLAYER_DRAFT = 'kdashboard/assessments/add';
const REMOVE_PLAYER_DRAFT = 'kdashboard/assessments/delete';

// Reducer
const initialState = {};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PLAYER_DRAFT:
      let { userId, assessment } = action.data;
      let updatedPlayerState = { ...state };
      updatedPlayerState[userId] = assessment;
      return updatedPlayerState;
    case REMOVE_PLAYER_DRAFT:
      let removedPlayerState = { ...state };
      delete removedPlayerState[action.userId];
      return removedPlayerState;
    default: 
      return state;
  }
}

// Action Creators
export function UpdatePlayerDraft(userId, assessment) {
  const data = { userId, assessment };
  return { type: UPDATE_PLAYER_DRAFT, data };
}

export function RemovePlayerDraft(userId) {
  return { type: REMOVE_PLAYER_DRAFT, userId };
}
