import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Grid,
  Chip,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { GetDrill } from '../../../network/drillRequests';
import { CAMERA_ANGLE } from '../../../constants/video.constants';
import useInput from '../../../utils/useInput';
import DrillVideo from './drillVideo';
import useStyles from './styles'; 
import { DrillTypeList, RepUnitsList } from '../drills.constants';

function DrillEditor({ 
  initialDrill = {}, 
  onSave, 
  isLoading, 
  allEquipment, 
  allAttributes, 
  allDrillVideos 
}) {  
  const nameInput = useInput({ initialValue: initialDrill.name });
  const descriptionInput = useInput({ initialValue: initialDrill.description });
  const setsInput = useInput({ 
    initialValue: initialDrill.defaultSets,
    validationMsg: 'Required',
    validation: value => value !== ''
  });
  const repsInput = useInput({ 
    initialValue: initialDrill.defaultReps,
    validationMsg: 'Required',
    validation: value => value !== ''
  });
  const [errMsg, setErrMsg] = useState('');
  const [fetchingDrill, setFetchingDrill] = useState(false);
  const [savingDrill, setSavingDrill] = useState(false);
  const [drillVideo, setDrillVideo] = useState(initialDrill.drillVideo);
  const [cueVideo, setCueVideo] = useState(initialDrill.cueVideo);
  const [trainingModeCueVideo, setTrainingModeCueVideo] = useState(
    initialDrill.trainigModeCueVideo);
  const [trainingModeCuePreviewVideo, setTrainingModeCuePreviewVideo] = useState(
    initialDrill.trainingModeCuePreviewVideo);
  const [drill, setDrill] = useState(initialDrill);
  const [requiredEquipmentIds, setRequiredEquipmentIds] = useState([]);
  const [relatedAttributeIds, setRelatedAttributeIds] = useState([]);
  const [cameraAngle, setCameraAngle] = useState('');
  const [drillType, setDrillType] = useState('');
  const [repUnits, setRepUnits] = useState('');
  const theme = useTheme();
  const classes = useStyles();

  const cameraAngles = [
    CAMERA_ANGLE.catchersView, 
    CAMERA_ANGLE.pitchersView, 
    CAMERA_ANGLE.faceOn
  ];

  useEffect(() => {
    if (!initialDrill.id) return;
    const cancelToken = axios.CancelToken.source();
    setFetchingDrill(true);
    async function fetchDrill() {
      try {
        var detailedDrill = await GetDrill(initialDrill.id, cancelToken);
        setDrill(detailedDrill);
        setCueVideo(detailedDrill.cueVideo);
        setDrillVideo(detailedDrill.drillVideo);
        setTrainingModeCueVideo(detailedDrill.trainingModeCueVideo);
        setTrainingModeCuePreviewVideo(detailedDrill.trainingModeCuePreviewVideo);
        setRelatedAttributeIds(detailedDrill.relatedAttributes.map(a => a.motionAttributeId));
        setRequiredEquipmentIds(detailedDrill.requiredEquipment.map(a => a.equipmentId));
        setCameraAngle(detailedDrill.cameraAngle);
        setDrillType(detailedDrill.type);
        setRepUnits(detailedDrill.repUnits);
      } catch (e) {
        setErrMsg('There was problem getting the details for this drill.');
      }
      setFetchingDrill(false);
    }
    fetchDrill();
    return cancelToken.cancel;
  }, [initialDrill.id]);

  const updateDrill = async () => {
    var validations = [setsInput, repsInput].map(input => input.validate());
    if (!validations.every(valid => valid)) {
      return;
    }

    setSavingDrill(true);
    const newDrill = {
      ...drill,
      name: nameInput.value,
      description: descriptionInput.value,
      defaultReps: repsInput.value,
      defaultSets: setsInput.value,
      cueVideoId: cueVideo && cueVideo.id,
      drillVideoId: drillVideo && drillVideo.id,
      trainingModeCuePreviewVideoId: trainingModeCuePreviewVideo && trainingModeCuePreviewVideo.id,
      trainingModeCueVideoId: trainingModeCueVideo && trainingModeCueVideo.id, 
      relatedAttributes: relatedAttributeIds.map(id => ({ motionAttributeId: id })),
      requiredEquipment: requiredEquipmentIds.map(id => ({ equipmentId: id })),
      cameraAngle: cameraAngle === '' ? null : cameraAngle,
      type: drillType === '' ? null : drillType,
      repUnits: repUnits === '' ? null : repUnits
    };
    await onSave(newDrill);
    setSavingDrill(false);
  };

  function getStyles(item, state, theme) {
    return {
      fontWeight:
        state.some(itemInState => itemInState.id === item.id)
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  }

  const requestingData = isLoading || fetchingDrill;

  return (
    <Grid container spacing={4}>
      <Typography color='error'>{errMsg}</Typography>
      <Grid item xs={7} container className={classes.textfieldsContainer} justify='space-between'>
        <Grid item xs={6}>
          <TextField 
            className={classes.formField} 
            variant='outlined' 
            label='Name' 
            disabled={requestingData}
            autoFocus
            {...nameInput.bind} 
          />
        </Grid>
        <Grid item xs={5}>
          <FormControl variant='outlined' className={classes.formField}>
            <InputLabel htmlFor='select-camera-angle'>Camera Angle</InputLabel>
            <Select
              disabled={requestingData}
              variant='outlined'
              value={cameraAngle}
              inputProps={{
                id: 'select-camera-angle'
              }}
              onChange={e => setCameraAngle(e.target.value)}
              className={classes.cameraAngleSelect}
            >
              {cameraAngles.map(angle => (
                <MenuItem 
                  key={angle} 
                  value={angle} 
                >
                  {angle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField 
            className={classes.formField}
            variant='outlined' 
            label='Description'
            disabled={requestingData}
            multiline
            rows={4} 
            {...descriptionInput.bind} 
          />
        </Grid>
        <Grid item xs={12} container justify='space-between'>
          <Grid item xs={6} container>
            <FormControl variant='outlined' className={classes.formField}>
              <InputLabel htmlFor='select-equipment-chip'>Required Equipment</InputLabel>
              <Select
                disabled={requestingData}
                variant='outlined'
                multiple
                value={requiredEquipmentIds}
                onChange={e => setRequiredEquipmentIds(e.target.value)}
                inputProps={{
                  id: 'select-equipment-chip'
                }}
                renderValue={(selectedIds) => (
                  <div className={classes.chips}>
                    {selectedIds.map(id => (
                      <Chip 
                        key={`equip-chip-${id}`} 
                        label={allEquipment.find(e => e.id === id).name} 
                        className={classes.chip} 
                      />
                    ))}
                  </div>
                )}
              >
                {allEquipment.map(e => (
                  <MenuItem 
                    key={e.id} 
                    value={e.id} 
                    style={getStyles(e.id, requiredEquipmentIds, theme)}
                  >
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant='outlined' className={classes.formField}>
              <InputLabel htmlFor='select-attribute-chip'>Related Characteristics</InputLabel>
              <Select
                multiple
                disabled={requestingData}
                value={relatedAttributeIds}
                onChange={e => setRelatedAttributeIds(e.target.value)}
                inputProps={{
                  id: 'select-attribute-chip'
                }}
                renderValue={(selectedIds) => (
                  <div className={classes.chips}>
                    {selectedIds.map(id => (
                      <Chip 
                        key={`equip-chip-${id}`} 
                        label={allAttributes.find(e => e.id === id).name} 
                        className={classes.chip} 
                      />
                    ))}
                  </div>
                )}
              >
                {allAttributes.map(a => (
                  <MenuItem 
                    key={a.id} 
                    value={a.id} 
                    style={getStyles(a.id, relatedAttributeIds, theme)}
                  >
                    {a.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5} container>
            <TextField 
              className={classes.formField} 
              disabled={requestingData}
              variant='outlined' 
              label='Default Sets' 
              type='number' 
              {...setsInput.bind}
            />
            <TextField 
              className={classes.formField} 
              disabled={requestingData}
              variant='outlined' 
              label='Default Reps' 
              type='number' 
              {...repsInput.bind} 
            />
          </Grid>
        </Grid>
        <Grid item x={12} container justify='space-between' spacing={3}>
          <Grid item xs={6}>
            <FormControl variant='outlined' className={classes.formField}>
              <InputLabel id='select-drill-type-label'>Drill Type</InputLabel>
              <Select
                disabled={requestingData}
                variant='outlined'
                value={drillType}
                labelId='select-drill-type-label'
                id='select-drill-type'
                onChange={e => setDrillType(e.target.value)}
              >
                <MenuItem value=''><em>None</em></MenuItem>
                {DrillTypeList.map(drillType => (
                  <MenuItem 
                    key={drillType} 
                    value={drillType} 
                  >
                    {drillType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant='outlined' className={classes.formField}>
              <InputLabel id='select-rep-units-label'>Rep Units</InputLabel>
              <Select
                disabled={requestingData}
                variant='outlined'
                value={repUnits}
                labelId='select-camera-angle-label'
                id='select-camera-angle'
                onChange={e => setRepUnits(e.target.value)}
              >
                <MenuItem value=''><em>None</em></MenuItem>
                {RepUnitsList.map(units => (
                  <MenuItem 
                    key={units} 
                    value={units} 
                  >
                    {units}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button 
          onClick={updateDrill} 
          color='primary' 
          variant='contained' 
          className={classes.createButton}
          disabled={fetchingDrill || savingDrill}
        >
          {drill.id ? 'Update' : 'Create'}
        </Button>
      </Grid>
      <Grid item xs={5}>
        <DrillVideo 
          title='Drill Video' 
          video={drillVideo} 
          allDrillVideos={allDrillVideos} 
          onSelection={setDrillVideo}
          className={classes.videoComponentWrapper}
        />
        <DrillVideo 
          title='Cue Video' 
          video={cueVideo} 
          allDrillVideos={allDrillVideos}
          onSelection={setCueVideo}
          className={classes.videoComponentWrapper}
        />
        <DrillVideo 
          title='Training Mode Cue Video' 
          video={trainingModeCueVideo} 
          allDrillVideos={allDrillVideos}
          onSelection={setTrainingModeCueVideo}
          className={classes.videoComponentWrapper}
        />
        <DrillVideo 
          title='Training Mode Cue Preview Video' 
          video={trainingModeCuePreviewVideo} 
          allDrillVideos={allDrillVideos}
          onSelection={setTrainingModeCuePreviewVideo}
          className={classes.videoComponentWrapper}
        />
      </Grid>
    </Grid>
  );
};

export default DrillEditor;