const CHART_FONT_COLOR = '#000';
const CHART_FONT_FAMILY = '"Roboto", "Helvetica", "Arial", sans-serif'; 
const FONT_SIZE = 16;

const styles = {
  container: {
    background: 'white'
  },
  victoryChart: { 
    parent: { 
      borderRadius: '10px',
      height: 'inherit'
    }
  },
  title: { 
    fill: CHART_FONT_COLOR, 
    fontFamily: CHART_FONT_FAMILY,
    fontSize: FONT_SIZE
  },
  axis: { 
    axis: { 
      stroke: 'none' 
    }, 
    tickLabels: { 
      fill: CHART_FONT_COLOR, 
      fontFamily: CHART_FONT_FAMILY,
      fontSize: FONT_SIZE
    }
  },
  legend: { 
    labels: { 
      fill: CHART_FONT_COLOR,
      fontFamily: CHART_FONT_FAMILY,
      fontSize: FONT_SIZE,
      background: 'rgba(34, 65, 74, 0.4)'
    },
    parent: {
      background: 'rgba(34, 65, 74, 0.4)'
    },
    data: {
      size: 3
    }
  }
};

export default styles;