import React, { useEffect, useState } from 'react';
import Logger from 'js-logger';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import MenuItem from '@material-ui/core/MenuItem';

import ActionPlanTextEditor from '../../../actionPlanComponent/actionPlanTextEditor';
import ProgressButton from '../../../../components/progressButton';
import TEXT_FORMAT_TYPES from '../../../../constants/actionPlanTextTypes.constants';
import { CreateContentFeedItem } from '../../../../network/contentFeedItemsRequests';
import VideoSelectionDialog from '../../../actionPlanComponent/videoSelectionDialog';
import useStyles from './styles';
import FormControlSelect from '../../../../components/formControlSelect';

const INITIAL_EDITOR_STATE = {
  markdown: '',
  plainText: '',
  textFormatType: null, 
  state: null
};

const CONTENT_TYPES = [
  'Welcome',
  'SwingFoundation',
  'Mobility',
  'BaseballIQ',
  'Other'
];

function CreateContentFeedItemDialog({ open, onClose, organizationId, onNewItemCreated }) {
  const [editorData, setEditorData] = useState(INITIAL_EDITOR_STATE);
  const [title, setTitle] = useState('');
  const [contentType, setContentType] = useState('');
  const [selectingVideos, setSelectingVideos] = useState(false);
  const [selectedVideoIds, setSelectedVideoIds] = useState([]);
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(false);

  const classes = useStyles();

  useEffect(() => { 
    if (open) {
      setEditorData(INITIAL_EDITOR_STATE);
      setSelectedVideoIds([]);
      setError(false);
      setCreating(false);
    }
  }, [open]);

  function onSelectedVideosChange(videoIds) {
    setSelectedVideoIds(videoIds);
    setSelectingVideos(false);
  }

  async function onCreateClick() {
    setCreating(true);
    try {
      const { markdown, plainText, textFormatType } = editorData;
      const text = textFormatType === TEXT_FORMAT_TYPES.MARKDOWN ? markdown : plainText;
      const contentFeedItemVideos = selectedVideoIds.map(videoId => ({ videoId }));
      const body = { 
        organizationId, 
        title,
        text, 
        textFormatType, 
        type: contentType,
        contentFeedItemVideos 
      };
      const newContentFeedItem = await CreateContentFeedItem(body);
      onNewItemCreated(newContentFeedItem);
    }
    catch (e) {
      Logger.error(`Error creating new content feed item for org ${organizationId}`, e);
      setError(true);
    }
    setCreating(false);
  }

  const createDisabled = (editorData.plainText.trim() === '' && selectedVideoIds.length === 0)
    || title.trim() === ''
    || contentType === '';

  return <>
    <Dialog open={open} maxWidth='lg' fullWidth>
      <DialogTitle>New Content Feed Item</DialogTitle>
      <DialogContent>
        <div className={classes.headerContainer}>
          <TextField 
            variant='outlined'
            label='Title'
            value={title}
            onChange={e => setTitle(e.target.value)}
            className={classes.titleTextField}
          />
          <FormControlSelect id='content-type-dropdown'
            value={contentType}
            label='Content Type'
            onChange={setContentType}            
            items={CONTENT_TYPES}
            renderItem={item => <MenuItem key={item} value={item}>{item}</MenuItem>}
            className={classes.contentTypeSelect}
          />
        </div>

        <div className={classes.textEditorContainer}>
          <ActionPlanTextEditor
            placeholder='Enter new content feed item text here.'
            textData={{ data: editorData.state, formattedText: editorData.markdown }}
            onChange={(markdown, plainText, textFormatType, state) => 
              setEditorData({ markdown, plainText, textFormatType, state })}
            editorHeight={300}
          />
        </div>

        <div className={classes.videoButtonContainer}>
          <Badge max={9} badgeContent={selectedVideoIds.length} color='primary'>
            <Button 
              startIcon={<VideoLibraryIcon />} 
              onClick={() => setSelectingVideos(true)}
              color='primary'
            >
              Attach Videos
            </Button>
          </Badge>
        </div>
      </DialogContent>
      <DialogActions>
        {error && <Typography color='error'>
          There was an error creating the new content feed item. Please try again.
        </Typography>}
        <Button variant='outlined' onClick={onClose} disabled={creating}>
          Cancel
        </Button>
        <ProgressButton 
          variant='outlined' 
          color='primary' 
          onClick={onCreateClick}
          disabled={createDisabled}
          showProgress={creating}
        >
          Create
        </ProgressButton>
      </DialogActions>
    </Dialog>

    <VideoSelectionDialog 
      open={selectingVideos}
      videoIds={selectedVideoIds}
      onCancel={() => setSelectingVideos(false)}
      onSave={onSelectedVideosChange}
    />
  </>;
}

export default CreateContentFeedItemDialog;
