import makeStyles from '@material-ui/core/styles/makeStyles';
import Palette from '../../styles/palette';

const useStyles = makeStyles(() => ({
  buttonBase: ({ color }) => ({
    width: '40px',
    aspectRatio: 1,
    borderRadius: '10px',
    border: '2px solid ' + Palette.black,
    backgroundColor: color,
    '&:hover': {
      opacity: '0.8'
    }
  }),
  button: () => ({
    margin: '3px'
  }),
  popover: ({ newColor }) => ({
    backgroundColor: newColor
  }),
  hexPicker: {
    padding: '25px', 
    display: 'flex', 
    justifyContent: 'center'
  },
  inputContainer: {
    display: 'flex', 
    justifyContent: 'space-between', 
    padding: '0 25px 25px 25px'
  }
}));

export default useStyles;
