import { makeStyles } from '@material-ui/styles';
import palette from '../../../../styles/palette';

const useStyles = makeStyles(_theme => ({ 
  listItem: {
    marginTop: '8px',
    padding: '12px',
    backgroundColor: 'white',
    border: '1px solid lightgray',
    borderRadius: '5px',
    zIndex: 2,
    minWidth: '200px',
    position: 'relative',
    overflowX: 'hidden',
    transition: 'background-color 0.5s ease'
  },
  listItemFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  listItemLabel: {
    flexGrow: 1, 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'flex-start'
  },
  newListItem: {
    backgroundColor: 'lightgreen'
  },
  selectedListItem: {
    border: '2px solid darkblue'
  },
  textField: {
    width: '80px',
    minWidth: '45px',
    padding: '0 15px'
  },
  textFieldInput: {
    padding: '8px 10px'
  },
  error: {
    border: `2px solid ${palette.error}`
  },
  grayText: {
    color: palette.gray
  },
  exitVelocityMargin: {
    margin: '10px'
  },
  exitVelocityContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  moreButton: {
    color: palette.gray,
    cursor: 'pointer',
    '&:hover': {
      color: 'black'
    }
  },
  savingLabelSaved: {
    color: palette.success,
    opacity: 0,
    transition: 'opacity 3s'
  },
  savingLabelError: {
    color: palette.error
  },
  badMotion: {
    backgroundColor: palette.errorLessSevere,
    padding: '5px',
    borderRadius: '3px'
  }
}));

export default useStyles;
