import React, { useState, useCallback } from 'react';
import {
  DialogContent,
  Grid,
  Button,
  Typography,
  Link,
  Box,
  Divider,
  CircularProgress
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useLatestUserMeasurement } from 'network/userRequests';
import UnitConverter from 'utils/unitConverter.utils';
import PercentilesTable from './percentilesTable';
import BodyPercentiles from './bodyPercentiles';
import useStyles from './styles';
import { updateEventUser } from 'store/captureEvents';
import { fetchCaptureEvents } from 'store/captureEvents';
import useNetworkRequest from 'network/useNetworkRequest';
import { CommunicationChannelTypes } from 'constants/communicationChannel.constants';
import { GetCommunicationChannels } from 'network/userRequests';
import PlayerParents from './playerParents';
import EditAvailability from './editAvailability';
import SliderDemoDialog from '../sliderDemoDialog';
import PastTrainingPlans from './pastTrainingPlans';
import UpdateUserFieldsDialog from './updateUserFieldsDialog';
import classnames from 'classnames';
import PurchaseStatusIcon from '../purchaseStatusIcon';
import DeliveryStatusDialog from './deliveryStatusDialog';
import { RefreshPerfectGameProfile } from 'network/captureEventRequests';
import Logger from 'js-logger';

const PLAYER_PROFILE_URL_BASE = 'https://www.perfectgame.org/Players/Playerprofile.aspx?ID=';

const LEFT_COLUMN_SIZE = 4;
const RIGHT_COLUMN_SIZE = 12 - LEFT_COLUMN_SIZE;

function TrainingPlanToolsDialog({ eventUser, captureEvent }) {
  const { user, captureEventResults, captureEventId } = eventUser;
  const { userId, fullName, emailAddress, organizationUserProperties, highSchoolClassYear } = user;
  const externalId = organizationUserProperties?.externalId;

  const [refreshingProfile, setRefreshingProfile] = useState(false);
  const [refreshMessage, setRefreshMessage] = useState(null);

  const dispatch = useDispatch();

  const GetCommunicationChannelsForUser = useCallback(async token => {
    return await GetCommunicationChannels(user.userId, token);
  }, [user]);

  const channelsNetworkRequest = 
    useNetworkRequest([], 'GetCommunicationChannels', GetCommunicationChannelsForUser);

  const [communicationChannels, channelsLoading,, setChannels] = channelsNetworkRequest;
    
  const [
    measurement, 
    measurementLoading, 
    measurementError, 
    setMeasurement
  ] = useLatestUserMeasurement(userId);

  const { weightKilograms, heightCentimeters } = measurement ?? {};
  
  const weight = weightKilograms != null 
    ? Math.round(UnitConverter.kgToLb(weightKilograms)) 
    : null;

  const height = heightCentimeters != null 
    ? Math.round(UnitConverter.cmToIn(heightCentimeters)) 
    : null;

  const [selectedResultForDemo, setSelectedResultForDemo] = useState(null);

  const [updateUserFieldsDialogOpen, setUpdateUserFieldsDialogOpen] 
    = useState(false);
  const [updateDeliveryStatusOpen, setUpdateDeliveryStatusOpen]
    = useState(false);

  const playerProfileUrl = externalId 
    ? PLAYER_PROFILE_URL_BASE + externalId
    : null;

  const classes = useStyles();
  
  const updateFields = fieldsUpdated => {
    const { 
      user, 
      measurement, 
      captureEventResults, 
      addedCommunicationChannel,
      deletedCommunicationChannelId  
    } = fieldsUpdated;

    // the external if field can be deleted signified by a null value
    // so we need to check if the property exists in the json
    if (user != null || 'externalId' in fieldsUpdated) {
      dispatch(fetchCaptureEvents());
    }

    if (measurement != null) {
      setMeasurement(measurement);
    }

    if (captureEventResults != null) {
      const updatedEventUser = { ...eventUser, captureEventResults };
      dispatch(updateEventUser(updatedEventUser));
    }

    if (addedCommunicationChannel != null) {
      setChannels(prev => {
        if (prev.some(x => x.id === addedCommunicationChannel.id)) {
          return prev.map(
            x => x.id === addedCommunicationChannel.id ? addedCommunicationChannel : x);
        } else {
          return [...prev, addedCommunicationChannel];
        }
      });
    }

    if (deletedCommunicationChannelId != null) {
      setChannels(prev => prev.filter(x => x.id !== deletedCommunicationChannelId));
    }

    setUpdateUserFieldsDialogOpen(false);
  };

  const measurementErrorMsg = measurementError ? 'Error loading player stats.' : null;

  const getHandednessLabel = result => captureEventResults.length > 1
    ? ` (${result.handedness})`
    : '';

  const heightToFeetAndInches = (heightCentimeters) => {
    return `${Math.floor(heightCentimeters / 12)}'${heightCentimeters % 12}"`;
  };

  const resultUpdated = (updatedResult) => {
    const updatedResults = 
      captureEventResults.map((result) => {
        return updatedResult.id === result.id ? updatedResult : result;
      });
    const updatedEventUser = { ...eventUser, captureEventResults: updatedResults };
    dispatch(updateEventUser(updatedEventUser));
  };

  const getEmailChannel = allChannels => {
    return allChannels.find(x => x.type === CommunicationChannelTypes.EmailAddress);
  };

  const deliveryStatusUpdated = (updatedEventUser) => {
    dispatch(updateEventUser(updatedEventUser));
    setUpdateDeliveryStatusOpen(false);
  };

  const refreshProfile = async () => {
    if (refreshingProfile) return;

    setRefreshMessage('');
    setRefreshingProfile(true);

    try {
      const resp = await RefreshPerfectGameProfile(externalId, captureEvent.externalId);
      setRefreshMessage(resp);
    } catch (e) {
      setRefreshMessage('Unknown error refreshing data. Please contact dev.');
      Logger.error('Unknown refresh perfect game player', e);
    }

    setRefreshingProfile(false);
  };

  return <>
    <>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Box className={classes.editFieldsContainer} p={1}>

              <Typography color='error'>{measurementErrorMsg}</Typography>
              <Grid container spacing={1}>

                <Grid 
                  className={classnames(classes.gridItem, classes.alignRight)} 
                  item 
                  xs={LEFT_COLUMN_SIZE}
                >
                  <Typography className={classes.tableLabel}>Name</Typography>
                </Grid>
                <Grid className={classes.gridItem} item xs={RIGHT_COLUMN_SIZE}>
                  <Typography>{ fullName }</Typography>
                </Grid>

                <Grid 
                  className={classnames(classes.gridItem, classes.alignRight)} 
                  item 
                  xs={LEFT_COLUMN_SIZE}
                >
                  <Typography className={classes.tableLabel}>Login Email</Typography>
                </Grid>
                <Grid className={classes.gridItem} item xs={RIGHT_COLUMN_SIZE}>
                  <Typography>{ emailAddress }</Typography>
                </Grid>

                <Grid 
                  className={classnames(classes.gridItem, classes.alignRight)}
                  item 
                  xs={LEFT_COLUMN_SIZE}
                >
                  <Typography className={classes.tableLabel}>External Id</Typography>
                </Grid>
                <Grid className={classes.gridItem} item xs={RIGHT_COLUMN_SIZE}>
                  <Typography >
                    {user?.organizationUserProperties?.externalId ?? <i>No External Id</i>}
                  </Typography>
                </Grid>

                <Grid 
                  className={classnames(classes.gridItem, classes.alignRight)}
                  item 
                  xs={LEFT_COLUMN_SIZE}
                >
                  <Typography className={classes.tableLabel}>Communication Email</Typography>
                </Grid>
                <Grid className={classes.gridItem} item xs={RIGHT_COLUMN_SIZE}>
                  {channelsLoading && <CircularProgress size={25} />}
                  {!channelsLoading && communicationChannels != null && 
                    (getEmailChannel(communicationChannels)?.value != null
                      ? <Typography>{ getEmailChannel(communicationChannels)?.value }</Typography> 
                      : <Typography color='error'><b>*REQUIRED | No Communication Channel</b></Typography>)}
                </Grid>

                <Grid 
                  className={classnames(classes.gridItem, classes.alignRight)}
                  item
                  xs={LEFT_COLUMN_SIZE}
                >
                  <Typography className={classes.tableLabel}>Weight (lbs)</Typography>
                </Grid>
                <Grid className={classes.gridItem} item xs={RIGHT_COLUMN_SIZE}>
                  {measurementLoading && <CircularProgress size={25} />}
                  {!measurementLoading && <Typography>{
                    weight != null 
                      ? Math.round(weight) + ' lbs'
                      : <i>No Weight For Player</i>
                  }</Typography>}
                </Grid>

                <Grid 
                  className={classnames(classes.gridItem, classes.alignRight)}
                  item 
                  xs={LEFT_COLUMN_SIZE}
                >
                  <Typography className={classes.tableLabel}>Height</Typography>
                </Grid>
                <Grid className={classes.gridItem} item xs={RIGHT_COLUMN_SIZE}>
                  {measurementLoading && <CircularProgress size={25} />}
                  {!measurementLoading && <Typography>{
                    height != null 
                      ? heightToFeetAndInches(height)
                      : <i>No Height For Player</i>
                  }</Typography>}
                </Grid>

                <Grid 
                  className={classnames(classes.gridItem, classes.alignRight)}
                  item 
                  xs={LEFT_COLUMN_SIZE}
                >
                  <Typography className={classes.tableLabel}>Class Year</Typography>
                </Grid>
                <Grid className={classes.gridItem} item xs={RIGHT_COLUMN_SIZE}>
                  <Typography>{ highSchoolClassYear ?? <i>No Class Year Saved</i>}</Typography>
                </Grid>
              </Grid>

              { playerProfileUrl && <div className={classes.pgProfileButtons}>
                <Link 
                  href={playerProfileUrl} 
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Link To Player Profile
                </Link>

                { captureEvent.externalId != null 
                  ? <div className={classes.refreshProfileContainer}>
                    <Button
                      variant='contained' 
                      color='primary'
                      onClick={refreshProfile}
                      disabled={refreshingProfile}
                    >
                      Refresh Profile
                    </Button>
                    { refreshMessage && <Typography>{ refreshMessage }</Typography> }
                  </div>
                  : <div></div> }
              </div> }

              <div className={classes.updateUserFieldButton} >
                <Button
                  variant='contained' 
                  color='secondary'
                  onClick={() => setUpdateUserFieldsDialogOpen(true)}
                  disabled={user == null || measurementLoading || channelsLoading}
                >
                  Update User Fields
                </Button>

                <Button
                  className={classes.deliveryStatusButton}
                  endIcon={
                    <PurchaseStatusIcon 
                      purchaseStatus={eventUser.playerDevelopmentPlanStatus} />
                  }
                  color='primary'
                  onClick={() => setUpdateDeliveryStatusOpen(true)}
                  disabled={eventUser == null}
                >
                  Change Plan Delivery Status
                </Button>   
              </div>

              <UpdateUserFieldsDialog 
                open={updateUserFieldsDialogOpen}
                user={user}
                eventUser={eventUser}
                measurement={measurement}
                captureEventResults={captureEventResults}
                communicationChannels={communicationChannels}
                onCancel={() => setUpdateUserFieldsDialogOpen(false)}
                onFieldsUpdated={updateFields}
              />

              <DeliveryStatusDialog 
                open={updateDeliveryStatusOpen}
                eventUser={eventUser}
                purchaseStatus={eventUser.playerDevelopmentPlanStatus}
                onCancel={() => setUpdateDeliveryStatusOpen(false)}
                onComplete={deliveryStatusUpdated}
              />
            </Box>
          </Grid>
          <Grid item xs={1}><Divider orientation='vertical' /></Grid>
          <Grid item xs={5}>
            <PlayerParents user={user} captureEventId={captureEventId} />
          </Grid>
        </Grid>
        
        <Grid container>
          <Grid item xs={6}>
            {captureEventResults.map((result) => (
              <Grid container justify='center' key={`${result.id}-container`} className={classes.playerResults}>
                <div className={classes.clientTitle}>
                  <Typography variant='h6'>{fullName + getHandednessLabel(result)}</Typography>
                  <Button 
                    className={classes.showSliderDemoButton}
                    color='primary'
                    onClick={() => setSelectedResultForDemo(result)} 
                    variant='contained'
                  >
                    Slider Demo
                  </Button>
                </div>
                <EditAvailability 
                  user={user}
                  captureEvent={captureEvent} 
                  captureEventResult={result} 
                  onEditSuccess={()=> {
                    dispatch(fetchCaptureEvents());
                  }}/>
                <PercentilesTable 
                  captureResult={result} 
                  title={result.motionType + ' ' + result.handedness}
                />
                <BodyPercentiles
                  channelsNetworkRequest={channelsNetworkRequest}
                  captureResult={result} 
                  eventUser={eventUser}
                  onResultUpdate={resultUpdated}
                />
              </Grid>
            ))}
          </Grid>

          <Grid item xs={6} className={classes.pastPlansContainer}>
            <Divider orientation='vertical' />
            <PastTrainingPlans userId={eventUser.userId} className={classes.pastPlans} />
          </Grid>
        </Grid>
      </DialogContent>
    </>

    <SliderDemoDialog 
      open={selectedResultForDemo != null}
      user={user}
      captureEvent={captureEvent}
      result={selectedResultForDemo}
      onClose={() => setSelectedResultForDemo(null)}
    />
  </>;
}

export default TrainingPlanToolsDialog;