import React, { useState, useEffect } from 'react';
import useStyles from './styles';

import { Paper, TextField } from '@material-ui/core';
import { labelPalette } from '../../../../utils/kAssessments.utils';

function Label({ onClick, children, data }) {
  const classes = useStyles({ color: labelPalette[data.tag] });
  const labelClicked = () => {
    onClick(data);
  };

  return <span className={classes.label} onClick={labelClicked}>
    <span className={classes.dot}></span>
    <span className={classes.labelName}>{children}</span>
  </span>;
}

function ReferenceTextArea({ 
  header, 
  initialText = '', 
  labels,
  disable,
  editable = false,
  onTextUpdate = () => {},
  onLabelSelected = () => {}
}) {

  const classes = useStyles();

  const MAX_SECTION_SIZE = 1500;

  // refrence area state
  const [text, setText] = useState(initialText);

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  const textEditted = (e) => {
    let updatedText = e.target.value;
    if (updatedText.length > MAX_SECTION_SIZE) {
      updatedText = updatedText.substring(0, MAX_SECTION_SIZE);
    }
    onTextUpdate(header, updatedText);
    setText(updatedText);
  };

  const _getLabelMatches = () => {
    const labelsArr = Object.keys(labels).sort().reverse().join('|');
    if (!labelsArr.length) {
      return [];
    }
    let regExpText = `(#{2}(${labelsArr}))`;
    let reg = new RegExp(regExpText, 'g');
    let result = null;
    const matches = [];
    while((result = reg.exec(text)) !== null) {
      matches.push(result);
    }
    return matches;
  };

  const _getListOfLabels = (matches, includeDuplicates = false) => {
    // also we will add the labels to a set
    const labelsPresent = {};
    const labelsPresentInOrder = [];
    matches.forEach((m) => {
      const labelText = m[2];
      if (!labelsPresent[labelText]) {
        labelsPresentInOrder.push(labelText);
        labelsPresent[labelText] = !includeDuplicates;
      }
    });
    return labelsPresentInOrder;
  };

  const _getFormattedText = (matches) => {

    // Now we need to go through and create the appropriate elements
    // given that we found all of our matches

    // does the first segment of the substring
    let lastIdx = matches.length ? matches[0].index : text.length;
    const firstText = text.substring(0, lastIdx);
    const updatedText = [];
    if (firstText.length > 0) {
      updatedText.push(text.substring(0, lastIdx));
    }

    // loop through the matches and add spans on matches
    matches.forEach((m, idx) => {

      const labelText = m[2];
      updatedText.push((
        <span className={classes.labelContainer} key={idx}>
          <Label data={labels[labelText]} onClick={onLabelSelected}>{labelText}</Label>
        </span>
      ));

      // now we need to move the index forward and campture the text afterwards
      const nextIdx = idx + 1;
      const nextStart = nextIdx >= matches.length ? text.length : matches[nextIdx].index;
      const nextText = text.substring(m.index + m[0].length, nextStart);
      if (nextText.length > 0) {
        updatedText.push(nextText);
      }
    });

    return updatedText;
  };

  // perform calculations depending on whether we are editting or displaying
  const matches = _getLabelMatches();
  const labelsPresent = _getListOfLabels(matches, editable);

  return <div className={classes.container}>
    <div className={classes.headerContainer}>
      <span className={classes.header}>{header}</span>
      {
        editable &&
        <span className={classes.characters}>{text.length}/{MAX_SECTION_SIZE}</span>
      }
    </div>
    <div className={classes.textContainer}>
      <div className={classes.text}>
        {
          editable &&
                    <TextField
                      value={text}
                      disabled={disable}
                      onChange={textEditted}
                      fullWidth
                      multiline
                      rows={3}
                      rowsMax={12}
                      variant='outlined'
                    />
        }
        {!editable && _getFormattedText(matches)}
      </div>
      {
        Boolean(labelsPresent.length) &&
                <div className={classes.referencesContainer}>
                  <Paper elevation={3}>
                    <div className={classes.labels}>
                      {
                        labelsPresent.map((label, idx) => {
                          return <Label 
                            data={labels[label]} 
                            key={idx} 
                            onClick={onLabelSelected}>{label}</Label>;
                        })
                      }
                    </div>
                  </Paper>
                </div>
      }
    </div>
  </div>;
}
  
export default ReferenceTextArea;