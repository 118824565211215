import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { LoadAllMotionData } from '../../network/motionRequests';
import { GetUser } from '../../network/userRequests';
import CircularProgress from '@material-ui/core/CircularProgress';
import DictionaryOfMetricMetadata from '../../constants/motionDetails/combinedAsDictionary.constants';
import { getTableKeys } from './tableValuesOrder.constants';
import { useStyles, selectedCellBorder, hoveredCellBorder } from './styles';
import { PERMISSIONS } from '../../constants/permissions.constants';

function getDataValue(dataValue, formatter) {
  if(dataValue == null) {
    return 'N/A';
  }
  return formatter == null ? dataValue : formatter(dataValue);
}

function getBackground(colors) {
  if(colors == null || colors.length === 0) {
    return;
  }
  else if(colors.length === 1) {
    return `linear-gradient(90deg, 
      ${colors[0]} 0%, ${colors[0]} 100%)`;
  }
  else if(colors.length === 2) {
    return `linear-gradient(90deg, 
      ${colors[0]} 0%, ${colors[0]} 50%, 
      ${colors[1]} 51%, ${colors[1]} 100%)`;
  }
  else if(colors.length === 3) {                       
    return `linear-gradient(90deg, ${colors[0]} 0%, 
      ${colors[0]} 33%, ${colors[1]} 34%, 
      ${colors[1]} 67%, ${colors[2]} 68%, ${colors[2]} 100%)`; 
  }
  // TODO: maybe return basic preset colors?
  return `linear-gradient(90deg, ${colors[0]} 0%, 
    ${colors[0]} 33%, ${colors[1]} 34%, 
    ${colors[1]} 67%, ${colors[2]} 68%, ${colors[2]} 100%)`; 
}

export function DenseTable(props) {
  const { allData
    , motionType
    , onMetricClick = () => {}
    , onMetricHover = () => {}
    , metricsBackgroundColors = {}
    , selectedMetrics = []
    , enableHovering } = props;

  const classes = useStyles();
  const [hoveredMetric, setHoveredMetric] = useState(null);
  const permissions = useSelector(state => state.featurePermissions);
  const tableKeys = getTableKeys(
    permissions.includes(PERMISSIONS.canHandleLaunchMonitorData), 
    motionType);
  return <table className={classes.table}><tbody>
    {tableKeys.map((row, index) => {
      return (
        <tr key={`${index}-row`}>
          {row.map((metricKey) => {
            const metricMetaInfo = DictionaryOfMetricMetadata[metricKey];
            const dataValue = getDataValue(allData[metricKey], metricMetaInfo.formatter);
            const colors = metricsBackgroundColors[metricKey];
            const backgroundGradient = getBackground(colors);
            return (
              <td className={classes.td} key={`${metricKey}-table-entry`}>
                <table className={classes.table} 
                  onMouseDown={() => {
                    onMetricClick(metricKey);
                  }}
                  onMouseEnter={() => {
                    setHoveredMetric(metricKey);
                    onMetricHover(metricKey);
                  }}
                  onMouseLeave={() => {
                    setHoveredMetric(null);
                    onMetricHover(null);
                  }}
                >
                  <tbody style={{
                    border: metricKey === hoveredMetric && 
                      enableHovering ? hoveredCellBorder : 
                      selectedMetrics.includes(metricKey) ? selectedCellBorder : '',
                    background: backgroundGradient
                  }}>
                    <tr className={classes.tr} >
                      <th className={classes.th} style={{ background: backgroundGradient }}>
                        {metricMetaInfo.abbreviation}
                      </th>
                    </tr>
                    <tr className={classes.tr}>
                      <td className={classes.td}>{dataValue}</td>
                    </tr>          
                  </tbody>
                </table>
              </td>
            );
          })}
        </tr>
      );
    })}
  </tbody></table>;
}

function GetMotionData(motion, userId) {
  const [isErrored, setIsErrored] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [motionData, setMotionData] = useState({});
  useEffect( () => {
    const cancelToken = axios.CancelToken.source();

    (async () => {
      try {
        if(motion != null && userId != null) {
          const motionId = motion.id;
          const user = await GetUser(userId, cancelToken);
          const data = await LoadAllMotionData(motionId, user, cancelToken);
          setMotionData(data);
          setIsLoading(false);
        }
      } catch(e) {
        if (!axios.isCancel(e)) {
          setIsErrored(true);
          setIsLoading(false);
        }        
      }
    })();
    return cancelToken.cancel;
  }, [motion, userId]);
  return { isLoading, isErrored, motionData };
}

export default function KinematicDetailsDense(props) {
  const { motion, userId } = props;
  const { motionData, isLoading:isLoadingMotionData, isErrored } = GetMotionData(motion, userId);
  if(isErrored) {
    return <div>Error loading motion data</div>; 
  }
  if(isLoadingMotionData) {
    return <CircularProgress />;
  }
  
  const { 
    motion: { fullMotionMetrics, launchMonitorData, motionType }, 
    keyFrameDataMap 
  } = motionData;
  return <div>
    <DenseTable
      {...props} 
      motionType={motionType} 
      allData={{ ...fullMotionMetrics, ...keyFrameDataMap, ...launchMonitorData }} 
    />
  </div>;
};