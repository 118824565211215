export default class MathUtils {
  // adapted from https://stackoverflow.com/a/31566791
  static linearRegression = (x, y) => {
    const n = y.length;
    let sum_x = 0;
    let sum_y = 0;
    let sum_xy = 0;
    let sum_xx = 0;
    let sum_yy = 0;

    for (var i = 0; i < y.length; i++) {
      sum_x += x[i];
      sum_y += y[i];
      sum_xy += (x[i] * y[i]);
      sum_xx += (x[i] * x[i]);
      sum_yy += (y[i] * y[i]);
    } 

    const slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
    const intercept = (sum_y - slope * sum_x) / n;

    const r_denominator = Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y));
    const r = (n * sum_xy - sum_x * sum_y) / r_denominator;
    const r2 = Math.pow(r, 2);

    return { 
      slope, 
      intercept, 
      r, 
      r2
    };
  }
}
