import React, { useState } from 'react';
import useStyles from './styles';

import classNames from 'classnames';

import { 
  Button,
  Typography
} from '@material-ui/core';

import MessageDialog from '../../../../../components/dialogs/messageDialog';


function CoachHeader({ title, onCreateAssessment }) {
  const classes = useStyles();

  // hooks
  const [requestOpen, setRequestOpen] = useState(false);

  // callbacks and actions
  const createAssessment = () => {
    onCreateAssessment();
  };

  const toggleRequestDialog = (val) => {
    setRequestOpen(val);
  };

  return <div className={classes.container}>
    <div>
      {title}
    </div>
    <div>
      <Button className={classes.request} onClick={() => toggleRequestDialog(true)}>
        <Typography className={classNames(classes.buttonText, classes.button)}>Request</Typography>
      </Button>
      <Button className={classes.create} variant='outlined' onClick={createAssessment} color='primary'>
        <Typography className={classNames(classes.buttonText, classes.button)}>Create</Typography>
      </Button>
    </div>

    <MessageDialog 
      open={requestOpen}
      title='Request a K-Assessment'
      exitMessage='CLOSE'
      onClose={() => toggleRequestDialog(false)}
    >
            If you are a coach who would like to request an assessment for 
            any player or players, please send an email to assessments@k-vest.com
    </MessageDialog>
  </div>;
}

export default CoachHeader;