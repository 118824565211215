import React, { useState } from 'react';
import PropTypes from 'prop-types';
import log from 'js-logger';
import minBy from 'lodash/minBy';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { formatReadableDateLongTime } from '../../../../utils/formatting.utils';
import { PostEvaluation } from '../../../../network/evalReportRequests';
import ProgressButton from '../../../../components/progressButton';
import useStyles from './styles'; 

const HOLISTIC_BASEBALL = 'holisticBaseball';

function ConfirmationDialog({ motions, open, onClose, updatePushedMotions }) {
  const [pushing, setPushing] = useState(false);
  const [pushError, setPushError] = useState(false);
  const classes = useStyles();
  
  async function onSubmit() {
    if (motions.length === 0) return;
    setPushing(true);
    setPushError(false);
    
    const firstMotion = minBy(motions, m => m.timestamp);
    const { serialCode, clientId, machineKey, timestamp, userId } = firstMotion;
    try {
      const motionIds = motions.map(({ id }) => id);
      const data = {
        userId, 
        serialCode,
        machineKey,
        clientId, 
        timestamp,
        creationTimestamp: (new Date()).toISOString(),
        type: HOLISTIC_BASEBALL, 
        swings: motionIds.map(motionId => ({ motionId }))
      };
      await PostEvaluation(data);
      updatePushedMotions(motionIds);
      setPushing(false);
      onClose();
    } catch (e) {
      log.error(e, 'Unable to post evaluation for ' + userId);
      setPushError(true);
      setPushing(false);
    }
    
  }
  
  return open && <Dialog open maxWidth='md' fullWidth>
    <DialogContent>
      <Typography variant='h6' align='center'>
        Submitting these swings will make them available in KGo<br />
        and alert the user that they have new data available. 
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={1}>
        {motions.map(motion => <Grid item xs={12} md={6} key={motion.id}>
          <Typography>
            {formatReadableDateLongTime(motion.timestamp)} 
            {motion.launchMonitorData && 
              ` | Exit Velocity: ${motion.launchMonitorData.ballspeedMph}mph`}
          </Typography>
        </Grid>)}
      </Grid>
      <Typography color='error' style={{ visibility: pushError ? null : 'hidden' }}>
        Error pushing motions to KGo. Please try again.
      </Typography>
      <Divider className={classes.divider} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <ProgressButton 
        onClick={onSubmit} 
        color='primary' 
        showProgress={pushing}
      >
        Submit
      </ProgressButton>
    </DialogActions>
  </Dialog>;
}

ConfirmationDialog.propTypes = {
  motions: PropTypes.array.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  updatePushedMotions: PropTypes.func.isRequired // callback after new data is submitted to keep track of pushed motions
};

export default ConfirmationDialog;
