import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  holder: {
    position: 'fixed',
    display: 'flex',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px'
  }

}));

export default styles;