import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(_ => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: props => ({
    objectFit: 'contain',
    height: props.height,
    display: 'inline-block'
  }),
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minWidth: 0
  },
  columnsContainer: {
    flex: 1,
    display: 'flex',
    alignSelf: 'stretch'
  }
}));

export default styles;
