import Network from './network';

const API_KEYS = 'Api/ApiKeys';

export const GetAllKeys = async (cancelToken) => {
  const resp = await Network.Get(API_KEYS, null, cancelToken);
  return resp.data.items;
};

export const RevokeKey = async id => {
  const url = `${API_KEYS}/${id}`;
  const resp = await Network.Delete(url);
  return resp.data;
};

export const GenerateKey = async () => {
  const resp = await Network.Post(API_KEYS);
  return resp.data;
};