import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_theme => ({
  textField: {
    width: '150px'
  },
  title: {
    marginBottom: '10px'
  }
}));

export default useStyles;
