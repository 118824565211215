import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

function VideoPlayer({ src, title, aspectRatioHeightPercentage, options, allowFullScreen }) {
  const classes = useStyles();

  function constructQuery() {
    var query = Object.keys(options)
      .map(key => `${key}=${options[key]}`)
      .join('&');
    return query.length > 0 ? '?' + query : '';
  }

  return <div className={classes.videoContainer} 
    style={{ paddingBottom: `${aspectRatioHeightPercentage}%` }}
  >
    <div className={classes.videoIframe}>
      <iframe allowFullScreen={allowFullScreen} 
        title={title} 
        className={classes.videoIframe} 
        src={src + constructQuery()} 
      />
    </div>
  </div>;
};

VideoPlayer.defaultProps = {
  options: {},
  allowFullScreen: true,
  aspectRatioHeightPercentage: 56.25 // 16:9
};

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired, // base url of video
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // title used by iframe - must be unique
  options: PropTypes.object, // options to be appended as query params to url
  allowFullScreen: PropTypes.bool,
  aspectRatioHeightPercentage: PropTypes.number
};

export default VideoPlayer;
