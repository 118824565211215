import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { 
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow 
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { meanBy } from 'lodash';
import { calculatePercentileTableData } from '../../playerPercentiles/playerPercentiles.utils';
import { 
  MAX_SPEED_KEYS, 
  PEAK_SPEED_KEYS, 
  SEGMENTS_LIST 
} from '../../playerPercentiles/playerPercentiles.constants';
import useStyles from './styles';
import Logger from 'js-logger';

function SelectedPercentileSpeeds() {
  const classes = useStyles();
  const { motions: motionsState, weight } = useSelector(state => state.playerPercentiles);
  const { items: motions } = motionsState;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const beforeMotions = motions.filter(x => x.before);
      const afterMotions = motions.filter(x => x.after);
      const motionGroups = [
        { label: 'Before', items: beforeMotions }, 
        { label: 'After', items: afterMotions }
      ];

      const rows = [];
      try {
        setLoading(true);
        motionGroups.filter(x => x.items.length > 0).forEach(async ({ label, items }) => {
          rows.push({ 
            label, 
            type: 'max', 
            items: await calculatePercentileTableData(items, weight, MAX_SPEED_KEYS) 
          });
          rows.push({ 
            label, 
            type: 'peak', 
            items: await calculatePercentileTableData(items, weight, PEAK_SPEED_KEYS) 
          });
        });
      } catch (e) {
        Logger.log('Error loading calculatePercentileTableData in SelectedPercentileSpeeds ', e);
        setError('Error loading percentiles');
      } finally {
        setLoading(false);
      }
      const _tableData = rows.map(({ label, type, items }) => ({
        label,
        type,
        data: SEGMENTS_LIST.reduce((acc, segment) => ({
          ...acc,
          [segment]: meanBy(items, x => x.speeds[segment])
        }), {})
      }));
      setTableData(_tableData);
    };
    fetchData();
  }, [motions, weight]);

  const StyledCell = props => <TableCell { ...props } className={classes.cell} />;

  if (loading) return <CircularProgress />;
  
  return tableData.length > 0 && <Table size='small' >
    {error && <MuiAlert elevation={6} variant='filled' severity='error'>
      There was an error loading percentiles data.
    </MuiAlert>}
    <TableHead>
      <TableRow>
        <StyledCell>Avg Selected Speeds</StyledCell>
        {SEGMENTS_LIST.map(segment => <StyledCell key={segment}>
          {segment}
        </StyledCell>)}
      </TableRow>
    </TableHead>
    <TableBody>
      {tableData.map(({ label, type, data }) => <TableRow key={`${label}_${type}`}>
        <StyledCell>{label} {type} speeds</StyledCell>
        {SEGMENTS_LIST.map(segment => <StyledCell key={`${label}_${type}_${segment}`}>
          {data[segment]?.toFixed(1)}
        </StyledCell>)}
      </TableRow>)}
    </TableBody>
  </Table>;
}

export default SelectedPercentileSpeeds;
