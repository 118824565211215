import React, { useState, useCallback } from 'react';
import { orderBy, uniqBy } from 'lodash';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import { GetMotionsForUserInCurrentOrg } from 'network/motionRequests';
import { formatDate } from 'utils/formatting.utils';
import useNetworkRequest from 'network/useNetworkRequest';

function VideoDevDetailsButton({ userId, captureEventId, className }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const GetAllMotions = useCallback(async (cancelToken) => {
    if (dialogOpen) {
      const motions = (await GetMotionsForUserInCurrentOrg(
        userId, 
        { captureEventId, deleted: false },
        cancelToken
      )).items;

      const deletedMotions = (await GetMotionsForUserInCurrentOrg(
        userId,
        { captureEventId, deleted: true },
        cancelToken
      )).items;
      
      const combinedMotions = uniqBy([...motions, ...deletedMotions], 'id');
      return orderBy(combinedMotions, 'timestamp', 'desc');
    } else {
      return [];
    }
  }, [dialogOpen, captureEventId, userId]);

  const [motions, loading, error] = 
    useNetworkRequest([], 'GetAllMotionsForVideoDevDetails', GetAllMotions);

  const closeDialog = () => setDialogOpen(false);

  return <>
    <Button 
      className={className}
      startIcon={<BuildIcon />}
      variant='outlined' 
      color='secondary' 
      onClick={() => setDialogOpen(true)}
    >
      Open Video Dev Details
    </Button>
    <Dialog onClose={closeDialog} open={dialogOpen}>
      <DialogTitle>
        Video Dev Details
      </DialogTitle>
            
      <DialogContent>
        <div>
          {loading && <CircularProgress />}
          {!loading && error && <Typography color='error'>{error}</Typography>}
          {!loading && !error && <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Video Name</TableCell>
                  <TableCell align='center'>Serial Code</TableCell>
                  <TableCell align='center'>Timestamp</TableCell>
                  <TableCell align='center'>Motion Deleted</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {motions.map(({ id, serialCode, deleted, timestamp }) => (
                  <TableRow key={id}>
                    <TableCell component='th' scope='row'>
                      { id }
                    </TableCell>
                    <TableCell align='center'>{serialCode}</TableCell>
                    <TableCell align='center'>{formatDate(timestamp)}</TableCell>
                    <TableCell align='center'>{
                      deleted ? 'Yes' : 'No'
                    }</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
        </div>
      </DialogContent>

      <DialogActions>
        <Button 
          onClick={closeDialog} 
          color='primary'
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default VideoDevDetailsButton;