import Network from './network';
import { getOrganizationId } from '../utils/auth.utils';

const CLIENT_ACTIVATIONS_ENDPOINT = 'API/ClientActivations';

export const GetClientActivations = async (userId, params) => {
  let resp = await Network.Get(CLIENT_ACTIVATIONS_ENDPOINT + '/User/' + userId, params);
  return resp.data.items;
};

export const GetClientActivationsForOrg = async (params, cancelSource) => {
  var orgId = getOrganizationId();
  let resp = await Network.Get(CLIENT_ACTIVATIONS_ENDPOINT + '/Organization/' + orgId, params, cancelSource);
  return resp.data.items;
};

export const PatchActivatedClients = async (id, params) => {
  await Network.Patch(CLIENT_ACTIVATIONS_ENDPOINT + `/${id}`, params);
};

export const CreateClientActivation = async (client) => {
  await Network.Post(CLIENT_ACTIVATIONS_ENDPOINT, client);
};