import Colors from '../../../constants/colors.constants';

const styles = {
  root: {
    color: 'white'
  },
  title: {
    textAlign: 'center'
  },
  images: {
    display: 'flex',
    jusitfyItems: 'center',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center'
  },
  extraInfo: {
    textAlign: 'center',
    marginLeft: '15px',
    marginRight: '15px'
  },
  learnMore: {
    textAlign: 'center'
  },
  link: {
    color: Colors.primaryBlueGreen
  },
  'link:visited': {
    color: Colors.primaryBlueGreen
  },
  prizeImage: {
    width: '85%',
    marginTop: '10px'
  }
};

export default styles;
