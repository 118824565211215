export const RepUnits = {
  Seconds: 'Seconds'
};

export const RepUnitsList = [RepUnits.Seconds];

export const DrillType = {
  Mobility: 'Mobility',
  Strength: 'Strength',
  Hitting: 'Hitting',
  Conditioning: 'Conditioning'
};

export const DrillTypeList = [
  DrillType.Mobility, 
  DrillType.Strength, 
  DrillType.Hitting, 
  DrillType.Conditioning
];