const product1 = {
  key: 6,
  id: 6,
  publicName: 'Silver',
  extendedDescription: [
    '1 Swing Analysis',
    '1 Personalized Training Plan',
    '1 Swing Check-In',
    '52 weeks of Skills & Drills Video training (New videos each week)'
  ],
  isBulkProduct: false
};

const product2 = {
  key: 7,
  id: 7,
  publicName: 'Gold',
  extendedDescription: [
    '3 Swing Analyses',
    '3 Personalized Training Plans',
    '3 Swing Check-Ins',
    '52 weeks of Skills & Drills Video training (New videos each week)'
  ],
  isBulkProduct: false
};


const product3 = {
  key: 8,
  id: 8,
  publicName: 'Platinum',
  extendedDescription: [
    '6 Swing Analyses',
    '6 Personalized Training Plans',
    '12 Swing Check-Ins',
    '52 weeks of Skills & Drills Video training (New videos each week)'
  ],
  isBulkProduct: false
};

const product4 = {
  key: 9,
  id: 9,
  publicName: 'Platinum',
  extendedDescription: [
    '1 Swing Analysis every other month',
    '1 Personalized Training Plan',
    '1 Swing Check-In every month',
    '52 weeks of Skills & Drills Video training (New videos each week)',
    'Requires 12 months subscription to receive all assets'
  ],
  isBulkProduct: false
};

const personalSwingCoach = {
  key: 11,
  id: 11,
  publicName: 'Personal Swing Coach',
  extendedDescription: [
    '1 Swing Analysis every month',
    '1 Personalized Training Plan every month',
    '1 Swing Check-In every month',
    'Access to Baseball 101 Content',
    'Access to Training Leaderboard',
    'Cancel anytime (You\'ll keep all your programs)'
  ],
  isBulkProduct: false
};

const onetimeAnalysis = {
  key: 12,
  id: 12,
  publicName: 'Personal Swing Coach',
  extendedDescription: [
    '1 personalized assessment',
    '3 weekly customized video training plans',
    'Leaderboards, badges, and exclusive Baseball 101 content',
    '1 personalized video swing check-in'
  ],
  isBulkProduct: false
};

const onetimeAnalysisBulk3 = {
  key: 13,
  id: 13,
  publicName: 'Personal Swing Coach 3 Pack',
  extendedDescription: [
    '3 personalized assessment',
    '9  weekly customized video training plans',
    'Leaderboards, badges, and exclusive Baseball 101 content',
    '3 personalized video swing check-in'
  ],
  isBulkProduct: true
};

const onetimeAnalysisBulk6 = {
  key: 14,
  id: 14,
  publicName: 'Personal Swing Coach 6 Pack',
  extendedDescription: [
    '6 personalized assessment',
    '18 weekly customized video training plans',
    'Leaderboards, badges, and exclusive Baseball 101 content',
    '6 personalized video swing check-in'
  ],
  isBulkProduct: true
};

const singleAnalysisNoCheckin = {
  key: 1,
  id: 1,
  publicName: 'Swing Analysis',
  extendedDescription: [
    '1 personalized assessment',
    '3 weekly customized video training plans',
    'Leaderboards, badges, and exclusive Baseball 101 content'
  ],
  isBulkProduct: false
};

export const products = {
  [product1.id]: product1,
  [product2.id]: product2,
  [product3.id]: product3,
  [product4.id]: product4,
  [personalSwingCoach.id]: personalSwingCoach,
  [onetimeAnalysis.id]: onetimeAnalysis,
  [onetimeAnalysisBulk3.id]: onetimeAnalysisBulk3,
  [onetimeAnalysisBulk6.id]: onetimeAnalysisBulk6,
  [singleAnalysisNoCheckin.id]: singleAnalysisNoCheckin
};

export const productsList = [
  product1,
  product2,
  product3,
  product4,
  personalSwingCoach,
  onetimeAnalysis,
  onetimeAnalysisBulk3,
  onetimeAnalysisBulk6,
  singleAnalysisNoCheckin
];

export const PurchaseStatuses = {
  active: 'Active',
  canceled: 'Canceled',
  expired: 'Expired',
  cancellationRequested: 'CancellationRequested',
  updateRequested: 'UpdateRequested',
  awaitingUpdate: 'AwaitingUpdate',
  updateFulfilled: 'UpdateFulfilled',
  updateCanceled: 'UpdateCanceled',
  paymentError: 'PaymentError',
  consumed: 'Consumed',
  invalid: 'Invalid'
};

export const BillingPeriods = {
  'Yearly': 'Annual Subscription',
  'Monthly': 'Monthly Subscription'
};
