import { setActionPlan, closeActionPlan, setInTrainingPlanMode } from './actionPlan';
import { addNotes } from './notes';
import axios from 'axios';
import addDays from 'date-fns/addDays';
import pick from 'lodash/pick';
import TEXT_TYPES from '../constants/actionPlanTextTypes.constants';
import { NOTE_TYPES } from '../constants/noteStatuses.constants';
import { PLAN_TYPES } from '../constants/trainingPlans.constants';
import { PERMISSIONS } from '../constants/permissions.constants';
import { CreateTrainingPlan, GetTrainingPlan, UpdateTrainingPlan } from '../network/trainingPlanRequests';
import Logger from 'js-logger';

// Actions
export const SET_TRAINING_PLAN = 'trainingPlan/set';
export const RESET_TRAINING_PLAN = 'trainingPlan/reset';
export const UPDATE_SELECTED_IDX = 'trainingPlan/updateIndex';
export const UPDATE_NOTE = 'trainingPlan/updateNote';
export const SET_MOTION_ATTRIBUTE = 'trainingPlan/setAttribute';
export const SET_IS_SAVING = 'trainingPlan/setIsSaving';
export const SET_ERROR_MSG = 'trainingPlan/setErrorMsg';
export const SET_IS_EDITING_TEMPLATE = 'trainingPlan/setEditingTemplate';

const DAYS_BETWEEN_NOTES = 5;

// Reducer
const initialState = {
  // using idx to keep track as new notes won't have an id
  selectedNoteIdx: null,
  selectedMotionAttribute: {},
  isSaving: false,
  trainingPlanError: '',
  notes: [],
  isEditingTemplate: false
};

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case SET_TRAINING_PLAN:
      return {
        ...state,
        ...action.payload
      };
    case RESET_TRAINING_PLAN:
      return initialState;
    case UPDATE_SELECTED_IDX:
      return {
        ...state,
        selectedNoteIdx: action.payload
      };
    case SET_MOTION_ATTRIBUTE:
      return {
        ...state,
        selectedMotionAttribute: action.payload,
        motionAttributeId: action.payload.id
      };
    case SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.payload
      };
    case SET_ERROR_MSG:
      return {
        ...state,
        trainingPlanError: action.payload
      };
    case UPDATE_NOTE:
      return {
        ...state,
        notes: state.notes.map((note, idx) => {
          return idx === action.payload.noteIdx
            ? {
              ...note,
              ...action.payload.note
            }
            : note;
        })
      };
    case SET_IS_EDITING_TEMPLATE:
      return {
        ...state,
        isEditingTemplate: action.payload
      };
    default:
      return state;
  }
};

// Action Creators
export function setTrainingPlan(trainingPlan) {
  return { type: SET_TRAINING_PLAN, payload: trainingPlan };
};

export function resetTrainingPlan() {
  return { type: RESET_TRAINING_PLAN };
}

export function updateSelectedNoteIdx(idx) {
  return { type: UPDATE_SELECTED_IDX, payload: idx };
}

export function updateNoteInPlan(noteIdx, note) {
  return { type: UPDATE_NOTE, payload: { noteIdx, note }};
}

export function setMotionAttribute(attribute) {
  return { type: SET_MOTION_ATTRIBUTE, payload: attribute };
}

export function setIsSaving(isSaving) {
  return { type: SET_IS_SAVING, payload: isSaving };
}

export function setErrorMsg(errMsg) {
  return { type: SET_ERROR_MSG, payload: errMsg };
}

export function setIsEditingTemplate(isEditing) {
  return { type: SET_IS_EDITING_TEMPLATE, payload: isEditing };
}

// Thunks
export function loadTrainingPlan(trainingPlan) {
  return (dispatch) => {
    dispatch(setTrainingPlan(trainingPlan));
    dispatch(updateSelectedNoteIdx(0));
    dispatch(setActionPlan(trainingPlan.notes[0]));
  };
}

export function initializeEmptyTrainingPlan(user) {    
  return (dispatch, getState) => {
    const allowMarkdown = getState().featurePermissions
      .some(p => p === PERMISSIONS.markdownEnabled);
    const plan = {
      type: PLAN_TYPES.userTrainingPlan,
      notes: [
        createNote(user, null, allowMarkdown, 1),
        createNote(user, getPlanNoteDate(1), allowMarkdown, 2),
        createNote(user, getPlanNoteDate(2), allowMarkdown, 3)
      ],
      endTimestamp: getPlanNoteDate(3),
      userId: user.userId,
      id: undefined
    };
    dispatch(loadTrainingPlan(plan));
  };
};

export function initializeEmptyTemplate(motionAttribute) {
  return (dispatch, getState) => {
    const allowMarkdown = getState().featurePermissions
      .some(p => p === PERMISSIONS.markdownEnabled);
      
    const template = {
      type: PLAN_TYPES.template,
      motionAttributeId: motionAttribute,
      motionAttribute: undefined,
      userId: null,
      id: undefined,
      notes: [
        createNoteTemplate(allowMarkdown, 1),
        createNoteTemplate(allowMarkdown, 2),
        createNoteTemplate(allowMarkdown, 3)
      ]
    };
    dispatch(loadTrainingPlan(template));
  };
}

export function switchNote(newIdx) {
  return (dispatch, getState) => {
    const {
      trainingPlan,
      actionPlan
    } = getState();
    const { notes } = trainingPlan;

    if (!notes || newIdx > notes.length - 1 || !actionPlan) {
      return;
    }

    // we need to save the current note data before switching
    const updatedNote = transformActionPlanStateToNote(actionPlan);
    dispatch(updateNoteInPlan(trainingPlan.selectedNoteIdx, updatedNote));
    dispatch(updateSelectedNoteIdx(newIdx));
    dispatch(setActionPlan(notes[newIdx]));
  };
}

function _planIsValid(plan) {
  // looks throughout all notes in the plan to see if any user supplied tokens
  // were not updated. This markers are areas that a write must manually updated with data.
  const failedChecks = [];
  plan.notes.forEach((note, i) => {
    const variablesFound = note.text.match(/<<[^\s]+>>/g);
    if (variablesFound) {
      const variableStr = variablesFound.join(', ');
      failedChecks.push(` - (Week ${i + 1} note): ${variableStr}`);
    }
  });

  if (failedChecks.length > 0) {
    const msg = 'The following variables look like they need to be updated \n' + 
      failedChecks.join('\n') + '\n\n' +
      'Do you still want to send the plan?';
    return window.confirm(msg);
  } else {
    return true;
  }
}

export function saveTrainingPlan() {
  return (dispatch, getState) => {
    async function save() {
      dispatch(setIsSaving(true));

      let { actionPlan, trainingPlan } = getState();
      // need to make sure we save any current edits to the training plan
      const updatedNote = transformActionPlanStateToNote(actionPlan);
      dispatch(updateNoteInPlan(trainingPlan.selectedNoteIdx, updatedNote));
      try {
        // getting state again to make sure our last dispatch has gone through
        trainingPlan = getState().trainingPlan;
        if (trainingPlan.type !== PLAN_TYPES.template && !_planIsValid(trainingPlan)) {
          return;
        }

        let respPlan;
        if (trainingPlan.id) {
          respPlan = await UpdateTrainingPlan(trainingPlan.id, trainingPlan);
        } else {
          respPlan = await CreateTrainingPlan(getState().trainingPlan);
        }
        dispatch(closeActionPlan());
        dispatch(setInTrainingPlanMode(false));
        dispatch(setTrainingPlan(initialState));
        
        if (trainingPlan.type === PLAN_TYPES.userTrainingPlan) {
          dispatch(addNotes(respPlan.notes));
        }

      } catch(e) {
        Logger.error('Error when attempting to save a training plan', e);
        dispatch(setErrorMsg('Could not save the training plan, please try again.'));
      } finally {
        dispatch(setIsSaving(false));
      }
    } 
    save();
  };
}

export function fetchAndLoadTemplate(
  templateId,
  videoAnalysisId = null,
  user = null, 
  openingText = null, 
  additionalVideoIds = []
) {
  // If no user is applied, the template is loaded without modification 
  // with intention of updating the template.
  // If a user is given then the template is applied for the given user
  // with the intention of creating the plan to send. 
  return (dispatch) => {
    const cancelToken = axios.CancelToken.source();
    async function fetchAndLoadTemplate() {
      try {        
        const template = await GetTrainingPlan(templateId, cancelToken);
        
        if (!user) {
          dispatch(loadTrainingPlan(template));
        } else {
          // There are a lot of properties that we don't want to
          // include from the template so avoiding spread operators here
          // and only picking the data that is important
          const trainingPlan = {
            type: PLAN_TYPES.userTrainingPlan,
            userId: user.userId,
            motionAttributeId: template.motionAttributeId,
            endTimestamp: getPlanNoteDate(template.notes.length),
            id: undefined,
            motionAttribute: undefined,
            videoAnalysisId
          };
          
          trainingPlan.notes = template.notes.map((note, i) => ({
            userId: user.userId,
            organizationId: note.organizationId,
            text: replaceNoteSpecialTokens(note.text, user),
            formattedText: replaceNoteSpecialTokens(note.formattedText, user),
            textFormatType: note.textFormatType,
            dateToSend: i > 0 ? getPlanNoteDate(i) : null,
            noteType: NOTE_TYPES.trainingPlan,
            assignedDrills: note.assignedDrills.map(assignedDrill => 
              pick(assignedDrill, ['drillId', 'sets', 'reps', 'sequenceNumber'])),
            noteVideos: note.noteVideos.map(video => pick(video, ['videoId'])),
            noteImages: note.noteImages.map(image => pick(image, ['imageId'])),
            sequenceOrder: note.sequenceOrder
          }));

          if (openingText) {
            // insert opening text after initial greeting
            const note = trainingPlan.notes[0];
            const textPos = note.text.indexOf('\n') + 2;
            note.text = note.text.substring(0, textPos) + 
              openingText + note.text.substring(textPos);
            
            const formattedTextPos = note.formattedText.indexOf('\n') + 2;
            note.formattedText = note.formattedText.substring(0, formattedTextPos) + 
              openingText + note.formattedText.substring(formattedTextPos);
          }

          if (additionalVideoIds.length > 0) {
            const note = trainingPlan.notes[0];
            note.noteVideos = [
              ...note.noteVideos, 
              ...additionalVideoIds.map(videoId => ({ videoId }))
            ];
          }
  
          dispatch(loadTrainingPlan(trainingPlan));
        }
        
      } catch (e) {
        Logger.error('Error loading training plan template', e);
        dispatch(setErrorMsg('Could not load the template, please try again'));
      }
    }

    fetchAndLoadTemplate();
    return cancelToken.cancel;
  };
}

function replaceNoteSpecialTokens(text, user) {
  return text ? text.replace('<<PlayerName>>', user.firstName) : '';
}

function getPlanNoteDate(noteIndex) {
  // pass in the index of the note within the training plan (ie. first note is 0)
  return addDays(new Date(), noteIndex * DAYS_BETWEEN_NOTES);
}

function transformActionPlanStateToNote(state) {
  // the action plan state is not exactly the same as a note
  const { textData } = state;
  return {
    noteVideos: state.videoIds.map(videoId => ({ videoId })), 
    noteImages: state.images.map(({ id }) => ({ 'imageId': id }) ),
    text: textData.plainText,
    formattedText: textData.type === TEXT_TYPES.PLAIN_TEXT ? '' : textData.formattedText,
    textFormatType: textData.type,
    noteTemplateId: state.noteTemplateId,
    assignedDrills: state.assignedDrills
  };
}

function createNote(user, dateToSend, allowMarkdown, sequenceOrder) {
  const initialText = `${user.firstName.trim()},\n\n`;
  return {
    userId: user.userId,
    noteType: NOTE_TYPES.trainingPlan,
    dateToSend,
    noteVideos: [],
    noteImages: [],
    text: initialText,
    formattedText: initialText,
    textFormatType: allowMarkdown ? TEXT_TYPES.MARKDOWN : TEXT_TYPES.PLAIN_TEXT,
    assignedDrills: [],
    sequenceOrder
  };
}

function createNoteTemplate(allowMarkdown, sequenceOrder) {
  const initialText = '<<PlayerName>>,\n\n';
  return {
    noteType: NOTE_TYPES.template,
    noteVideos: [],
    noteImages: [],
    text: initialText,
    formattedText: initialText,
    textFormatType: allowMarkdown ? TEXT_TYPES.MARKDOWN : TEXT_TYPES.PLAIN_TEXT,
    assignedDrills: [],
    sequenceOrder
  };
}