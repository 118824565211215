import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Grid,
  IconButton,
  Select,
  MenuItem
} from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';
import Styles from './styles';


/* Intended to be used as a wrapper around components you want to display.
   The dropdown carousel has left and right arrows as well as a dropdown
   for the user to make a selection.
   
   Prop functions will fire for user selections but it is up to 
   callers to re-render children properly.
*/
class DropdownCarousel extends React.Component {

  render () {
    const { items, currentIdx, classes, dropdownGridSpace } = this.props;
    const noItems = items.length === 0;

    return (
      <div className={classes.mainContainer}>
        <Grid container justify='space-evenly'>
          <Grid item xs={2}>
            <IconButton 
              aria-label='previous item' 
              onClick={() => this.props.onChange(currentIdx - 1)}
              className={noItems || currentIdx === 0 ? classes.hidden : ''}
            >
              <PlayArrow className={classes.rotate180} color='disabled'/>
            </IconButton>
          </Grid>
          <Grid item xs={dropdownGridSpace} className={classes.dropdownContainer}>
            <Select 
              value={currentIdx} 
              disableUnderline 
              onChange={(e) => this.props.onChange(e.target.value)}
              className={items.length === 0 ? classes.hidden : ''}
            >
              {items.map((item, idx) => (
                <MenuItem key={idx} value={idx}>{item}</MenuItem>    
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <IconButton 
              aria-label='next item' 
              onClick={() => this.props.onChange(currentIdx + 1)}
              className={
                noItems || currentIdx === items.length - 1
                  ? classes.hidden 
                  : classes.rightArrow
              }
            >
              <PlayArrow color='disabled'/>
            </IconButton>
          </Grid>
        </Grid>
        {this.props.children}
      </div>
    );
  }
}

DropdownCarousel.props = {
  items: PropTypes.arrayOf(PropTypes.node), // populates dropdown
  currentIdx: PropTypes.number, // determines which item in dropdown is selected and what value is passed into onChange
  onChange: PropTypes.func, // will be called with the index of the item selected
  dropdownGridSpace: PropTypes.number // can be used to determine how much of the row the dropdown takes
};

DropdownCarousel.defaultProps = {
  dropdownGridSpace: 8
};

export default withStyles(Styles)(DropdownCarousel);
