import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Badge,
  Grid,
  Paper, 
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Typography
} from '@material-ui/core';
import keyBy from 'lodash/keyBy';
import { formatMMDDYY } from '../../../utils/formatting.utils';
import { GetOrganizationUsers } from '../../../network/organizationRequests';
import { ANALYSIS_STATUS } from '../../../constants/videoAnalysis.constants';
import { getIconForAnalysis, statusToLabel } from '../analysisUtils';
import useStyles from './styles';
import Logger from 'js-logger';

function AnnotationsTable({ analyses, navigateToAnalysis }) {
  const classes = useStyles();
  const [ orgUsersById, setOrgUsersById ] = useState({});

  useEffect(() => {
    const token = axios.CancelToken.source();
    async function fetchUsers() {
      try {
        const orgUsers = await GetOrganizationUsers(token);
        setOrgUsersById(keyBy(orgUsers, user => user.userId));
      }
      catch (e) {
        if (!axios.isCancel(e)) {
          // not going to bother with setting up error messages 
          // yet as this will just leave one column blank
          Logger.error('Error when attempting to get org users', e);
        }
      }
    }

    fetchUsers();
  }, []);

  const statusesToShow = [
    ANALYSIS_STATUS.readyForAnnotation,
    ANALYSIS_STATUS.submitted,
    ANALYSIS_STATUS.annotated
  ];
  let submittedAnalyses = analyses
    .filter(analysis => statusesToShow.includes(analysis.status));

  return (
    <Paper className={classes.container}>
      <div className={classes.titleContainer}>
        <Badge badgeContent={submittedAnalyses.length} color='secondary'>
          <Typography className={classes.title} variant='h5'>Video Analysis</Typography>
        </Badge>
      </div>
      <Table size='small' aria-label='annotations table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='left'>Status</TableCell>
            <TableCell align='left'>Date Submitted</TableCell>
            <TableCell align='left'>Date Due</TableCell>
            <TableCell align='left'>Annotator</TableCell>
            <TableCell align='left'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {submittedAnalyses.map((analysis) => {
            const annotator = orgUsersById[analysis.annotatorUserId];
            const annotatorName = annotator && `${annotator.firstName} ${annotator.lastName[0]}`; 

            return (
              <TableRow key={analysis.id}>
                <TableCell component='th' scope='row'>{analysis.playerName}</TableCell>
                <TableCell align='left' className={classes.statusCell}>
                  <Grid container>
                    <Grid item className={classes.iconSpacing}>{getIconForAnalysis(analysis)}</Grid>
                    <Grid item><Typography>{statusToLabel[analysis.status]}</Typography></Grid>
                  </Grid>
                </TableCell>
                <TableCell align='left'>{formatMMDDYY(analysis.editedTimestamp)}</TableCell>
                <TableCell align='left'>{formatMMDDYY(analysis.dueDate)}</TableCell>
                <TableCell component='th' scope='row'>{annotatorName}</TableCell>
                <TableCell align='left'>
                  {analysis.status !== ANALYSIS_STATUS.submitted && 
                    <Button color='primary' onClick={() => navigateToAnalysis(analysis.userId, analysis.id)}>
                      Annotate Videos
                    </Button>
                  }
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default AnnotationsTable;