import React from 'react';
import Logger from 'js-logger';
import { GetNotes, CreateNote } from '../../../network/notesRequests';
import { 
  CircularProgress, 
  Typography,
  TextField
} from '@material-ui/core';
import ProgressButton from '../../../components/progressButton';
import Plan from './plan';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import palette from '../../../styles/palette';
import { GetUser } from '../../../network/userRequests';
import { getUserId } from '../../../utils/auth.utils';
import ErrorSnackbar from '../../../components/errorSnackbar';

class DevelopmentPlans extends React.Component {
  state = {
    loggedInUser: {},
    notes: [],
    noteText: '',
    loading: true,
    submittingNote: false,
    errorMsg: null
  }

  async componentDidMount() {
    const { userId } = this.props.match.params;
    const loggedInUserId = getUserId();
    try {
      const [notes, loggedInUser] = await Promise.all([
        GetNotes(userId),
        GetUser(loggedInUserId)]);
      this.setState({ loading: false, notes, loggedInUser });
    } catch(e) {
      this.setState({ 
        loading: false, 
        errorMsg: 'Error fetching development plans, please try again.' 
      });
      Logger.error(`Error getting user ${userId} and/or author ${loggedInUserId}.`);
    } 
  }

  _submitNewDevPlan = async () => {
    this.setState({ submittingNote: true });
    const { userId } = this.props.match.params;
    const { notes, loggedInUser } = this.state;
    try {
      let note = await CreateNote({ userId, text: this.state.noteText });
      note.author = loggedInUser;
      this.setState({ submittingNote: false, notes: [note, ...notes], noteText: '' });
    } catch (e) {
      this.setState({ 
        submittingNote: false, 
        errorMsg: 'Error creating development plan, please try again.' 
      });
      Logger.error(`Error creating new dev plan for ${userId} ` +
        `by ${loggedInUser ? loggedInUser.userId : 'NULL'}`);
    }
  }

  render() {
    const { classes } = this.props;
    const { notes, noteText, loading, submittingNote, errorMsg } = this.state;
    return <div>
      <ErrorSnackbar message={errorMsg} onClose={() => this.setState({ errorMsg: null })} />
      <div>
        <TextField multiline
          variant='outlined'
          placeholder='Write a development plan here.'
          rows={12}
          value={noteText}
          onChange={e => this.setState({ noteText: e.target.value })}
          className={classes.textField}
        />
        <ProgressButton 
          onClick={this._submitNewDevPlan}
          showProgress={submittingNote}
          disabled={noteText.length === 0}
          progressColor={palette.white}
          className={classes.button}
        >
          <Typography className={classes.buttonText}>Create</Typography>
        </ProgressButton>
      </div>
      <Typography className={classes.previousPlans}>Previous Development Plans</Typography>
      {notes.map((note, index) => {
        return <Plan key={index + '-note'} {...note} />;
      })}
      {loading && <CircularProgress />}
      {(!loading && notes.length === 0) &&
        <Typography>There are no development plans for this player.</Typography>} 
    </div>;
  }
}

export default withStyles(styles)(DevelopmentPlans);
