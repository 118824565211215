import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  header: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    paddingBottom: '10px'
  },
  dateRangeContainer: {
    height: '56px'
  },
  tile: {
    width: '160px',
    height: '150px',
    padding: '5px 10px'
  },
  tilesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 'calc((160px + 20px) * 5)'
  },
  select: {
    margin: '0 10px',
    fontWeight: 'bold',
    fontSize: 'larger'
  }
}));

export default useStyles;
