export const PLAN_TYPES = {
  template: 'Template',
  userTrainingPlan: 'UserTrainingPlan',
  googleDriveTemplate: 'GoogleDriveTemplate',
  googleDriveTrainingPlan: 'GoogleDriveTrainingPlan'
};

export const SEGMENT_FOCUS = {
  primary: 'Primary',
  none: 'None'
};

export const TRAINING_PLAN_DOCUMENT_TYPE = {
  googleDoc: 'GoogleDoc',
  googleSheet: 'GoogleSheet'
};

export const PLAN_ID_PATTERN = ':trainingPlanId'; 
export const PLAN_SECTION_PATTERN = ':planSection?';
export const TRAINING_PLAN_ROUTE_PATH = `/training-plan/${PLAN_ID_PATTERN}/${PLAN_SECTION_PATTERN}`;

const getGDocUrlByType = (docType) => {
  return (trainingPlan) => { 
    return trainingPlan
      ?.externalDocuments
      ?.filter(({ documentType }) => documentType === docType)
      ?.[0]
      ?.embeddableUrl;
  };
};

export const FULL_PLAN_SECTION = {
  title: 'Plan',
  pageName: 'Full Training Plan',
  extractUrl: getGDocUrlByType(TRAINING_PLAN_DOCUMENT_TYPE.googleDoc),
  key: 'full-plan',
  documentType: TRAINING_PLAN_DOCUMENT_TYPE.googleDoc
};

export const PROGRAM_SHEET_SECTION = {
  title: 'Program',
  pageName: 'Program Sheet',
  extractUrl: getGDocUrlByType(TRAINING_PLAN_DOCUMENT_TYPE.googleSheet),
  key: 'program-sheet',
  documentType: TRAINING_PLAN_DOCUMENT_TYPE.googleSheet
};

export const LEADERBOARD_SECTION = {
  title: 'Leaderboard',
  pageName: 'Leaderboard',
  key: 'leaderboard',
  extractUrl: () => {},
  leaderboard: true
};

export const TRAINING_PLAN_SECTIONS = {
  [FULL_PLAN_SECTION.key]: FULL_PLAN_SECTION,
  [PROGRAM_SHEET_SECTION.key]: PROGRAM_SHEET_SECTION,
  [LEADERBOARD_SECTION.key]: LEADERBOARD_SECTION
};