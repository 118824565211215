import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  CardMedia,
  ListItemText
} from '@material-ui/core';
import VideoPlayer from '../../../components/videoPlayer';
import SearchBar from '../../../components/searchBar';
import useStyles from './styles';
import useInput from '../../../utils/useInput';

function DrillVideo({ title, video, onSelection, allDrillVideos, ...containerProps }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const searchInput = useInput();

  const toggleOpen = () => setOpen(prev => !prev);
  const selectVideo = video => {
    toggleOpen();
    onSelection(video);
  };

  const filteredVideos = allDrillVideos.filter(video => 
    video.title.toUpperCase().includes(searchInput.value.toUpperCase()));

  return (
    <div { ...containerProps}>
      <Grid container justify='space-between'>
        <Typography variant='h6'>{title}</Typography>
        <Button color='primary' onClick={toggleOpen}>
          Select Existing Video
        </Button>
      </Grid>
      <Typography>{video && video.title}</Typography>
      {video 
        ? <VideoPlayer 
          className={classnames(classes.videoContainer, classes.hasVideo)} 
          src={video.url} 
          title={video.id} 
        />
        : <div className={classnames(classes.videoContainer, classes.noVideo)}>
          <Typography>{title} has not been added yet.</Typography>
        </div>
      }
      <Dialog onClose={toggleOpen} open={open} PaperProps={{ className: classes.dialog }}>
        <DialogTitle>Drill Videos</DialogTitle>
        <SearchBar {...searchInput.bind} className={classes.search} autoFocus/>
        <List>
          {filteredVideos.map(video => (
            <ListItem key={video.id} button onClick={() => selectVideo(video)}>
              <ListItemAvatar>
                {video.thumbnailUrl 
                  ? <CardMedia image={video.thumbnailUrl} className={classes.videoImg}/>
                  : <div></div>
                }
              </ListItemAvatar>
              <ListItemText className={classes.videoTitle}>
                {video.title}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </div>
  );
}

export default DrillVideo;