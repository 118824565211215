import makeStyles from '@material-ui/core/styles/makeStyles';
import Palette from '../../../../../styles/palette';

const useStyles = makeStyles(() => ({
  buttonBase: {
    borderRadius: '8px',
    marginLeft: '8px',
    marginRight: '8px',
    border: '1px solid ' + Palette.green,
    backgroundColor: Palette.subtleGreen,
    '&:hover': {
      backgroundColor: Palette.blueGreen
    }
  }
}));

export default useStyles;
