import axios from 'axios';
import queryString from 'query-string';

import { history } from '../store';

function buildHeaders(rest) {
  return {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      ...rest
    }
  };
}

function trimUrl(url) {
  return url[0] === '/'
    ? url.substring(1)
    : url;
}

const Network = {
  Get: (route, params, cancelSource, headers, responseType) => {
    const baseUrl = process.env.REACT_APP_KCLOUD_BASE_URL;
    var url = baseUrl + trimUrl(route);

    if (params != null) {
      url += '?' + queryString.stringify(params);
    }

    var cancelToken = cancelSource == null ? null : cancelSource.token;
    
    return axios.get(url, {
      responseType: responseType,
      ...buildHeaders(headers),
      cancelToken: cancelToken
    }).then(res => {
      return res;
    }).catch(err => {
      if (axios.isCancel(err)) {
        // Rethrow err so that callers can handle canceled requests appropriately
        throw err;
      } else if (err.response) {
        if (err.response.status === 403) {
          history.push('/forbidden');
        } else if (err.response.status === 401) {
          history.push('/login');
        }
      }
      throw err;
    });
  },
  Put: (route, params, headers) => {
    const baseUrl = process.env.REACT_APP_KCLOUD_BASE_URL;
    var url = baseUrl + trimUrl(route);

    return axios.put(url, params, buildHeaders(headers))
      .then(res => {
        return res;
      }).catch(err => {
        if (err.response) {
          if (err.response.status === 403) {
            history.push('/forbidden');
          } else if (err.response.status === 401) {
            history.push('/login');
          }
        } 
        throw err;
      });
  },
  Patch: (route, params, headers) => {
    const baseUrl = process.env.REACT_APP_KCLOUD_BASE_URL;
    var url = baseUrl + trimUrl(route);

    return axios.patch(url, params, buildHeaders(headers))
      .then(res => {
        return res;
      }).catch(err => {
        if (err.response) {
          if (err.response.status === 403) {
            history.push('/forbidden');
          } else if (err.response.status === 401) {
            history.push('/login');
          }
        } 
        throw err;
      });
  },
  Post: (route, params, headers) => {
    const baseUrl = process.env.REACT_APP_KCLOUD_BASE_URL;
    var url = baseUrl + trimUrl(route);

    return axios.post(url, params, buildHeaders(headers))
      .then(res => {
        return res;
      }).catch(err => {
        if (err.response && err.response.status === 401) {
          history.push('/login');
        }
        throw err;
      });
  },
  Delete: (route, headers, cancelSource) => {
    var cancelToken = cancelSource == null ? null : cancelSource.token;
    const baseUrl = process.env.REACT_APP_KCLOUD_BASE_URL;
    var url = baseUrl + trimUrl(route);

    return axios.delete(url, { ...buildHeaders(headers), cancelToken })
      .then(res => {
        return res;
      }).catch(err => {
        if (err.response && err.response.status === 401) {
          history.push('/login');
        }
        throw err;
      });
  }
};

export default Network;
