import { makeStyles } from '@material-ui/core/styles';
import Palette from '../../../styles/palette';

export default makeStyles(_ => ({
  drillInput: {
    width: '60px'
  },
  assignedDrillContainer: {
    minWidth: '280px',
    width: 'unset',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: Palette.lightGray,
    padding: '10px 10px 10px 0px',
    marginLeft: '15px'
  },
  availableLabel: {
    marginTop: '25px',
    marginLeft: '15px',
    fontStyle: 'italic'
  },
  assignedLabel: {
    marginLeft: '15px'
  },
  assignedDrillItem: {
    marginBottom: '10px'
  },
  divider: {
    marginTop: '15px'
  },
  assignButton:{
    marginRight: '15px',
    height: '40px'
  }
}));