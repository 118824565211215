// Actions
export const SET_MOTIONS = 'motions/set';
export const SET_FORCE_PLATE = 'forcePlate/set';
export const SET_LAUNCH_MONITOR = 'launchMonitor/set';
export const SET_HITTING_DEVICE_SENSOR = 'hittingDevice/set';
export const SET_FULL_SWING_METRICS = 'fullSwingMetrics/set';
export const SET_KEY_FRAMES = 'keyFrames/set';
export const SET_ATTRIBUTES = 'motionAttributes/set';
export const ADD_MOTION = 'motions/add';

// Reducer
const initialState = [];

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case SET_MOTIONS:
      return action.motions;
    case ADD_MOTION:
      return [...state, action.motion];
    case SET_FORCE_PLATE:
      return state.map(motion => {
        return motion.id === action.forcePlate.motionId
          ? { ...motion, forcePlate: action.forcePlate }
          : motion;
      });
    case SET_LAUNCH_MONITOR:
      return state.map(motion => {
        return motion.id === action.launchMonitor.motionId
          ? { ...motion, launchMonitor: action.launchMonitor }
          : motion;
      });
    case SET_HITTING_DEVICE_SENSOR:
      return state.map(motion => {
        return motion.id === action.hittingDeviceSensor.motionId
          ? { ...motion, hittingDeviceSensor: action.hittingDeviceSensor }
          : motion;
      });
    case SET_FULL_SWING_METRICS:
      return state.map(motion => {
        return motion.id === action.fullSwingMetrics.motionId
          ? { ...motion, fullSwingMetrics: action.fullSwingMetrics }
          : motion;
      });
    case SET_KEY_FRAMES:
      return state.map(motion => {
        return motion.id === action.keyFrames[0].motionId
          ? { ...motion, keyFrames: action.keyFrames }
          : motion;
      });
    case SET_ATTRIBUTES:
      return state.map(motion => {
        return motion.id === action.payload.motionId
          ? { ...motion, attributes: action.payload.attributes }
          : motion;
      });
    default:
      return state;
  }
};

// Action Creators
export const setMotions = motions => {
  return { type: SET_MOTIONS, motions };
};

export const addMotion = motion => {
  return { type: ADD_MOTION, motion };
};

export const setLaunchMonitor = launchMonitor => {
  return { type: SET_LAUNCH_MONITOR, launchMonitor };
};

export const setForcePlate = forcePlate => {
  return { type: SET_FORCE_PLATE, forcePlate };
};

export const setHittingDeviceSensor = hittingDeviceSensor => {
  return { type: SET_HITTING_DEVICE_SENSOR, hittingDeviceSensor };
};

export const setFullSwingMetrics = fullSwingMetrics => {
  return { type: SET_FULL_SWING_METRICS, fullSwingMetrics };
};

export const setKeyFrames = keyFrames => {
  return { type: SET_KEY_FRAMES, keyFrames };
};

export const setAttributes = (motionId, attributes) => {
  return { 
    type: SET_ATTRIBUTES,
    payload: {
      motionId,
      attributes
    }
  };
};