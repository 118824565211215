import React from 'react';
import PropTypes from 'prop-types';
import { VictoryLine, VictoryLabel, Border } from 'victory';
import { orderBy } from 'lodash';
import palette from '../../styles/palette';

/* This component needs to be a child of a 
 * VictoryContainer so that correct props will be passed in. 
 */
const VERTICAL_LINE_COLOR = palette.mediumGray;
const VERTICAL_LINE_COLOR2 = '#5c72e3';
const VERTICAL_LABEL_COLOR = '#264297';

const VERTICAL_LINE_LABEL_WIDTH = 40;
const BORDER_Y_OFFSET = 57;
const LABEL_Y_OFFSET = 67;

class VerticalLines extends React.Component {
  _renderLabel = (idx, x, text) => {
    const borderElement = (
      <Border
        x={x - (VERTICAL_LINE_LABEL_WIDTH / 2)} 
        y={BORDER_Y_OFFSET}
        height={20} 
        width={VERTICAL_LINE_LABEL_WIDTH} 
        style={{ fill: idx % 2 === 0 ? VERTICAL_LABEL_COLOR : VERTICAL_LINE_COLOR2, rx:'4', ry: '4' }}
        key={`${idx}-border`}
      />
    );
    const labelElement = (
      <VictoryLabel 
        x={x} 
        y={LABEL_Y_OFFSET} 
        style={{ fill: palette.white }} 
        text={text} 
        verticalAnchor='middle' 
        textAnchor='middle' 
        key={`${idx}-text`} 
      />);

    // returns an array due to the way svg rendering handles z axis.
    // border will be rendered first and label will appear on top.
    return ([borderElement, labelElement]);
  }

  _updateLines = (lineData, scaleFunc) => {
    let sortedLines = orderBy(lineData, 'x');
    let svgXCoords = sortedLines.map(line => scaleFunc(line.x));

    const halfWidth = VERTICAL_LINE_LABEL_WIDTH / 2;
    let updatedIndexes = [];
    for(let i = 0; i < svgXCoords.length - 1; i++) {
      if (svgXCoords[i] + halfWidth > svgXCoords[i + 1] - halfWidth) {
        // we have overlap
        if (updatedIndexes.includes(i)) {
          // already adjusted this label, need to only modify the next
          svgXCoords[i + 1] = svgXCoords[i] + VERTICAL_LINE_LABEL_WIDTH;
          updatedIndexes.push(i + 1);
        } else {
          // change both labels to meet in the middle
          let halfWayPoint = (svgXCoords[i] + svgXCoords[i + 1]) / 2;
          svgXCoords[i] = halfWayPoint - halfWidth;
          svgXCoords[i + 1] = halfWayPoint + halfWidth;

          // it's possible that the reshuffling now conflicts with a previous label
          if (i > 0 && 
              !updatedIndexes.includes(i - 1) && 
              svgXCoords[i - 1] + halfWidth > svgXCoords[i] - halfWidth) 
          {
            svgXCoords[i - 1] = svgXCoords[i] - VERTICAL_LINE_LABEL_WIDTH;
            updatedIndexes.push(i - 1);
          }
          
          updatedIndexes.push(i, i + 1);
        }
      }
    }

    return sortedLines.map((line, idx) => ({ name: line.name, x: svgXCoords[idx] }));
  }

  render() {
    const { minYValue, maxYValue, data } = this.props;

    // render lines separately from labels as labels will have 
    // separate positioning calculations to prevent overlapping.
    const lines = data.map((line, idx) => (
      <VictoryLine
        {...this.props}
        key={`${line.name}-vertline-${idx}`}
        data= {[
          { x: line.x, y: minYValue }, 
          { x: line.x, y: maxYValue }
        ]}
        style={{ 
          data: { 
            stroke: idx % 2 === 0 ? VERTICAL_LINE_COLOR2 : VERTICAL_LINE_COLOR 
          }
        }}
      />
    ));

    let updatedLines = this._updateLines(data, this.props.scale.x);
    
    let labels = [];
    updatedLines.forEach((line, idx) => labels.push(...this._renderLabel(idx, line.x, line.name)));

    return [...lines, ...labels];
  }
}

VerticalLines.props = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string, // label that appears above line, should be a few characters max
    x: PropTypes.number // the x value where the vertical line should be drawn 
  })),
  minYValue: PropTypes.number,
  maxYValue: PropTypes.number
};

export default VerticalLines;