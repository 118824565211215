import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  devPlan: {
    flexGrow: 1
  },
  tempVideoContainer: {
    height: '100%'
  },
  mobilityContainer: {
    marginTop: '48px'
  }
}));

export default useStyles;
