const styles = {
  hidden: {
    visibility: 'hidden'
  },
  dateText: {
    whiteSpace: 'nowrap'
  },
  listItem: {
    overflow: 'hidden'
  }
};

export default styles;