import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  createCaptureEventContainer: {
    width: '50%',
    paddingLeft: 10
  },
  buttonRow: {
    display: 'flex', 
    alignItems: 'flex-start', 
    paddingLeft: 20, 
    paddingTop: 10
  },
  eventActions: {
    marginLeft: 10
  }
}));

export default useStyles;
