import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import SelectionList from '../../../../components/selectionList';

const MAX_SELECTION = 9;
const MAX_SELECTION_MSG = `You can only select up to ${MAX_SELECTION} additional players to view.`;

function SelectPlayers({ label, players, selectedPlayers, onChange }) {
  const [ maxSelectionExceeded, setMaxSelectionExceeded ] = useState(false);
  const [ selectingPlayers, setSelectingPlayers ] = useState(false);
  const [ newlySelectedPlayers, setNewlySelectedPlayers ] = useState(selectedPlayers);

  function onSubmit() {
    onChange(newlySelectedPlayers);
    setMaxSelectionExceeded(false);
    setSelectingPlayers(false);
  }

  function onCancel() {
    setMaxSelectionExceeded(false);
    setSelectingPlayers(false);
    setNewlySelectedPlayers(selectedPlayers);
  }

  function onSelectionListChange(newItems) {
    if (newItems.length > MAX_SELECTION) {
      setMaxSelectionExceeded(true);
    } else {
      setNewlySelectedPlayers(newItems);
      setMaxSelectionExceeded(false);
    }
  }

  return <div>
    <Button onClick={() => setSelectingPlayers(true)} variant='outlined' color='primary'>
      {label}
    </Button>
    <Dialog open={selectingPlayers} fullWidth maxWidth='lg'>
      <DialogTitle>
        {label}
        {maxSelectionExceeded && <Typography color='error' variant='h3'>
          {MAX_SELECTION_MSG}
        </Typography>}
      </DialogTitle> 
      <DialogContent>
        <SelectionList hideSelectAll
          items={players}
          selectedItems={newlySelectedPlayers}
          onChange={onSelectionListChange}
          columnWidths={{ xs: 4 }}
          getLabel={x => `${x.firstName} ${x.lastName}`}
          getValue={x => x.userId}
        />
      </DialogContent>
      <DialogActions> 
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onSubmit} color='primary'>Submit</Button>
      </DialogActions>
    </Dialog>
  </div>;
};

SelectPlayers.defaultProps = {
  label: 'Select Players'
};

SelectPlayers.propTypes = {
  label: PropTypes.string,
  selectedPlayers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SelectPlayers;
