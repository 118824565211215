import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import BodyParts from 'constants/bodyParts.constants';
import BODY_PERCENTILES_AVATAR from 'assets/body_percentiles_avatar_2.png';
import BodyPartValueBox from './bodyPartValueBox';

/**
 * This displays the outline of a baseball player with
 * text boxes connected to each body part.
 * 
 * You can provide any values needed for each body part as a string.
 * Any body parts passed into highlightedBodyParts will be highlighted in red.
 */
function PlayerBodySegmentGraphic({
  pelvisValue,
  torsoValue,
  upperArmValue,
  handValue,
  highlightedBodyParts = []
}) {
  const classes = useStyles();

  const SEGMENT_LINES = [
    {
      label: 'Hand',
      bodyPart: BodyParts.hand,
      value: handValue,
      end: 9
    },
    { 
      label: 'Arm',
      bodyPart: BodyParts.upperArm,
      value: upperArmValue,
      end: 31
    },
    {
      label: 'Torso',
      bodyPart: BodyParts.torso,
      value: torsoValue,
      end: 53
    },
    {
      label: 'Pelvis',
      bodyPart: BodyParts.pelvis,
      value: pelvisValue,
      end: 75
    }
  ];

  return (
    <div className={classes.outer}>
      <div className={classes.inner}>
        <div className={classes.imageContainer}>
          <img src={BODY_PERCENTILES_AVATAR} alt='Percentiles' className={classes.image} />
        </div>
        
        <div className={classes.labelsContainer}>
          {SEGMENT_LINES.filter(segment => segment.value != null).map(line => 
            <BodyPartValueBox
              key={`percentile-label-${line.bodyPart}`}
              label={line.label}
              value={`${line?.value}%`}
              top={line.end}
              inFocus={highlightedBodyParts.includes(line.bodyPart)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

PlayerBodySegmentGraphic.propTypes = {
  pelvisValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  torsoValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  upperArmValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highlightedBodyParts: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(BodyParts)))
};

export default PlayerBodySegmentGraphic;