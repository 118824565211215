import palette from '../../../styles/palette';

const styles = {
  dialogContent: {
    height: '70vh'
  },
  videoSelectionContainer: {
    height: '50%', 
    display: 'flex'
  },
  videoListItems: {
    display: 'flex',
    flexDirection: 'column'
  },
  videoPreviewContainer: {
    height: '50%', 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center'
  },
  videoList: {
    borderStyle: 'inset', 
    height: '100%', 
    width: '60%', 
    overflowY: 'scroll'
  },
  selectedVideosContainer: {
    height: '100%', 
    width: '40%', 
    overflowY: 'auto', 
    paddingLeft: '10px'
  },
  selectedVideo: {
    display: 'flex', 
    alignItems: 'center', 
    paddingLeft: '15px', 
    paddingTop: '3px'
  },
  closeIcon: {
    fontSize: '16px', 
    marginRight: '5px',
    padding: '3px',
    cursor: 'pointer',
    color: palette.darkGray,
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: palette.lightGray
    }
  },
  previewVideoLabel: {
    paddingTop: '15px'
  },
  previewIFrame: {
    flexGrow: 1, 
    width: '100%', 
    border: 'none'
  },
  videoTypeTitle: {
    margin: '20px'
  }
};

export default styles;
