import makeStyles from '@material-ui/core/styles/makeStyles';
import palette from '../../../../../styles/palette';

const useStyles = makeStyles(() => ({
  clickableRow: {
    cursor: 'pointer'
  },
  synced: {
    color: palette.success
  },
  notSynced: {
    color: palette.error
  }
}));

export default useStyles;