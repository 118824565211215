import BodyParts from '../../constants/bodyParts.constants';

const ROTATIONAL_VELOCITY_FRAME_MAP = {
  [BodyParts.pelvis]: { bodySegment: BodyParts.pelvis, propertyName: 'pelvisRotationalVelocity' },
  [BodyParts.torso]: { bodySegment: BodyParts.torso, propertyName: 'torsoRotationalVelocity' },
  [BodyParts.lowerArm]: { bodySegment: BodyParts.lowerArm, propertyName: 'lowerArmRotationalVelocity' },
  [BodyParts.upperArm]: { bodySegment: BodyParts.upperArm, propertyName: 'upperArmRotationalVelocity' },
  [BodyParts.hand]: { bodySegment: BodyParts.hand, propertyName: 'handRotationalVelocity' }
};

const PITCHING_FRAME_MAPS = [
  { bodySegment: BodyParts.pelvis, propertyName: 'pelvisRotationalVelocity' },
  { bodySegment: BodyParts.torso, propertyName: 'torsoRotationalVelocity' },
  { bodySegment: BodyParts.lowerArm, propertyName: 'lowerArmFlexionRate' },
  { bodySegment: BodyParts.upperArm, propertyName: 'upperArmRotationRate' }
];

const SEGMENT_TO_LINE_OPTIONS = {
  [BodyParts.pelvis]: { color: '#FF5656' },
  [BodyParts.torso]:  { color: '#01DB3E' },
  [BodyParts.lowerArm]: { color: '#2E9BFF' },
  [BodyParts.upperArm]: { color: '#2E9BFF' },
  [BodyParts.hand]: { color: '#CBA977' }
};

export { ROTATIONAL_VELOCITY_FRAME_MAP, PITCHING_FRAME_MAPS, SEGMENT_TO_LINE_OPTIONS };