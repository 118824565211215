import { red } from '@material-ui/core/colors';
const errorRed = red[700];

const styles = {
  content: {
    backgroundColor: errorRed,
    borderRadius: 8
  },
  dialogContent: { borderBottom: `1px solid ${errorRed}` },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  message: {
    color: 'white',
    marginRight: '10px'
  }
};

export default styles;
