import React, { useEffect, useState } from 'react';
import axios from 'axios';
import sortBy from 'lodash/sortBy';
import { 
  Paper, 
  Typography, 
  Fab, 
  Grid, 
  Divider,
  Snackbar,
  SnackbarContent, 
  Icon,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import {
  GetAllDrills,
  UpdateDrill,
  CreateDrill,
  GetEquipment
} from '../../network/drillRequests';
import DrillEditor from './drillEditor';
import DrillSelection from './drillSelection';
import useStyles from './styles';
import { GetDrillVideos } from '../../network/videoRequests';
import { GetMotionAttributes } from '../../network/motionAttributeRequests';
import { MotionAttributes } from '../../constants/motionTypes.constants';

function Drills() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [allDrills, setAllDrills] = useState([]);
  const [allDrillVideos, setAllDrillVideos] = useState([]);
  const [allEquipment, setAllEquipment] = useState([]);
  const [allAttributes, setAllAttributes] = useState([]);
  const [selectedDrill, setSelectedDrill] = useState(null);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchDrills() {
      try {
        const [drills, drillVideos, equipment, attributes] = await Promise.all([
          GetAllDrills(cancelToken),
          GetDrillVideos(null, cancelToken),
          GetEquipment(cancelToken),
          GetMotionAttributes({ type: MotionAttributes.onBaseU }, cancelToken)
        ]);
        setAllDrills(drills);
        setAllDrillVideos(sortBy(drillVideos, video => video.title));
        setAllEquipment(equipment);
        setAllAttributes(attributes);
      } catch (e) {
        if (!axios.isCancel(e)) {
          setErrMsg('Error when getting drills. Please contact dev.');
        }
      }
      setIsLoading(false);
    }
    fetchDrills();
    return cancelToken.cancel;
  }, []);
  
  useEffect(() => {
    if (allDrills.length > 0 && selectedDrill == null) {
      setSelectedDrill(allDrills[0]);
    }
  }, [allDrills, selectedDrill]);

  const newDrill = () => {
    const drill = { };
    setSelectedDrill(drill);
  };

  const saveDrill = async drill => {
    try {
      if (drill.id) {
        const updatedDrill = await UpdateDrill(drill);
        setAllDrills(prev => prev.map(d => d.id === drill.id ? updatedDrill : d));
        setSelectedDrill(updatedDrill);
      } else {
        const newDrill = await CreateDrill(drill);
        setAllDrills(prev => [...prev, newDrill]);
        setSelectedDrill(newDrill);
      }
      setShowSnackbar(true);
    } catch(e) {
      setErrMsg('There was an error when creating or updating the drill. Please try again.');
    }
  };

  return (
    <div className={classes.container}>
      <Typography variant='h3'>Drills</Typography>
      <Paper className={classes.paper}>
        <Typography color='error'>{errMsg}</Typography>
        <Grid container direction='row'>
          <Grid item xs={2} container>
            <Grid container direction='column' className={classes.drillList}>
              <DrillSelection 
                drills={allDrills} 
                selectedDrill={selectedDrill} 
                onSelect={(drill) => setSelectedDrill(drill)}
                isLoading={isLoading}
              />
              <Fab color='secondary' size='small' className={classes.addButton} onClick={newDrill}>
                <AddIcon />
              </Fab>
            </Grid>
            <Divider orientation='vertical'/>
          </Grid>
          <Grid item xs={9}>
            <DrillEditor
              key={selectedDrill && selectedDrill.id}
              initialDrill={selectedDrill || {}} 
              onSave={saveDrill}
              isLoading={isLoading}
              allDrillVideos={allDrillVideos}
              allEquipment={allEquipment}
              allAttributes={allAttributes}
            />
          </Grid>
        </Grid>
      </Paper>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        onClose={() => setShowSnackbar(false)}
      >
        <SnackbarContent
          message={
            <span>
              <Icon />
              Drill Updated Successfully
            </span>
          }
          action={[
            <IconButton key='close' onClick={() => setShowSnackbar(false)}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
          className={classes.snackbar}
        />
      </Snackbar>
    </div>
  );
}

export default Drills;