import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function SelectionList({ 
  items, 
  selectedItems, 
  onChange, 
  hideSelectAll, 
  columnWidths, 
  getLabel,
  getValue 
}) {
  const selectedItemValues = selectedItems.map(getValue);

  function onSelectionChanged(item, isChecked) {
    if (isChecked) {
      onChange([...selectedItems, item], item);
    } else {
      const itemValue = getValue(item);
      onChange(selectedItems.filter(x => getValue(x) !== itemValue), item);
    }
  }

  function onSelectAllClicked(checked) {
    if (checked) {
      onChange(items);
    } else {
      onChange([]);
    }
  }

  return <div>
    {!hideSelectAll && <FormControlLabel 
      control={<Checkbox color='primary'
        checked={items.length === selectedItems.length}
        onClick={({ target }) => onSelectAllClicked(target.checked)}
      />}
      label='Select All'
    />}
    <Grid container>
      {items.map(item => {
        const value = getValue(item);

        return <Grid item key={value} {...columnWidths}>
          <FormControlLabel 
            control={<Checkbox color='primary'
              checked={selectedItemValues.includes(value)}
              onChange={({ target }) => onSelectionChanged(item, target.checked)}
            />}
            label={getLabel(item)}
          />
        </Grid>;
      })}
    </Grid>
  </div>;
}

SelectionList.defaultProps = {
  getLabel: x => x,
  getValue: x => x,
  columnWidths: { xs: 12 }
};

const MUI_GRID_OPTIONS = PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
const COLUMN_WIDTHS_SHAPE = PropTypes.shape({ 
  xs: MUI_GRID_OPTIONS, 
  sm: MUI_GRID_OPTIONS, 
  md: MUI_GRID_OPTIONS, 
  lg: MUI_GRID_OPTIONS, 
  xl: MUI_GRID_OPTIONS 
});

SelectionList.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired, //gets called with new list of selected items and item that triggered change
  hideSelectAll: PropTypes.bool,
  columnWidths: COLUMN_WIDTHS_SHAPE, //MUI grid widths of columns
  /* Callbacks used to get display label and data key (used to control if item is checked). If none
   * passed in, entire object is used for both, allowing support for lists of ints and strings.
   */
  getLabel: PropTypes.func, 
  getValue: PropTypes.func
};

export default SelectionList;
