import { useCallback } from 'react';
import { GetMotionsForUserInCurrentOrg } from '../../../../network/motionRequests';
import useNetworkRequest from '../../../../network/useNetworkRequest';
import { 
  LAUNCH_MONITOR,
  FULL_MOTION_METRICS,
  KEY_FRAME_DATA,
  NESTED_ATTRIBUTES
} from '../../../../constants/motionSubresources.constants';

function useEventMotionRequest(captureEventId, userId) {
  const motionsRequest = useCallback(async (cancelToken) => {
    const subresources = [LAUNCH_MONITOR, FULL_MOTION_METRICS, KEY_FRAME_DATA, NESTED_ATTRIBUTES];
    const params = { captureEventId, subresources };
    const motionResp = await GetMotionsForUserInCurrentOrg(userId, params, cancelToken);
    return motionResp.items;
  }, [captureEventId, userId]);
  return useNetworkRequest([], 'motionsGet', motionsRequest);
}

export default useEventMotionRequest;