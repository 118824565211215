import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../../styles/palette';
import { calculateFontSize } from './../../styles';

export const useClasses = makeStyles(() => ({
  dialog: {
    backgroundColor: palette.kmobileBackgroundColor,
    color: palette.white
  },
  title: {
    backgroundColor: palette.kmobileBackgroundColor,
    color: palette.white,
    borderBottom: `1px solid ${palette.white}`,
    paddingBottom: '5px',
    paddingTop: '5px'
  },
  titleText: {
    fontSize: calculateFontSize(3)
  },
  bold: {
    fontWeight: 'bold'
  },
  content: {
    backgroundColor: palette.kmobileBackgroundColor,
    color: palette.white
  },
  closeButton: {
    width: '30px',
    height: '30px',
    color: palette.white
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  titleWithExit: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '10px'
  },
  options: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  radioContainer: {
    marginBottom: '3px'
  },
  titleRoot: {
    color: `${palette.white} !important`,
    fontSize: calculateFontSize()
  }
}));

export default useClasses;