import { SPORTS } from 'constants/modes.constants';
import { GetClassYearSlopes } from 'utils/playerPercentiles/playerPercentiles.utils';
import { orderBy } from 'lodash';
import { 
  InputLabel, 
  Select,
  MenuItem,
  FormControl
} from '@material-ui/core';

export const UNSELECTED_CLASS_YEAR = '----';

function ClassYearSelector({
  classYear,
  onChange,
  sport = SPORTS.BASEBALL,
  className = null,
  inputClassName = null,
  removeUnselectedOption = false
}) {

  let classYears = orderBy(Object.keys(GetClassYearSlopes(sport)), x => x, ['desc']);

  if (!removeUnselectedOption) {
    classYears = [UNSELECTED_CLASS_YEAR, ...classYears];
  }

  return <FormControl className={className} variant='outlined'>
    <InputLabel id='choose-class-year-label'>Class Year</InputLabel>
    <Select
      className={inputClassName}
      labelId='choose-class-year-label'
      value={classYear}
      label='Class Year'
      onChange={({ target }) => onChange(target.value)}
    >
      {
        classYears.map(year => {
          return <MenuItem key={year} value={year}>{year}</MenuItem>;
        })
      }
    </Select>
  </FormControl>;
};

export default ClassYearSelector;