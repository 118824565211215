import { makeStyles } from '@material-ui/core/styles';
import Palette from '../../../../../styles/palette';

export default makeStyles(theme => ({
  thumbsContainer: {
    marginTop: '25px'
  },
  videoContainer: {
    width: '590px',
    height: '350px',
    marginTop: '15px'
  },
  contentContainer: {
    display: 'flex',
    alignSelf: 'center',
    maxWidth: '630px',
    flexDirection: 'column',
    paddingBottom: '25px'
  },
  thumbButton: {
    width: '60px'
  },
  successColor: {
    backgroundColor: Palette.success
  },
  failureColor: {
    backgroundColor: Palette.error
  },
  thumbIcon: {
    fontSize: '40px',
    color: Palette.subtleWhite
  },
  finishedMsg: {
    textAlign: 'center',
    marginTop: '15px'
  },
  devDetails: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  topMargin: {
    marginTop: '50px'
  },
  videoFileInput: {
    padding: '5px 10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: Palette.white,
    color: theme.palette.primary.main,
    '&:hover': {
      opacity: 0.7
    }
  },
  divider: {
    backgroundColor: Palette.black
  },
  videosDisplayContainer: {
    margin: '20px'
  }
}));