import React, { useState, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography
} from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import useNetworkRequest from '../../../../network/useNetworkRequest';
import { GetVideos } from '../../../../network/videoRequests';
import { HOSTING_PROVIDER, VIDEO_TYPE } from '../../../../constants/video.constants';
import { formatDateWithSeconds } from '../../../../utils/formatting.utils';
import useVideoDownloadUrl from '../../../../utils/useVideoDownloadUrl';

function BlobStorageVideoView({ userId }) {
  const [open, setOpen] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);

  const videosRequest = useCallback(async (cancelSource) => {
    const requestedVideos = userId == null 
      ? []
      : await GetVideos({ 
        userId, 
        type: VIDEO_TYPE.motion, 
        hostingProvider: HOSTING_PROVIDER.azureBlobStorage 
      }, cancelSource);
    return orderBy(requestedVideos, 'creationTimestamp', 'desc');
  }, [userId]);
  const [videos, videosLoading] = useNetworkRequest([], 'getBlobStorageVideos', videosRequest);

  const { downloadUrl, loading: downloadLoading, error: downloadError } = 
    useVideoDownloadUrl(selectedVideoId);

  return <Box>
    <Button onClick={() => setOpen(true)} variant='outlined' color='primary'>
      View Blob Storage Videos
    </Button>

    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='lg'>
      <Box display='flex' height='75vh'>
        <Box overflow='auto' height='100%' width='20%'>
          <List>
            {videosLoading && <ListItem>
              <CircularProgress />
            </ListItem>}
            {videos.map(video => <ListItem 
              key={video.key} 
              button
              onClick={() => setSelectedVideoId(video.id)}
              selected={video.id === selectedVideoId}
              divider
            >
              <ListItemText primary={formatDateWithSeconds(video.creationTimestamp)} />
            </ListItem>)}
          </List>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' width='80%'>
          {downloadLoading && <CircularProgress />}
          {downloadError && <Typography color='error'>
            Error loading video, please try again.
          </Typography>}
          {downloadUrl && <video src={downloadUrl} controls height='100%' width='100%' />}
        </Box>
      </Box>
    </Dialog>
  </Box>;
}

export default BlobStorageVideoView;
