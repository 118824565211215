import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash/throttle';

// Adapted from https://medium.com/@jrcreencia/persisting-redux-state-to-local-storage-f81eb0b90e7e
const loadState = key => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}; 

const saveState = (state, key) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};

export const useLocalStoragePersistedReduxState = (key, setActionCreator) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state[key]);

  useEffect(() => {
    const persistedState = loadState(key);
    dispatch(setActionCreator(persistedState));
  }, [key, dispatch, setActionCreator]);

  const throttledCallback = useMemo(() => throttle(() => {
    saveState(state, key);
  }, 500), [state, key]);

  useEffect(() => {
    throttledCallback();
    return throttledCallback.cancel;
  }, [throttledCallback]);
};
