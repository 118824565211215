import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const StyledInput = props => {
  const { classes, className, RightAdornment = null, ...rest } = props;
  return <div className={classNames(classes.inputContainer, className)}>
    <div className={classes.inputFlex}>
      <input 
        className={classes.input}
        {...rest}
      />
    </div>
    { RightAdornment }
  </div>;
};

export default withStyles(styles)(StyledInput);