import { useEffect, useState } from 'react';
import axios from 'axios';
import Logger from 'js-logger';
import keyBy from 'lodash/keyBy';
import { GetMetricDefinitions } from '../../../../network/metricDefinitionRequests';

function useMetricDefinitionsMap(type) {
  const [ metricDefinitionsMap, setMetricDefinitionsMap ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    let cancelToken = axios.CancelToken.source();
    async function fetchData() {
      setLoading(true);
      try {
        var metricDefinitions = await GetMetricDefinitions(type, cancelToken);
        setMetricDefinitionsMap(keyBy(metricDefinitions, 'name'));
      } catch (e) {
        if (axios.isCancel(e)) return;
        Logger.error(e);
        setError('Error getting ranges, please try again.');
      }
      setLoading(false);
    }
    fetchData();
    return () => cancelToken.cancel();
  }, [type]);

  return { metricDefinitionsMap, loading, error };
}

export default useMetricDefinitionsMap;