import motionTypesConstants from '../../../constants/motionTypes.constants';

export const MetricKeys = {
  torsoRotation: 'torsoRtnJcs',
  torsoVelocity: 'torsoRotationalVelocity',
  torsoBend: 'torsoBend',
  torsoSideBend: 'torsoSideBend',
  pelvisRotation: 'pelvisRtnJcs',
  pelvisVelocity: 'pelvisRotationalVelocity',
  pelvisBend: 'pelvisBend',
  pelvisSideBend: 'pelvisSideBend',
  handVelocity: 'handRotationalVelocity',
  lowerArmVelocity: 'lowerArmRotationalVelocity',
  upperArmVelocity: 'upperArmRotationalVelocity',
  xFactor: 'xFactor'
};

export const GraphableMetrics = [
  { key: MetricKeys.pelvisVelocity, displayName: 'Pelvis Velocity' },
  { key: MetricKeys.torsoVelocity, displayName: 'Torso Velocity' },
  { key: MetricKeys.pelvisBend, displayName: 'Pelvis Bend' },
  { key: MetricKeys.pelvisSideBend, displayName: 'Pelvis Sidebend' },
  { key: MetricKeys.pelvisRotation, displayName: 'Pelvis Rotation' },
  { key: MetricKeys.xFactor, displayName: 'X-Factor' },
  { key: MetricKeys.upperArmVelocity, displayName: 'Upper Arm Velocity' },
  { key: MetricKeys.handVelocity, displayName: 'Hand Velocity' },
  { key: MetricKeys.torsoBend, displayName: 'Torso Bend' },
  { key: MetricKeys.torsoSideBend, displayName: 'Torso Sidebend' },
  { key: MetricKeys.torsoRotation, displayName: 'Torso Rotation' }
];

export function getGraphableMetrics(motionType) {
  if (motionType === motionTypesConstants.golfLeadWrist) {
    return GraphableMetrics.map(metric => metric.key === MetricKeys.upperArmVelocity 
      ? { key: MetricKeys.lowerArmVelocity, displayName: 'Lower Arm Velocity' }
      : metric);
  } else {
    return GraphableMetrics;
  }
}