import React from 'react';
import { lightBlue, orange } from '@material-ui/core/colors';
import { CartesianGrid, Scatter, ScatterChart, XAxis, YAxis, Label } from 'recharts';
import { SEGMENTS } from '../playerPercentiles.constants';

function PercentileScatterPlot({ beforePercentiles, afterPercentiles }) {
  return <ScatterChart width={800} height={500} margin={{ top: 10, bottom: 20 }}>
    <CartesianGrid />
    <XAxis name='body' type='number' dataKey={SEGMENTS.body} domain={[0, 100]} allowDecimals={false}>
      <Label value='Body Percentiles' dy={20} />
    </XAxis>
    <YAxis name='ball' type='number' dataKey={SEGMENTS.ball} domain={[0, 100]} allowDecimals={false}>
      <Label angle={-90} value='EV Percentile' dx={-10} />
    </YAxis>
    <Scatter 
      data={beforePercentiles}
      fill={lightBlue[400]}
    />
    <Scatter 
      data={afterPercentiles}
      fill={orange[400]}
    />
  </ScatterChart>;
}

export default PercentileScatterPlot;
