import React from 'react';
import classNames from 'classnames';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  withMobileDialog
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { CalculateAge } from '../../utils/user.utils';
import { CreateUserWithForms } from '../../network/userRequests';
import ErrorSnackbar from '../errorSnackbar';
import ProgressButton from '../progressButton';

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const DATE_OF_BIRTH = 'dateOfBirth';
const GENDER = 'gender';
const EULA = 'EULA';
const COPPA = 'COPPA';
const COPPA_CUTOFF = 13;

const FormInput = ({ 
  classes, 
  name, 
  onChange, 
  data, 
  disabled 
}) => {
  return <div className={classes.formInputBody}>
    <input 
      type='file'
      onChange={onChange} 
      name={name} 
      id={`file-input-${name}`}
      className={classes.inputFile} 
      accept='image/*'
      disabled={disabled}
    />
    <label htmlFor={`file-input-${name}`} className={classes.inputLabel}>
      <Typography className={classes.formLabel}>Add {name}</Typography>
      <AddIcon className={classes.iconButton}/>
      {data && <img src={data} alt='' height={50} width={50} />}
    </label>
  </div>;
};

const initialState = {
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [DATE_OF_BIRTH]: '',
  [GENDER]: '',
  [EULA]: {},
  [COPPA]: {},
  erroredKeys: [],
  networkError: null,
  saving: false
};

class CreateAccountDialog extends React.Component {
  state = initialState;
  _inputLabelRef = React.createRef();

  _requiresCoppa = () => {
    var dob = new Date(this.state[DATE_OF_BIRTH]);
    return CalculateAge(dob) < COPPA_CUTOFF;
  }

  _onClose = () => {
    this.setState(initialState, this.props.onClose);
  }

  _validateFormInput = (requiresCoppa) => {
    var erroredKeys = [];
    const state = this.state;
    if (state[FIRST_NAME].trim() === '') erroredKeys.push(FIRST_NAME);
    if (state[LAST_NAME].trim() === '') erroredKeys.push(LAST_NAME);
    if (state[GENDER] === '') erroredKeys.push(GENDER);
    if (new Date(state[DATE_OF_BIRTH]).toString() === 'Invalid Date' || !isNaN(new Date(state[DATE_OF_BIRTH])) == null) {
      erroredKeys.push(DATE_OF_BIRTH);
    }
    if (state[EULA].file == null) erroredKeys.push(EULA);
    if (requiresCoppa && state[COPPA].file == null) erroredKeys.push(COPPA);

    if (erroredKeys.length > 0) {
      this.setState({ erroredKeys });
      return false;
    }
    return true;
  }

  _onSubmit = async () => {
    const requiresCoppa = this._requiresCoppa();
    if (!this._validateFormInput(requiresCoppa)) {
      return;
    }
    try {
      this.setState({ saving: true });
      const state = this.state;
      const newUser = await CreateUserWithForms({
        clientId: this.props.clientId,
        email: this.props.email,
        [FIRST_NAME]: state[FIRST_NAME].trim(),
        [LAST_NAME]: state[LAST_NAME].trim(),
        [GENDER]: state[GENDER],
        [DATE_OF_BIRTH]: state[DATE_OF_BIRTH]
      }, {
        eulaFile: state[EULA].file,
        coppaFile: requiresCoppa ? state[COPPA].file : null
      });
      this.setState(initialState, () => this.props.onSubmit(this.props.clientId, newUser));
    } catch (e) {
      var errMsg = 'Unknown error while creating this account, please try again.';
      if (e.response && e.response.status === 409) {
        errMsg = `An account with the email ${this.props.email} already exists.`;
      }
      this.setState({ saving: false, networkError: errMsg });
    }
  }

  _onFormSelected = key => ({ target }) => {
    var files = target.files;
    if (FileReader && files && files.length) {
      var fileReader = new FileReader();
      var file = files[0];
      fileReader.onload = () => {
        this.setState({ 
          [key]: { data: fileReader.result, file }, 
          erroredKeys: [] 
        });
      };
      fileReader.readAsDataURL(file);
    }
  }

  _renderTextField = (key, label) => <TextField variant='outlined'
    error={this.state.erroredKeys.includes(key)}
    className={this.props.classes.inputField} 
    onChange={({ target }) => this.setState({ [key]: target.value, erroredKeys: [] })}
    value={this.state[key]}
    label={label}
    InputLabelProps={{ style: { fontStyle: 'italic' }}}
    disabled={this.state.saving}
  />;

  _renderGenderInput = (key) => <FormControl variant='outlined'
    className={this.props.classes.inputField} 
    error={this.state.erroredKeys.includes(key)}
  >
    <InputLabel ref={this._inputLabelRef} htmlFor={key} >Gender</InputLabel>
    <Select
      value={this.state[key]} 
      onChange={e => this.setState({ [key]: e.target.value, erroredKeys: [] })}
      inputProps={{ name: key, id: key }}
      labelWidth={this._inputLabelRef.current ? this._inputLabelRef.current.offsetWidth : 50}
    >
      <MenuItem value='Male'>Male</MenuItem>
      <MenuItem value='Female'>Female</MenuItem>
      <MenuItem value='Other'>Other</MenuItem>
    </Select>
  </FormControl>;

  _renderFormInput = (key) => <div className={this.state.erroredKeys.includes(key) ? this.props.classes.errorBorder : ''}>
    <FormInput 
      classes={this.props.classes} 
      name={key} 
      onChange={this._onFormSelected(key)} 
      data={this.state[key].data} 
      disabled={this.state.saving} />
  </div>

  render() {
    const {
      open, 
      fullScreen,
      classes,
      email 
    } = this.props;

    return <div>
      <Dialog open={open} fullScreen={fullScreen} fullWidth={true} maxWidth='sm'>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.header}> 
            <Typography variant='body2'>
              <span className={classes.grayText}>Create account for</span> {email}
            </Typography>
            <IconButton onClick={this._onClose}><CloseIcon /></IconButton>
          </div>
          {this._renderTextField(FIRST_NAME, 'First Name')}
          {this._renderTextField(LAST_NAME, 'Last Name')}
          {this._renderGenderInput(GENDER)}
          {this._renderTextField(DATE_OF_BIRTH, 'Birthday (MM/DD/YYYY)')}
          {this._renderFormInput(EULA)}
          <div style={{ visibility: this._requiresCoppa() ? '' : 'hidden' }}> {this._renderFormInput(COPPA)} </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <ProgressButton showProgress={this.state.saving} 
            color='primary' variant='contained'
            onClick={this._onSubmit}
            className={classes.button}
          >
            Create Account
          </ProgressButton>
          <Button disabled={this.state.saving} 
            onClick={this._onClose} 
            className={classNames(classes.button, classes.italic)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorSnackbar 
        message={this.state.networkError}
        onClose={() => this.setState({ networkError: null })}
      />
    </div>;
  }
}

export default withMobileDialog()(withStyles(styles)(CreateAccountDialog));
