import React, { useCallback } from 'react';
import { 
  useStyles, 
  CustomTableRow, 
  BodyTableCell,
  HeaderTableCell
} from '../styles';
import classnames from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';

import InningsTable from './../innings';

const COLUMNS = [
  { colName: '', prop: 'rank' }, 
  { colName: 'Name', prop: 'name' }
];

function LeaderboardPlayerTable({ 
  scores,
  currentLeaderboard,
  gameCurDay
}) {
  const classes = useStyles();

  const scrollRefCB = useCallback(element => {
    if (element) {
      // we can just overshoot and take the width of the screen
      // instead of calculating padding and width with getBoundingClientRect
      // to save some logic
      element.scrollLeft = window.screen.width;
    }
  }, []);

  return <TableContainer classes={{ root: classes.tableContainer }}>
    <Table className={classes.playerTable}>
      <TableHead>
        <CustomTableRow>
          {COLUMNS.map((col) => {
            return <HeaderTableCell key={col.prop}>{col.colName}</HeaderTableCell>;
          })}
        </CustomTableRow>
      </TableHead>
      
      <TableBody>
        {scores.map((player) => {
          const { selected } = player;
          return <CustomTableRow
            className={classnames({ [classes.selectedRow]: selected })}
            key={player.userId} >
            {COLUMNS.map(({ prop }) => {
              return <BodyTableCell 
                className={classnames(classes.cell, { [classes.selected]: selected })} 
                key={prop}>
                <div className={classes.nameCellContainer}>
                  {player[prop]}
                </div>
              </BodyTableCell>;
            })}
          </CustomTableRow>;
        })}
      </TableBody>
    </Table>
    <TableContainer
      ref={scrollRefCB}
      className={classes.scrollableContainer}>
      <InningsTable
        leaderboard={currentLeaderboard}
        scores={scores} 
        curDay={gameCurDay}
      />
      <Table className={classes.runsTable}>
        <TableHead>
          <CustomTableRow>
            <HeaderTableCell></HeaderTableCell>
            <HeaderTableCell>RUNS</HeaderTableCell>
            <HeaderTableCell></HeaderTableCell>
          </CustomTableRow>
        </TableHead>
        <TableBody>
          {scores.map((player) => (
            <CustomTableRow 
              className={classnames({ [classes.selectedRow]: player.selected })} 
              key={player.userId}>
              <BodyTableCell></BodyTableCell>
              <BodyTableCell 
                className={classnames(classes.cell, { [classes.selected]: player.selected })} >
                <div className={classes.numberBackground}>{player.totalScore}</div>
              </BodyTableCell>
              <BodyTableCell></BodyTableCell>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </TableContainer>;
};

export default LeaderboardPlayerTable;