export function formatCentsToDollars(numCents) {
  /* If the amount ends in an even dollar amount,
   * this will return with just the dollar amount.
   * Otherwise, a period will be added between dollars and cents
   * and there will be two digits for cents.
   * 
   * Throws an error if not an integer.
   */
  if (!Number.isInteger(numCents)) {
    throw Error(`Expected integer, received ${numCents}`);
  } else if (numCents % 100 === 0) {
    return (numCents / 100).toString();
  }

  let display = numCents.toString();
  if (display.length < 3) {
    display = display.padStart(3, '0');
  }

  return `${display.slice(0, display.length - 2)}.${display.slice(-2)}`;
}