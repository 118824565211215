import React from 'react';
import PropTypes from 'prop-types';
import meanBy from 'lodash/meanBy';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import uploadStatusConstants from '../uploadStatus.constants';
import ProgressBar from '../../../../../components/progressBar';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import palette from '../../../../../styles/palette';

const COLORS = {
  pending: { foreground: palette.transparent, background: '#DDDBD5' },
  completed: { foreground: palette.transparent, background: '#BCF8B2' },
  failed: { foreground: palette.transparent, background: '#FFC3C3' },
  progressing: { foreground: '#9CE1FF', background: '#CCFFFF' }
};

class UploadProgressDialog extends React.Component {
  _renderRow = ({ file, uploadProgress, title, status }, idx) => {
    const { classes } = this.props;
    let label;
    let colorObj;
    switch (status) {
      case uploadStatusConstants.VIMEO_UPLOADING:
        colorObj = COLORS.progressing;
        break;
      case uploadStatusConstants.FAILURE:
        label = 'Failed'; 
        colorObj = COLORS.failed;
        break;
      case uploadStatusConstants.COMPLETE:
        label = 'Completed';
        colorObj = COLORS.completed;
        break;
      default: 
        label = 'Pending';
        colorObj = COLORS.pending;
        break;
    }

    return <div className={classes.itemContainer} key={file.name}>
      <Typography className={classes.itemText}>{idx + 1}).</Typography>
      <ProgressBar 
        progress={uploadProgress} 
        label={label} 
        foreground={colorObj.foreground} 
        background={colorObj.background} 
      />
      <Typography className={classes.itemText}>{title}</Typography>
    </div>;
  }
  
  render() {
    const { classes, open, videoFiles, onClose, uploadComplete } = this.props;
    const totalComplete = (meanBy(videoFiles, x => x.uploadProgress) || 0).toFixed(0);
    return <Dialog maxWidth='md' fullWidth open={open}>
      <DialogContent>
        {uploadComplete
          ? <Typography className={classes.header}>Upload Complete</Typography>
          : <div className={classes.header}>
            <CircularProgress size={30} className={classes.circularProgress}/>
            <Typography className={classes.header}>
              Uploading Videos... please do not navigate away from this page.
            </Typography>
          </ div>}
        {videoFiles && videoFiles.map((videoFile, idx) => <div key={videoFile.file.name}>
          {this._renderRow(videoFile, idx)}
        </div>)}
        <Typography className={classes.header}> {totalComplete}% Completed Overall </Typography>
      </DialogContent>
      <DialogActions style={{ visibility: uploadComplete ? 'visible' : 'hidden' }}>
        <Button variant='outlined' onClick={onClose} color='primary'>Close</Button>
      </DialogActions>
    </Dialog>;
  }
}

UploadProgressDialog.propTypes = {
  videoFiles: PropTypes.array,
  open: PropTypes.bool,
  uploadComplete: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(UploadProgressDialog);