
export const DEVICE = {
  iOS: 'ios',
  android: 'android',
  other: 'other'
};

export const getDeviceType = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return DEVICE.iOS;
  if (/android/i.test(userAgent)) return DEVICE.android;
  return DEVICE.other;
};

export const isMobile = () => {
  const deviceType = getDeviceType();
  return deviceType === DEVICE.iOS || deviceType === DEVICE.android;
};
