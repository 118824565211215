import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  pageContainer: {
    marginTop: '25px'
  },
  orgUserPaper: {
    padding: '15px'
  },
  title: {
    margin: '25px 0px'
  },
  percentiles: {
    maxWidth: '800px'
  }
}));

export default useStyles;