import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import logger from 'js-logger';
import { indexOf } from 'lodash';
import { 
  Paper, 
  Typography, 
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid
} from '@material-ui/core';
import { PatchTeam, CreateTeam, DeleteTeam } from '../../../network/teamRequests';
import { getOrganizationId } from '../../../utils/auth.utils';
import EditTeam from './editTeam';
import useStyles from './styles';
import ErrorSnackbar from '../../../components/errorSnackbar';
import { SetTeams } from '../../../store/teams';

const Teams = ({ className }) => {
  const classes = useStyles();
  const [errMsg, setErrMsg] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const players = useSelector(state => state.players);
  const teams = useSelector(state => state.teams);
  const dispatch = useDispatch();
  const setTeams = (teams) => dispatch(SetTeams(teams));

  const createTeam = async ({ teamName, abbreviation = '', selectedPlayers }) => {
    try {
      const team = {
        name: teamName,
        userTeams: selectedPlayers.map(({ userId }) => ({ userId })),
        organizationId: getOrganizationId(),
        abbreviation: abbreviation.trim() || null
      };
      const teamResp = await CreateTeam(team);
      setTeams([...teams, teamResp]);
      setSelectedTeam(null);
    } catch (e) {
      logger.error('There was an error while creating the team.', e);
      setErrMsg('There was an error while creating the team. Please refresh and try again.');
    }
  };

  const deleteTeam = async () => {
    try {
      await DeleteTeam(selectedTeam.id);
      setTeams(teams.filter(team => team.id !== selectedTeam.id));
      setSelectedTeam(null);
    } catch (e) {
      logger.error(e);
      setErrMsg('There was an error when deleting the team. Please refresh and try again.');
    }
  };

  const updateTeam = async ({ teamName, abbreviation = '', selectedPlayers }) => {
    let existingPlayerIds = selectedTeam.userTeams.map((user) => user.userId);
    const playerIdUpdates = selectedPlayers.map(user => user.userId);

    // removed players
    let changes = [];
    existingPlayerIds.forEach(id => {
      if (!playerIdUpdates.includes(id)) {
        const userIdx = indexOf(existingPlayerIds, id);
        changes.push({ path: `/UserTeams/${userIdx}/userId`, op: 'test', value: id });
        changes.push({ path: `/UserTeams/${userIdx}`, op: 'remove' });
        // jsonpatch applies ops in order so we need to make sure 
        // the next removals have the correct index
        existingPlayerIds = existingPlayerIds.filter(userId => userId !== id);
      }
    });

    // new players
    playerIdUpdates.forEach(id => {
      if (!existingPlayerIds.includes(id)) {
        changes.push({ path: '/UserTeams/-', op: 'add', value: { userId: id }});
      }
    });

    if (teamName !== selectedTeam.name) {
      changes.push({ path: '/Name', op: 'replace', value: teamName });
    }

    const trimmedAbbr = abbreviation.trim();
    const prevAbbr = selectedTeam.abbreviation || '';
    if (trimmedAbbr !== prevAbbr) {
      changes.push({ 
        path: '/Abbreviation',
        op: 'replace',
        value: trimmedAbbr || null
      });
    }

    if (changes.length === 0) {
      setSelectedTeam(null);
      return;
    }
    
    try {
      const updatedTeam = await PatchTeam(selectedTeam.id, changes);
      const allTeams = teams.map(team => team.id === selectedTeam.id ? updatedTeam : team);
      setTeams(allTeams);
      setSelectedTeam(null);
    } catch (e) {
      logger.error('error patching team', e);
      setErrMsg('There was an error while updating the team. Please refresh and try again.');
      setSelectedTeam(null);
    }
  };

  return (
    <div className={className}>
      <ErrorSnackbar message={errMsg} onClose={() => setErrMsg(null)}/>
      <Grid container justify='space-between' className={classes.teamsTitle}>
        <Typography variant='h5' className={classes.teamsTitle}>Teams</Typography>
        <Button variant='contained' color='primary' onClick={() => setSelectedTeam({})}>Create Team</Button>
      </Grid>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Abbreviation</TableCell>
              <TableCell align='right'># Players</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map(team => (
              <TableRow key={team.id}>
                <TableCell component='th' scope='row'>
                  {team.name}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {team.abbreviation || '-----'}
                </TableCell>
                <TableCell align='right'>{team.userTeams.length}</TableCell>
                <TableCell align='right'>
                  <Button onClick={() => setSelectedTeam(team)}>Manage Team</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <EditTeam 
        players={players}
        team={selectedTeam}
        onCancel={() => setSelectedTeam(null)}
        onSubmit={(data) => selectedTeam.id ? updateTeam(data) : createTeam(data)}
        onDelete={deleteTeam}
      />
    </div>
  );
};

export default Teams;