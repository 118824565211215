import makeStyles from '@material-ui/core/styles/makeStyles';
import Palette from '../../../styles/palette';

const useStyles = makeStyles(() => ({
  autocomplete: {
    width: '300px'
  },

  tagColorInfo: ({ color }) => ({
    marginRight: '10px',
    width: '20px',
    aspectRatio: 1,
    borderRadius: '2px',
    border: '1px solid ' + Palette.black,
    backgroundColor: color
  })
}));

export default useStyles;
