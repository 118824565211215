import { Typography, TextField, Paper, Checkbox, FormControlLabel, List, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ANALYSIS_METRICS } from '../dataAnalysis.constants';
import { calculateStandardDeviation, calculateStdDeviationOfPoints } from './utils';
import useStyles from './styles';

function StandardDeviation() {
  const { motions, tags } = useSelector(state => state.dataAnalysis);
  const [firstMetric, setFirstMetric] = useState(
    ANALYSIS_METRICS.find(metric => metric.label === 'Exit Velocity'));
  const [secondMetric, setSecondMetric] = useState(
    ANALYSIS_METRICS.find(metric => metric.label === 'Peak Speed Body Percentile'));
  const [useTwoDimensions, setUseTwoDimensions] = useState(true);
  const classes = useStyles();

  const tagsAndDeviation = Object.keys(tags).map(tag => {
    const tagMotions = tags[tag].motionIds.map(id => motions[id]);
    let stdDeviation;
    
    if (useTwoDimensions) {
      const points = tagMotions.map(motion => ({
        x: firstMetric.getValue(motion),
        y: secondMetric.getValue(motion)
      }));
      stdDeviation = calculateStdDeviationOfPoints(points);
    } else {
      const data = tagMotions.map(firstMetric.getValue);
      stdDeviation = calculateStandardDeviation(data);
    }
    return { tag, color: tags[tag].color, stdDeviation };
  });
  
  return (
    <Paper className={classes.container}>
      <Typography variant='h5'>Standard Deviations</Typography>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Autocomplete
            disablePortal
            disableClearable
            options={ANALYSIS_METRICS}
            value={firstMetric}
            getOptionLabel={metric => metric.label}
            onChange={(_, newValue) => setFirstMetric(newValue)}
            renderInput={(params) => <TextField {...params} variant='outlined' />}
          />
          <Autocomplete
            disabled={!useTwoDimensions}
            disablePortal
            disableClearable
            options={ANALYSIS_METRICS}
            value={secondMetric}
            getOptionLabel={metric => metric.label}
            onChange={(_, newValue) => setSecondMetric(newValue)}
            renderInput={(params) => <TextField {...params} variant='outlined' />}
          />
          <FormControlLabel 
            checked={useTwoDimensions} 
            control={<Checkbox onChange={() => setUseTwoDimensions(prev => !prev)}/>} 
            label='2 Dimensions'
          />
        </Grid>
        <Grid item>
          <List>
            {tagsAndDeviation.map(({ tag, color, stdDeviation }) => (
              <Grid container key={`${tag}-${stdDeviation}`}>
                <Typography style={{ color }}>{tag}:</Typography>
                <Typography>{String(stdDeviation)}</Typography>
              </Grid>
            ))}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StandardDeviation;