import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TABLE_ROWS, CATEGORIES } from '../../overview/mobilityScreenTable/constants';
import useStyles from '../../overview/mobilityScreenTable/styles';
import { 
  Paper,
  TextField, 
  Button,
  Grid,
  Divider,
  Typography,
  Select,
  MenuItem,
  ListItemText
} from '@material-ui/core';
import useIsMobile from '../../../../utils/useIsMobile';

const NewMobilityScreen = ({ onCancel, onSave }) => {
  const [mobilityScreen, setMobilityScreen] = useState({ moverType: 'neutral' });
  const isMobile = useIsMobile();
  const classes = useStyles();

  const _updateField = (key, value) => {
    setMobilityScreen(prev => ({ ...prev, [key]: value }));
  };

  const categories = Object.keys(CATEGORIES).map(x => ({ value: x, label: CATEGORIES[x] }));
  const spacing = isMobile ? 2 : 8;

  return (
    <Paper className={classes.editContainer}>
      <Grid container justify='center'>
        <Grid item lg={12} >
          <div className={classes.newScreenContainer}>
            <Grid container direction='column'>
              <Grid item container spacing={spacing}>
                <Grid item xs={4}><Typography>Segment</Typography></Grid>
                <Grid item xs={4}><Typography>Left</Typography></Grid>
                <Grid item xs={4}><Typography>Right</Typography></Grid>
              </Grid>
              <Divider orientation='horizontal' className={classes.editDivider}/>
              {TABLE_ROWS.map(row => {
                return <Grid container direction='row' spacing={spacing} key={row.title}>
                  <Grid item xs={4}>
                    <Typography className={classes.titleCell}>
                      {row.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField 
                      type='number' 
                      variant='outlined' 
                      align='center'
                      onChange={e => _updateField(row.left, e.target.value)}/>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField 
                      type='number' 
                      variant='outlined'
                      onChange={e => _updateField(row.right, e.target.value)}/>
                  </Grid>
                </Grid>;
              })}
            </Grid>
          </div>
        </Grid>
        <Grid 
          item 
          container 
          direction='row' 
          alignItems='center' 
          justify='space-evenly' 
          className={classes.moverTypeContainer}
        >
          <Typography>Mover Type:</Typography>
          <Select 
            value={mobilityScreen.moverType} 
            onChange={e => setMobilityScreen(prev => ({ ...prev, moverType: e.target.value }))}
            disableUnderline
          >
            {categories.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container justify='space-between' className={classes.editButtonContainer}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button variant='contained' color='primary' onClick={() => onSave(mobilityScreen)}>Add</Button>
      </Grid>
    </Paper>
  );
};

NewMobilityScreen.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default NewMobilityScreen;