import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  logTrainingButton: ({ hasTrainedToday }) => ({
    pointerEvents: hasTrainedToday 
      ? 'none' 
      : 'auto'
  }),
  floatingTrainingButton: {
    position: 'absolute',
    top: '50px',
    right: '30px',
    marginTop: '10px'
  },
  anchoredTrainingButton: {
    right: '10px'
  },
  spinner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%'
  },
  viewMode: {
    width: '300px',
    margin: '15px'
  },
  topBarHolder: {
    zIndex: 10
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    alignItems: 'center',
    padding: '0 10px 0 20px',
    marginBottom: '1px',
    borderRadius: 0,
    zIndex: 10
  },
  tabSmall: {
    minWidth: '60px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  rightSideButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  root: {
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default styles;