import makeStyles from '@material-ui/core/styles/makeStyles';
import Palette from 'styles/palette';

export default makeStyles(() => ({
  itemSecondaryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  showAllWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  selected: {
    position: 'absolute',
    right: 10
  },
  check: {
    color: Palette.green
  }
}));