import { useMemo } from 'react';

function useEventUserSearchInput(searchInput, eventUsers) {
  const trimmedSearchInput = searchInput.value.trim().toLowerCase();
  const filteredEventUsers = useMemo(() => {
    if (!eventUsers) return []; 
    if (trimmedSearchInput == null) return eventUsers;
    return eventUsers.filter(({ user }) =>
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(trimmedSearchInput)
      || (user.emailAddress && user.emailAddress.toLowerCase().includes(trimmedSearchInput)));
  }, [eventUsers, trimmedSearchInput]);
  return filteredEventUsers;
};

export default useEventUserSearchInput;