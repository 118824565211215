import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import useStyles from './styles';
import { Grid } from '@material-ui/core';

const PlayerListItem = ({ selected, onClick, player, actionTakenText }) => {
  const classes = useStyles();
  return (
    <div className={classes.listItemContainer}>
      {selected && <div className={classes.selectedItemBottom} />}
      {selected && <div className={classes.selectedItemTop} />}
      {selected && <div className={classes.selectedItemBackground} />}

      <div onClick={() => onClick(player.userId)}>
        <div className={classNames(classes.listItem, { [classes.selectedListItem]: selected })}>
          <Typography align='right'>
            {player.firstName} {player.lastName}
          </Typography>
          {actionTakenText &&
            <Grid container direction='row'>
              <Grid item xs={6} className={classes.iconContainer}>
                <CheckCircleOutlineOutlinedIcon className={classes.icon}/>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.actionTakenText}>
                  {actionTakenText}
                </Typography>
              </Grid>
            </Grid>
          }
        </div>
      </div>
    </div>
  );
};

PlayerListItem.propTypes = {
  selected: PropTypes.bool, // the player given is currently selected
  onClick: PropTypes.func, // callback for then when the item is clicked
  player: PropTypes.object // player object to create the list item for
};

export default PlayerListItem;