import React, { useRef, useState } from 'react';
import SHAPES from './shapes/constants';
import Line from './shapes/line';
import Circle from './shapes/circle';
import Freehand from './shapes/freehand';

function CanvasLayer({ 
  onDraw, 
  mode = 'line', 
  color = 'red',
  lineWidth = 3,
  ...props 
}) {
  const canvasRef = useRef();
  const [mouseIsDown, setMouseIsDown] = useState(false);
  const [startCoordinates, setStartCoordinates] = useState({ x: 0, y: 0 });
  const [freehandCoordinates, setFreehandCoordinates] = useState([]);

  function getCanvasCoordinates(mouseX, mouseY){
    var rect = canvasRef.current.getBoundingClientRect();
    let x = mouseX - rect.left;
    let y = mouseY - rect.top;
    return { x, y };
  };

  function calculateCircleParams(mouseX, mouseY) {
    const diffX = Math.abs(mouseX - startCoordinates.x);
    const diffY = Math.abs(mouseY - startCoordinates.y);
    let r = Math.hypot(diffX, diffY) / 2;
    let x = (mouseX + startCoordinates.x) / 2;
    let y = (mouseY + startCoordinates.y) / 2;
    return { x, y, r };
  }

  function clearCanvas() {
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  function onMouseDown(e) {
    setMouseIsDown(true);
    const coordinates = getCanvasCoordinates(e.clientX, e.clientY);
    setStartCoordinates(coordinates);
    if (mode === SHAPES.freehand) {
      const ctx = canvasRef.current.getContext('2d');
      drawPoint(ctx, coordinates.x, coordinates.y);
      setFreehandCoordinates([coordinates]);
    } 
  }

  function onMouseUp(e) {
    if (!mouseIsDown) {
      return;
    }
    const { x, y } = getCanvasCoordinates(e.clientX, e.clientY);
    setMouseIsDown(false);
    
    let shape;
    if (mode === SHAPES.line) {
      shape = new Line(startCoordinates.x, startCoordinates.y, x, y, color, lineWidth);
    }
    else if (mode === SHAPES.circle) {
      const { x: circleX, y: circleY, r } = calculateCircleParams(x, y);
      shape = new Circle(circleX, circleY, r, color, lineWidth);
    }
    else if (mode === SHAPES.freehand) {
      shape = new Freehand(freehandCoordinates, color, lineWidth);
      setFreehandCoordinates([]);
    }

    shape && onDraw && onDraw(shape);
    clearCanvas();
  }

  const onMouseLeave = (e) => {
    if (mouseIsDown) {
      onMouseUp(e);
    }
  };

  function drawLine(ctx, x, y) {
    clearCanvas();
    const line = new Line(startCoordinates.x, startCoordinates.y, x, y, color, lineWidth);
    line.drawOnCanvas(ctx);
  }

  function drawPoint(ctx, x, y) {
    ctx.fillStyle = color;
    // adjust to not draw fractions of pixels and keep dots centered
    const adjustedWidth = lineWidth % 2 === 0 ? lineWidth + 1 : lineWidth;
    const offset = (adjustedWidth - 1) / 2;
    ctx.fillRect(x - offset, y - offset, adjustedWidth, adjustedWidth);
  }
 
  function drawCircle(ctx, mouseX, mouseY) {
    clearCanvas();
    const { x, y, r } = calculateCircleParams(mouseX, mouseY);
    const circle = new Circle(x, y, r, color, lineWidth);
    circle.drawOnCanvas(ctx);
  }

  function onMouseMove(e) {
    if (mouseIsDown) {
      const ctx = canvasRef.current.getContext('2d');
      const { x, y } = getCanvasCoordinates(e.clientX, e.clientY);
      if (mode === SHAPES.line) {
        drawLine(ctx, x, y);
      }
      else if (mode === SHAPES.freehand) {
        drawPoint(ctx, x, y);
        setFreehandCoordinates(prev => ([...prev, { x, y }]));
      }
      else if (mode === SHAPES.circle) {
        drawCircle(ctx, x, y);
      }
    }
  }

  return <canvas 
    ref={canvasRef}
    {...props}
    onMouseDown={onMouseDown}
    onMouseUp={onMouseUp}
    onMouseLeave={onMouseLeave}
    onMouseMove={onMouseMove}
  />;
}

export default CanvasLayer;
