import yellow from '@material-ui/core/colors/yellow';

const SWITCH_MARGIN = '50px';

const styles = {
  title: {
    marginBottom: '15px',
    marginTop: '30px'
  },
  dialog: {
    padding: '20px'
  },
  switch: {
    marginLeft: SWITCH_MARGIN
  },
  teamAssignmentContainer: {
    margin: '15px'
  },
  warning: {
    marginLeft: SWITCH_MARGIN,
    display: 'inline-flex',
    backgroundColor: yellow[400],
    padding: '5px',
    borderRadius: '10px',
    alignItems: 'center'
  },
  warningIcon: {
    marginRight: '10px'
  },
  hidden: {
    visibility: 'hidden'
  }
};

export default styles;