import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { formatShortDateWithSeconds } from 'utils/formatting.utils';
import { FOCUS_SEGMENTS } from '../trainingPlanTemplates.constants';
import { SEGMENT_FOCUS } from 'constants/trainingPlans.constants';
import useStyles from './styles';

function TemplatesList({ 
  templates, 
  onTemplateSelected,
  onNewTemplateClick, 
  selectedId 
}) {
  const classes = useStyles();

  const getFocusString = (template) => FOCUS_SEGMENTS
    .filter(({ key }) => template[key] === SEGMENT_FOCUS.primary)
    .map(({ label }) => label)
    .join(', ');

  return <List>
    <ListItem button divider onClick={onNewTemplateClick}>
      <ListItemIcon><AddIcon color='primary' /></ListItemIcon>
      <ListItemText>New Template</ListItemText>
    </ListItem>
    {templates.map(template => <ListItem
      key={template.id} 
      selected={selectedId === template.id}
      onClick={() => onTemplateSelected(template.id)}
      button
      divider
    >
      <div className={classes.listItemContents}>
        <Typography variant='h5'>{template.name ?? 'Template'}</Typography>
        <Typography variant='caption'>
          Last Edited: {formatShortDateWithSeconds(template.editedTimestamp)}
        </Typography>
        <Typography variant='caption'>Segment Focuses: {getFocusString(template)}</Typography>
      </div>
    </ListItem>)}
  </List>;
}

export default TemplatesList;
