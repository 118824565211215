import { useState } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Button
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { CreateUser, GetUsers, CreateAccountAssociation } from 'network/userRequests';
import Logger from 'js-logger';
import ProgressButton from 'components/progressButton';
import useInput from 'utils/useInput';
import useStyles from './styles';
import { getOrganizationId } from 'utils/auth.utils';

function NewParentAccountCreation({ playerUserId, onNewAccountCreated }) {
  const [errMsg, setErrMsg] = useState('');
  const [emailExists, setEmailExists] = useState(false);
  const emailInput = useInput({ trimValue: true });
  const firstNameInput = useInput({ trimValue: true });
  const lastNameInput = useInput({ trimValue: true });
  const [creatingAccount, setCreatingAccount] = useState(false);
  const classes = useStyles();
  
  const createAccount = async () => {
    setCreatingAccount(true);
    setErrMsg('');
    try {
      // parents get a password reset email when plans are first sent
      var parentUser = await CreateUser({
        email: emailInput.value,
        firstName: firstNameInput.value,
        lastName: lastNameInput.value,
        password: uuidv4(),
        clientId: uuidv4(),
        accountAssociations: [{ associatedUserId: playerUserId }],
        organizationId: getOrganizationId(),
        passwordSet: false
      });
      onNewAccountCreated(parentUser);
      resetForm();
    } catch(e) {
      if (e.response?.status === 409) {
        setEmailExists(true);
      } else {
        Logger.error('Error creating parent account', e);
        setErrMsg('There was an error creating the account. Please try again.');
      }
    }
    setCreatingAccount(false);
  };

  const addExistingUserAsParent = async () => {
    setCreatingAccount(true);
    try {
      // We know the parent account with this email exists, but we need 
      // the userId of that account before we can make the association.
      const users = await GetUsers({ email: emailInput.value });
      if (users.length === 0) {
        setErrMsg('No users found with that email address. Contact dev.');
        setCreatingAccount(false);
        return;
      }
      await CreateAccountAssociation(users[0].userId, playerUserId);
      onNewAccountCreated(users[0]);
      resetForm();
    } catch (e) {
      Logger.error('Error when attempting to create a parent ' +
        'association with an existing user account', e);
      setErrMsg('There was an issue associating the accounts.');
    }
    setCreatingAccount(false);
  };

  const resetForm = () => {
    setEmailExists(false);
    emailInput.setValue('');
    firstNameInput.setValue('');
    lastNameInput.setValue('');
  };

  return (
    <div>
      {emailExists 
        ? <div className={classes.emailExistsContainer}>
          <Typography>An account with this email already exists. 
            This would be expected in the case of siblings.
            Would you like to continue adding this user as a parent?
          </Typography>
          <Grid container justify='center' className={classes.emailExistsButtonGroup}>
            <ProgressButton 
              variant='outlined' 
              color='primary' 
              onClick={addExistingUserAsParent} 
              showProgress={creatingAccount}
            >
              Set As Parent
            </ProgressButton>
            <Button color='secondary' onClick={resetForm}>Cancel</Button>
          </Grid>
        </div>
        : <Grid container>
          <TextField label='First Name' variant='outlined' className={classes.input} {...firstNameInput.bind}/>
          <TextField label='Last Name' variant='outlined' className={classes.input} {...lastNameInput.bind}/>
          <TextField label='Email Address*' variant='outlined' className={classes.input} {...emailInput.bind}/>
          <ProgressButton 
            color='primary' 
            variant='outlined' 
            onClick={createAccount} 
            showProgress={creatingAccount}
            disabled={!emailInput.value}
          >
            Save
          </ProgressButton>
        </Grid>

      }
      <Typography color='error'>{errMsg}</Typography>
    </div>
  );
}

export default NewParentAccountCreation;