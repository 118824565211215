import { makeStyles } from '@material-ui/core/styles';
import { calculateFontSize } from './../../../styles';

export const useClasses = makeStyles(() => ({
  contentContainer: {
    paddingTop: '10px'
  },  
  inningContainer: {
    marginBottom: '30px'
  },
  inningTitle: {
    marginBottom: '15px',
    fontWeight: 'bold',
    fontSize: calculateFontSize(3)
  },
  img: {
    width: '50px'
  },
  badgeRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px'
  },
  badgeImage: {
    marginRight: '15px'
  },
  badgeInfo: {
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      fontSize: calculateFontSize()
    },
    '& span': {
      fontSize: calculateFontSize()
    },
    '&>div': {
      marginBottom: '5px'
    },
    fontSize: calculateFontSize()
  },
  bold: {
    fontWeight: 'bold',
    display: 'inline-block'
  },
  italics: {
    fontStyle: 'italic',
    display: 'inline-block'
  },
  message: {
    whiteSpace: 'pre-wrap',
    fontStyle: 'italic',
    opacity: 0.8
  },
  names: {
    marginTop: '7px'
  }
}));

export default useClasses;