import { useState } from 'react';
import { 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Typography,
  Grid,
  IconButton
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import StyledFileInput from 'components/styledFileInput';
import { PostEventUserCsv } from 'network/captureEventRequests';
import Logger from 'js-logger';
import ProgressButton from 'components/progressButton';

function AddUsersDialog({ open, onClose, eventId }) {
  const [file, setFile] = useState(null);
  const [uploadError, setUploadError] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const uploadCsv = async () => {
    setUploadError('');
    setIsUploading(true);
    try {
      await PostEventUserCsv(eventId, file);
      onClose();
    } catch (e) {
      Logger.error('Error uploading a event user csv: ', e);
      setUploadError('Error when uploading csv. Try again.');
    }
    setIsUploading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add Users</DialogTitle>
      <DialogContent>
        <Typography color='error'>{uploadError}</Typography>
        {file
          ? <div>
            <Grid container alignItems='center'>
              <Typography>File: {file?.name}</Typography>
              <IconButton onClick={() => setFile(null)}>
                <Delete />
              </IconButton>
            </Grid>
          </div>
          : <StyledFileInput 
            name='CsvUpload'
            label={!file ? 'Select user csv' : 'Upload New Data File'}
            onChange={csv => setFile(csv[0])}
            accept='.xlsx, .xls, .csv'
          />
        }
      </DialogContent>
      <DialogActions>
        <ProgressButton disabled={!file} variant='outlined' onClick={uploadCsv} showProgress={isUploading}>
          Upload
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddUsersDialog;