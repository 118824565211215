import React from 'react';
import PropTypes from 'prop-types';
import { formatDateWithSeconds } from '../../../../utils/formatting.utils';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import { 
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Divider,
  Typography,
  ListItemText,
  Grid,
  IconButton
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import MotionTypeIcon from '../../../../components/svgIcons/golfMotionIcon';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const SwingPanel = ({ 
  pagedMotionData, 
  setPage, 
  selectedMotionId, 
  deleteMotion, 
  onMotionSelected, 
  classes 
}) => {
  return (
    <Paper>
      <List>
        <ListItem>
          <Typography align='center' variant='h4'>Motions</Typography>
        </ListItem>
        <Divider />
        {pagedMotionData.items.map(motion => {
          const isCurrentlySelected = motion.id === selectedMotionId;
          return (
            <ListItem 
              button
              className={classes.listItem}
              key={motion.id} 
              selected={isCurrentlySelected}
              onClick={() => onMotionSelected(motion)}
            >
              <ListItemIcon>
                <MotionTypeIcon motionType={motion.motionType} size={30}/>
              </ListItemIcon>
              <ListItemText 
                primary={formatDateWithSeconds(motion.timestamp)}
                className={classes.dateText}
              />
              <ListItemSecondaryAction>
                {isCurrentlySelected && <DeleteOutlinedIcon onClick={deleteMotion}/>}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <Grid container direction='row' justify='space-between' alignItems='center'>
        <IconButton 
          aria-label='previous motions' 
          onClick={() => setPage(pagedMotionData.currentPage - 1)}
          className={pagedMotionData.hasPrev ? null : classes.hidden}  
        >
          <ArrowBack fontSize='inherit' />
        </IconButton>
        <Typography>
          {pagedMotionData.startIndex}-{pagedMotionData.endIndex} / {pagedMotionData.totalCount}
        </Typography>
        <IconButton 
          aria-label='next motions' 
          onClick={() => setPage(pagedMotionData.currentPage + 1)}
          className={pagedMotionData.hasMore ? null : classes.hidden}  
        >
          <ArrowForward fontSize='inherit' />
        </IconButton>
      </Grid> 
    </Paper>
  );
};

SwingPanel.propTypes = {
  selectedMotion: PropTypes.object,
  onMotionSelected: PropTypes.func
};

export default withStyles(styles)(SwingPanel);