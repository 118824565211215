import { useSelector } from 'react-redux';

// takes in either a single permission string or an array of permissions.
//
// returns either a bool or an array of bools to indicate whether each
// permission is available for the user
const usePermissionCheck = (input) => {
  const permissions = useSelector(state => state.currentOrganization.featurePermissions) || [];

  return Array.isArray(input)
    ? input.map(permission => permissions.some(p => p.feature === permission))
    : permissions.some(p => p.feature === input);
};

export default usePermissionCheck;