import React from 'react';
import PropTypes from 'prop-types';
import round from 'lodash/round';
import {
  ScatterChart, 
  Scatter, 
  XAxis, 
  YAxis, 
  CartesianGrid,
  Label,
  Tooltip
} from 'recharts';

function ScatterPlot({ 
  height, 
  width, 
  xAxis, 
  yAxis, 
  userData, 
  hoveringUserId, 
  setHoveringUserId, 
  margin 
}) {
  const xDomain = xAxis.type === 'number' ? ['auto', 'auto'] : null;
  const yDomain = yAxis.type === 'number' ? ['auto', 'auto'] : null;

  function formatTooltip(val, _name, props) {
    if (props.dataKey === xAxis.dataKey && xAxis.tooltipFormatter)
      return xAxis.tooltipFormatter(val);
    else if (props.dataKey === yAxis.dataKey && yAxis.tooltipFormatter)
      return yAxis.tooltipFormatter(val);
    else if (typeof val === 'number')
      return round(val, 2);
    else 
      return val;
  }

  return <ScatterChart width={width} height={height} margin={margin}>
    <CartesianGrid />
    <Tooltip cursor={{ strokeDasharray: '3 3' }} formatter={formatTooltip} />
    <XAxis {...xAxis} allowDuplicatedCategory={false} domain={xDomain}>
      <Label value={xAxis.name} offset={-10} position='insideBottom'/>
    </XAxis>
    <YAxis {...yAxis} tickCount={8} allowDuplicatedCategory={false} 
      domain={yDomain}
      label={{ value: yAxis.name, angle: -90, offset: -10, position: 'insideLeft' }}
    />

    {userData.map(data => {
      return <Scatter key={data.user.userId}
        name={data.user ? `${data.user.firstName} ${data.user.lastName}` : null}
        data={data.graphData}
        stroke={'black'}
        strokeOpacity={hoveringUserId === data.user.userId ? 0.75 : 0}
        fill={data.color}
        onMouseEnter={() => setHoveringUserId(data.user.userId)}
        onMouseLeave={() => setHoveringUserId(null)}
      />;
    })}
  </ScatterChart>;
}

ScatterPlot.defaultProps = {
  userData: [],
  height: 730,
  width: 730,
  setHoveringUserId: () => {},
  margin: { top: 20, right: 30, left: 30, bottom: 20 }
};

const axisShape = PropTypes.shape({
  type: PropTypes.string,
  dataKey: PropTypes.string.isRequired,
  name: PropTypes.string
});

const userDataShape = PropTypes.arrayOf(PropTypes.shape({
  user: PropTypes.object,
  color: PropTypes.string,
  graphData: PropTypes.array
}));

ScatterPlot.propTypes = {
  xAxis: axisShape,
  yAxis: axisShape,
  userData: userDataShape,
  height: PropTypes.number,
  width: PropTypes.number,
  hoveringUserId: PropTypes.string,
  setHoveringUserId: PropTypes.func,
  margin: PropTypes.shape({ // Margin used for recharts graph component
    top: PropTypes.number, 
    left: PropTypes.number, 
    bottom: PropTypes.number, 
    right: PropTypes.number 
  })
};

export default ScatterPlot;