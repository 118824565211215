import { makeStyles } from '@material-ui/core/styles';
import { calculateFontSize } from './../styles';

const useClasses = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: calculateFontSize(2.5),
    width: '100%',
    color: 'white',
    padding: '20px 0 20px 0',
    fontWeight: 'bold'
  },
  message: {
    color: 'white',
    fontSize: calculateFontSize(),
    textAlign: 'center',
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px'
  }
}));

export default useClasses;