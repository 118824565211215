import { useState } from 'react';
import { useSelector } from 'react-redux';
import { round } from 'lodash';
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import { ANALYSIS_METRICS } from '../dataAnalysis.constants';
import { median, mean } from 'mathjs';
import useStyles from './styles';
import SelectedMetricsButton from '../selectMetricsButton';
import classnames from 'classnames';

function MetricTable() {
  const [activeStat, setActiveStat] = useState('Median');
  const [selectedTag, setSelectedTag] = useState(null);
  const { motions, tags } = useSelector(state => state.dataAnalysis);
  // this initial set is exit velo and peak speeds
  const [selectedMetrics, setSelectedMetrics] = useState([
    ANALYSIS_METRICS[0],
    ANALYSIS_METRICS[3],
    ANALYSIS_METRICS[4],
    ANALYSIS_METRICS[5],
    ANALYSIS_METRICS[7]
  ]);
  const classes = useStyles();
  
  const getValueForTagAndMetric = ({ tag, metric }) => {
    const tagMotions = tags[tag].motionIds?.map(id => motions[id]);
    let value;
    const cleanedData = tagMotions.map(metric.getValue).filter(x => x != null);
    if (cleanedData?.length === 0) {
      return value;
    } else if (activeStat === 'Median') {
      value = String(median(cleanedData));
    } else if (activeStat === 'Mean') {
      value = String(round(mean(cleanedData), 2));
    }
    return value;
  };
  
  const onTagClick = (tag) => {
    return () => {
      if (selectedTag === tag) {
        setSelectedTag(null);
      } else {
        setSelectedTag(tag);
      }
    };
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Grid container>
        <Typography variant='h5' className={classes.title}>{activeStat} Values</Typography>
        <SelectedMetricsButton onChange={setSelectedMetrics} selectedMetrics={selectedMetrics} />
    
        <RadioGroup name='stats' 
          value={activeStat} 
          className={classes.stats}
          onChange={({ target }) => setActiveStat(target?.value)}>
          <FormControlLabel value='Median' control={<Radio />} label='Median' />
          <FormControlLabel value='Mean' control={<Radio />} label='Mean' />
        </RadioGroup>
      </Grid>
      <Typography variant='p' className={classes.subtitle}>
        {selectedTag == null ? 'Click on a tag name to see relative values against it.' : `Click ${selectedTag} to deselect it.`}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tag</TableCell>
            {selectedMetrics.map(metric => (
              <TableCell key={`${metric.label}-header`} align='right'>{metric.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(tags).map((tag, idx) => (
            <TableRow key={`${tag.name}-${idx}`}>
              <TableCell 
                align='right' 
                className={classnames(classes.clickable, 
                  { [classes.boldFont]: tag === selectedTag })} 
                onClick={onTagClick(tag)}
              >
                {tag}
                {tag === selectedTag ? '*' : ''}
              </TableCell>
              {selectedMetrics.map(metric => {
                let value = getValueForTagAndMetric({ tag, metric });
                if(selectedTag != null && selectedTag !== tag) {
                  // Get delta value:
                  value = value - getValueForTagAndMetric({ tag: selectedTag, metric });
                  value = String(round(value, 2));
                }
                return <TableCell key={metric.label} align='right'>{value ?? 'N/A'}</TableCell>; 
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MetricTable;