import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import compareDesc from 'date-fns/compareDesc';
import subDays from 'date-fns/subDays';
import { GetMotionsForUserInCurrentOrg, GetMotionsForOrg } from '../../../network/motionRequests';
import { LAUNCH_MONITOR, FULL_MOTION_METRICS, FORCE_PLATE, HITTING_DEVICE_SENSOR } from '../../../constants/motionSubresources.constants';
import { ConvertSubresourcesForMotions } from '../../../utils/unitConverter.utils';

const DELAY_MS = 1500;
const INITIAL_DAYS_AGO = 120;

// Only set userId or orgId but not both
function useRecentMotions(setMotions, userId, orgId, motionType) {
  const [newMotionIds, setNewMotionIds] = useState([]);
  const [error, setError] = useState(null);
  let laterThan = useRef(subDays(new Date(), INITIAL_DAYS_AGO).toISOString());
  let timeoutId = useRef(null);
  let firstRun = useRef(true);

  useEffect(() => {
    let cancelToken = axios.CancelToken.source();

    async function fetchMotions() {
      if (!userId && !orgId) {
        timeoutId.current = setTimeout(fetchMotions, DELAY_MS);
        return;
      }

      try {
        let params = {
          laterThan: laterThan.current,
          subresources: [LAUNCH_MONITOR, FULL_MOTION_METRICS, FORCE_PLATE, HITTING_DEVICE_SENSOR]
        };
        if (motionType){
          params['motionType'] = motionType;
        };

        const motionResponse = userId 
          ? await GetMotionsForUserInCurrentOrg(userId, params, cancelToken)
          : await GetMotionsForOrg(orgId, params, cancelToken);
        let newMotions = motionResponse.items;
        
        // Convert units of necessary data
        newMotions = ConvertSubresourcesForMotions(newMotions);

        newMotions.sort((a, b) => compareDesc(new Date(a.timestamp), new Date(b.timestamp)));
        if (firstRun.current) {
          firstRun.current = false;
          setNewMotionIds([]);
        } else {
          let ids = newMotions.map(x => x.id);
          setNewMotionIds(prevIds => prevIds.length === 0 && ids.length === 0 ? prevIds : ids);
        } 
        setError(null);
        if (newMotions.length > 0) {
          // We only request newer motions if we our launchMonitor data is 
          // is filled in. Which could mean we keep requesting really old motions.      
          if(newMotions[0].launchMonitorData != null) {
            laterThan.current = newMotions[0].timestamp;
          }
          // Because we do request older motions that are already on the screen,
          // we need to filter out the older motions that are also in the new set.
          // We are assuming these old motions are stale and could be missing
          // newer data (launch monitor data);
          setMotions(prev => {
            const filteredPrev = prev.filter(({ id }) => {
              return !newMotions.some((motion) => id === motion.id);
            });
            return [...newMotions, ...filteredPrev].sort(
              (a, b) => compareDesc(new Date(a.timestamp), new Date(b.timestamp)));
          });
        }
      } catch (error) {
        if (axios.isCancel(error)) return;
        setNewMotionIds([]);
        setError(error);
      }

      timeoutId.current = setTimeout(fetchMotions, DELAY_MS);
    }

    fetchMotions(true);
    return function() {
      timeoutId.current != null && clearTimeout(timeoutId.current);
      cancelToken.cancel();
    };
  }, [setMotions, userId, orgId, motionType]);
  
  return { newMotionIds, error, firstRun: firstRun.current };
};

export default useRecentMotions;
