import React from 'react';
import BODY_PERCENTILES_AVATAR from 'assets/body_percentiles_avatar_ball-dark.png';
import useStyles from './styles';
import MetricsColumn from './MetricsColumn';
import classNames from 'classnames';

function SwingSnapshotAvatar({ data = [], height, className }) {
  const classes = useStyles({ height });

  return <div className={classNames(classes.container, className)}>
    <div className={classes.innerContainer}>
      <img 
        src={BODY_PERCENTILES_AVATAR}
        alt='Snapshot'
        className={classes.image}
      />

      <div className={classes.columnsContainer}>
        {data.map(item => <MetricsColumn 
          {...item}
        />)}
      </div>
    </div>
  </div>;
}

export default SwingSnapshotAvatar;
