import Network from './network';

const TEAMS = 'API/Teams';

export const GetAllTeams = async (organizationId, cancelToken) => {
  const resp = await Network.Get(TEAMS, { organizationId }, cancelToken);
  return resp.data.items;
};

export const PatchTeam = async (teamId, changes) => {
  const resp = await Network.Patch(`${TEAMS}/${teamId}`, changes);
  return resp.data;
};

export const CreateTeam = async (team) => {
  const resp = await Network.Post(TEAMS, team);
  return resp.data;
};

export const DeleteTeam = async (teamId) => {
  await Network.Delete(`${TEAMS}/${teamId}`);
};