import makeStyles from '@material-ui/core/styles/makeStyles';
import palette from '../../../styles/palette';

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: 'bold'
  },
  error: {
    color: palette.error
  },
  row: {
    '&:hover': {
      backgroundColor: palette.lightGray,
      cursor: 'pointer'
    }
  },
  snippet: {
    maxHeight: '75px',
    overflow: 'auto'
  },
  showAll: {
    marginTop: '15px'
  }
}));

export default useStyles;
