import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logger from 'js-logger';

import useStyles from './styles';

import { Button, Typography } from '@material-ui/core';

import KinematicCarousel from '../../../../../components/kinematicCarousel';
import DraftTable from './draftTable';

import { formatDateWithSeconds } from '../../../../../utils/formatting.utils';
import { GetDrafts, DeleteDraft, GetAssessmentWithMotion } from '../../../../../network/kAssessmentRequests';

function SelectMotionView({ 
  onBack,
  userId,
  orgId,
  onConfirmation
}) {
  
  const [motionData, setMotionData] = useState(null);

  // drafts state
  const [drafts, setDrafts] = useState(null);
  const [deletingDraft, setDeletingDraft] = useState(null);
  const [loadingDraft, setLoadingDraft] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // callbacks for selecting motions
  const motionSelected = (data) => setMotionData(data);
  const motionConfirmed = () => onConfirmation(motionData, null);

  // callbacks for selecting actions for drafts
  const draftSelected = (draft) => setLoadingDraft(draft);
  const draftDeleted = (draft) => setDeletingDraft(draft);

  // load when mounts
  useEffect(() => {
    setLoadingDraft(null);
    setDeletingDraft(null);
    setIsLoading(false);
    setMotionData(null);
    setDrafts(null);
  }, []);

  // calls to get list of drafts
  useEffect(() => {
    if (!userId || !orgId || drafts) {
      return;
    }

    const cancelToken = axios.CancelToken.source();
    const getDrafts = async () => {
      try {
        const drafts = await GetDrafts(userId, orgId, cancelToken);
        setDrafts(drafts);
      } catch (e) {
        if (!axios.isCancel(e)) {
          Logger.error('Error with listing Wip Assessments', e);
        }
      }
    };
    getDrafts();
    
    return cancelToken.cancel;
  }, [userId, orgId, drafts]);

  // call to delete a draft
  useEffect(() => {
    if (!userId || !orgId || !deletingDraft) {
      return;
    }

    const deleteCall = async () => {
      setIsLoading(true);
      try {
        await DeleteDraft(deletingDraft.id);
        setDeletingDraft(null);
        setIsLoading(false);
        setDrafts(null);
      } catch (e) {
        Logger.error('Error with deleting Wip Assessment', e);
      }
    };
    deleteCall();

  }, [userId, orgId, deletingDraft]);

  // call to load an draft
  useEffect(() => {
    if (!userId || !orgId || !loadingDraft) {
      return;
    }
    const cancelToken = axios.CancelToken.source();

    const loadCall = async () => {
      setIsLoading(true);
      try {
        const data = await GetAssessmentWithMotion(loadingDraft.id, cancelToken);
        if (data) {
          const { assessment, motionData } = data;
          onConfirmation(motionData, assessment);
        }
      } catch (e) {
        if (!axios.isCancel(e)) {
          Logger.error('Error with loading a draft', e);
        }
      }
    };
    
    loadCall();
    return cancelToken.cancel;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, orgId, loadingDraft]);

  // private functions
  const formatMotionSelected = (data) => {
    const pre = 'Selected Motion: ';
    if (!data) {
      return pre + '-----------';
    }
    return pre + formatDateWithSeconds(data.motion.timestamp).toString();
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button className={classes.button} onClick={onBack} disabled={isLoading}>
        <Typography className={classes.buttonText}>BACK TO COACH VIEW</Typography>
      </Button>

      <DraftTable 
        drafts={drafts} 
        onDraftSelected={draftSelected}
        onDraftDeleted={draftDeleted}
        isLoading={isLoading} />

      <div className={classes.motionContainer}>
        <KinematicCarousel userId={userId} onMotionSelected={motionSelected}/>
      </div>

      {
        Boolean(motionData) &&
                <div className={classes.selectedMotionText}>
                  {formatMotionSelected(motionData)}
                </div>
      }

      <Button
        className={classes.button} 
        disabled={!Boolean(motionData) || isLoading}
        onClick={motionConfirmed}>
        <Typography className={classes.buttonText}>SELECT MOTION</Typography>
      </Button>

    </div>
  );
}

export default SelectMotionView;