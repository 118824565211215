import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../../styles/palette';

const styles = makeStyles(() => ({
  button: {
    margin: '5px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  alreadyAttachedText: {
    color: palette.darkGray,
    fontStyle: 'italic'
  },
  submissionButtons: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default styles;
