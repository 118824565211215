import React, { useState, useEffect } from 'react';
import Logger from 'js-logger';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import sortBy from 'lodash/sortBy';
import axios from 'axios';
import VideoUploadForm from './videoUploadForm';
import { GetMotionVideos } from '../../../network/videoRequests';

import VideoStatus, { VIDEO_TYPE } from '../../../constants/video.constants';
import { PERMISSIONS } from '../../../constants/permissions.constants';
import useStyles from './styles';
import VideoPlayer from '../../../components/videoPlayer';
import NoDataMessage from '../../../components/noDataMessage';
import useCurrentPlayerName from '../../../utils/useCurrentPlayerName';
import usePermissionCheck from '../../../utils/usePermissionsCheck';
import ErrorSnackbar from '../../../components/errorSnackbar';
import CreateAnalysis from './createAnalysis';
import BlobStorageVideoView from './blobStorageVideoView';

function MotionVideos({ match, location }) {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const playerName = useCurrentPlayerName();
  const classes = useStyles();

  const { userId } = match.params;

  const hasToolingPermission = usePermissionCheck(PERMISSIONS.internalTooling);

  useEffect(() => {
    // clear location state so that initial videos don't persist
    window.history.replaceState(null, null);
    const cancelToken = axios.CancelToken.source();

    (async function fetchData() {
      setLoading(true);
      try {
        const videos = await GetMotionVideos(userId, null, cancelToken);
        setVideos(sortBy(videos, x => x.CreationTimestamp).reverse());
      } catch (e) {
        if (!axios.isCancel(e)) {
          Logger.error(`Error loading videos for user ${userId}: ${e}`);
          setError('Error loading videos.');
        }
      }
      setLoading(false);
    })();

    return cancelToken.cancel;
  }, [userId]);

  const availableVideos = videos.filter(video => video.status === VideoStatus.available);
  const processingVideos = videos.filter(video => 
    video.status === VideoStatus.processing || 
    video.status === VideoStatus.uploading);

  const processingMsg = processingVideos.length === 1
    ? '1 video is still being processed'
    : `${processingVideos.length} videos are still being processed`;

  return <div className={classes.pageContainer}>
    <ErrorSnackbar message={error} onClose={() => setError(null)} />
    <Grid container justify='space-around'>
      <VideoUploadForm
        userId={userId}
        initialFiles={location.state ? location.state.initialFiles : null}
        onUploadComplete={videos => setVideos(prev => [...videos, ...prev])}
        className={classes.uploadForm}
        videoType={VIDEO_TYPE.motion}
      />
      {hasToolingPermission && <Box p={1}>
        <CreateAnalysis userVideos={videos}/>
        <BlobStorageVideoView userId={userId} />
      </Box>}
    </Grid>
    {processingVideos.length > 0 &&
      <Typography className={classes.processingVideosText}>
        {processingMsg}
      </Typography>
    }
    {loading ? <CircularProgress /> : availableVideos.length > 0 
      ? <Grid container alignItems='flex-end' spacing={1}>
        {availableVideos.map(video => <Grid item key={video.id}
          xs={12}
          sm={6}
          className={classes.transition}
        >
          <VideoPlayer src={video.url} title={video.id} options={{ loop: true }} />
        </Grid>)}
      </Grid>
      : <NoDataMessage>
        There are currently no videos for {playerName}.
      </NoDataMessage>
    }
  </div>;
}

export default MotionVideos;
