import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  headerStyle: {
    fontWeight: 'bold'
  },
  tableContainer: {
    flex: 1
  },
  row: {
    cursor: 'pointer'
  }
}));

export default useStyles;