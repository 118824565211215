import React, { useState, useEffect } from 'react';
import log from 'js-logger';
import { VerifyEmailAddress } from '../../network/userRequests';
import EmailVerificationSuccess from '../emailVerificationSuccess';
import AuthenticationService from '../../services/authentication';
import AuthorizationPageWrapper from '../../components/authorizationPageWrapper';

function VerifyEmail({ match }) {
  const encodedTokenString = match.params.token;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function postVerification() {
      let token;
      try {
        const authenticationService = new AuthenticationService();
        token = authenticationService.decodeJwtToken(encodedTokenString);
      } catch (e) {
        log.error(e, 'Error deserializing token.');
        setLoading(false);
        setError(true);
        return;
      }

      try {
        await VerifyEmailAddress(token.primarysid, encodedTokenString);
      } catch (e) {
        log.error(e, 'Error verifying email address for token ' + token.primarysid);
        setError(true);
      }
      setLoading(false);
    }
    postVerification();
  }, [encodedTokenString]);

  return <AuthorizationPageWrapper>
    <EmailVerificationSuccess loading={loading} error={error} />
  </AuthorizationPageWrapper>;
};

export default VerifyEmail;