import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  rowMessage: {
    padding: '5px',
    textAlign: 'center'
  },
  headerStyle: {
    fontWeight: 'bold'
  },
  button: {
    marginBottom: '10px'
  }
}));

export default useStyles;