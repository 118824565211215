import format from 'date-fns/format';

const simpleFormatter = (value) => { return value; };
const decimalFormatter = (value) => { return value.toFixed(2); };
const dateFormatter = (value) => { 
  var date = new Date(value);
  return format(date, 'MM/dd/yy h:mm aa');
};

const PitchingSummaryData = [
  {
    key: 'firstName',
    displayName: 'First Name',
    formatter: simpleFormatter
  },
  {
    key: 'lastName',
    displayName: 'Last Name',
    formatter: simpleFormatter
  },
  {
    key: 'timestamp',
    displayName: 'Timestamp',
    dataType: 'date',
    formatter: dateFormatter
  },
  {
    key: 'maxXFactor',
    displayName: 'Max X-Factor',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'maxXFactorFrame',
    displayName: 'Max X-Factor Frame',
    formatter: simpleFormatter
  },
  {
    key: 'peakSpeedPelvis',
    displayName: 'Peak Speed Pelvis',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'peakSpeedTorso',
    displayName: 'Peak Speed Torso',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'peakSpeedUpperArm',
    displayName: 'Peak Speed Upper Arm',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'peakSpeedLowerArm',
    displayName: 'Peak Speed Lower Arm',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'peakSpeedFramePelvis',
    displayName: 'Peak Speed Frame Pelvis',
    formatter: simpleFormatter
  },
  {
    key: 'peakSpeedFrameTorso',
    displayName: 'Peak Speed Frame Torso',
    formatter: simpleFormatter
  },
  {
    key: 'peakSpeedFrameUpperArm',
    displayName: 'Peak Speed Frame Upper Arm',
    formatter: simpleFormatter
  },
  {
    key: 'peakSpeedFrameLowerArm',
    displayName: 'Peak Speed Frame Lower Arm',
    formatter: simpleFormatter
  },
  {
    key: 'xFactorAtMaxLoad',
    displayName: 'ML X Factor',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisBendAtMaxLoad',
    displayName: 'ML Pelvic Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisRotationAtMaxLoad',
    displayName: 'ML Pelvic Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisSideBendAtMaxLoad',
    displayName: 'ML Pelvic Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoBendAtMaxLoad',
    displayName: 'ML Torso Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoRotationAtMaxLoad',
    displayName: 'ML Torso Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoSideBendAtMaxLoad',
    displayName: 'ML Torso Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'upperArmRotationRateAtMaxLoad',
    displayName: 'ML Upper Arm Rotation Rate',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'lowerArmFlexionRateAtMaxLoad',
    displayName: 'ML Lower Arm Flexion Rate',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'xFactorAtPeakPelvisSpeed',
    displayName: 'PPS X Factor',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisBendAtPeakPelvisSpeed',
    displayName: 'PPS Pelvic Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisRotationAtPeakPelvisSpeed',
    displayName: 'PPS Pelvic Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisSideBendAtPeakPelvisSpeed',
    displayName: 'PPS Pelvic Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoBendAtPeakPelvisSpeed',
    displayName: 'PPS Torso Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoRotationAtPeakPelvisSpeed',
    displayName: 'PPS Torso Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoSideBendAtPeakPelvisSpeed',
    displayName: 'PPS Torso Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'upperArmRotationRateAtPeakPelvisSpeed',
    displayName: 'PPS Upper Arm Rotation Rate',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'lowerArmFlexionRateAtPeakPelvisSpeed',
    displayName: 'PPS Lower Arm Flexion Rate',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'xFactorAtBallRelease',
    displayName: 'BR X Factor',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisBendAtBallRelease',
    displayName: 'BR Pelvic Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisRotationAtBallRelease',
    displayName: 'BR Pelvic Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisSideBendAtBallRelease',
    displayName: 'BR Pelvic Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoBendAtBallRelease',
    displayName: 'BR Torso Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoRotationAtBallRelease',
    displayName: 'BR Torso Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoSideBendAtBallRelease',
    displayName: 'BR Torso Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'upperArmRotationRateAtBallRelease',
    displayName: 'BR Upper Arm Rotation Rate',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'lowerArmFlexionRateAtBallRelease',
    displayName: 'BR Lower Arm Flexion Rate',
    formatter: decimalFormatter,
    units: 'Degrees'
  }
];

// Filter out: firstName, lastName
const nonGraphableValues = ['firstName'];
const graphableValuesList = PitchingSummaryData.filter((row) => {
  return !nonGraphableValues.includes(row.key);
});

export const PitchingSummaryGraphableValues = graphableValuesList;

const pitchingSummaryMap = {};

PitchingSummaryData.forEach((row) => {  
  pitchingSummaryMap[row.key] = row;
});

export const PitchingSummaryToMap = pitchingSummaryMap;
export const PitchingSummaryUtilList = PitchingSummaryData;

const metricValueBlacklist = [
  'timestamp', 
  'maxXFactorFrame', 
  'peakSpeedFramePelvis', 
  'peakSpeedFrameTorso', 
  'peakSpeedFrameUpperArm', 
  'peakSpeedFrameLowerArm', 
  'lastName'];
export const PitchingSwingProgressMetrics = graphableValuesList.filter((row) => {
  return !metricValueBlacklist.includes(row.key);
});