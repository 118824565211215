import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Grid, Modal, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { GetDrillVideos, DeleteVideo } from '../../network/videoRequests';
import SearchBar from '../../components/searchBar';
import styles from './styles';
import VimeoPlayer from '../../components/vimeoPlayer';
import VideoCard from '../../components/videoCard';
import VideoUploadForm from '../playerDataTabs/motionVideos/videoUploadForm';
import logger from 'js-logger';
import { VIDEO_TYPE } from '../../constants/video.constants';

const DrillVideosUpload = ({ classes }) => {
  const [drillVideos, setDrillVideos] = useState([]);
  const [isUploadingVideos, setIsUploadingVideos] = useState(false);
  const [isFetchingVideos, setIsFetchingVideos] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    const token = axios.CancelToken.source();
    const fetchVideos = async () => {
      setIsFetchingVideos(true);
      try {
        const videos = await GetDrillVideos(null, token);
        setDrillVideos(videos);
      } catch (e) {
        if (axios.isCancel(e)) return;
        logger.error('Error when attempting to get drill videos for org.', e);
      } finally {
        setIsFetchingVideos(false);
      }
    };
    fetchVideos();
    return token.cancel;
  }, []);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  };

  const deleteVideo = async video => {
    if (!window.confirm(`Are you want to delete ${video.title}?`)) {
      return;
    }

    try {
      await DeleteVideo(video.id);
      setDrillVideos(prev => prev.filter(prevVideo => prevVideo.id !== video.id));
    } catch (e) {
      setErrMsg('There was an issue deleting the video. Please try again.');
      logger.error('Error deleting a video', e);
    }
  };

  const closeSnackbar = () => {
    setErrMsg('');
  };

  const videos = searchValue.trim() !== ''
    ? drillVideos.filter(video => video.title.toLowerCase()
      .includes(searchValue.toLowerCase().trim()))
    : drillVideos;
  
  const selectVideo = video => {
    setSelectedVideo(video);
  };

  const finishedUpload = videos => {
    setDrillVideos([...drillVideos, ...videos]);
    setIsUploadingVideos(false);
  };

  return (
    <div className={classes.pageContainer}>
      <Typography variant='h4'>Drill Videos</Typography>
      <Grid className={classes.header} container direction='row'>
        {!isUploadingVideos && 
          <SearchBar 
            className={classes.searchBar} 
            value={searchValue} 
            onChange={(e) => setSearchValue(e.target.value)}
            onClear={() => setSearchValue('')}
          />
        }
        <VideoUploadForm
          videoType={VIDEO_TYPE.drill}
          onVideosSelected={files => files.length > 0 && setIsUploadingVideos(true)}
          onSelectionCancelled={() => setIsUploadingVideos(false)}
          onUploadComplete={finishedUpload}
          className={classes.uploadButton}
        />
      </Grid>
      {isFetchingVideos ? <CircularProgress /> 
        : !isUploadingVideos && videos.length > 0 
          ? <Grid container className={classes.videoGrid} spacing={4}>
            {videos.map(video => (
              <Grid item key={video.id}>
                <VideoCard 
                  video={video} 
                  onClick={() => selectVideo(video)} 
                  onDelete={() => deleteVideo(video)}
                />
              </Grid>
            ))}
          </Grid>
          : <Typography className={classes.videoGrid}>
            Your organization does not have any drills uploaded yet. 
            Click the upload button to get started.
          </Typography>
      }
      <Modal
        open={selectedVideo != null}
        onClose={() => setSelectedVideo(null)}
      >
        <div className={classes.videoModal}>
          {selectedVideo != null && 
            <VimeoPlayer 
              id={Number(selectedVideo.externalId)} 
              url={selectedVideo.url}
              shouldPlay
            /> }
        </div>
      </Modal>
      <Snackbar open={errMsg !== ''} autoHideDuration={3000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity='error'>{errMsg}</Alert>
      </Snackbar>
    </div>
  );
};

export default withStyles(styles)(DrillVideosUpload);