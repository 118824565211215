import { useState, useEffect } from 'react';
import axios from 'axios';
import Logger from 'js-logger';
import { GetScheduledContentDeliveries } from '../../network/scheduledContentDeliveriesRequests';

function useScheduledContentDeliveries(organizationId) {
  const [deliveries, setDeliveries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    
    async function fetchData() {
      setLoading(true);
      try {
        const scheduledDeliveries = 
          await GetScheduledContentDeliveries(organizationId, cancelSource);
        scheduledDeliveries.sort(
          (a, b) => new Date(b.creationTimestamp) - new Date(a.creationTimestamp));
        setDeliveries(scheduledDeliveries);
        setLoading(false);
      }
      catch (e) {
        if (!axios.isCancel(e)) {
          Logger.error('Error fetching scheduled content deliveries', e);
          setError(true);
          setLoading(false);
        }
      }
    }
    fetchData();

    return cancelSource.cancel;
  }, [organizationId]);

  return [deliveries, setDeliveries, loading, error];
}

export default useScheduledContentDeliveries;
