import Network from './network';

const NOTES = 'API/Notes/';

export async function GetNotes(userId, cancelToken) {
  const resp = await Network.Get(NOTES, { userId, includePending: true }, cancelToken);
  return resp.data.items;
}

export async function CreateNote(params) {
  const resp = await Network.Post(NOTES, params);
  return resp.data;
}

export async function UpdateNote(noteId, params) {
  const resp = await Network.Put(NOTES + noteId, params);
  return resp.data;
}

export async function PatchNote(noteId, patchDoc) {
  const resp = await Network.Patch(NOTES + noteId, patchDoc);
  return resp.data;
}
