import palette from '../../../styles/palette';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(_ => ({
  cardPricing: {
    justifyContent: 'center',
    alignItems: 'baseline',
    textAlign: 'center'
  },
  cardHeader: {
    backgroundColor: palette.superLightGrey
  },
  strikeThrough: {
    color: palette.red,
    textDecoration: 'line-through'
  },
  promoPricePrefix: {
    marginRight: '20px'
  },
  promoPricing: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '50px'
  },
  savings: {
    fontWeight: 'bold'
  }
}));
