import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import useStyles from './styles';
import motionTypesConstants from '../../../../../constants/motionTypes.constants';
import useEventMotionRequest from './../useEventMotionsRequest';
import EventUserMotionMetricsTable from './../eventUserMotionMetricsTable';
import { Grid, IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

function EventUserDetails({ eventUser, onDeleteUser }) {
  const { userId, captureEventId, captureEventResults } = eventUser;
  
  const classes = useStyles();

  const [motions, loading] = useEventMotionRequest(captureEventId, userId);

  return <Paper elevation={3} className={classes.detailsContainer}>
    {captureEventResults.length === 0 && <Typography>
      Results for this user have not yet been generated or could not be found.
    </Typography>}
    {captureEventResults.map(captureEventResult => {
      const { handedness, motionType } = captureEventResult;
      const isPitch = motionType === motionTypesConstants.baseballPitch;
      const resMotions = motions.filter(m => {
        return (motionType == null || m.motionType === motionType)
          && (handedness == null || m.fullMotionMetrics.playerHandedness === handedness);
      });

      return <div key={captureEventResult.id}>
        <Grid container justify='space-between'>
          {(motionType != null || handedness != null) && <Typography variant='h5'>
            {motionType && `Motion Type: ${motionType}`}
            {motionType && handedness && ', '}
            {handedness && `Handedness: ${handedness}`}
          </Typography>}
          <IconButton onClick={() => onDeleteUser(eventUser)}>
            <DeleteOutlineIcon />
          </IconButton>
        </Grid>

        <Typography variant='h6'>Descriptions</Typography>
        <Table size='small'>
          <TableBody>
            <TableRow>
              <TableCell align='right' className={classes.boldText}>Speed:</TableCell>
              <TableCell>{captureEventResult.speedDescription || 'N/A'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='right' className={classes.boldText}>Sequence:</TableCell>
              <TableCell>{captureEventResult.sequenceDescription || 'N/A'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='right' className={classes.boldText}>Stretch:</TableCell>
              <TableCell>{captureEventResult.stretchDescription || 'N/A'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        
        <br />

        <EventUserMotionMetricsTable 
          loading={loading}
          motions={resMotions}
          captureEventResult={captureEventResult}
          isPitch={isPitch}
        />

        <Divider />
      </div>;
    })}
  </Paper>;
}

export default EventUserDetails;
