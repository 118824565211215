import React from 'react';
import Typography from '@material-ui/core/Typography';

export const ERROR_TYPES = {
  loading: 'loading',
  submitting: 'submitting',
  conflict: 'conflict',
  invalidInput: 'invalidInput',
  deleting: 'deleting'
};

function ErrorText({ error, title }) {
  return <Typography color='error'>
    {error === ERROR_TYPES.loading && 'Error fetching drill videos. Refresh to try again.'}
    {error === ERROR_TYPES.submitting && 'Unknown error creating template. Please try again.'}
    {error === ERROR_TYPES.invalidInput && 'Title or body text is invalid.'}
    {error === ERROR_TYPES.conflict && `Template with title ${title} already exists.`}
    {error === ERROR_TYPES.deleting && 'Error deleting template. Please try again.'}
  </Typography>;
}

export default ErrorText;
