import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Logger from 'js-logger';

import StickyHeader from '../../assessmentStickyHeader';
import AnalystHeader from '../../assessmentHeaderContent/analystHeader';

import { Button, Typography, CircularProgress } from '@material-ui/core';

import AssessmentView from '../assessmentView';
import ConfirmationDialog from '../../../../../components/dialogs/confirmationDialog';

import { CreateAssessment, UpdateDraft } from '../../../../../network/kAssessmentRequests'; 
import { transformToServerObject, STATUS } from '../../../../../utils/kAssessments.utils.js';

const VIEWS = {
  IN_PROGRESS: 'in-progress',
  REVIEW: 'review'
};

function AnalystView({
  initialAssessment,
  topPadding,
  userId,
  orgId,
  motionData,
  onBackToSelectMotion,
  onPublish,
  onSaved
}) {

  const [labels, setLabels] = useState(initialAssessment.labels);
  const [sections, setSections] = useState(initialAssessment.sections);
  const [selectedLabel, setSelectedLabel] = useState(null);

  useEffect(() => {
    setLabels(initialAssessment.labels);
    setSections(initialAssessment.sections);
  }, [initialAssessment]);

  // need to update the initial assessment in place 
  useEffect(() => {
    // reference to the label changed so that it always points here at the end
    initialAssessment.labels = labels;
  }, [initialAssessment, labels]);

  // actions
  const [publishingAssessment, setPublishingAssessment] = useState(false);
  const [savingAssessment, setSavingAssessment] = useState(false);

  // callbacks and handlers
  const labelsUpdated = (action, labelData) => {
    if (action === 'add') {
      labels[labelData.name] = labelData;
    } else { // must be to delete
      delete labels[labelData.name];
    }
    setLabels(Object.assign({}, labels));
  };

  const sectionUpdated = (sectionName, text) => {
    if (!sections[sectionName]) {
      sections[sectionName] = {};
    }
    sections[sectionName].text = text;
  };

  const labelSelected = (label) => {
    setSelectedLabel(Object.assign({}, label));
  };

  const publishAssessment = () => {
    setPublishingAssessment(true);
  };

  // view management
  const [view, setView] = useState(VIEWS.IN_PROGRESS);
  const [dialogOpen, setDialogOpen] = useState(false);
  const goToReview = () => setView(VIEWS.REVIEW);
  const goToInProgress = () => setView(VIEWS.IN_PROGRESS);
  const goToMotions = () => setDialogOpen(true);
  const closeDialogAndGoBack = () => {
    setDialogOpen(false);
    onBackToSelectMotion();
  };

  // calls to API
  useEffect(() => {

    if (!publishingAssessment && !savingAssessment) {
      return;
    }

    const save = async () => {
      let status = publishingAssessment ? STATUS.PUBLISHED : STATUS.WIP;
      let updated;
      try {
        const id = initialAssessment.id;
        const assessmentInfo = transformToServerObject(
          userId, 
          orgId, 
          motionData, 
          sections, 
          labels, 
          status,
          initialAssessment.id
        );
        if (id != null) {
          updated = await UpdateDraft(id, assessmentInfo);
        } else {
          updated = await CreateAssessment(assessmentInfo);
        }
        setPublishingAssessment(false);
        setSavingAssessment(false);

        // go back to coach view
        if (status === STATUS.PUBLISHED) {
          onPublish();
        } else {
          onSaved(updated);
        }

      } catch (e) {
        Logger.error('Error with publishing an assessment', e);
      }
    };

    save();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    publishingAssessment, 
    savingAssessment, 
    userId, 
    orgId, 
    motionData, 
    sections, 
    labels, 
    initialAssessment, 
    onPublish
  ]);

  // cleanup
  useEffect(() => {
    return () => {
      setLabels({});
      setSections({});
      setView(VIEWS.IN_PROGRESS);
      setSelectedLabel(null);
    };
  }, []);

  const classes = useStyles({ topPadding });

  // vars to help state
  const isLoading = publishingAssessment || savingAssessment;

  return <>
    <StickyHeader 
      labels={labels} 
      label={selectedLabel}
      showSave={true}
      savingDraft={savingAssessment}
      onSaveDraft={() => { setSavingAssessment(true); }}
      motionData={motionData}
      userId={userId} >
      <AnalystHeader
        userId={userId}
        disable={isLoading}
        motionData={motionData}
        onLabelChange={labelsUpdated} 
        labels={labels} />
    </StickyHeader>

    <div className={classes.viewMargin}>
      {
        view === VIEWS.IN_PROGRESS &&
        <div>
          <div className={classes.topButtons}>
            <Button disabled={isLoading} className={classes.actionButtons} onClick={goToReview}>
              <Typography className={classes.buttonText}>Review Assessment</Typography>
            </Button>

            <Button disabled={isLoading} className={classes.actionButtons} onClick={goToMotions}>
              <Typography className={classes.buttonText}>Back to Motions</Typography>
            </Button>
          </div>
          <AssessmentView
            userId={userId}
            motionData={motionData}
            disable={isLoading}
            labels={labels}
            editable={true}
            sections={sections}
            onSectionUpdate={sectionUpdated}
            onLabelSelected={labelSelected}
          />
          <div>
            <Button className={classes.actionButtons} onClick={goToReview}>
              <Typography className={classes.buttonText}>Review Assessment</Typography>
            </Button>
          </div>
        </div>
      }

      {
        view === VIEWS.REVIEW &&
          <div>
            <AssessmentView
              userId={userId}
              motionData={motionData}
              labels={labels}
              sections={sections}
              editable={false}
              onLabelSelected={labelSelected}
            />
            <div className={classes.reviewButtons}>
              <Button 
                disabled={isLoading} 
                className={classes.actionButtons} 
                onClick={goToInProgress} >
                <Typography className={classes.buttonText}>Edit Assessment</Typography>
              </Button>

              <Button 
                disabled={isLoading} 
                className={classes.actionButtons} 
                onClick={publishAssessment} >
                <Typography className={classes.buttonText}>Publish Assessment</Typography>
              </Button>
            </div>
            {
              publishingAssessment &&
              <CircularProgress 
                className={classes.circularProgress} 
                size={45}
              />
            }
          </div>
      }

    </div>

    <ConfirmationDialog 
      title='Did You Save?'
      open={dialogOpen}
      onConfirm={closeDialogAndGoBack}
      onCancel={() => { setDialogOpen(false); }} >
        If you haven't saved, you will lose your progress if you go back.
        Are you sure you want to go back?
    </ConfirmationDialog>
  </>;
}
  
export default AnalystView;