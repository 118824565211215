import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  detailsContainer: {
    padding: '10px', 
    marginBottom: '10px'
  },
  boldText: {
    fontWeight: 'bold'
  }
}));