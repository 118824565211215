import minBy from 'lodash/minBy';
import MOTION_TYPES from '../constants/motionTypes.constants';

export const SENSOR_HZ = 200;
export const MILLISECONDS_IN_SECOND = 1000;

export function FrameNumToMs(num) {
  // converts a given frame number to the number of milliseconds within the swing
  return num / SENSOR_HZ * MILLISECONDS_IN_SECOND;
};

export function GetKeyFramesCoordinates(motion, analyzedMotionData, fullSwingMetrics) {
  // returns an array of key frames that have a display name and an x value 
  // that represents it's x coordinate within a graph
  let keyFrames;
  const firstFrame = analyzedMotionData.length > 0
    ? minBy(analyzedMotionData, x => x.frameNumber).frameNumber || 0
    : 0;
  if (motion.motionType === MOTION_TYPES.baseball) {
    keyFrames = [
      { name: 'HS', x: FrameNumToMs(fullSwingMetrics.heelStrikeFrame - firstFrame) },
      { name: 'FM', x: FrameNumToMs(fullSwingMetrics.firstMoveFrame - firstFrame) },
      { name: 'CON', x: FrameNumToMs(fullSwingMetrics.contactFrame - firstFrame) }
    ];
  } else if (motion.motionType === MOTION_TYPES.baseballPitch) {
    keyFrames = [
      { name: 'PPS', x: FrameNumToMs(fullSwingMetrics.peakSpeedFramePelvis - firstFrame) },
      { name: 'ML', x: FrameNumToMs(fullSwingMetrics.maxLoadFrame - firstFrame) },
      { name: 'BR', x: FrameNumToMs(fullSwingMetrics.ballReleaseFrame - firstFrame) }
    ];
  } else {
    keyFrames = [
      { name: 'ADR', x: FrameNumToMs(fullSwingMetrics.addressFrame - firstFrame) },
      { name: 'TOP', x: FrameNumToMs(fullSwingMetrics.topFrame - firstFrame) },
      { name: 'IMP', x: FrameNumToMs(fullSwingMetrics.contactFrame - firstFrame) }
    ];
  }

  return keyFrames.filter(frame => frame.x > firstFrame);
}