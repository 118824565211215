import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  tableCellContents: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',

    // disable highlighting on click
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none'
  },
  sortAscendingIcon: {
    WebkitTransform: 'scaleY(-1)',
    transform: 'scaleY(-1)'
  },
  hidden: {
    visibility: 'hidden'
  }
}));

export default useStyles;
