import { useCallback, useEffect, useState, useMemo } from 'react';
import { generatePath } from 'react-router';
import { getUserId } from 'utils/auth.utils';
import logger from 'js-logger';
import { CircularProgress } from '@material-ui/core';
import { GetTrainingPlan } from 'network/trainingPlanRequests';
import TrainingPlan from 'components/trainingPlan';
import { GetUser } from 'network/userRequests';
import useTrackPageEvents from 'services/useTrackPageEvents';
import { TRAINING_PLAN_SECTIONS as SECTIONS } from 'constants/trainingPlans.constants';
import { CATEGORIES } from 'services/analytics';

const TrainingPlanById = ( { match, history } ) => {
  const [user, setUser] = useState();
  const { trainingPlanId, planSection } = match.params;
  
  const userId = getUserId();
  const [trainingPlan, setTrainingPlan] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const pageName = SECTIONS[planSection]?.pageName;
  
  useTrackPageEvents({ 
    eventCategory: CATEGORIES.TRAINING_PLAN,
    userId: getUserId(), 
    pageName,
    userEmail: user?.emailAddress, 
    url: match?.url,
    miscData: useMemo(() => ({
      trainingPlanId: trainingPlanId
    }), [trainingPlanId])
  });

  const redirectToPlanSection = useCallback((planSection) => {
    const newUrl = generatePath(match.path, { trainingPlanId, planSection });
    history.push(newUrl);    
  }, [match.path, trainingPlanId, history]);
  
  useEffect(() => {
    const fetchTrainingPlan = async () => {
      setIsLoading(true);
      try {
        const user = await GetUser(userId);
        setUser(user);
        const trainingPlan = await GetTrainingPlan(trainingPlanId);
        setTrainingPlan(trainingPlan);
      } catch(e) {
        logger.error('Error on training plan by id', e);
      }
      
      setIsLoading(false);
    };
    fetchTrainingPlan();
  }, [userId, trainingPlanId]);

  if(isLoading) {
    return <CircularProgress />;
  }

  return <div>
    <TrainingPlan
      user={user}
      pageName={pageName}
      trainingPlan={trainingPlan} 
      activePlanSection={planSection} 
      setActivePlanSection={redirectToPlanSection}/>
  </div>;
};
export default TrainingPlanById;