import React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import palette from '../../../styles/palette';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  indicator: {
    backgroundColor: palette.blueGreen
  },
  header: {
    position: 'sticky',
    paddingRight: 'calc(2% + 48px)', // offset settings gear
    top: 0,
    paddingTop: '10px',
    zIndex: '100',
    backgroundColor: palette.subtleWhite
  }
}));

function PlayerDataTabs({ tabs, onTabChange, selectedTab, children }) {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <Tabs
        onChange={onTabChange} 
        value={selectedTab} 
        variant='scrollable'
        classes={{ indicator: classes.indicator }}
      >
        {tabs.map(route => <Tab key={route.value} label={route.label} value={route.value} />)}
      </Tabs>
      { children }
    </div>
  );
}

export default PlayerDataTabs;