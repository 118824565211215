import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import useInput from '../../../../../../utils/useInput';
import LengthLimitedTextField from '../../../../../../components/lengthLimitedTextField';
import StyledSelect from '../../../../../../components/styledSelect';
import { CAMERA_ANGLE, VIDEO_TYPE } from '../../../../../../constants/video.constants';
import Logger from 'js-logger';
import { UpdateVideo, UploadVideoToCloudAndVimeo } from '../../../../../../network/videoRequests';
import { updateEventUser } from '../../../../../../store/captureEvents';
import useStyles from './styles';

function VideoUploadDialog({ 
  open,
  captureEventResult, 
  eventUser,
  videoFile, 
  onClose,
  userId,
  defaultTitle
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [cameraAngle, setCameraAngle] = useState(CAMERA_ANGLE.faceOn);
  const [uploadComplete, setUploadComplete] = useState(false);
  const titleInput = useInput();

  const videoUrl = useMemo(() => videoFile != null 
    ? URL.createObjectURL(videoFile) 
    : null
  , [videoFile]);

  useEffect(() => {
    const setValue = titleInput.setValue;
    const clearError = titleInput.clearError;
    setValue(defaultTitle);
    clearError();
    setSubmitting(false);
    setError(false);
    setUploadProgress(0);
    setCameraAngle(CAMERA_ANGLE.faceOn);
    setUploadComplete(false);
  }, [videoFile, titleInput.clearError, titleInput.setValue, defaultTitle]);

  const { motionType, handedness, videos: existingVideos } = captureEventResult;

  const onSubmit = async () => {
    setSubmitting(true);
    setError(null);

    try {
      const videoPostBody = {
        userId,
        title: titleInput.value,
        type: VIDEO_TYPE.motion,
        captureEventResultId: captureEventResult.id,
        videoAttributes: {
          cameraAngle,
          handedness
        }
      };

      const existingVideoPatchRequests = existingVideos.map(v => {
        return UpdateVideo(v.id, [
          { op: 'test', value: v.captureEventResultId, path: '/captureEventResultId' },
          { op: 'replace', value: null, path: '/captureEventResultId' }
        ]);
      });

      // separate calls in case patch tests fail.
      await Promise.all(existingVideoPatchRequests);
      const newVideo = await UploadVideoToCloudAndVimeo(
        videoFile, 
        videoPostBody, 
        setUploadProgress);

      let eventUserWithNewVideos = {
        ...eventUser,
        captureEventResults: eventUser.captureEventResults.map(r => 
          r.id === captureEventResult.id 
            ? { ...r, videos: [newVideo] }
            : r)
      };

      setUploadComplete(true);
      setSubmitting(false);

      dispatch(updateEventUser(eventUserWithNewVideos));
    } catch (e) {
      setError('There was an error submitting this video, please refresh and try again.');
      setSubmitting(false);
      Logger.error(`Error manually uploading video for result ${captureEventResult.id}`, e);
    }
  };

  return <Dialog open={open} fullWidth maxWidth='md'>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <LengthLimitedTextField
            label='Title' 
            maxLength={100}
            disabled={submitting}
            fullWidth
            className={classes.fullWidthPadded}
            {...titleInput.bind}
          />
          
          <Typography>MotionType: <b>{motionType}</b></Typography>
          <Typography>Handedness: <b>{handedness}</b></Typography>
          
          <FormControl className={classes.fullWidthPadded}>
            <InputLabel id='camera_angle_select_label'>Camera Angle</InputLabel>
            <StyledSelect 
              value={cameraAngle} 
              onChange={e => setCameraAngle(e.target.value)}
              labelId='camera_angle_select_label'
              disabled={submitting}
            >
              <MenuItem value={CAMERA_ANGLE.faceOn}>{CAMERA_ANGLE.faceOn}</MenuItem>
              <MenuItem value={CAMERA_ANGLE.catchersView}>{CAMERA_ANGLE.catchersView}</MenuItem>
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item>
          <video width={500} height={300} src={videoUrl} controls />
        </Grid>
      </Grid>
      {submitting && <LinearProgress 
        color='primary'
        variant={uploadProgress === 0 ? 'indeterminate' : 'determinate'}
        value={uploadProgress}
      />}
    </DialogContent>
    <DialogActions>
      <Typography color='error'>
        {error}
      </Typography>
      <Button 
        variant='outlined'
        disabled={submitting} 
        onClick={onClose} 
        color={uploadComplete ? 'primary' : 'default'}
      >
        {uploadComplete ? 'Done' : 'Cancel'}
      </Button>
      {!uploadComplete && <Button 
        variant='outlined' 
        disabled={submitting} 
        onClick={onSubmit}
        color='primary'
      >
        {existingVideos.length > 0 ? 'Replace' : 'Upload'} Video
      </Button>}
    </DialogActions>
  </Dialog>;
}

export default VideoUploadDialog;
