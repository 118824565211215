import { makeStyles } from '@material-ui/core/styles';
import palette from '../../styles/palette';

export default makeStyles(theme => ({
  header: {
    backgroundColor: palette.deepBlue,
    boxShadow: 'inherit'
  },
  logo: {
    margin: '10px',
    width: '215px',
    height: '50px'
  },
  docsContainer: {
    marginTop: '70px',
    offset: theme.mixins.toolbar
  }
}));