import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(_ => ({
  container: {
    padding: '20px'
  },
  title: {
    marginRight: '25px'
  },
  clickable: {
    cursor: 'pointer'
  },
  stats: {
    display: 'inline',
    marginLeft: '10px'
  },
  subtitle: {
    padding: '5px'
  },
  boldFont: {
    fontWeight: 'bold'
  }
}));

export default useStyles;