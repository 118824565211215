import { makeStyles } from '@material-ui/styles'; 

export default makeStyles(_ => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    padding: '0px',
    paddingRight: '20px',
    maxWidth: '350px'
  },
  title: {
    marginBottom: '20px'
  },
  textField: {
    width: '100%'
  },
  label: {
    display: 'flex',
    alignItems: 'center'
  },
  labelContainer: {
    justifyContent: 'flex-end',
    display: 'inline-flex'
  }
}));