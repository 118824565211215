import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    width: '90vw',
    position: 'relative',
    height: '100%'
  },
  selectNumber: {
    marginBottom: 25
  },
  listContainer: {
    overflow: 'scroll',
    position: 'absolute',
    top: 0,
    bottom: 0
  },
  overflow: {
    overflow: 'auto'
  },
  listOfEvents: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20
  }
}));

export default useStyles;
