import React, { useMemo } from 'react';
import useStyles from './styles';

import { formatMonthDayTime } from './../../../../utils/formatting.utils';
import useScheduledContentDelivery from './../../useScheduledContentDelivery';
import { useSelector } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@material-ui/core';

const COLUMNS = [
  {
    name: 'Player Name',
    displayFn: ({ userId }, playersMap) => {
      const user = playersMap[userId];
      if (!user) {
        return <i>User Not Found</i>;
      }
      return `${user.firstName || ''} ${user.lastName || ''}`.trim();
    }
  },
  {
    name: 'Fulfillment Timestamp',
    displayFn: ({ fulfillmentTimestamp }) => {
      return formatMonthDayTime(fulfillmentTimestamp);
    }
  },
  {
    name: 'Status',
    displayFn: ({ status }) => status
  }
];

function DeliveryItemsDialog({
  delivery = null,
  onClose = () => {}
}) {
  const classes = useStyles();
  const open = Boolean(delivery);

  const [deliveryWithItems, loading, error] =
    useScheduledContentDelivery(delivery ? delivery.id : null);

  const players = useSelector(state => state.players);
  const playersMap = useMemo(() => {
    return players.reduce((acc, player) => {
      acc[player.userId] = player;
      return acc;
    }, {});
  }, [players]);

  const deliveryUsers = deliveryWithItems ?
    deliveryWithItems.scheduledContentDeliveryItems : [];

  return <>
    <Dialog open={open} fullWidth>
      <DialogContent>
        {
          loading && !error &&
          <div className={classes.loadContainer}>
            <CircularProgress />
          </div>
        }
        {
          error && !loading &&
          <div className={classes.error}>
            There was an error loading the content delivery information
          </div>
        }
        {
          !loading && !error && deliveryWithItems &&
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {
                    COLUMNS.map(({ name }) => {
                      return <TableCell key={name} className={classes.header}>{name}</TableCell>;
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  deliveryUsers.map((deliveryUser) => {
                    const { id } = deliveryUser;
                    return <TableRow key={id}>
                      {
                        COLUMNS.map(({ name, displayFn }) => {
                          return <TableCell key={name}>
                            { displayFn(deliveryUser, playersMap) }
                          </TableCell>;
                        })
                      }
                    </TableRow>;
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        }
      </DialogContent>
      <DialogActions>
        <Button 
          disabled={loading} 
          onClick={onClose} 
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </>;
}

export default DeliveryItemsDialog;