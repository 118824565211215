import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  linearProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  container: {
    position: 'relative',
    padding: '15px'
  }
}));

export default useStyles;
