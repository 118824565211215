import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../../../../styles/palette';

const borderStyle = `1px solid ${palette.fadedGrey}`;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderTop:  borderStyle
  },
  motion: {
    '&:hover': {
      backgroundColor: palette.superLightGrey,
      cursor: 'pointer'
    },
    minWidth: '200px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom:  borderStyle
  },
  motionInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  selected: {
    backgroundColor: palette.mediumGray,
    '&:hover': {
      backgroundColor: palette.mediumGray
    }
  },
  reviewed: {
    color: palette.success
  },
  extraInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '3px',
    '& > span': {
      fontSize: '12px',
      fontStyle: 'italic'
    }
  }
}));

export default useStyles;