const styles = {
  container: {
    padding: '10px'
  },
  divider: {
    margin: '10px'
  }
};

export default styles;
