import { makeStyles } from '@material-ui/core/styles';
import Pallete from '../../../styles/palette';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: Pallete.white,
    padding: '10px',
    borderRadius: '5px'
  },
  swingItem: {
    margin: '10px',
    padding: '10px',
    paddingBottom: '30px'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '40%',
    alignSelf: 'center'
  },
  playerDivider: {
    marginTop: '30px',
    marginBottom: '30px'
  },
  topFeed: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  closeIcon: {
    alignSelf: 'flex-end'
  }
}));