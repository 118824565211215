// Actions
export const SET_EVAL_REPORT = 'evalReport/set';

// Reducer
const initialState = {};

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case SET_EVAL_REPORT:
      return action.report;
    default:
      return state;
  }
};

// Action Creators
export function SetEvalReport(report) {    
  return { type: SET_EVAL_REPORT, report };
};
