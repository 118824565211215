import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  gridContainer: {
    height: '80vh'
  },
  templateInput: {
    margin: '10px 0'
  },
  videoListContainer: {
    height: '100%',
    overflow: 'auto'
  },
  selectedDrillsContainer: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  drillsLabel: {
    paddingRight: '5px'
  },
  selectedVideoTitleList: {
    textDecoration: 'italic'
  },
  selectCharacteristic: {
    width: '200px',
    marginBottom: '50px'
  }
}));

export default useStyles;
