import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import Palette from '../../../../styles/palette';

const StyledSlider = withStyles({ 
  mark: {
    backgroundColor: Palette.blueGray,
    height: 40,
    width: 1,
    marginTop: -10
  },
  /*
    We don't need a track since trimming makes it so 
    we are actually playing from
    the middle at times
  */
  track: {
    height: 0
  }

})(Slider);

export default StyledSlider;