import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Typography, LinearProgress, Grid } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import KinematicSequenceGraphDark from '../../../components/kinematicSequenceGraphDark';
import TimeSyncStatsGraph from '../../../components/timeSyncStatsGraph';
import { fetchAnalyzedData } from '../../../store/analyzedMotionData'; 
import { fetchTimeSyncStats } from '../../../store/timeSyncStats';
import { formatDateWithSeconds } from '../../../utils/formatting.utils';
import useStyles from './styles';

function SwingItem({ 
  motion,         // motion object to display a swing for
  previousMotion, // the previous motion to compare differences against
  className      // className to be applies to highest level card
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const analyzedData = useSelector(state => state.analyzedMotionData[motion.id]);
  const isFetchingAnalyzedData = !analyzedData || analyzedData.isFetching;
  const timeSyncStats = useSelector(state => state.timeSyncStats[motion.id]);
  const prevMotionId = previousMotion ? previousMotion.id : null;
  const prevTimeSyncStats = useSelector(state => state.timeSyncStats[prevMotionId]);
  const isFetchingTimeSyncStats = !timeSyncStats || timeSyncStats.isFetching;
  dispatch(fetchAnalyzedData(motion.id));
  dispatch(fetchTimeSyncStats(motion.id));

  const diffOffsets = getMaxMinDiffOffsets(timeSyncStats);
  const prevDiffOffsets = getMaxMinDiffOffsets(prevTimeSyncStats);

  function getMaxMinDiffOffsets(syncStats) {
    if (!syncStats || syncStats.isFetching || syncStats.error) return null;
    const minValues = syncStats.stats.minAdjustedOffsets
      .split(',')
      .map(value => Number(value));
    const maxValues = syncStats.stats.maxAdjustedOffsets
      .split(',')
      .map(value => Number(value));
    return maxValues.map((max, idx) => max - minValues[idx]);
  }

  function renderAvgOffset(idx) {
    if (!diffOffsets.length > idx) return;
    let elements = [
      <Typography key={`diff-${idx}`}>Diff: {diffOffsets[idx].toFixed(2)}</Typography>
    ];

    if (prevDiffOffsets && prevDiffOffsets.length > idx) {
      if (diffOffsets[idx] > prevDiffOffsets[idx]) {
        const diff = (prevDiffOffsets[idx] - diffOffsets[idx]).toFixed(2);
        elements.push(<TrendingUpIcon key={`icon-${idx}`}className={classes.green}/>);
        elements.push(<Typography key={`diffval-${idx}`}>{diff}</Typography>);
      } else {
        const diff = (diffOffsets[idx] - prevDiffOffsets[idx]).toFixed(2);
        elements.push(<TrendingDownIcon key={`icon-${idx}`} className={classes.red} />);
        elements.push(<Typography key={`diffval-${idx}`}>{diff}</Typography>);
      }
    }
    return (
      <Grid container justify='space-evenly'>
        {elements}
      </Grid>
    );
  }

  return (
    <Card className={className}>
      <Typography>{formatDateWithSeconds(motion.timestamp)}</Typography>
      {isFetchingAnalyzedData && <LinearProgress />}
      {!isFetchingAnalyzedData && 
        <KinematicSequenceGraphDark 
          motion={motion}
          analyzedFrames={analyzedData.frames}
          fullMotionMetrics={motion.fullMotionMetrics}
          showCrosshairs={false}
          style={{ 
            parent: { 
              background: { fill: 'white' }
            }
          }}
        />
      }
      {!isFetchingTimeSyncStats && !timeSyncStats.error && 
        <Grid container direction='row'>
          <Grid item className={classes.timeGraph}>
            <TimeSyncStatsGraph offsetList={timeSyncStats.stats.adjustedOffsetList[0]} title='Sensor 0'/>
            {renderAvgOffset(0)}
          </Grid>
          <Grid item className={classes.timeGraph}>
            <TimeSyncStatsGraph offsetList={timeSyncStats.stats.adjustedOffsetList[1]} title='Sensor 1'/>
            {renderAvgOffset(1)}
          </Grid>
          <Grid item className={classes.timeGraph}>
            <TimeSyncStatsGraph offsetList={timeSyncStats.stats.adjustedOffsetList[2]} title='Sensor 2'/>
            {renderAvgOffset(2)}
          </Grid>
          <Grid item className={classes.timeGraph}>
            <TimeSyncStatsGraph offsetList={timeSyncStats.stats.adjustedOffsetList[3]} title='Sensor 3'/>
            {renderAvgOffset(3)}
          </Grid>
        </Grid>
      }
    </Card>
  );
}

SwingItem.propTypes = {
  motion: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default SwingItem;