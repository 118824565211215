import React, { useEffect, useCallback, useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { isMobile } from '../../utils/device.utils';
import useIsMobile from '../../utils/useIsMobile';
import { history } from '../../store';
import useClasses from './styles';

function EmailVerificationSuccess({ loading = false, error = false }) {
  const isOnMobileDevice = useMemo(() => isMobile(), []);
  const fullScreenDialog = useIsMobile();
  const classes = useClasses();
  const goToKGoRedirect = useCallback(() => {
    history.push('/kgo-redirect?redirectFromEmailVerification=true');
  }, []);

  useEffect(() => {
    if (!isOnMobileDevice) return;
    const timeout = setTimeout(goToKGoRedirect, 6000);
    return () => clearTimeout(timeout);
  }, [goToKGoRedirect, isOnMobileDevice]);

  return <Dialog open fullScreen={fullScreenDialog}>
    <DialogContent className={classes.dialogContent}>
      {loading 
        ? <CircularProgress />
        : error
          ? <Typography>
            There was an error verifying your email address. 
            Please try again or reach out to support@k-vest.com to resolve.
          </Typography>
          : <>
            <CheckCircleIcon className={classes.icon} />
            <Typography>
              Thanks for verifying your email address!
            </Typography>
            {isOnMobileDevice && <Link onClick={goToKGoRedirect}>
              If you are not redirected, click here to open KGo if installed.
            </Link>}
          </>
      }
    </DialogContent>
  </Dialog>;
}

export default EmailVerificationSuccess;
