import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { products, PurchaseStatuses } from '../../../constants/purchases.constants';
import { CancelSubscription, UndoFutureSubscriptionChanges } from '../../../network/purchaseRequests';
import logger from 'js-logger';
import { 
  Button,
  Grid,
  Card, 
  Typography,
  Collapse,
  Divider
} from '@material-ui/core';
import HowToGetStarted from '../howToGetStarted';
import { history } from '../../../store';
import { formatMMDDYY } from '../../../utils/formatting.utils';
import PlanComponent from '../plan';
import useTrackingServices, { EVENT_NAMES } from '../../../services/useTrackingServices';
import useStyles from './styles';

const CurrentSubscription = ({
  activeSubscriptionDetails,
  outerSubscriptionId,
  indexOfPurchaseItem,
  onUndoComplete,
  upgradeProducts
}) => {
  const tracking = useTrackingServices();
  const [isLoading, setIsLoading] = useState(false);
  const [manageSubscription, setManageSubscription] = useState(false);
  const [cancelUndone, setCancelUndone] = useState(false);
  const [justCancelled, setJustCancelled] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const classes = useStyles();
  
  if(justCancelled) {
    return <div>  Success! Subscription will not auto renew.</div>;
  }
  
  if(activeSubscriptionDetails == null) { 
    return null;
  }
  
  if(isLoading) {
    return <CircularProgress />;
  }
  
  const cancelClicked = async () => {
    setIsLoading(true);
    setErrorMsg(null);
    try {
      await CancelSubscription(outerSubscriptionId, indexOfPurchaseItem);
      setJustCancelled(true);
      onUndoComplete();
      tracking.track(EVENT_NAMES.subscriptionCancellationScheduled);
    } catch(e) {
      logger.error('Error cancelling subscription', e);
      setErrorMsg('There was an error cancelling your subscription. Please try again.');
    }
    setIsLoading(false);
  };

  const undoCancel = async () => {
    setIsLoading(true);
    setErrorMsg(null);
    try {
      await UndoFutureSubscriptionChanges(outerSubscriptionId, indexOfPurchaseItem);
      setCancelUndone(true);
      onUndoComplete();
      tracking.track(EVENT_NAMES.subscriptionCancellationUndone);
    } catch (e) {
      logger.error('Error undoing cancellation request', e);
      setErrorMsg('There was an error attempting to undo your cancellation. Please try again.');
    }
    setIsLoading(false);
  };

  const toggleManage = () => setManageSubscription(prev => !prev);
    
  const { expirationTimestamp, status, product } = activeSubscriptionDetails;
  const { publicName } = products[product.id]; 
  const expirationDateFormatted = formatMMDDYY(expirationTimestamp);

  return <Grid container justify='center' alignItems='center' className={classes.container}>
    <Grid item xs={12} sm={8} md={8} lg={6}>
      <Card className={classes.card}>
        <Grid container alignItems='center' direction='column'>
          <Typography variant='h6'>
            You're subscribed to: 
          </Typography>
          <Typography variant='h4' className={classes.planName}>
            {publicName} ({product.billingPeriod }) plan
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <br />
          <HowToGetStarted />
          <Button className={classes.manageButton} color='primary' onClick={toggleManage}>
            Manage Subscription
          </Button>
          <Collapse in={manageSubscription}>
            <Divider className={classes.divider}/>
            <Grid container alignItems='center' direction='column' spacing={4} className={classes.manageContainer}>
              <Grid item>
                <Typography>
                  Your subscription {status === PurchaseStatuses.active ? 'renews ' : 'is set to end '} 
                  on {expirationDateFormatted}
                </Typography>
              </Grid>
              { status === PurchaseStatuses.active && 
                <Grid item>
                  <Button variant='outlined' color='primary' onClick={cancelClicked}>
                    Cancel your subscription
                  </Button> 
                </Grid>
              }
              {status === PurchaseStatuses.cancellationRequested && (cancelUndone 
                ? <Typography>Your subscription will no longer be canceled.</Typography>
                : <Grid item>
                  <Button variant='outlined' color='primary' onClick={undoCancel}>
                    Undo cancellation
                  </Button>
                </Grid>
              )}
              <Typography color='error'>{errorMsg}</Typography>
              
              <Grid container justify='space-around' spacing={6} className={classes.upgradeProducts}>
                {upgradeProducts.map(product => (
                  <Grid item xs={12} sm={10} md={5} key={product.id}>
                    <PlanComponent
                      {...product}
                      key={product.id}
                      currentlySubscribed
                      onClick={() => {
                        tracking.track(EVENT_NAMES.planClicked, {
                          Plan: product.name, 
                          Upgrade: true,
                          'Current Plan': activeSubscriptionDetails.product.name
                        });
                        history.push(`/purchase/${product.id}`);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Card>
    </Grid>
  </Grid>;
};

export default CurrentSubscription;
