import React, { useState, useEffect } from 'react';
import round from 'lodash/round';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { TitleFormatter } from '../../../../../constants/DeviceKeys.constants';
import useStyles from './styles';

const HEEL_LOAD = 'heelLoad';
const HEEL_LOAD_OPTIONS = ['toe', 'ball', 'whole', 'heel'];

function DataInput({ motionId, data, keys, title, onChange, saving }) {
  const [ inputData, setInputData ] = useState(data != null ? data : { motionId });
  const classes = useStyles();

  useEffect(() => {
    setInputData(data != null ? data : { motionId });
  }, [motionId, data]);

  return <div>
    <Typography align='center' className={classes.title}>{title}</Typography>
    <Grid container spacing={1} justify='center'>
      {keys.map(key => <Grid item key={`input-${key}`}>
        <TextField disabled={saving}
          select={key === HEEL_LOAD}
          className={classes.textField}
          label={TitleFormatter[key] ? TitleFormatter[key](key) : TitleFormatter.default(key)}
          variant='outlined'
          type={key !== HEEL_LOAD ? 'number' : null}
          inputMode={key !== HEEL_LOAD ? 'numeric' : null}
          value={inputData[key] == null ? '' : round(inputData[key], 3) || inputData[key]}
          onChange={({ target }) => onChange({ ...inputData, [key]: target.value }) &&
           setInputData(prev => ({ ...prev, [key]: target.value }))}
          InputLabelProps={{ shrink: true }}
        >
          {key === HEEL_LOAD && HEEL_LOAD_OPTIONS.map(option => 
            <MenuItem key={option} value={option}>{option}</MenuItem>
          )}
        </TextField>
      </Grid>)}
    </Grid>
  </div>;
};

export default DataInput;