import React from 'react';
import { useSelector } from 'react-redux';
import { LinearProgress, Box } from '@material-ui/core';
import ConfigurableTimeseriesGraph from '../../../components/configurableTimeseriesGraph';
import { GraphableMetrics } from '../../playerDataTabs/swingDetails/swingDetails.constants';
import { useMotionsWithAnalyzedData } from './timeseries.utils';
import useStyles from './styles';
import useMotionsWithTag from '../useMotionsWithTag';

function TimeseriesGraph() {
  const { hoveredMotionId } = useSelector(state => state.dataAnalysis);
  const motions = useMotionsWithTag();
  const [motionsWithAnalyzedData, loading] = useMotionsWithAnalyzedData(motions);
  const classes = useStyles();

  return <Box position='relative'>
    {loading && <LinearProgress className={classes.linearProgress} />}
    <ConfigurableTimeseriesGraph 
      motions={motionsWithAnalyzedData}
      graphableMetrics={GraphableMetrics}
      activeMotionId={hoveredMotionId}
      getMotionColor={motion => motion.tag?.color}
    />
  </Box>;
}

export default TimeseriesGraph;
