import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import useStyles from './styles';
import LOGO_BLACK from 'assets/pgtech-logo-black.png';
import LOGO_WHITE from 'assets/pgtech-logo-white.png';
import VideoPlayer from 'components/videoPlayer';
import CloseIcon from '@material-ui/icons/Close';
import ThumbnailPlaaceholder from 'assets/slowmo_placeholder_thumbnail.png';
import TypicalSequence from './typicalSequence';
import { round } from 'lodash';
import { SPORTS } from 'constants/modes.constants';
import classNames from 'classnames';

const CLASS_AVG_EV_MPH = {
  2022: 81,
  2023: 80,
  2024: 79,
  2025: 76,
  2026: 73,
  2027: 69,
  2028: 63,
  2029: 58,
  2030: 52
};

const CLASS_YEAR_EV_MPH_SOFTBALL = {
  2023: 67,
  2024: 66,
  2025: 64,
  2026: 62,
  2028: '--',
  2029: 56,
  2030: 50
};

const GetEVMap = (sport) => {
  if (sport === SPORTS.SOFTBALL) {
    return CLASS_YEAR_EV_MPH_SOFTBALL;
  } else {
    return CLASS_AVG_EV_MPH;
  }
};

function LabelAndValue({ label, unit, children }) {
  const classes = useStyles();
  return <div className={classes.labelAndValue}>
    <Typography variant='h6' className={classes.label}>{ label }</Typography>
    <div className={classes.valueContainer}>
      <Typography variant='h3' className={classes.label}>{ children }</Typography>
      <Typography variant='h4' className={classes.label}>{ unit }</Typography>
    </div>
  </div>;
};

const PerfectGameProfileExample = ({
  onBack = () => {}, 
  captureEventResult = {},
  classYear,
  sport,
  evPercentileByClass,
  captureEvent = {}
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const logoImg = theme.palette.type === 'light' ? LOGO_BLACK : LOGO_WHITE;

  const { 
    ballspeedMph,
    playerPercentiles
  } = captureEventResult;

  const swingEfficiency = playerPercentiles?.bodySpeed;

  const handedness = captureEventResult.handedness;
  const maxEV = ballspeedMph != null ? round(ballspeedMph) : null;
  const eventName = captureEvent.name;

  const videoUrl = captureEventResult.videos[0]?.url;
  return <div className={classes.container}>
    <Grid container>
      <IconButton
        className={classes.sliderDemoCancel}
        onClick={onBack}
        aria-label='close'
      >
        <CloseIcon />
      </IconButton>
      <Grid item xs={12}>
        <img alt='logo' src={logoImg} className={classes.logo} />
      </Grid>
      <Grid className={classes.rankings} container>
        <Grid container className={classes.rankingsHeader}>
          <Grid item xs={12}>
            <Typography variant='h5'>
              PERCENTILE RANKINGS
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.classInfo}>
            <Typography variant='h5' className={classes.classOf}>
              CLASS OF {classYear}
            </Typography>
            <Typography variatn='h6'>
              IN THE RECORDED YEAR
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.evStatsHeaders}>
          <Grid item xs={3}>
            Categories
          </Grid>
          <Grid item xs={3}>
            Top Result
          </Grid>

          <Grid item xs={3}>
            Class Avg
          </Grid>
          <Grid item xs={3}>
            Percentile
          </Grid>
        </Grid>

        <Grid container className={classes.evStats}>
          <Grid item xs={3}>
            Exit Velo
          </Grid>
          <Grid item xs={3} className={classes.evLabel}>
            {maxEV ?? '--'}
            <Typography className={classes.mph}>MPH</Typography>
          </Grid>

          <Grid item xs={3} className={classes.evLabel}>
            {GetEVMap(sport)[classYear]}
            <Typography className={classes.mph}>MPH</Typography>
          </Grid>
          <Grid item xs={3}>
            {evPercentileByClass}%
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.title}>
        <Typography variant='h4' className={classes.eventName}>{eventName}</Typography>
        <Typography variant='h6' className={classes.handednessText}>
          {handedness} Handed Hitting
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={1} className={classes.playerData}>
      <Grid item xs={6} className={classes.motionData}>
        <div className={classes.motionData}>
          <div className={classNames(classes.motionDataSection, classes.statsContainer)}>
            <LabelAndValue label='Swing Efficiency&trade;' unit={swingEfficiency ? '%' : ''}>
              { swingEfficiency ?? '---' }
            </LabelAndValue>
            <LabelAndValue label='Exit Velo' unit={maxEV ? 'mph' : ''}>
              {maxEV ?? '---'}
            </LabelAndValue>
          </div>
          <div className={classes.motionDataSection}>
            <Typography variant='h6' className={classes.label}>Signature Sequence:</Typography>
            <Typography variant='h6'>
              The order in which each body segment reaches its peak speed
            </Typography>
            <div className={classes.sequenceContainer}>
              <TypicalSequence captureEventResult={captureEventResult} />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.videoContainer}>
          {videoUrl 
            ? <VideoPlayer src={videoUrl} title='' />
            : <img alt='video_thumbnail' src={ThumbnailPlaaceholder} className={classes.edgerThumbnail}/>}
        </div>
      </Grid>
    </Grid>
  </div>;
};

export default PerfectGameProfileExample;