import palette from '../../styles/palette';
import green from '@material-ui/core/colors/green';

const styles = () => ({
  flex: {
    display: 'flex'
  },
  headerIconButton: {
    marginLeft: '2px',
    color: palette.gray
  },
  headerIconButtonEnabled: {
    cursor: 'pointer',
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.lightenedDeepBlue
    }
  },
  footerIconButton: {
    color: palette.gray,
    padding: '5px',
    borderRadius: '3px',
    margin: '0 3px'
  },
  footerIconButtonEnabled: {
    cursor: 'pointer',
    color: '#212121',
    '&:hover': {
      backgroundColor: palette.mediumGray
    }
  },
  rightAlignFlex: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  paper: {
    width: '700px',
    backgroundColor: palette.deepBlue,
    borderRadius: '8px 8px 0 0'
  },
  rootTemplateOpen: {
    width: '500px'
  },
  buttonText: {
    marginLeft: '10px'
  },
  dialogTitle: {
    backgroundColor: palette.deepBlue,
    padding: 0
  },
  dialogContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  contentFullContainer: {
    position: 'relative'
  },
  fab: {
    marginBottom: '25px'
  },
  header: {
    color: 'white', 
    display: 'flex', 
    padding: '10px' 
  },
  footer: {
    display: 'flex', 
    padding: '10px', 
    alignItems: 'center'
  },
  textFieldContainer: {
    width: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    flexDirection: 'row'
  },
  fullContainer: {
    position: 'relative'
  },
  templateOpen: {
    backgroundColor: palette.mediumGray
  },
  fullDrawer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 1
  },
  fullRightDrawer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 1
  },
  collapse: {
    width: '100%',
    backgroundColor: 'white'
  },
  snackbar: {
    backgroundColor: green[800]
  },
  snackbarFail: {
    backgroundColor: '#f44336'
  },
  paddedIcon: {
    marginRight: '10px'
  },
  multiPartText: {
    marginRight: '15px',
    color: palette.wateredDownMustard
  },
  trainingPlanIcon: {
    marginRight: '20px'
  },
  mildYellowColor: {
    color: palette.wateredDownMustard
  }
});

export default styles;
