import React from 'react';
import {
  Grid,
  TextField,
  ListItem,
  ListItemText,
  Button,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import usePagedEndpoint from '../../../utils/usePagedEndpoint';
import { GetAllPaginatedUsers } from '../../../network/userRequests';
import ProgressButton from '../../../components/progressButton';

const PAGE_SIZE = 20;

function UserSelection({ 
  setUser, 
  setSearchFilters, 
  searchFilters,
  firstInput, 
  lastInput, 
  emailInput 
}) {
  const { 
    items, 
    hasMore, 
    hasPrev,
    isFetching
  } = usePagedEndpoint(
    GetAllPaginatedUsers, 
    searchFilters.searchFilters, 
    PAGE_SIZE, 
    searchFilters.page);

  const onSearch = e => {
    e.preventDefault();
    setSearchFilters({
      page: 1,
      searchFilters: {
        email: emailInput.value,
        firstName: firstInput.value,
        lastName: lastInput.value
      }
    });
  };

  const onNextPage = () => setSearchFilters(prev => ({ ...prev, page: prev.page + 1 }));
  const onPreviousPage = () => setSearchFilters(prev => ({ ...prev, page: prev.page - 1 }));

  const onClear = () => {
    firstInput.clearValue();
    lastInput.clearValue();
    emailInput.clearValue();
  };

  return <form onSubmit={onSearch}>
    <Grid container spacing={1} alignItems='flex-end'>
      <Grid item xs={3}>
        <TextField {...firstInput.bind} label='First' />
      </Grid>
      <Grid item xs={3}>
        <TextField {...lastInput.bind} label='Last' />
      </Grid>
      <Grid item xs={3}>
        <TextField {...emailInput.bind} label='Email' />
      </Grid>
      <Grid item xs={3}>
        <IconButton onClick={onClear}>
          <CloseIcon fontSize='small' />
        </IconButton>
        <ProgressButton 
          variant='contained' 
          color='primary'
          showProgress={isFetching}
          type='submit'
        >
          Search
        </ProgressButton>
      </Grid>
      
      <Grid container item xs={6} justify='flex-start'>
        {hasPrev && <Button disabled={isFetching} onClick={onPreviousPage} variant='outlined'>
          Previous
        </Button>}
      </Grid>
      <Grid container item xs={6} justify='flex-end'>
        {hasMore && <Button disabled={isFetching} onClick={onNextPage} variant='outlined'>
          Next
        </Button>}
      </Grid>

      <Grid container item xs={12} spacing={1}>
        {items.map(user => <Grid item xs={3} key={user.userId}>
          <ListItem button onClick={() => setUser(user)}>
            <ListItemText 
              primary={`${user.firstName} 
              ${user.lastName}`} 
              secondary={user.emailAddress} 
              secondaryTypographyProps={{ style: { overflowWrap: 'anywhere' }}}
            />
          </ListItem>
        </Grid>)}
      </Grid>
    </Grid>
  </form>;
}

export default UserSelection;