import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  newTemplateButtonContainer: {
    width: '100%', 
    display: 'flex', 
    justifyContent: 'center', 
    padding: '10px'
  }
}));

export default useStyles;