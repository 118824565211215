import React from 'react';
import PropTypes from 'prop-types';
import LineGraph from '../lineGraph';
import MotionTypes from '../../constants/motionTypes.constants';
import MotionUtils from '../../utils/motion.utils';
import {
  ROTATIONAL_VELOCITY_FRAME_MAP,
  SEGMENT_TO_LINE_OPTIONS
} from './constants';
import { GetKeyFramesCoordinates, FrameNumToMs } from '../../utils/motionGraph.utils';


class KinematicSequenceGraphDark extends React.Component {
  _getFrameMapsForMotionType = motionType => {
    // temporary comment out for perfect game
    // if (motionType === MotionTypes.baseballPitch) {
    //   return PITCHING_FRAME_MAPS;
    // }
    let bodyParts = MotionUtils.getBodyPartsForMotionType(motionType);
    return bodyParts.map(part => ROTATIONAL_VELOCITY_FRAME_MAP[part]);
  }

  _getLinesFromAnalyzedData = (motionType, analyzedFrames) => {
    let frameMaps = this._getFrameMapsForMotionType(motionType);
    
    let linesBySegment = Object.assign({}, ...frameMaps.map(map => ({
      [map.bodySegment]: { 
        name: map.bodySegment, 
        data: [],
        color: SEGMENT_TO_LINE_OPTIONS[map.bodySegment].color 
      } 
    })));
    
    analyzedFrames.forEach((frame, idx) => {
      frameMaps.forEach(map => {
        let value = frame[map.propertyName];
        if (value) {
          linesBySegment[map.bodySegment].data.push({ x: FrameNumToMs(idx), y: value });
        }
      });
    });
    return Object.values(linesBySegment);
  }

  _getTickValuesForMotion = (motionType) => {
    // different motion types will have different expected ranges for the velocities
    if (!this.props.showDetailedTicks || motionType !== MotionTypes.baseball) {
      // let the graphing library handle which ticks to show
      return null;
    } else { 
      return [-500, -250, 0, 250, 500, 750, 1000, 1250, 1500, 1750, 2000, 2250];
    }
  }

  getCroppedLines = (verticalLines, lines) => {
    // We crop based on the earliest marker frame, back by a few hundred ms.
    const markerFramesInMs = verticalLines.map( marker => marker.x);
    const minMarkerFrameInMs = Math.min.apply(null, markerFramesInMs);

    const msBuffer = 250; // We show this many ms before first marker:
    const msPerFrame = 5;
    const cropFrameStart = Math.floor(Math.max( (minMarkerFrameInMs - msBuffer) / msPerFrame, 0));
    return lines.map((line) => {
      return { ...line, data: line.data.slice(cropFrameStart) };
    });
  }

  render() {
    const {
      analyzedFrames,
      fullMotionMetrics, 
      motion,
      showLegend = true,
      showCrosshairs 
    } = this.props;

    if (analyzedFrames == null || fullMotionMetrics == null) {
      return <LineGraph />; 
    }
    
    const uncroppedLines = this._getLinesFromAnalyzedData(motion.motionType, analyzedFrames);
    const verticalLines = GetKeyFramesCoordinates(motion, analyzedFrames, fullMotionMetrics);
    const croppedLines = this.getCroppedLines(verticalLines, uncroppedLines );
    const yTickValues = this._getTickValuesForMotion(motion.motionType); 
    const { playerHandedness } = fullMotionMetrics;
    // We just pass the props coming from above down the pipe:
    return (
      <LineGraph        
        dataLines={croppedLines}
        verticalLines={verticalLines} 
        title={`${playerHandedness}-Handed Kinematic Sequence (Deg/s vs Time in ms)`}
        showLegend={showLegend}
        showCrosshairs={showCrosshairs}
        yTickValues={yTickValues}
        {...this.props}
      />
    );
  }
}

KinematicSequenceGraphDark.propTypes = {
  motion: PropTypes.object,
  analyzedFrames: PropTypes.arrayOf(PropTypes.object),
  fullMotionMetrics: PropTypes.object,
  showDetailedTicks: PropTypes.bool
};

export default KinematicSequenceGraphDark;
