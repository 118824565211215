const styles = {
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fullHeight: { height: '100%' },
  headerContainer: {
    alignItems: 'center',
    height: '90px',
    justifyContent: 'space-between'
  },
  downloadBtn: {
    backgroundColor: 'lightgreen',
    width: '160px',
    marginLeft: '15px'
  },
  tableContainer: {
    boxShadow: '0px -1px 3px gray', 
    backgroundColor: 'white', 
  
    bottom: 0, 
    right: 0, 
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px'
  },
  rightActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
};

export default styles;