import { useState, useEffect } from 'react';
import axios from 'axios';
import Logger from 'js-logger';

const useNetworkRequest = (initialData, requestName, request) => {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchData() {
      setLoading(true);
      try {
        const fetchedData = await request(cancelToken);
        setData(fetchedData);
      } catch (ex) {
        if (axios.isCancel(ex)) {
          return;
        }
        Logger.error('Error making request ' + requestName, ex);
        setError(true);
      }
      setLoading(false);
    }
    fetchData();
    return cancelToken.cancel;
  }, [request, requestName]);

  return [data, loading, error, setData];
};

export default useNetworkRequest;