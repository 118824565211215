import palette from '../../../../../styles/palette';

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'italic',
    fontSize: 'larger',
    padding: '20px'
  },
  circularProgress: {
    color: palette.almostBlackBlue
  },
  itemContainer: {
    margin: '10px',
    display: 'flex'
  },
  itemText: {
    color: palette.darkGray,
    fontStyle: 'italic',
    padding: '0 10px'
  }
};

export default styles;
