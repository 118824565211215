export const getDimensionsAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (async function(theFile) { 
      var img = new Image();      
      img.src = theFile.target.result;
  
      img.onload = function() {
        resolve([this.width, this.height]);
      };
    });
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};