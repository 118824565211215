import { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import log from 'js-logger';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import MotionUtils from '../../../utils/motion.utils';
import { GetMotionsForUserInCurrentOrg } from '../../../network/motionRequests';

import { 
  FULL_MOTION_METRICS, 
  LAUNCH_MONITOR, 
  HITTING_DEVICE_SENSOR, 
  FORCE_PLATE, 
  KEY_FRAME_DATA
} from '../../../constants/motionSubresources.constants';
import { LOADING, ERROR, COMPLETE } from './rechartsGraph.utils';
import { GetMobilityScreens } from '../../../network/userRequests';
import { getMobilityScreenForDate } from '../../../utils/mobilityScreens.utils';

function useUsersMotionData(
  providedUsers, 
  numMotions, 
  laterThan,
  earlierThan,
  canAccessThirdPartyData
) {
  const [ users, setUsers ] = useState(providedUsers);
  const initialUserData = useMemo(() => users
    .reduce((acc, user) => ({ ...acc, [user.userId]: { user, status: LOADING }}), {}), [users]);
  const [ userData, setUserData ] = useState(initialUserData);

  // Only setting stored userIds when the provided Ids change so that effect doesn't get run 
  // unnecessarily (shallow compare always returns true for new instance of array w/same values)
  const sortedUsers = sortBy(users, 'userId');
  const sortedProvidedUsers = sortBy(providedUsers, 'userId');
  if (!isEqual(sortedUsers, sortedProvidedUsers)) {
    setUsers(providedUsers);
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    
    async function fetchMobilityData(userId) {
      try {
        return await GetMobilityScreens(userId, cancelToken);
      } catch (e) {
        if (axios.isCancel(e)) throw e;
        log.error(`Unable to load mobility data for ${userId}: ${e}`);
        return [];
      }
    }

    async function fetchData(user) {
      const { userId } = user;
      try {
        let subresources = [ FULL_MOTION_METRICS, LAUNCH_MONITOR, KEY_FRAME_DATA ];
        if (canAccessThirdPartyData) {
          subresources = [...subresources, HITTING_DEVICE_SENSOR, FORCE_PLATE];
        }

        let [motions, mobilityScreens] = await Promise.all([
          GetMotionsForUserInCurrentOrg(userId, {
            count: numMotions,
            laterThan,
            earlierThan,
            subresources
          }, cancelToken),
          fetchMobilityData(userId)
        ]);

        const data = motions.items.map(motion => ({
          timestamp: motion.timestamp,
          date: new Date(new Date(motion.timestamp).toDateString()).valueOf(),
          name: user.firstName,
          userId: user.userId,
          ...motion.fullMotionMetrics,
          ...motion.launchMonitorData,
          ...motion.hittingDeviceSensor,
          ...motion.forcePlate,
          ...MotionUtils.constructKeyFramePositionsMap(motion.keyFrameData),
          ...getMobilityScreenForDate(motion.timestamp, mobilityScreens)
        }));
        
        setUserData(prev => ({ 
          ...prev, 
          [userId]: { ...prev[userId], status: COMPLETE, payload: data }
        }));
      } catch (e) {
        if (axios.isCancel(e)) return;
        setUserData(prev => ({ 
          ...prev, 
          [userId]: { ...prev[userId], status: ERROR }
        }));
      }
    }

    async function fetchDataForUsers() {
      setUserData(initialUserData);
      await Promise.all(users.map(user => fetchData(user)));
    }
    fetchDataForUsers();

    return cancelToken.cancel;
  }, [users, initialUserData, numMotions, laterThan, earlierThan, canAccessThirdPartyData]);

  return userData;
};

export default useUsersMotionData;
