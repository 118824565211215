import palette from '../../../../styles/palette';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  miscInfo: {
    marginTop: '20px',
    color: palette.almostBlackBlue
  },
  header: {
    marginBottom: '18px',
    width: '100%',
    textAlign: 'center'
  },
  contentArea: {
    height: '300px', // magic number
    padding: '20px',
    background: palette.white,
    borderRadius: '4px'
  },
  text: {
    whiteSpace: 'pre-wrap'
  }
}));

export default useStyles;