import React, { useState, useMemo, useEffect } from 'react';
import { 
  Button, 
  Checkbox, 
  IconButton, 
  List, 
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import ConfirmationDialog from 'components/dialogs/confirmationDialog';
import SearchBar from 'components/searchBar';
import useInput from 'utils/useInput';
import useStyles from './styles';

const AssignedDrillCountTextField = ({ assignedDrill, idx, property, ...props }) => <TextField
  value={assignedDrill[property]} 
  type='number'
  inputProps={{ min: 1 }}
  variant='outlined'
  label={property}
  size='small'
  {...props}
/>;

function AssignedDrillsList({ 
  selectedNote, 
  drills,
  onAssignedDrillChange,
  onAssignedDrillRemoved,
  onAssignedDrillsAdded
}) {
  const classes = useStyles();

  const [selectingDrills, setSelectingDrills] = useState(false);
  const [selectedDrillIds, setSelectedDrillIds] = useState([]);
  const { 
    value: searchInputValue, 
    clearValue: clearSearchInput, 
    bind: searchInputBind 
  } = useInput();

  const filteredDrills = sortBy(drills.filter(
    drill => drill.name.toLowerCase().includes(searchInputValue.trim().toLowerCase())), 'name');
  const drillsById = useMemo(() => keyBy(drills, 'id'), [drills]);

  const existingDrillIds = new Set(selectedNote.assignedDrills.map(x => x.drillId));

  useEffect(() => {
    if (selectingDrills) {
      setSelectedDrillIds([]);
      clearSearchInput();
    }
  }, [selectingDrills, clearSearchInput]);

  const onDrillCheckboxChange = drillId => e => {
    const { checked } = e.target;
    setSelectedDrillIds(prev => checked 
      ? [...prev, drillId]
      : prev.filter(x => x !== drillId));
  };

  const onDrillsAdded = () => {
    onAssignedDrillsAdded(selectedDrillIds.map(drillId => ({
      drillId,
      reps: drillsById[drillId].defaultReps,
      sets: drillsById[drillId].defaultSets
    })));
    setSelectingDrills(false);
  };

  return <div>
    <Button color='primary' startIcon={<AddIcon />} onClick={() => setSelectingDrills(true)}>
      Add Drills
    </Button>

    <List dense>
      {selectedNote.assignedDrills.map((assignedDrill, idx) => <ListItem
        key={assignedDrill.id ?? `assigned-drill-${selectedNote.sequenceOrder}-${idx}`}
      >
        <Paper className={classes.listItemPaper}>
          <Typography className={classes.drillName}>
            {idx + 1}) {drillsById[assignedDrill.drillId].name}
          </Typography>
          <AssignedDrillCountTextField 
            assignedDrill={assignedDrill}
            idx={idx}
            property='sets'
            onChange={e => onAssignedDrillChange(e, idx, 'sets')}
            className={classes.assignedDrillCountTextField}
          />
          <AssignedDrillCountTextField 
            assignedDrill={assignedDrill}
            idx={idx}
            property='reps'
            onChange={e => onAssignedDrillChange(e, idx, 'reps')}
            className={classes.assignedDrillCountTextField}
          />
          <IconButton onClick={() => onAssignedDrillRemoved(idx)}>
            <CloseIcon />
          </IconButton>
        </Paper>
      </ListItem>)}
    </List>

    <ConfirmationDialog 
      open={selectingDrills}
      onCancel={() => setSelectingDrills(false)}
      onConfirm={onDrillsAdded}
      title={<SearchBar {...searchInputBind} onClear={clearSearchInput} />}
    >
      <List>
        {filteredDrills.map(drill => <ListItem key={drill.id} divider>
          <ListItemIcon>
            <Checkbox 
              edge='start'
              tabIndex={-1}
              onChange={onDrillCheckboxChange(drill.id)}
              checked={existingDrillIds.has(drill.id) || selectedDrillIds.includes(drill.id)}
              disabled={existingDrillIds.has(drill.id)}
            />
          </ListItemIcon>
          <ListItemText 
            primary={drill.name} 
            secondary={`Default sets: ${drill.defaultSets}, `
              + `reps: ${drill.defaultReps} ${drill.repUnits ?? ''}`}
          />
        </ListItem>)}
      </List>
    </ConfirmationDialog>
  </div>;
}

export default AssignedDrillsList;
