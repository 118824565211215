import React, { useCallback } from 'react';
import { 
  LinearProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  Typography 
} from '@material-ui/core';
import camelCase from 'lodash/camelCase';
import classNames from 'classnames';
import BodyParts from 'constants/bodyParts.constants';
import { GetTrainingPlans } from 'network/trainingPlanRequests';
import useNetworkRequest from 'network/useNetworkRequest';
import { getOrganizationId } from 'utils/auth.utils';
import { formatMMDDYY } from 'utils/formatting.utils';
import { SEGMENT_FOCUS } from 'constants/trainingPlans.constants';
import useStyles from './styles';
import { PERCENTILE_THRESHOLDS } from '../percentiles.constants';

function PastTrainingPlans({ userId, className }) {
  const classes = useStyles();
  const plansRequest = useCallback(cancelToken => 
    GetTrainingPlans({
      userId,
      organizationId: getOrganizationId(),
      subresources: ['InitialCaptureEventResult', 'Template']
    }, cancelToken), 
  [userId]);
  const [trainingPlans, loading] = useNetworkRequest([], 'GetTrainingPlans', plansRequest);

  const PercentileCell = ({ result, segment }) => {
    const speedKey = camelCase(segment) + 'Speed';
    const focusKey = camelCase(segment) + 'Focus';
    const focus = result?.[focusKey] === SEGMENT_FOCUS.primary;
    const percentile = result?.playerPercentiles?.[speedKey];

    if (percentile == null) {
      return <TableCell>N/A</TableCell>;
    }

    let className = percentile < PERCENTILE_THRESHOLDS.LOWER 
      ? classes.fail
      : percentile < PERCENTILE_THRESHOLDS.UPPER
        ? classes.needsWork
        : classes.pass;

    return <TableCell align='center' className={focus ? classes.focus : null}>
      <Typography align='center' className={classNames(className, classes.percentileCell)}>
        {percentile}%
      </Typography>
    </TableCell>;
  };

  return <div className={className}>
    <Typography variant='h5'>Past Training Plans</Typography>
    {loading && <LinearProgress />}

    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Template</TableCell>
          <TableCell>Pelvis</TableCell>
          <TableCell>Torso</TableCell>
          <TableCell>Arm</TableCell>
          <TableCell>Hand</TableCell>
          <TableCell>EV</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {trainingPlans.map(({ 
          id, 
          creationTimestamp, 
          template, 
          initialCaptureEventResult 
        }) => <TableRow key={id}>
          <TableCell>{formatMMDDYY(creationTimestamp)}</TableCell>
          <TableCell>{template?.name}</TableCell>
          <PercentileCell result={initialCaptureEventResult} segment={BodyParts.pelvis} />
          <PercentileCell result={initialCaptureEventResult} segment={BodyParts.torso} />
          <PercentileCell result={initialCaptureEventResult} segment={BodyParts.upperArm} />
          <PercentileCell result={initialCaptureEventResult} segment={BodyParts.hand} />
          <TableCell>{initialCaptureEventResult?.ballspeedMph}</TableCell>
        </TableRow>)}
      </TableBody>
    </Table>
  </div>;
}

export default PastTrainingPlans;