import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { formatDateWithWrittenMonth } from '../../../utils/formatting.utils';
import VideoStatus from '../../../constants/video.constants';

function VideoListItem({ 
  video, 
  selected, 
  onToggleSelection, 
  onPlayPreview,
  displayDate
}) {
  const [ isHovered, setIsHovered ] = useState(false);

  const videoAvailable = video.status === VideoStatus.available;
  const title = videoAvailable
    ? video.title
    : video.title + '*'; 

  const tooltipText = videoAvailable
    ? ''
    : 'This video is still processing. If attached, the action plan will be sent as soon as it finishes.';

  return (
    <ListItem key={video.id}>
      <ListItemIcon>
        <Checkbox 
          edge='start'
          color='primary'
          checked={selected}
          onClick={() => onToggleSelection(video.id)}
        />
      </ListItemIcon>
      <ListItem 
        button 
        onMouseEnter={() => setIsHovered(true)} 
        onMouseLeave={() => setIsHovered(false)}
      >
        <ListItemIcon>
          <IconButton edge='start' onClick={onPlayPreview}>
            {isHovered ? <PlayCircleOutlineIcon /> : <PlayArrow />}
          </IconButton>
        </ListItemIcon>
        <Tooltip title={tooltipText} placement='top'>
          <ListItemText 
            id='titleId' 
            primary={title}
            secondary={displayDate && formatDateWithWrittenMonth(video.creationTimestamp)}
            onClick={onPlayPreview} 
          />
        </Tooltip>
      </ListItem>
    </ListItem>
  );
}

VideoListItem.propTypes = {
  video: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onToggleSelection: PropTypes.func.isRequired,
  onPlayPreview: PropTypes.func.isRequired,
  displayDate: PropTypes.bool
};

export default VideoListItem;