import GoogleDocIFrame from 'components/googleDocIFrame';
import useStyles from './styles';

const IFrameHack = () => {
  const classes = useStyles();
  return <div className={classes.holder}>
    <GoogleDocIFrame 
      isSpreadsheet={ false }
      url={ 'https://docs.google.com/document/d/1Jc5OGiFyYEZ8XS8eDLtYs1lFwAW10o7XdKz_THH8jxg/preview' }/>
  </div>;
};

export default IFrameHack;