import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { 
  Box,
  Button,
  Collapse,
  Grid,
  Paper,
  Typography,
  IconButton,
  Chip,
  Divider
} from '@material-ui/core';
import { 
  ExpandMore, 
  ExpandLess, 
  Edit,
  Delete,
  Close
} from '@material-ui/icons';
import MotionsList from './motionsList';
import TagEditorDialog from './tagEditorDialog';
import ColoredCheckbox from '../../../../components/coloredCheckbox';
import ConfirmationDialog from '../../../../components/dialogs/confirmationDialog';
import SaveTagsDialog from './saveTagsDialog';
import { clearDataAnalysis } from '../../../../store/dataAnalysis';

function MotionTagsList({ 
  motionsWithUser = {}, 
  tags = {},
  onTagCreate = ()=>{},
  onTagChange = ()=>{},
  onTagDelete = ()=>{}
}) {
  const [expandedTag, setExpandedTag] = useState(null);
  const [newTag, setNewTag] = useState(null);
  const [editedTag, setEditedTag] = useState(null);
  const [tagToDelete, setTagToDelete] = useState(null);
  const [tagGroupDialogOpen, setTagGroupDialogOpen] = useState(false);
  const [clearingMotions, setClearingMotions] = useState(false);

  const dispatch = useDispatch();

  const motionsByTag = Object.keys(tags).reduce((acc, tagName) => ({
    ...acc,
    [tagName]: tags[tagName].motionIds.map(motionId => motionsWithUser[motionId])
  }), {});

  const onExpandClick = tagName => {
    setExpandedTag(prev => prev === tagName ? null : tagName);
  };

  const onCheckboxChange = (tagName, checked) => {
    onTagChange(tagName, tagName, { ...tags[tagName], selected: checked });
  };

  const getChipLabel = tagName => {
    const numMotions = tags[tagName].motionIds.length;
    const suffix = numMotions === 1 ? '' : 's';
    return `${numMotions} motion${suffix}`;
  };

  const getMotionsTitle = () => {
    const numMotions = Object.keys(motionsWithUser).length;
    return `${numMotions} Motions Loaded`;
  };

  const onTagSave = (tagName, tag) => {
    if (newTag != null) {
      onTagCreate(tagName, tag);
    } else {
      onTagChange(editedTag, tagName, tag);
    }
    setNewTag(null);
    setEditedTag(null);
  };

  const onCancelDialog = () => {
    setEditedTag(null);
    setNewTag(null);
  };

  const onTagDeletePressed = (tagName) => {
    setTagToDelete(tagName);
  };
  
  const confirmTagDeletion = () => {
    if(expandedTag === tagToDelete) {
      setExpandedTag(null);
    }
    onTagDelete(tagToDelete);
    setTagToDelete(null);
  };

  const confirmClear = () => {
    dispatch(clearDataAnalysis());
    setClearingMotions(false);
  };
  
  return <Box padding={1}>
    <Grid container spacing={2}>
      <Grid container item xs alignItems='center' spacing={1}>
        <Grid item>
          <Typography variant='h6'>{getMotionsTitle()}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setClearingMotions(true)} size='small'>
            <Close fontSize='small' />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item>
        <Button 
          color='primary' 
          onClick={() => setTagGroupDialogOpen(true)} 
          disabled={Object.keys(tags).length === 0}
        >
          Save Tags
        </Button>
      </Grid>
      <Grid item>
        <Button 
          onClick={() => setNewTag('')} 
          color='secondary'
          variant='outlined'
        >
          Create Tag
        </Button>
      </Grid>
    </Grid>
    {Object.keys(tags).map((tagName, idx) => <Paper key={tagName}>
      {idx > 0 && <Divider />}
      <Grid container alignItems='center' spacing={1}>
        <Grid item>
          <ColoredCheckbox 
            checked={tags[tagName]?.selected ?? false}
            onChange={(_, checked) => onCheckboxChange(tagName, checked)}
            color={tags[tagName].color}
          />
        </Grid>
        <Grid item xs>
          <Typography variant='h6'>{tagName}</Typography>
        </Grid>
        <Grid item>
      
          <Chip
            label={'Delete'}
            icon={<Delete />}
            clickable
            onClick={() => onTagDeletePressed(tagName)}
          />
      
          <Chip
            label={getChipLabel(tagName)}
            icon={<Edit />}
            clickable
            onClick={() => setEditedTag(tagName)}
          />
        </Grid>
        <Grid item>
          <IconButton onClick={() => onExpandClick(tagName)}>
            {expandedTag === tagName ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expandedTag === tagName} timeout='auto' unmountOnExit>
            <MotionsList motionsWithUser={motionsByTag[tagName]} />
          </Collapse>
        </Grid>
      </Grid>
    </Paper>)}

    <ConfirmationDialog
      title='Delete Tag?'
      open={tagToDelete != null}
      disableCancelOnLoad
      onConfirm={confirmTagDeletion}
      onCancel={() => setTagToDelete(null)}
    >
      Are you sure you want to delete this tag?
    </ConfirmationDialog>
    
    <TagEditorDialog 
      open={editedTag != null || newTag != null}
      tagName={editedTag}
      tag={tags[editedTag]}
      motionsWithUser={motionsWithUser}
      onSave={onTagSave}
      onCancel={onCancelDialog}
    />

    <SaveTagsDialog
      open={tagGroupDialogOpen}
      onClose={() => setTagGroupDialogOpen(false)}
      currentTags={tags}
    />
    <ConfirmationDialog 
      open={clearingMotions}
      title='Clear Motions'
      onConfirm={confirmClear}
      onCancel={() => setClearingMotions(false)}
    >
      Are you sure you want to clear out all loaded motions? 
      This action cannot be undone.
    </ConfirmationDialog>
  </Box>;
}

export default MotionTagsList;
