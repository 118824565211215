import React from 'react';
import {
  Tab,
  Grid,
  Typography
} from '@material-ui/core';
import StyledTabs from './styledTabs';
import styles from './styles';

export default function LabelDefinition({ labels = [], selectedIndex, onClick, onHover }) {

  if (selectedIndex == null) {
    return <div></div>;
  }
 
  return <Grid container spacing={2} style={styles.root}>
    <Grid item xs={12} style={styles.header}> 
      <StyledTabs
        variant='scrollable'
        value={selectedIndex}
        onChange={(event, index) => {
          onClick(labels[index], index);
        }}
      >
        {labels.map((label, index) => {
          const { title, color } = label;
          return <Tab
            style={{ backgroundColor: color }}
            key={`label-title-${index}`}
            label={title}
          />; 
        })}
      </StyledTabs>
    </Grid>
    <Grid item xs={12} style={styles.body}>
      <Typography> {labels != null &&
            selectedIndex != null &&
            labels[selectedIndex] != null ? labels[selectedIndex].description : '--'} </Typography>
    </Grid>
  </Grid>;
};