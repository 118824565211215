import React, { useState } from 'react';
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Line
} from 'recharts';
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress
} from '@material-ui/core';
import round from 'lodash/round';
import TaggedMotionSelect from './taggedMotionSelect';
import { useMotionSimilarityCalculation } from './motionSimilarity.utils';
import { GraphableMetrics } from '../../playerDataTabs/swingDetails/swingDetails.constants';
import useStyles from './styles';

function MotionSimilarity() {
  const classes = useStyles();
  const [firstMotion, setFirstMotion] = useState(null);
  const [secondMotion, setSecondMotion] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState(GraphableMetrics[0]);

  const {
    loading,
    similarityScore,
    offsetGraphData
  } = useMotionSimilarityCalculation(firstMotion?.id, secondMotion?.id, selectedMetric.key);
  
  const formatGraphData = () => {
    const [array1, array2] = offsetGraphData;
    return array1.map((motionData1, idx) => ({
      idx,
      motionData1,
      motionData2: array2[idx]
    }));
  };

  return <Paper className={classes.container}>
    {loading && <LinearProgress className={classes.linearProgress} />}
    <Grid container spacing={2} alignItems='center' justify='center'>
      <Grid item>
        <Typography variant='h5'>Swing Similarity</Typography>
      </Grid>
      <Grid item>
        <TaggedMotionSelect 
          id='first-tagged-motion-select'
          motion={firstMotion}
          onChange={setFirstMotion}
        />
      </Grid>
      <Grid item>
        <Typography>vs</Typography>
      </Grid>
      <Grid item xs>
        <TaggedMotionSelect 
          id='second-tagged-motion-select'
          motion={secondMotion}
          onChange={setSecondMotion}
        />
      </Grid>

      <Grid container item xs={12} alignItems='flex-end' justify='center' spacing={3}>
        <Grid item>
          <FormControl>
            <InputLabel id='similarity-metric'>Metric</InputLabel>
            <Select value={selectedMetric} onChange={e => setSelectedMetric(e.target.value)}>
              {GraphableMetrics.map(metric => <MenuItem
                key={`similarity-metric-item-${metric.key}`}
                value={metric}
              >
                {metric.displayName}
              </MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Typography>
            Similarity Score: <b>{round(similarityScore, 3).toString()}</b>
          </Typography>
        </Grid>
      </Grid>
    
      <Grid item container justify='center' xs={12}>
        <LineChart 
          width={700} 
          height={400} 
          data={formatGraphData()}
          margin={{ bottom: 20, left: 0, top: 0, right: 0 }}
        >
          <CartesianGrid />
          <XAxis dataKey='idx' />
          <YAxis />
          <Legend wrapperStyle={{ bottom: 0 }} />
          <Line 
            type='monotone' 
            dataKey='motionData1' 
            stroke='#8884d8' 
            strokeWidth={3} 
            dot={false} 
          />
          <Line 
            type='monotone' 
            dataKey='motionData2' 
            stroke='#82ca9d' 
            strokeWidth={3} 
            dot={false} 
          />
        </LineChart>
      </Grid>
    </Grid>
  </Paper>;
}

export default MotionSimilarity;
