import { makeStyles } from '@material-ui/styles';
import palette from '../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  labelNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  labelName: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  commentHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  characters: {
    color: palette.darkGray,
    fontSize: '14px',
    marginLeft: '15px'
  },
  closeButton: {
    marginLeft: '40px'
  },
  visuals: {
    marginBottom: '15px'
  },
  hashtags: {
    fontSize: '24px',
    marginRight: '10px'
  },
  comment: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px',
    '& div': {
      marginBottom: '5px'
    }
  },
  tag: {
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      marginBottom: '5px'
    }
  }
}));

export default useStyles;