import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import log from 'js-logger';
import keyBy from 'lodash/keyBy';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ProgressButton from '../../../components/progressButton';
import useStyles from './styles';
import TemplateInput from './templateInput';
import ErrorText, { ERROR_TYPES } from './errorText';
import { GetDrillVideos, GetContentVideos } from '../../../network/videoRequests';
import SelectableVideoList from '../selectableVideoList';
import { CreateNoteTemplate, DeleteNoteTemplate, UpdateNoteTemplate } from '../../../network/noteTemplateRequests';
import VideoPreviewDialog from '../../../components/dialogs/videoPreviewDialog';
import { PERMISSIONS } from '../../../constants/permissions.constants';
import videoStatusConstants from '../../../constants/video.constants';

const MAX_TITLE_LENGTH = 50;
const MAX_TEXT_LENGTH = 2000;

function TemplateCreationDialog({ 
  open, 
  existingTemplate, 
  onClose, 
  onSubmitted, 
  onDeleted 
}) {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [selectedVideoIds, setSelectedVideoIds] = useState([]);
  const [drillVideos, setDrillVideos] = useState([]);
  const [contentVideos, setContentVideos] = useState([]);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(null);

  const permissions = useSelector(state => state.featurePermissions);
  const hasContentPermission = permissions.some(permission => 
    permission.feature === PERMISSIONS.contentFeedEnabled);

  useEffect(() => {
    if (existingTemplate && open) {
      setTitle(existingTemplate.title);
      setText(existingTemplate.text);
      if (existingTemplate.noteTemplateVideos) {
        setSelectedVideoIds(existingTemplate.noteTemplateVideos.map(ntv => ntv.videoId));
      }
    } else {
      setTitle('');
      setText('');
      setSelectedVideoIds([]);
    }
  }, [existingTemplate, open]);

  const classes = useStyles();

  useEffect(() => { setError(null); }, [title, text, selectedVideoIds]);

  const videoMap = useMemo(
    () => keyBy([...drillVideos, ...contentVideos], 'id'), 
    [drillVideos, contentVideos]);
  const videoTitles = useMemo(
    () => selectedVideoIds.map(id => videoMap[id] ? videoMap[id].title : `Video ${id}`), 
    [videoMap, selectedVideoIds]);
  const videoListSections = [
    { header: 'Content', videos: contentVideos },
    { header: 'Drills', videos: drillVideos }
  ];
  
  const disableSubmit = loading || 
    deleting || 
    title.trim().length === 0 || 
    text.trim().length === 0 || 
    error === ERROR_TYPES.conflict;

  useEffect(() => {
    // Repeated code with drill video dialog -
    // network requests should probably be moved up one level
    const cancelToken = axios.CancelToken.source();
    
    async function fetchVideos() {
      setLoading(true);
      try {
        const [fetchedDrillVideos, fetchedContentVideos] = await Promise.all([
          GetDrillVideos(cancelToken),
          hasContentPermission ? GetContentVideos(videoStatusConstants.available, cancelToken) : []
        ]);
        setDrillVideos(fetchedDrillVideos);
        setContentVideos(fetchedContentVideos);
      } catch (e) {
        if (axios.isCancel(e)) return;
        log.error(e, 'Error fetching videos');
        setError(ERROR_TYPES.loading);
      }
      setLoading(false);
    }
    fetchVideos();

    return cancelToken.cancel;
  }, [hasContentPermission]);

  async function submitTemplate() {
    setSubmitting(true);
    try {
      const noteTemplateVideos = selectedVideoIds.map(videoId => ({ videoId }));
      const body = { title, text, noteTemplateVideos };
      const updatingTemplate = existingTemplate != null;

      let template = updatingTemplate 
        ? await UpdateNoteTemplate({ ...existingTemplate, ...body }) 
        : await CreateNoteTemplate(body);
      
      if (template.noteTemplateVideos) {
        template.noteTemplateVideos = template.noteTemplateVideos.map(ntv => ({
          ...ntv,
          video: videoMap[ntv.videoId]
        }));
      }
      onSubmitted(template, updatingTemplate);
      onClose();
    } catch (e) {
      if (e.response && e.response.status === 409) {
        setError(ERROR_TYPES.conflict);
      } 
      else if (e.response && e.response.status === 400) {
        setError(ERROR_TYPES.invalidInput);
      }
      else {
        log.error(e, 'Error creating new template.');
        setError(ERROR_TYPES.submitting);
      }
    }
    setSubmitting(false);
  }

  async function deleteTemplate() {
    if (existingTemplate == null) return;
    setDeleting(true);
    try {
      const { id } = existingTemplate;
      await DeleteNoteTemplate(id);
      onDeleted(id);
      onClose();
    } catch (e) {
      log.error(e, 'Error deleting template with id ' + existingTemplate.id);
      setError(ERROR_TYPES.deleting);
    }
    setDeleting(false);
  }

  const toggleVideoSelection = useCallback(id => {
    setSelectedVideoIds(prev => prev.includes(id) 
      ? prev.filter(x => x !== id)
      : [...prev, id]);
  }, []);

  return <div>
    <Dialog open={open} maxWidth='lg' fullWidth>
      <DialogContent>
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={7}>
            <TemplateInput 
              label='Template Name'
              value={title}
              setValue={setTitle}
              maxLength={MAX_TITLE_LENGTH}
              className={classes.templateInput}
            />

            <TemplateInput 
              label='Body'
              value={text}
              setValue={setText}
              maxLength={MAX_TEXT_LENGTH}
              multiline
              rows={15}
              className={classes.templateInput}
            />

            <div className={classes.selectedDrillsContainer}>
              <Typography className={classes.drillsLabel}>Drills:</Typography>
              <Typography className={classes.selectedVideoTitleList}>{videoTitles.join(', ')}</Typography>
            </div>
          </Grid>
            
          <Grid item xs={5} className={classes.videoListContainer}>
            <SelectableVideoList 
              sections={videoListSections}
              loading={loading}
              selectedVideoIds={selectedVideoIds}
              onToggleSelection={toggleVideoSelection}
              onPreviewClicked={setPreviewVideo}
            />
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        {existingTemplate && <div style={{ flex: 1 }}>
          <ProgressButton 
            onClick={deleteTemplate}
            showProgress={deleting}
            disabled={disableSubmit}
            color='secondary'
            variant='outlined'
          >
            Delete Template
          </ProgressButton>
        </div>}
        {error && <ErrorText error={error} title={title} />}
        <Button onClick={onClose} variant='contained' disabled={submitting || deleting}>
          Cancel
        </Button>
        <ProgressButton 
          onClick={submitTemplate}
          showProgress={submitting}
          disabled={disableSubmit}
          color='primary'
          variant='contained'
        >
          {existingTemplate ? 'Update' : 'Create'} Template
        </ProgressButton>
      </DialogActions>
    </Dialog>

    <VideoPreviewDialog 
      open={previewVideo != null} 
      video={previewVideo}
      onClose={() => setPreviewVideo(null)}
    />
  </div>;
}

TemplateCreationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  existingTemplate: PropTypes.object,
  motionAttributes: PropTypes.array
};

export default TemplateCreationDialog;
