import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography, Grid } from '@material-ui/core';
import { groupBy, keyBy, round } from 'lodash';
import { 
  DeleteTagGroup, 
  GetAllTagGroups, 
  GetTagGroup
} from '../../../network/tagGroups.requests';
import useNetworkRequest from '../../../network/useNetworkRequest';
import ListWithSubheader from './listWithSubheader';
import { addPercentilesAndKeyFramePositionsToMotions } from './motionSelection.utils';
import { setDataAnalysis } from '../../../store/dataAnalysis';
import Logger from 'js-logger';
import { getUserId } from '../../../utils/auth.utils';
import UnitConverter from '../../../utils/unitConverter.utils';

function TagGroupSelection({ onClose }) {
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [errMsg, setErrMsg] = useState('');
  const [tagGroups, , , setTagGroups] = useNetworkRequest([], 'getTagGroups', GetAllTagGroups);
  const dispatch = useDispatch();
  
  const selectGroup = group => setSelectedGroup([ group ]);

  const deleteGroup = async () => {
    try {
      const groupId = selectedGroup[0].id;
      await DeleteTagGroup(groupId);
      setTagGroups(prev => prev.filter(group => group.id !== groupId));
    } catch (e){
      Logger.error('Error when deleting a tag group.', e);
      setErrMsg('Deleting the tag group failed.');
    }
  };

  const importGroup = async () => {
    try {
      const group = await GetTagGroup(selectedGroup[0].id);
      
      const users = keyBy(group.usersInGroupMotions, user => user.userId);
      const tags = group.tags.map(tag => ({
        name: tag.name,
        color: tag.color,
        selected: false,
        motionIds: tag.tagsMotions.map(tagMotion => tagMotion.motionId)
      }));
      
      const motions = group.tags.flatMap(tag => tag.tagsMotions.map(tagMotion => {
        const { motion } = tagMotion;
        const weightKgs = users[motion.userId].measurements[0].weightKilograms;
        const weightLbs = round(UnitConverter.kgToLb(weightKgs), 3);
        const updatedMotion = addPercentilesAndKeyFramePositionsToMotions(
          { [motion.id]: motion }, 
          weightLbs);
        return updatedMotion[motion.id];
      }));

      dispatch(setDataAnalysis({
        motions: keyBy(motions, motion => motion.id),
        users,
        hoveredMotionId: null,
        tags: keyBy(tags, tag => tag.name)
      }));

      onClose();
    } catch(e) {
      Logger.error('Error when importing tag group into analysis page', e);
      setErrMsg('There was an error when importing the group.');
    }
  };

  const groupsByCreator = groupBy(tagGroups, group => group.creator.fullName);
  const deleteDisabled = selectedGroup.length === 0 
    || selectedGroup[0].creatorId !== getUserId();

  return (
    <div>
      <Typography color='error'>{errMsg}</Typography>
      <Grid container justify='space-between' alignItems='center'>
        <Typography>Import Tags</Typography>
        <Button variant='contained' color='primary' onClick={importGroup}>
          Import
        </Button>
      </Grid>
      <ListWithSubheader
        listObject={groupsByCreator}
        displayFn={group => group.name}
        selectedItems={selectedGroup}
        onToggleCheckbox={selectGroup}
      />
      <Button 
        color='secondary' 
        variant='outlined' 
        disabled={deleteDisabled} 
        onClick={deleteGroup}
      >
        Delete Tag Group
      </Button>
    </div>
  );
};

export default TagGroupSelection;