import { makeStyles } from '@material-ui/styles';
import palette from '../../../styles/palette';

const useStyles = makeStyles(_ => ({
  container: {
    padding: '20px 20px',
    margin: '20px 5px',
    backgroundColor: 'white',
    display: 'inline-block',
    fontStyle: 'italic',
    width: '80%'
  },
  header: {
    paddingBottom: '20px'
  },
  sendEmailContainer: {
    margin: '10px 0'
  },
  sentEmailMessage: {
    padding: '5px 15px',
    backgroundColor: palette.subtleGreen,
    fontStyle: 'normal',
    display: 'inline-block',
    borderRadius: '5px'
  },
  sendEmailButtonContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  errorText: {
    backgroundColor: palette.errorLessSevere,
    marginLeft: '10px'
  },
  saveButton: {
    marginLeft: '80px'
  }
}));

export default useStyles;