import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Logger from 'js-logger';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import useStyles from './styles';
import { PutLaunchMonitor } from '../../../../network/motionRequests';
import { formatMonthDayTime } from '../../../../utils/formatting.utils';

const SAVING_STATE = {
  saving: 'saving',
  saved: 'saved',
  error: 'error'
};

function MotionListItem({ 
  motion, 
  isMobile,
  selected, 
  canEnterExitVelocity, 
  error,
  isNew, 
  onClick, 
  onSave,
  startAdornment
}) {
  const { id, timestamp, launchMonitorData } = motion;
  const initialLm = useMemo(() => launchMonitorData == null 
    ? { motionId: id, ballspeedMph: null } 
    : launchMonitorData, [launchMonitorData, id]);
  const [ inputLm, setInputLm ] = useState(initialLm);
  const [ savingState, setSavingState ] = useState(SAVING_STATE.saved);

  useEffect(() => {
    setInputLm(initialLm);
  }, [initialLm]);

  const date = new Date(timestamp);
  const classes = useStyles();
  const listItemClasses = classNames({
    [classes.listItem]: true,
    [classes.selectedListItem]: selected,
    [classes.newListItem]: isNew,
    [classes.error]: error
  });

  async function saveExitVelocity() {
    const oldBallspeedMph = launchMonitorData ? launchMonitorData.ballspeedMph : null;
    if (inputLm.ballspeedMph === oldBallspeedMph || inputLm === null) {
      return;
    }
    setSavingState(SAVING_STATE.saving);
    try {
      const resp = await PutLaunchMonitor(id, inputLm);
      const lmId = resp.data ? resp.data.id : null;
      onSave({ ...inputLm, id: lmId });
      setSavingState(SAVING_STATE.saved);
    } catch (e) {
      Logger.error('Error saving lm data', e);
      setSavingState(SAVING_STATE.error);
    }
  }

  function onTextFieldChange(e) {
    let ballspeedMph = e.target.value;
    if (ballspeedMph.trim() === '') {
      ballspeedMph = null;
    }
    setInputLm(prev => ({ ...prev, ballspeedMph }));
  }

  function renderSavingLabel() {
    const saving = savingState === SAVING_STATE.saving;
    const saved = savingState === SAVING_STATE.saved;
    const error = savingState === SAVING_STATE.error;
    const className = classNames({ 
      [classes.savingLabelError]: error, 
      [classes.savingLabelSaved]: saved 
    });
    return <Typography variant='body2' className={className}>
      {saving && 'Saving...'}
      {saved && 'Saved!'}
      {error && 'Error, try again.'}
    </Typography>;
  }

  return <div className={listItemClasses} onClick={() => { !isMobile && onClick(); }}>
    {motion.badMotion && <div className={classes.badMotion}>
      Potential bad motion. Review graph.</div>}
    {startAdornment}
    {canEnterExitVelocity && <div className={classes.exitVelocityMargin}>
      <div className={classes.exitVelocityContainer}>
        <Typography>Exit Velocity:</Typography>
        <TextField
          value={inputLm.ballspeedMph != null ? inputLm.ballspeedMph : ''}
          onChange={onTextFieldChange}
          classes={{ root: classes.textField }}
          inputProps={{ className: classes.textFieldInput }}
          variant='outlined'
          type='number'
          disabled={savingState === SAVING_STATE.saving}
          onBlur={saveExitVelocity}
          onKeyDown={e => e.keyCode === 13 && e.target.blur()}
        />
        <Typography className={classes.grayText}>mph</Typography>
      </div>
      {renderSavingLabel()}
    </div>}
    <div className={classes.listItemFooter}>
      <Typography variant='body2'>{formatMonthDayTime(date)}</Typography>
      {isMobile && <Typography variant='body2' onClick={onClick} className={classes.moreButton}>
        More
      </Typography>}
    </div>
  </div>;
}

MotionListItem.propTypes = {
  motion: PropTypes.object.isRequired, 
  selected: PropTypes.bool, 
  canEnterExitVelocity: PropTypes.bool, 
  error: PropTypes.bool,
  isNew: PropTypes.bool, 
  onClick: PropTypes.func, 
  onSave: PropTypes.func,
  startAdornment: PropTypes.node
};

export default MotionListItem;
