import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, TextField, Grid, Button } from '@material-ui/core';
import ProgressButton from '../../../../components/progressButton';
import { UpdateOrganizationUserProperties, DeleteOrganizationUserProperties } from '../../../../network/userRequests';
import { 
  SetPlayerOrganizationUserProperties,
  SetCurrentPlayerOgranizationUserProperties 
} from '../../../../store/players';
import useStyles from './styles';
import ConfirmationDialog from 'components/dialogs/confirmationDialog';
import Logger from 'js-logger';

function OrgProperties({ userId, organizationUserProperties = null }){
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [resultMessage, setResultMessage] = useState('');
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  
  const initialExternalId = organizationUserProperties && organizationUserProperties.externalId
    ? organizationUserProperties.externalId
    : '';

  useEffect(() => {
    setExternalId(initialExternalId);
  }, [initialExternalId]);

  const [externalId, setExternalId] = useState(initialExternalId);

  useEffect(() => {

    // if confirm is closed, set error back to null
    if (!confirmingDelete) {
      setDeleteError(null);
    }
  }, [confirmingDelete]);

  const saveExternalId = async () => {
    setIsLoading(true);
    try {
      const orgUserProperties = 
        await UpdateOrganizationUserProperties(userId, { externalId: externalId.trim() } );

      dispatch(SetPlayerOrganizationUserProperties(userId, orgUserProperties));
      dispatch(SetCurrentPlayerOgranizationUserProperties(orgUserProperties));
      setResultMessage('Saved!');
    } catch(e) {
      Logger.error('Error when attempting to update an users externalId', e);
      setResultMessage('There was a problem saving.');    
    }
    setIsLoading(false);
  };

  const deleteExternalIdFromConfirmation = async () => {
    setIsDeleting(true);
    setDeleteError(null);
    try {
      await DeleteOrganizationUserProperties(organizationUserProperties.id);
      dispatch(SetPlayerOrganizationUserProperties(userId, null));
      dispatch(SetCurrentPlayerOgranizationUserProperties(null));
      setIsDeleting(false);
      setResultMessage('Deleted!');
      setConfirmingDelete(false);
    } catch (e) {
      Logger.error('Error deleting externalId from Account Info', e);
      setDeleteError('There was an error deleting this external ID');
      setIsDeleting(false);
    }
  };

  return (<>
    <div>
      <Grid container>
        <div>
          <div className={classes.textFieldContainer}>
            <Grid container>
              <Typography>External User Id:</Typography> 
            </Grid>
            <TextField
              variant='outlined'
              size='small'
              disabled={isLoading}
              value={externalId}
              onChange={e => setExternalId(e.target.value)}
            />
          </div>
        </div>
        {externalId && initialExternalId !== externalId && 
          <Grid item className={classes.buttonContainer}>
            <ProgressButton
              showProgress={isLoading}
              className={classes.button} 
              color='primary' 
              variant='contained' 
              onClick={saveExternalId}
            >
              Update
            </ProgressButton>
          </Grid>}

        {organizationUserProperties != null && 
          <Grid item className={classes.buttonContainer}>
            <Button
              className={classes.button} 
              disabled={isLoading}
              variant='contained' 
              onClick={() => setConfirmingDelete(true)}
            >
              Delete
            </Button>
          </Grid>}
      </Grid>
      <Typography color='error'>{resultMessage}</Typography>
    </div>

    <ConfirmationDialog 
      open={confirmingDelete}
      title='Are You Sure You Want To Delete This External ID?'
      loading={isDeleting}
      onConfirm={deleteExternalIdFromConfirmation}
      onCancel={() => setConfirmingDelete(false)}
      errorMessage={deleteError}
      disableCancelOnLoad
    >
      <Typography>You Cannot Undo This Action</Typography>
    </ConfirmationDialog>
  </>);
};

export default OrgProperties;