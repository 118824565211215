import { useCallback, useEffect, useRef } from 'react';
import Analytics, { 
  CATEGORIES,
  TIME_ON_PAGE,
  VIEWED_PAGE
} from 'services/analytics';

const VISIBILITY_CHANGE_EVENT = 'visibilitychange';
const VISIBLE_STATE = 'visible';
const HIDDEN_STATE = 'hidden';
const MS_IN_SECONDS = 1000;

// This is an analytics hook to track page views
// and time on page.
const useTrackPageEvents = ({
  /* 
    Generally this hook will only track events when
    the browser notifies us of a change in the 
    visibility of the current browser tab. 
    Mainly when the browser tab is closed or when
    user switches tabs or navigates away. 
    Further details bellow. 
  */
  eventCategory = CATEGORIES.GENERAL,
  pageName,
  // Changes to url resets the timer to
  // zero & does fire a tracking event. 
  url,
  userId, 
  userEmail,
  miscData 
}) => {
  const startTime = useRef(Date.now());
  // We are doing this because userEmail will update and
  // trigger double counting of events.
  // Sadly it has a side effect that the first event will be
  // anonymous@k-vest.com which is not always correct.
  const userEmailRef = useRef(userEmail);

  const onPageViewed = useCallback(() => {
    // Resetting the timer every time the user is back on the page:
    Analytics.startTimer(TIME_ON_PAGE);
    startTime.current = Date.now();

    Analytics.track({ 
      eventCategory: eventCategory,
      eventName: VIEWED_PAGE, 
      userId, 
      userEmail: userEmailRef.current,
      pageName,
      miscData: { 
        ...miscData
      }    
    });
  }, [userId, pageName, eventCategory, miscData]);

  const trackTimeOnPage = useCallback((extraTrackingData = {}) => {
    Analytics.track({ 
      eventName: TIME_ON_PAGE, 
      userId, 
      userEmail: userEmailRef.current,
      pageName,
      miscData: { 
        durationOnPageSanity: (Date.now() - startTime.current) / MS_IN_SECONDS,
        ...miscData,
        ...extraTrackingData
      }, 
      eventCategory 
    }, 
    { sendAsBeacon: true });
  }, [userId, pageName, eventCategory, miscData]);

  const handleVisibilityChange = useCallback(() => {
    // Note this visibility event fires when:
    // 1) user switches to a different chrome tab in the same window.
    // 2) user switches to a different program that is not their browser (e.g not chrome)
    // 3) user switches to a different chrome profile window
    // It does not fire if the user opens a new chrome window on the same
    // chrome profile. 
    if (document.visibilityState === HIDDEN_STATE) {
      trackTimeOnPage({ navigatedWithinSite: false });
    } else if (document.visibilityState === VISIBLE_STATE) {
      onPageViewed();
    }
  }, [trackTimeOnPage, onPageViewed]);

  useEffect(() => {
    document.addEventListener(VISIBILITY_CHANGE_EVENT, handleVisibilityChange);

    return () => {
      document.removeEventListener(VISIBILITY_CHANGE_EVENT, handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  useEffect(() => {
    userEmailRef.current = userEmail;
  }, [userEmail]);
  
  useEffect(() => {
    onPageViewed();
    const url = window.location.href; 
    return () => {
      trackTimeOnPage({ navigatedWithinSite: true, '$current_url': url });
    };
  }, [url, pageName, onPageViewed, trackTimeOnPage]);

};

export default useTrackPageEvents;