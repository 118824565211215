// Actions
export const SET_PLAYERS = 'players/set';
export const ADD_PLAYER = 'players/add';
export const TOGGLE_PLAYER = 'baseballSummary/toggle';
export const SET_CURRENT_PLAYER = 'currentPlayer/set';
export const SET_MEASUREMENTS = 'measurements/set';
export const SET_ORGANIZATION_USER_PROPERTIES = 'organizationuserProperties/set';
export const SET_CURRENT_PLAYER_ORGANIZATION_USER_PROPERTIES 
  = 'currentPlayer/set/organizationUserProperties';
export const UPDATE_CURRENT_PLAYER = 'currentPlayer/update';

// Reducer
const initialState = [];
const initialPlayer = {};

export function currentPlayer(state = initialPlayer, action) {
  switch(action.type) {
    case SET_CURRENT_PLAYER:
      return action.player;
    case UPDATE_CURRENT_PLAYER:
      return {
        ...state, 
        firstName: action.player.firstName,
        lastName: action.player.lastName,
        emailAddress: action.player.emailAddress
      };
    case SET_CURRENT_PLAYER_ORGANIZATION_USER_PROPERTIES:
      return {
        ...state,
        organizationUserProperties: action.organizationUserProperties
      };
    default:
      return state;
  }
};

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case SET_PLAYERS:
      return action.players.map((player) => {
        return {
          ...player,
          selected: false
        };
      });
    case ADD_PLAYER:
      return [...state, action.player];
    case TOGGLE_PLAYER:
      return state.map(player => {
        return player.userId === action.userId 
          ? { ...player, selected : !player.selected }
          : player;
      });
    case SET_MEASUREMENTS:
      return state.map(player => {
        return player.userId === action.measurements[0].userId
          ? { ...player, measurements: action.measurements }
          : player;
      });
    case SET_ORGANIZATION_USER_PROPERTIES:
      return state.map(player => player.userId === action.payload.userId
        ? { ...player, organizationUserProperties: action.payload.organizationUserProperties }
        : player);
    case UPDATE_CURRENT_PLAYER:
      return state.map(player => player.userId === action.player.userId
        ? {
          ...player, 
          firstName: action.player.firstName,
          lastName: action.player.lastName,
          emailAddress: action.player.emailAddress
        }
        : player);
    default:
      return state;
  }
};

// Action Creators
export const SetPlayers = (players) => {
  return { type: SET_PLAYERS, players };
};

export const addPlayer = player => {
  return { type: ADD_PLAYER, player };
};

export const TogglePlayer = (userId) => {
  return { type: TOGGLE_PLAYER, userId };
};

export const SetPlayerMeasurements = (measurements) => {
  return { type: SET_MEASUREMENTS, measurements };
};

export const SetPlayerOrganizationUserProperties = (userId, organizationUserProperties) => {
  return { type: SET_ORGANIZATION_USER_PROPERTIES, payload: { userId, organizationUserProperties }};
};

export const SetCurrentPlayer = (player) => {
  return { type: SET_CURRENT_PLAYER, player };
};

export const UpdateCurrentPlayer = (player) => {
  return { type: UPDATE_CURRENT_PLAYER, player };
};

export const SetCurrentPlayerOgranizationUserProperties = (organizationUserProperties) => {
  return { type: SET_CURRENT_PLAYER_ORGANIZATION_USER_PROPERTIES, organizationUserProperties };
};