import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../../styles/palette';

const THUMBNAIL_HEIGHT = '100px';
const THUMBNAIL_WIDTH = '150px';

export default makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  requestButton: {
    margin: '10px'
  },
  list: {
    width: '100%'
  },
  listItemBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer'
  },
  listItemSelected: {
    backgroundColor: '#E1FAFF'
  },
  
  timestamp: {
    width: '20%',
    margin: '5px',
    color: palette.gray
  },
  tilesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    overflowX: 'auto',
    margin: '5px'
  },
  statusNoteContainer: {
    height: THUMBNAIL_HEIGHT,
    paddingLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  thumbnailContainer: {
    width: THUMBNAIL_WIDTH,
    height: THUMBNAIL_HEIGHT,
    margin: '0px 10px 10px 10px',
    borderRadius: '5px',
    overflow: 'hidden'
  },
  blackBackground: {
    backgroundColor: 'black'
  },
  thumbnailImgContainer: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  thumbnailTitleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  playIconContainer: {
    position: 'absolute', 
    top: 0, left: 0, bottom: 0, right: 0, 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    '&:hover > svg': {
      display: 'flex'
    }
  },
  playIcon: {
    width: '80%',
    height: '80%',
    color: 'lightgray',
    display: 'none'
  },
  titleChip: {
    backgroundColor: '#B7F1F9'
  },
  videoSkeletonContainer: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  processingText: {
    position: 'absolute',
    top: 0, left: 0, bottom: 0, right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeButton: {
    float: 'right',
    paddingTop: '0px'
  },
  analysisTypeButtonsContainer: {
    justifyContent: 'space-evenly',
    height: '80px'
  },
  analysisTypeButton: {
    height: '50px'
  },
  attributeName: {
    fontStretch: 'condensed',
    fontWeight: 'bold'
  },
  creditsContainer: {
    display: 'flex',
    marginTop: '10px'
  },
  checkInCredits: {
    marginLeft: '15px'
  }
}));
