import React, { useState, useCallback } from 'react';
import useStyles from './styles';

import { 
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';

import KinematicCellPicker from '../../../../components/kinematicDetailsDenseMultiCellPicker';
import KinematicSequenceGraph from '../../../../components/kinematicSequenceGraphAddLabel';

import { labelPalette, tags } from '../../../../utils/kAssessments.utils';

const LABEL_CONSTRAINTS = {
  COMMENT_LENGTH: 300,
  LABEL_LENGTH: 30
};

function AddLabelDialog({ open, onClose, onAddLabel, motionData, userId }) {
  const classes = useStyles();

  const [tag, setTag] = useState(tags[1]);
  const [labelName, setLabelName] = useState('');
  const [cellsPicked, setCellsPicked] = useState([]);
  const [points, setPoints] = useState(null);
  const [description, setDescription] = useState('');

  const tagChange = (__, tagSelected) => {
    setTag(tagSelected);
  };

  const addLabel = () => {
    if (!labelName) {
      return;
    }

    const labelData = {
      id: Symbol(labelName),
      name: labelName.trim(),
      description,
      graphPoints: [],
      tableCells: cellsPicked,
      tag,
      newLabel: true
    };

    if (points) {
      labelData.graphPoints = [ points ];
    }

    onAddLabel(labelData);
  };

  const cellsUpdated = useCallback(
    (cells) => {
      const newCells = [];
      Object.keys(cells).forEach((cell) => {
        newCells.push({
          cellType: cell,
          id: cell
        });
      });
      setCellsPicked(newCells);
    },
    []
  );

  const commentUpdated = (e) => {
    const text = e.target.value;
    if (text.length > LABEL_CONSTRAINTS.COMMENT_LENGTH) {
      setDescription(text.substring(0, LABEL_CONSTRAINTS.COMMENT_LENGTH));
    } else {
      setDescription(text);
    }
  };

  const labelNameUpdated = (e) => {
    const text = e.target.value;
    if (text.length > LABEL_CONSTRAINTS.LABEL_LENGTH) {
      setLabelName(text.substring(0, LABEL_CONSTRAINTS.LABEL_LENGTH));
    } else {
      setLabelName(text);
    }
  };

  const pointSelected = useCallback(
    (xy) => {
      setPoints(xy);
    },
    []
  );

  const cleanUp = () => {
    setTag(tags[1]);
    setLabelName('');
    setDescription('');
    setCellsPicked([]);
    setPoints(null);
  };

  const color = labelPalette[tag];

  return <Dialog open={open} onClose={onClose} onExited={cleanUp}>
    <DialogContent>
      <div className={classes.dialogContent}>
        <div className={classes.labelNameContainer}>
          <div className={classes.labelName}>
            <div className={classes.hashtags}>##</div>
            <TextField label='Label Name'
              variant='outlined'
              value={labelName}
              onChange={labelNameUpdated} />
          </div>

          <div className={classes.closeButton}>
            <Button onClick={onClose}>CLOSE</Button>
          </div>
        </div>

        <div className={classes.visuals}>
          <KinematicSequenceGraph 
            pointWasSelected={pointSelected}
            initialColor={color}
            {...motionData}
          />
          <KinematicCellPicker
            selectedMetricsChanged={cellsUpdated}
            initialColor={color}
            motion={motionData.motion}
            userId={userId}
          />
        </div>

        <div className={classes.comment}>
          <div className={classes.commentHeader}>
            <span>Comment</span>
            <span className={classes.characters}>
              {description.length}/{LABEL_CONSTRAINTS.COMMENT_LENGTH}
            </span>
          </div>
          <TextField 
            placeholder='Add Comment (optional)'
            multiline
            variant='outlined'
            rows={4}
            rowsMax={4}
            value={description}
            onChange={commentUpdated}
          />
        </div>

        <div className={classes.tag}>
          <div>Tag</div>
          <RadioGroup row value={tag} onChange={tagChange}>
            <FormControlLabel value='Positive' control={<Radio />} label='Good' />
            <FormControlLabel value='Neutral' control={<Radio />} label='Neutral' />
            <FormControlLabel value='Negative' control={<Radio />} label='Needs Improvement' />
          </RadioGroup>
        </div>
      </div>
    </DialogContent>

    <DialogActions>
      <Button disabled={false} onClick={addLabel} color='primary'> ADD LABEL </Button>
    </DialogActions>
  </Dialog>;
}

export default AddLabelDialog;