import React, { useState, useMemo } from 'react';
import useStyles from './styles';

import { formatMonthDayTime } from './../../../utils/formatting.utils';
import DeliveryItemsDialog from './deliveryItemsDialog';
import TEXT_TYPES from './../../../constants/actionPlanTextTypes.constants';
import ReactMarkdown from 'react-markdown';

import {
  Button, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@material-ui/core';

const COLUMNS = [
  {
    name: 'Timestamp',
    displayFn: ({ creationTimestamp }) => {
      return formatMonthDayTime(creationTimestamp);
    }
  },
  {
    name: 'Content Text',
    displayFn: ({ contentFeedItemId }, contentMap, classes) => {
      const content = contentMap[contentFeedItemId];
      if (!content) return <i>No Content Found</i>;
      return <div className={classes.snippet}>
        {
          content.textFormatType === TEXT_TYPES.MARKDOWN 
            ? <ReactMarkdown source={content.text} />
            : content.text 
        }
      </div>;
    }
  },
  {
    name: 'Trigger Type',
    displayFn: ({ triggerType }) => {
      return triggerType;
    }
  },
  {
    name: 'Status',
    displayFn: ({ status }) => {
      return status;
    }
  }
];

const INITIAL_ROWS = 5;

function ScheduledContent({
  contentFeedItems,
  contentFeedError,
  contentFeedLoading,
  deliveries,
  deliveriesLoading,
  deliveriesError
}) {

  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const rowClicked = delivery => setSelectedDelivery(delivery);

  const classes = useStyles();

  const loading = contentFeedLoading || deliveriesLoading;
  const error = deliveriesError || contentFeedError;

  const contentMap = useMemo(() => {
    if (!contentFeedItems) return {};
    return contentFeedItems.reduce((acc, content) => {
      acc[content.id] = content;
      return acc;
    }, {});
  }, [contentFeedItems]);

  const toggleShow = () => setShowAll(prev => !prev);

  const shownDeliveries = !deliveries ? null :
    (showAll ? deliveries : deliveries.slice(0, INITIAL_ROWS));

  return <div>
    {
      loading &&
      <CircularProgress />
    }
    {
      !loading && error &&
      <div classname={classes.error}>
        There was an error loading deliveries and/or content items
      </div> 
    }
    {
      !error && !loading && shownDeliveries &&
      <>
        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {
                  COLUMNS.map(({ name }) => {
                    return <TableCell key={name} className={classes.header}>{name}</TableCell>;
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                shownDeliveries.map(delivery => {
                  const { id } = delivery;
                  return <TableRow 
                    className={classes.row} 
                    onClick={() => rowClicked(delivery)} 
                    key={id}>
                    {
                      COLUMNS.map(({ name, displayFn }) => {
                        return <TableCell key={name}>
                          {
                            displayFn(delivery, contentMap, classes)
                          }
                        </TableCell>;
                      })
                    }
                  </TableRow>;
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          deliveries.length > INITIAL_ROWS &&
          <Button className={classes.showAll} color='primary' onClick={toggleShow}>
            {
              showAll ? 'Show Less' : 'Show All Scheduled Content'
            }
          </Button>
        }
      </>
    }

    <DeliveryItemsDialog
      delivery={selectedDelivery}
      contentMap={contentMap}
      onClose={() => setSelectedDelivery(null)}
    />
  </div>;
}

export default ScheduledContent;