import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import ProgressButton from '../../progressButton';

function ConfirmationDialog({
  className = null,
  open, 
  onCancel, 
  onConfirm, 
  title, 
  children, 
  loading,
  errorMessage,
  confirmDisabled = false,
  disableCancelOnLoad = false
}) {
  return (
    <Dialog 
      classes={{ paper: className }}
      onClose={onCancel} 
      disableBackdropClick={disableCancelOnLoad && loading}
      aria-labelledby='confirmation-dialog' 
      open={open}
    >
      {title && <DialogTitle onClose={onCancel}>
        {title}
      </DialogTitle>}
            
      <DialogContent>
        {typeof children === 'string'
          ? <Typography>{children}</Typography>
          : children}
      </DialogContent>

      <DialogActions>
        <Typography color='error'>
          {errorMessage}
        </Typography>

        <Button
          disabled={disableCancelOnLoad && loading}
          onClick={onCancel} 
          color='primary'
        >
          Cancel
        </Button>

        <ProgressButton 
          onClick={onConfirm} 
          color='primary' 
          showProgress={loading} 
          disabled={confirmDisabled}
        >
          Confirm
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  children: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  confirmDisabled: PropTypes.bool
};

export default ConfirmationDialog;
