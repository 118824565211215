import React from 'react';

class Gear extends React.Component {
  render() {
    const { size, color, opacity } = this.props;
    return <svg
      width={size} 
      height={size} 
      viewBox='0 0 31 31' 
      fill='none' 
      xmlns='http://www.w3.org/2000/svg'
    >
      <path 
        fillRule='evenodd' 
        clipRule='evenodd' 
        d='M19.8106 0C21.0815 0.352435 22.2891 0.857022 23.4124 1.4928V4.68335C23.4124 6.27784 24.7178 7.58278 26.3134 7.58278H29.5062C30.1424 8.70529 30.6473 9.91203 31 11.1817L28.741 13.4391C27.6127 14.5667 27.6127 16.4119 28.741 17.5394L31 19.7969C30.6473 21.0665 30.1428 22.2733 29.5066 23.3958H26.3134C24.7178 23.3958 23.4124 24.7003 23.4124 26.2952V29.4865C22.2891 30.1219 21.0815 30.6265 19.811 30.9789L17.5516 28.7211C16.4233 27.5935 14.5771 27.5935 13.4484 28.7211L11.1898 30.9785C9.9189 30.6261 8.71132 30.1215 7.58803 29.4861V26.2952C7.58803 24.7007 6.28258 23.3958 4.68698 23.3958H1.49306C0.857227 22.2736 0.352679 21.0669 0 19.7973L2.25901 17.5394C3.38735 16.4119 3.38774 14.5667 2.25901 13.4391L0.000388422 11.182C0.353067 9.91203 0.857615 8.70529 1.49384 7.58278H4.68698C6.28258 7.58278 7.58803 6.27823 7.58803 4.68335V1.49203C8.71132 0.856634 9.91851 0.352435 11.1894 0L13.4488 2.25745C14.5771 3.385 16.4233 3.385 17.5516 2.25745L19.8106 0ZM15.5 9.71563C18.6908 9.71563 21.2776 12.3007 21.2776 15.4893C21.2776 18.6779 18.6908 21.2629 15.5 21.2629C12.3092 21.2629 9.72236 18.6779 9.72236 15.4893C9.72236 12.3007 12.3092 9.71563 15.5 9.71563Z' 
        fill={color}
        fillOpacity={opacity}
      />
    </svg>;
  }
};

Gear.defaultProps = {
  size: 40,
  color: 'black',
  opacity: 1
};

export default Gear;
