import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker } from '@material-ui/pickers';
import ProgressButton from '../progressButton';

import { withStyles } from '@material-ui/core/styles';
import Styles from './styles';

class DateRangeSelector extends React.Component {

  render() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

    if(this.props.hidden) {
      return null;
    }

    const { classes } = this.props;
    return (
      <div className={classes.outerContainer}>
        <div className={classes.datePickerContainer}>
          <DatePicker
            className={classes.datePicker}
            label='From'
            variant='inline'
            inputVariant='outlined'
            format='MM/dd/yy'
            disableFuture
            value={this.props.fromDate}
            onChange={(date) => this.props.handleDateChange(date, true)}
          />
          <DatePicker
            className={classes.datePicker}
            label='To'
            variant='inline'
            inputVariant='outlined'
            format='MM/dd/yy'
            disableFuture
            value={this.props.toDate}
            onChange={(date) => this.props.handleDateChange(date, false)}
          />
          {this.props.submitDateRange && <ProgressButton 
            showProgress={false} 
            onClick={this.props.submitDateRange} 
            className={classes.goBtn}
            variant='contained'
          >
            Go
          </ProgressButton>}
        </div>
      </div>
    );
  }
}

DateRangeSelector.propTypes = {
  fromDate: PropTypes.object.isRequired,
  toDate: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  submitDateRange: PropTypes.func
};

export default withStyles(Styles)(DateRangeSelector);