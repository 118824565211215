import makeStyles from '@material-ui/core/styles/makeStyles';
import palette from '../../../../styles/palette';

const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: '90vw',
    minHeight: '80vh'
  },
  content: {
    height: '100%',
    width: '100%'
  },
  loading: {
    display: 'flex',
    width: '100%',
    marginTop: '20px',
    justifyContent: 'center'
  },
  recordActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  recordCounter: {
    color: palette.error,
    marginLeft: '15px',
    '&$mergeCanBeDone': {
      color: palette.success
    }
  },
  notAvailable: {
    opacity: 0.5,
    fontStyle: 'italic'
  },
  formControl: {
    minWidth: '150px',
    marginRight: '15px'
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  syncButton: {
    marginLeft: '10px',
    backgroundColor: palette.success,
    '&:hover': {
      backgroundColor: palette.success
    }
  },
  rejectButton: {
    backgroundColor: palette.error,
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.error
    }
  },
  mergeCanBeDone: {},
  records: {}
}));

export default useStyles;