import React, { useState, useEffect } from 'react';
import axios from 'axios';
import sortBy from 'lodash/sortBy';
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import { GetAllDrills } from '../../../network/drillRequests';
import Logger from 'js-logger';
import useStyles from './styles';

function DrillAssignmentDialog({ onAssignment, onClose, noteDrills = [] }) {
  const [allDrills, setAllDrills] = useState([]);
  const [assignedDrills, setAssignedDrills] = useState(
    orderBy(noteDrills, drill => drill.sequenceNumber));
  const [errMsg, setErrMsg] = useState('');
  const [drillsById, setDrillsById] = useState({});
  const classes = useStyles();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchDrills() {
      try {
        const drills = await GetAllDrills(cancelToken);
        const drillsById = keyBy(drills, drill => drill.id);
        setAllDrills(sortBy(drills, drill => drill.name));
        setDrillsById(drillsById);
        setAssignedDrills(prev => prev.map(assignedDrill => {
          const drill = drillsById[assignedDrill.drillId];
          return {
            ...assignedDrill,
            drillVideoId: drill.drillVideoId,
            cueVideoId: drill.cueVideoId
          };
        }));
      } catch (e) {
        Logger.error('Error when fetching drills', e);
        setErrMsg('There was trouble getting available drills. Please try again.');
      }
    }
    fetchDrills();
    return cancelToken.cancel;
  }, []);

  const assignDrill = drill => {
    setAssignedDrills(prev => [
      ...prev, {
        drillId: drill.id,
        sets: drill.defaultSets,
        reps: drill.defaultReps,
        drillVideoId: drill.drillVideoId,
        cueVideoId: drill.cueVideoId,
        sequenceNumber: assignedDrills.length + 1
      }
    ]);
  };

  const removeDrill = drill => {
    setAssignedDrills(prev => prev
      .filter(prevDrill => prevDrill.drillId !== drill.drillId)
      .map((prevDrill, i) => ({ ...prevDrill, sequenceNumber: i + 1 })));
  };

  const updateSets = (e, drill) => {
    const val = (e.target && e.target.value) || 0;
    setAssignedDrills(prev => prev.map(d => d.drillId === drill.drillId
      ? { ...d, sets: val }
      : d 
    ));
  };

  const updateReps = (e, drill) => {
    const val = (e.target && e.target.value) || 0;
    setAssignedDrills(prev => prev.map(d => d.drillId === drill.drillId
      ? { ...d, reps: val }
      : d 
    ));
  };

  const lookupDrillName = (drillId) => {
    return drillsById[drillId]
      ? drillsById[drillId].name
      : '';
  };

  const assignedDrillIds = assignedDrills.map(drill => drill.drillId);
  const selectableDrills = allDrills.filter(drill => !assignedDrillIds.includes(drill.id));

  return (
    <Dialog open onClose={onClose} maxWidth='sm' fullWidth>
      <Grid container justify='space-between' alignItems='center'>
        <DialogTitle>Drill Assignment</DialogTitle>
        <Button 
          color='primary' 
          variant='contained' 
          className={classes.assignButton}
          onClick={() => onAssignment(assignedDrills)}
        >
          Assign
        </Button>
      </Grid>
      <Typography color='error'>{errMsg}</Typography>
      <Typography className={classes.assignedLabel}>Drills Assigned</Typography>
      <List>
        <Grid container justify='center'>
          {assignedDrills.map(drill => (
            <Grid 
              container 
              alignItems='center' 
              justify='center' 
              key={`assigned-${drill.drillId}`} 
              className={classes.assignedDrillItem}
            >
              <IconButton onClick={() => removeDrill(drill)}>
                <DeleteIcon />
              </IconButton>
              <Typography variant='h6'>{drill.sequenceNumber}.</Typography>
              <Grid
                container 
                justify='space-around'
                alignItems='center'
                className={classes.assignedDrillContainer} 
              >
                <Typography>{lookupDrillName(drill.drillId)}</Typography>
                <TextField 
                  variant='outlined' 
                  label='Sets'
                  size='small'
                  className={classes.drillInput}
                  value={drill.sets}
                  onChange={e => updateSets(e, drill)}
                >
                  {drill.sets}
                </TextField>
                <TextField 
                  variant='outlined' 
                  label='Reps'
                  size='small'
                  className={classes.drillInput}
                  onChange={e => updateReps(e, drill)}
                  value={drill.reps}
                >
                  {drill.reps}
                </TextField>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </List>
      <Divider className={classes.divider}/>
      <Typography className={classes.availableLabel}>Drills Available</Typography>
      <List>
        {selectableDrills.map(drill => (
          <ListItem
            button 
            key={`selectable-${drill.id}`} 
            onClick={() => assignDrill(drill)}
          >
            <ListItemText primaryTypographyProps={{ variant: 'h6' }}>
              {drill.name}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default DrillAssignmentDialog;