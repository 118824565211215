const styles = {
  root: {
    padding: '20px 0px'
  },
  header: {
    padding: '0px'
  },
  body: {
    padding: '10px 20px'
  },
  customTabs: {
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > div': {
        maxWidth: 80,
        width: '100%',
        backgroundColor: 'black'
      }
    }
  }
};

export default styles;
