import React from 'react';
import { connect } from 'react-redux';
import { CancelToken } from 'axios';
import logger from 'js-logger';
import { detect } from 'detect-browser';
import { history } from '../../store';
import { endOfDay, startOfDay, isAfter } from 'date-fns';
import Modes from '../../constants/modes.constants';

import {
  GetBaseballSwingSummaries,
  DownloadPitchingSummaryDataAsCsv,
  DownloadBaseballSummaryDataAsCsv,
  GetPitchingSummaries,
  DownloadMotionsAsZip
} from '../../network/baseballRequests';
import { GetGolfSwingSummaries, DeleteMotion } from '../../network/motionRequests';
import DownloadMotions from '../../components/downloadMotions';

import { 
  GolfSummaryUtilList,  
  GolfSwingSummaryGraphableValues, 
  GolfSummaryToMap 
} from '../../utils/golfSummary.utils';
import { 
  BaseballSummaryUtilList,  
  BaseballSwingSummaryGraphableValues, 
  BaseballSummaryToMap 
} from '../../utils/baseballSummary.utils';
import {
  PitchingSummaryUtilList,
  PitchingSummaryGraphableValues,
  PitchingSummaryToMap
} from '../../utils/pitchingSummary.utils';

import { getDownloadPermissionsForCurrentOrg } from '../../utils/permissions.utils';


class SwingSummaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      showDownloadMotions: false,
      showDownloadSummaries: false,
      downloadingMotions: false,
      summaries: [],
      filter: {
        value: 5,
        fromDate: new Date(),
        toDate: new Date()
      },
      summaryUtils: this.getSummaryUtils(props.mode),
      errorMsg: null
    };

    this.cancelSource = CancelToken.source();
  }

  async componentDidMount() {
    await this.loadSwingSummaries();
    this.checkFeaturePermissions();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.organization?.id !== this.props.organization?.id ||
      !this.arrayEquals(prevProps.playerIds, this.props.playerIds)) {
      await this.loadSwingSummaries();
    }
  }

  componentWillUnmount() {
    this.cancelSource.cancel();
  }

  arrayEquals(a, b) {
    return a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

  getSummaryUtils = (mode) => {
    switch(mode){
      case Modes.golf:
        return {
          list: GolfSummaryUtilList,
          graphableValues: GolfSwingSummaryGraphableValues,
          toMap: GolfSummaryToMap,
          summaryFunc: GetGolfSwingSummaries
        };
      case Modes.baseball:
        return {
          list: BaseballSummaryUtilList,
          graphableValues: BaseballSwingSummaryGraphableValues,
          toMap: BaseballSummaryToMap,
          summaryFunc: GetBaseballSwingSummaries
        };
      case Modes.baseballPitch:
        return {
          list: PitchingSummaryUtilList,
          graphableValues: PitchingSummaryGraphableValues,
          toMap: PitchingSummaryToMap,
          summaryFunc: GetPitchingSummaries
        };
      default:
        return {
          list: [],
          graphableValues: {},
          toMap: {},
          summaryFunc: () => []
        };
    }
  }

  _swingSummaryParams = () => {    
    var queryParams = this.props;
    let params;
    if (queryParams.organization) {
      params = { organizationId: queryParams.organization.id };
    } else {
      params = { playerIds: queryParams.playerIds };
    }

    if (this.state.filter.value !== 'date') {
      return { ...params, recentMotionCount: this.state.filter.value };
    } 
    else {
      const { fromDate, toDate } = this.state.filter;
      var laterThan = fromDate;
      var earlierThan = toDate;
      if(isAfter(fromDate, toDate)) {
        laterThan = toDate;
        earlierThan = fromDate;
      }
      return { 
        ...params, 
        laterThan: startOfDay(laterThan).toISOString(), 
        earlierThan: endOfDay(earlierThan).toISOString() 
      };
    }
  }

  _deleteMotion = async (motionId) => {
    try {
      await DeleteMotion(motionId);
      this.setState({ summaries: this.state.summaries.filter(x => x.motionId !== motionId ) });
    } catch (e) {
      this.setState({ errorMsg: 'Error deleting swing, please try again.' });
    }
  }

  loadSwingSummaries = async () => {
    this.setState({ loadingData: true });
    var params = this._swingSummaryParams();

    this.cancelSource.cancel();
    this.cancelSource = CancelToken.source();
    let utils = this.getSummaryUtils(this.props.mode);

    try {
      if (params.organizationId != null || 
        (params.playerIds && params.playerIds.length > 0)) {        
        let summaries = await utils.summaryFunc(params);
        this.setState({
          summaries,
          loadingData: false,
          summaryUtils: utils
        });
      } else {
        this.setState({
          summaries: [],
          loadingData: false,
          summaryUtils: utils
        });

      }
    } catch (err) {
      logger.error(err);
      this.setState({ loadingData: false, summaries: [], summaryUtils: utils });
      // TODO: alert error occurred:
    }
  }

  downloadMotions = async () => {
    const params = this._swingSummaryParams();
    this.cancelSource.cancel();
    this.cancelSource = CancelToken.source();

    try {
      this.setState({ downloadingMotions: true });
      const response = await DownloadMotionsAsZip(params, this.cancelSource);
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Motions.zip';
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      logger.error(err);
    } finally {
      this.setState({ downloadingMotions: false });
    }
  }

  checkFeaturePermissions = () => {
    try {
      let { showDownloadMotions, showDownloadSummaries } = 
        getDownloadPermissionsForCurrentOrg();
      this.setState({ showDownloadMotions, showDownloadSummaries });
    } catch (err) {
      logger.error(err);
    }
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  downloadSummariesCsv = async () => {
    let csv;
    var params = this._swingSummaryParams();
    if (this.props.mode === Modes.baseball) {
      csv = await DownloadBaseballSummaryDataAsCsv(params);
    } else if (this.props.mode === Modes.baseballPitch) {
      csv = await DownloadPitchingSummaryDataAsCsv(params);
    }
    this.downloadCsvFile('PlayerSummaries.csv', csv.data);
  }

  downloadCsvFile = (fileName, contents) => {
    let csvContent = 'data:text/csv;charset=utf-8,' + contents;
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement('a');
    link.innerHTML = fileName;
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);

    const browser = detect();
    if (browser && browser.name === 'firefox') {
      document.body.appendChild(link); // only required for firefox
    }
    link.click();
  }

  handleFilterChange = (event) => {
    this.setState(
      { filter: { ...this.state.filter, value: event.target.value }}, 
      () => {
        if(this.state.filter.value !== 'date') {
          this.loadSwingSummaries();
        }
      }
    );
  }

  handleDateChange = (date, isFrom) => {
    if(isFrom) {
      this.setState({ filter: { ...this.state.filter, fromDate: date }});
    } else {
      this.setState({ filter: { ...this.state.filter, toDate: date }});
    }
  }

  viewSwingDetails = (swingId) => {
    history.push('/swingDetails/' + swingId);
  }

  render() {
    return (
      <DownloadMotions 
        {...this.state} 
        handleFilterChange={this.handleFilterChange} 
        handleDateChange={this.handleDateChange}
        submitDateRange={this.loadSwingSummaries}
        viewSwingDetails={this.viewSwingDetails}
        downloadMotionCsvs={this.downloadMotions}
        downloadSummariesCsv={this.downloadSummariesCsv}
        downloadingMotions={this.state.downloadingMotions}
        deleteMotion={this._deleteMotion}
        onSnackbarClose={() => this.setState({ errorMsg: null })}
      />
    );
  }
}

const mapStateToProps = state => {
  return { mode: state.currentMode };
};

export default connect(
  mapStateToProps
)(SwingSummaries);
