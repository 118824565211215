import React from 'react';
import useStyles from './styles';

import { SyncStatus } from '../../../../../constants/captureEvents.constants';
import { formatMMDDYY } from '../../../../../utils/formatting.utils';

import classnames from 'classnames';

import { CircularProgress } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ErrorIcon from '@material-ui/icons/Error';

function SyncProperty({ label, data }) {
  const classes = useStyles();
  return <div className={classes.property}>
    <span className={classes.bold}>{label}</span>
    <span className={classes.propertyData}>{data}</span>
  </div>;
}

function SyncAttemptItem({ syncAttempt, onReview }) {
  const classes = useStyles();
  const timeframeMsg = ({ startDate, endDate }) => {
    if (!startDate && !endDate) return 'All Time';
    if (startDate && endDate) return `${startDate} - ${endDate}`;
    if (startDate) return `Starting From ${startDate}`;
    return `Up To ${endDate}`; // endDate only
  };

  const status = syncAttempt.status;
  const syncAttemptSelected = () => {
    onReview(syncAttempt);
  };

  return <div 
    className={classnames(
      classes.container, 
      { [classes.canClick]: status !== SyncStatus.PROCESSING }
    )} 
    onClick={syncAttemptSelected}
  >
    <div className={classes.syncInfoContainer}>
      <div className={classes.bold}>
        Sync Attempt requested: {formatMMDDYY(syncAttempt.creationTimestamp)}
      </div>
      <SyncProperty label='Serial Code:' data={syncAttempt.serialCode} />
      <SyncProperty label='Sync Timeframe:' data={timeframeMsg(syncAttempt)}/>
      <SyncProperty 
        label='KCoach Time Adjustment (minutes):' 
        data={`${syncAttempt.kCoachTimeAdjustmentMinutes || '0'} minutes`}
      />
      <SyncProperty 
        label='KCoach Time Adjustment (seconds):' 
        data={`${syncAttempt.kCoachTimeAdjustmentSeconds || '0'} seconds`}
      />
      <SyncProperty label='Sync Threshold:' data={`${syncAttempt.timeSyncThresholdSeconds} seconds`}/>
    </div>
    <div className={classes.actionContainer}>
      {
        status === SyncStatus.PROCESSING &&
        <div className={classes.actionItems}>
          <div>Syncing...</div>
          <CircularProgress size={30}/>
        </div>
      }
      {
        status === SyncStatus.COMPLETE &&
        <div className={classes.reviewContainer}>
          <div className={classes.reviewMark}>
            <div>Review Sync</div>
            <ArrowForwardIcon className={classes.forwardIcon}/>
          </div>
        </div>
      }
      {
        status === SyncStatus.SYNC_APPLIED &&
        <div className={classes.actionItems}>
          <div>Sync Applied</div>
          <CheckCircleOutlineIcon className={classes.acceptedIcon}/>
        </div>
      }
      {
        status === SyncStatus.REJECTED &&
        <div className={classes.actionItems}>
          <div>Sync Rejected</div>
          <ThumbDownIcon className={classes.badIcon}/>
        </div>
      }
      {
        status === SyncStatus.FAILED &&
        <div className={classes.actionItems}>
          <div>Sync Attempt Failed</div>
          <ErrorIcon className={classes.badIcon}/>
        </div>
      }
    </div>
  </div>;
};

export default SyncAttemptItem;