import Shape from './shape';
import SHAPES from './constants';

class Circle extends Shape {
  shape = SHAPES.circle;

  constructor(x, y, r, color, lineWidth = 1) {
    super();
    this.x = x;
    this.y = y;
    this.r = r;
    this.color = color;
    this.lineWidth = lineWidth;
  }

  drawOnCanvas(ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI);
    ctx.strokeStyle = this.color;
    ctx.lineWidth = this.lineWidth;
    ctx.stroke();
  }
};

export default Circle;
