// Actions
export const SET_FEATURE_PERMISSIONS = 'global/featurePermissions';


// Reducer
const initialState = null;
export default function reducer(state = initialState, action) {
  switch(action.type) {
    case SET_FEATURE_PERMISSIONS:
      return action.featurePermissions;
    default:
      return state;
  }
};

// Action Creators
export function setFeaturePermissions (featurePermissions) {
  return { type: SET_FEATURE_PERMISSIONS, featurePermissions };
};
