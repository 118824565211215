import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const defaultProps = {
  selected: true
};

function useMotionsWithTag(props = defaultProps) {
  const { selected } = props;
  const { motions, tags } = useSelector(state => state.dataAnalysis);
  
  return useMemo(() => {
    const tagByMotionId = Object.keys(tags)
      .reduce((acc, tagName) => {
        const { motionIds, ...rest } = tags[tagName];
        const tagsToAdd = {};
        motionIds.forEach(motionId => {
          tagsToAdd[motionId] = { ...rest };
        });
        return { ...acc, ...tagsToAdd };
      }, {});

    let motionIds = Object.keys(motions);
    if (selected != null) {
      motionIds = motionIds
        .filter(motionId => tagByMotionId[motionId]?.selected === selected);
    }

    return motionIds.reduce((acc, motionId) => ({
      ...acc,
      [motionId]: {
        ...motions[motionId],
        tag: tagByMotionId[motionId] ?? {}
      }
    }), {});
  }, [motions, tags, selected]);
}

export default useMotionsWithTag;
