import React from 'react';
import { Button, Typography } from '@material-ui/core';

import Eula from '../eula';
import ClientActivationTable from '../clientActivationTable';

import { withStyles } from '@material-ui/core';
import Styles from './styles';

class ClientActionPage extends React.Component {
  render() {
    const { 
      displayEula,
      acceptEula,
      closeEula,
      submittingEula,
      classes,
      submit,
      underCoppaCutoff,
      ...rest
    } = this.props;

    return (
      <div>
        <Eula open={displayEula} 
          acceptEula={acceptEula}
          closeEula={closeEula}
          submittingEula={submittingEula}/>
        <h1>Pending Client Activations</h1>
        {rest.data.length > 0
          ? underCoppaCutoff 
            ? <Typography variant='h5'>
                  In order to activate your clients and start uploading data, please have your 
                  parent/guardian sign the necessary forms with your coach.
            </Typography>
            : <ClientActivationTable {...rest}/>
          : <h3>No more pending client activations.</h3>
        }
        <Button variant='contained'
          color='primary'
          className={classes.submitButton}
          onClick={submit}
          disabled={this.props.data.filter(r => r.active).length === 0}>
          Submit
        </Button>
      </div> 
    );
  }
}

export default withStyles(Styles)(ClientActionPage);