import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  videoContainer: {
    height: '400px', 
    padding: '20px', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  },
  header: {
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    paddingBottom: '20px'
  },
  logoCheckbox: {
    marginRight: 'auto',
    marginLeft: '0px'
  }
}));

export default useStyles;