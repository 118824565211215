import { decimalFormatter } from '../../utils/formatting.utils';

export const PLAYER_HANDEDNESS = 'playerHandedness';
export const fullMotionMetricsValues = [
  [
    {
      key: 'maxXFactor',
      title: 'Max X-Factor',
      formatter: decimalFormatter,
      units: '°',
      abbreviation: 'XFMAX'
    }
  ],
  [
    {
      key: 'club',
      title: 'Club',
      abbreviation: 'CLUB'
    }
  ]
];
