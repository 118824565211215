import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '15px'
  },
  syncList: {
    marginTop: '15px'
  }
}));

export default useStyles;