import { makeStyles } from '@material-ui/styles';
import palette from '../../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  viewMargin: ({ topPadding }) => ({
    marginTop: topPadding
  }),
  actionButtons: {
    backgroundColor: palette.bluePurple,
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.bluePurple,
      filter: 'brightness(80%)'
    },
    padding: '10px 50px'
  },
  topButtons: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonText: {
    fontSize: '14px',
    padding: '1px'
  },
  reviewButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  circularProgress: {
    top: '50%',
    left: '50%',
    zIndex: 100,
    position: 'fixed',
    marginTop: '-23px',
    marginLeft: '-8px'
  }
}));

export default useStyles;