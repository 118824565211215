import Network from './network';
import { STATUS } from '../utils/kAssessments.utils';
import { GetMotion, GetAnalyzedSwingData } from '../network/motionRequests';
import { FULL_MOTION_METRICS } from '../constants/motionSubresources.constants';

const ASSESSMENTS = 'API/Assessments';

export const GetAssessments = async (userId, organizationId, cancelToken) => {
  const resp = await Network.Get(
    `${ASSESSMENTS}/list`, 
    { userId, organizationId, status: STATUS.PUBLISHED }, 
    cancelToken
  );
  return resp.data.items;
};

export const GetDrafts = async (userId, organizationId, cancelToken) => {
  const resp = await Network.Get(
    `${ASSESSMENTS}/list`, 
    { userId, organizationId, status: STATUS.WIP }, 
    cancelToken
  );
  return resp.data.items;
};

export const GetAssessment = async (assessmentId, cancelToken) => {
  const resp = await Network.Get(`${ASSESSMENTS}/${assessmentId}`, null, cancelToken);
  return resp.data;
};

export const CreateAssessment = async (assessment) => {
  const resp = await Network.Post(ASSESSMENTS, assessment);
  return resp.data;
};

export const UpdateDraft = async (assessmentId, assessment) => {
  const resp = await Network.Put(`${ASSESSMENTS}/${assessmentId}`, assessment);
  return resp.data;
};

export const DeleteDraft = async (assessmentId) => {
  const resp = await Network.Delete(`${ASSESSMENTS}/${assessmentId}`);
  return resp.data;
};

export const GetAssessmentWithMotion = async (id, cancelToken) => {
  const assessment = await GetAssessment(id, cancelToken);
    
  // now we must load the motion information
  const motionId = assessment.motionId;
  const [motion, analyzedFrames] = await Promise.all([
    GetMotion(
      motionId,
      { subresources: [FULL_MOTION_METRICS] },
      cancelToken
    ),
    GetAnalyzedSwingData(motionId, cancelToken)
  ]);

  const motionData = {
    motion,
    fullMotionMetrics: motion.fullMotionMetrics,
    analyzedFrames
  };

  return {
    assessment,
    motionData
  };
};