import React from 'react';
import useStyles from './styles';
import classnames from 'classnames';
import { formatDate } from '../../../../../../utils/formatting.utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Checkbox } from '@material-ui/core';

function MotionSelection({ 
  motions = [], 
  enableCheckboxMode,
  onMotionSeen,
  onMotionSelected,
  selectedMotionIds = [],
  seenMotionIds = {},
  highlightedMotion
}) {
  const classes = useStyles();
  return <div className={classes.container}>
    {
      motions.map((motion, idx) => {
        const handedness = motion.fullMotionMetrics.playerHandedness;
        const type = motion.motionType;

        return <div 
          key={motion.id} 
          className={classnames(
            classes.motion,
            { [classes.selected]: highlightedMotion.id === motion.id }
          )}
          onClick={() => onMotionSeen(idx, motion)}
        >
          <div className={classes.motionInfo}>
            <div>
              { formatDate(motion.timestamp) }
            </div>
            <div className={classes.extraInfo}>
              <span>{ handedness } | { type }</span>
            </div>
          </div>

          {
            !enableCheckboxMode && seenMotionIds[motion.id] &&
              <CheckCircleIcon className={classes.reviewed}/>
          }

          {
            enableCheckboxMode && <Checkbox
              checked={selectedMotionIds[motion.id] != null}
              onChange={(_, checked) => onMotionSelected(motion, checked)}
            />
          }

        </div>;
      })
    }
  </div>;
}

export default MotionSelection;