import { GetTimeSyncStats } from '../network/motionRequests';

// Actions
const REQUEST = 'kdashboard/timesyncstats/request';
const ADD = 'kdashboard/timesyncstats/add';
const ERROR = 'kdashboard/timesyncstats/error';

// Reducer
function timeSyncStats(
  state = {
    isFetching: false,
    stats: [],
    error: null
  },
  action = {}
) {
  switch (action.type) {
    case REQUEST:
      return { ...state, isFetching: true };
    case ADD:
      return { ...state, isFetching: false, stats: action.stats };
    case ERROR:
      return { ...state, isFetching: false, error: action.error };
    default:  
      return state;
  }
}

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case REQUEST:
    case ADD:
    case ERROR:
      return { 
        ...state, 
        [action.motionId]: timeSyncStats(state[action.motionId], action) 
      };
    default: 
      return state;
  }
}

// Action Creators
export function RequestTimeSyncStats(motionId) {
  return { 
    type: REQUEST, 
    motionId
  };
}

export function AddTimeSyncStats(motionId, stats) {
  return { 
    type: ADD, 
    motionId,
    stats
  };
}

export function SetTimeSyncError(motionId, error) {
  return { 
    type: ERROR, 
    motionId,
    error: error
  };
}

// Thunks
const NUM_RETRIES = 3;
const RETRY_DELAY_MS = 2000;

export function fetchTimeSyncStats(motionId) {
  return function (dispatch, getState) {
    // no need to request if the data is already in redux
    if (!getState().timeSyncStats[motionId]) {
      function fetchDataRetry(retries) {
        setTimeout(() => fetchData(retries), RETRY_DELAY_MS);
      }

      async function fetchData(retries = NUM_RETRIES) {
        dispatch(RequestTimeSyncStats(motionId));
        try {
          const data = await GetTimeSyncStats(motionId);
          // The time sync model is a bit messy. 
          // All values are strings and not proper json so we need
          // an eval call with some tweaking of the value to make it work.
          // eslint-disable-next-line
          data.adjustedOffsetList = eval('({' + data.adjustedOffsetList + '})');

          dispatch(AddTimeSyncStats(motionId, data));
        } catch (e) {
          if (e.response && e.response.status === 404 && retries > 1) {
            fetchDataRetry(retries - 1);
            return;
          }
          dispatch(SetTimeSyncError(motionId, e));
        }
      };

      fetchData();
    }
  };
}