import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import logger from 'js-logger';
import debounce from 'lodash/debounce';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../../store';
import SimpleBar from 'simplebar-react';
import { sortBy, differenceBy, orderBy } from 'lodash';
import { SetPlayers } from '../../../store/players';
import { SetTeams } from '../../../store/teams';
import { clearOrgActionsTaken } from '../../../store/orgActionsTaken';
import { GetUsers } from '../../../network/userRequests';
import StyledInput from './styledInput';
import KMotionLogo from '../../../assets/KMotionLogoWhite.png';
import useStyles from './styles';
import { getOrganizationId } from '../../../utils/auth.utils';
import { PERMISSIONS } from '../../../constants/permissions.constants';
import 'simplebar/dist/simplebar.min.css';
import { GetAllTeams } from '../../../network/teamRequests';
import FilterByPanelDesktop from './filterByPanelDesktop';
import FilterByPanelMobile from './filterByPanelMobile';
import FilterOptions from './filterByPanelDesktop/filter.constants';
import useIsMobile from '../../../utils/useIsMobile';
import ToggleButtonGroup from '../../../components/toggleButtonGroup';
import SEARCH_OPTIONS from './searchOptions';
import TeamList from './teamList';

const UNASSIGNED_PLAYERS_TEAM_ID = -1;
const SEARCH_TABS = [SEARCH_OPTIONS.PLAYER_NAME, SEARCH_OPTIONS.TEAM_NAME];

const Sidebar = ({ onChange, onTeamsLoaded }) => {
  const [searchText, setSearchText] = useState('');
  const [teamsPlusUnassigned, setTeamsPlusUnassigned] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [filterHasChanged, setFilterHasChanged] = useState(false);
  const [filterOption, setFilterOption] = useState(FilterOptions.all);
  const [selectedSearchIndex, setSelectedSearchIndex] = useState(0);
  const teams = useSelector(state => state.teams);
  const allPlayers = useSelector(state => state.players);
  const showFilterPanel = useSelector(state => 
    state.featurePermissions.includes(PERMISSIONS.kGoFeatureSet));
  const orgActionsTaken = useSelector(state => state.orgActionsTaken);
  const [players, setPlayers] = useState([]);
  const currentUserId = useSelector(state => state.currentPlayer.userId);
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useIsMobile();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    // initial call on load to get players and teams
    const fetchData = async () => {
      try {
        const orgId = getOrganizationId();
        const [players, teams] = await Promise.all([
          GetUsers(null, cancelToken), 
          GetAllTeams(orgId, cancelToken)
        ]);
        dispatch(SetTeams(teams));
        dispatch(SetPlayers(players));
        setPlayers(players);
      } catch (e) {
        if (axios.isCancel(e)) return;
        logger.error(e);
      }
    };
    fetchData();
    return cancelToken.cancel;
  }, [dispatch]);

  useEffect(()=> {
    // when teams or players changes, this orders the players within teams
    if (players.length === 0) {
      setTeamsPlusUnassigned([]);
      if (filterHasChanged) {
        history.push('/player-development/');
        setFilterHasChanged(false);
      }
      return;
    }

    const sortPlayers = players => {
      return sortBy(players, [
        player => player.lastName && player.lastName.toLowerCase(),
        player => player.firstName && player.firstName.toLowerCase()
      ]);
    };

    let playersMap = new Map(players.map(player => [player.userId, player]));
    
    // Not all players may be assigned to a team.
    // We'll create a pseudo 'Unassigned Team' so that they can be grouped.
    let allTeams = [...teams];
    let assignedPlayers = [];
    allTeams.forEach(team => {
      let teamPlayers = team.userTeams
        .filter(user => playersMap.has(user.userId))
        .map(user => playersMap.get(user.userId));
      assignedPlayers.push(...teamPlayers);
      team.players = sortPlayers(teamPlayers);
    });
    allTeams = allTeams.filter(team => team.players.length > 0);
    allTeams = orderBy(allTeams, 'name');

    let teamForUnassignedPlayers = { 
      id: UNASSIGNED_PLAYERS_TEAM_ID, 
      name: 'Unassigned Players', 
      description: '' 
    };
    let unassignedPlayers = differenceBy([...playersMap.values()], assignedPlayers, 'userId');
    if (unassignedPlayers.length > 0) {
      teamForUnassignedPlayers.players = sortPlayers(unassignedPlayers);
      allTeams.push(teamForUnassignedPlayers);
    }
    setTeamsPlusUnassigned(allTeams);
    setFilteredTeams(allTeams);
    onTeamsLoaded(allTeams);

    if (filterHasChanged) {
      if (allTeams.length > 0) {
        onChange(allTeams[0].players[0].userId);
      }
      setFilterHasChanged(false);
    }

  }, [teams, players, onTeamsLoaded, filterHasChanged, onChange]);

  const debounceHandler = useMemo(() => debounce(searchText => {
    // performs search functionality for players or teams depending on
    // what search option is selected
    const { searchFn } = SEARCH_TABS[selectedSearchIndex];
    const searchedTeams = searchFn(teamsPlusUnassigned, searchText);
    setFilteredTeams(searchedTeams);
  }, 250), [selectedSearchIndex, teamsPlusUnassigned]);

  useEffect(() => {
    debounceHandler(searchText);
  }, [searchText, debounceHandler]);


  const filterChanged = ({ filterOption, userIds }) => {
    dispatch(clearOrgActionsTaken());
    setFilterOption(filterOption);
    if (filterOption === FilterOptions.needsForms) {
      history.push('/player-development/pending-clients');
    } else {
      setFilterHasChanged(true);
    }
    const filteredPlayers = filterOption === FilterOptions.all 
      ? allPlayers  
      : allPlayers.filter(player => userIds.includes(player.userId));
    setPlayers(filteredPlayers);
  };

  
  const checkPlayerAction = useCallback(player => {
    if (filterOption !== FilterOptions.needsActionPlan && 
        filterOption !== FilterOptions.needsCapture) {
      return '';
    } 
    const playerHasAction = orgActionsTaken.some(action =>
      action.userId === player.userId && 
      action.actionItem === filterOption);
    
    let actionText = '';
    if (playerHasAction) {
      actionText = filterOption === FilterOptions.needsCapture 
        ? 'Capture has just taken place'
        : 'Action Plan has just been added';
    }
    return actionText;
  }, [filterOption, orgActionsTaken]);

  const hideTeams = teams.length === 1 && teams[0].id === UNASSIGNED_PLAYERS_TEAM_ID;
  const { Icon } = SEARCH_TABS[selectedSearchIndex];

  return <div className={classes.container}>
    <SimpleBar className={classes.scrollView}>
      <div className={classes.headerContainer}>
        <img 
          src={KMotionLogo} 
          className={classes.logoImg}
          alt=''
          onClick={() => history.push('/player-development')}
        />
        {showFilterPanel && isMobile && <FilterByPanelMobile />}
        <StyledInput
          RightAdornment={<Icon className={classes.icon} />}
          placeholder='Search'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className={classes.searchBar}
          name='player-search-bar'
        />

        <div className={classes.searchByContainer}>
          <ToggleButtonGroup
            selectedIndex={selectedSearchIndex}
            renderItem={({ display }) => display}
            onSelect={index => setSelectedSearchIndex(index)}
            options={SEARCH_TABS} />
        </div>

        {showFilterPanel && !isMobile && 
          <FilterByPanelDesktop
            totalPlayersCount={allPlayers.length}
            className={classes.filterByPanel} 
            onFilterChange={filterChanged} 
          /> 
        }
      </div>
      <TeamList 
        teams={filteredTeams}
        hideTeams={hideTeams} 
        selectedUserId={currentUserId}
        onChange={onChange} 
        checkPlayerAction={checkPlayerAction}
      />
    </SimpleBar>
    <div className={classes.rightBorder} />
  </div>;
};

Sidebar.props = {
  onChange: PropTypes.func,
  onTeamsLoaded: PropTypes.func, // will fire method with teams passed in as parameter
  currentUserId: PropTypes.string
};

Sidebar.defaultProps = {
  onChange: () => { },
  onTeamsLoaded: () => { }
};

export default Sidebar;