import Network from './network';
import formatISO from 'date-fns/formatISO';

const URL = 'Api/VideoAnalyses/';

export async function GetAllVideoAnalyses(userId, organizationId, isCompleted, cancelToken) {
  const resp = await Network.Get(URL, { userId, organizationId, isCompleted }, cancelToken);
  return resp.data && resp.data.items;
}

export async function CreateAnalysis(userId, organizationId, type) {
  const resp = await Network.Post(URL, { 
    userId, 
    organizationId,
    creationTimestamp: formatISO(new Date()),
    type
  });
  return resp.data;
}

export async function GetAnalysis(id, cancelToken) {
  const resp = await Network.Get(URL + id, null, cancelToken);
  return resp.data;
}

export async function DeleteAnalysis(id) {
  const resp = await Network.Delete(URL + id);
  return resp.data;
}

export async function SaveAnnotatedVideo(analysisId, body) {
  const resp = await Network.Post(URL + `${analysisId}/VideoAnnotations`, body);
  return resp.data;
}

export async function DeleteAnnotatedVideo(analysisId, annotationId, cancelToken) {
  const fullUrl = URL + `${analysisId}/VideoAnnotations/${annotationId}`;
  const resp = await Network.Delete(fullUrl, null, cancelToken);
  return resp.data;
}

export async function UpdateAnalysis(id, analysis) {
  const resp = await Network.Put(URL + id, analysis);
  return resp.data;
}

export async function PatchAnnotation(analysisId, annotationId, patchDoc) {
  const fullUrl = URL + `${analysisId}/VideoAnnotations/${annotationId}`;
  const resp = await Network.Patch(fullUrl, patchDoc);
  return resp.data;
}