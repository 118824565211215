import Palette from '../../styles/palette';

const styles = {
  submitButton: {
    width: '100%',
    height: '65px',
    borderRadius: '0',
    backgroundColor: Palette.primaryRed,
    color: 'white',
    fontSize: '18px',
    transition: 'font-size .3s ease',
    '&:hover': {
      background: Palette.primaryRed,
      fontSize: '22px'
    }
  },
  fullWidth: { width: '100%' },
  paddingHorizontal: { padding: '0 20px' },
  verticalMargin: { margin: '10px' },
  textFieldContainer: { margin: '5px 20px' },
  formContainer: {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center'
  },
  lockIcon: {
    color: Palette.darkGray,
    transform: 'scale(0.8)'
  },
  legalLinks: { 
    margin: '20px'
  }
};

export default styles;
