import { makeStyles } from '@material-ui/core/styles';
import Palette from '../../styles/palette';

export default makeStyles(_ => ({
  container: {
    padding: '20px',
    marginTop: '50px'
  },
  paper: {
    padding: '20px',
    marginTop: '10px'
  },
  drillList: {
    width: '85%'
  },
  addButton: {
    alignSelf: 'center'
  },
  snackbar: {
    backgroundColor: Palette.success
  }
}));