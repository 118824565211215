import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  assignedDrillCountTextField: {
    width: '100px', 
    marginRight: '20px'
  },
  listItemPaper: {
    width: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    padding: '5px 10px'
  },
  drillName: {
    flex: 1
  }
}));

export default useStyles;
