import { makeStyles } from '@material-ui/styles';
import palette from '../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  container: {
    position: 'sticky',
    /*** 
         * this value is the height of the top tabs (constant) plus padding 
         * ***/
    top: '65px',
    left: 0,
    right: 0,
    zIndex: 1
  },
  headerContainer: ({ height }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: `${height || 50}px`,
    backgroundColor: palette.lightGray,
    padding: '0px 15px',
    borderRadius: '2px',
    border: `1px solid ${palette.mediumGray}`
  }),
  graphButton: {
    marginTop: '10px',
    backgroundColor: 'red',
    width: '100px',
    height: '100px',
    position: 'absolute'
  },
  buttons: {
    marginTop: '5px',
    position: 'absolute',
    top: '65px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  }
}));

export default useStyles;