import { makeStyles } from '@material-ui/core/styles';
import Palette from 'styles/palette';

const useStyles = makeStyles(() => ({
  container: {
    paddingLeft: '20px'
  },
  accounts: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  input: {
    width: '150px',
    marginRight: '5px'
  },
  emailExistsButtonGroup: {
    marginTop: '15px'
  },
  emailExistsContainer: {
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: '10px'
  },
  hidden: {
    visibility: 'hidden'
  },
  migrateButtonContainer: {
    marginTop: '25px'
  },
  migrateButton: {
    backgroundColor: Palette.red,
    color: Palette.white,
    '&:hover': {
      backgroundColor: Palette.error,
      color: Palette.white
    }
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: '10px'
  }
}));

export default useStyles;