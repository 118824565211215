import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { 
  Checkbox, 
  CircularProgress, 
  Divider, 
  FormControl,
  FormControlLabel, 
  Grid, 
  IconButton, 
  InputAdornment, 
  Switch, 
  TextField, 
  Typography,
  Paper,
  InputLabel,
  Select,
  LinearProgress
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import { formatMMDD, formatTimeWithSeconds } from '../../../utils/formatting.utils';
import useStyles from './styles';
import { calculatePercentileTableData } from './playerPercentiles.utils';
import PercentileTable from './PercentileTable';
import PercentileScatterPlot from './PercentileScatterPlot';
import MotionInfoDialog from './motionInfoDialog';
import { MAX_SPEED_KEYS, PEAK_SPEED_KEYS, SEGMENTS, SEGMENTS_LIST } from './playerPercentiles.constants';
import {
  setExpandedMotionId,
  fetchMotions,
  setMotionBeforeToggle,
  setMotionAfterToggle,
  setStartDate,
  setEndDate,
  setWeight,
  setShowMaxSpeeds,
  setFocusSegment
} from '../../../store/playerPercentiles';
import GenerateReportButton from './GenerateReportButton';
import ProgressButton from '../../../components/progressButton';
import { PLAYER_HANDEDNESS } from '../../../constants/motionDetails/fullMotionMetrics.constants';
import useNetworkRequest from 'network/useNetworkRequest';

function PlayerPercentiles() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.currentPlayer);
  const playerPercentilesData = useSelector(state => state.playerPercentiles);
  const classes = useStyles();

  const { 
    weight, 
    showMaxSpeeds, 
    expandedMotionId,
    startDate,
    endDate,
    focusSegment,
    moreMotionsAvailable,
    initialRequestMade,
    motions: motionsState
  } = playerPercentilesData;
  const { items: motions, loading } = motionsState;

  const speedKeys = showMaxSpeeds ? MAX_SPEED_KEYS : PEAK_SPEED_KEYS;

  const beforeMotions = useMemo(() => { return motions.filter(x => x.before); }, [motions]);
  const afterMotions = useMemo(() => { return motions.filter(x => x.after); }, [motions]); 
  
  const selectedMotion = expandedMotionId 
    ? motions.find(x => x.id === expandedMotionId)
    : null;
  const setSelectedMotion = motionId => {
    dispatch(setExpandedMotionId(motionId));
  };

  useEffect(() => {
    // lazy load first set of motions
    if (!initialRequestMade) {
      dispatch(fetchMotions());
    }
  }, [dispatch, initialRequestMade]);
  
  const getBeforePercentiles = useCallback(async () => {
    return await calculatePercentileTableData(beforeMotions, weight, speedKeys);      
  }, [beforeMotions, weight, speedKeys]);

  const getAfterPercentiles = useCallback(async () => {
    return await calculatePercentileTableData(afterMotions, weight, speedKeys);      
  }, [afterMotions, weight, speedKeys]);

  const [
    beforePercentileData,
    loadingBeforePercentileData,
    errorBeforePercentileData
  ] = useNetworkRequest([], 'getBeforePercentiles', getBeforePercentiles);
    
  const [
    afterPercentileData,
    loadingAterPercentileData,
    errorAfterPercentileData
  ] = useNetworkRequest([], 'getAfterPercentiles', getAfterPercentiles);

  const errorLoadingPercentilesData = 
    errorBeforePercentileData || errorAfterPercentileData;
  const loadingPercentilesData = loadingBeforePercentileData || loadingAterPercentileData;
  return <div className={classes.container}>
    
    <div className={classes.headerOptions}>
      <KeyboardDatePicker
        label='Start Date'
        variant='inline'
        inputVariant='outlined'
        format='MM/dd/yy'
        disableFuture
        maxDate={endDate}
        value={startDate}
        className={classes.paddedInput}
        onChange={d => dispatch(setStartDate(d))}
      />
      <KeyboardDatePicker
        label='End Date'
        variant='inline'
        inputVariant='outlined'
        format='MM/dd/yy'
        disableFuture
        minDate={startDate}
        value={endDate}
        className={classes.paddedInput}
        onChange={d => dispatch(setEndDate(d))}
      />

      <TextField 
        variant='outlined'
        label='Weight'
        type='number'
        value={weight}
        onChange={({ target }) => dispatch(setWeight(target.value))}
        InputProps={{
          endAdornment: <InputAdornment position='end'>lbs</InputAdornment>
        }}
        className={classes.paddedInput}
        error={weight === ''}
      />

      <FormControlLabel 
        label='Max Speeds'
        className={classes.toggle}
        control={<Switch 
          checked={showMaxSpeeds} 
          onChange={e => dispatch(setShowMaxSpeeds(e.target.checked))} 
          name='MaxSpeedsToggle'
          color='primary'
        />}
      />

      <FormControl className={classes.paddedInput} variant='outlined'>
        <InputLabel htmlFor='focus-segment-picker'>Focus Segment</InputLabel>
        <Select
          native
          value={focusSegment}
          label='Focus Segment'
          onChange={({ target }) => dispatch(setFocusSegment(target.value))}
          inputProps={{ name: 'focus-segment-picker', id: 'focus-segment-picker' }}
        >
          <option aria-label='None' value='' />
          {SEGMENTS_LIST.map(segment => <option
            key={`select_option_${segment}`}
            value={segment}
          >
            {segment}
          </option>)}
        </Select>
      </FormControl>

      <GenerateReportButton
        name={user.firstName}
        focusSegment={focusSegment} 
        weight={weight}
        disabled={weight.trim() === '' || focusSegment === ''}
        className={classes.topMargin}
      />
    </div>
  
    <Grid container className={classes.topMargin} spacing={2} alignContent='center'>
      <Grid item xs={12}>
        {loading && <CircularProgress />}
        
        {!loading && motions.length === 0 && <Typography>
          No motions found in this date range.
        </Typography>}
        
        {!loading && motions.length > 0 && <div className={classes.motionTableContainer}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th colSpan={3} className={classes.headerCell}>
                  {moreMotionsAvailable && <ProgressButton 
                    variant='outlined'
                    showProgress={loading} 
                    onClick={() => dispatch(fetchMotions())}
                  >
                    Load Earlier Motions
                  </ProgressButton>}
                </th>
                <th className={classes.headerCell}>Pelvis</th>
                <th className={classes.headerCell}>Torso</th>
                <th className={classes.headerCell}>Arm</th>
                <th className={classes.headerCell}>Hand</th>
                <th className={classes.headerCell}>Before</th>
                <th className={classes.headerCell}>After</th>
                <th className={classes.headerCell}>Handedness</th>
              </tr>
            </thead>
            <tbody>
              {motions.map(motion => <React.Fragment key={motion.id}>
                <tr key={motion.id}>
                  <td>
                    <IconButton onClick={() => setSelectedMotion(motion.id)}>
                      <InfoIcon />
                    </IconButton>
                  </td>
                  <td className={classes.paddedCell}>
                    {formatMMDD(motion.timestamp)}<br />
                    {formatTimeWithSeconds(motion.timestamp)}
                  </td>
                  <td className={classes.paddedCell}>
                    {motion.launchMonitorData?.ballspeedMph?.toFixed(1) ?? 'N/A'} mph
                  </td>
                  <td>{motion.fullMotionMetrics[speedKeys[SEGMENTS.pelvis]]}</td>
                  <td>{motion.fullMotionMetrics[speedKeys[SEGMENTS.torso]]}</td>
                  <td>{motion.fullMotionMetrics[speedKeys[SEGMENTS.arm]]}</td>
                  <td>{motion.fullMotionMetrics[speedKeys[SEGMENTS.hand]]}</td>
                  <td>
                    <Checkbox 
                      checked={motion.before || false}
                      onChange={e => dispatch(
                        setMotionBeforeToggle(motion.id, e.target.checked))}
                      classes={{ root: classes.beforeCheckbox, checked: classes.checked }}
                    />
                  </td>
                  <td>
                    <Checkbox 
                      checked={motion.after || false}
                      onChange={e => dispatch(
                        setMotionAfterToggle(motion.id, e.target.checked))}
                      classes={{ root: classes.afterCheckbox, checked: classes.checked }}
                    />
                  </td>
                  <td>{motion.fullMotionMetrics[PLAYER_HANDEDNESS]}</td>
                </tr>
                <tr>
                  <td colSpan={10}><Divider /></td>
                </tr>
              </React.Fragment>)}
            </tbody>
          </table>
        </div>}
      </Grid>
      
      <Grid item xs={12}>
        {errorLoadingPercentilesData && <MuiAlert elevation={6} variant='filled' severity='error'>
          There was an error loading percentiles data.
        </MuiAlert>}
        {loadingPercentilesData && <LinearProgress />}
        <div className={classes.scatterPlot}>
          <PercentileScatterPlot 
            beforePercentiles={beforePercentileData.map(x => x.percentiles)}
            afterPercentiles={afterPercentileData.map(x => x.percentiles)}
          />
        </div>
      </Grid>
      
      <Grid item xs={12}>
        <Paper className={classes.topMargin}>
          <PercentileTable 
            beforeData={beforePercentileData} 
            afterData={afterPercentileData} 
            focusSegment={focusSegment}
          />
        </Paper>
      </Grid>
    </Grid>

    <MotionInfoDialog 
      open={selectedMotion != null}
      motion={selectedMotion}
      onClose={() => setSelectedMotion(null)}
    />
  </div>;
}

export default PlayerPercentiles;