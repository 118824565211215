import React, { useState, useEffect } from 'react';
import KinematicSequenceGraphWithLabels from '../kinematicSequenceGraphWithLabels';

export default React.memo(props => {
  const { pointWasSelected = () =>{}, initialColor = 'green' } = props;
  const [selectedPoints, setSelectedPoints] = useState([]);
  
  useEffect(() => {
    if(selectedPoints.length > 0) {
      // Update our points  to our passed in color:
      const newPoints = selectedPoints.map((point) => {
        return { ...point, color: initialColor };
      });
      setSelectedPoints(newPoints);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialColor]);
  
  return <KinematicSequenceGraphWithLabels 
    {...props} 
    labelPoints={selectedPoints}
    onGraphClick={(point) => {
      setSelectedPoints([{ ...point, color: initialColor }] );
      pointWasSelected(point);
    }}
    isEditing={true}
  />;
});
