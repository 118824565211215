import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import orderBy from 'lodash/orderBy';

const GROUPS = {
  concurrent: 'Concurrent',
  recent: 'Recent',
  upcoming: 'Upcoming'
};

function CaptureEventsAutocomplete({ 
  captureEvents, 
  selectedEvent, 
  setSelectedEvent, 
  currentEvent
}) {
  function getOrderedOptions() {
    const now = new Date();
    const currentStart = new Date(currentEvent?.startDate);
    const currentEnd = new Date(currentEvent?.endDate);
    
    function overlapsCurrentEvent(item) {
      if (currentEvent == null) {
        return false;
      }
      
      const itemStart = new Date(item?.startDate);
      const itemEnd = new Date(item?.endDate);
      return (itemStart <= currentEnd && itemEnd >= currentStart)
        || (itemEnd >= currentStart && itemStart <= currentEnd);
    }

    const concurrentEvents = [];
    const upcomingEvents = [];
    const recentEvents = [];

    if (currentEvent != null) {
      concurrentEvents.push({ ...currentEvent, group: GROUPS.concurrent });
    }

    orderBy(captureEvents, x => new Date(x?.startDate), ['desc']).forEach(item => {
      if (item.id === currentEvent?.id) {
        return;
      }
      if (overlapsCurrentEvent(item)) {
        concurrentEvents.push({ ...item, group: GROUPS.concurrent });
      }
      else if (new Date(item.startDate) <= now) {
        recentEvents.push({ ...item, group: GROUPS.recent });
      }
      else {
        upcomingEvents.push({ ...item, group: GROUPS.upcoming });
      }
    });

    return [
      ...concurrentEvents,
      ...recentEvents,
      ...upcomingEvents.reverse() // put closest events first
    ];
  }

  return <Autocomplete
    id='capture-event'
    fullWidth
    autoHighlight
    options={getOrderedOptions()}
    onChange={(_, value) => setSelectedEvent(value)}
    value={selectedEvent}
    defaultValue={currentEvent}
    groupBy={option => option.group}
    getOptionLabel={option => option.name ?? ''}
    getOptionSelected={option => option.id === selectedEvent?.id}
    renderOption={option => <div>
      <Typography>
        {option.id === currentEvent?.id && <>&bull;&nbsp;</>}  
        {option.name}
      </Typography>
      <Typography variant='caption'>{option.startDate} - {option.endDate}</Typography>
    </div>}
    renderInput={params => <TextField 
      {...params}
      label='Capture Event' 
      variant='outlined'
      inputProps={{ ...params.inputProps, 'data-lpignore': true }}
    />}
  />;
}

export default CaptureEventsAutocomplete;