import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Link
} from '@material-ui/core';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import IconTextField from '../../components/iconTextField';
import ProgressButton from '../../components/progressButton';
import { CreateUser } from '../../network/userRequests';
import { SetCurrentPlayer } from '../../store/players';
import AuthenticationService from '../../services/authentication';
import useInput from '../../utils/useInput';
import useStyles from './styles';
import Logger from 'js-logger';
import { history } from '../../store';
import useTrackingServices, { EVENT_NAMES } from '../../services/useTrackingServices';

function CreateAccountStep({ onComplete, organizationId, onShowLogin }) {
  const tracking = useTrackingServices();
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [accountErrMsg, setAccountErrMsg] = useState('');
  const emailInput = useInput({
    trimValue: true,
    validation: email => /^.+@.+\..+$/.test(email),
    validationMsg: 'Must input a valid email' 
  });
  const passwordInput = useInput({
    validation: pwd => pwd.length > 5, 
    validationMsg: 'Passwords need to be at least 6 characters long'
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const fields = [emailInput, passwordInput];

  function fieldsAreValid() {
    fields.forEach(field => field.clearError());
    const results = fields.map(field => field.validate());
    return results.every(isSuccess => isSuccess);
  }

  async function createAccount(e) {
    e.preventDefault();
    if (!fieldsAreValid()) return;
    
    setIsCreatingAccount(true);
    try {
      const email = emailInput.value.trim();
      var params = {
        email,
        password: passwordInput.value,
        organizationId
      };
      const user = await CreateUser(params);
      tracking.track(
        EVENT_NAMES.accountCreationClicked,
        { 'Email Address': email, 'KMI UserId': user.userId });

      dispatch(SetCurrentPlayer(user));
      const authService = new AuthenticationService();
      authService.loginProgrammatically(
        params.email,
        params.password, 
        () => history.push('/Login')
      );
      onComplete(user);
    } catch(e) {
      if (e.response && e.response.status === 409) {
        setAccountErrMsg('There is already an account with this email. ' +
          'Please click the "already have an account below" link below and sign in.');
      } else {
        setAccountErrMsg('We\'re currently experiencing technical difficulties please try again later.');
      }
      Logger.error('Error when attempting to create accounts.', e);
    }

    setIsCreatingAccount(false);
  }

  return (
    <form onSubmit={createAccount}>
      <CardContent>
        <Typography className={classes.introduction}>
          Welcome to Babe Ruth League App registration!
          To get started please provide the email address you'd like 
          to use for your account and create a password that has 6 or more characters
        </Typography>
        <IconTextField
          {...emailInput.bind}
          autoFocus
          placeholder='Email' 
          icon={<PersonOutlineIcon />}
          error={emailInput.inError}
          errorMsg={emailInput.errorMsg}
          disabled={isCreatingAccount}
          type='email'
        />
        <IconTextField
          {...passwordInput.bind}
          placeholder='Password' 
          icon={<LockOutlinedIcon />}
          error={passwordInput.inError}
          errorMsg={passwordInput.errorMsg}
          disabled={isCreatingAccount}
          type='password'
        />
        <br />
        <Typography color='error'>{accountErrMsg}</Typography>
        <Typography>By clicking Create you are agreeing to K-Motion's&nbsp;
          <Link href='https://www.k-motion.com/eula/' target='_blank'>End User License</Link>,&nbsp;
          <Link href='https://www.k-motion.com/terms-of-use/' target='_blank'>Terms of Use</Link>, and&nbsp;
          <Link href='https://www.k-motion.com/privacy-policy-kgo/' target='_blank'>Privacy Policy</Link>.
        </Typography>
        <br />
      </CardContent>
      <CardActions className={classes.cardActions}>
        <ProgressButton 
          showProgress={isCreatingAccount}
          variant='contained' 
          color='primary' 
          className={classes.nextButton}
          disabled={!emailInput.value || !passwordInput.value}
          type='submit'
        >
          Create
        </ProgressButton>
      </CardActions>
      <Grid container justify='flex-end'>
        <Grid item>
          <Button color='primary' onClick={onShowLogin}>
            <b>Already have an account?</b>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default CreateAccountStep;