import { useCallback } from 'react';
import Network from './network';
import { getOrganizationId } from '../utils/auth.utils';
import orderBy from 'lodash/orderBy';
import useNetworkRequest from './useNetworkRequest';

const USERS = 'API/Users/';
const WITH_FORMS = 'WithForms';
const MEASUREMENTS_SUFFIX = '/Measurements';
const ORGANIZATION_PROPERTIES = 'OrganizationUserProperties';
const VERIFY_EMAIL_ADDRESS = 'VerifyEmailAddress';

const SEND_SET_PASSWORD_EMAIL = '/SendSetPasswordEmail';
const MOBILITY_SCREENS_SUFFIX = '/MobilityScreens';
const RESET_PASSWORD = '/ResetPassword';
const COMMUNICATION_CHANNELS = '/CommunicationChannels';
const ACCOUNT_ASSOCIATIONS = `${USERS}AccountAssociations`;

export const GetUser = async (userId, cancelSource) => {
  const resp = await Network.Get(USERS + userId, { includeKGoExperience: true }, cancelSource);
  return resp.data;
};

export const GetUsers = async (params, cancelSource) => {
  const organizationId = getOrganizationId();
  const resp = await Network.Get(USERS, { organizationId, ...params }, cancelSource);
  return resp.data.items;
};

export const GetAllPaginatedUsers = async (filters, cancelSource) => {
  const resp = await Network.Get(USERS, filters, cancelSource);
  return resp.data;
};

export const CreateUser = async (params, token) => {
  var headers = { 
    'content-type': 'application/json' 
  };
  if (token) {
    headers.token = token;
  }
  var resp = await Network.Post(USERS, params, headers);
  return resp.data;
};

export const CreateUserWithForms = async (params, files) => {
  var headers = { 'content-type': 'multipart/form-data' };
  var bodyFormData = new FormData();
  for (var key in params) {
    bodyFormData.set(key, params[key]);
  }
  for (var fileKey in files) {
    bodyFormData.append(fileKey, files[fileKey]);
  }
  var resp = await Network.Post(USERS + WITH_FORMS, bodyFormData, headers);
  return resp.data;
};

export const UpdateOrganizationUserProperties = async (userId, params) => {
  const url = USERS + userId + '/' + ORGANIZATION_PROPERTIES;
  const resp = await Network.Put(url, params);
  return resp.data;
};

export const DeleteOrganizationUserProperties = async organizationUserPropertiesId => {
  const url = USERS + ORGANIZATION_PROPERTIES + '/' + organizationUserPropertiesId;
  await Network.Delete(url);
};

export const GetUserMeasurements = async (userId, cancelToken) => {
  const url = USERS + userId + MEASUREMENTS_SUFFIX;
  var resp = await Network.Get(url, null, cancelToken);
  return resp.data.items;
};

export const GetLatestUserMeasurement = async (userId, cancelToken) => {
  const measurements = await GetUserMeasurements(userId, cancelToken);
  const sortedMeasurements = orderBy(measurements, 'endTime', 'desc');
  return sortedMeasurements.length > 0 ? sortedMeasurements[0] : null;
};

export const useLatestUserMeasurement = (userId) => {
  const measurementRequest = useCallback(() => GetLatestUserMeasurement(userId), [userId]);
  return useNetworkRequest({}, 'GetLatestMeasurement', measurementRequest);
};

export const UpdateUserMeasurements = async (userId, params) => {
  const url = USERS + userId + MEASUREMENTS_SUFFIX;
  const resp = await Network.Put(url, params);
  return resp.data;
};

export const GetMobilityScreens = async (userId, cancelToken) => {
  const url = USERS + userId + MOBILITY_SCREENS_SUFFIX;
  const resp = await Network.Get(url, null, cancelToken);
  return resp.data.items;
};

export const PutMobilityScreen = async (userId, screen) => {
  const url = USERS + userId + MOBILITY_SCREENS_SUFFIX;
  return Network.Put(url, screen);
};

export const SetUserPassword = async (userId, password, token) => {
  const url = USERS + userId + RESET_PASSWORD;
  const resp = await Network.Put(url, { password }, { token });
  return resp.data;
};

export const GetAccountAssociations = async (userId, subResources, cancelToken) => {
  const url = ACCOUNT_ASSOCIATIONS;
  const resp = await Network.Get(url, { associatedUserId: userId, subResources }, cancelToken);
  return resp.data;
};

export const DeleteAccountAssociation = async (id) => {
  const url = ACCOUNT_ASSOCIATIONS + '/' + id;
  await Network.Delete(url);
};

export const GetAccountAssociationsAsPrimary = async (userId, subResources, cancelToken) => {
  const url = ACCOUNT_ASSOCIATIONS;
  const resp = await Network.Get(url, { userId, subResources }, cancelToken);
  return resp.data;
};

export const CreateAccountAssociation = async (userId, associatedUserId) => {
  const url = ACCOUNT_ASSOCIATIONS;
  const resp = await Network.Post(url, { userId, associatedUserId });
  return resp.data;
};

export const SendSetPasswordEmail = async (userId) => {
  const url = USERS + userId + SEND_SET_PASSWORD_EMAIL;
  const resp = await Network.Post(url);
  return resp.data;
};

export const VerifyEmailAddress = async (tokenId, token) => {
  var headers = { 
    'content-type': 'application/json', 
    'token': token
  };
  const url = USERS + VERIFY_EMAIL_ADDRESS + `?tokenId=${tokenId}`;
  const resp = await Network.Post(url, null, headers);
  return resp.data;
};

export const PatchUser = async (userId, changes) => {
  const url = USERS + userId;
  const resp = await Network.Patch(url, changes);
  return resp.data;
};

export const GetCommunicationChannels = async (userId, cancelToken) => {
  const url = USERS + userId + COMMUNICATION_CHANNELS;
  const resp = await Network.Get(url, null, cancelToken);
  return resp.data;
};

export const CreateCommunicationChannel = async (userId, channel) => {
  const url = USERS + userId + COMMUNICATION_CHANNELS;
  const resp = await Network.Post(url, channel);
  return resp.data;
};

export const DeleteCommunicationChannel = async (userId, channelId) => {
  const url = USERS + userId + COMMUNICATION_CHANNELS + '?id=' + channelId;
  const resp = await Network.Delete(url);
  return resp.data;
};

export const UpsertCommunicationChannel = async (userId, communicationChannel) => {
  const url = USERS + userId + COMMUNICATION_CHANNELS;
  const resp = await Network.Put(url, communicationChannel);
  return resp.data;
};