import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import endOfDay from 'date-fns/endOfDay';
import round from 'lodash/round';
import pickBy from 'lodash/pickBy';
import Logger from 'js-logger';
import MotionSelectionView from './motionSelectionView';
import useInput from '../../../utils/useInput';
import { 
  addPercentilesAndKeyFramePositionsToMotions, 
  MOTION_REQUEST_PARAMS 
} from './motionSelection.utils';
import usePagedEndpoint from '../../../utils/usePagedEndpoint';
import { GetMotionsForUser } from '../../../network/motionRequests';
import useNetworkRequest from '../../../network/useNetworkRequest';
import { GetLatestUserMeasurement, UpdateUserMeasurements } from '../../../network/userRequests';
import UnitConverter from '../../../utils/unitConverter.utils';

const PAGE_SIZE = 10;

function MotionSelection({ userId, onCancel, onComplete }) {
  const dataAnalysis = useSelector(state => state.dataAnalysis);
  const initialSelectedMotions = pickBy(dataAnalysis.motions, m => m.userId === userId);

  const [page, setPage] = useState(1);
  const [selectedMotions, setSelectedMotions] = useState(initialSelectedMotions);
  const [motionsEarlierThanFilter, setMotionsEarlierThanFilter] = useState(new Date());
  const [savingWeight, setSavingWeight] = useState(false);
  const [weightErrorMsg, setWeightErrorMsg] = useState('');
  const { setValue: setPlayerWeight, ...playerWeightInput } = useInput();

  const earlierThanString = endOfDay(motionsEarlierThanFilter).toISOString();
  const motionsRequest = useCallback(async (params, cancelToken) =>
    GetMotionsForUser(userId, { earlierThan: earlierThanString, ...params }, cancelToken),
  [userId, earlierThanString]);
  const {
    items,
    hasPrev,
    hasMore,
    totalPages,
    currentPage,
    isFetching
  } = usePagedEndpoint(motionsRequest, MOTION_REQUEST_PARAMS, PAGE_SIZE, page);

  const latestMeasurementRequest = useCallback(async (cancelSource) =>
    GetLatestUserMeasurement(userId, cancelSource),  
  [userId]);
  const [
    latestMeasurement,
    measurementsLoading
  ] = useNetworkRequest(null, 'getLatestUserMeasurement', latestMeasurementRequest);

  useEffect(() => {
    const { weightKilograms } = latestMeasurement ?? {};
    if (!measurementsLoading && weightKilograms) {
      const weightValue = round(UnitConverter.kgToLb(weightKilograms), 3);
      setPlayerWeight(weightValue);
    }
  }, [setPlayerWeight, latestMeasurement, measurementsLoading]);

  useEffect(() => {
    setPage(1);
  }, [motionsEarlierThanFilter]);

  const getWeightInputNumericalValue = () => {
    return playerWeightInput.value === '' || isNaN(playerWeightInput.value) 
      ? 0 
      : parseFloat(playerWeightInput.value);
  };

  const onSave = async () => {
    setSavingWeight(true);
    setWeightErrorMsg('');
    try {
      const weightKilograms = round(UnitConverter.lbToKg(getWeightInputNumericalValue()), 3);
      if (weightKilograms !== latestMeasurement?.weightKilograms) {
        const newMeasurement = { 
          ...latestMeasurement, 
          id: 0,
          weightKilograms,
          startTime: (new Date()).toISOString()
        };
        await UpdateUserMeasurements(userId, newMeasurement); 
      }
      setSavingWeight(false);

      const populatedMotions = await addPercentilesAndKeyFramePositionsToMotions(
        selectedMotions, 
        getWeightInputNumericalValue());
      onComplete(populatedMotions);
    } catch (e) {
      Logger.error(e, 'Error updating user measurement for user ' + userId);
      setWeightErrorMsg('Error saving weight, please try again.');
      setSavingWeight(false);
      return;
    }
  };

  return <MotionSelectionView 
    motions={items}
    motionsLoading={isFetching}
    selectedMotions={selectedMotions}
    onSelectedMotionsChange={setSelectedMotions}
    motionsEarlierThan={motionsEarlierThanFilter}
    setMotionsEarlierThan={setMotionsEarlierThanFilter}
    pageSize={PAGE_SIZE}
    currentPage={currentPage}
    totalPages={totalPages}
    onCancel={onCancel}
    onSave={onSave}
    onNext={hasMore ? () => setPage(prev => prev + 1) : null}
    onPrevious={hasPrev ? () => setPage(prev => prev - 1) : null}
    saveDisabled={getWeightInputNumericalValue() <= 0}
    saveLoading={savingWeight}
    actions={<Grid container spacing={1} direction='row' alignItems='center'>
      <Grid item>
        <Typography color='error'>{weightErrorMsg}</Typography>
      </Grid>
      <Grid item>
        <TextField 
          variant='outlined'
          type='number'
          size='small'
          label='Player Weight (lbs)'
          disabled={measurementsLoading}
          {...playerWeightInput.bind}
          onChange={e => { 
            setWeightErrorMsg('');
            playerWeightInput.bind.onChange(e);
          }}
        />
      </Grid>
    </Grid>}
  />;
}

export default MotionSelection;
