import React, { useState } from 'react';
import { Button, Typography, Grid, CircularProgress } from '@material-ui/core';
import Logger from 'js-logger';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import { CreateAnalysis } from '../../network/videoAnalysisRequests';
import SelectionList from '../../components/selectionList';
import { getOrganizationId } from '../../utils/auth.utils';
import { ANALYSIS_STATUS } from '../../constants/videoAnalysis.constants';

function RequestPlayerSelection({ players, activeAnalyses, onClose }) {
  const [ error, setError ] = useState(null);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ newAnalyses, setNewAnalyses ] = useState([]);
  const [ selectedPlayers, setSelectedPlayers ] = useState([]);

  const activeAnalysesByUserId = keyBy(activeAnalyses, analysis => analysis.userId);

  function getPlayerSelectionName(player) {
    let displayName = `${player.firstName} ${player.lastName}`;
    if (player.userId in activeAnalysesByUserId) {
      displayName += ' (has active request)';
    }
    return displayName;
  }

  async function sendRequests() {
    setIsSaving(true);
    const orgId = getOrganizationId();
    // we want to try all players, before telling the user what failed
    let failedPlayers = {};
    const posts = selectedPlayers
      .map(player => CreateAnalysis(player.userId, orgId, ANALYSIS_STATUS.fullAnalysis)
        .catch(e => {
          Logger.error(e);
          failedPlayers[player.userId] = true;
        }));
    const allResponses = await Promise.all(posts);
    const updatedAnalyses = allResponses.filter(result => !(result instanceof Error));
    setNewAnalyses(prev => [...prev, ...updatedAnalyses]);

    if (Object.keys(failedPlayers).length > 0) {
      setSelectedPlayers(prev => prev.filter(player => failedPlayers[player.userId]));
      setError('Not all analysis succeeded. Hit Send again to retry failures.');
    } else {
      // the set call above is async and newAnalyses will not have these values yet.
      onClose([...newAnalyses, ...updatedAnalyses]);
    }
  }

  const orderedPlayers = sortBy(players, player => player.lastName);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item>
          <Typography variant='h5'>Select Players</Typography>
        </Grid>
        <Grid item>
          {isSaving 
            ? <CircularProgress color='secondary' />
            : <Button
              variant='contained'
              color='primary'
              onClick={sendRequests}>
                Send Requests
            </Button>
          }
        </Grid>
        {selectedPlayers.length > 0 && 
          <Grid item>
            <Typography>
              {`${selectedPlayers.length} players selected`}
            </Typography>
          </Grid>
        }
        {error && <Grid item><Typography color='error'>{error}</Typography></Grid>}
      </Grid>

      <SelectionList 
        items={orderedPlayers}
        selectedItems={selectedPlayers}
        onChange={newItems => setSelectedPlayers(newItems)}
        getLabel={player => getPlayerSelectionName(player)}
        getValue={player => player.userId}
        columnWidths={{ xs: 4 }}
      />
    </div>
  );
}

export default RequestPlayerSelection;