import { makeStyles } from '@material-ui/styles';
import palette from '../../../styles/palette';

const useStyles = makeStyles(_ => ({
  container: ({ width }) => ({
    width: `${width}px`,
    minWidth: `${width}px`
  }),
  isFull: {
    height: '100%',
    minHeight: '100%',
    borderRight: `1px solid ${palette.mediumGray}`,
    borderRadius: 0
  },
  isMinimized: {
    position: 'absolute',
    left: '10px',
    paddingRight: '10px',
    top: 0,
    transform: 'translateX(-100%)',
    zIndex: -1,
    height: '100%',
    borderRadius: '5px'
  },
  templateContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  addIcon: {
    marginRight: '10px',
    color: palette.midnightBlue
  },
  addTemplateAction: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: palette.lightGray
    }
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '30px',
    height: '100%',
    overflow: 'hidden'
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    padding: '0 5px'
  },
  templateList: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  }
}));

export default useStyles;