import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import logger from 'js-logger';
import HttpsIcon from '@material-ui/icons/Https';
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { history } from '../../store';
import useStyles, { StripeCardStyle } from './styles';
import { GetProducts, GetPurchases } from '../../network/purchaseRequests';
import { products } from '../../constants/purchases.constants';
import { CreateStripePurchase, UpgradeSubscription, ChangeSubscription } from '../../network/purchaseRequests';
import { getUserId } from '../../utils/auth.utils';
import { getSubscriptions, getActiveSubscriptionAndPath } from '../../utils/purchase.utils';
import { formatMMDDYY } from '../../utils/formatting.utils';
import useTrackingServices, { EVENT_NAMES } from '../../services/useTrackingServices';
import { useCallback } from 'react';
import { TextField } from '@material-ui/core';
import useInput from '../../utils/useInput';
import { formatCentsToDollars } from '../../utils/money.utils';
import ProgressButton from '../../components/progressButton';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  logger.error('**Stripe publishable key environment variable not set**');
  logger.error(
    '**Add an environemnt variable REACT_APP_STRIPE_PUBLISHABLE_KEY**'
  );
  logger.error('**Replace .env.example with .env and **');
}

const getIsSwitchingPlans = (subscriptionDetails, product) => {
  if(subscriptionDetails == null || product == null) {
    return false;
  }
  return product.orderingWeight === subscriptionDetails.product.orderingWeight;
};

const PriceToDisplay = ({
  upgradePrice, 
  promotionalPrice, 
  standardPrice
}) => {
  const classes = useStyles();

  if(upgradePrice != null) {
    return <Grid>
      <Typography component='h6' variant='h6' color='textPrimary'>
        Price: ${formatCentsToDollars(standardPrice)} 
      </Typography>
      <Typography component='h5' variant='h5' color='textPrimary'>
        Payment due to upgrade: ${formatCentsToDollars(upgradePrice)} 
      </Typography>
    </Grid>;
  }

  return <Grid>          
    {promotionalPrice != null && 
      <Grid>
        <Typography component='h6' variant='h6' color='textPrimary'>
          List Price: <span className={classes.crossedOutText}> 
            ${formatCentsToDollars(standardPrice)}  </span>
        </Typography> 
        <Typography component='h4' variant='h4' color='textPrimary'>
          Price: ${formatCentsToDollars(promotionalPrice)} 
        </Typography>
      </Grid> 
    }

    {promotionalPrice == null &&
      <Grid>
        <Typography component='h5' variant='h5' color='textPrimary'>
          Price: ${formatCentsToDollars(standardPrice)} 
        </Typography>
      </Grid>
    }

  </Grid>;
};

const getOldPaymentIdMethod = (entireSubscriptionHistory) => {
  if(entireSubscriptionHistory == null) {
    return null;
  }
  return {
    paymentMethodId: entireSubscriptionHistory.paymentMethodId,
    last4DigitsOfCard: entireSubscriptionHistory.last4DigitsOfCard
  };
};

const PaymentForm = ({ match }) => {
  const stripe = useStripe();
  const tracking = useTrackingServices();
  const elements = useElements();
  const [hasErroredBefore, setHasErroredBefore] = useState(false);
  const [subscribing, setSubscribing] = useState(false);
  const [errorToDisplay, setErrorToDisplay] = useState('');
  const [purchases, setPurchases] = useState([]);
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [discountMsg, setDiscountMsg] = useState('');
  const [promoCode, setpromoCode] = useState(null);
  const [checkingDiscount, setCheckingDiscount] = useState(false);
  const promoCodeInput = useInput({ trimValue: true });
  const classes = useStyles();

  const userId = getUserId();
  const { productId } = match.params;
  const { publicName } = products[productId];
  const subscriptions = getSubscriptions(purchases);
  const { 
    outerSubscriptionId,
    indexOfPurchaseItem,
    subscriptionDetails,
    entireSubscriptionHistory
  } = getActiveSubscriptionAndPath(subscriptions);

  let isSwitchingPlans = getIsSwitchingPlans(subscriptionDetails, product);
  let oldPaymentMethod = getOldPaymentIdMethod(entireSubscriptionHistory);

  let [useNewPaymentMethod, getUseNewPaymentMethod] = useState(oldPaymentMethod != null);

  const fetchCurrentProduct = useCallback(async (promoCode) => {
    const products = await GetProducts(userId, promoCode);
    return products.find((product) => `${product.id}` === productId);
  }, [productId, userId]);

  useEffect(() => {
    async function loadInitialProducts() {
      setIsLoading(true);
      try {
        const [currentProduct, allPurchases] = await Promise.all([
          fetchCurrentProduct(),
          GetPurchases(userId)
        ]);
        setProduct(currentProduct);
        setPurchases(allPurchases.items);
      } catch (e) {
        logger.error(e);
        setErrorToDisplay('There was an error loading your products');
      }
      setIsLoading(false);
    }

    loadInitialProducts();
  }, [fetchCurrentProduct, userId]);

  async function applyDiscount() {
    const code = promoCodeInput.value;
    if (!code) {
      setDiscountMsg('Enter a code before applying.');
      return;
    }
    if (code === promoCode) {
      return;
    }

    setCheckingDiscount(true);
    try {
      const productWithCode = await fetchCurrentProduct(code);
      if (productWithCode.promotionalPrice != null) {
        // site wide sales will always have a promo price, 
        // server will return best discount among the two
        if (product.promotionalPrice && 
          product.promotionalPrice <= productWithCode.promotionalPrice) {
          setDiscountMsg('The current discount is a better price than the promo entered.');
        } else {
          setpromoCode(code);
          setDiscountMsg('');
          setProduct(productWithCode);
        }
      } else {
        setDiscountMsg(code + ' is not a valid code');
      }
    } catch(e) {
      var errMsg = e.response.status === 400
        ? e.response.data
        : 'There was a problem checking the code. Try again.';
      logger.error('Error when attempting to apply a discount code', e);
      setDiscountMsg(errMsg);
    }
    setCheckingDiscount(false);
  }

  async function createSubscription({ paymentMethodId, last4DigitsOfCard }, upgrading) {
    const { sku } = product.purchasePlatformSkus.find(({ identifier }) => {
      return identifier === process.env.REACT_APP_PRODUCT_SKU_IDENTIFIER;
    });

    if(isSwitchingPlans) {
      return await ChangeSubscription(
        userId,
        productId,
        sku,
        paymentMethodId,
        outerSubscriptionId,
        indexOfPurchaseItem
      );
    }

    if(upgrading) {
      return await UpgradeSubscription(
        userId,
        productId,
        sku,
        paymentMethodId,
        outerSubscriptionId,
        indexOfPurchaseItem
      );
    }

    return await CreateStripePurchase(
      userId,
      productId,
      sku,
      paymentMethodId,
      last4DigitsOfCard,
      promoCode
    );
  }

  const onSuccessSubscribing = (resp) => {
    history.push('/purchase?purchaseComplete=true');
  };

  const getActivePaymentMethod = async () => {
    if(oldPaymentMethod != null && !hasErroredBefore && !useNewPaymentMethod) {
      return oldPaymentMethod;
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });

    if (error) {
      setSubscribing(false);
      setErrorToDisplay(error && error.message);
      setHasErroredBefore(true);
      return;
    }

    return {
      paymentMethodId: paymentMethod.id,
      last4DigitsOfCard: paymentMethod.card.last4 
    };
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setErrorToDisplay(null);
    if(subscribing) {
      // In the middle of subscribing
      return;
    }
    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const upgrading = product.upgradePrice != null;
    tracking.track(EVENT_NAMES.paymentAttempted, { 
      Plan: product.name, 
      Upgrade: upgrading 
    });

    // Create the subscription
    try {
      const paymentMethod = await getActivePaymentMethod();
      if(paymentMethod === null) return;
      const resp = await createSubscription({
        paymentMethodId: paymentMethod.paymentMethodId,
        last4DigitsOfCard: paymentMethod.last4DigitsOfCard
      }, upgrading);
      
      tracking.track(
        EVENT_NAMES.subscriptionPurchased, 
        { Plan: product.name, Upgrade: upgrading });
      tracking.setProfileProperty('Subscribed Plan', product.name);
      onSuccessSubscribing(resp);
    } catch(error) {
      // these error should have user friendly messages
      let msg = 'There was a problem with your card please try again.';
      try {
        msg = error.response.data.message;
      } catch {
        // if that property does exist, use default message
      }
      logger.error('Error subscribing', error);
      setErrorToDisplay(msg);
    }

    setSubscribing(false);
  };

  if(isLoading) {
    return <CircularProgress />;
  }

  if(product == null ) {
    return <div> No product selected. </div>;
  }

  const { upgradePrice } = product;

  const upgrading = upgradePrice != null && !isSwitchingPlans;
  return (
    <Grid container item xs={12} sm={10} md={5} justify='center' className={classes.paymentFormContainer}>
      <Grid container direction='column'>
        <Button 
          color='primary' 
          onClick={() => history.push('/purchase')}
          className={classes.subscriptionsButton}
        >
          <ArrowBackIcon className={classes.subscriptionIcon} /> View Products
        </Button>
        
        <Card className={classes.card}>
          <Typography variant='h5' className={classes.errorMsg}>
            {errorToDisplay}
          </Typography>
          <form id='payment-form' onSubmit={handleSubmit}>
            <Typography component='h2' variant='h3' color='textPrimary' className={classes.title}>
              {publicName}
            </Typography>
                      
            <PriceToDisplay
              {...product}
              isSwitchingPlans={isSwitchingPlans}
            />
            {subscriptionDetails != null && 
              <Typography>
                Your current plan: {products[subscriptionDetails.product.id].publicName} 
                ({subscriptionDetails.product.billingPeriod})
              </Typography>
            }
            {isSwitchingPlans && 
              <Typography>
                Note you are switching plans and won't be charged until 
                the end of your billing period.
                On: {formatMMDDYY(subscriptionDetails.expirationTimestamp)}
              </Typography>
            }

            <Grid className={classes.paymentArea}>
              <Grid>
                {(oldPaymentMethod != null && useNewPaymentMethod === true) &&
                  <Button onClick={() => {
                    getUseNewPaymentMethod(false);
                  }}>
                  Select Credit Card on File
                  </Button>}


                {(oldPaymentMethod != null && useNewPaymentMethod === false) && 
                  <Button onClick={() => getUseNewPaymentMethod(true)}>
                    Use New Credit Card
                  </Button>
                }

              </Grid>
              <Grid className={classes.ccContainer}>
            
                {!useNewPaymentMethod && oldPaymentMethod != null && <Grid>
                  Pay with card on file ending in: {oldPaymentMethod.last4DigitsOfCard}
                </Grid>
                }
                {(useNewPaymentMethod || oldPaymentMethod == null) &&
                  <CardElement 
                    options={{
                      disabled: subscribing,
                      style: StripeCardStyle
                    }}
                  />
                }
              </Grid>

              {!subscribing && 
                <Button 
                  fullWidth 
                  variant={'contained'} 
                  color='primary' 
                  type='submit' 
                  className={classes.submitButton}
                >
                  <HttpsIcon className={classes.lockIcon}/>
                  {upgrading ? 'Finish Upgrading' : 'Finish Purchasing'}
                </Button>
              }
            </Grid>
            {subscribing && <div> Processing... please wait. <CircularProgress /> </div>}
          </form>
        </Card>
        {!subscribing && !isSwitchingPlans && !upgrading && 
          <Grid container direction='column' alignItems='flex-end' className={classes.promoContainer}>
            <div>
              <TextField size='small' label='Promo code' variant='outlined' {...promoCodeInput.bind}/>
              <ProgressButton color='primary' onClick={applyDiscount} showProgress={checkingDiscount}>
                Apply
              </ProgressButton>
            </div>
            {promoCode && 
              <Typography color='primary' className={classes.discountText}>
                {promoCode} applied
              </Typography>
            }
            <Typography color='error' className={classes.discountText}>{discountMsg}</Typography>
          </Grid>
        }
      </Grid>
    </Grid>
  );
};

const PaymentFormWrapper = (props) => (
  <Elements stripe={stripePromise}>
    <PaymentForm {...props} />
  </Elements>
);

export default PaymentFormWrapper;


