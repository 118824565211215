import React, { useState, useEffect, useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import useStyles from './styles';
import StyledSlider from './styledSlider';
import TrimmerControls from './trimmerControls';
import useKeyDown from '../../../../utils/useKeyDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CircularProgress from '@material-ui/core/CircularProgress';

function VideoWithCustomControls({
  dataUrl,
  disableTrimmingUI = false,
  videoFps = 30,
  videoHeight, 
  videoWidth,
  minimumVideoLength = 0.2,
  currentVideoTime = 0,
  startTime = 0,
  endTime = 0,
  setStartTime = () => {},
  setEndTime = () => {},
  playbackRate = 1,
  onVideoLoad,
  onVideoTimeUpdate = () => {}
}, videoRef) {
  const [videoIsLoaded, setVideoIsLoaded] = useState(false);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [totalVideoDuration, setTotalVideoDuration] = useState(0);
  const classes = useStyles();

  const frameRate = 1 / videoFps;
  const advanceVideoForward = useCallback(() => videoRef.current.currentTime += frameRate, 
    [frameRate, videoRef]);
  const advanceVideoBackward = useCallback(() => videoRef.current.currentTime -= frameRate, 
    [frameRate, videoRef]);

  // Arrows interfere with existing slider controls:
  useKeyDown('ArrowRight', advanceVideoForward);
  useKeyDown('ArrowLeft', advanceVideoBackward);
  useKeyDown('k', advanceVideoForward);
  useKeyDown('j', advanceVideoBackward);

  const isPlayable = useCallback(() => {
    return videoRef != null && videoRef.current != null;
  }, [videoRef]);
  
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate, videoRef]);

  useEffect(() => {
    setVideoIsPlaying(false);
    // IS THIS REALLY NEEDED?
    if (dataUrl != null) {
      videoRef.current.load();
    }
  }, [videoRef, dataUrl]);

  useEffect(() => {
    if (videoIsPlaying) {
      // Could get into a state that is wrong if videoRef is null
      // and this doesn't get called.
      isPlayable() && videoRef.current.play();
    } else {     
      isPlayable() && videoRef.current.pause();
    }
  }, [videoIsPlaying, videoRef, isPlayable]);
 
  const toggleIsPlaying = () => {
    setVideoIsPlaying(!videoIsPlaying);
  };

  const onLoadedData = () => {
    const { duration } = videoRef.current;
    setTotalVideoDuration(duration);
    onVideoLoad && onVideoLoad(duration);
    setVideoIsLoaded(true); 
  };

  const onTimeUpdate = (setToPauseDisabled = false) => {
    if (videoRef.current == null) return;
    let time = videoRef.current.currentTime;
    if (time >= endTime || time < startTime) {
      if(videoIsPlaying) {
        // This will prevent weird stuck since this lets the playhead advance
        // just enough.
        time = startTime + frameRate;  // Can  be any number like startTime + 1/100000;
        videoRef.current.currentTime = time;
      }
    }
    onVideoTimeUpdate(time);
  };

  const onTrimmerControlsChange = (_, [startTime, endTime]) => {
    if(startTime < endTime && Math.abs(startTime - endTime) > minimumVideoLength) {
      setStartTime(startTime);
      setEndTime(endTime);
    }
  };

  const onManualSliderChange = (_, time) => {
    if (videoRef.current == null) {
      return;
    }
    if (videoIsPlaying) {
      toggleIsPlaying();
    }

    onVideoTimeUpdate(time);
    videoRef.current.currentTime = time;
  };

  const trimmingValues = startTime === endTime ?
    [{ value: startTime }] : [{ value: startTime }, { value: endTime } ];
  return <div className={classes.container}>
    <div className={classes.videoContainer} style={{ height: `${videoHeight}px` }}>
      <video ref={videoRef}
        preload='auto'
        autoPlay={false}
        onPlaying={() => {
          if(!videoIsPlaying) {
            // Syncs UI with actual stuff happening..
            isPlayable() && videoRef.current.pause();
          }
        }}
        onPause={() => {
          if(videoIsPlaying) {
            // Syncs UI with actual stuff happening..
            isPlayable() && videoRef.current.play();
          }
        }}
        loop
        onLoadedData={onLoadedData}
        onTimeUpdate={onTimeUpdate}
        height={videoHeight}
        width={videoWidth}
      >
        <source src={dataUrl} type='video/mp4'/>
      </video>
    </div>
  
    {/* Custom Controls */}
    {isPlayable() && videoIsLoaded && startTime !== endTime ?
      <div className={classes.customControlsContainer}>
        <IconButton onClick={toggleIsPlaying}>
          {videoIsPlaying ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
        <div className={classes.slidersHolder}>
          <StyledSlider
            min={0}
            max={totalVideoDuration}
            step={frameRate}
            marks={trimmingValues}
            value={currentVideoTime}
            onChange={onManualSliderChange}
            className={classes.slider}
          />
          {
            !disableTrimmingUI &&
            <TrimmerControls
              min={0}
              max={totalVideoDuration}
              step={frameRate}
              marks={trimmingValues}
              value={[startTime, endTime]}
              onChange={onTrimmerControlsChange}
              className={classes.slider}
            />
          }
        </div>
        <div>
          <IconButton onClick={advanceVideoBackward}>
            <ArrowBackIosIcon className={classes.frameByFrameIcon}/>
          </IconButton>
          <IconButton onClick={advanceVideoForward}>
            <ArrowForwardIosIcon className={classes.frameByFrameIcon} />
          </IconButton>
        </div>
      </div> : <div> <CircularProgress /> Loading video... </div>}
  </div>;
}

export default React.forwardRef(VideoWithCustomControls);
