export const LESSON_TYPES = {
  playerLesson: 'PlayerLesson',
  template: 'Template',
  popUpCapture: 'PopUpCapture'
};

export const SEGMENT_TYPES = {
  initialMotions: 'InitialMotions',
  bodySegmentFocusSelection: 'BodySegmentFocusSelection',
  physicalScreen: 'PhysicalScreen',
  checkpointMotions: 'CheckpointMotions',
  drill: 'Drill',
  finalMotions: 'FinalMotions'
};

export default LESSON_TYPES;
