import palette from '../../../styles/palette';
import { makeStyles } from '@material-ui/styles';

const borderWidth = '12px';

const useStyles = makeStyles(_theme => ({
  container: {
    position: 'relative'
  },
  scrollView: {
    height: '100vh',
    backgroundColor: palette.deepBlue,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    paddingRight: borderWidth
  },
  rightBorder: {
    position: 'absolute',
    right: 0, 
    top: 0, 
    bottom: 0,
    height: '100vh',
    width: borderWidth,
    backgroundColor: palette.subtleWhite
  },
  headerContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 3,
    paddingTop: '10px',
    paddingBottom: '10px',
    borderBottomRightRadius: '10px',
    color: palette.white,
    backgroundColor: palette.deepBlue,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  searchByContainer: {
    width: '90%',
    marginTop: '10px'
  },
  logoImg: {
    width: '90%',
    cursor: 'pointer',
    margin: '20px 0'
  },
  searchBar: {
    width: '90%'
  },
  tooltipText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      marginTop: '2px'
    },
    fontSize: '12px'
  },
  icon: {
    width: '20px',
    height: '20px',
    paddingRight: '3px'
  },
  filterByPanel: {
    textAlign: 'center',
    color: palette.white,
    width: '100%'
  }
}));

export default useStyles;
