import palette from '../../../styles/palette';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  textField: {
    width: '100%',
    backgroundColor: palette.white,
    margin: '20px 0'
  },
  button: {
    backgroundColor: palette.bluePurple,
    color: palette.white,
    padding: '8px 15px',
    '&:hover': {
      backgroundColor: palette.bluePurple,
      filter: 'brightness(80%)'
    },
    '&:disabled': {
      backgroundColor: palette.lightGray
    }
  },
  previousPlans: {
    padding: '30px 0 15px 30px'
  }
}));

export default useStyles;
