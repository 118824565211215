import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(_ => ({
  container: {
    width: '100%'
  },
  videoContainer: {
    width: '100%', 
    background: 'black', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  customControlsContainer: {
    display: 'flex', 
    alignItems: 'center'
  },
  slider: {
    flexGrow: 1
  },
  slidersHolder: {
    flexGrow: 1,
    flexDirection: 'row',
    paddingRight: 10,
    marginTop: 6
  },
  frameByFrameIcon: {
    fontSize: 10
  }

}));

export default useStyles;
