import React, { useState, useRef } from 'react';
import KinematicSequenceGraphDark from '../kinematicSequenceGraphDark';

function KinematicSequenceGraphWithLabels(props) {
  const { labelPoints = []
    , initialLabelSelected = null
    , showCrosshairs = false
    , isEditing = false
    , onGraphClick = () => {}
    , onScatterHover = () => {} 
    , onScatterClick = () => {} } = props;

  // Note: victory throws a warning when isEditing is true since
  // cursor dimension is not an expected value but it does what we want which is
  // to not render the cross hairs and only the location of the cursor
  
  // Note: hover wont work for mobile
  const hoverPosition = useRef({ x: 0, y: 0 });
  const scatteredDots = labelPoints;
  const [currentlyHovered, setCurrentlyHovered] = useState(null);

  const lastClicked = initialLabelSelected;
  // We can do a "currentlyHovered" and "lastClicked" state objects
  // to determine which item should be selected
  // if currentlyHovered == null, we use lastCLicked. Otherwise we 
  // use currentlyHovered as selected item.
  // if lastClicked is null, we use null meaning nothing is selcted.

  const scatteredDotSelected = currentlyHovered != null ?
    currentlyHovered : lastClicked;
  const scatteredDotsProcessed = scatteredDots.map((element, index) => {
    return { ...element, isSelected: index === scatteredDotSelected };
  });
  return <div> 
    <KinematicSequenceGraphDark
      {...{ ...props, onGraphClick: (data) => {
        // This is overwritting the passed in props.onGraph click and adding this in between

        // This is a ref value from on hover (which wont work for mobile) 
        // and we just pass those coordinates down
        onGraphClick(hoverPosition.current, data);
      } }}
      
      scatteredDots={scatteredDotsProcessed}
              
      onGraphHover={(data) => {
        hoverPosition.current = data;
      }}
      showCurrentXY={isEditing}
      showCrosshairs={showCrosshairs}
      onScatterHover={(data, indexSelected) => {
        setCurrentlyHovered(indexSelected);
        onScatterHover(scatteredDots[indexSelected], indexSelected);                    
      }}                    
      onScatterClick={(data, indexSelected) => {
        onScatterClick(scatteredDots[indexSelected], indexSelected);
      }}                    
    />
  </div>;
};

export default KinematicSequenceGraphWithLabels;