import Network from './network';
import { getOrganizationId } from '../utils/auth.utils';
import { getDimensionsAsync } from '../utils/image.utils';

const IMAGES = 'Api/Images';

export async function UploadImage(userId, file) {
  const organizationId = getOrganizationId();
  const data = new FormData();
  const dimensions = await getDimensionsAsync(file);

  data.append('UploaderUserId', userId);
  data.append('OrganizationId', organizationId);
  data.append('File', file);
  data.append('Width', dimensions[0]);
  data.append('Height', dimensions[1]);
  const resp = await Network.Post(IMAGES, data);
  return resp.data;
}