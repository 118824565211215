const styles = {
  mainContainer: {
    height: '100%',
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  hidden: {
    visibility: 'hidden'
  },
  rotate180: {
    transform: 'rotate(180deg)' 
  },
  rightArrow: {
    float: 'right'
  },
  dropdownContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
};

export default styles;
