import React from 'react';
import useStyles from './styles';

import { IconButton, CircularProgress } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Table, TableCell, TableRow, TableBody, TableHead } from '@material-ui/core';

import { formatDateWithSeconds } from '../../../../../../utils/formatting.utils';


function DraftTable({ drafts, onDraftSelected, onDraftDeleted, isLoading }) {
  const classes = useStyles();

  const deleteDraft = (i) => {
    onDraftDeleted(drafts[i]);
  };

  const editDraft = (i) => {
    onDraftSelected(drafts[i]);
  };

  const formatLastTimeEdited = (datetime) => {
    if (!datetime) {
      return 'N/A';
    }
    return formatDateWithSeconds(datetime);
  };

  const formatName = (draft) => {
    const { firstName, lastName } = draft.author;
    let name = '';
    if (firstName) name += `${firstName} `;
    if (lastName) name += lastName;
    return name;
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>Drafts</div>
      {
        !drafts &&
            <CircularProgress 
              size={35}
            />
      }
      {
        drafts && 
            Boolean(!drafts.length) &&
            <div>No drafts available for this player</div>
      }
      {
        drafts &&
            Boolean(drafts.length) &&
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Date Created</TableCell>
                  <TableCell>Last Author</TableCell>
                  <TableCell>Last Time Edited</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  drafts.map((draft, i) => {
                    return <TableRow key={i}>
                      <TableCell>{formatDateWithSeconds(draft.creationTimestamp)}</TableCell>
                      <TableCell>{formatName(draft)}</TableCell>
                      <TableCell>{formatLastTimeEdited(draft.editedTimestamp)}</TableCell>
                      <TableCell>
                        <IconButton disabled={isLoading} onClick={editDraft.bind(this, i)}>
                          <Edit />
                        </IconButton>

                        <IconButton disabled={isLoading} onClick={deleteDraft.bind(this, i)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>;
                  })
                }
              </TableBody>
            </Table>
      }
    </div>
  );
}

export default DraftTable;