// Actions
export const SET_EVALUATIONS = 'evaluations/set';
export const UPDATE_EVALUATION = 'evaluation/update';
export const ADD_EVALUATION = 'evaluation/add';
export const REMOVE_MOTION = 'evaluations/removeMotion';

// Reducer
const initialState = [];

export default function reducer(state = initialState, action) {
  switch(action.type) {    
    case SET_EVALUATIONS:
      return action.evaluations;
    case UPDATE_EVALUATION:
      return state.map(evaluation => {
        return evaluation.id === action.evaluation.id
          ? action.evaluation
          : evaluation;
      });
    case ADD_EVALUATION:
      return [
        action.evaluation,
        ...state
      ];
    case REMOVE_MOTION:
      return state.map(evaluation => evaluation.swings == null 
        ? evaluation
        : {
          ...evaluation,
          swings: evaluation.swings.filter(swing => 
            swing.motionId !== action.motionId)
        });
    default:
      return state;
  }
};

// Action Creator
export function setEvaluations(evaluations) {
  return { type: SET_EVALUATIONS, evaluations };
};

export function updateEvaluation(evaluation) {
  return { type: UPDATE_EVALUATION, evaluation };
};

export function addEvaluation(evaluation) {
  return { type: ADD_EVALUATION, evaluation };
};

export function removeMotion(motionId) {
  return { type: REMOVE_MOTION, motionId };
};