import React from 'react';
import { RedocStandalone } from 'redoc';
import { AppBar } from '@material-ui/core';
import KMotionLogo from '../../assets/KMotionLogoWhite.png';
import { history } from '../../store';
import useStyles from './styles';

const ApiDocs = () => {
  const classes = useStyles();
  const specUrl = process.env.REACT_APP_PUBLIC_API_SPEC_URL;

  return (
    <div>
      <AppBar className={classes.header} position='fixed'>
        <img 
          src={KMotionLogo} 
          className={classes.logo}
          alt=''
          onClick={() => history.push('/player-development')}
        />
      </AppBar>
      <div className={classes.docsContainer}>
        <RedocStandalone
          specUrl={specUrl}
          options={{
            hideDownloadButton: true,
            hideLoading : true
          }}
        />
      </div>
    </div>
  );
};

export default ApiDocs;