export const ANALYSIS_STATUS = {
  requested: 'Requested',
  submitted: 'Submitted',
  readyForAnnotation: 'ReadyForAnnotation',
  annotated: 'Annotated',
  readyForActionPlan: 'ReadyForActionPlan',
  completed: 'Completed'
};

export const ANALYSIS_TYPE = {
  fullAnalysis: 'FullAnalysis',
  checkIn: 'CheckIn'
};

/* 
  For everything except the REVIEW view, the id should
  sync with the id passed by the back-end
*/
export const VIEWS = {
  CATCHER: {
    id: 'CatchersView',
    name: 'Catcher\'s View'
  },
  FACE_ON: {
    id: 'FaceOn',
    name: 'Face On'
  },

  /*
    This section is made on the front-end and has no
    back-end connection
   */
  REVIEWED: {
    id: 'review',
    name: 'Reviewed Annotations'
  }
};

// map to ids specified on the backend
export const VIEWS_TO_CHARACTERISTICS = {
  [VIEWS.FACE_ON.id]: [16, 17, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29],
  [VIEWS.CATCHER.id]: [18, 23, 29]
};

export const CHARACTERISTIC_SEVERITY = {
  none: 'None',
  present: 'Present'
};

// helper variables and function for keyframe constant below
const catchersView = [ VIEWS.CATCHER.id ];
const faceOnView = [ VIEWS.FACE_ON.id ];
const bothViews = [ VIEWS.FACE_ON.id, VIEWS.CATCHER.id ];

// list of keyframe values
export const KEY_FRAMES = [
  {
    key: 'stanceFrameTimeSeconds',
    name: 'Stance',
    views: faceOnView
  },
  {
    key: 'negativeMoveFrameTimeSeconds',
    name: 'Negative Move',
    views: faceOnView
  },
  {
    key: 'toeTouchFrameTimeSeconds',
    name: 'Toe Touch',
    views: bothViews
  },
  {
    key: 'heelPlantFrameTimeSeconds',
    name: 'Heel Plant',
    views: faceOnView
  },
  {
    key: 'connectionFrameTimeSeconds',
    name: 'Connection',
    views: faceOnView
  },
  {
    key: 'positiveMoveFrameTimeSeconds',
    name: 'Positive Move',
    views: catchersView
  },
  {
    key: 'contactFrameTimeSeconds',
    name: 'Contact',
    views: bothViews
  },
  {
    key: 'firstMoveFrameTimeSeconds',
    name: 'First Move',
    views: faceOnView
  }
];
