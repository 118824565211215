import useStyles from './styles';
import { isMobile } from 'react-device-detect';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const LOADING_INDICATOR_TIMEOUT_SEC = 1000 * 6;

const GoogleDocIFrame = ({ 
  url,
  onLoad = () => {},
  isSpreadsheet
}) => {
  const classes = useStyles();
  // Hacky way to get the GDocIframe to render better on mobile:
  const rootClass = isMobile ? classes.rootMobile : classes.rootDesktop;
  const isTaller = window.innerHeight > window.innerWidth;
  let startingMargin = isTaller ? -14 : 0;
  startingMargin = isMobile ? startingMargin : 0;

  // We want to render normaly for spreadsheets:
  let styles = isSpreadsheet ? {} : {
    width: isTaller & isMobile ? '130vw' : '98%',
    marginLeft:  startingMargin + 'vw'
  };
 
  useEffect(() => {
    const id = setTimeout(() => {
      onLoad({ timedOut: true });
    }, LOADING_INDICATOR_TIMEOUT_SEC);
    return () => {
      clearTimeout(id);
    };
  }, [url, onLoad]);

  return <div className={classes.holder}> 
    <iframe title='Embedded GDocument'
      onLoad={onLoad}
      style={styles}
      className={rootClass}
      frameBorder='0'
      src={url}>
    </iframe>
  </div>;
};

GoogleDocIFrame.propTypes = {
  url: PropTypes.string,
  onLoad: PropTypes.func,
  isSpreadsheet: PropTypes.bool
};


export default GoogleDocIFrame;
