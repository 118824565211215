import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

class SearchBar extends React.Component {
  render() {
    const {
      value,
      onChange,
      onClear,
      ...rest
    } = this.props;
    return <TextField 
      value={value} 
      onChange={onChange}
      placeholder='Search'
      InputProps={{
        startAdornment: (<InputAdornment position='start'><SearchIcon /></InputAdornment>),
        endAdornment: onClear ? (
          <InputAdornment position='end'>
            <IconButton onClick={onClear}><ClearIcon /></IconButton>
          </InputAdornment>) : null
      }}
      {...rest}
    />;
  }
}

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func
};

export default SearchBar;
