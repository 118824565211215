import { mean, meanBy } from 'lodash';

// expects an array of numbers
export function calculateStandardDeviation(arr) {
  if (!Array.isArray(arr) || !arr.length > 0) {
    return NaN;
  }

  const avg = mean(arr);
  // assumes total population instead of sample of data
  const variance = mean(arr.map(i => Math.pow(i - avg, 2)));
  const stdDeviation = Math.sqrt(variance).toFixed(2);
  return stdDeviation;
}

// expects an array of objects with x and y properties.
// calculates the std deviation of the distances from each point
// to the centroid of the given points.
export function calculateStdDeviationOfPoints(points) {
  // first get a center point for all body/ev points
  const center = {
    x: meanBy(points, point => point.x),
    y: meanBy(points, point => point.y)
  };

  // get the distance for every individual swing from the center
  const distances = points.map(point => {
    return Math.sqrt(
      Math.pow(point.x - center.x, 2) +
      Math.pow(point.y - center.y, 2)
    );
  });
  
  return calculateStandardDeviation(distances);
}