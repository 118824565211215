import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

/* This expects an object where each key is a subheader.
 * The value of each key should be an array of objects that 
 * have a property called displayName.
 * 
 * The selectedItems should be an array of these same objects which
 * are selected.
 */
function ListWithSubheader({ 
  listObject,       // main object that feeds list
  onToggleCheckbox, // callback called with listem item when checkbox changed
  selectedItems,    // list items that should be checked
  displayFn        // function that if given a list item returns a displayable name
}) {
  const classes = useStyles();
  return (
    <List subheader={<li />} className={classes.lessonsList}>
      {Object.keys(listObject).map(subheader => (
        <li key={subheader} className={classes.lessonUser}>
          <ul className={classes.lessonItem}>
            <ListSubheader>{subheader}</ListSubheader>
            {listObject[subheader].map(listItem =>(
              <ListItem 
                button 
                dense
                disableRipple
                key={listItem.id} 
                onClick={() => onToggleCheckbox(listItem)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    checked={selectedItems.indexOf(listItem) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={displayFn(listItem)} />
              </ListItem>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
};

ListWithSubheader.propTypes = {
  listObject: PropTypes.objectOf(PropTypes.array),
  onToggleCheckbox: PropTypes.func,
  selectedItems: PropTypes.array,
  displayFn: PropTypes.func
};

export default ListWithSubheader;