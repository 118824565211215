import React, { useState, useEffect } from 'react';
import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
  IconButton
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import IconTextField from '../../components/iconTextField';
import ProgressButton from '../../components/progressButton';
import AuthenticationService from '../../services/authentication';
import useInput from '../../utils/useInput';
import useStyles from './styles';
import Logger from 'js-logger';
import useTrackingServices, { EVENT_NAMES } from '../../services/useTrackingServices';

function SignIn({ showAcctCreation }) {
  const tracking = useTrackingServices();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginFailureMsg, setLoginFailureMsg] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const emailInput = useInput({
    trimValue: true
  });
  const passwordInput = useInput();
  const classes = useStyles();

  useEffect(() => {
    if (!emailSent) return;
    const timer = setTimeout(() => {
      setEmailSent(false);
      setShowResetPassword(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [emailSent]);

  const onLoginComplete = (userId, emailAddress) => {
    tracking.identify({ userId, emailAddress });
    tracking.track(EVENT_NAMES.loggedIn);
  };

  async function onSubmit(e) {
    e.preventDefault();
    setIsLoggingIn(true);
    setLoginFailureMsg(null);
    try {
      const authService = new AuthenticationService();
      if (showResetPassword){
        authService.resetPassword(
          emailInput.value,
          () => setEmailSent(true)
        );
      } else {
        authService.loginProgrammatically(
          emailInput.value,
          passwordInput.value, 
          (err) => {
            if (err.statusCode === 403 ) {
              setLoginFailureMsg('Wrong email or password.');
            } else {
              Logger.error(err);
              setLoginFailureMsg('We\'re experiencing technical difficulties. ' +
                ' Please try again later.');
            }
          },
          '/',
          userId => onLoginComplete(userId, emailInput.value)
        );
      }
    } catch(e) {
      Logger.error('Error when attempting to login', e);
    }
    setIsLoggingIn(false);
  }

  const disableButton = isLoggingIn ||
    (showResetPassword && !emailInput.value) || 
    (!showResetPassword && (!passwordInput.value || !emailInput.value));

  return (
    <form onSubmit={onSubmit}>
      <CardContent>
        {showResetPassword &&
          <div>
            <IconButton className={classes.backButton} onClick={() => setShowResetPassword(false)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography color='textSecondary' className={classes.resetPasswordText}>
              {emailSent 
                ? 'You should receive an email shortly with a link to reset your password.'
                : 'Please enter your email address. If you have an account with ' + 
                  'us you will receive an email to change your password.'}
            </Typography>
          </div> 
        }
        <Typography className={classes.introduction}>
          Login with your Babe Ruth League App account
        </Typography>
        <IconTextField
          {...emailInput.bind}
          autoFocus
          placeholder='Email' 
          icon={<PersonOutlineIcon />}
          error={emailInput.inError}
          errorMsg={emailInput.errorMsg}
          disabled={isLoggingIn}
          type='email'
        />
        {!showResetPassword &&  
          <IconTextField
            {...passwordInput.bind}
            placeholder='Password' 
            icon={<LockOutlinedIcon />}
            error={passwordInput.inError}
            errorMsg={passwordInput.errorMsg}
            disabled={isLoggingIn}
            type='password'
          />
        }
        <Grid container justify='center'>
          <Typography color='error'>{loginFailureMsg}</Typography>
          {!showResetPassword &&
            <Button 
              onClick={() => setShowResetPassword(true)} 
              className={classes.showResetPwdButton}
            >
              Don't remember your password?
            </Button>
          }
        </Grid>
        <br />
      </CardContent>
      <CardActions className={classes.cardActions}>
        <ProgressButton 
          showProgress={isLoggingIn}
          variant='contained' 
          color='primary' 
          className={classes.nextButton}
          disabled={disableButton}
          type='submit'
        >
          {showResetPassword ? 'Send Email' : 'Login' }
        </ProgressButton>
      </CardActions>
      <Grid container justify='flex-end'>
        <Grid item>
          <Button color='primary' onClick={showAcctCreation}>
            <b>Don't have an account yet?</b>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default SignIn;