import palette from '../../../../styles/palette';

const styles = {
  container: {
    backgroundColor: palette.blueGray
  },
  name: {
    fontSize: '16px',
    paddingLeft: '15px',
    paddingTop: '5px',
    color: palette.subtleWhite
  },
  description: {
    fontSize: '13px',
    paddingLeft: '15px',
    paddingBottom: '12px',
    color: palette.mediumGray
  }
};

export default styles;