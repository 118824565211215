import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import classnames from 'classnames';

const BodyPartValueBox = ({ value, top, inFocus, label }) => {
  const classes = useStyles({ top });

  return (
    <Box 
      className={classnames(
        classes.percentileLabelContainer,
        { [classes.focusBorder]: inFocus }
      )}
    >
      <div className={classes.boxLabel}>
        <Typography className={classes.boxLabelText}>{ label }</Typography>
      </div>
      <div>
        <span className={classes.percentileValue}>
          {value}
        </span>
      </div>
    </Box>
  );
};

export default BodyPartValueBox;