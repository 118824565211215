import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  resendTitle: {
    display: 'inline',
    marginRight: '10px'
  },
  resendHeader: {
    marginTop: '30px',
    fontSize: '36px',
    fontWeight: 700
  }
}));

export default useStyles;
