import { useMemo } from 'react';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import orderBy from 'lodash/orderBy';

function useCaptureEventsSplitRecent(NOW = new Date(), captureEvents = []) {
  const currentEvents = useMemo(() => {
    const eventIsCurrent = (event) => startOfDay(new Date(event.startDate)) <= NOW 
      && NOW <= endOfDay(new Date(event.endDate));
    return orderBy(captureEvents.filter(eventIsCurrent), x => new Date(x.startDate));
  }, [NOW, captureEvents]);

  const pastEvents = useMemo(() => {
    const eventIsOver = (event) => endOfDay(new Date(event.endDate)) < NOW;
    return orderBy(captureEvents.filter(eventIsOver), event => new Date(event.startDate), 'desc');
  }, [NOW, captureEvents]);

  const upcomingEvents = useMemo(() => {
    const eventIsUpcoming = (event) => startOfDay(new Date(event.startDate)) > NOW;
    return orderBy(captureEvents.filter(eventIsUpcoming), event => new Date(event.startDate), 'asc');
  }, [NOW, captureEvents]);

  return {
    currentEvents,
    pastEvents,
    upcomingEvents
  };  
};

export default useCaptureEventsSplitRecent;