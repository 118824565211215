import React from 'react';
import {} from '@material-ui/core';

import BabeRuthLogo from './../../assets/babeRuth.png';
import BRSwingCoachLogo from './../../assets/PSC-Logo.png';
import KGoRedLogo from './../../assets/kgo-red.png';

import classnames from 'classnames';

import useStyles from './styles';

const PurchaseHeader = () => {
  const classes = useStyles();
  return <div className={classes.header}>
    <img
      src={BabeRuthLogo}
      alt='BabeRuthLogo'
      className={classnames(classes.leftLogo, classes.babeRuthImg)} />
    <img 
      src={BRSwingCoachLogo}
      alt='BRSwingCoachLogo'
      className={classnames(classes.middleLogo, classes.PSCLogo)} />
    <img 
      src={KGoRedLogo}
      alt='KGORedLogo'
      className={classnames(classes.rightLogo, classes.KGoLogo)} />
  </div>;
};

export default PurchaseHeader;