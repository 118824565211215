export const COLUMN_KEYS = {
  TRAINING_COUNT: 'trainingCount',
  EXIT_VELO_IMPROVEMENT: 'exitVelocityImprovement'
};

export const PLAYER_VELO_IMPROVEMENTS = {
  //Logan Taranto
  '623c8b384891ef0069ebc5a6': 0,
  //Jack Warmkessel
  '623c8b3b95ad74006f931fb6': 0,
  //Michael Duffy
  '623c8b3e9aae9d0070d951bc': 0,
  //Benjamin Vuernick
  '623c8b401d10aa00689770e9': 1,
  //Thomas DiBlasi
  '623c8b434d4629006f1e4c56': 0,
  //Kieran Davis
  '623c8b462634e40068932ac9': 0,
  //Seamus OSullivan
  '623c8b4800fd32006fc83306': 0,
  //Charlie Kennedy
  '623c8b4a9aae9d0070d951cf': 3,
  //Nolan Gratton
  '623c8b4c4891ef0069ebc5cf': 0,
  //Nathaniel Saleski
  '623c8b4fbfcb010068181d9e': 0,
  //Remy Schuster
  '623c8b514d4629006f1e4c6c': 4,
  //Jackson Evans
  '623c8b5343a3a5006ac4bc29': 0,
  //Daniel Duffy
  '623c8b552634e40068932add': 0,
  //Griffin Sonier
  '623c8b572f254b0068b1c650': 0,
  //Cameron Cacciola
  '623c8b5a69533b006a8b70e4': 3,
  //Owen Murphy
  '623c8b5c2634e40068932ae2': 2,
  //Quinn Gallagher
  '623c8b5e43a3a5006ac4bc39': 0,
  //Jack Brown
  '623c8b6369533b006a8b70ef': 0,
  //Brennan McKenzie
  '623c8b65de09820071ea8875': 0,
  //Christopher Hajduk
  '623c8b671d10aa0068977136': 0,
  //James Kaune
  '623c8b6a4760430069425bd7': 1,
  //Alexander Winn
  '623c8b6c43a3a5006ac4bc54': 0,
  //Kevin Agger
  '623c8b6e1d10aa006897713f': 0,
  //Ethan OShea
  '623c8b70680114006f4295e5': 0,
  //Zachariah Brown
  '623e4f302634e40068950cc4': 0
};
