import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  container: {
    width: '100%',
    maxWidth: '550px',
    position: 'relative'
  },
  circularProgress: {
    top: '50%',
    left: '50%',
    zIndex: 100,
    position: 'absolute',
    marginTop: '-23px',
    marginLeft: '-8px'
  }
}));

export default useStyles;