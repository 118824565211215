import Network from './network';
import { getOrganizationId } from '../utils/auth.utils';
import { Upload } from 'tus-js-client';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';

const VIDEOS = 'Api/Videos';
const MOTION_ID_CHUNK_SIZE = 10;

export async function GetVideos(params, cancelToken) {
  const { motionIds } = params;
  if (!motionIds || motionIds.length <= MOTION_ID_CHUNK_SIZE) {
    let resp = await Network.Get(VIDEOS, params, cancelToken);
    return resp.data.items;
  }

  // If the number of motionIds is too large, split the request up
  // into multiple requests and then combine the results after
  const motionIdChunks = chunk(motionIds, MOTION_ID_CHUNK_SIZE);
  const requests = motionIdChunks.map(chunk => GetVideos({
    ...params,
    motionIds: chunk
  }, cancelToken));
  var result = await Promise.all(requests);
  return flatten(result);
}

export async function GetDrillVideos(params, cancelToken) {
  const fullParams = params ? { ...params, type: 'drill' } : { type: 'drill' };
  let resp = await Network.Get(VIDEOS, fullParams, cancelToken);
  return resp.data.items;
}

export async function GetAnnotatedVideos(userId, status, cancelToken){
  let resp = await Network.Get(VIDEOS, { userId, status, type: 'analysis' }, cancelToken);
  return resp.data.items;
}

export async function GetContentVideos(status, cancelToken){
  let resp = await Network.Get(VIDEOS, { status, type: 'content' }, cancelToken);
  return resp.data.items;
}

export async function GetMotionVideos(userId, status, cancelToken) {
  let resp = await Network.Get(VIDEOS, { userId, status, type: 'motion' }, cancelToken);
  return resp.data.items;
}

export async function GetVideo(id, params, cancelToken) {
  let resp = await Network.Get(`${VIDEOS}/${id}`, params, cancelToken);
  return resp.data;
}

export async function DeleteVideo(videoId) {
  await Network.Delete(`${VIDEOS}/${videoId}`);
}

export async function UploadMotionVideo(userId, titleStr, descriptionStr, file) {
  const { size: sizeBytes } = file;
  const organizationId = getOrganizationId();
  const title = titleStr.trim().length === 0 ? null : titleStr;
  const description = descriptionStr.trim().length === 0 ? null : descriptionStr;
  const body = { userId, title, description, organizationId, sizeBytes, type: 'Motion' };
  const url = `${VIDEOS}?redirectUrl=${encodeURI(window.location.href + '?uploadComplete=true')}`;
  const resp = await Network.Post(url, body);
  return resp.data;
}

export async function UploadVideo(titleStr, descriptionStr, file, type) {
  const { size: sizeBytes } = file;
  const organizationId = getOrganizationId();
  const title = titleStr.trim().length === 0 ? null : titleStr;
  const description = descriptionStr.trim().length === 0 ? null : descriptionStr;
  const body = { title, description, organizationId, sizeBytes, type };
  const url = `${VIDEOS}?redirectUrl=${encodeURI(window.location.href + '?uploadComplete=true')}`;
  const resp = await Network.Post(url, body);
  return resp.data;
}

export async function VimeoTUSVideoUpload(file, uploadUrl, progressCallback) {
  const promise = new Promise((resolve, reject) => {
  
    var upload = new Upload(file, {
      uploadUrl,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      onError: function(error) {
        reject(error);
      },
      onProgress: function(bytesUploaded, bytesTotal) {
        progressCallback(bytesUploaded / bytesTotal * 100);
      },
      onSuccess: function() {
        resolve({ file, uploadUrl });
      }
    });

    // Start the upload
    upload.start();    
  });

  return promise;
}

export async function UploadVideoToCloudAndVimeo(videoFile, video, onProgress = ()=>{}) {
  const body = { 
    ...video, 
    sizeBytes: videoFile.size, 
    organizationId: getOrganizationId(), 
    hostingProvider: 'Vimeo'
  };
  const uploadedVideo = (await Network.Post(VIDEOS, body)).data;

  await VimeoTUSVideoUpload(videoFile, uploadedVideo.uploadUrl, onProgress);
  return uploadedVideo;
}

export async function UpdateVideoAttributes(videoId, patch) {
  const resp = await Network.Patch(`${VIDEOS}/${videoId}/videoAttributes`, patch);
  return resp.data;
}

export async function UpdateVideo(videoId, patch) {
  const resp = await Network.Patch(`${VIDEOS}/${videoId}`, patch);
  return resp.data;
}

export async function CreateOrPatchVideo(video) {
  let videoId;
  try {
    const resp = await Network.Post(VIDEOS, video);
    return resp.data;
  }
  catch (e) {
    if (e.response?.status !== 409 || e.response?.data?.id == null) {
      throw e;
    }
    videoId = e.response.data.id;
  }

  var patchDoc = [
    { op: 'replace', path: '/status', value: video.status },
    { op: 'replace', path: '/captureEventResultId', value: video.captureEventResultId }
  ];
  return await UpdateVideo(videoId, patchDoc);
}
