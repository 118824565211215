import Palette from '../../styles/palette';

const styles = {
  flex: { display: 'flex' },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  innerContainer: { padding: '5px 20px 5px 20px' },
  prompt: {
    textAlign: 'center',
    padding: '10px'
  },
  submitBtn: {
    width: '100%',
    height: '65px',
    borderRadius: '0',
    backgroundColor: Palette.primaryRed,
    color: 'white',
    fontSize: '18px',
    transition: 'font-size .3s ease',
    '&:hover': {
      background: Palette.primaryRed,
      fontSize: '22px'
    }
  },
  inputIcon: {
    color: Palette.darkGray,
    transform: 'scale(0.8)'
  },
  error: { color: Palette.primaryRed },
  inputContainer: { padding: '20px 10px' },
  showPasswordCheckbox: { margin: '5px' }
};

export default styles;

export const SignUpErrorStyles = {
  outerContainer: {
    width: '100%', 
    height: '50px', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    background: Palette.primaryRed
  },
  innerContainer: {
    display: 'flex',
    textAlign: 'center'
  },
  exclamation: {
    background: Palette.white, 
    color: Palette.primaryRed, 
    width: '25px', 
    height: '25px',
    fontSize: 18, 
    borderRadius: '5px', 
    fontWeight: 'bold'
  },
  messageContainer: {
    paddingLeft: '10px',
    color: Palette.white
  }
};