import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import Logger from 'js-logger';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  CircularProgress,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import useResultMotionsWithVideosRequest from '../../useResultMotionsRequest';
import MotionCard from './motionCard';
import useVideoDownloadUrl from '../../../../../../utils/useVideoDownloadUrl';
import StyledFileInput from '../../../../../../components/styledFileInput';
import ProgressButton from '../../../../../../components/progressButton';
import VIDEO_STATUSES, { HOSTING_PROVIDER } from '../../../../../../constants/video.constants';
import { 
  CreateOrPatchVideo, 
  UpdateVideo 
} from '../../../../../../network/videoRequests';
import useStyles from './styles';
import { setEventResultVideos } from '../../../../../../store/captureEvents';

function VideoSelectionDialog({ 
  open, 
  onClose, 
  userId, 
  captureEventId,
  captureEventResult
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const captureEventResultId = captureEventResult.id;

  let [motions, loading, error] = useResultMotionsWithVideosRequest(userId, captureEventResultId);
  motions = orderBy(
    motions.filter(motion => motion.video != null),
    m => m.launchMonitorData?.ballspeedMph ?? 0,
    'desc');

  const [selectedMotion, setSelectedMotion] = useState(null);
  const [selectedVideoFile, setSelectedVideoFile] = useState(null);
  const [addPgLogoOverlay, setAddPgLogoOverlay] = useState(true);
  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState(false);

  useEffect(() => {
    setSelectedMotion(null);
    setSelectedVideoFile(null);
  }, [open]);

  const { downloadUrl, loading: videoLoading, error: videoError } = 
    useVideoDownloadUrl(selectedMotion?.video?.id);

  const onFileInputChange = files => {
    setSelectedMotion(null);
    setSelectedVideoFile(files.length > 0 ? files[0] : null);
  };

  const onMotionSelectionChange = motion => {
    setSelectedVideoFile(null);
    setSelectedMotion(motion);
    setSavingError(false);
  };

  const onSelect = async () => {
    if (selectedVideoFile != null) {
      onClose(selectedVideoFile);
      return;
    }

    let video = selectedMotion?.video;
    if (video.id == null) return;

    video = {
      ...video,
      videoAttributes: {
        ...video.videoAttributes,
        handedness: selectedMotion.fullMotionMetrics.playerHandedness,
        id: 0
      },
      id: 0,
      status: addPgLogoOverlay ? VIDEO_STATUSES.addingLogoToVideo : VIDEO_STATUSES.transferring,
      hostingProvider: HOSTING_PROVIDER.vimeo,
      captureEventResultId
    };

    setSaving(true);
    setSavingError(false);
    try {
      // remove existing videos from result
      const existingVideos = captureEventResult.videos;
      const existingVideoPatchRequests = existingVideos.map(v => {
        return UpdateVideo(v.id, [
          { op: 'test', value: v.captureEventResultId, path: '/captureEventResultId' },
          { op: 'replace', value: null, path: '/captureEventResultId' }
        ]);
      });

      await Promise.all(existingVideoPatchRequests);

      // add new video to result and stage for transfer
      video = await CreateOrPatchVideo(video);

      // update result in redux store
      dispatch(setEventResultVideos(
        captureEventId,
        captureEventResult.captureEventUserId,
        captureEventResultId,
        [ video ]));

      onClose();
    }
    catch (e) {
      Logger.error(e, 'Error saving selected blob video.');
      setSavingError(true);
    }
    setSaving(false);
  };

  const videoSrc = selectedVideoFile != null 
    ? URL.createObjectURL(selectedVideoFile)
    : downloadUrl;

  return <> 
    <Dialog open={open} maxWidth='md' fullWidth>
      <DialogContent>
        <div className={classes.header}>
          <Typography variant='h5'>Select a Video</Typography>
          <StyledFileInput 
            name={`motion_file_data_${captureEventResultId}`}
            label='Select From File System'
            accept='video/*'
            onChange={onFileInputChange}
            disabled={saving}
          />
        </div>

        {loading && <CircularProgress />}

        {error && <Typography color='error'>
          Error loading motions with videos, please try again.
        </Typography>}

        {!loading && !error && (motions.length === 0
          ? <Typography>
            No backup motion videos found for this user at this event.
          </Typography>
          : <>
            <Grid container spacing={2}> 
              {motions.map(motion => <MotionCard 
                key={motion.id}
                motion={motion}
                selected={motion.id === selectedMotion?.id}
                onClick={() => !saving && onMotionSelectionChange(motion)}
              />)}

              {motions.length === 0 && <Grid item>
                <Typography>
                  No uploaded motions videos found.
                </Typography>
              </Grid>}
            </Grid>

            <div className={classes.videoContainer}>
              {videoLoading && <CircularProgress />}
              {videoError && <Typography color='error'>
                Error loading video, please try again.  
              </Typography>}
              {!videoLoading && !videoError && videoSrc && <video 
                src={videoSrc}
                controls
                height='100%'
              />}
            </div>
          </>)}
      </DialogContent>
      <DialogActions>
        {savingError && <Typography color='error'>
          Error saving video, please try again.
        </Typography>}
        <FormControlLabel
          control={
            <Checkbox
              checked={addPgLogoOverlay}
              onChange={() => setAddPgLogoOverlay(prev => !prev)}
              name='addLogoCheckbox'
              color='primary'
            />
          }
          label='Add PG Tech Logo'
          className={classes.logoCheckbox}
        />
        <Button 
          onClick={() => onClose()}
          variant='outlined'
          disabled={saving}
        >
          Cancel
        </Button>
        <ProgressButton
          variant='outlined'
          color='primary'
          onClick={onSelect}
          disabled={downloadUrl == null && selectedVideoFile == null}
          showProgress={saving}
        >
          Select
        </ProgressButton>
      </DialogActions>
    </Dialog>
  </>;
}

VideoSelectionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  captureEventId: PropTypes.number.isRequired,
  captureEventResult: PropTypes.object.isRequired
};

export default VideoSelectionDialog;