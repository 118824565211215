import palette from '../../../styles/palette';

const styles = {
  dialogContent: {
    height: '70vh'
  },
  uploadImagesContainer: {
    height: '30%', 
    width: '100%', 
    backgroundColor: palette.subtleWhite,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative'
  },
  selectedImagesContainer: {
    height: '70%', 
    width: '100%', 
    overflowY: 'auto',
    justifyContent: 'center'
  },
  uploadDesc: {
    color: palette.darkGray
  },
  browseLink: {
    color: palette.brightBlue
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden'
  },
  imageTile: {
    width: '50%'
  },
  icon: {
    color: 'white'
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  hideButton: {
    display:'none'
  }
};

export default styles;