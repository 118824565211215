import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, 
  DialogContent, 
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import PopupForm from '../popupForm';
import EulaText from './eulaText';
import ProgressButton from '../progressButton';

class Eula extends React.Component {
  state = { readAndAgree: false }

  handleAccept = () => {
    this.setState({ readAndAgree: false });
    this.props.acceptEula();
  }

  handleClose = () => {
    this.setState({ readAndAgree: false });
    this.props.closeEula();
  }

  render () {
    return (
      <PopupForm open={this.props.open} 
        title={'User Agreement'}>
        <DialogContent>
          <DialogContent>
            <EulaText />
          </DialogContent>
          <FormControlLabel
            label='I have read and agree to the above terms.'
            control={
              <Checkbox
                checked={this.state.readAndAgree}
                onChange={() => this.setState({ readAndAgree: !this.state.readAndAgree })}
                color='primary'
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <ProgressButton color='primary' 
            onClick={this.handleClose} 
            showProgress={false}
            disabled={this.props.submittingEula}
            style={{ width: '100px' }}>
            Cancel
          </ProgressButton>
          <ProgressButton color='primary' 
            onClick={this.handleAccept}
            showProgress={this.props.submittingEula}
            disabled={!this.state.readAndAgree}
            style={{ width: '100px' }}>
            Accept
          </ProgressButton>
        </DialogActions>
      </PopupForm>
    );
  }
}

Eula.propTypes = { open: PropTypes.bool };

export default Eula;