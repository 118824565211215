import React, { 
  useState, 
  useEffect, 
  useCallback, 
  useMemo
} from 'react';
import sortBy from 'lodash/sortBy';
import some from 'lodash/some';
import palette from '../../styles/palette';
import RadioGroup from '@material-ui/core/RadioGroup';
import TablePagination from '@material-ui/core/TablePagination';
import { 
  GetLeaderboards, 
  GetLeaderboardScores,
  GetUserLevels
} from '../../network/leaderboardRequests';
import queryString from 'query-string';
import './index.css';
import BadgeMiniFeed from './badges/badgeMiniFeed';
import BadgeDisplay from './badges/badgeDisplay';
import { makeBadgesFeed } from './badges/badgeUtils';
import { useStyles, WhiteRadio, CustomFormControlLabel, CustomCircularProgress } from './styles';
import Logger from 'js-logger';
import LevelsDisplay from './levelsDisplay';
import LevelsLeaderboard from './levelsLeaderboard';
import { formatMMDDYY } from '../../utils/formatting.utils';
import LeaderboardPlayerTable from './leaderboardPlayerTable';
import ContestHero from './contestHero';


const ROW_DISPLAY_MULTIPLE_OPTIONS = [25, 50, 100];

const LEVELS = [
  {
    name: 'Undrafted',
    salary: 100,
    promotion: 2,
    demotion: null
  },
  {
    name: 'Rookie Ball',
    salary: 150,
    promotion: 2,
    demotion: 1
  },
  {
    name: 'Single-A',
    salary: 200,
    promotion: 3,
    demotion: 1
  },
  {
    name: 'Double-A',
    salary: 250,
    promotion: 5,
    demotion: 2
  },
  {
    name: 'Triple-A',
    salary: 300,
    promotion: 7,
    demotion: 3
  },
  {
    name: 'MLB',
    salary: 500,
    promotion: null,
    demotion: 5
  }
];

function Leaderboard(props) {
  const [gameNum, setGameNum] = useState(null);
  const [loading, setLoading] = useState(false);

  const [gameStartDate, setGameStartDate] = useState();
  const [gameCurDay, setgameCurDay] = useState();
  const [gameEndDate, setGameEndDate] = useState();

  const [currentLeaderboard, setCurrentLeaderboard] = useState();
  const [leaderboards, setLeaderboards] = useState(null);

  const [displayBadges, setDisplayBadges] = useState(false);
  const [page, setPage] = useState(0);
  const [playersPerPage, setPlayersPerPage] = useState(ROW_DISPLAY_MULTIPLE_OPTIONS[0]);

  const [levelSelected, setLevelSelected] = useState(0);
  const [userLevelsMap, setUserLevelsMap] = useState(null);

  let params = queryString.parse(props.location.search, { parseBooleans: true });
  const { userId, organizationId } = params;
  const classes = useStyles();

  // for now, we will not show levels for anyone
  const showLevels = false;

  const addDays = function(inputDate, days) {
    let date = new Date(inputDate);
    date.setDate(date.getDate() + days);
    return date;
  };

  const diffDays = function(firstDate, secDate) {
    const date1 = new Date(firstDate);
    const date2 = new Date(secDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
  };

  const switchGames = (event) => {
    setGameNum(parseInt(event.target.value));
  };

  const createPlayerData = useCallback((currentLeaderboard, currentUserId, userLevelsMap) => {
    let scores = [];
    const { maxScorePerInterval } = currentLeaderboard;
    const capScore = score => {
      if (userLevelsMap) return Math.min(score, 1); // one run per game with levels
      return maxScorePerInterval != null ? 
        Math.min(maxScorePerInterval, score) : score; 
    };
    const foundPlayers = {};
    for (var key in currentLeaderboard.leaderboardScores) {
      const scoreData = currentLeaderboard.leaderboardScores[key];
      const cappedScore = capScore(scoreData.score);

      const playerIndex = scores.findIndex(player => player.userId === scoreData.userId);
      if (playerIndex !== -1) {
        // add the capped score to the total score
        scores[playerIndex].totalScore += cappedScore;
        scores[playerIndex].innings[scoreData.interval] = cappedScore;
        scores[playerIndex].latestEvents[scoreData.interval] 
          = new Date(scoreData.latestTrainingTimestamp);
      } 
      else {
        const innings = Array(currentLeaderboard.numberOfIntervals).fill(0);
        const latestEvents = Array(currentLeaderboard.numberOfIntervals).fill(null);
        innings[scoreData.interval] = cappedScore;
        latestEvents[scoreData.interval] = new Date(scoreData.latestTrainingTimestamp);
        const name = `${scoreData.user.firstName} ${scoreData.user.lastName}`;
        const userId = scoreData.userId;

        // we are initalizing the total score, so we will start with the first interval
        // score. The rest of the scores are added in the if statement above
        const totalScore = cappedScore;
        const selected = scoreData.userId === currentUserId;
        scores.push({ 
          name,
          userId,
          totalScore, 
          innings,
          latestEvents,
          selected
        });

        // keep list of players that have training data
        foundPlayers[userId] = true;
      }
    }

    // add in people who have trained in the past and have levels data if possible
    if (userLevelsMap) {
      Object.keys(userLevelsMap).forEach(userId => {
        if (!foundPlayers[userId]) {
          // not from scores but we still want to show on the leaderboard
          const { user } = userLevelsMap[userId];
          const innings = Array(currentLeaderboard.numberOfIntervals).fill(0);
          const latestEvents = Array(currentLeaderboard.numberOfIntervals).fill(null);

          // fill with empty innings, they have no training events so should be 
          // all zeroes and nulls

          // this will make other operations easier by doing this here
          scores.push({
            name: `${user.firstName} ${user.lastName}`,
            userId,
            totalScore: 0,
            innings,
            latestEvents,
            selected: userId === currentUserId
          });
        }
      });
    }

    return scores;
  }, []);

  const { scores, rawScores } = useMemo(() => {
    if (!currentLeaderboard || !currentLeaderboard.leaderboardScores) return {
      scores: [],
      rawScores: []
    };

    const scores = createPlayerData(currentLeaderboard, userId, userLevelsMap);
    let orderedScores = sortBy(scores, o => o.totalScore).reverse();

    // filter to see if we need to filter by levels
    if (userLevelsMap) {
      orderedScores = orderedScores.filter(({ userId }) => {
        const userLevel = userLevelsMap[userId];
        const level = userLevel ? userLevel.level : 0;
        return level === levelSelected;
      });
    } 

    // calculate rankings and make sure ties are the same rank
    let prevRank = -1;
    let prevScore = null;
    orderedScores.forEach((score, idx) => {
      const { totalScore } = score;
      score.rank = prevScore === totalScore ? prevRank : idx + 1;
      prevRank = score.rank;
      prevScore = totalScore;
    });

    return {
      scores: orderedScores,
      rawScores: scores 
    };
  }, [currentLeaderboard, createPlayerData, userId, userLevelsMap, levelSelected]);

  const showTrainingText = userId != null
    && !showLevels
    && userId.trim() !== ''
    && !some(scores, x => x.userId === userId);

  const pickCorrectLeaderboards = useCallback((leaderboards) => {
    // get the latest active one first
    const latestLeaderboardsFirst = [...leaderboards]
      .sort((a, b) => new Date(b.startTimestamp) - new Date(a.startTimestamp));

    let latestActiveLeaderboard = 
      latestLeaderboardsFirst.find(lb => new Date() > new Date(lb.startTimestamp));

    // did not find a leaderboard that matches so get latest
    if (!latestActiveLeaderboard) {
      latestActiveLeaderboard = latestLeaderboardsFirst[0];
    }

    // check to see if this leaderboard is actually active 
    // this should not happen but should make things backwards compatible
    const endDate = addDays(
      latestActiveLeaderboard.startTimestamp, 
      latestActiveLeaderboard.numberOfIntervals * latestActiveLeaderboard.daysPerInterval
    );
    const isActive = new Date() > new Date(latestActiveLeaderboard.startTimestamp) && 
      new Date() < endDate;

    const gameIdx = leaderboards.findIndex(lb => lb.id === latestActiveLeaderboard.id);
    const filteredLeaderboards = [{ 
      ...latestActiveLeaderboard, 
      gameName: isActive ? 'Current Game' : 'Latest Game'
    }];

    // leaderboard is latest to earliest
    const nextGame = gameIdx - 1;
    const previousGame = gameIdx + 1;

    const prevGameName = 'Previous Game';
    const nextGameName = 'Upcoming Game';
    if (previousGame < latestLeaderboardsFirst.length) {
      filteredLeaderboards.unshift(
        { ...latestLeaderboardsFirst[previousGame], gameName: prevGameName }
      );
    }

    if (nextGame >= 0) {
      filteredLeaderboards.push({ ...latestLeaderboardsFirst[nextGame], gameName: nextGameName });
    }

    return {
      filteredLeaderboards,
      // if there was no previous game then the active game is the first element,
      // else it's the second
      activeGameIdx: filteredLeaderboards.some(({ gameName }) => gameName === prevGameName) ? 1 : 0
    };
  }, []);

  useEffect(() => {
    const updateData = async () => {
      try {
        setLoading(true);
        const fetchedLeaderboards = await GetLeaderboards(organizationId);
        const { 
          filteredLeaderboards, 
          activeGameIdx 
        } = pickCorrectLeaderboards(fetchedLeaderboards);
        setLeaderboards(filteredLeaderboards);
        setGameNum(activeGameIdx);
      } catch (err) {
        Logger.error(err);
      } finally {
        setLoading(false);
      }
    };
    updateData();
  }, [organizationId, pickCorrectLeaderboards]);

  useEffect(() => {
    if (gameNum === null && !leaderboards) return;
    const updateData = async () => {
      if (leaderboards.length < gameNum) return;
      try {
        setLoading(true);
        const currentGameLeaderboard = leaderboards[gameNum];
        if (!currentGameLeaderboard) return;
        const currentGameScores = await GetLeaderboardScores(currentGameLeaderboard.id);
        currentGameLeaderboard.leaderboardScores = currentGameScores;
        setCurrentLeaderboard(currentGameLeaderboard);

        /* Set Dates */
        const startDate = new Date(currentGameLeaderboard.startTimestamp);
        setGameStartDate(startDate);
        setGameEndDate(addDays(currentGameLeaderboard.startTimestamp, 
          currentGameLeaderboard.numberOfIntervals * currentGameLeaderboard.daysPerInterval));
        const curDay = new Date() < startDate ? 
          null : 
          diffDays(currentGameLeaderboard.startTimestamp, new Date());
        setgameCurDay(curDay);
      } catch (err) {
        Logger.error(err);
      } finally {
        setLoading(false);
      }
    };
    updateData();
  }, [gameNum, leaderboards]);

  // badges feed
  const feed = useMemo(() => {
    if (!currentLeaderboard 
      || !scores
      || !gameEndDate 
      || !gameStartDate 
      || gameCurDay === null
      || showLevels
    ) {
      return null;
    }

    return makeBadgesFeed(
      scores,
      currentLeaderboard, 
      gameCurDay - 1, 
      gameStartDate, 
      gameEndDate
    );
  }, [
    currentLeaderboard, 
    scores, 
    gameEndDate, 
    gameStartDate, 
    gameCurDay,
    showLevels
  ]);

  useEffect(() => {
    if (!showLevels || !organizationId) return;
    const getUserLevels = async () => {
      try {
        const userLevels = await GetUserLevels(organizationId);
        const userLevelsMap = userLevels.reduce((acc, userLevel) => {
          acc[userLevel.userId] = userLevel;
          return acc;
        }, {});
        setUserLevelsMap(userLevelsMap);
      } catch (err) {
        Logger.error(err);
        setUserLevelsMap(null);
      }
    };

    getUserLevels();
  }, [organizationId, showLevels]);

  useEffect(() => {
    if (!userLevelsMap || !userId || !userLevelsMap[userId]) return;
    setLevelSelected(userLevelsMap[userId].level);
  }, [userLevelsMap, userId]);

  const openBadgeModal = useCallback(() => setDisplayBadges(true), []);

  const idToObjMap = useMemo(() => {
    if (!scores) {
      return null;
    }
    return scores.reduce((map, score) => {
      map[score.userId] = score;
      return map;
    }, {});
  }, [scores]);

  const handleChangePage = (_, newPage) => setPage(newPage);
  const handleChangePlayersPerPage = e => {
    const newPlayersPerPage = parseInt(e.target.value, 10);
    const start = page * newPlayersPerPage;
    if (start > scores.length) {
      const newPage = Math.floor(scores.length / newPlayersPerPage);
      setPage(newPage);
    }
    setPlayersPerPage(newPlayersPerPage);
  };

  const displayScores = useMemo(() => {
    if (showLevels) return scores;
    const start = page * playersPerPage;
    const end = (page + 1) * playersPerPage;
    return scores ? scores.slice(start, end) : [];
  }, [scores, page, playersPerPage, showLevels]);

  const userRow = useMemo(() => {
    const userScore = scores.find(({ selected }) => selected);
    if (!userScore) return null;
    return { ...userScore, name: 'YOU' };
  }, [scores]);
  const leaderboardHasStarted = new Date() > gameStartDate;
  const leaderBoardIsActive = leaderboardHasStarted && new Date() < gameEndDate;
  const showLeaderboard = !loading && currentLeaderboard && displayScores.length !== 0;

  return (
    <div className={classes.pageContainer}>
      <ContestHero organizationId={organizationId} />
      <div className={classes.leaderboardContainer}>
        <div className={classes.leaderboardHeading}>
          {
            showLevels ? 
              <>
                {
                  (userLevelsMap && rawScores && idToObjMap && gameCurDay) ?
                    <LevelsDisplay
                      onLevelChanged={newLevel => setLevelSelected(newLevel)}
                      levelSelected={levelSelected}
                      levels={LEVELS}
                      curInterval={gameCurDay - 1}
                      currentUserId={userId || null}
                      idMap={idToObjMap}
                      userLevelsMap={userLevelsMap}
                      allScores={rawScores}
                    /> 
                    : 
                    <div className={classes.centeredProgress}>
                      <CustomCircularProgress/>
                    </div>
                }
              </>
              :
              <>
                <div className={classes.topContainer}>
                  <BadgeMiniFeed
                    loading={loading}
                    leaderboardStarted={leaderboardHasStarted}
                    badgesFeed={feed}
                    currentUserId={userId || null}
                    idMap={idToObjMap}
                    curInterval={gameCurDay - 1}
                    onOpenBadgeModal={openBadgeModal} />
                </div>
                <RadioGroup row onChange={switchGames}>
                  {leaderboards && leaderboards.map((leaderboard, i) => {
                    return <CustomFormControlLabel
                      key={leaderboard.id}
                      checked={gameNum === i}
                      value={i}
                      control={<WhiteRadio />}
                      label={leaderboard.gameName}
                    />;
                  })}
                </RadioGroup>
                {
                  showLeaderboard && currentLeaderboard.maxScorePerInterval != null &&
                  <div className={classes.maxScoreNote}>
                    <b>Note: </b> 
                    For this game, 
                    &nbsp;you can score a maximum of {currentLeaderboard.maxScorePerInterval} 
                    &nbsp;run{currentLeaderboard.maxScorePerInterval !== 1 ? 's' : ''} per day
                  </div>
                }
              </>
          }
        </div>
        {showLeaderboard && new Date() >= gameStartDate && userRow &&
            <LeaderboardPlayerTable 
              className={classes.userRow}
              scores={[ userRow ]}
              currentLeaderboard={currentLeaderboard}
              gameCurDay={gameCurDay}
            />
        }
        <div className={classes.border}>
          <div className={classes.leaderboardSubHeading}>
            {loading && <CustomCircularProgress/> }
            {!loading && currentLeaderboard && new Date() < gameStartDate &&
              <div>
                This Leaderboard Starts On&nbsp;
                {formatMMDDYY(gameStartDate)}
              </div>
            }
            {currentLeaderboard && scores && new Date() > gameEndDate &&
              <div>Final</div>
            }
            {showLeaderboard && leaderBoardIsActive &&
              <div>Day {gameCurDay}</div>
            }
            {!loading && currentLeaderboard && showTrainingText && leaderBoardIsActive &&
              <div>Start training to watch yourself climb the leaderboard!</div>
            }
          </div>
          {
            showLeaderboard && new Date() >= gameStartDate && !showLevels &&
            <LeaderboardPlayerTable 
              scores={displayScores}
              currentLeaderboard={currentLeaderboard}
              gameCurDay={gameCurDay}
            />
          }
          {
            showLeaderboard && new Date() >= gameStartDate && showLevels &&
            <LevelsLeaderboard 
              scores={displayScores}
              currentLeaderboard={currentLeaderboard}
              gameCurDay={gameCurDay}
              level={LEVELS[levelSelected]}
            />
          }
          {
            showLeaderboard && leaderboardHasStarted && !showLevels && 
            <TablePagination
              classes={{
                root: classes.tablePagination,
                caption: classes.tablePagination,
                selectIcon: classes.tablePagination,
                select: classes.tablePagination,
                actions: classes.tablePagination
              }}
              backIconButtonProps={{ 
                style: { 
                  color: page === 0 ? palette.fadedGray : palette.white
                }
              }}
              nextIconButtonProps={{ 
                style: { 
                  color: (page + 1) * playersPerPage >= scores.length ? 
                    palette.fadedGray : palette.white
                }
              }}
              rowsPerPageOptions={ROW_DISPLAY_MULTIPLE_OPTIONS}
              component='div'
              labelRowsPerPage='Players Shown'
              count={scores.length}
              rowsPerPage={playersPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangePlayersPerPage}
            />
          }
        </div>
      </div>

      {
        !showLevels &&
        <BadgeDisplay
          currentGameNumber={gameNum === null ? null : gameNum + 1}
          curInterval={gameCurDay - 1}
          currentLeaderboard={currentLeaderboard}
          badgeFeed={feed}
          displayBadges={displayBadges}
          idMap={idToObjMap}
          onClose={() => setDisplayBadges(false)}
        />
      }
    </div>
  );
};

export default Leaderboard;