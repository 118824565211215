import palette from 'styles/palette';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  menuItem: {
    '&:hover': {
      backgroundColor: palette.lightenedDeepBlue
    }
  }
}));

export default useStyles;
