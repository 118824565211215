import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(_ => ({
  tabSelected: {
    color: '#00A5BC'
  },
  tab: {
    height: '40px',
    paddingBottom: '10px',
    minHeight: '0px',
    fontSize: '16px',
    margin: '0 2px',
    backgroundColor: 'white'
  },
  tabIndicator: {
    background: '#00A5BC',
    height: '2px',
    zIndex: 2
  },
  tabRoot: {
    minHeight: '0px'
  }
}));

export default useStyles;