import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import SortIcon from '@material-ui/icons/Sort';
import useStyles from './styles';

function SortableHeaderCell({ 
  children, 
  selected, 
  descending, 
  onClick, 
  ...cellProps 
}) {
  const classes = useStyles();

  return <TableCell onClick={onClick} {...cellProps}>
    <div className={classes.tableCellContents}>
      <div>{children}</div>
      <SortIcon 
        className={classNames({ 
          [classes.sortAscendingIcon]: !descending,
          [classes.hidden]: !selected
        })} 
      />
    </div>
  </TableCell>;
}

SortableHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  descending: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default SortableHeaderCell;
