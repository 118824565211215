import React, { useState, useEffect } from 'react';
import {
  ButtonBase,
  Button,
  Popover
} from '@material-ui/core';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import useStyles from './styles';

function ColorPicker({ color, onChange, id, className }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [newColor, setNewColor] = useState(color);
  const classes = useStyles({ color, newColor });

  useEffect(() => { setNewColor(color); }, [anchorEl, color]);

  const onSave = () => {
    onChange(newColor);
    setAnchorEl(null);
  };

  const elementId = anchorEl != null ? id : undefined;

  return <div className={className}>
    <ButtonBase 
      aria-describedby={elementId} 
      onClick={e => setAnchorEl(e.target)} 
      className={classes.buttonBase}
    />

    <Popover
      id={elementId}
      open={anchorEl != null}
      anchorEl={anchorEl}
      onClose={onSave}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      classes={{ paper: classes.popover }}
    >
      <div className={classes.hexPicker}>
        <HexColorPicker color={newColor} onChange={setNewColor} />
      </div>
      <div className={classes.inputContainer}>
        <HexColorInput color={newColor} onChange={setNewColor} />
        <Button className={classes.button} color='primary' onClick={() => setAnchorEl(null)} variant='contained'>
          Cancel
        </Button>
        <Button className={classes.button} color='primary' onClick={onSave} variant='contained'>
          Save
        </Button>
      </div>
    </Popover>
  </div>;
}

export default ColorPicker;
