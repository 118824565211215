import { useEffect, useState } from 'react';
import axios from 'axios';
import Logger from 'js-logger';
import { GetVideo } from '../network/videoRequests';

function useVideoDownloadUrl(videoId) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  useEffect(() => {
    setError(false);
    setDownloadUrl(null);

    const cancelToken = axios.CancelToken.source();
    const fetchDownloadUrl = async () => {
      if (videoId == null) {
        return;
      }

      setLoading(true);
      try {
        const { download } = await GetVideo(videoId, { includeDownload: true }, cancelToken);
        setDownloadUrl(download.downloadUrl);
      }
      catch (e) {
        if (axios.isCancel(e)) return;
        Logger.error(e, 'Error fetching download for video with id ' + videoId);
        setError(true);
      }

      setLoading(false);
    };
    fetchDownloadUrl();

    return cancelToken.cancel;
  }, [videoId]);

  return { downloadUrl, loading, error };
}

export default useVideoDownloadUrl;
