import React, { useState } from 'react';
import {
  IconButton,
  Grid,
  Typography,
  Divider
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { PatchEventUser } from '../../../../../network/captureEventRequests';
import { updateEventUser } from '../../../../../store/captureEvents';
import useStyles from './styles';
import { ACTION_STATUSES } from '../../../../../constants/captureEvents.constants';
import VideosDisplay from './VideosDisplay';
import logger from 'js-logger';
import VideoDevDetails from './videoSelectionDialog/videoDevDetails';

function EventUserVideoReview({ onNextUser, eventUser }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState('');
  const [finishedMsg, setFinishedMsg] = useState('');

  const updateReviewStatus = async (newStatus) => {
    const ops = [{
      value: eventUser.videosReviewedStatus,
      path: 'VideosReviewedStatus',
      op: 'test'
    }, {
      value: newStatus,
      path: 'VideosReviewedStatus',
      op: 'replace'
    }];
    
    const updatedUser = await PatchEventUser(eventUser.captureEventId, eventUser.id, ops);
    return { 
      ...eventUser, 
      videosReviewedStatus: updatedUser.videosReviewedStatus 
    };
  };

  const onVideoReviewed = async approved => {
    try {
      const updatedEventUser = await updateReviewStatus(approved 
        ? ACTION_STATUSES.completed 
        : ACTION_STATUSES.failed);
      
      dispatch(updateEventUser(updatedEventUser));
      if (onNextUser) {
        onNextUser();
      } else {
        setFinishedMsg('Last video reviewed.');
      }
    } catch(e) {
      logger.error('error recording video review results', e);
      setErrMsg('There was a problem recording results. Try refreshing the page and trying again.');
    }
  };

  const hasVideos = eventUser.captureEventResults.some(result => result.videos.length > 0);

  return <>
    <div className={classes.contentContainer}>
      <Typography color='error'>{errMsg}</Typography>
      <div>
        {eventUser.captureEventResults.map(result => <React.Fragment key={result.id}>
          <VideosDisplay 
            eventResult={result} 
            user={eventUser.user} 
            captureEvent={eventUser.captureEvent}
            eventUser={eventUser}
            className={classes.videosDisplayContainer}
          />
          <Divider className={classes.divider} />
        </React.Fragment>)}
      </div>
      <Typography variant='h4' color='primary' className={classes.finishedMsg}>{finishedMsg}</Typography>
      {hasVideos && <Grid container justify='space-around' className={classes.thumbsContainer}>
        <IconButton 
          onClick={() => onVideoReviewed(false)}
          className={classnames(classes.thumbButton, classes.failureColor)}
          disabled={!hasVideos}
        >
          <ThumbDownIcon className={classes.thumbIcon} />
        </IconButton>
        <IconButton 
          className={classnames(classes.thumbButton, classes.successColor)}
          onClick={() => onVideoReviewed(true)}
          disabled={!hasVideos}
        >
          <ThumbUpIcon className={classes.thumbIcon} />
        </IconButton>
      </Grid>}
      <div className={classnames(classes.devDetails, { [classes.topMargin]: hasVideos })}>
        <VideoDevDetails 
          userId={eventUser.userId} 
          captureEventId={eventUser.captureEventId} 
        />
      </div>
    </div>
  </>;
};

export default EventUserVideoReview;