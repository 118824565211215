import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useStyles from './styles';

function AxisSelection({ id, value, options, onChange, label }) {
  const classes = useStyles();
  return <Autocomplete 
    id={id}
    options={options}
    getOptionLabel={option => option.label}
    value={value}
    onChange={(_, newValue) => onChange(newValue)}
    disableClearable
    groupBy={option => option.section}
    className={classes.autocomplete}
    renderInput={inputProps => <TextField 
      {...inputProps}
      variant='outlined'
      label={label}
      margin='normal'
    />}
  />;
}

export default AxisSelection;
