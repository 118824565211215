import React, { useState, useRef, useCallback } from 'react';
import useStyles from './styles';

function DragAndDrop(props) {
  const dragCounter = useRef(0);

  const [isDragging, setIsDragging] = useState(false);

  const classes = useStyles();
  
  const handleDrag = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
    }, []);
    
  const handleDragIn = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current += 1;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setIsDragging(true);
      }
    }, []);

  const handleDragOut = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current -= 1;
      if (dragCounter.current > 0) return;
      setIsDragging(false);
    }, []);

  const handleDrop = useCallback(
    (e) => {  
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.items.length > 0) {
        props.handleDrop(e.dataTransfer.files);
        e.dataTransfer.clearData();
        dragCounter.current = 0;
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
  return (
    <div
      className={props.className}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      onClick={props.onClick}
    >
      {isDragging &&
        <div className={classes.outerDrop}>
          <div className={classes.innerDrop}>
          </div>
        </div>
      }
      {props.children}
    </div>
  );
}

export default DragAndDrop;