import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_theme => ({
  axisSelectContainer: {
    margin: '0 90px', 
    display: 'flex', 
    alignItems: 'center'
  },
  vs: {
    margin: '0 10px'
  },
  header: {
    display: 'flex',
    margin: '20px',
    alignItems: 'center'
  },
  graph: {
    background: 'white',
    padding: '20px',
    margin: '10px',
    alignSelf: 'flex-start'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px'
  }
}));

export default useStyles;
