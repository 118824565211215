import React, { useEffect, useState } from 'react';
import { 
  Checkbox, 
  FormControlLabel, 
  Grid, 
  List, 
  ListItem, 
  ListItemText, 
  Paper, 
  TextField,
  Typography
} from '@material-ui/core';
import { FOCUS_SEGMENTS } from '../trainingPlanTemplates.constants';
import { SEGMENT_FOCUS } from 'constants/trainingPlans.constants';
import AssignedDrillsList from './assignedDrillsList';
import ProgressButton from 'components/progressButton';
import useStyles from './styles';

function TemplateEditor({ 
  template, 
  setTemplate, 
  drills, 
  onSave, 
  saving, 
  savingError, 
  saveDisabled 
}) {
  const classes = useStyles();

  const [selectedNoteIdx, setSelectedNoteIdx] = useState(0);
  const selectedNote = selectedNoteIdx < template.notes.length 
    ? template.notes[selectedNoteIdx] 
    : null;

  // Allow for compatibility with templates that are both 1-indexed instead of 0-indexed.
  // Older templates are 1-indexed, newer templates are 0-indexed.
  const weekAdjustment = template.notes.length > 0 && template.notes[0].sequenceOrder === 0
    ? 1
    : 0;
  
  useEffect(() => {
    setSelectedNoteIdx(0);
  }, [template.id]);

  const onCheckboxChange = (checked, key) => {
    setTemplate(prev => ({ 
      ...prev, 
      [key]: checked ? SEGMENT_FOCUS.primary : SEGMENT_FOCUS.none
    }));
  };

  const onSelectedNoteTextChange = e => {
    const { value } = e.target;
    setTemplate(prev => ({
      ...prev,
      notes: prev.notes.map((note, idx) => ({
        ...note,
        text: idx === selectedNoteIdx ? value : note.text
      }))
    }));
  };

  const onAssignedDrillChange = (e, assignedDrillIdx, key) => {
    const { value } = e.target;
    setTemplate(prev => ({
      ...prev,
      notes: prev.notes.map((note, noteIdx) => noteIdx === selectedNoteIdx
        ? ({
          ...note,
          assignedDrills: note.assignedDrills.map((assignedDrill, idx) => idx === assignedDrillIdx
            ? ({
              ...assignedDrill,
              [key]: value
            })
            : assignedDrill)
        })
        : note)
    }));
  };

  const onAssignedDrillRemoved = assignedDrillIdx => {
    setTemplate(prev => ({
      ...prev,
      notes: prev.notes.map((note, noteIdx) => noteIdx === selectedNoteIdx
        ? ({
          ...note,
          assignedDrills: note.assignedDrills.filter((_, idx) => idx !== assignedDrillIdx)
        })
        : note)
    }));
  };

  const onAssignedDrillsAdded = assignedDrills => {
    setTemplate(prev => ({
      ...prev,
      notes: prev.notes.map((note, noteIdx) => noteIdx === selectedNoteIdx
        ? ({ ...note, assignedDrills: [...note.assignedDrills, ...assignedDrills ] })
        : note)
    }));
  };

  return <Paper className={classes.container}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField 
          value={template.name ?? ''} 
          onChange={e => setTemplate(prev => ({ ...prev, name: e.target.value }))} 
          variant='outlined' 
          label='Template Name'
          className={classes.fullWidth}
        />
      </Grid>
      <Grid item xs className={classes.saveButtonContainer}>
        {savingError && <Typography color='error'>
          Error saving template.
        </Typography>}
        <ProgressButton 
          showProgress={saving} 
          onClick={onSave}
          color='primary'
          disabled={saveDisabled}
        >
          Save
        </ProgressButton>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.focusCheckboxContainer}>
          {FOCUS_SEGMENTS.map(({ key, label }) => <FormControlLabel 
            key={key}
            label={label}
            control={<Checkbox 
              checked={template[key] === SEGMENT_FOCUS.primary}
              onChange={e => onCheckboxChange(e.target.checked, key)}
              name={`segment-focus-checkbox-${key}`}
            />}
          />)}
        </div>
      </Grid>

      <Grid item xs={3}>
        <List>
          {template.notes.map((note, idx) => <ListItem
            key={note.id ?? `note-list-item-${idx}`}
            button
            divider
            selected={idx === selectedNoteIdx}
            onClick={() => setSelectedNoteIdx(idx)}
          >
            <ListItemText 
              primary={`Week ${note.sequenceOrder + weekAdjustment}`}
              secondary={note.text} 
              secondaryTypographyProps={{ className: classes.noteDescriptionPreview }} 
            />
          </ListItem>)}
        </List>
      </Grid>
      <Grid item xs>
        {selectedNote && <div>
          <TextField 
            value={selectedNote.text}
            onChange={onSelectedNoteTextChange}
            variant='outlined'
            multiline
            rows={8}
            label={`Week ${selectedNote.sequenceOrder + weekAdjustment} Text`}
            className={classes.fullWidth}
          />

          <AssignedDrillsList 
            selectedNote={selectedNote}
            drills={drills}
            onAssignedDrillChange={onAssignedDrillChange}
            onAssignedDrillRemoved={onAssignedDrillRemoved}
            onAssignedDrillsAdded={onAssignedDrillsAdded}
          />
        </div>}
      </Grid>
    </Grid>
  </Paper>;
}

export default TemplateEditor;
