export const NOTE_STATUSES = {
  pending: 'Pending',
  sent: 'Sent',
  scheduled: 'Scheduled'
};

export const NOTE_TYPES = {
  actionPlan: 'ActionPlan',
  contentFeedItem: 'ContentFeedItem',
  template: 'Template',
  trainingPlan: 'TrainingPlan',
  googleDriveTemplateSection: 'GoogleDriveTemplateSection',
  googleDrivePlanSection: 'GoogleDrivePlanSection'
};