import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { LINKS } from './constants.js';

function LegalLinks({ className, buttonName = 'Submit' }) {
  return <Typography className={classNames(className)}>
    By clicking <b>{buttonName}</b>, you are agreeing to K-Motion’s&nbsp;
    <a href={LINKS.eula}>End User License Agreement</a>,&nbsp;
    <a href={LINKS.termsOfUse}>Terms of Use</a>, and&nbsp;
    <a href={LINKS.privacyPolicy}> Privacy Policy</a>.
  </Typography>;
};

LegalLinks.propTypes = {
  className: PropTypes.string,
  buttonName: PropTypes.string
};

export default LegalLinks;
