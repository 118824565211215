// Actions
const SET_TEAMS = 'kdashboard/teams/set';

// Reducer
const initialState = [];
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_TEAMS:
      return action.teams;
    default: 
      return state;
  }
}

// Action Creators
export function SetTeams(teams) {
  return { type: SET_TEAMS, teams };
}