import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ErrorSnackbar from '../../../components/errorSnackbar';

import useStyles from './styles';
import GraphOptionsConstants from './GraphOptions.constants';
import ExpandableProgressGraphs from './expandableProgressGraphs';

function Progress({ match }) {
  const [tabValue, setTabValue] = useState(0);
  const [errMsg, setErrMsg] = useState(null);
  const userId = match.params.userId;
  const featurePermissions = useSelector(state => state.featurePermissions);
  const classes = useStyles();
  
  return <div>
    <ErrorSnackbar message={errMsg} onClose={() => setErrMsg(null)}/>
    <Tabs 
      value={tabValue} 
      onChange={(_, value) => setTabValue(value)}
      classes={{ indicator: classes.tabIndicator, root: classes.tabRoot }}
    >
      {GraphOptionsConstants.map((opt, idx) => 
        (opt.permission && !featurePermissions.includes(opt.permission))
          ? null
          : <Tab key={opt.title}
            disableRipple 
            value={idx} 
            className={classNames(classes.tab, { [classes.tabSelected]: tabValue === idx })} 
            label={opt.title} 
          />
      )}
    </Tabs>
    
    <ExpandableProgressGraphs 
      userId={userId}
      keys={GraphOptionsConstants[tabValue].keys}
      fetchAverages={GraphOptionsConstants[tabValue].averagesFunction}
      fetchDataForDateRange={GraphOptionsConstants[tabValue].dataByDateFunction}
    />
  </div>;
}

export default Progress;