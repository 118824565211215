
/* Takes in the width and height of a component and the 
 * width and height of the target area, returning the largest possible
 * width and height that the component can take while maintaining
 * aspect ratio.
 */
export function sizeToFit(width, height, targetWidth, targetHeight) {
  const factor = Math.max(width / targetWidth, height / targetHeight);
  return {
    width: width / factor,
    height: height / factor
  };
}
