import motionTypesConstants from '../../constants/motionTypes.constants';

const baseballAndGolfSpeeds = [
  'peakSpeedPelvis',
  'peakSpeedTorso',
  'peakSpeedUpperArm',
  'peakSpeedHand'
];

const golfLeadWristSpeeds = [
  'peakSpeedPelvis',
  'peakSpeedTorso',
  'peakSpeedLowerArm',
  'peakSpeedHand'
];

const speedGains = [
  'speedGainTorsoToPelvis',
  'speedGainArmToTorso',
  'speedGainHandToArm'
];

const sequences = [
  'peakSpeedSequence',
  'transitionSequence'
];

const baseballTiming = [
  'heelStrikeToContactTime',
  'firstMoveToContactTime'
];

const golfTiming = [
  'swingTime',
  'tempo'
];

const baseballPostureHeelStrike = [
  'pelvisRotationAtHeelStrike',
  'pelvisBendAtHeelStrike',
  'pelvisSideBendAtHeelStrike',
  'torsoRotationAtHeelStrike',
  'torsoBendAtHeelStrike',
  'torsoSideBendAtHeelStrike',
  'xFactorAtHeelStrike'
];

const baseballPostureFirstMove = [
  'pelvisRotationAtFirstMove',
  'pelvisBendAtFirstMove',
  'pelvisSideBendAtFirstMove',
  'torsoRotationAtFirstMove',
  'torsoBendAtFirstMove',
  'torsoSideBendAtFirstMove',
  'xFactorAtFirstMove'
];

const baseballPostureContact = [
  'pelvisRotationAtContact',
  'pelvisBendAtContact',
  'pelvisSideBendAtContact',
  'torsoRotationAtContact',
  'torsoBendAtContact',
  'torsoSideBendAtContact',
  'xFactorAtContact'
];

const pitchPostureBallRelease = [
  'pelvisRotationAtBallRelease',
  'pelvisBendAtBallRelease',
  'pelvisSideBendAtBallRelease',
  'torsoRotationAtBallRelease',
  'torsoBendAtBallRelease',
  'torsoSideBendAtBallRelease',
  'xFactorAtBallRelease'
];

const pitchPostureMaxLoad = [
  'pelvisRotationAtMaxLoad',
  'pelvisBendAtMaxLoad',
  'pelvisSideBendAtMaxLoad',
  'torsoRotationAtMaxLoad',
  'torsoBendAtMaxLoad',
  'torsoSideBendAtMaxLoad',
  'xFactorAtMaxLoad'
];

const pitchPosturePeakPelvisSpeed = [
  'pelvisRotationAtPeakPelvisSpeed',
  'pelvisBendAtPeakPelvisSpeed',
  'pelvisSideBendAtPeakPelvisSpeed',
  'torsoRotationAtPeakPelvisSpeed',
  'torsoBendAtPeakPelvisSpeed',
  'torsoSideBendAtPeakPelvisSpeed',
  'xFactorAtPeakPelvisSpeed'
];

const golfPostureAddress = [
  'pelvisRotationAtAddress',
  'pelvisBendAtAddress',
  'pelvisSideBendAtAddress',
  'torsoRotationAtAddress',
  'torsoBendAtAddress',
  'torsoSideBendAtAddress',
  'xFactorAtAddress'
];

const golfPostureTop = [
  'pelvisRotationAtTop',
  'pelvisBendAtTop',
  'pelvisSideBendAtTop',
  'torsoRotationAtTop',
  'torsoBendAtTop',
  'torsoSideBendAtTop',
  'xFactorAtTop'
];

const golfPostureImpact = [
  'pelvisRotationAtImpact',
  'pelvisBendAtImpact',
  'pelvisSideBendAtImpact',
  'torsoRotationAtImpact',
  'torsoBendAtImpact',
  'torsoSideBendAtImpact',
  'xFactorAtImpact'
];

const firstRowBaseball = [
  ...baseballPostureHeelStrike,
  ...baseballTiming,
  ...sequences
];
  
const secondRowBaseball = [
  ...baseballPostureFirstMove,
  ...baseballAndGolfSpeeds
];

const thirdRowBaseball = [
  ...baseballPostureContact,
  ...speedGains
];

const firstRowPitch = [
  ...pitchPostureMaxLoad,
  ...baseballTiming,
  ...sequences
];

const secondRowPitch = [
  ...pitchPostureBallRelease,
  ...baseballAndGolfSpeeds
];

const thirdRowPitch = [
  ...pitchPosturePeakPelvisSpeed,
  ...speedGains
];

const firstRowGolf = [
  ...golfPostureAddress,
  ...golfTiming,
  ...sequences
];

const secondRowGolf = [
  ...golfPostureTop,
  ...baseballAndGolfSpeeds
];

const secondRowGolfLeadWrist = [
  ...golfPostureTop,
  ...golfLeadWristSpeeds
];

const thirdRowGolf = [
  ...golfPostureImpact,
  ...speedGains,
  'club'
];

export function getTableKeys(includeLaunchMonitor, motionType) {
  if (motionType === motionTypesConstants.golf) {
    return [firstRowGolf, secondRowGolf, thirdRowGolf];
  }
  if (motionType === motionTypesConstants.golfLeadWrist) {
    return [firstRowGolf, secondRowGolfLeadWrist, thirdRowGolf];
  }
  if (motionType === motionTypesConstants.baseballPitch) {
    return [firstRowPitch, secondRowPitch, thirdRowPitch];
  }

  return includeLaunchMonitor 
    ? [firstRowBaseball, secondRowBaseball, [...thirdRowBaseball, 'ballspeedMph']] 
    : [firstRowBaseball, secondRowBaseball, [...thirdRowBaseball, 'maxXFactor']];
}
