import React, { useCallback, useEffect } from 'react';
import Logger from 'js-logger';
import axios from 'axios';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PrivateRoute from '../../auth/privateRoute';
import { ORG_ADMIN_ROLE, ORG_ROLE } from '../../constants/auth.constants';

import Grid from '@material-ui/core/Grid';
import Sidebar from './sidebar';
import { history } from '../../store';
import PlayerData from '../playerDataTabs';
import OrganizationManagement from '../organizationManagement';
import AssociateExternalUserIds from '../associateExternalUserIds';
import VideoAnalysesStatus from '../videoAnalysesStatus';
import SwingStream from '../swingStream';

import DownloadMotionData from '../downloadMotionData';
import SettingsMenu from '../settingsMenu';
import SimpleBar from 'simplebar-react';
import DrillVideosUpload from '../drillVideos';

import useStyles from './styles';
import 'simplebar/dist/simplebar.min.css';
import { getUserId } from '../../utils/auth.utils';
import { setCurrentOrganization } from '../../store/currentOrganization';
import { GetOrganization } from '../../network/organizationRequests';
import PendingClients from '../pendingClients';
import Analytics from '../../services/analytics';
import useIsMobile from '../../utils/useIsMobile';
import { PERMISSIONS } from '../../constants/permissions.constants';
import ContentFeedManagement from '../contentFeedManagement';
import Drills from '../drills';
import LessonTemplates from '../lessonTemplates';
import BLAITrainingPlans from 'pages/BLAITrainingPlans';

const PlayerDevelopmentDashboard = ({ location, match }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useIsMobile();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchOrg = async() => {
      const loggedInUserId = getUserId();
      Analytics.setUserId(loggedInUserId);
      try {
        const org = await GetOrganization(loggedInUserId, cancelToken);
        dispatch(setCurrentOrganization(org));
        Analytics.setOrganizationName(org.name);
        
        const featurePermissions = org.featurePermissions 
          ? org.featurePermissions.map(x => x.feature) 
          : [];
          
        if (!featurePermissions.includes(PERMISSIONS.playerDevelopmentDashboard)) {
          history.push('/');
        }
      } catch(e) {
        if (axios.isCancel(e)) return;
        Logger.error(`Unable to load organization for user ${loggedInUserId}: ${e}`);
      }
    };
    fetchOrg();
    return cancelToken.cancel;
  }, [dispatch]);

  const selectPlayer = useCallback((userId) => {
    history.push(`${match.path}/player/${userId}`);
  }, [match.path]);

  const playerSelectedInRoute = location.pathname !== '/player-development';
  const onTeamsChanges = useCallback((teams, filterChanged) => {
    // select the first player on the team if nothing else is showing
    if (playerSelectedInRoute) {
      return;
    }
    if (teams.length > 0 &&
        teams[0].players && 
        teams[0].players.length > 0) {
      selectPlayer(teams[0].players[0].userId, false);
    }
  }, [playerSelectedInRoute, selectPlayer]);

  return (
    <div>
      {!isMobile && <SettingsMenu className={classes.settingsMenu}/>}
      <Grid container className={classes.document}>
        <Grid item xs={3} sm={2}>
          <Sidebar 
            onChange={selectPlayer} 
            onTeamsLoaded={onTeamsChanges}
          />
        </Grid>
        <Grid item xs={9} sm={10}>
          <SimpleBar className={classes.scrollArea}>
            <div className={classes.body}>
              <Switch>
                <Route path={`${match.path}/player/:userId`} component={PlayerData} />
                <Route exact path={match.path} component={() => 
                  <div>
                    <h1> K-Motion Player Development Dashboard</h1> 
                    Please select a player.
                  </div>
                }/>

                <PrivateRoute path={`${match.path}/download-motions`} 
                  component={DownloadMotionData} 
                  roles={[ORG_ADMIN_ROLE, ORG_ROLE]} />

                <PrivateRoute path={`${match.path}/organization-management`} 
                  component={OrganizationManagement} 
                  roles={[ORG_ADMIN_ROLE]} />
                
                <PrivateRoute path={`${match.path}/associate-external-userids`} 
                  component={AssociateExternalUserIds} 
                  roles={[ORG_ADMIN_ROLE]} />

                <PrivateRoute path={`${match.path}/drill-videos`}
                  component={DrillVideosUpload}
                  roles={[ORG_ADMIN_ROLE, ORG_ROLE]} />

                <PrivateRoute path={`${match.path}/pending-clients`}
                  component={PendingClients}
                  roles={[ORG_ADMIN_ROLE, ORG_ROLE]} />

                <PrivateRoute path={`${match.path}/video-analysis`}
                  component={VideoAnalysesStatus}
                  roles={[ORG_ADMIN_ROLE, ORG_ROLE]} />

                <PrivateRoute path={`${match.path}/swing-stream`}
                  component={SwingStream}
                  roles={[ORG_ADMIN_ROLE, ORG_ROLE]} />

                <PrivateRoute path={`${match.path}/content-feed-management`}
                  component={ContentFeedManagement}
                  roles={[ORG_ADMIN_ROLE, ORG_ROLE]} />

                <PrivateRoute roles={[ORG_ADMIN_ROLE]}
                  path={`${match.path}/drills`}
                  featurePermission={PERMISSIONS.internalTooling}
                  component={Drills}/>

                <PrivateRoute roles={[ORG_ADMIN_ROLE, ORG_ROLE]}
                  path={`${match.path}/lesson-templates`}
                  featurePermission={PERMISSIONS.internalTooling}
                  component={LessonTemplates}/>

                <PrivateRoute roles={[ORG_ADMIN_ROLE, ORG_ROLE]}
                  path={`${match.path}/blai-training-plans`}
                  featurePermission={PERMISSIONS.internalTooling}
                  component={BLAITrainingPlans}/>
              </Switch>
            </div>
          </SimpleBar>
        </Grid>
      </Grid>
    </div>
  );
};

export default PlayerDevelopmentDashboard;
