import { format } from 'date-fns';

export const isValidDateString = date => {
  return date != null && 
    new Date(date) !== 'Invalid Date' && 
    !isNaN(new Date(date));
};

export const formatDate = dateString => {
  return format(
    // Suffixing with 'Z' indicates that the timestamp string is in UTC
    new Date(dateString),
    'MM/dd/yyyy h:mm a'
  );
};

export const formatDateWithSeconds = dateString => {
  return format(
    new Date(dateString), 
    'MM/dd/yyyy h:mm:ss a'
  );
};

export const formatShortDateWithSeconds = dateString => {
  return format(
    new Date(dateString), 
    'MM/dd/yy h:mm:ss a'
  );
};

export const formatDateWithWrittenMonth = dateString => {
  return format(
    new Date(dateString),
    'MMM d, yyyy'
  );
};

export const formatDateJustTime = dateString => {
  return format(
    new Date(dateString),
    'h:mm a'
  );
};

export const formatTimeWithSeconds = dateString => {
  return format(
    new Date(dateString),
    'h:mm:ss a'
  );
};

export const formatMMDDYY = date => {
  if (!isValidDateString(date)) return null;
  return format(new Date(date), 'MM/dd/yy');
};

export const formatMMDD = date => {
  if (!isValidDateString(date)) return null;
  return format(new Date(date), 'MM/dd');
};

export const formatReadableDateLongTime = date => {
  return format(new Date(date), 'MMM dd yyyy h:mm:ss a');
};

export const formatMonthDayTime = date => {
  if (!isValidDateString(date)) return null;
  return format(new Date(date), 'MMM dd h:mm:ssa');
};

export const decimalFormatter = (value) => { return value.toFixed(2); };