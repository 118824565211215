import { useEffect } from 'react';

function useExponentialBackoff(callback, {
  initialDelaySeconds = 2,
  backoffFactor = 2,
  shouldRun = true,
  runImmediately = false
}) {
  useEffect(() => {
    let timeout;

    function cleanup() {
      timeout && clearTimeout(timeout);
      timeout = null;
    }

    function runTimeout(delay) {
      if (!shouldRun) {
        cleanup();
        return;
      }
      timeout = setTimeout(() => {
        callback();
        runTimeout(delay * backoffFactor);
      }, delay);
    }

    if (runImmediately) {
      callback();
    }
    runTimeout(initialDelaySeconds * 1000);
    return cleanup;
  }, [callback, initialDelaySeconds, backoffFactor, shouldRun, runImmediately]);
}

export default useExponentialBackoff;