import React from 'react';
import queryString from 'query-string';

import Auth from '../../auth/auth';
import AuthorizationPageWrapper from '../../components/authorizationPageWrapper';

class AuthPage extends React.Component {
  render() {
    const values = queryString.parse(this.props.location.search);
    const { location } = this.props;
    const { errorMessage } = location?.state ?? {};

    const flashMessage = errorMessage
      ? { type: 'error', text: errorMessage }
      : null;

    const auth = new Auth(
      values.email, 
      location == null 
        || location.state == null 
        || location.state.from == null ? null : location.state.from,
      flashMessage);
    auth.showLogin();
    return <AuthorizationPageWrapper />;
  }
}

export default AuthPage;