import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  listContainer: {
    padding: 10
  },
  searchBar: {
    padding: '10px'
  }
}));

export default useStyles;
