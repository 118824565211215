import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import classNames from 'classnames';
import orderBy from 'lodash/orderBy';
import useNetworkRequest from 'network/useNetworkRequest';
import useStyles from './styles';
import { GetCaptureEventLeaderboard } from 'network/captureEventRequests';
import { COLUMN_KEYS, PLAYER_VELO_IMPROVEMENTS } from './leaderboard.utils';
import { getInitialCaptureEventResult } from 'utils/trainingPlan.utils';

const Leaderboard = ({ user, trainingPlan, onLoad }) => {
  const classes = useStyles();
  const [sortedColumn, setSortedColumn] = useState(COLUMN_KEYS.EXIT_VELO_IMPROVEMENT);

  const initialCaptureEventResult = getInitialCaptureEventResult(trainingPlan);

  const captureEventId = initialCaptureEventResult
    ?.captureEventUser
    ?.captureEventId;

  const organizationId = trainingPlan?.organizationId;

  const getLeaderboardScores = useCallback(async cancelSource => {
    if (organizationId == null || captureEventId == null) return [];
    let leaderboardScores = 
      await GetCaptureEventLeaderboard(organizationId, captureEventId, cancelSource);
    return leaderboardScores
      .filter(score => PLAYER_VELO_IMPROVEMENTS[score.userId] != null)
      .map(score => ({ 
        ...score, 
        exitVelocityImprovement: PLAYER_VELO_IMPROVEMENTS[score.userId] 
      }));
  }, [organizationId, captureEventId]);
  const [leaderboardScores, loading, error] = useNetworkRequest([], 'getLeaderboardScores', getLeaderboardScores);

  const rankedLeaderboardScores = useMemo(() => {
    const orderedScores = orderBy(leaderboardScores, sortedColumn, 'desc');
    let prevValue = null;
    let prevRank = null;
    return orderedScores.map((score, idx) => {
      let rank = idx + 1;
      if (prevValue != null && prevValue === score[sortedColumn]) {
        rank = prevRank;
      }
      prevRank = rank;
      prevValue = score[sortedColumn];
      return { ...score, rank };
    });
  }, [leaderboardScores, sortedColumn]);
  
  useEffect(() => {
    if ((leaderboardScores.length > 0 && !loading) || error) {
      onLoad();
    }
  }, [leaderboardScores, loading, error, onLoad]);

  return <TableContainer className={classes.container}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Name</TableCell>
          <TableCell 
            onClick={() => setSortedColumn(COLUMN_KEYS.EXIT_VELO_IMPROVEMENT)}
            className={classNames(classes.clickableHeader, { 
              [classes.selectedHeader]: sortedColumn === COLUMN_KEYS.EXIT_VELO_IMPROVEMENT 
            })}
          >
            Peak EV Gain Since 3/25
          </TableCell>
          <TableCell 
            onClick={() => setSortedColumn(COLUMN_KEYS.TRAINING_COUNT)}
            className={classNames(classes.clickableHeader, { 
              [classes.selectedHeader]: sortedColumn === COLUMN_KEYS.TRAINING_COUNT 
            })}
          >
            Days Trained
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rankedLeaderboardScores.map((leaderboardScore, idx) => <TableRow 
          key={`${idx}-leaderboard-row`}
          className={classNames({ [classes.playerRow]: leaderboardScore.userId === user.userId })}
        >
          <TableCell>{leaderboardScore.rank}.</TableCell>
          <TableCell>
            {leaderboardScore.firstName} {leaderboardScore.lastName}
          </TableCell>
          <TableCell>{leaderboardScore.exitVelocityImprovement} mph</TableCell>
          <TableCell>{leaderboardScore.trainingCount}</TableCell>
        </TableRow>)}
      </TableBody>
    </Table>
  </TableContainer>;
};

export default Leaderboard;
