import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';
import VideoCard from '../../../../components/videoCard';
import { TAB_PERMISSIONS } from '../../../../constants/permissions.constants';
import { NOTE_STATUSES } from '../../../../constants/noteStatuses.constants';
import TEXT_TYPES from '../../../../constants/actionPlanTextTypes.constants';
import { formatMMDDYY } from '../../../../utils/formatting.utils';
import { Grid } from '@material-ui/core';

function Plan({
  title,
  text, 
  textFormatType, 
  noteVideos, 
  creationTimestamp, 
  onClickVideo, 
  author, 
  status,
  dateToSend
}) {
  const [ showVideos, setShowVideos ] = useState(false);
  const featurePermissions = useSelector(state => state.featurePermissions);
  const canViewDrillVideos = featurePermissions.includes(TAB_PERMISSIONS.drillVideos);
  const classes = useStyles();
  const date = new Date(creationTimestamp);
  const scheduledDate = formatMMDDYY(new Date(dateToSend));
  const drillVideos = noteVideos.map(({ video }) => video);
  
  return <div className={classes.planContainer}>
    <div>
      {status === NOTE_STATUSES.pending && 
        <Typography className={classes.pendingStatus}>
          Videos processing...
        </Typography>
      }
      {status === NOTE_STATUSES.scheduled &&
        <Typography className={classes.pendingStatus}>
          Scheduled to be sent on {scheduledDate}
        </Typography>
      }
      <Grid container justify='space-between'>
        <Grid item><Typography variant='h6'>{title}</Typography></Grid>
        <Grid item className={classes.timeLabel}>
          {date.toLocaleString()} 
        </Grid>
      </Grid>
    </div>
    <div className={classes.text}> 
      {textFormatType === TEXT_TYPES.MARKDOWN 
        ? <ReactMarkdown source={text} />
        : text}
    </div>
    <div className={classes.footer}>
      <div >
        {author && <span>by {author.firstName} {author.lastName} 
        </span>}
      </div>
      {canViewDrillVideos && <div 
        onClick={() => setShowVideos(prev => !prev)} 
        className={classes.expandDrillVideos}
      >
        {noteVideos.length} Video{noteVideos.length !== 1 && 's'} Attached
        <span>{showVideos 
          ? <ExpandLessIcon className={classes.expandIcon}/> 
          : <ExpandMoreIcon className={classes.expandIcon}/>}
        </span>
      </div>}
    </div>
    {canViewDrillVideos && <Collapse in={showVideos}>
      <div className={classes.drillsCollapse}>
        {drillVideos.map((video, idx) => <div key={`video-${idx}-${video.id}`} 
          className={classes.videoCard}
        >
          <VideoCard video={video} onClick={onClickVideo}>{video.title}</VideoCard>
        </div>)}
      </div>
    </Collapse>}
  </div>;
}

export default Plan;