import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';

import { speedValues } from './speed.constants';
import { 
  heelStrikePositionValues,
  firstMovePositionValues,
  contactPositionValues,
  addressPositionValues,
  topPositionValues,
  impactPositionValues,
  ballReleasePositionValues,
  maxLoadPositionValues,
  peakPelvisSpeedPositionValues
} from './position.constants';

import { sequenceSummaries } from './sequence.constants';
import { timingValues } from './timing.constants';
import { thirdPartyValues } from './thirdPartyTech.constants';
import { fullMotionMetricsValues } from './fullMotionMetrics.constants';


const allValues = [
  ...flatten(speedValues),
  ...flatten(timingValues),
  ...flatten(sequenceSummaries),
  ...flatten(heelStrikePositionValues),
  ...flatten(firstMovePositionValues),
  ...flatten(contactPositionValues),
  ...flatten(thirdPartyValues),
  ...flatten(fullMotionMetricsValues),
  ...flatten(addressPositionValues),
  ...flatten(topPositionValues),
  ...flatten(impactPositionValues),
  ...flatten(ballReleasePositionValues),
  ...flatten(maxLoadPositionValues),
  ...flatten(peakPelvisSpeedPositionValues)
];

// Convert into a dictionary with 'key' as the key and contents as the item itself:
export default keyBy(allValues, 'key');