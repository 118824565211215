import React from 'react';
import startCase from 'lodash/startCase';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import keyFramesConstants from '../../../../../constants/keyFrames.constants';
import useStyles from './styles';
import motionTypesConstants from '../../../../../constants/motionTypes.constants';
import { formatMonthDayTime } from '../../../../../utils/formatting.utils';

function EventUserMotionMetricsTable({ 
  captureEventResult,
  motions,
  loading,
  isPitch
}) {
  const classes = useStyles();

  function getXFactorValAtFrame(keyFrameData, frameName) {
    if (keyFrameData == null) return null;
    var frame = keyFrameData.find(x => x.keyFrameName === frameName);
    return frame == null ? null : frame.xFactor;
  }

  function getXFactorAtFrame(keyFrameData, frameName) {
    const xFactor = getXFactorValAtFrame(keyFrameData, frameName);
    if (xFactor == null) return null;
    return `${round(xFactor, 2)}°`;
  }

  function formatAttributes(attributes) {
    if (attributes == null) return [];
    return sortBy(attributes.map(({ attribute }) => startCase(attribute.name)), x => x);
  }

  function formatResultValue(value, units = '') {
    if (value == null) return '-';
    return `${round(value, 2)}${units}`;
  }

  return <Table size='small'>
    <TableHead>
      <TableRow>
        <TableCell>Timestamp</TableCell>
        <TableCell>Motion Type</TableCell>
        <TableCell>EV</TableCell>
        <TableCell>PSP</TableCell>
        <TableCell>PST</TableCell>
        <TableCell>PSUA</TableCell>
        <TableCell>PSH</TableCell>
        <TableCell>SGPT</TableCell>
        <TableCell>SGTA</TableCell>
        <TableCell>SGAH</TableCell>
        <TableCell>{isPitch ? 'ML' : 'Hs'} XF</TableCell>
        <TableCell>{isPitch ? 'PPS' : 'Fm'} XF</TableCell>
        <TableCell>{isPitch ? 'BR' : 'Con'} XF</TableCell>
        <TableCell>XF Str</TableCell>
        <TableCell>PS Sequence</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {captureEventResult && <TableRow>
        <TableCell className={classes.boldText} colSpan={2} align='right'>Event Result:</TableCell>
        <TableCell className={classes.boldText}>
          {formatResultValue(captureEventResult.ballspeedMph)}
        </TableCell>
        <TableCell className={classes.boldText}>
          {formatResultValue(captureEventResult.peakSpeedPelvis)}
        </TableCell>
        <TableCell className={classes.boldText}>
          {formatResultValue(captureEventResult.peakSpeedTorso)}
        </TableCell>
        <TableCell className={classes.boldText}>
          {formatResultValue(captureEventResult.peakSpeedUpperArm)}
        </TableCell>
        <TableCell className={classes.boldText}>
          {formatResultValue(captureEventResult.peakSpeedHand)}
        </TableCell>
        <TableCell className={classes.boldText}>
          {formatResultValue(captureEventResult.differentialSpeedGainPelvisToTorso)}
        </TableCell>
        <TableCell className={classes.boldText}>
          {formatResultValue(captureEventResult.differentialSpeedGainTorsoToUpperArm)}
        </TableCell>
        <TableCell className={classes.boldText}>
          {formatResultValue(captureEventResult.differentialSpeedGainUpperArmToHand)}
        </TableCell>
        <TableCell className={classes.boldText}>-</TableCell>
        <TableCell className={classes.boldText}>-</TableCell>
        <TableCell className={classes.boldText}>
          {formatResultValue(isPitch 
            ? captureEventResult.xFactorAtBallRelease 
            : captureEventResult.xFactorAtContact, 
          '°')}
        </TableCell>
        <TableCell className={classes.boldText}>
          {formatResultValue(captureEventResult.xFactorStretch)}
        </TableCell>
        <TableCell className={classes.boldText}>
          {formatResultValue(captureEventResult.correctPeakSpeedSequenceRate, '% In Sequence')}
        </TableCell>
      </TableRow>}
        
      {loading
        ? <TableRow>
          <TableCell colSpan={14}>
            <Skeleton variant='rect' height={300} />
          </TableCell>
        </TableRow>
        : motions.map(motion => {
          var { 
            timestamp,  
            keyFrameData, 
            launchMonitorData, 
            fullMotionMetrics,
            attributes
          } = motion;

          const loadFrameName = motion.motionType === motionTypesConstants.baseballPitch
            ? keyFramesConstants.maxLoad
            : keyFramesConstants.heelStrike;
          const middleFrameName = motion.motionType === motionTypesConstants.baseballPitch
            ? keyFramesConstants.peakPelvisSpeed
            : keyFramesConstants.firstMove;
          const endFrameName = motion.motionType === motionTypesConstants.baseballPitch
            ? keyFramesConstants.ballRelease
            : keyFramesConstants.contact;

          const xfAtLoad = getXFactorValAtFrame(keyFrameData, loadFrameName);
          const xfStretch = xfAtLoad - fullMotionMetrics.maxXFactor;

          return <React.Fragment key={motion.id}>
            <TableRow className={classes.tableRow}>
              <TableCell>{formatMonthDayTime(timestamp)}</TableCell>
              <TableCell>{motion.motionType}</TableCell>
              <TableCell>{launchMonitorData && launchMonitorData.ballspeedMph}</TableCell>
              <TableCell>{fullMotionMetrics.peakSpeedPelvis}</TableCell>
              <TableCell>{fullMotionMetrics.peakSpeedTorso}</TableCell>
              <TableCell>{fullMotionMetrics.peakSpeedUpperArm}</TableCell>
              <TableCell>{fullMotionMetrics.peakSpeedHand}</TableCell>
              <TableCell>{fullMotionMetrics.differentialSpeedGainPelvisToTorso}</TableCell>
              <TableCell>{fullMotionMetrics.differentialSpeedGainTorsoToArm}</TableCell>
              <TableCell>{fullMotionMetrics.differentialSpeedGainArmToHand}</TableCell>
              <TableCell>
                {getXFactorAtFrame(keyFrameData, loadFrameName)}
              </TableCell>
              <TableCell>
                {getXFactorAtFrame(keyFrameData, middleFrameName)}
              </TableCell>
              <TableCell>
                {getXFactorAtFrame(keyFrameData, endFrameName)}
              </TableCell>
              <TableCell>{xfStretch != null ? xfStretch.toFixed(1) : '-'}</TableCell>
              <TableCell>{motion.fullMotionMetrics.peakSpeedSequence}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell colSpan={13}>
                {formatAttributes(attributes).map(attribute => <Chip 
                  key={`${motion.id}-${attribute}`} 
                  className={classes.attributeChip}
                  label={attribute} 
                  size='small'
                />)}
              </TableCell>
            </TableRow>
          </React.Fragment>;
        })
      }
    </TableBody>
  </Table>;
}

export default EventUserMotionMetricsTable;
