import Network from './network';

const EVALREPORT = 'API/Evaluations';
const NOTES = 'Notes';

export const GetLatestEval = (userId) => {
  // TODO: implement so we can get only the latest eval
};

export const GetEvalList = async (userId, cancelToken) => {
  const resp = await Network.Get(EVALREPORT, { userId }, cancelToken);
  return resp.data.items;
};

export const GetGolfEvalReport = async (reportId) => {
  const url = EVALREPORT + `/Golf/${reportId}`;
  const resp = await Network.Get(url, null, null);
  return resp.data;
};

export const GetEvaluation = async (id) => {
  const url = EVALREPORT + `/${id}`;
  const resp = await Network.Get(url);
  return resp.data;
};

export const PostEvaluation = async (data) => {
  const resp = await Network.Post(EVALREPORT, data);
  return resp.data;
};

export const UpdateEvaluationNotes = async (id, data) => {
  const url = EVALREPORT + `/${id}/` + NOTES;
  const resp = await Network.Put(url, data);
  return resp.data;
};
