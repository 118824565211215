import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import orderBy from 'lodash/orderBy';
import useStyles from './styles';

import SyncAttemptItem from './syncAttemptItem';
import SyncRecordsDialog from './../syncRecordsDialog';
import { SyncStatus } from './../../../../constants/captureEvents.constants';
import { fetchCaptureEvent } from '../../../../store/captureEvents';
import { Button, Collapse, Grid } from '@material-ui/core';

const CHECK_TIME = 5 * 1000; // milliseconds
const NUM_ATTEMPTS_DISPLAYED = 1;

function SyncAttempts({
  captureEvent
}) {
  const classes = useStyles();

  const eventId = captureEvent.id;
  const syncAttempts = captureEvent.rawDataSyncAttempts;
  const timerId = useRef(null);

  const dispatch = useDispatch();
  const [reviewingSync, setReviewingSync] = useState(null);
  const [showAllAttempts, setShowAllAttempts] = useState(false);
  const dialogOpen = reviewingSync !== null;

  const toggleShowAttempts = () => setShowAllAttempts(prev => !prev);

  const isProcessing = useMemo(() => {
    if (!syncAttempts) return false;
    return syncAttempts.some(({ status }) => status === SyncStatus.PROCESSING);
  }, [syncAttempts]);

  const refreshCaptureEvent = useCallback(() => {
    dispatch(fetchCaptureEvent(eventId));
  }, [eventId, dispatch]);

  useEffect(() => {
    if (!dialogOpen && isProcessing) {
      timerId.current = setInterval(() => {
        refreshCaptureEvent();
      }, CHECK_TIME);
    } 
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
        timerId.current = null;
      }
    };
  }, [isProcessing, dialogOpen, refreshCaptureEvent]);

  const syncComplete = () => {
    setReviewingSync(null);
    refreshCaptureEvent();
  };
  
  const orderedAttempts = orderBy(syncAttempts, attempt => attempt.creationTimestamp, ['desc']);

  return <>
    {
      orderedAttempts &&
      <div className={classes.container}>
        {
          orderedAttempts.lenth === 0 &&
          <span>There are no sync attempts for this event yet</span>
        }
        {
          orderedAttempts.length > 0 &&
          orderedAttempts.slice(0, NUM_ATTEMPTS_DISPLAYED).map((syncAttempt) => {
            return <SyncAttemptItem
              key={syncAttempt.id}
              syncAttempt={syncAttempt}
              onReview={(sync) => setReviewingSync(sync)}
            />;
          })
        }
        {
          orderedAttempts.length > NUM_ATTEMPTS_DISPLAYED &&
          <div>
            <Collapse in={showAllAttempts}>
              {
                orderedAttempts.slice(NUM_ATTEMPTS_DISPLAYED).map((syncAttempt) => {
                  return <SyncAttemptItem
                    key={syncAttempt.id}
                    syncAttempt={syncAttempt}
                    onReview={(sync) => setReviewingSync(sync)}
                  />;
                })
              }
            </Collapse>
            <Grid container justify='center'>
              <Button color='primary' onClick={toggleShowAttempts}>
                {showAllAttempts ? 'Hide ' : 'Show '} previous attempts
              </Button>
            </Grid>
          </div>
        }
      </div>
    }

    <SyncRecordsDialog 
      open={dialogOpen}
      syncAttempt={reviewingSync}
      onSyncComplete={syncComplete}
      onSyncRejected={syncComplete}
      onClose={() => setReviewingSync(null)}
    />
  </>;
};

export default SyncAttempts;