import Logger from 'js-logger';
import { keyBy, orderBy } from 'lodash';
import { startOfDay, endOfDay } from 'date-fns';
import { GetMotionsForUserInCurrentOrg } from '../network/motionRequests';
import { LAUNCH_MONITOR, FULL_MOTION_METRICS } from '../constants/motionSubresources.constants';

const MOTION_COUNT_PER_REQUEST = 100;

// Actions
const RESET_STATE = 'kdashboard/playerPercentiles/reset';
const SET_START_DATE = 'kdashboard/playerPercentiles/startDate/set';
const SET_END_DATE = 'kdashboard/playerPercentiles/endDate/set';
const SET_SHOW_MAX_SPEEDS = 'kdashboard/playerPercentiles/showMaxSpeeds/set';
const SET_WEIGHT = 'kdashboard/playerPercentiles/weight/set';
const SET_EXPANDED_MOTION_ID = 'kdashboard/playerPercentiles/expandedMotionId/set';
const SET_FOCUS_SEGMENT = 'kdashboard/playerPercentiles/focusSegment/set';
const REQUEST_MOTIONS = 'kdashboard/playerPercentiles/motions/request';
const ERROR_REQUESTING_MOTIONS = 'kdashboard/playerPercentiles/motions/error';
const ADD_MOTIONS = 'kdashboard/playerPercentiles/motions/add';
const SET_MOTION_SELECTION_TOGGLES = 'kdashboard/playerPercentiles/motions/selectionToggles/set';

// Reducer
const initialState = {
  startDate: new Date(),
  endDate: new Date(),
  showMaxSpeeds: true,
  weight: '',
  expandedMotionId: null,
  focusSegment: '',
  moreMotionsAvailable: true,
  initialRequestMade: false,
  motions: {
    loading: false,
    error: false,
    items: [],
    pagination: {
      startIndex: 0,
      count: 0,
      totalCount: 0
    }
  }
};

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case RESET_STATE:
      return initialState;
    case SET_START_DATE:
      return { ...state, startDate: action.payload, motions: initialState.motions };
    case SET_END_DATE:
      return { ...state, endDate: action.payload, motions: initialState.motions };
    case SET_SHOW_MAX_SPEEDS:
      return { ...state, showMaxSpeeds: action.payload };
    case SET_WEIGHT:
      return { ...state, weight: action.payload };
    case SET_EXPANDED_MOTION_ID:
      return { ...state, expandedMotionId: action.payload };
    case SET_FOCUS_SEGMENT:
      return { ...state, focusSegment: action.payload };
    case REQUEST_MOTIONS:
      return { ...state, motions: { ...state.motions, loading: true, error: false }};
    case ERROR_REQUESTING_MOTIONS:
      return { ...state, motions: { ...state.motions, loading: false, error: true }};
    case ADD_MOTIONS:
      const existingMotionsById = keyBy(state.motions.items, 'id');
      const newMotions = action.payload.motions;
      const newMotionIds = new Set(newMotions.map(x => x.id));
      const newMotionsWithSelectionToggles = newMotions.map(item => {
        const { before, after } = existingMotionsById[item.id] ?? {};
        return { ...item, before, after };
      });
      const existingMotions = state.motions.items.filter(x => !newMotionIds.has(x.id));
      return { 
        ...state, 
        moreMotionsAvailable: action.payload.moreMotionsAvailable,
        initialRequestMade: true,
        motions: { 
          items: orderBy([...newMotionsWithSelectionToggles, ...existingMotions], 'timestamp'), 
          loading: false, 
          error: false,
          pagination: action.payload.pagination
        }
      };
    case SET_MOTION_SELECTION_TOGGLES:
      const { motionId, ...selections } = action.payload;
      return {
        ...state,
        motions: {
          ...state.motions,
          items: state.motions.items.map(item => item.id !== motionId
            ? item
            : { ...item, ...selections })
        }
      };
    default:
      return state;
  }
}

// Action Creators
export function resetPlayerPercentilesState() {
  return { type: RESET_STATE };
}

export function setStartDate(startDate) {
  return dispatch => {
    dispatch({ type: SET_START_DATE, payload: startDate });
    dispatch(fetchMotions());
  };
}

export function setEndDate(endDate) {
  return dispatch => {
    dispatch({ type: SET_END_DATE, payload: endDate });
    dispatch(fetchMotions());
  };
}

export function setShowMaxSpeeds(showMaxSpeeds) {
  return { type: SET_SHOW_MAX_SPEEDS, payload: showMaxSpeeds };
}

export function setWeight(weight) {
  return { type: SET_WEIGHT, payload: weight };
}

export function setExpandedMotionId(motionId) {
  return { type: SET_EXPANDED_MOTION_ID, payload: motionId };
}

export function setFocusSegment(focusSegment) {
  return { type: SET_FOCUS_SEGMENT, payload: focusSegment };
}

export function setMotionBeforeToggle(motionId, before) {
  return { 
    type: SET_MOTION_SELECTION_TOGGLES, 
    payload: { motionId, before, after: false }
  };
}

export function setMotionAfterToggle(motionId, after) {
  return { 
    type: SET_MOTION_SELECTION_TOGGLES, 
    payload: { motionId, after, before: false }
  };
}

function requestMotions() {
  return { type: REQUEST_MOTIONS };
}

function errorRequestingMotions() {
  return { type: ERROR_REQUESTING_MOTIONS };
}

function addMotions(motions, pagination, moreMotionsAvailable) {
  return { type: ADD_MOTIONS, payload: { motions, pagination, moreMotionsAvailable }};
}

function getNextMotions(state) {
  const { userId } = state.currentPlayer;
  const { startDate, endDate, motions } = state.playerPercentiles;
  const { startIndex, count } = motions.pagination;

  const params = { 
    earlierThan: endOfDay(endDate).toISOString(),
    laterThan: startOfDay(startDate).toISOString(),
    subresources: [LAUNCH_MONITOR, FULL_MOTION_METRICS],
    startIndex: startIndex + count,
    count: MOTION_COUNT_PER_REQUEST
  };

  return GetMotionsForUserInCurrentOrg(userId, params);
}

export function fetchMotions() {
  return (dispatch, getState) => {
    const state = getState();
    if (state.playerPercentiles.motions.loading || state.currentPlayer.userId == null) {
      return;
    }

    dispatch(requestMotions);

    const handleSuccess = resp => {
      const { pagination, items } = resp;
      const sortedMotions = orderBy(items, 'timestamp');
      const moreMotionsAvailable = pagination.startIndex + pagination.count < pagination.totalCount;
      dispatch(addMotions(sortedMotions, pagination, moreMotionsAvailable));
    };

    const handleError = err => {
      Logger.error(`Error fetching player pecentile motions: ${err}`);
      dispatch(errorRequestingMotions());
    };

    getNextMotions(state).then(handleSuccess, handleError);
  };
}
