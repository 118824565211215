import { unit } from 'mathjs';
import _ from 'lodash';
import { 
  LAUNCH_MONITOR, 
  FULL_MOTION_METRICS, 
  HITTING_DEVICE_SENSOR 
} from '../../../../constants/motionSubresources.constants';
import { sequenceBodyPartsToNumbersFormatter } from '../../../../utils/sequence.utils';

const round = to => value => value != null ? _.round(value, to) : null;

const speedGain = gain => gain != null ? '1 : ' + round(1)(gain) : null;
const sToMs = s => s != null ? round(0)(s * 1000) : null;
const mpsToMph = mps => mps != null ? round(1)(unit(mps, 'm/s').toNumber('mi/h')) : null;

const MOST_COMMON_SEQUENCE = { 
  title: 'Most Common Sequence', 
  subresource: FULL_MOTION_METRICS, 
  key: 'peakSpeedSequence',
  transform: sequenceBodyPartsToNumbersFormatter
};
const TORSO_PEAK_SPEED = { 
  title: 'Torso Peak Speed', 
  subresource: FULL_MOTION_METRICS, 
  key: 'peakSpeedTorso',
  units: 'deg/s',
  transform: round(0)
};
const PELVIS_PEAK_SPEED = { 
  title: 'Pelvis Peak Speed', 
  subresource: FULL_MOTION_METRICS, 
  key: 'peakSpeedPelvis', 
  units: 'deg/s',
  transform: round(0)
};
const ARM_PEAK_SPEED = { 
  title: 'Arm Peak Speed', 
  subresource: FULL_MOTION_METRICS, 
  key: 'peakSpeedUpperArm', 
  units: 'deg/s',
  transform: round(0)
};
const HAND_PEAK_SPEED = { 
  title: 'Hand Peak Speed', 
  subresource: FULL_MOTION_METRICS, 
  key: 'peakSpeedHand', 
  units: 'deg/s',
  transform: round(0)
};
const PELVIS_TO_TORSO_SPEED_GAIN = { 
  title: 'Pelvis to Torso Speed Gain', 
  subresource: FULL_MOTION_METRICS, 
  key: 'speedGainTorsoToPelvis', 
  transform: speedGain
};
const TORSO_TO_ARM_SPEED_GAIN = { 
  title: 'Torso to Arm Speed Gain', 
  subresource: FULL_MOTION_METRICS, 
  key: 'speedGainArmToTorso', 
  transform: speedGain
};
const ARM_TO_HAND_SPEED_GAIN = { 
  title: 'Arm to Hand Speed Gain', 
  subresource: FULL_MOTION_METRICS, 
  key: 'speedGainHandToArm', 
  transform: speedGain
};
const TIME_TO_CONTACT = { 
  title: 'Time to Contact', 
  subresource: FULL_MOTION_METRICS, 
  key: 'firstMoveToContactTime', 
  units: 'ms',
  transform: sToMs
};
const MAX_X_FACTOR = { 
  title: 'Max X-Factor', 
  subresource: FULL_MOTION_METRICS, 
  key: 'maxXFactor', 
  units: 'deg',
  transform: round(0)
};

const BAT_SPEED = { 
  title: 'Bat Speed', 
  subresource: HITTING_DEVICE_SENSOR, 
  key: 'deviceSpeedMetersPerSecond', 
  units: 'm/s',
  transform: mpsToMph
};
const ATTACK_ANGLE = { 
  title: 'Attack Angle', 
  subresource: HITTING_DEVICE_SENSOR, 
  key: 'attackAngleDegrees', 
  units: 'deg',
  transform: round(0)
};
const ROTATIONAL_ACCELERATION = { 
  title: 'Rotational Acceleration', 
  subresource: HITTING_DEVICE_SENSOR, 
  key: 'rotationalAccelerationGs', 
  units: 'g',
  transform: round(1)
};

const EXIT_VELOCITY = { 
  title: 'Exit Velocity', 
  subresource: LAUNCH_MONITOR, 
  key: 'ballspeedMph', 
  units: 'mph',
  transform: round(1)
};

export function getMetrics(useThirdPartyData, useExitVelocity) {
  if (useThirdPartyData) {
    let metrics = [
      MOST_COMMON_SEQUENCE, TORSO_PEAK_SPEED, MAX_X_FACTOR, ATTACK_ANGLE, BAT_SPEED,
      TIME_TO_CONTACT, PELVIS_PEAK_SPEED, PELVIS_TO_TORSO_SPEED_GAIN
    ];
    return useExitVelocity 
      ? [...metrics, ROTATIONAL_ACCELERATION, EXIT_VELOCITY] 
      : [...metrics, ARM_TO_HAND_SPEED_GAIN, ROTATIONAL_ACCELERATION];
  } else {
    let metrics = [
      MOST_COMMON_SEQUENCE, TORSO_PEAK_SPEED, ARM_PEAK_SPEED, 
      TORSO_TO_ARM_SPEED_GAIN, MAX_X_FACTOR, TIME_TO_CONTACT, PELVIS_PEAK_SPEED, 
      HAND_PEAK_SPEED, PELVIS_TO_TORSO_SPEED_GAIN
    ];
    return useExitVelocity ? [...metrics, EXIT_VELOCITY] : [...metrics, ARM_TO_HAND_SPEED_GAIN];
  }
};

