import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect, Prompt } from 'react-router-dom';
import logger from 'js-logger';
import axios from 'axios';

import PlayerDataTabs from './tabs';
import { history } from '../../store';
import ActionPlanComponent from '../actionPlanComponent';
import Overview from './overview';
import ActionPlans from './actionPlans';
import DevelopmentPlans from './actionPlans/oldDevelopmentPlans';
import Progress from './progress';
import MotionVideos from './motionVideos';
import LiveCapture from './liveCapture';
import Analytics from './analytics';
import SwingDetails from './swingDetails';
import AccountInfo from './accountInfo';
import KAssessments from './kAssessments';

import Mobility from './mobility';
import { PERMISSIONS, TAB_PERMISSIONS } from '../../constants/permissions.constants';
import { GetUser } from '../../network/userRequests';
import { GetNotes } from '../../network/notesRequests';
import { SetCurrentPlayer } from '../../store/players';
import { setNotes, setLoadingNotes, setNotesError } from '../../store/notes';
import { closeActionPlan } from '../../store/actionPlan';
import useCurrentPlayerName from '../../utils/useCurrentPlayerName';
import useStyles from './styles';
import VideoAnalysis from './videoAnalysis';
import PlayerPercentiles from './playerPercentiles';
import { resetPlayerPercentilesState } from '../../store/playerPercentiles';
import BLAITrainingPlans from 'pages/BLAITrainingPlans';

const TAB_ROUTES = [
  { label: 'Overview', value: 'overview', component: Overview, permission: TAB_PERMISSIONS.overview, onOldDashboard: true },
  { label: 'Action Plans', value: 'action-plans', component: ActionPlans, permission: TAB_PERMISSIONS.actionPlans },
  { label: 'Development Plans', value: 'development-plans', component: DevelopmentPlans, onOldDashboard: true },
  { label: 'Progress', value: 'progress', component: Progress, permission: TAB_PERMISSIONS.progress },
  { label: 'Live Capture', value: 'live-capture', component: LiveCapture, permission: TAB_PERMISSIONS.liveCapture },
  { label: 'Video', value: 'video', component: MotionVideos, permission: TAB_PERMISSIONS.video, onOldDashboard: true },
  { label: 'Mobility', value: 'mobility', component: Mobility, permission: TAB_PERMISSIONS.mobility },
  { label: 'Analytics', value: 'analytics', component: Analytics, permission: TAB_PERMISSIONS.analytics },
  { label: 'Swing Details', value: 'swing-details', component: SwingDetails, permission: TAB_PERMISSIONS.swingDetails },
  { label: 'K-Assessments', value: 'k-assessments', component: KAssessments, permission: TAB_PERMISSIONS.kAssessments },
  { label: 'Video Analysis', value: 'video-analysis', component: VideoAnalysis, permission: TAB_PERMISSIONS.videoAnalysis },
  { label: 'Account Info', value: 'account-info', component: AccountInfo, permission: TAB_PERMISSIONS.overview },
  { label: 'Player Percentiles', value: 'player-percentiles', component: PlayerPercentiles, permission: PERMISSIONS.internalTooling },
  { label: 'BLAI Training Plans', value: 'blai-training-plans', component: BLAITrainingPlans, permission: PERMISSIONS.internalTooling }
];

function PlayerData({ location, match }) {

  // Gets tab value based off of route (3rd item in url) so that tab always matches location
  // function is required for initial call when initializing selectedTab
  function getTabValue() {
    const { pathname } = location;
    let items = pathname.split('/');
    if (items[0] === '') items = items.slice(1);
    if (items.length < 4) return TAB_ROUTES[0].value;
    return items[3];
  }

  const { userId } = match.params;
  const [selectedTab, setSelectedTab] = useState(getTabValue() || 'overview');
  const dispatch = useDispatch();
  const featurePermissions = useSelector(state => state.featurePermissions);
  const actionPlanInProgress = useSelector(state => state.actionPlan.inProgress);
  const playerName = useCurrentPlayerName();
  const classes = useStyles();

  useEffect(() => {
    return function() { 
      dispatch(closeActionPlan()); 
      dispatch(resetPlayerPercentilesState());
    };
  }, [userId, dispatch]);

  useEffect(() => {
    let cancelToken = axios.CancelToken.source();
    async function getAndSetCurrentPlayer() {
      dispatch(SetCurrentPlayer({ userId }));
      try {
        var user = await GetUser(userId, cancelToken);
        dispatch(SetCurrentPlayer(user));
      } catch (e) {
        if (axios.isCancel(e)) return;
        logger.error('Error getting user: ', e);
      }
    }
    getAndSetCurrentPlayer();
    return function() {
      cancelToken.cancel();
      dispatch(SetCurrentPlayer({}));
    };
  }, [userId, dispatch]);

  useEffect(() => {
    let cancelToken = axios.CancelToken.source();
    async function setActionPlans() {
      try {
        dispatch(setLoadingNotes());
        dispatch(setNotes(await GetNotes(userId, cancelToken)));
      } catch (e) {
        if (axios.isCancel(e)) return;
        logger.error('Error setting action plans: ', e);
        dispatch(setNotesError());
      }
    }
    setActionPlans();
    return function() {
      dispatch(setNotes([]));
      cancelToken.cancel();
    };
  }, [userId, dispatch]);
  
  function onTabChange(_, value) {
    setSelectedTab(value);
    history.push(`${match.url}/${value}`);
  }

  function getPromptMessage(nextLocation) {
    if (!actionPlanInProgress || nextLocation.pathname.startsWith(match.url)) {
      return true;
    }
    return `You have started creating an action plan for ${playerName}, ` + 
      'are you sure you want to navigate away? This action plan will not be saved.';
  }

  const newDashboardVisible = featurePermissions.includes(PERMISSIONS.newDashboardVisible);
  const tabs = !newDashboardVisible
    ? TAB_ROUTES.filter(tab => tab.onOldDashboard)
    : TAB_ROUTES.filter(tab => tab.permission && featurePermissions.includes(tab.permission));

  return <div>
    <Prompt message={getPromptMessage} />
  
    <div>
      <PlayerDataTabs onTabChange={onTabChange} selectedTab={selectedTab} tabs={tabs} />
      <div>
        <Switch>
          {tabs.map(route => <Route exact 
            key={route.value} 
            path={`${match.path}/${route.value}`} 
            component={route.component} />)}
          <Route render={() => <Redirect to={`${match.url}/${selectedTab}`} />} />
          <Route render={() => <div>Not found :(</div>} />
        </Switch>
      </div>
    </div>

    {featurePermissions.includes(TAB_PERMISSIONS.actionPlans) &&
      <ActionPlanComponent className={classes.actionPlanComponent} />
    }
  </div>;
}

export default PlayerData;
