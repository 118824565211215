import { useEffect, useState } from 'react';
import axios from 'axios';
import { GetAnalyzedSwingData } from '../../../../network/motionRequests';

export default function useAnalyzedMotionData(id) {
  const [ analyzedMotionData, setAnalyzedMotionData ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const NUM_RETRIES = 3;
  const RETRY_DELAY_MS = 2000;

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const retryIntervalIds = [];

    function fetchDataRetry(retries) {
      retryIntervalIds.push(setInterval(() => fetchData(retries), RETRY_DELAY_MS));
    }

    async function fetchData(retries = NUM_RETRIES) {
      setLoading(true);
      try {
        setAnalyzedMotionData(await GetAnalyzedSwingData(id, cancelToken));
      } catch (e) {
        if (axios.isCancel(e)) return;
        if (e.response && e.response.status === 404 && retries > 1) {
          fetchDataRetry(retries - 1);
          return;
        }
        setError(e);
      }
      setLoading(false);
    };
    fetchData();

    return function() {
      cancelToken.cancel();
      retryIntervalIds.forEach(id => id != null && clearInterval(id));
    };
  }, [id]);

  return { analyzedMotionData, loading, error };
}
