import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import log from 'js-logger';
import Typography from '@material-ui/core/typography';
import useStyles from './styles';
import ProgressButton from '../../../../components/progressButton';
import ConfirmationDialog from '../../../../components/dialogs/confirmationDialog';
import { removeVideoAnnotationFromAnalysis, updateVideoAnnotation } from '../../../../store/videoAnalyses';
import { CHARACTERISTIC_SEVERITY } from '../../../../constants/videoAnalysis.constants';
import { PERMISSIONS } from '../../../../constants/permissions.constants';
import { DeleteAnnotatedVideo, PatchAnnotation } from '../../../../network/videoAnalysisRequests';
import ActionPlanCharacteristicButton from './actionPlanCharacteristicButton';

function CharacteristicSubmission({ 
  analysis,
  characteristic, 
  onSubmit,
  existingAnnotation,
  loading,
  onUpdate
}) {
  const dispatch = useDispatch();
  const [callInProgress, setCallInProgress] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [submittedSeverity, setSubmittedSeverity] = useState(null);
  const [error, setError] = useState(false);
  const [alreadyAttached, setAlreadyAttached] = useState(false);

  const permissions = useSelector(state => state.currentOrganization.featurePermissions);
  const hasToolingPermission = permissions.some(permission => 
    permission.feature === PERMISSIONS.internalTooling);

  useEffect(() => { setAlreadyAttached(false); }, [characteristic]);
  useEffect(() => { !loading && setSubmittedSeverity(null); }, [loading]);

  const classes = useStyles();

  async function deleteAnnotation() {
    if (existingAnnotation == null) return;
    let cancelToken = axios.CancelToken.source();
    try {
      setCallInProgress(true);
      await DeleteAnnotatedVideo(analysis.id, existingAnnotation.id, cancelToken);
      dispatch(removeVideoAnnotationFromAnalysis(existingAnnotation.id, analysis.id));
      setShowDeleteConfirmation(false);
      setCallInProgress(false);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setCallInProgress(false);
      if (e.response && e.response.status === 400) {
        setAlreadyAttached(true);
      } else {
        setError(true);
        log.error(e, `Unknown error when deleting annotation with id ${existingAnnotation.id}`);
      }
    }
  }

  async function changeResult() {
    try {
      setCallInProgress(true);
      const severity = existingAnnotation.swingCharacteristicSeverity;
      const newResult = severity === CHARACTERISTIC_SEVERITY.none
        ? CHARACTERISTIC_SEVERITY.present
        : CHARACTERISTIC_SEVERITY.none;
      const patchDoc = [
        { op: 'test', path: '/swingCharacteristicSeverity', value: severity },
        { op: 'replace', path: '/swingCharacteristicSeverity', value: newResult }
      ];
      const updatedAnnotation = await PatchAnnotation(analysis.id, existingAnnotation.id, patchDoc);
      dispatch(updateVideoAnnotation(updatedAnnotation));
      onUpdate(updatedAnnotation);
    } catch (e) {
      setError(true);
      log.error(e, 'Error when patching an annotation');
    } finally {
      setCallInProgress(false);
    }
  }

  function onConfirmationCancel() {
    setShowDeleteConfirmation(false);
    setError(false);
  }

  const submitCharacteristic = (severity) => () => {
    setSubmittedSeverity(severity);
    onSubmit(severity);
  };

  if (characteristic == null || characteristic === '') {
    return <Typography>Please select a characteristic to submit.</Typography>;
  }

  if (existingAnnotation != null) {
    return <div>
      <ConfirmationDialog 
        title={`Redo Annotation for ${characteristic}?`}
        open={showDeleteConfirmation}
        onCancel={onConfirmationCancel}
        onConfirm={deleteAnnotation}
        loading={callInProgress}
        confirmDisabled={alreadyAttached}
      >
        <Typography>
          Starting to redo the annotation for <b>{characteristic}</b> will delete 
          the existing annotation. Are you sure you want to delete and redo?
        </Typography>

        {error && <Typography color='error'>
          An error occured while attempting to start to redo this
          annotation. Please try again. 
        </Typography>}

        {alreadyAttached && <Typography color='error'>
          The annotation for <b>{characteristic}</b> has already been attached 
          to an action plan and submitted to the player, so it can no longer be edited.
        </Typography>}
      </ConfirmationDialog>

      <Typography>Annotation for <b>{characteristic}</b> submitted.</Typography>
      <div className={classes.submissionButtons}>
        <ProgressButton variant='contained' color='primary'
          className={classes.button}
          showProgress={callInProgress} 
          onClick={() => setShowDeleteConfirmation(true)}
          disabled={alreadyAttached}
        >
          Redo
        </ProgressButton>
        <ProgressButton variant='contained' color='primary'
          className={classes.button}
          showProgress={callInProgress}
          onClick={changeResult}
        >
          Change Result
        </ProgressButton>

        {existingAnnotation.swingCharacteristicSeverity === CHARACTERISTIC_SEVERITY.present && 
        hasToolingPermission && 
          <ActionPlanCharacteristicButton
            annotation={existingAnnotation}
            analysis={analysis}
          /> 
        }
      </div>

      {alreadyAttached && <Typography className={classes.alreadyAttachedText}>
        Cannot redo a video that has already been added to an action plan.
      </Typography>}
    </div>;
  }

  return <div>
    <Typography>Finish and submit <b>{characteristic}</b></Typography>
    <div className={classes.buttonContainer}>
      <ProgressButton variant='contained' color='primary'
        className={classes.button}
        showProgress={loading && submittedSeverity === CHARACTERISTIC_SEVERITY.none}
        disabled={loading}
        onClick={submitCharacteristic(CHARACTERISTIC_SEVERITY.none)}
      >
        Looks Good
      </ProgressButton>
      <ProgressButton variant='contained' color='primary'
        className={classes.button}
        showProgress={loading && submittedSeverity === CHARACTERISTIC_SEVERITY.present}
        disabled={loading}
        onClick={submitCharacteristic(CHARACTERISTIC_SEVERITY.present)}
      >
        Needs Work
      </ProgressButton>
    </div>
  </div>;
};

export default CharacteristicSubmission;