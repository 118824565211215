import React from 'react';
import { ButtonBase } from '@material-ui/core';
import useStyles from './styles';
import classNames from 'classnames';
import DrawIcon from '@material-ui/icons/Gesture';
import StarIcon from '@material-ui/icons/Star';

import SelectableTag from '../../../../../components/selectableTag';

function CharacteristicSelectionSection({
  items = [],
  title = '',
  selected,
  selectedSection,
  sectionId,
  onClick,
  analysis
}) {

  const classes = useStyles();

  const charSelected = (c) => {
    onClick(sectionId, c);
  };

  const videoTypeSelected = () => {
    onClick(sectionId, null);
  };

  const sectionIsSelected = sectionId === selectedSection;
 
  return (
    <div>
      <ButtonBase 
        className={classNames(classes.header, { [classes.selectedVideo]: sectionIsSelected })}
        onClick={videoTypeSelected}
      >
        { title }
      </ButtonBase>
      {
        selected === null &&
        sectionIsSelected &&
        <div className={classes.message}>Please select a characteristic:</div>
      }
      <div className={classes.tags}>
        {
          items.map((data, idx) => {
            let icon;
            if (!data.annotation) {
              icon = null;
            } else if (analysis && analysis.selectedMotionAttributeId === data.parentId) {
              icon = StarIcon;
            } else {
              icon = DrawIcon;
            }

            return (
              <span key={idx} className={classes.tag}>
                <SelectableTag
                  Icon={icon}
                  className={classes.tag}
                  onClick={charSelected}
                  selected={selected === data.id}
                  data={data}>{ data.name }</SelectableTag>
              </span>
            );
          })
        }
      </div>
    </div>
  );
}

export default CharacteristicSelectionSection;