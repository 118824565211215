import React from 'react';
import useStyles from './styles';

import { Paper } from '@material-ui/core';
import GraphToggle from './stickyHeaderGraphToggle';
import SaveButton from './saveButton';


function AssessmentStickyHeader({ 
  labels = {}, 
  label = null, 
  children, 
  motionData, 
  userId,
  showSave,
  onSaveDraft = () => {},
  savingDraft = false
}) {

  // we keep a constant height of 60 pixels here
  const classes = useStyles({ height: 60 });

  return (
    <div className={classes.container}>
      <Paper elevation={1}>
        <div className={classes.headerContainer}>
          {children}
        </div>
      </Paper>

      <div className={classes.buttons}>
        <GraphToggle
          labels={labels}
          label={label}
          motionData={motionData} 
          userId={userId} />

        {
          showSave &&
            <SaveButton onClick={onSaveDraft} saving={savingDraft}/>
        }
      </div>
    </div>
  );
}

export default AssessmentStickyHeader;