import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  badge: {
    backgroundColor: 'lightgray'
  },
  badgeRoot: {
    cursor: 'pointer'
  },
  icon: props => ({
    color: props.color
  }),
  popover: {
    pointerEvents: 'none'
  },
  popoverPaper: {
    padding: theme.spacing(1)
  },
  tagColor: {
    height: '10px',
    aspectRatio: 1,
    borderRadius: 5,
    marginRight: '4px'
  }
}));

export default useStyles;
