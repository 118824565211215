import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../../styles/palette';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  firstDropdown: {
    marginRight: '5px'
  },
  setMenu: {
    display: 'flex',
    flexDirection: 'column',
    width: '160px'
  },
  menuTitle: {
    padding: '5px 10px',
    textAlign: 'center',
    fontWeight: 'bold',
    whiteSpace: 'normal !important'
  },
  item: {
    borderTop: `1px solid ${palette.darkGray}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.mediumGray
    },
    '&$loadingItem': {
      backgroundColor: palette.mediumGray
    },
    flexWrap: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    height: '40px',
    justifyContent: 'space-between',
    '& span': {
      fontSize: '14px'
    }  
  },
  loadingItem: {
    pointerEvents: 'none'
  },
  setItem: {
    padding: '0 5px'
  },
  goMenu: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px'
  },
  keyframeInfo: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 5px',
    height: '100%'
  },
  goToTitle: {
    marginRight: '7px',
    whiteSpace: 'nowrap'
  },
  goToActions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px'
  },
  emptyMessage: {
    padding: '5px 5px 10px 5px',
    textAlign: 'center'
  },
  removeAction: {
    color: palette.darkGray,
    '&:hover': {
      color: palette.hoverBlack
    }
  }
}));