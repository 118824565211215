import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Paper, TextField, CircularProgress } from '@material-ui/core';
import useStyles from './styles';
import { UpdateOrganizationUserProperties } from '../../../network/userRequests';
import { SetPlayerOrganizationUserProperties } from '../../../store/players';

const UserRow = ({ firstName, lastName, userId, organizationUserProperties = {}}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { externalId = '' } = organizationUserProperties || {};
  const [isLoading, setIsLoading] = useState(false);
  const [externalUserId, setExternalUserId ] = useState(externalId);

  const [resultMessage, setResultMessage] = useState('');
  const updateExternalUserId = ({ target }) => {
    setResultMessage('');
    const newID = target.value;
    setExternalUserId(newID);
  };

  const saveExternalUserId = async () => {
    setIsLoading(true);
    try {
      const orgUserProperties = 
        await UpdateOrganizationUserProperties(userId, { externalId: externalUserId } );
      dispatch(SetPlayerOrganizationUserProperties(userId, orgUserProperties));
      setResultMessage('Saved!');
    } catch(e) {
      setResultMessage('There was a problem saving.');    
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Paper className={classes.container}> 
        <Typography variant='h6'>{firstName} {lastName}</Typography>
        <div className={classes.flexContainer}>
          <div className={classes.textFieldContainer}>
            <Typography>External User Id:</Typography> 
            <TextField value={externalUserId}
              disabled={isLoading}
              onBlur={saveExternalUserId}
              onChange={updateExternalUserId}
              onKeyUp={e => { if(e.key === 'Enter') e.target.blur(); }}
            />
          </div>
          {isLoading 
            ? <CircularProgress /> 
            : <Typography variant={'h5'}>{resultMessage}</Typography>}
        </div>
      </Paper>
    </div>
  );
};

export default UserRow;