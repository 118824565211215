import { useState, useEffect, useRef } from 'react';

const useConstantlySaveOnUpdate = (saveCallback, interval = 500) =>
{
  const isSaving = useRef(false);
  const lastSavedTimestamp = useRef(new Date());
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(false);
  // This is done so we basically are not going to miss new updates and also we wait for
  // the server to finish before we save again:
  useEffect(() => {
    // Avoiding weird bug with sets not working here or triggering infinite loop:
    const intervalToken = setInterval(async () => {
      if(!isSaving.current &&
        lastUpdatedTimestamp !== false &&
        lastUpdatedTimestamp.getTime() >= lastSavedTimestamp.current.getTime() 
      ) {
        lastSavedTimestamp.current = new Date();
        isSaving.current = true;
        await saveCallback();
        isSaving.current = false;      
      }
    }, interval);
    return () => {
      clearInterval(intervalToken);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedTimestamp, interval]); // Does some debouncing.
  return setLastUpdatedTimestamp;
};
export default useConstantlySaveOnUpdate;