import makeStyles from '@material-ui/core/styles/makeStyles';
import palette from '../../../../styles/palette';

const useStyles = makeStyles(() => ({
  expandedContainer: {
    border: `1px solid ${palette.mediumGray}`,
    borderRadius: '3px',
    padding: '10px'
  },
  fileInput: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  fileName: {
    marginLeft: '10px'
  },
  textField: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center'
  },
  inputZIndexFix: {
    zIndex: 0
  },
  help: {
    marginLeft: '10px'
  },
  dropdownLabel: {
    zIndex: 0
  }
}));

export default useStyles;