import { SEGMENT_FOCUS } from 'constants/trainingPlans.constants';
import BodyParts from 'constants/bodyParts.constants';
import { minBy } from 'lodash';

export const bodyPartsFocusedForResults = result => {
  const partsFocused = [];
  if (result.pelvisFocus === SEGMENT_FOCUS.primary) partsFocused.push(BodyParts.pelvis);
  if (result.torsoFocus === SEGMENT_FOCUS.primary) partsFocused.push(BodyParts.torso);
  if (result.upperArmFocus === SEGMENT_FOCUS.primary) partsFocused.push(BodyParts.upperArm);
  if (result.handFocus === SEGMENT_FOCUS.primary) partsFocused.push(BodyParts.hand);
  return partsFocused;
};

export const getInitialCaptureEventResult = trainingPlan => {
  return minBy(
    trainingPlan?.trainingPlanCaptureEventResults ?? [],
    ({ captureEventResult }) => new Date(captureEventResult.creationTimestamp) 
  )?.captureEventResult;
};