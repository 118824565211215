import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Styles from './styles';

class SignUpInfo extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classNames({ [classes.error]:this.props.error })}>
        <b style={{ visibility: this.props.error ? 'visible' : 'hidden' }}>!</b> 
        &nbsp;{this.props.children}
      </div>
    );
  }
}

SignUpInfo.propTypes = { error: PropTypes.bool };

export default withStyles(Styles)(SignUpInfo);