import palette from '../../../../styles/palette';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_theme => ({
  filterByPanel: {
    textAlign: 'center',
    color: palette.white
  },
  filterItemContainer: {
    position: 'relative',
    marginBottom: '15px',
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer'
    }
  },
  svgBox: {
    top: 0,
    left: 0,
    bottom: 0,
    position: 'absolute'
  },
  filterCaption: {
    fontSize: '10px',
    display: 'block',
    lineHeight: '13px',
    marginTop: '3px',
    marginBottom: '5px'
  },
  filterCount: {
    marginTop: '8px'
  },
  hidden: {
    visibility: 'hidden'
  },
  filterTitle: {
    marginTop: '15px',
    marginBottom: '15px'
  },
  boldText: {
    fontWeight: 600
  }
}));

export default useStyles;
