import React from 'react';
import TeamListItem from './teamListItem';
import PlayerListItem from './playerListItem';

function TeamList({ teams, hideTeams, selectedUserId, onChange, checkPlayerAction }) {
  return <div name='players-sidebar'>
    {teams.map(team => (
      <div key={team.id}>
        {!hideTeams && <TeamListItem team={team} />}
        {team.players.map(player => (
          <PlayerListItem
            key={`${team.id}-${player.userId}`}
            player={player} 
            selected={player.userId === selectedUserId}
            onClick={() => onChange(player.userId)}
            actionTakenText={checkPlayerAction(player)}
          />
        ))}
      </div>
    ))}
  </div>;
}

export default React.memo(TeamList);
