import useStyles from './styles';
import { useState } from 'react';
import orderBy from 'lodash/orderBy';
import { NOTE_STATUSES } from 'constants/noteStatuses.constants';
import { Table, TableBody, TableRow, TableCell, Typography, CircularProgress } from '@material-ui/core';
import { formatDate } from 'utils/formatting.utils';
import ProgressButton from 'components/progressButton';
import useTrainingPlanSentNotes from './useTrainingPlanSentNotes';

const ResendNotes = ({ trainingPlan, updateNoteStatus }) => {
  const classes = useStyles();
  const [noteLoading, setNoteLoading] = useState(null);
  const [sentNotes, loading] = useTrainingPlanSentNotes(trainingPlan);
  
  const resendNote = async (note) => {
    if(!window.confirm('Are you sure you want to resend an email for this note?')) return;
    setNoteLoading(note);
    try {
      await updateNoteStatus(note, NOTE_STATUSES.sent);
    } catch(e) {
      alert('There was an error resending');
    } finally {
      setNoteLoading(null);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }
  
  return sentNotes?.map(note => <div key={'resend-note-' + note.id}>
    <div className={classes.resendHeader}>
      <Typography className={classes.resendTitle}>
        Week {note.sequenceOrder + 1}
      </Typography>
      <ProgressButton
        showProgress={noteLoading === note}
        variant='outlined'
        color='secondary'
        onClick={() => resendNote(note)}
      >
        Resend
      </ProgressButton>
    </div>
    <hr />
    <Table>
      <TableBody>
        {orderBy(note.notifications, notification => new Date(notification?.editedTimestamp), ['desc'])
          .filter(x => x.communicationChannel?.type === 'EmailAddress')
          .map(notification => (<TableRow key={'notifcation' + notification.id}>
            <TableCell>{notification.deliveryStatus}</TableCell>
            <TableCell>
              {notification.editedTimestamp ? formatDate(notification.editedTimestamp) : '--'}
            </TableCell>
            <TableCell>{notification.communicationChannel?.value}</TableCell>
          </TableRow>))}
      </TableBody>
    </Table>
  </div>);
};

export default ResendNotes;