import TEXT_TYPES from './../constants/actionPlanTextTypes.constants';
import { getPlainText, appendTextToMarkdown, clearEditorState } from './../components/editor';
import { resetTrainingPlan } from './trainingPlan';

export const positions = {
  CLOSED: 'closed',
  OPEN: 'open',
  MINIMIZED: 'minimized',
  EXPANDED: 'expanded'
};

// Actions
const SET_ACTION_PLAN = 'actionPlan/setPlan';
const CLEAR_ACTION_PLAN_TEXT = 'actionPlan/clearText';
const SET_ACTION_PLAN_TEXT = 'actionPlan/setText';
const SET_ACTION_PLAN_TEXT_DATA = 'actionPlan/setTextData';
const SET_ACTION_PLAN_VIDEO_IDS = 'actionPlan/setVideoIds';
const SET_ACTION_PLAN_POSITION = 'actionPlan/setPosition';
const SET_ACTION_PLAN_TEMPLATE_ID = 'actionPlan/setTemplateId';
const CLOSE_ACTION_PLAN = 'actionPlan/close';
const OPEN_ACTION_PLAN = 'actionPlan/open';
const SET_ACTION_PLAN_IMAGE_IDS = 'actionPlan/setImages';
const SET_ACTION_PLAN_DRILLS = 'actionPlan/setDrills';
const SET_TRAINING_PLAN_MODE = 'actionPlan/setTrainingPlanMode';

// Reducer
const initialState = { 
  position: positions.CLOSED, 
  textData: { formattedText: '', plainText: '', data: null, type: TEXT_TYPES.PLAIN_TEXT }, 
  videoIds: [], 
  images: [],
  noteTemplateId: null, 
  inProgress: false,
  assignedDrills: [],
  inTrainingPlanMode: false
};

export default function reducer(state = initialState, action) {

  switch(action.type) {
    case SET_ACTION_PLAN_TEXT:
      const oldTextData = state.textData;
      const oldEditorState = oldTextData.data;

      const newText = action.payload;
      const newEditorState = appendTextToMarkdown(newText, oldEditorState);
      const newPlainText = getPlainText(newEditorState);

      return {
        ...state,
        textData: {
          ...initialState.textData,
          type: oldTextData.type,
          data: newEditorState,
          formattedText: newText,
          plainText: newPlainText
        }
      };
    case CLEAR_ACTION_PLAN_TEXT:
      const clearedState = state.textData.data
        ? clearEditorState(state.textData.data)
        : {};
      return {
        ...state,
        textData: {
          formattedText: '',
          plainText: '',
          textFormatType: null, 
          data: clearedState
        }
      };
    case SET_ACTION_PLAN:
      const { payload } = action;
      return {
        ...state,
        textData: {
          formattedText: payload.formattedText,
          plainText: payload.text,
          textFormatType: payload.textFormatType
        }
      };
    case SET_ACTION_PLAN_TEXT_DATA:
      return { 
        ...state, 
        textData: action.payload
      };
    case SET_TRAINING_PLAN_MODE:
      return {
        ...state,
        inTrainingPlanMode: action.payload
      };
    case SET_ACTION_PLAN_VIDEO_IDS:
      return { 
        ...state, 
        videoIds: action.payload 
      };
    case SET_ACTION_PLAN_IMAGE_IDS:
      return { 
        ...state, 
        images: action.payload 
      }; 
    case SET_ACTION_PLAN_TEMPLATE_ID:
      return {
        ...state,
        noteTemplateId: action.payload.templateId
      };
    case SET_ACTION_PLAN_DRILLS:
      return {
        ...state,
        assignedDrills: action.payload
      };
    case SET_ACTION_PLAN_POSITION: 
      return { ...state, position: action.payload };
    case OPEN_ACTION_PLAN:
      return { ...state, position: positions.OPEN, inProgress: true };  
    case CLOSE_ACTION_PLAN: 
      return initialState;
    default:
      return state;
  }
};

// Action Creators
export function setActionPlanText(actionPlanText) {
  return { type: SET_ACTION_PLAN_TEXT, payload: actionPlanText };
};

export function setInitialTextData(note) {
  return { type: SET_ACTION_PLAN, payload: note };
}

export function setInTrainingPlanMode(inTrainingPlan) {
  return { type: SET_TRAINING_PLAN_MODE, payload: inTrainingPlan };
}

export function clearActionPlanText() {
  return { type: CLEAR_ACTION_PLAN_TEXT };
}

export function setActionPlanTextData(actionPlanData) {
  return { type: SET_ACTION_PLAN_TEXT_DATA, payload: actionPlanData };
};

export function setActionPlanVideoIds(videoIds) {
  return { type: SET_ACTION_PLAN_VIDEO_IDS, payload: videoIds };
}

export function setActionPlanImages(images) {
  return { type: SET_ACTION_PLAN_IMAGE_IDS, payload: images };
}

export function setNoteTemplateId(id) {
  return { 
    type: SET_ACTION_PLAN_TEMPLATE_ID, 
    payload: { 
      templateId: id 
    }
  }; 
}

export function setActionPlanDrills(drills) {
  return {
    type: SET_ACTION_PLAN_DRILLS,
    payload: drills
  };
}

function setActionPlanPosition(position) {
  return { type: SET_ACTION_PLAN_POSITION, payload: position };
}

export function openActionPlan() {
  return { type: OPEN_ACTION_PLAN };
}

export function minimizeActionPlan() {
  return setActionPlanPosition(positions.MINIMIZED);
}

export function expandActionPlan() {
  return setActionPlanPosition(positions.EXPANDED);
}

function _closeActionPlan() {
  return { type: CLOSE_ACTION_PLAN };
}

// Thunks
export function closeActionPlan() {
  return (dispatch) => {
    dispatch(_closeActionPlan());
    dispatch(resetTrainingPlan());
  };
}

export function setActionPlan(note) {
  // this is a convenience function to update the editor with 
  // all values from an existing note. It just calls
  // all of the relevant dispatches.
  return (dispatch) => {
    dispatch(clearActionPlanText());
    dispatch(setInitialTextData(note));
    dispatch(setActionPlanVideoIds(note.noteVideos.map(v => v.videoId)));
    dispatch(setActionPlanImages(note.noteImages));
    dispatch(setActionPlanDrills(note.assignedDrills));
  };
}
