import palette from '../../styles/palette';

const styles = theme => ({
  fileInput: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1'
  },
  fileInputLabel: {
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    color: palette.white,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    cursor: 'pointer'
  },
  uploadText: { 
    color: 'white' 
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  selectedFilename: {
    color: palette.darkGray,
    fontStyle: 'italic',
    padding: '0px 10px'
  },
  cancelIcon: {
    color: palette.darkGray,
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(80%)'
    }
  }
});

export default styles;
