import { ANALYSIS_METRICS } from '../dataAnalysis.constants';

export const CSV_COLUMNS = [
  {
    label: 'Id',
    getValue: motion => motion.id
  },
  {
    label: 'Timestamp',
    getValue: motion => motion.timestamp
  },
  {
    label: 'Tag',
    getValue: motion => motion.tag?.tagName
  },
  {
    label: 'UserId',
    getValue: motion => motion.userId
  },
  {
    label: 'First Name',
    getValue: motion => motion.user?.firstName
  },
  {
    label: 'Last Name',
    getValue: motion => motion.user?.lastName
  },
  {
    label: 'Email Address',
    getValue: motion => motion.user?.emailAddress
  },

  ...ANALYSIS_METRICS
];
