import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../../../styles/palette';

export default makeStyles(() => ({
  container: {
    position: 'relative',
    zIndex: 5,
    '&:hover': {
      '& $icon': {
        transform: 'rotate(0deg)',
        color: palette.hoverBlack
      },
      '& $dropUp': {
        backgroundColor: palette.mediumGray
      }
    }
  },
  dropUp: {
    backgroundColor: palette.appBackground,
    borderRadius: '3px',
    border: `1px solid ${palette.darkGray}`,
    padding: '3px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  title: {
    whiteSpace: 'nowrap'
  },
  icon: {
    width: '25px',
    height: '25px',
    transition: 'transform 0.15s',
    transform: 'rotate(90deg)',
    color: palette.darkGray
  },
  menu: {
    position: 'absolute',
    transform: 'translate(-50%, -100%)',
    left: '50%',
    top: 0,
    backgroundColor: palette.lightGray,
    border: `1px solid ${palette.darkGray}`
  }
}));