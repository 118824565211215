import React from 'react';
import {
  FormControl,
  FormHelperText
} from '@material-ui/core';
import IconTextField from 'components/iconTextField';
import LockOutlined from '@material-ui/icons/LockOutlined';

const PasswordTextField = ({ 
  helperText, 
  error, 
  classes, 
  showPassword,  
  ...rest 
}) => {
  return <FormControl 
    className={classes.fullWidth} 
    error={error}
  >
    <div className={classes.textFieldContainer}>
      <IconTextField 
        icon={<LockOutlined className={classes.lockIcon} />}
        error={error}
        {...rest}
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  </FormControl>;
};

export default PasswordTextField;