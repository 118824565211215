import palette from '../styles/palette';
export const tags = ['Positive', 'Neutral', 'Negative'];

export const labelPalette = {
  Positive: palette.positiveLabelColor,
  Neutral: palette.neutralLabelColor,
  Negative: palette.negativeLabelColor
};

export const STATUS = {
  WIP: 'Wip',
  PUBLISHED: 'Published'
};

export const convertSectionsToObject = (sections) => {
  const obj = {};
  sections.forEach(({ sectionName, text, id }) => {
    obj[sectionName] = {
      text
    };

    if (id != null) {
      obj[sectionName].id = id;
    }
  });

  // return id object 
  return obj;
};

export const convertSectionsToArray = (sections, assessmentId) => {
  const arr = [];
  Object.keys(sections).forEach((sectionName) => {
    const info = sections[sectionName];
    const hasId = info.id || info.id === 0;
    if (info && info.text) {
      const newObj = {
        sectionName,
        text: info.text || '',
        assessmentId: assessmentId
      };
      if (hasId) {
        newObj.id = info.id;
      }
      arr.push(newObj);
    }
  });
  return arr;
};

export const convertLabelsToObject = (labels) => {
  const obj = {};
  labels.forEach((label) => {
    obj[label.name] = {
      id: label.id || label.id === 0 ? label.id : label.name,
      name: label.name,
      tag: label.tag,
      description: label.description,
      graphPoints: label.graphPoints,
      tableCells: label.tableCells
    };
  });
  return obj;
};

export const convertLabelsToArray = (labels) => {
  const arr = [];
  Object.keys(labels).forEach((name) => {
    arr.push(labels[name]);
  });
  return arr;
};

export const convertLabelsToServerCallObject = (labels, assessmentId) => {
  return convertLabelsToArray(labels).map((labelObj) => {
    const labelCopy = Object.assign({}, labelObj);
    const { newLabel = false } = labelCopy;

    if (newLabel) {
      delete labelCopy.id;
      delete labelCopy.assessmentId;
    } else {
      labelCopy.assessmentId = assessmentId;
    }
  
    // edit tableCells so it works
    labelCopy.tableCells = labelCopy.tableCells.map((cellObj) => {
      const newCellObj = Object.assign({}, cellObj);
      if (!newLabel) {
        newCellObj.assessmentLabelId = labelCopy.id; 
      } else { // if it's a new label we must get rid of our frontend id
        delete newCellObj.id;
      }
      return newCellObj;
    });
  
    labelCopy.graphPoints = labelCopy.graphPoints.map((xy) => {
      const newPointsObj = Object.assign({}, xy);
      if (!newLabel) {
        newPointsObj.assessmentLabelId = labelCopy.id;
      } else { // if it's a new label we must get rid of our frontend id
        delete newPointsObj.id;
      }
      return xy;
    });
  
    return labelCopy;
  });
};

export const transformToServerObject = (
  userId, 
  orgId, 
  motionData, 
  sections,
  labels, 
  status, 
  id
) => {
  const keepId = id != null;
  const newObj = {
    userId,
    organizationId: orgId,
    motionId: motionData.motion.id,
    status,
    sections: convertSectionsToArray(sections, id),
    labels: convertLabelsToServerCallObject(labels, id)
  };

  if (keepId) {
    newObj.id = id;
  }
  return newObj;
};
