const KEY_FRAMES = {
  address: 'Address',
  top: 'Top',
  impact: 'Impact',
  heelStrike: 'HeelStrike',
  pelvisTransition: 'PelvisTransition',
  torsoTransition: 'TorsoTransition',
  followThrough: 'FollowThrough',
  contact: 'Contact',
  firstMove: 'FirstMove',
  finish: 'Finish',
  maxLoad: 'MaxLoad',
  peakPelvisSpeed: 'PeakPelvisSpeed',
  ballRelease: 'BallRelease'
};

export default KEY_FRAMES;
