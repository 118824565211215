import React, { useState } from 'react';
import { 
  Button,
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { history } from '../../store';
import MotionSelectionDialog from './motionSelectionDialog';
import SelectedMotionsList from './selectedMotionsList';
import TimeseriesGraph from './timeseriesGraph';
import MetricTable from './metricTable';
import ExportButton from './exportButton';
import ScatterPlot from './scatterPlot';
import StandardDeviation from './standardDeviation';
import CorrelationMatrix from './correlationMatrix';
import MotionSimilarity from './motionSimilarity';
import { useLocalStoragePersistedReduxState } from '../../store/store.utils';
import { setDataAnalysis } from '../../store/dataAnalysis';

function DataAnalysis() {
  useLocalStoragePersistedReduxState('dataAnalysis', setDataAnalysis);

  const [addingMotions, setAddingMotions] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    'ScatterPlot': { expanded: true, component: <ScatterPlot /> },
    'TimeSeriesGraph': { expanded: true, component: <TimeseriesGraph /> },
    'StandardDeviation': { expanded: true, component: <StandardDeviation /> },
    'MetricTable': { expanded: true, component: <MetricTable /> },
    'CorrelationMatrix': { expanded: true, component: <CorrelationMatrix /> },
    'MotionSimilarity': { expanded: true, component: <MotionSimilarity /> }
  });

  const toggleExpandedSection = (sectionName) => {
    return () => {
      const sectionInfo = expandedSections[sectionName];
      const updatedSectionInfo = { ...sectionInfo, expanded: !sectionInfo.expanded };
      setExpandedSections({ ...expandedSections, [sectionName]: updatedSectionInfo });
    };
  };

  return <Box padding={1}>
    <Button 
      variant='outlined' 
      color='primary' 
      onClick={() => history.push('/player-development')}
    >
      Back
    </Button>
    <Grid container spacing={2} alignItems='flex-start'>
      <Grid container item xs={4} spacing={1}>
        <Grid item xs>
          <Typography variant='h4'>Motions</Typography>
        </Grid>
        <Grid item>
          <Button 
            variant='outlined' 
            color='primary' 
            onClick={() => setAddingMotions(true)}
          >
            Add Motions
          </Button>
        </Grid>
        <Grid item xs={12}>
          <SelectedMotionsList />
        </Grid>
      </Grid>
      <Grid container item xs={8} spacing={2}>
        <Grid item xs>
          <Typography variant='h4'>Tools</Typography>
        </Grid>
        <Grid item>
          <ExportButton />
        </Grid>
        {Object.keys(expandedSections).map((SectionName) => {
          const { expanded, component } = expandedSections[SectionName];
          return <Grid item xs={12} key={SectionName}>
            <Accordion expanded={expanded} onChange={toggleExpandedSection(SectionName)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>{SectionName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {component}
              </AccordionDetails>
            </Accordion>
          </Grid>;
        }) }
      </Grid>
    </Grid>

    <MotionSelectionDialog 
      open={addingMotions} 
      onClose={() => setAddingMotions(false)} 
      maxWidth='lg'
      fullWidth
    />
  </Box>;
}

export default DataAnalysis;
