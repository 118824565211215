import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(_ => ({
  grid: {
    marginTop: '10px'
  },
  paperItemContainer: {
    padding: '10px'
  },
  graphTitle: {
    fontStyle: 'italic',
    fontSize: '16px',
    marginBottom: '5px'
  }
}));

export default useStyles;
