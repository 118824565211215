import React from 'react';
import PropTypes from 'prop-types';
import log from 'js-logger';
import { 
  Modal,
  CircularProgress,
  Paper,
  Button
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import { 
  GetMotionsForUserInCurrentOrg, 
  GetAnalyzedSwingData 
} from '../../network/motionRequests';
import { FULL_MOTION_METRICS } from '../../constants/motionSubresources.constants';
import KinematicSequenceGraphWithLabels from '../kinematicSequenceGraphWithLabels';
import KinematicDetailsDense from '../kinematicDetailsDense';
import DropdownCarousel from '../dropdownCarousel';
import { formatDateWithSeconds } from '../../utils/formatting.utils';
import Styles from './styles';
import axios from 'axios';
import NoDataMessage from '../noDataMessage';

// Table to show decel metrics. Will be removed after baseball team reviews
import DecelerationTable from './decelerationTable';

/* The KinematicScroller is a re-usable component that is a wrapper around
 * kinematic sequence graphs for a given player. 
 *
 * It provides the ability to cycle through and select various motions for the user to display.
 */
class KinematicCarousel extends React.Component {
  state = {
    currentMotionIdx: '',
    motions: [],
    frames: {},
    modalIsOpen: false,
    isLoadingMotion: true,
    userHasNoMotions: false
  }

  _cancelToken = axios.CancelToken.source();
  _onMotionSelected = this.props.onMotionSelected || null;

  async componentDidMount() {
    const { userId } = this.props;

    try {
      let motions = await GetMotionsForUserInCurrentOrg(userId, {
        count: 20, 
        subresources: [ FULL_MOTION_METRICS ]
      }, this._cancelToken);
      motions = motions.items;
      let userHasNoMotions = motions.length === 0;
      if (userHasNoMotions) {
        this.setState({ motions, userHasNoMotions, isLoadingMotion: false });
      } else {
        this.setState({ motions, userHasNoMotions }, 
          async () => await this._setMotionIndex(0));
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        log.error(e);
        this.setState({ userHasNoMotions: false });
      }
    }
  }

  componentWillUnmount() {
    this._cancelToken.cancel();
    this._onMotionSelected = null;
  }

  _setMotionIndex = async (idx) => {
    const { motions, frames } = this.state;
    if (motions.length === 0) return;

    if (idx > motions.length - 1) {
      idx = motions.length - 1;
    } else if (idx < 0) {
      idx = 0;
    }
    
    let selectedMotion = motions[idx];
    if (!frames[selectedMotion.id]) {
      try {
        this.setState({ isLoadingMotion: true });
        let analyzedFrames = await GetAnalyzedSwingData(
          selectedMotion.id, this._cancelToken);
        let updatedFrames = { ...frames, [selectedMotion.id]: analyzedFrames };
        this.setState({ frames: updatedFrames, isLoadingMotion: false, currentMotionIdx: idx });
      } catch (e) {
        if (!axios.isCancel(e)) {
          log.error(e);
          this.setState({ isLoadingMotion: false, currentMotionIdx: idx });
        } 
      }
    } else {
      this.setState({ currentMotionIdx: idx });
    }

    if (this._onMotionSelected) {
      this._onMotionSelected({
        motion: selectedMotion,
        analyzedFrames: this.state.frames[selectedMotion.id],
        fullMotionMetrics: selectedMotion.fullMotionMetrics
      });
    }
  }

  _closeModal = () => this.setState({ modalIsOpen: false });
  _openModal = () => this.setState({ modalIsOpen: true });

  render() {
    const { motions, frames, currentMotionIdx, isLoadingMotion, userHasNoMotions } = this.state;
    const { classes, userId } = this.props;
    const motion = motions[currentMotionIdx];
    const analyzedFrames = motion ? frames[motion.id] : null;
    const fullMotionMetrics = motion ? motion.fullMotionMetrics : null;


    return (
      <div>
        <DropdownCarousel
          items={motions.map(motion => formatDateWithSeconds(motion.timestamp))}
          currentIdx={currentMotionIdx}
          onChange={async(idx) => await this._setMotionIndex(idx)}
          dropdownGridSpace={8}
        >
          <div className={classes.graphProgressContainer}>
            {isLoadingMotion &&
              <CircularProgress 
                className={classNames(classes.circularProgress, { 
                  [classes.spinnerColor]: Object.keys(frames).length > 0
                })} 
                size={45}
              />
            }
            {userHasNoMotions 
              ? <div className={classes.noMotionsContainer}>
                <Paper className={classes.noMotionsText}>
                  <NoDataMessage>Player does not have any motions yet.</NoDataMessage>
                </Paper>
              </div>
              : <div onClick={this._openModal}>                
                <KinematicSequenceGraphWithLabels
                  motion={motion}  
                  showCrosshairs
                  analyzedFrames={analyzedFrames}
                  fullMotionMetrics={fullMotionMetrics}                    
                />                
                <KinematicDetailsDense motion={motion} userId={userId}/>
                <DecelerationTable motion={motion} />
              </div>}
          </div>
        </DropdownCarousel>
        
        {!userHasNoMotions &&
          <Modal
            aria-labelledby='Fullscreen Kinematic Graph'
            open={this.state.modalIsOpen}
            onClose={this._closeModal}
          >
            <div className={classes.graphModal}>
              <Button className={classes.closeButton} onClick={this._closeModal}>
                Close
              </Button>
              <DropdownCarousel
                items={motions.map(motion => formatDateWithSeconds(motion.timestamp))}
                currentIdx={currentMotionIdx}
                onChange={async(idx) => await this._setMotionIndex(idx)}
                dropdownGridSpace={2}
              >
                <div>
                  {isLoadingMotion && 
                    <CircularProgress 
                      className={classNames(classes.circularProgress, { 
                        [classes.spinnerColor]: Object.keys(frames).length > 0
                      })} 
                      size={45}
                    />
                  }
                  <div>
                    <KinematicSequenceGraphWithLabels
                      motion={motion}
                      analyzedFrames={analyzedFrames}
                      fullMotionMetrics={fullMotionMetrics}
                      showCrosshairs
                      svgHeight={900}
                      svgWidth={1350}
                      showDetailedTicks
                    />
                  </div>
                </div>
              </DropdownCarousel>
            </div>
          </Modal>
        }
      </div>
    );
  }
}

KinematicCarousel.propTypes = {
  userId: PropTypes.string.isRequired
};

export default withStyles(Styles)(KinematicCarousel);