import { makeStyles } from '@material-ui/core/styles';

const borderRadius = '10px';
const tabPadding = '3px';
const getBorder = color => `2px solid ${color}`;

export default makeStyles(_ => ({
  container: ({ secondaryColor }) => ({
    width: '100%',
    backgroundColor: secondaryColor,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  tabContainer: ({ primaryColor }) => ({
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: getBorder(primaryColor),
    borderBottom: getBorder(primaryColor),
    paddingTop: tabPadding,
    paddingBottom: tabPadding
  }),
  selected: ({ primaryColor, secondaryColor }) => ({
    backgroundColor: primaryColor,
    color: secondaryColor
  }),
  leftTab: ({ primaryColor }) => ({
    borderTopLeftRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
    borderLeft: getBorder(primaryColor)
  }),
  rightTab: ({ primaryColor }) => ({
    borderTopRightRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    borderRight: getBorder(primaryColor)
  })
}));