import makeStyles from '@material-ui/core/styles/makeStyles';
import orange from '@material-ui/core/colors/orange';
import Palette from 'styles/palette';

const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: Palette.appBackground,
    height: '100vh',
    width: '100vw'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  resendNotesContainer: {
    padding: '20px'
  },
  headerWrapper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  tableWrapper: {
    margin: theme.spacing(2),
    flex: 1,
    overflow: 'auto'
  },
  pastDueIcon: {
    color: Palette.error
  },
  dueIcon: {
    color: orange[500]
  },
  upcomingIcon: {
    color: Palette.warning
  },
  dueStatusCountItem: {
    display: 'flex'
  }
}));

export default useStyles;
