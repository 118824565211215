const styles = {
  root: {
    flexGrow: 1,
    zIndex: 100,
    position: 'fixed',
    top: 0,
    width: '100%'
  },
  title: {
    marginLeft: '10px;',
    flexGrow: 1
  },
  headerLink: {
    textDecoration: 'none',
    color: 'white',
    padding: 0,
    '&:hover': { backgroundColor: 'transparent' }
  },
  img: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  link: { color: 'white' }
};

export default styles;
