import React from 'react';
import useStyles from './styles';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import RecordRow from './../recordRow';

function SyncRecordsTable({
  records = [],
  syncAttemptData,
  columns,
  selectedRecords,
  onRecordClicked
}) {
  const classes = useStyles();

  return <div className={classes.container}>
    <div className={classes.table}>
      <TableContainer className={classes.tableContainer}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell className={classes.headerStyle}>Synced</TableCell>
              {
                columns.map(({ header }) => {
                  return <TableCell 
                    key={header}
                    className={classes.headerStyle}
                  >
                    {header}
                  </TableCell>;
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              records.map(record => {
                const selected = selectedRecords.includes(record.id);
                return <RecordRow
                  key={record.id}
                  syncAttemptData={syncAttemptData}
                  record={record}
                  columns={columns}
                  selected={selected}
                  onClick={onRecordClicked}
                />;
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </div>;
};

export default SyncRecordsTable;