import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../../styles/palette';
import { calculateFontSize } from './../../styles';

export const useClasses = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: calculateFontSize()
  },
  newBadges: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold'
  },
  feed: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },  
  badgeNotification: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '3px'
  },
  badgeImg: {
    height: '22px',
    marginRight: '5px'
  },
  seeAll: {
    marginTop: '10px',
    border: `1px solid ${palette.white}`,
    padding: '5px',
    borderRadius: '5px'
  },
  message: {
    '& span': {
      whiteSpace: 'pre-wrap'
    }
  },
  bold: {
    fontWeight: 'bold'
  }
}));

export default useClasses;