import Network from './network';

const LEADERBOARDS = 'API/Leaderboards';

export const GetLeaderboards = async (organizationId, cancelSource) => {
  var res = await Network.Get(LEADERBOARDS, { organizationId }, cancelSource);
  return res.data.items;
};

export const GetLeaderboardScores = async (leaderboardId, cancelSource) => {
  var res = await Network.Get(
    `${LEADERBOARDS}/${leaderboardId}/LeaderboardScores`,
    cancelSource);
  return res.data.items;
};

export const GetUserLevels = async (organizationId) => {
  var res = await Network.Get(`${LEADERBOARDS}/UserLevels`, { organizationId });
  return res.data.items;
};