import React from 'react';

import { 
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core';

function MessageDialog({ open, onClose, title, exitMessage, children }) {
  return (
    <Dialog onClose={onClose} aria-labelledby='message-dialog' open={open}>
      {
        title &&
            <DialogTitle onClose={onClose}>
              {title}
            </DialogTitle>
      }
            
      <DialogContent>
        <Typography>
          {children}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {exitMessage || 'EXIT'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MessageDialog;