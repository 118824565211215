const styles = {
  select: {
    color: 'var(--color)',
    '& > svg': {
      color: 'var(--color)'
    },
    '&:before': {
      borderBottom: 'none'
    },
    '&:after': {
      borderBottom: 'none'
    }
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: 'transparent'
    }
  }
};

export default styles;