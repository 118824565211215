import { useState, useEffect, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { GetAllPaginatedUsers } from 'network/userRequests';
import useNetworkRequest from 'network/useNetworkRequest';
import { getOrganizationId } from 'utils/auth.utils';

const DEBOUNCE_DELAY = 250;
const ORGANIZATION_ID = getOrganizationId();

function useFilteredPlayers(firstName, lastName, email, startIndex = 0, count = 10) {
  const [filterParams, setFilterParams] = useState({
    firstName,
    lastName,
    email
  });
  
  const getFilteredUsers = useCallback(async cancelSource => {
    const params = {
      ...filterParams,
      subresources: 'CaptureEventUsers',
      organizationId: ORGANIZATION_ID,
      startIndex,
      count
    };
    return await GetAllPaginatedUsers(params, cancelSource);
  }, [filterParams, startIndex, count]);

  const updateFiltersDebounceHandler = useMemo(() => debounce(setFilterParams, DEBOUNCE_DELAY), []);

  useEffect(() => {
    return updateFiltersDebounceHandler.cancel;
  }, [updateFiltersDebounceHandler]);

  useEffect(() => {
    updateFiltersDebounceHandler({ firstName, lastName, email });
  }, [updateFiltersDebounceHandler, firstName, lastName, email]);

  const [{ items, pagination }, loading, error] = useNetworkRequest({ items: [], pagination: {}}, 'GetFilteredUsers', getFilteredUsers);

  return { users: items, pagination, loading, error };
}

export default useFilteredPlayers;