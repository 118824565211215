import React, { useState, useEffect } from 'react';
import useStyles from './styles';

import { Paper } from '@material-ui/core';

import GraphIcon from '../../../../../assets/GraphIcon.png';
import AssessmentGraphDisplay from '../../assessmentGraphDisplay';

function StickyHeaderGraphToggle({ label, motionData, userId, labels }) {

  // state hooks
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const close = () => {
    setOpen(false);
  };

  const openGraph = () => {
    if (motionData) {
      setOpen(true);
    }
  };

  useEffect(() => {
    setOpen(Boolean(label));
  }, [label]);

  useEffect(() => {
    // guarantee that the last action is always close to the toggle
    // even if it's not correct, closing it is better than it being opened 
    // in a not optimal state
    setTimeout(() => {
      setOpen(false);
    }, 0);
  }, [motionData]);

  const labelName = label ? label.id : null;

  return (
    <div className={classes.container}>
      <Paper elevation={1}>
        {
          !open &&
          <div className={classes.graphIconContainer} onClick={openGraph}>
            <img src={GraphIcon} alt='Toggle Graph' />
          </div>
        }

        {
          open &&
          <>
            <div className={classes.graphPopupContainer}>
              <AssessmentGraphDisplay 
                label={labelName} 
                motionData={motionData} 
                userId={userId} 
                labels={labels}
              />
            </div>
            <div onClick={close} className={classes.cancelButton}>X</div>
          </>
        }
      </Paper>
    </div>
  );
}

export default StickyHeaderGraphToggle;