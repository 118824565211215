import Network from './network';

const URL = '/Api/TagGroups';

export const GetTagGroup = async (id, cancelSource) => {
  return (await Network.Get(URL + `/${id}`, null, cancelSource)).data;
};

export const GetAllTagGroups = async (cancelSource) => {
  return (await Network.Get(URL, null, cancelSource)).data;
};

export const CreateTagGroup = async (tagGroup) => {
  return (await Network.Post(URL, tagGroup)).data;
};

export const UpdateTagGroup = async (tagGroup) => {
  return (await Network.Put(URL + `/${tagGroup.id}`, tagGroup)).data;
};

export const DeleteTagGroup = async (groupId) => {
  return (await Network.Delete(URL + `/${groupId}`));
};