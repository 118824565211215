import Palette from '../../styles/palette';
import { makeStyles } from '@material-ui/core/styles';

export const selectedCellBorder = `2px solid ${Palette.black}`;
export const hoveredCellBorder = `2px solid ${Palette.brightBlue}`;

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2)
  },

  table: {
    fontSize: '13px',
    borderSpacing: 0,
    width: '100%',
    textAlign: 'center',
    borderCollapse: 'collapse',
    backgroundColor: Palette.white
  },
  tr: {
    padding: 0,
    margin: 0,
    borderCollapse: 'collapse'
  },
  th: {
    fontWeight: 'normal',
    backgroundColor: Palette.lightBlue,
    borderCollapse: 'collapse',
    padding: 0,
    margin: 0,
    whiteSpace: 'nowrap',
    color: Palette.black
  },
  td: {
    padding: 0,
    margin: 0,
    border: `1px solid ${Palette.lightGray}`,
    borderCollapse: 'collapse',
    whiteSpace: 'nowrap'
  }
}));