import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { 
  setInTrainingPlanMode,
  expandActionPlan
} from '../../../../store/actionPlan';
import { 
  setMotionAttribute,
  fetchAndLoadTemplate
} from '../../../../store/trainingPlan';
import ProgressButton from '../../../../components/progressButton';
import { UpdateAnalysis } from '../../../../network/videoAnalysisRequests';
import { GetMotionAttributes } from '../../../../network/motionAttributeRequests';
import { CHARACTERISTIC_SEVERITY } from '../../../../constants/videoAnalysis.constants';
import Logger from 'js-logger';
import { GetTrainingPlanTemplates } from '../../../../network/trainingPlanRequests';

function ActionPlanCharacteristicButton({
  annotation,
  analysis
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [missingAttribute, setMissingAttribute] = useState('');
  const dispatch = useDispatch();
  const user = useSelector(state => state.currentPlayer);

  async function assignToActionPlan() {
    setIsUpdating(true);
    
    try {
      // this is necessary until we update annotations to reference motion attribute ids
      const [attributes, templates] = await Promise.all([
        GetMotionAttributes({ type: 'OnBaseU' }),
        GetTrainingPlanTemplates()
      ]);
      
      const attribute = attributes.find(attribute => 
        attribute.name === annotation.swingCharacteristic);
      const template = templates.find(template => template.motionAttributeId === attribute.id);

      if (!template) {
        setMissingAttribute(attribute);
        setShowErrorModal(true);
        setIsUpdating(false);
      } else {
        const updatedAnalysis = await _addAttributeToAnalysis(attribute.id);

        const openingText = _getOpeningText(updatedAnalysis);
        dispatch(setMotionAttribute(attribute));
        dispatch(fetchAndLoadTemplate(
          template.id,
          analysis.id,
          user, 
          openingText, 
          [annotation.analyzedVideoId]));
        dispatch(setInTrainingPlanMode(true));
        dispatch(expandActionPlan());
      }
    } catch(e) {
      Logger.error('error when setting characteristic to analysis', e);
    }
    setIsUpdating(false);
  }

  async function _addAttributeToAnalysis(attributeId) {
    let analysisCopy = {
      ...analysis,
      selectedMotionAttributeId: attributeId
    };
    return await UpdateAnalysis(analysisCopy.id, analysisCopy);
  }

  function _getOpeningText(analysis) {
    let plan = 'Thanks for submitting your videos, we are excited to work with you ' +
      'to improve your swing! We started by evaluating your swing with the OnBaseU ' +
      'Swing Characteristics that are used by the MLB and multiple D1 programs. ' +
      'Here’s our breakdown of your swing based on the evaluation.\n';

    // When annotations start using attribute ids, this will need to be a lookup
    const needsWork = analysis.videoAnnotations.filter(annotation => 
      annotation.swingCharacteristicSeverity === CHARACTERISTIC_SEVERITY.present);
    const looksGood = analysis.videoAnnotations.filter(annotation => 
      annotation.swingCharacteristicSeverity === CHARACTERISTIC_SEVERITY.none);

    plan += '\nLOOKS GOOD:\n';
    looksGood.forEach(annotation => plan += `-${annotation.swingCharacteristic}\n`);
    plan += '\nNEEDS WORK:\n';
    needsWork.forEach(annotation => plan += `-${annotation.swingCharacteristic}\n`);

    plan += '\n';
    return plan;
  }

  function handleClose() {
    setShowErrorModal(false);
  }

  return (
    <div>
      <ProgressButton 
        variant='contained' 
        color='primary'
        onClick={assignToActionPlan}
        showProgress={isUpdating}
      >
        Assign To Action Plan
      </ProgressButton>
      <Dialog
        open={showErrorModal}
        onClose={handleClose}
      >
        <DialogTitle>Missing Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            In order for the training plan to be created you must first 
            create a template for {missingAttribute.name}. Go to the action plan screen,
            select the training plan icon, and then switch to template mode to create one.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>   
  );
}

export default ActionPlanCharacteristicButton;