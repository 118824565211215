import { SEGMENTS } from './playerPercentiles.constants';
import { GetPercentiles } from 'network/motionRequests';
import UnitConverter from 'utils/unitConverter.utils';
import mean from 'lodash/mean';

export async function calculatePercentileTableData(motions, weight, bodySpeedKeys) {
  if (weight == null) return [];

  const fetchPercentilesData = async m => {
    const pelvisSpeed = m.fullMotionMetrics[bodySpeedKeys[SEGMENTS.pelvis]];
    const torsoSpeed = m.fullMotionMetrics[bodySpeedKeys[SEGMENTS.torso]];
    const upperArmSpeed = m.fullMotionMetrics[bodySpeedKeys[SEGMENTS.arm]];
    const handSpeed = m.fullMotionMetrics[bodySpeedKeys[SEGMENTS.hand]];
    const ballSpeed = m.launchMonitorData?.ballspeedMph;
    
    const motionSpeeds = {
      pelvisSpeed,
      torsoSpeed,
      upperArmSpeed,
      handSpeed,
      ballSpeed
    };

    const percentiles = await GetPercentiles(
      UnitConverter.lbToKg(weight), motionSpeeds);

    return {
      motionId: m.id,
      timestamp: m.timestamp,
      speeds: {
        [SEGMENTS.pelvis]: pelvisSpeed,
        [SEGMENTS.torso]: torsoSpeed, 
        [SEGMENTS.arm]: upperArmSpeed, 
        [SEGMENTS.hand]: handSpeed, 
        [SEGMENTS.body]: mean([pelvisSpeed, torsoSpeed, upperArmSpeed, handSpeed]),
        [SEGMENTS.ball]: ballSpeed
      },
      percentiles: {
        [SEGMENTS.pelvis]: percentiles?.pelvisSpeed,
        [SEGMENTS.torso]: percentiles?.torsoSpeed, 
        [SEGMENTS.arm]:  percentiles?.upperArmSpeed, 
        [SEGMENTS.hand]: percentiles?.handSpeed, 
        [SEGMENTS.body]: percentiles?.bodySpeed,
        [SEGMENTS.ball]: percentiles?.ballSpeed
      }
    };
  };

  return await Promise.all(motions.map(fetchPercentilesData));
};
 

