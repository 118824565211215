import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from './styles';

const doesPlayerMatchSearch = (player, searchText) => {
  const fullName = (player.firstName || '').trim() + ' ' + (player.lastName || '').trim();
  return fullName.trim().toLowerCase().includes(searchText.trim().toLowerCase());
};

const searchPlayer = (teams, searchText) => {
  const cleanedupSearchText = searchText.trim().toLowerCase();
  return teams.reduce((teamsWithSearchedPlayers, team) => {
    let playerMatchesForTeam = team.players.filter(
      player => doesPlayerMatchSearch(player, cleanedupSearchText));
    if (playerMatchesForTeam.length > 0) {
      teamsWithSearchedPlayers.push({ ...team, players: playerMatchesForTeam });
    }
    return teamsWithSearchedPlayers;
  }, []);
};

const searchTeam = (teams, searchText) => {
  const cleanedupSearchText = searchText.trim().toLowerCase();
  return teams.filter(({ name }) => {
    return (name || '').trim().toLowerCase().includes(cleanedupSearchText);
  });
};

const wrapTooltip = (tooltipText, Icon) => {
  return ({ className }) => {
    const classes = useStyles();
    return <Tooltip
      arrow
      placement='right'
      title={<div className={classes.tooltipText}>
        <b>SEARCHING BY</b>
        <span>{ tooltipText }</span>
      </div>}>
      <Icon className={className}/>
    </Tooltip>;
  };
};

const SEARCH_OPTIONS = {
  PLAYER_NAME: {
    display: 'NAME',
    searchFn: searchPlayer,
    Icon: wrapTooltip('Player Name', PersonIcon)
  },
  TEAM_NAME: {
    display: 'TEAM',
    searchFn: searchTeam,
    Icon: wrapTooltip('Team Name', GroupIcon)
  }
};

export default SEARCH_OPTIONS;