import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SwingsTable from './swingsTable';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SelectionList from '../../components/selectionList';
import { orderBy } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Styles from './styles';

const SwingTable = ((props)=> {
  const { classes } = props;
  const [isListCollapsed, setIsListCollapsed] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const { players, organization } = useSelector(state => ({
    players: state.players,
    organization: state.currentOrganization
  }));
  const selectAll = players.length > 0 && players.length === selectedPlayers.length;

  const playersSorted = orderBy(players, player => {
    const lastName = player.lastName ? player.lastName.toLowerCase() : '';
    const firstName = player.firstName ? player.firstName.toLowerCase() : '';
    return lastName + ' ' + firstName;
  });

  return <div>
    Select Players: 
    <Button
      className={classes.selectButton}
      variant='contained'
      color='primary'
      onClick={() => {setIsListCollapsed(!isListCollapsed);}}>
      {selectedPlayers.length} {'players selected'}
    </Button>

    <Dialog fullWidth maxWidth='lg' 
      onClose={() => {setIsListCollapsed(true);}}
      open={!isListCollapsed}>
      <DialogContent>
        <div className={classes.header}>
          <Button
            className={classes.selectButton}
            variant='contained'
            color='secondary'
            onClick={() => {setIsListCollapsed(true);}}>
              Finish
          </Button>
          {error && <Typography color='error'>{error}</Typography>}
        </div>

        <SelectionList 
          items={playersSorted}
          selectedItems={selectedPlayers}
          onChange={newItems => {
            if (newItems.length <= 40 || newItems.length === players.length) {
              setSelectedPlayers(newItems);
              setError(null);
            } else {
              setError('Please select up to 40 players or all players.');
            }
          }}
          getLabel={player => `${player.firstName} ${player.lastName}`}
          getValue={player => player.userId}
        />

      </DialogContent> 
    </Dialog>
    <SwingsTable playerIds={selectedPlayers.map(player => player.userId)} 
      organization={selectAll ? organization : null} />
  </div>;
});

export default withStyles(Styles)(SwingTable);