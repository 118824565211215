import React, { useState, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import { 
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton
} from '@material-ui/core'; 
import ProgressButton from '../../../components/progressButton';
import CaptureEventsList from '../captureEventsHome/captureEventsList';
import useStyles from './styles';
import { formatMMDDYY } from 'utils/formatting.utils';
import { orderBy } from 'lodash';
import Cancel from '@material-ui/icons/Cancel';
import Logger from 'js-logger';
import { DownloadRosterEmails } from 'network/captureEventRequests';

function CaptureEventsActions({
  captureEvents = [],
  captureEventSplits = null,
  loading = false,
  error = false,
  open = false,
  onCancel = () => {}
}) {

  const [errorMessage, setErrorMessage] = useState('');
  const [performingAction, setPerformingAction] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState({});

  const classes = useStyles();

  const selectEvent = ({ id }) => {
    if (selectedEvents[id]) {
      setSelectedEvents(prev => ({ ...prev, [id]: false }));
    } else {
      setSelectedEvents(prev => ({ ...prev, [id]: true }));
    }
  };

  const {
    currentEvents = [],
    upcomingEvents = [],
    pastEvents = []
  } = captureEventSplits ?? {};

  const onDialogCancel = () => {
    setErrorMessage('');
    setSelectedEvents({});
    onCancel();
  };

  const selectedEventsList = useMemo(() => {
    return orderBy(
      captureEvents.filter(x => selectedEvents[x.id]), 
      x => new Date(x.startDate),
      'asc'); 
  }, [captureEvents, selectedEvents]);

  const deselectEvent = id => {
    setSelectedEvents(prev => ({ ...prev, [id]: false }));
  };

  const downloadRosters = async () => {
    setErrorMessage('');
    setPerformingAction(true);
    try {
      const eventIds = selectedEventsList.map(x => x.id);
      const response = await DownloadRosterEmails(eventIds);
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'RosterEmails.zip';
      document.body.appendChild(link);
      link.click();
    } catch (ex) {
      Logger.error(ex, 'Error downloading roster emails');
      setErrorMessage('Error trying to download roster emails');
    }
    setPerformingAction(false);
  };

  if (captureEventSplits == null) {
    return null;
  }

  return (<>
    <Dialog fullScreen open={open}>
      <DialogTitle onClose={onCancel}>
        Capture Event Actions
      </DialogTitle>
            
      <DialogContent>
        <Typography color='error'>
          {errorMessage}
        </Typography>
        <Grid container>
          <Grid xs={5} className={classes.scroll}>
            <CaptureEventsList 
              includeIds
              loading={loading}
              error={error} 
              currentEvents={currentEvents} 
              upcomingEvents={upcomingEvents}
              pastEvents={pastEvents}
              selectedEvents={selectedEvents}
              onChange={selectEvent}
            />
          </Grid>
          <Grid xs={7}>
            <div className={classes.listOfEvents}>
              <Typography className={classes.selectNumber}>
                {selectedEventsList?.length ?? 0} event(s) selected
              </Typography>

              <Table size='small'>
                <TableBody>
                  {
                    selectedEventsList.map(x => {
                      return <TableRow key={x.id}>
                        <TableCell><i>{ `[${x.id}]` }</i></TableCell>
                        <TableCell>{x.name}</TableCell>
                        <TableCell>{ 
                          `(${formatMMDDYY(x.startDate)} - ${formatMMDDYY(x.endDate)})` 
                        }</TableCell>
                        <TableCell>
                          <IconButton onClick={() => deselectEvent(x.id)}>
                            <Cancel />
                          </IconButton>  
                        </TableCell>
                      </TableRow>;
                    })
                  }
                </TableBody>
              </Table>

            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={performingAction}
          onClick={onDialogCancel} 
          color='primary'
        >
          Cancel
        </Button>

        <ProgressButton 
          onClick={downloadRosters} 
          color='primary' 
          showProgress={performingAction} 
          disabled={performingAction || selectedEventsList.length === 0}
          variant='outlined'
        >
          Download Email Rosters
        </ProgressButton>
      </DialogActions>
    </Dialog>
  </>);
};

export default CaptureEventsActions;