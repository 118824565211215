import { lightBlue, orange } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Palette from '../../../styles/palette';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px',
    marginBottom: '80px'
  },
  headerOptions: {
    width: '100%',
    marginBottom: '30px',
    paddingBottom: '20px',
    borderBottom: '1px solid gray'
  },
  topMargin: {
    margin: '10px'
  },
  paddedCell: {
    padding: '10px'
  },
  motionTableContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  table: {
    display: 'block',
    maxHeight: '40vh',
    overflowY: 'auto'
  },
  toggle: {
    paddingRight: '20px'
  },
  headerCell: {
    backgroundColor: Palette.appBackground,
    position: 'sticky',
    top: 0,
    zIndex: 10
  },
  paddedInput: {
    marginRight: '20px',
    width: '180px'
  },
  scatterPlot: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100%', 
    marginLeft: '20px'
  },
  beforeCheckbox: {
    color: lightBlue[400],
    '&$checked': {
      color: lightBlue[600]
    }
  },
  afterCheckbox: {
    color: orange[400],
    '&$checked': {
      color: orange[600]
    }
  },
  checked: {} // required to override checkbox coloring
}));

export default useStyles;
