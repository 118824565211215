import React, { useImperativeHandle, useCallback, useMemo, forwardRef, useRef } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './styles';

import classNames from 'classnames';
import CustomEditor from './../../../components/editor';

import TEXT_TYPES from '../../../constants/actionPlanTextTypes.constants';
import { PERMISSIONS } from '../../../constants/permissions.constants';
import { MARKDOWN_BLOCK_TYPES, MARKDOWN_INLINE_STYLES } from './markdownTypes.constants';

const ActionPlanTextEditor = forwardRef(({
  placeholder,
  textData,
  onChange,
  expanded,
  leftDrawerOpen,
  rightDrawerOpen,
  drawerWidth,
  editorHeight = 420,
  padding
}, ref) => {
  const classes = useStyles({ drawerWidth, padding });
  const editorRef = useRef(null);
  const featurePermissions = useSelector(state => state.featurePermissions);

  const { blockTypes, inlineStyles, textType } = useMemo(() => {
    const markdownEnabled = featurePermissions.some(p => p === PERMISSIONS.markdownEnabled);
    return {
      blockTypes: markdownEnabled ? MARKDOWN_BLOCK_TYPES : [],
      inlineStyles: markdownEnabled ? MARKDOWN_INLINE_STYLES : [],
      textType: markdownEnabled ? TEXT_TYPES.MARKDOWN : TEXT_TYPES.PLAIN_TEXT
    };
  }, [featurePermissions]);
  
  useImperativeHandle(ref, () => ({
    replaceText: text => editorRef.current && editorRef.current.replaceText(text)
  }), [editorRef]);

  const onChangeHandler = useCallback((markdown, plain, editorState) => {
    onChange(markdown, plain, textType, editorState);
  }, [onChange, textType]);

  const initialText = textData.textFormatType === TEXT_TYPES.PLAIN_TEXT 
    ? textData.plainText 
    : textData.formattedText;

  return <div 
    className={
      classNames(
        classes.textField,
        { [classes.shiftedMarkdownTextField] : leftDrawerOpen && expanded },
        { [classes.shiftedRightMarkdownTextField] : rightDrawerOpen && expanded }
      )
    }
  >
    <CustomEditor
      ref={editorRef}
      initialText={initialText}
      placeholder={placeholder}
      editorState={textData.data}
      editorHeight={editorHeight}
      blockTypes={blockTypes}
      inlineStyles={inlineStyles}
      onChange={onChangeHandler}
    />
  </div>;
});

export default ActionPlanTextEditor;