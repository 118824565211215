import palette from '../../../../styles/palette';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    marginLeft: '10px'
  },
  text: {
    color: palette.darkGray
  }
};

export default styles;