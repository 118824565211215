import { useState, useCallback } from 'react';
import { Typography, Grid, IconButton, Button } from '@material-ui/core';
import useNetworkRequest from 'network/useNetworkRequest';
import { 
  GetAccountAssociations, 
  GetAccountAssociationsAsPrimary,
  DeleteAccountAssociation
} from 'network/userRequests';
import useStyles from './styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import NewParentAccountCreation from './newParentAccountCreation';
import ConfirmationDialog from 'components/dialogs/confirmationDialog';
import Logger from 'js-logger';
import ErrorIcon from '@material-ui/icons/Error';
import MigrateMotionsToPlayerDialog from './MigrateMotionsToPlayerDialog';
import { useDispatch } from 'react-redux';
import { fetchCaptureEvent } from 'store/captureEvents';

function PlayerParents({ user, captureEventId }) {
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [hoveringId, setHoveringId] = useState(null);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [deletingErrorMessage, setDeletingErrorMessage] = useState(false);
  const [confirmMotionMigration, setConfirmMotionMigration] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const parentAssociationRequest = useCallback((cancelToken) => 
    GetAccountAssociations(user.userId, ['user'], cancelToken), [user]);

  const playerAssociationRequest = useCallback((cancelToken) => {
    return GetAccountAssociationsAsPrimary(user.userId, ['associatedUser'], cancelToken);
  }, [user]);

  const [
    parentAccountAssociations, 
    parentAccountsLoading, 
    parentAccountFetchError, 
    setParentAccountAssociations
  ] = useNetworkRequest(
    [], 
    'GetAccountAssociations', 
    parentAssociationRequest
  );

  const [
    playerAccountAssociations, 
    playerAccountsLoading, 
    playerAccountFetchError
  ] = useNetworkRequest(
    [], 
    'GetAccountAssociationsAsPrimary', 
    playerAssociationRequest
  );

  const handleNewAccount = account => {
    setParentAccountAssociations(prev => [...prev, { user: account }]);
    setShowAddAccount(false);
  };

  const showNoAssociations = !parentAccountsLoading 
    && parentAccountAssociations.length === 0
    && !showAddAccount
    && !parentAccountFetchError;

  const showPlayerAssociationWarning = !playerAccountsLoading
    && !playerAccountFetchError
    && playerAccountAssociations.length > 0;

  const getAccountDisplayName = (account) => {
    return (account?.user?.fullName.trim() + ' ' + account?.user?.emailAddress).trim();
  };

  const ParentAccount = ({ account }) => {
    const { user, id } = account;
    if (!user) return null;

    const showDeleteButton = hoveringId === id || accountToDelete?.id === id;
    return (
      <div 
        className={classes.accounts} 
        onMouseEnter={() => setHoveringId(id)} 
        onMouseLeave={() => setHoveringId(null)}
      >
        <Typography variant='h6'>{getAccountDisplayName(account)}</Typography>
        <IconButton 
          className={showDeleteButton ? null : classes.hidden}
          onClick={() => setAccountToDelete(account)} 
          color='secondary'
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  const deleteAccount = async (account) => {
    if (deleting) {
      return;
    }

    try {
      setDeleting(true);
      await DeleteAccountAssociation(account.id);
      setParentAccountAssociations(prev => prev.filter(x => x.id !== account.id));
      setAccountToDelete(null);
      setHoveringId(null);
    }
    catch (e) {
      setDeletingErrorMessage(
        'There was an error removing this parent account, please try again.');
      Logger.error(e, 'Error deleting associated account ' + account.id);
    }
    setDeleting(false);
  };

  const migrationComplete = () => {
    setConfirmMotionMigration(false);
    dispatch(fetchCaptureEvent(captureEventId));
  };

  return (
    <div className={classes.container}>
      <Grid container alignItems='center'>
        <Typography variant='h5'>Parent Accounts</Typography>
        <IconButton onClick={() => setShowAddAccount(true)} color='primary'>
          <AddIcon/>
        </IconButton>
      </Grid>
      {parentAccountFetchError && <Typography color='error'>Error fetching parent accounts</Typography>}
      {showNoAssociations && <Typography>No parent accounts currently setup</Typography>}
      {showAddAccount && 
        <NewParentAccountCreation 
          playerUserId={user.userId} 
          onNewAccountCreated={handleNewAccount}
        />
      }
      <div>
        {parentAccountAssociations.map(account => 
          <ParentAccount key={account.userId} account={account} />)}
      </div>
      {showPlayerAssociationWarning && <div className={classes.migrateButtonContainer}>
        <Button 
          variant='contained' 
          className={classes.migrateButton}
          onClick={() => setConfirmMotionMigration(true)}
        >
          <div className={classes.buttonContent}>
            <ErrorIcon className={classes.icon}/>
            <Typography>Child Account Found!</Typography>
          </div>
        </Button>
      </div>}

      {playerAccountFetchError && <Typography color='error'>
        Error checking player asssociations. Please contact dev. 
      </Typography>}

      <ConfirmationDialog 
        open={accountToDelete != null}
        loading={deleting}
        onConfirm={() => deleteAccount(accountToDelete)}
        onCancel={() => setAccountToDelete(null)}
        title='Remove Parent Account'
      >
        <Typography>
          Are you sure you want to remove <i>{getAccountDisplayName(accountToDelete)}</i> from the
          list of parent accounts? This will not delete their account, it will only remove the
          association.
        </Typography>
        <br />
        <Typography color='error'>{deletingErrorMessage}</Typography>
      </ConfirmationDialog>

      <MigrateMotionsToPlayerDialog 
        open={confirmMotionMigration}
        currentUser={user}
        captureEventId={captureEventId}
        playerAccounts={playerAccountAssociations}
        onCancel={() => setConfirmMotionMigration(false)}
        onComplete={migrationComplete}
      />
    </div>
  );
};

export default PlayerParents;