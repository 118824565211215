// Actions
const SET_MOTION_ATTRIBUTES = 'kdashboard/motionAttributes/set';

// Reducer
const initialState = [];
export default function reducer(state = initialState, action = {}) {
  switch(action.type) {
    case SET_MOTION_ATTRIBUTES:
      return action.payload;
    default:
      return state;
  }
}

// Action Creators
export function setMotionAttributes(motionAttributes) {
  return {
    type: SET_MOTION_ATTRIBUTES,
    payload: motionAttributes
  };
}