import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import TeamAssignment from './teamAssignment';
import ProgressButton from '../../../components/progressButton';

function InvitationDialog ({
  open,
  close,
  email,
  onEmailChange,
  invite,
  submitting,
  sent,
  teams
}) {
  const [selectedTeams, setSelectedTeams] = useState([]);
  useEffect(() => { open && setSelectedTeams([]); }, [open]);

  return <Dialog open={open} maxWidth='md' onClose={close}>
    <DialogTitle>Invite User to Join Your Organization</DialogTitle>
    <DialogContent>
      <Typography>
        This will allow the user with the given email address to access all players, 
        their motions, and any other associated data.
      </Typography>
      <TextField
        autoFocus
        value={email}
        onChange={onEmailChange}
        label='Email Address'
        fullWidth
        type='email'
      />
      <TeamAssignment 
        userName='the new user'
        teams={teams}
        onChange={setSelectedTeams}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={close} disabled={submitting || sent}>
        Cancel
      </Button>
      <ProgressButton
        onClick={() => invite(email, selectedTeams)}
        showProgress={submitting}
        disabled={sent}
        color='primary'
      >
        {sent ? 'Sent!' : 'Invite'}
      </ProgressButton>
    </DialogActions>
  </Dialog>;
};

InvitationDialog.propTypes = {
  open: PropTypes.bool,
  email: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  invite: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  sent: PropTypes.bool,
  close: PropTypes.func.isRequired
};

export default InvitationDialog;
