import React, { Component } from 'react';
import Player from '@vimeo/player';
import Analytics, { CATEGORIES, VIDEO_PLAYED, VIDEO_FINISHED } from '../../services/analytics';

export default class VimeoVideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.vimeoVideoHolder = React.createRef();
    const url = new URL(window.location.href);
    const userEmail = decodeURIComponent(url.searchParams.get('userEmail'));
    const userId = decodeURIComponent(url.searchParams.get('userId'));
    const vimeoUrl = decodeURIComponent(url.searchParams.get('vimeoUrl'));
    const videoTitle = decodeURIComponent(url.searchParams.get('videoTitle'));
    const deviceName = decodeURIComponent(url.searchParams.get('deviceName'));
    const autoplay = decodeURIComponent(url.searchParams.get('autoplay'));
    const loop = decodeURIComponent(url.searchParams.get('loop'));
    const type = decodeURIComponent(url.searchParams.get('type'));

    this.state = {
      vimeoUrl,
      userEmail,
      userId,
      videoTitle,
      deviceName,
      autoplay,
      loop,
      type
    };
  }

  componentDidMount() {
    const videoHolderNode = this.vimeoVideoHolder.current;
    const { 
      vimeoUrl, 
      userEmail, 
      userId, 
      videoTitle, 
      deviceName, 
      autoplay, 
      loop,
      type 
    } = this.state;
    
    const playerOptions = {
      id: vimeoUrl,
      width: window.innerWidth,
      height: window.innerHeight,
      autoplay: autoplay === 'true',
      loop: loop === 'true'
    };

    const trackingData = {
      videoUrl: vimeoUrl,
      userId,
      userEmail,
      videoTitle,
      type,
      'device_name': deviceName
    };

    const videoPlayer = new Player(videoHolderNode, playerOptions);
    videoPlayer.on('play', () => {
      Analytics.track({ 
        eventName:VIDEO_PLAYED, 
        userId, 
        miscData: trackingData,
        userEmail, 
        eventCategory: CATEGORIES.VIDEO,
        project: Analytics.PROJECTS.KGO
      });
    });

    // Hack to restart video:
    let finished = false;
    videoPlayer.on('timeupdate', (data) => {
      if (data.percent === 1 && !finished) {
        finished = true;
        videoPlayer.pause();
        videoPlayer.setCurrentTime(0);
        videoPlayer.pause();
        Analytics.track({
          eventName: VIDEO_FINISHED, 
          miscData: trackingData, 
          userEmail, 
          userId,
          eventCategory: CATEGORIES.VIDEO,
          project: Analytics.PROJECTS.KGO
        });
      }
    });
  }

  render() {
    return <div>
      <div ref={this.vimeoVideoHolder}></div>
    </div>;
  }
}