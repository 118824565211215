import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
  textField: {
    width: '100%'
  },
  marginTop: {
    marginTop: '10px'
  },
  videoPreview: {
    maxHeight: '230px',
    maxWidth: '100%'
  }
}));
