import React from 'react';
import { useSelector } from 'react-redux';

import OrganizationUsers from './organizationUsers';
import { PERMISSIONS } from '../../constants/permissions.constants';
import Teams from './teams';
import useStyles from './styles';
import ApiKeys from './apiKeys';

const OrganizationManagement = () => {
  const classes = useStyles();
  const featurePermissions = useSelector(state => state.featurePermissions);
  const showApiKeys = featurePermissions.includes(PERMISSIONS.apiAvailable);

  return (
    <div className={classes.pageContainer}>
      <OrganizationUsers />
      <Teams className={classes.teams}/>
      {showApiKeys && <ApiKeys className={classes.teams}/>}
    </div>
  );
};

export default OrganizationManagement;