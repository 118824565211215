import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { min } from 'mathjs';
import axios from 'axios';
import Logger from 'js-logger';
import { sortBy, filter, keys } from 'lodash';
import {
  Chip,
  DialogContent,
  DialogActions,
  Button, 
  CircularProgress,
  Typography,
  TextField,
  Grid,
  Switch
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import ProgressButton from '../../../../../components/progressButton';
import useEventMotionRequest from './../useEventMotionsRequest';
import EventUserMotionMetricsTable from './../eventUserMotionMetricsTable';
import MotionSelection from './motionSelection';

import { PutLaunchMonitor } from '../../../../../network/motionRequests';

import { GetAnalyzedSwingData } from '../../../../../network/motionRequests';
import KinematicSequenceGraphWithLabels from '../../../../../components/kinematicSequenceGraphWithLabels';
import KinematicDetailsDense from '../../../../../components/kinematicDetailsDense';

import motionTypesConstants from '../../../../../constants/motionTypes.constants';
import { DeleteMotion } from '../../../../../network/motionRequests';
import ConfirmationDialog from '../../../../../components/dialogs/confirmationDialog';
import ErrorSnackbar from '../../../../../components/errorSnackbar';
import { EventActions } from '../eventActions';
import { CaptureEventResultTypes } from 'constants/captureEventResult.constants';

const HIGH_EV_LIMIT = 98;
const EV_DIFF_FROM_MIN = 10;

function EventUserMotionsReviewDialog({
  eventUser,
  onResultsCreation,
  onNextUser
}) {
  const classes = useStyles();

  const { userId, captureEventId } = eventUser;
  const [initialMotions, motionsLoading, motionsError] 
    = useEventMotionRequest(captureEventId, userId);
  const [motions, setMotions] = useState([]);

  const [error, setError] = useState(null);

  const [framesLoading, setFramesLoading] = useState(false);
  const [frames, setFrames] = useState({});
  const [confirmDeleteMotion, setConfirmDeleteMotion] = useState(false);
  const [motionsDeleted, setMotionsDeleted] = useState([]);

  const [curMotionIdx, setCurMotionIdx] = useState(0);
  const [selectedMotionIds, setSelectedMotionIds] = useState({});
  const [seenMotionIds, setSeenMotionIds] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [creatingResults, setCreatingResults] = useState(false);
  const [resultsSaved, setResultsSaved] = useState(false);

  const [exitVeloText, setExitVeloText] = useState('');
  const [exitVeloTextError, setExitVeloTextError] = useState(null);
  const [updatingExitVelo, setUpdatingExitVelo] = useState(false);

  const [confirmDeleteExitVelo, setConfirmDeleteExitVelo] = useState(false);

  const [enableMotionSelection, setEnableMotionSelection] = useState(false);

  const curMotion = motions != null && motions.length > 0 && motions[curMotionIdx];

  useEffect(() => {
    setMotions(sortBy(initialMotions, x => x.timestamp).reverse());
  }, [initialMotions]);

  useEffect(() => {
    if (motionsError) setError('There was an error loading initial motions');
  }, [motionsError]);

  useEffect(() => {
    // reset state on user change
    setResultsSaved(false);
    setCurMotionIdx(0);
  }, [eventUser.userId]);

  useEffect(() => {
    !enableMotionSelection && setSelectedMotionIds({});
  }, [enableMotionSelection]);

  useEffect(() => {
    if (!curMotion) return;

    const curMotionId = curMotion.id;

    // no need to update the frames, already saved
    if (frames[curMotionId]) return;

    const cancelToken = axios.CancelToken.source();
    const getAnalyzedFrames = async () => {
      try {
        setFramesLoading(true);
        const newFrame = await GetAnalyzedSwingData(curMotionId, cancelToken);
        setFrames(prevFrames => ({ ...prevFrames, [curMotionId]: newFrame }));
      } catch (ex) {
        if (axios.isCancel(ex)) {
          return;
        }
        Logger.error('Error making request frames request in review dialog', ex);
        setError('There was an error getting the frames for this motion');
      }
      setFramesLoading(false);
    };

    getAnalyzedFrames();
    return cancelToken.cancel;
  }, [curMotion, frames]);

  const analyzedFrames = curMotion != null && !framesLoading && frames[curMotion.id]; 

  const loading = 
    motionsLoading || framesLoading || deleting || creatingResults || updatingExitVelo;

  const toggleMotion = (motion, checked) => {
    setSelectedMotionIds(prev => {
      const newSelectedMotionIds = { ...prev };
      if (checked) {
        newSelectedMotionIds[motion.id] = true;
      } else if (newSelectedMotionIds[motion.id]) {
        delete newSelectedMotionIds[motion.id];
      }
      return newSelectedMotionIds;
    });
  };

  const deleteMotion = async () => {
    setDeleting(true);
    try {
      await DeleteMotion(curMotion.id);
      toggleMotion(curMotion, false);
      const newMotions = motions.filter(m => m.id !== curMotion.id);
      if (!newMotions.length) {
        setCurMotionIdx(0);
      } else if (curMotionIdx >= newMotions.length) {
        setCurMotionIdx(newMotions.length - 1);
      }
      setMotions(newMotions);
      setConfirmDeleteMotion(false);
      setMotionsDeleted(prev => ([ ...prev, curMotion.id ]));
      setResultsSaved(false);
    } catch (ex) {
      Logger.error('Error deleting motion in review dialog', ex);
      setError('There was an error deleting this motion');
    }
    setDeleting(false);
  };

  const curMotionId = curMotion?.id;
  useEffect(() => {
    if (curMotionId != null) {
      setSeenMotionIds(prev => {
        return { ...prev, [curMotionId]: true };
      });
    }
  }, [curMotionId]);

  const updateIdx = (newIdx) => {
    if (!motions.length) return setCurMotionIdx(0);
    newIdx = Math.max(0, newIdx);
    newIdx = Math.min(newIdx, motions.length - 1);
    setCurMotionIdx(newIdx);
  };

  const exitVelo = curMotion && curMotion.launchMonitorData 
    ? curMotion.launchMonitorData.ballspeedMph : null;

  const createUpdatedResults = async () => {
    setCreatingResults(true);
    try {
      const Create3SResults = EventActions.createResults.postAction;
      const params = { resultType: CaptureEventResultTypes.manual };

      if (enableMotionSelection) {
        params.selectedMotionIds = keys(selectedMotionIds);
      }

      const newAction = await Create3SResults(captureEventId, [userId], params);
      setCreatingResults(false);
      setResultsSaved(true);
      onResultsCreation(newAction);
    } catch (ex) {
      Logger.error('Error creating new 3S results', ex);
      setError('There was an error creating new 3S results');
      setCreatingResults(false);
    }
  };

  const disableExitVelo = 
    !curMotion || motionsLoading || deleting || creatingResults || updatingExitVelo;

  const updateExitVelo = async mph => {
    setUpdatingExitVelo(true);
    try {
      const { data } = await PutLaunchMonitor(curMotion.id, { ballspeedMph: mph });
      setMotions(prev => prev.map(motion => {
        if (data.motionId !== motion.id) {
          return motion;
        }
        return { ...motion, launchMonitorData: data };
      }));
    } catch (ex) {
      Logger.error(`Error updating exit velo ${mph} for motion ${curMotion?.id}` 
        + ' in review dialog', ex);
      setError('There was an error updating launch monitor data');
    }
    setUpdatingExitVelo(false);
  };

  const onDeleteExitVelo = async () => {
    setConfirmDeleteExitVelo(false);
    await updateExitVelo(null);
  };

  const onSaveExitVelo = async (e) => {
    e.preventDefault();
    if (disableExitVelo || !exitVeloText) return;

    setExitVeloTextError(null);
    const mph = parseFloat(exitVeloText);
    if (isNaN(mph)) {
      return setExitVeloTextError('Not a valid number');
    }
    await updateExitVelo(mph);
  };

  useEffect(() => {
    // reset everytime the motion changes
    setExitVeloText('');
  }, [curMotion]);

  const displayExitVelo = num => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };
  
  const isPitchingMotion = (motion) => {
    return motion?.motionType === motionTypesConstants.baseballPitch;
  };

  const exitVeloWarnings = (ev, motions) => {
    const motionsWithEV = filter(motions, motion => 
      motion?.launchMonitorData?.ballspeedMph != null); 
    const evs = motionsWithEV.map(({ launchMonitorData }) => launchMonitorData.ballspeedMph);
    const warningMessages = [];
    const minEv = min(evs);
    
    if (ev >= HIGH_EV_LIMIT && ev - minEv >= EV_DIFF_FROM_MIN) { 
      warningMessages.push(`EV is over ${HIGH_EV_LIMIT} 
        mph & is ${EV_DIFF_FROM_MIN} mph from the min ev (${minEv} mph)`);
    }

    return warningMessages;
  };

  const exitVeloExists = exitVelo != null;

  return <>
    <>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.topBar}>
          <div className={classes.deleted}>
            { motionsDeleted.length } motion(s) deleted this session
          </div>

          <div className={classes.toggleContainer}>
            <Typography>Manual Selection Mode</Typography>
            <Switch 
              checked={enableMotionSelection}
              onChange={(_, checked) => setEnableMotionSelection(checked)}
            />
          </div>
        </div>
        {
          motionsLoading &&
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        }

        {
          !motionsLoading && motions && !motions.length &&
            <div>There are no motions for this player.</div>
        }

        {
          !motionsLoading && curMotion &&
          <div className={classes.content}>
            <MotionSelection 
              enableCheckboxMode={enableMotionSelection}
              motions={motions}
              highlightedMotion={curMotion}
              seenMotionIds={seenMotionIds}
              selectedMotionIds={selectedMotionIds}
              onMotionSeen={updateIdx}
              onMotionSelected={toggleMotion}
            />

            <div className={classes.dataContainer}>
              <EventUserMotionMetricsTable 
                loading={motionsLoading}
                motions={curMotion ? [curMotion] : []}
                isPitch={curMotion.motionType === motionTypesConstants.baseballPitch}
              />
              {
                framesLoading && 
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              } 

              {
                !framesLoading && curMotion && analyzedFrames &&
                <div className={classes.motionInfo}>
                  <div className={classes.exitVeloContainer}>
                    {
                      updatingExitVelo
                        ? <CircularProgress />
                        : <div className={classes.exitVeloContents}>
                          {
                            exitVeloExists
                              ? <div>
                                <Typography>
                                  Current {isPitchingMotion(curMotion)
                                    ? 'Pitching'
                                    : 'Exit'
                                  } Velo:
                                  <b className={classes.exitVeloText}>
                                    {displayExitVelo(exitVelo)} MPH
                                  </b>
                                </Typography> 
                                {
                                  !isPitchingMotion(curMotion) && 
                                    exitVeloWarnings(exitVelo, motions).map((msg) => <Chip
                                      label={msg}
                                      className={classes.attributeChip}/>)
                                }
                              </div>
                              : <Typography><i>No Exit Velo Exists</i></Typography>
                          }
                          <form className={classes.form} onSubmit={onSaveExitVelo}>
                            <TextField
                              margin='dense'
                              className={classes.input}
                              placeholder={exitVeloExists ? 'Update Velo' : 'Add Velo'}
                              disabled={disableExitVelo}
                              error={exitVeloTextError != null}
                              helperText={exitVeloTextError || ''}
                              value={exitVeloText}
                              onChange={e => setExitVeloText(e.target.value)}
                              variant='outlined'
                              type='number'
                              inputProps={{ step: 'any' }}
                              label='New Velocity (MPH)'
                            /> 
                            <Button 
                              disabled={disableExitVelo || !exitVeloText}
                              type='submit' 
                              color='primary' 
                              variant='contained'>{ 
                                exitVeloExists ? 'Update' : 'Add'
                              }</Button>
                          </form>

                          { exitVeloExists && <Button
                            className={classes.deleteVelo}
                            variant='contained'
                            onClick={() => setConfirmDeleteExitVelo(true)}
                          >
                            Delete Velo
                          </Button> }
                        </div>
                    }
                  </div>
                  {curMotion.badMotion &&
                    <Grid container alignItems='center'>
                      <WarningIcon className={classes.warningIcon}/>
                      <Typography color='error' className={classes.badMotionLabel} variant='h5'>
                        This appears to be a bad motion!
                      </Typography>
                    </Grid>
                  }
                  <div className={classes.visuals}>
                    <KinematicSequenceGraphWithLabels
                      motion={curMotion}  
                      showCrosshairs
                      analyzedFrames={analyzedFrames}
                      fullMotionMetrics={curMotion.fullMotionMetrics}                 
                    />
                    <KinematicDetailsDense motion={curMotion} userId={userId}/>
                  </div>
                </div>
              } 
            </div>
          </div>
        }
      </DialogContent>
      <DialogActions>
        <div className={classes.dialogActions}>
          <Button
            color='secondary'
            variant='contained'
            disabled={loading || (motions && !motions.length)}
            onClick={() => setConfirmDeleteMotion(true)} 
          >
            Delete Motion
          </Button>

          <div className={classes.actions}>
            {resultsSaved && <Typography className={classes.resultsMsg}>
              Results Saved!
            </Typography>}

            {!enableMotionSelection && <Button
              disabled={
                creatingResults ||
                motionsLoading ||
                updatingExitVelo ||
                !motions ||
                !motions.length ||
                curMotionIdx === 0
              }
              className={classes.previousButton}
              variant='outlined'
              onClick={() => updateIdx(curMotionIdx - 1)}
            >
              Previous
            </Button>}

            {!enableMotionSelection 
              && (curMotionIdx < motions.length - 1 || motions.length === 0)
              ? <Button
                disabled={
                  creatingResults ||
                  motionsLoading ||
                  updatingExitVelo ||
                  !motions || 
                  !motions.length
                }
                color='primary'
                variant='outlined'
                onClick={() => updateIdx(curMotionIdx + 1)}
              >
                Next
              </Button>
              : resultsSaved
                ? <Button
                  disabled={onNextUser == null}
                  color='primary'
                  variant='contained'
                  onClick={onNextUser}
                >
                  Next Player
                </Button>
                : <ProgressButton
                  showProgress={creatingResults}
                  color='primary'
                  variant='contained'
                  onClick={createUpdatedResults}
                  disabled={enableMotionSelection && keys(selectedMotionIds).length === 0}
                >
                  Generate Results
                </ProgressButton>}
          </div>
        </div>
      </DialogActions>
    </>

    <ConfirmationDialog 
      title='Delete Motion?'
      open={confirmDeleteMotion}
      disableCancelOnLoad
      loading={deleting}
      onConfirm={deleteMotion}
      onCancel={() => setConfirmDeleteMotion(false)} >
      Are you sure you want to delete this motion?
    </ConfirmationDialog>

    <ConfirmationDialog 
      title='Delete Exit Velocity for this Motion?'
      open={confirmDeleteExitVelo}
      onConfirm={onDeleteExitVelo}
      onCancel={() => setConfirmDeleteExitVelo(false)} >
      Are you sure you want to delete this exit velocity for this motion?
    </ConfirmationDialog>

    <ErrorSnackbar 
      message={error}
      onClose={() => setError(null)}
    />
  </>;
};

export default EventUserMotionsReviewDialog;