import React, { useState } from 'react';
import useStyles from './styles';
import { useSelector } from 'react-redux';

import CoachView from './views/coachView';
import AnalystFlow from './views/analystFlow';

const VIEWS = {
  COACH: 'coach',
  ANALYST: 'analyst'
};

const TOP_PADDING = '85px';

function KAssessments(props) {

  const userId = props.match.params.userId;
  const orgId = useSelector(state => state.currentOrganization.id);

  // see if there are any drafts from the current session
  const drafts = useSelector(state => state.assessments);
  const draft = drafts[userId] || null;

  // view management
  const [view, setView] = useState(draft ? VIEWS.ANALYST : VIEWS.COACH);

  const startAssessment = () => setView(VIEWS.ANALYST);
  const backToCoach = () => setView(VIEWS.COACH);
  const published = () => setView(VIEWS.COACH);

  const classes = useStyles();

  return <div className={classes.container}>
    {
      view === VIEWS.COACH &&
      <CoachView 
        orgId={orgId}
        userId={userId}
        topPadding={TOP_PADDING}
        startAssessment={startAssessment}
      />
    }

    {
      view === VIEWS.ANALYST &&
      <AnalystFlow
        topPadding={TOP_PADDING}
        onPublish={published}
        orgId={orgId}
        userId={userId}
        onBack={backToCoach}
      />
    }

  </div>;
}
  
export default KAssessments;