import querystring from 'query-string';
import logger from 'js-logger';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import { AUTH_CONFIG } from '../constants/auth.constants';

import { ROLE_KEY, USER_ROLE, ORG_ROLE, ORG_ADMIN_ROLE } from '../constants/auth.constants';
import { GetUser } from '../network/userRequests';
import { GetOrganization } from '../network/organizationRequests';
import { setFeaturePermissions } from '../store/featurePermissions';
import store from '../store';
import { setLoggingContext } from './logging.utils';
import Analytics from '../services/analytics';

export function GetToken() {
  return localStorage.getItem('access_token');
}

export function GetSportTypes() {
  return localStorage.getItem('sportTypes') || '';
}

export function IsLoggedIn() {
  var sportTypes = GetSportTypes(); 
  var token = GetToken();
  if (token == null || sportTypes == null) {
    return false;
  }
  var decoded = jwtDecode(token);
  return decoded.exp > Math.floor(new Date().getTime() / 1000);
}

export function Logout(redirectUrl = '/login') {
  localStorage.clear();
  Analytics.reset();
  var location = window.location;
  var returnUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + redirectUrl;
  var queryParams = querystring.stringify({
    client_id: AUTH_CONFIG.clientId,
    returnTo: returnUrl
  });
  window.location = (AUTH_CONFIG.logoutUrl + '?' + queryParams);
}

export function setSession(authResult) {
  let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
  localStorage.setItem('access_token', authResult.accessToken);
  localStorage.setItem('id_token', authResult.idToken);
  localStorage.setItem('expires_at', expiresAt);
}

export function logNewUserIn(user) {
  const { userId, authentication } = user;
  localStorage.setItem('userId', userId);
  setLoggingDetails(userId);
  setSession(authentication);
}

export function getRolesForLoggedInUser() {
  var token = GetToken();
  if (token == null) {
    return [];
  }
  var decoded = jwtDecode(token);
  return decoded[ROLE_KEY];
}

export async function setUserInfo(userId) {
  try {
    localStorage.setItem('userId', userId);
    if(hasOrgRole()) {
      const org = await GetOrganization(userId);
      localStorage.setItem('sportTypes', org.sportTypes);
      localStorage.setItem('organizationId', org.id);
      setLoggingDetails(userId, org);
      const features = org.featurePermissions.map(f => f.feature);
      store.dispatch(setFeaturePermissions(features));
    } else if(hasUserRole()) {
      // make request for user info
      var user = await GetUser(userId);
      setLoggingDetails(userId);
      localStorage.setItem('sportTypes', user.sportTypes);
      store.dispatch(setFeaturePermissions([]));
    }
  } catch (networkError) {
    logger.error(networkError);
  }
}

export function getUserId() {
  var token = GetToken();
  if (token == null) {
    return [];
  }
  var decoded = jwtDecode(token);      
  return decoded.sub.split('|')[1];
}

export function getOrganizationId() {
  return localStorage.getItem('organizationId');
}

export function getFeaturePermissions() {
  const { featurePermissions } = store.getState();
  return featurePermissions;
}

export async function setFeaturePermissionsAsync() {
  let features;
  const state = store.getState();
  if (state.featurePermissions === null) {
    if (IsLoggedIn() && hasOrgRole()) {
      try {
        const userId = getUserId();
        const org = await GetOrganization(userId);
        setLoggingDetails(userId, org);
        features = org.featurePermissions.map(f => f.feature);
      } catch(e) {
        features = [];
      }
      store.dispatch(setFeaturePermissions(features));
    } else {
      setLoggingDetails(getUserId());
      features = [];
      store.dispatch(setFeaturePermissions(features));
    }
  } else {
    features = store.featurePermissions;
  }
  return features;
}

export function hasOrgRole() {
  var roles = getRolesForLoggedInUser();
  return roles.includes(ORG_ROLE);
}

export function hasUserRole() {
  var roles = getRolesForLoggedInUser();
  return roles.includes(USER_ROLE);
}

export function hasOrgAdminRole() {
  var roles = getRolesForLoggedInUser();
  return roles.includes(ORG_ADMIN_ROLE);
}

export function filterRedirectSearchParameters(search) {
  // filter out password token
  const { passwordToken, ...parsedSearch } = queryString.parse(search);
  const parsedQueryString = queryString.stringify(parsedSearch);
  if (parsedQueryString.length > 0) {
    return '?' + parsedQueryString;
  }
  return parsedQueryString;
}

const setLoggingDetails = (userId, org = null) => {
  setLoggingContext('userId', userId);
  if (org != null) {
    setLoggingContext('organizationName', org.name);
    setLoggingContext('organizationId', org.id);
  }
};