import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '20px'
  },
  content: {
    marginTop: '20px'
  },
  scheduled: {
    marginBottom: '50px'
  }
}));

export default useStyles;
