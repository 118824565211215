import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logger from 'js-logger';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress
} from '@material-ui/core';

import { history } from '../../../store';
import { GetAllKeys, RevokeKey, GenerateKey } from '../../../network/apiKeys';
import { formatMMDDYY } from '../../../utils/formatting.utils';
import ErrorSnackbar from '../../../components/errorSnackbar';
import useStyles from './styles';

const ApiKeys = ({ className }) => {
  const [keys, setKeys] = useState([]);
  const [errMsg, setErrMsg] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [isGeneratingKey, setIsGeneratingKey] = useState(false);
  const [newKey, setNewKey] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function GetKeys() {
      try {
        const apiKeys = await GetAllKeys();
        setKeys(apiKeys);
      } catch (e) {
        logger.error('Error when attempting to get api keys', e);
        setErrMsg('Could not get api keys. Refresh to try again.');
      }
    }
    GetKeys();
    return cancelToken.cancel;
  }, []);

  async function deleteToken(display, id) {
    if (window.confirm(`This will permanently delete the token ending with ${display}. 
      Do you want to continue?`)) {
      try {
        await RevokeKey(id);
        setKeys(prev => prev.filter(key => key.id !== id));
      } catch(e) {
        logger.error(`There was an error when attempting to revoke api key ${id}`, e);
        setErrMsg('There was an issue deleting the api key. Please try again.');
      }
    }
  }

  async function generateToken() {
    setIsGeneratingKey(true);
    setShowDialog(true);
    try {
      let key = await GenerateKey();
      setNewKey(key.displayToken);
      key.displayToken = key.displayToken
        .substring(key.displayToken.length - 4)
        .padStart(15, 'X');
      setKeys(prev => [...prev, key]);
    } catch (e) {
      logger.error('Error when attempting to generate api key', e);
      setErrMsg('There was an issue generate a new key. Please try again');
    }
    setIsGeneratingKey(false);
  }

  function closeDialog() {
    setNewKey(null);
    setShowDialog(false);
  }

  return (
    <div className={className}>
      <ErrorSnackbar message={errMsg} onClose={() => setErrMsg(null)}/>
      <Grid container justify='space-between' className={classes.title}>
        <Typography variant='h5' className={classes.title}>Api Keys</Typography>
        <Button color='primary' onClick={() => history.push('/api-docs')}>Api Documentation</Button>
        <Button variant='contained' color='primary' onClick={generateToken}>Generate Key</Button>
      </Grid>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Token</TableCell>
              <TableCell>Created</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys.map(key => (
              <TableRow key={key.id}>
                <TableCell component='th' scope='row'>
                  {key.displayToken}
                </TableCell>
                <TableCell alight='right'>
                  {formatMMDDYY(key.creationTimestamp)}
                </TableCell>
                <TableCell>
                  <Button onClick={() => deleteToken(key.displayToken, key.id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog open={showDialog} onClose={closeDialog}>
        <DialogTitle>New key generated.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This code will only appear once and cannot be seen again. 
            Copy down the contents now before closing the browser. 
            You can always delete keys and regenerate new ones if you need to.
            
            <div className={classes.key}>
              {isGeneratingKey 
                ? <CircularProgress />
                : <Typography>Key: {newKey}</Typography>
              }
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ApiKeys;