import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_theme => ({
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: 0,
    backgroundColor: 'black'
  },
  videoIframe: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0, 
    top: 0,
    border: 'none'
  }
}));

export default useStyles;
