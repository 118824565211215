import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

function LargeNumberStat({ title, value }) {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Typography variant='h4' >{value}</Typography>
      <Typography variant='subtitle2'>{title}</Typography>
    </Grid>
  );
}

LargeNumberStat.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default LargeNumberStat;