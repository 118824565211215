const styles = {
  checkboxCell: {
    width: '100px',
    marginRight: '20px'
  },
  textCell: {
    width: '30%',
    display: 'flex', 
    alignItems: 'center'
  }
};

export default styles;
