import { useEffect } from 'react';

function useScript(url = null, type = 'text/javascript', async = true, innerHtml = null) {
  useEffect(() => {
    const script = document.createElement('script');

    script.async = async;
    script.type = type;
    if (url != null) {
      script.src = url;
    }
    if (innerHtml != null) {
      script.innerHTML = innerHtml;
    }

    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, [url, type, async, innerHtml]);
}

export default useScript;
