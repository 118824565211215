import React, { useState, useEffect } from 'react';
import { 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox
} from '@material-ui/core';
import { ANALYSIS_METRICS } from '../dataAnalysis.constants';

function SelectMetricsButton({ onChange, selectedMetrics }) {
  const [open, setOpen] = useState(false);
  const [checkedMetrics, setCheckedMetrics] = useState(selectedMetrics);
  const allMetricsChecked = ANALYSIS_METRICS.length === checkedMetrics.length;

  useEffect(() => {
    if (!open) {
      setCheckedMetrics(selectedMetrics);
    }
  }, [open, selectedMetrics]);

  const toggleMetric = (metric) => {
    const idx = checkedMetrics.indexOf(metric);
    if (idx === -1) {
      setCheckedMetrics(prev => [...prev, metric]);
    } else {
      setCheckedMetrics(prev => prev.filter(x => x !== metric));
    }
  };

  const toggleAllMetrics = () => {
    if (allMetricsChecked) {
      setCheckedMetrics([]);
    } else {
      setCheckedMetrics([...ANALYSIS_METRICS]);
    }
  };

  const onSave = () => {
    onChange(checkedMetrics);
    setOpen(false);
  };

  return <React.Fragment>
    <Button color='primary' variant='contained' onClick={() => setOpen(true)}>
      Select Metrics
    </Button>

    <Dialog open={open}>
      <DialogTitle>Select Metrics</DialogTitle>
      <DialogContent>
        <List dense>
          <ListItem
            role='listitem'
            button
            onClick={() => toggleAllMetrics()}
          >
            <ListItemIcon>
              <Checkbox
                checked={allMetricsChecked}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary='Check All Metrics' />
          </ListItem>
          {ANALYSIS_METRICS.map(metric => (
            <ListItem
              key={metric.label}
              role='listitem'
              button
              onClick={() => toggleMetric(metric)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checkedMetrics.indexOf(metric) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={metric.label} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button onClick={onSave}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>;
}

export default SelectMetricsButton;
