import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import sortBy from 'lodash/sortBy';
import ProgressGraph from './progressGraph';
import useStyles from './styles';
import MOTION_TYPES from '../../../../constants/motionTypes.constants';

function ExpandableProgressGraphs({ 
  userId, 
  keys, 
  fetchAverages, 
  fetchDataForDateRange 
}) {
  const [averages, setAverages] = useState([]);
  const classes = useStyles();
  let cache = {};

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    (async function fetchData() {
      try {
        const data = await fetchAverages({ userId, timeUnit: 'day', motionType: MOTION_TYPES.baseball }, cancelToken);
        const items = data != null ? data : [];
        setAverages(sortBy(items, 'startTimestamp'));
      } catch (e) {
        if (axios.isCancel(e)) return;
      }
    })();
    return cancelToken.cancel;
  }, [userId, fetchAverages]);

  async function getDataForDateRange(laterThan, earlierThan, itemTimestamp) {
    // check if exists in cache, if so return
    if (cache[itemTimestamp] != null) {
      return cache[itemTimestamp];
    }
    
    const cancelToken = axios.CancelToken.source();
    try {
      const data = await fetchDataForDateRange(userId, laterThan, earlierThan, cancelToken);
      // add to cache
      cache[itemTimestamp] = data;
      return data;
    } catch (e) {
      if (axios.isCancel(e)) return;
    }
  }

  return <Grid container spacing={2} className={classes.grid}>
    {keys.map(({ key, title, yAxisDomain, converterFunction }) => {
      const graphData = averages.map(({ startTimestamp, endTimestamp, data }) => ({ 
        startTimestamp,
        endTimestamp,
        timestamp: new Date(startTimestamp).getTime(),
        [key]: converterFunction ? converterFunction(data[key]) : data[key]
      }));
      return <Grid item key={key} xs={12} md={6} xl={4}>
        <Paper className={classes.paperItemContainer}>
          <Typography className={classes.graphTitle}>{title}</Typography>
          <ProgressGraph
            xAxisKey='timestamp'
            yAxisKey={key}
            yAxisDomain={yAxisDomain}
            data={graphData}
            converter={converterFunction}
            getDataForDateRange={getDataForDateRange}
          />
        </Paper>
      </Grid>;
    })}
  </Grid>;
}

ExpandableProgressGraphs.propTypes = {
  userId: PropTypes.string.isRequired,
  keys: PropTypes.array.isRequired,
  fetchAverages: PropTypes.func.isRequired,
  fetchDataForDateRange: PropTypes.func.isRequired
};

export default ExpandableProgressGraphs;
