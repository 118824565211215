import { PurchaseStatuses } from '../constants/purchases.constants';

export const getActiveSubscriptionAndPath = (subscriptions) => {
  if(subscriptions != null) {
    for(let ii = 0; ii < subscriptions.length; ii++) {
      const currentSubscription = subscriptions[ii];
      if(currentSubscription != null && currentSubscription.purchaseItems != null) {
        for(let xx = 0; xx < currentSubscription.purchaseItems.length; xx++){
          const subscriptionDetails = currentSubscription.purchaseItems[xx];
          if(subscriptionDetails.active) {
            return {
              outerSubscriptionId: currentSubscription.id,
              indexOfPurchaseItem: xx,
              subscriptionDetails,
              entireSubscriptionHistory: currentSubscription
            };
          }
        }
      }
    };
  }
  
  return {
    indexOfPurchaseItem: null,
    outerSubscriptionId: null,
    subscriptionDetails: null,
    entireSubscriptionHistory: null
  };
};

export const getUpcomingSubscription = (subscriptions) => {
  if(subscriptions != null) {
    for(let ii = 0; ii < subscriptions.length; ii++) {
      const currentSubscription = subscriptions[ii];
      if(currentSubscription != null && currentSubscription.purchaseItems != null) {
        for(let xx = 0; xx < currentSubscription.purchaseItems.length; xx++){
          const subscriptionDetails = currentSubscription.purchaseItems[xx];
          if(subscriptionDetails.status === PurchaseStatuses.awaitingUpdate) {
            return {
              outerSubscription: currentSubscription,
              indexOfPurchaseItem: xx,
              subscriptionDetails,
              entireSubscriptionHistory: currentSubscription
            };
          }
        }
      }
    };
  }

  return {
    outerSubscription: null,
    indexOfPurchaseItem: null,
    subscriptionDetails: null,
    entireSubscriptionHistory: null
  };
};

  
export const getSubscriptions = (purchases) => {
  if(purchases == null) {
    return [];
  }
  return purchases.filter(({ purchaseType }) => purchaseType === 'Subscription');
};
