import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../../../styles/palette';

export default makeStyles(() => ({
  dialog: {
    minWidth: '100vw',
    minHeight: '100vh',
    borderRadius: 0
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > span': {
      fontSize: '20px'
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  topLeftInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '18px'
  },
  exitButton: {
    marginRight: '15px'
  },
  dialogContent: {
    display: 'flex'
  },
  divider: {
    marginRight: '10px',
    marginLeft: '10px'
  },
  eventName: {
    fontWeight: 'bold'
  },
  playerName: {
    fontStyle: 'italic'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flexGrow: 1
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minWidth: '100px',
    maxWidth: '200px',
    width: '25%'
  },
  playersSidebar: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    overflow: 'auto'
  },
  player: {
    '&:hover': {
      backgroundColor: palette.extraLightGray
    },
    cursor: 'pointer',
    padding: '5px',
    marginRight: '15px'
  },
  selected: {
    backgroundColor: palette.midnightBlue,
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.midnightBlue
    }
  },
  videoContainer: {
    height: '100%'
  },
  dataContainer: {
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden'
  },
  dataScroll: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    paddingRight: '20px',
    paddingLeft: '10px'
  }
}));