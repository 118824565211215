import makeStyles from '@material-ui/core/styles/makeStyles';
import Palette from 'styles/palette';

const useStyles = makeStyles(() => ({
  playerRow: {
    backgroundColor: Palette.lightBlue
  },
  container: {
    paddingTop: '50px'
  },
  clickableHeader: {
    cursor: 'pointer'
  },
  selectedHeader: {
    textDecoration: 'underline'
  }
}));

export default useStyles;
