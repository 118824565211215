import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';

// Actions
const SET_USER_MOTIONS = 'kdashboard/dataAnalysis/userWithMotions/set';
const REMOVE_MOTIONS = 'kdashboard/dataAnalysis/motions/remove';
const SET_HOVERED_MOTION_ID = 'kdashboard/dataAnalysis/hoveredMotionId/set';
const SET_TAG = 'kdashboard/dataAnalysis/tags/set';
const UPDATE_TAG = 'kdashboard/dataAnalaysis/tags/update';
const REMOVE_TAGS = 'kdashboard/dataAnalysis/tags/remove';
const CLEAR = 'kdashboard/dataAnalysis/clear';
const SET = 'kdashboard/dataAnalysis/set';

// Reducer
const initialState = {
  motions: {},  // dictionary of motionId -> motion
  users: {},    // dictionary of userId -> user
  hoveredMotionId: null,
  tags: {}      // dictionary of tag name -> { color: 'string', selected: bool, motionIds: ['string'] }
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER_MOTIONS:
      // remove + override all motions for given user
      const existingMotions = pickBy(state.motions, m => m.userId !== action.payload.user.userId);
      return { 
        ...state, 
        users: {
          ...state.users,
          [action.payload.user.userId]: action.payload.user
        },
        motions: {
          ...existingMotions,
          ...action.payload.motions
        }
      };
    case REMOVE_MOTIONS:
      const removedMotions = { ...state.motions };
      action.payload.forEach(motionId => delete removedMotions[motionId]);
      return { ...state, motions: removedMotions };
    case SET_HOVERED_MOTION_ID: 
      return { 
        ...state, 
        hoveredMotionId: action.payload 
      };
    case SET_TAG:
      return {
        ...state,
        tags: { 
          ...state.tags, 
          [action.payload.tagName]: action.payload.tag 
        }
      };
    case UPDATE_TAG:
      const updatedTags = Object.keys(state.tags).map(tagName => 
        tagName === action.payload.oldTagName
          ? action.payload.tag
          : { ...state.tags[tagName], tagName }
      );
      return {
        ...state,
        tags: keyBy(updatedTags, tag => tag.tagName)
      };
    case REMOVE_TAGS:
      const removedTags = { ...state.tags };
      action.payload.forEach(tagName => delete removedTags[tagName]);
      return { ...state, tags: removedTags };
    case SET:
      return action.payload ?? initialState;
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function setUserMotions(user, motions) {
  return {
    type: SET_USER_MOTIONS,
    payload: { user, motions: keyBy(motions, 'id') }
  };
}

export function removeTag(tagName) {
  return {
    type: REMOVE_TAGS,
    payload: [tagName]
  };
}

export function setTag(tagName, tag) {
  return {
    type: SET_TAG,
    payload: {
      tagName,
      tag: { ...tag, tagName }
    }
  };
}

export function updateTag(oldTagName, newTagName, tag) {
  return {
    type: UPDATE_TAG,
    payload: {
      oldTagName,
      newTagName,
      tag: { ...tag, tagName: newTagName }
    }
  };
}

export function clearDataAnalysis() {
  return {
    type: CLEAR
  };
}

export function setDataAnalysis(data) {
  return {
    type: SET,
    payload: data
  };
}
