import React from 'react';
import { useSelector } from 'react-redux';
import { Paper, Typography } from '@material-ui/core';
import UserRow from './userRow';
import useStyles from './styles';

const AssociateExternalUserIds = () => {
  const classes = useStyles();
  const players = useSelector(state => state.players);

  return (
    <div className={classes.pageContainer}>
      <Typography variant='h5' className={classes.title}>Update Players</Typography>
      
      <Paper className={classes.orgUserPaper}>
        {players.map((player, index) => {
          return <UserRow key={`player${index}`} {...player}/>;
        })}
      </Paper>
    </div>
  );
};


export default AssociateExternalUserIds;
