import { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from './styles';
import { getUserId, Logout } from 'utils/auth.utils';
import Analytics, { CATEGORIES, LOGGED_OUT } from 'services/analytics';

function TrainingPlanMenu({ user, pageName, onSeeOtherPlans }) {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = e => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const onLogoutClick = () => {
    Analytics.track({
      pageName,
      eventName: LOGGED_OUT,
      eventCategory: CATEGORIES.AUTH,
      userId: user?.userId ?? getUserId(),
      userEmail: user?.emailAddress
    });
    Logout();
  };

  return <>
    <IconButton onClick={openMenu}>
      <MenuIcon />
    </IconButton>

    <Menu
      anchorEl={anchorEl}
      name='training-plan-settings-menu'
      keepMounted
      open={anchorEl != null}
      onClose={closeMenu}
      classes={{ paper: classes.menu }}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {onSeeOtherPlans && <MenuItem onClick={onSeeOtherPlans}>View Other Plans</MenuItem>}
      <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
    </Menu>
  </>;
};

export default TrainingPlanMenu;