import {
  Grid,
  Typography
} from '@material-ui/core';
import { formatCentsToDollars } from '../../../utils/money.utils';
import useStyles from './styles';

function PriceComponent({ 
  promotionalPrice, 
  billingPeriod, 
  standardPrice, 
  upgradePrice 
}){
  const classes = useStyles();

  if (upgradePrice != null) {
    return <Grid container className={classes.cardPricing}>
      <Grid item xs={12}>
        <Typography 
          component='h2' 
          variant='h3' 
          color='textPrimary'
        >
          +${formatCentsToDollars(100)}
        </Typography>
      </Grid >
      <Grid item xs={12}>
        <Typography variant='h6'>
          {billingPeriod}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Full price: ${formatCentsToDollars(standardPrice)}
        </Typography>
      </Grid>
    </Grid>;
  } else if(promotionalPrice != null) {
    const amountOff = formatCentsToDollars(standardPrice - promotionalPrice);
    const percentageOff = ((standardPrice - promotionalPrice) / standardPrice) * 100;

    return <Grid container className={classes.cardPricing}>
      <Grid item xs={12} className={classes.promoPricing}>
        <Typography className={classes.promoPricePrefix}>
          Was 
        </Typography>
        <span className={classes.strikeThrough}>
          <Typography variant='h4' color='textPrimary'>
              ${formatCentsToDollars(standardPrice)}
          </Typography>
        </span>
      </Grid >

      <Grid item xs={12} className={classes.promoPricing}>
        <Typography className={classes.promoPricePrefix}>
          Now 
        </Typography>
        <Typography variant='h3' className={classes.savings}>
          ${formatCentsToDollars(promotionalPrice)}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6'>
          {billingPeriod}
        </Typography>
      </Grid>
      <br />
      <Typography variant='body2'>For your first program. No monthly commitment.</Typography>
      <Typography color='error' className={classes.savings}>
        Save ${amountOff} - {percentageOff}% off
      </Typography>
      <Typography className={classes.savings}>
        Special price for a limited time only!
      </Typography>
      <br />
    </Grid>;
  } else {
    return <Grid container className={classes.cardPricing}>
      <Grid item xs={12}>
        <Typography component='h2' variant='h3' color='textPrimary'>
          ${formatCentsToDollars(standardPrice)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          {billingPeriod}
        </Typography>
      </Grid>
    </Grid>;
  }
};

export default PriceComponent;