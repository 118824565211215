import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Divider
} from '@material-ui/core';
import UserSelection from './userSelection';
import MotionSelection from './motionSelection';
import { setUserMotions } from '../../../store/dataAnalysis';
import LessonSelection from './lessonSelection';
import TagGroupSelection from './tagGroupSelection';
import useInput from '../../../utils/useInput';

function MotionSelectionDialog({ open, onClose, ...props }) {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);

  const firstNameInput = useInput({ trimValue: true });
  const lastNameInput = useInput({ trimValue: true });
  const emailInput = useInput({ trimValue: true });
  const [userSearchFilters, setUserSearchFilters] = useState({ 
    page: 1, 
    searchFilters: {}
  });

  useEffect(() => {
    open && setUser(null);
  }, [open]);

  const dialogTitle = user 
    ? user.firstName + ' ' + user.lastName
    : 'Select User';

  const onMotionsSelected = motions => {
    dispatch(setUserMotions(user, motions));
    onClose();
  };

  return <Dialog open={open} onClose={onClose} {...props} scroll='paper'>
    <DialogTitle>{dialogTitle}</DialogTitle>
    <DialogContent>
      {user 
        ? <MotionSelection 
          userId={user.userId} 
          onComplete={onMotionsSelected} 
          onCancel={() => setUser(null)}
        /> 
        : <Grid container>
          <Grid item xs={5}>
            <UserSelection 
              setUser={setUser} 
              setSearchFilters={setUserSearchFilters}
              searchFilters={userSearchFilters}
              firstInput={firstNameInput}
              lastInput={lastNameInput}
              emailInput={emailInput}
            />
          </Grid>
          <Grid item xs={1} container justify='center'>
            <Divider orientation='vertical'/>
          </Grid>
          <Grid item xs={3}>
            <LessonSelection onClose={onClose}/>
          </Grid>
          <Grid item xs={1} container justify='center'>
            <Divider orientation='vertical'/>
          </Grid>
          <Grid item xs={2}>
            <TagGroupSelection onClose={onClose}/>
          </Grid>
        </Grid>
      }
    </DialogContent>
  </Dialog>;
}

export default MotionSelectionDialog;
