import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  container: {
    padding: '20px',
    backgroundColor: 'white',
    display: 'inline-block',
    fontStyle: 'italic'
  }
}));

function NoDataMessage({ className, children }) {
  const classes = useStyles();

  return <div className={classNames(classes.container, className)}>
    <Typography>{children}</Typography>
  </div>;
};

NoDataMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default NoDataMessage;
