import { SEGMENTS } from '../playerDataTabs/playerPercentiles/playerPercentiles.constants';

export const MAX_SPEEDS_PERCENTILES_KEY = 'maxSpeedsPercentiles';
export const PEAK_SPEEDS_PERCENTILES_KEY = 'peakSpeedPercentiles';
export const KEY_FRAME_POSITIONS = 'keyFramePositions';
export const PLAYER_WEIGHT = 'playerWeight';

const SECTIONS = {
  peakSpeeds: 'Peak Speeds',
  maxSpeeds: 'Max Speeds',
  keyFrameData: 'Key Frame Data',
  peakSpeedPercentiles: 'Peak Speed Percentiles',
  maxSpeedPercentiles: 'Max Speed Percentiles'
};

export const ANALYSIS_METRICS = [
  {
    label: 'Exit Velocity',
    getValue: motion => motion.launchMonitorData?.ballspeedMph
  },
  {
    label: 'Player Weight',
    getValue: motion => motion[PLAYER_WEIGHT]
  },
  {
    label: 'Max X-Factor',
    getValue: motion => motion.fullMotionMetrics?.maxXFactor
  },

  // Peak Speeds
  {
    label: 'Peak Speed Pelvis',
    section: SECTIONS.peakSpeeds,
    getValue: motion => motion.fullMotionMetrics?.peakSpeedPelvis
  },
  {
    label: 'Peak Speed Torso',
    section: SECTIONS.peakSpeeds,
    getValue: motion => motion.fullMotionMetrics?.peakSpeedTorso
  },
  {
    label: 'Peak Speed Upper Arm',
    section: SECTIONS.peakSpeeds,
    getValue: motion => motion.fullMotionMetrics?.peakSpeedUpperArm
  },
  {
    label: 'Peak Speed Lower Arm',
    section: SECTIONS.peakSpeeds,
    getValue: motion => motion.fullMotionMetrics?.peakSpeedLowerArm
  },
  {
    label: 'Peak Speed Hand',
    section: SECTIONS.peakSpeeds,
    getValue: motion => motion.fullMotionMetrics?.peakSpeedHand
  },
  
  {
    label: 'Peak Speed Body',
    section: SECTIONS.peakSpeeds,
    getValue: motion => {
      if(motion.fullMotionMetrics == null) return 0;
      const fullMotionMetrics = motion.fullMotionMetrics;
      return fullMotionMetrics.peakSpeedPelvis ?? 0 +
        fullMotionMetrics.peakSpeedTorso ?? 0 +
        fullMotionMetrics.peakSpeedUpperArm ?? 0 + 
        fullMotionMetrics.peakSpeedLowerArm ?? 0 + 
        fullMotionMetrics.peakSpeedHand ?? 0;
    }
  },

  // Max Speeds
  {
    label: 'Max Speed Pelvis',
    section: SECTIONS.maxSpeeds,
    getValue: motion => motion.fullMotionMetrics?.maxSpeedPelvis
  },
  {
    label: 'Max Speed Torso',
    section: SECTIONS.maxSpeeds,
    getValue: motion => motion.fullMotionMetrics?.maxSpeedTorso
  },
  {
    label: 'Max Speed Upper Arm',
    section: SECTIONS.maxSpeeds,
    getValue: motion => motion.fullMotionMetrics?.maxSpeedUpperArm
  },
  {
    label: 'Max Speed Lower Arm',
    section: SECTIONS.maxSpeeds,
    getValue: motion => motion.fullMotionMetrics?.maxSpeedLowerArm
  },
  {
    label: 'Max Speed Hand',
    section: SECTIONS.maxSpeeds,
    getValue: motion => motion.fullMotionMetrics?.maxSpeedHand
  },

  {
    label: 'Max Speed Body',
    section: SECTIONS.maxSpeeds,
    getValue: motion => {
      if (motion.fullMotionMetrics == null) return 0;
      const fullMotionMetrics = motion.fullMotionMetrics;
      return fullMotionMetrics.maxSpeedPelvis ?? 0 +
        fullMotionMetrics.maxSpeedTorso ?? 0 +
        fullMotionMetrics.maxSpeedUpperArm ?? 0 +
        fullMotionMetrics.maxSpeedLowerArm ?? 0 +
        fullMotionMetrics.maxSpeedHand ?? 0;
    }
  },
  
  // Key Frame Data
  {
    label: 'HS X-Factor',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.xFactorAtHeelStrike
  },
  {
    label: 'HS Pelvic Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.pelvisBendAtHeelStrike
  },
  {
    label: 'HS Pelvic Rotation',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.pelvisRotationAtHeelStrike
  },
  {
    label: 'HS Pelvic Side Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.pelvisSideBendAtHeelStrike
  },
  {
    label: 'HS Torso Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.torsoBendAtHeelStrike
  },
  {
    label: 'HS Torso Rotation',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.torsoRotationAtHeelStrike
  },
  {
    label: 'HS Torso Side Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.torsoSideBendAtHeelStrike
  },
  {
    label: 'FM X-Factor',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.xFactorAtFirstMove
  },
  {
    label: 'FM Pelvic Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.pelvisBendAtFirstMove
  },
  {
    label: 'FM Pelvic Rotation',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.pelvisRotationAtFirstMove
  },
  {
    label: 'FM Pelvic Side Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.pelvisSideBendAtFirstMove
  },
  {
    label: 'FM Torso Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.torsoBendAtFirstMove
  },
  {
    label: 'FM Torso Rotation',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.torsoRotationAtFirstMove
  },
  {
    label: 'FM Torso Side Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.torsoSideBendAtFirstMove
  },
  {
    label: 'CON X-Factor',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.xFactorAtContact
  },
  {
    label: 'CON Pelvic Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.pelvisBendAtContact
  },
  {
    label: 'CON Pelvic Rotation',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.pelvisRotationAtContact
  },
  {
    label: 'CON Pelvic Side Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.pelvisSideBendAtContact
  },
  {
    label: 'CON Torso Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.torsoBendAtContact
  },
  {
    label: 'CON Torso Rotation',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.torsoRotationAtContact
  },
  {
    label: 'CON Torso Side Bend',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.torsoSideBendAtContact
  },
  {
    label: 'X-Factor Stretch',
    section: SECTIONS.keyFrameData,
    getValue: motion => motion[KEY_FRAME_POSITIONS]?.xFactorStretch
  },

  // Peak Speed Percentiles
  {
    label: 'Peak Speed Pelvis Percentile',
    section: SECTIONS.peakSpeedPercentiles,
    domain: [0, 100],
    getValue: motion => motion[PEAK_SPEEDS_PERCENTILES_KEY]?.[SEGMENTS.pelvis]
  },
  {
    label: 'Peak Speed Torso Percentile',
    section: SECTIONS.peakSpeedPercentiles,
    domain: [0, 100],
    getValue: motion => motion[PEAK_SPEEDS_PERCENTILES_KEY]?.[SEGMENTS.torso]
  },
  {
    label: 'Peak Speed Arm Percentile',
    section: SECTIONS.peakSpeedPercentiles,
    domain: [0, 100],
    getValue: motion => motion[PEAK_SPEEDS_PERCENTILES_KEY]?.[SEGMENTS.arm]
  },
  {
    label: 'Peak Speed Hand Percentile',
    section: SECTIONS.peakSpeedPercentiles,
    domain: [0, 100],
    getValue: motion => motion[PEAK_SPEEDS_PERCENTILES_KEY]?.[SEGMENTS.hand]
  },
  {
    label: 'Peak Speed Body Percentile',
    section: SECTIONS.peakSpeedPercentiles,
    domain: [0, 100],
    getValue: motion => motion[PEAK_SPEEDS_PERCENTILES_KEY]?.[SEGMENTS.body]
  },
  {
    label: 'Ball Speed Percentile',
    section: SECTIONS.peakSpeedPercentiles,
    domain: [0, 100],
    getValue: motion => motion[PEAK_SPEEDS_PERCENTILES_KEY]?.[SEGMENTS.ball]
  },

  // Max Speed Percentiles
  {
    label: 'Max Speed Pelvis Percentile',
    section: SECTIONS.maxSpeedPercentiles,
    domain: [0, 100],
    getValue: motion => motion[MAX_SPEEDS_PERCENTILES_KEY]?.[SEGMENTS.pelvis]
  },
  {
    label: 'Max Speed Torso Percentile',
    section: SECTIONS.maxSpeedPercentiles,
    domain: [0, 100],
    getValue: motion => motion[MAX_SPEEDS_PERCENTILES_KEY]?.[SEGMENTS.torso]
  },
  {
    label: 'Max Speed Arm Percentile',
    section: SECTIONS.maxSpeedPercentiles,
    domain: [0, 100],
    getValue: motion => motion[MAX_SPEEDS_PERCENTILES_KEY]?.[SEGMENTS.arm]
  },
  {
    label: 'Max Speed Hand Percentile',
    section: SECTIONS.maxSpeedPercentiles,
    domain: [0, 100],
    getValue: motion => motion[MAX_SPEEDS_PERCENTILES_KEY]?.[SEGMENTS.hand]
  },
  {
    label: 'Max Speed Body Percentile',
    section: SECTIONS.maxSpeedPercentiles,
    domain: [0, 100],
    getValue: motion => motion[MAX_SPEEDS_PERCENTILES_KEY]?.[SEGMENTS.body]
  }
];
