import { makeStyles } from '@material-ui/styles';
import Palette from '../../../../styles/palette';

const useStyles = makeStyles(_theme => ({
  container: {
    height: '100%',
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',
    overflowY: 'auto'
  },
  divider: {
    width: '100%',
    marginTop: '5px'
  },
  headerContainer: {
    marginBottom: '10px', 
    paddingTop: '5px', 
    width: '95%', 
    position: 'sticky', 
    top: 0, 
    backgroundColor: 'white', 
    zIndex: 100 
  },
  headerContents: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  thirdPartyDataContainer: {
    display: 'flex', 
    alignItems: 'center',
    width: '90%'
  },
  graphContainer: {
    padding: '5px',
    border: '1px solid lightgray', 
    borderRadius: '3px',
    flexGrow: 1,
    width: '80%',
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tilesContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    paddingLeft: 10,
    backgroundColor: Palette.appBackground,
    borderRadius: '10px'
  },
  statsTile: {
    margin: 10,
    width: '20%'
  }
}));

export default useStyles;
