
// Map over  template lesson segments to fill in order and next template segment id.
export function mapOrderAndNextTemplateSegmentId(lessonSegments) {
  return lessonSegments.map((segment, idx) => ({
    ...segment,
    order: idx,
    nextTemplateSegmentId: idx + 1 >= lessonSegments.length 
      ? null 
      : lessonSegments[idx + 1].id
  }));
};
