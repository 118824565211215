import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import logger from 'js-logger';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';

import SelectableVideoList from '../selectableVideoList';
import { GetDrillVideos, GetAnnotatedVideos, GetContentVideos } from '../../../network/videoRequests';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import ErrorSnackbar from '../../../components/errorSnackbar';
import VideoStatus from '../../../constants/video.constants';
import { TAB_PERMISSIONS, PERMISSIONS } from '../../../constants/permissions.constants';

function useActionPlanVideos(userId) {
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);  
  const [ videos, setVideos ] = useState({
    drillVideos: [],
    analyzedVideos: [],
    contentVideos: []
  });

  const featurePermissions = useSelector(state => state.featurePermissions);
  const hasVideoAnalysis = featurePermissions.some(
    permission => permission === TAB_PERMISSIONS.videoAnalysis);
  const hasContentVideos = featurePermissions.some(
    permission => permission === PERMISSIONS.contentFeedEnabled);

  useEffect(() => {
    const token = axios.CancelToken.source();
    async function fetchData() {
      try {
        setLoading(true);
        setError(null);
        const [
          drillVideos, 
          analyzedVideos, 
          contentVideos
        ] = await Promise.all([
          GetDrillVideos({ status: VideoStatus.available }, token),
          hasVideoAnalysis && userId != null ? GetAnnotatedVideos(userId, null, token) : () => [],
          hasContentVideos ? GetContentVideos(VideoStatus.available, token) : () => []
        ]);

        const sortedAnalyzedVideos = orderBy(
          analyzedVideos, 
          video => new Date(video.creationTimestamp), 'desc');
        const sortedContentVideos = orderBy(
          contentVideos,
          video => new Date(video.creationTimestamp), 'desc');

        setVideos({ 
          drillVideos, 
          contentVideos: sortedContentVideos, 
          analyzedVideos: sortedAnalyzedVideos 
        });

        setLoading(false);
      } catch (e) {
        logger.error('Error loading videos', e);
        setError('Error loading videos.');
        setLoading(false);
      }
    }
    fetchData();
    return token.cancel;
  }, [userId, hasVideoAnalysis, hasContentVideos]);

  const clearError = () => setError(null);

  return { loading, error, clearError, ...videos };
}

function VideoSelectionDialog({ classes, open, onSave, onCancel, videoIds, userId }) {
  const { 
    loading, 
    error, 
    clearError, 
    drillVideos, 
    analyzedVideos, 
    contentVideos 
  } = useActionPlanVideos(userId);
  const [ selectedVideoIds, setSelectedVideoIds ] = useState(videoIds);
  const [ previewVideo, setPreviewVideo ] = useState(null);

  const videoMap = useMemo(
    () => keyBy([...contentVideos, ...drillVideos, ...analyzedVideos], 'id'), 
    [contentVideos, drillVideos, analyzedVideos]);

  function toggleSelectedVideoId(id) {
    if (selectedVideoIds.includes(id)) {
      setSelectedVideoIds(selectedVideoIds.filter(x => x !== id));
    } else {
      setSelectedVideoIds([...selectedVideoIds, id]);
    }
  }

  function previewVideoClicked(video) {
    setPreviewVideo(prev => prev && video.id === prev.id ? null : video);
  }

  let videoSections = [
    { header: 'Content Videos', videos: contentVideos },
    { header: 'Drill Videos', videos: drillVideos },
    { header: 'Analyzed Videos', videos: analyzedVideos, displayDate: true }
  ];

  return <div>
    <ErrorSnackbar message={error} onClose={clearError} />
    <Dialog open={open} fullWidth maxWidth='md'>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.videoSelectionContainer}>
          <div className={classes.videoList}>
            <SelectableVideoList loading={loading}
              sections={videoSections}
              selectedVideoIds={selectedVideoIds}
              onToggleSelection={toggleSelectedVideoId}
              onPreviewClicked={previewVideoClicked}
            />
          </div>
          <div className={classes.selectedVideosContainer}>
            <Typography variant='h6'>Selected Videos</Typography>
            <div>
              {selectedVideoIds.map(id => <div key={id} className={classes.selectedVideo}>
                <CloseIcon onClick={() => toggleSelectedVideoId(id)} 
                  className={classes.closeIcon} /> 
                <Typography>{videoMap[id] ? videoMap[id].title : `Video ${id}`}</Typography>
              </div>)}
            </div>
          </div>
        </div>

        <div className={classes.videoPreviewContainer}>
          <Typography className={classes.previewVideoLabel} variant='h6'>
            {previewVideo ? previewVideo.title : 'Video Preview'}
          </Typography>
          {previewVideo && <iframe 
            title='Video Preview' 
            src={`${previewVideo.url}?autoplay=1&loop=1`} 
            allow='autoplay; fullscreen'
            allowFullScreen
            className={classes.previewIFrame}
          />}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onSave(selectedVideoIds)} color='primary'>Save</Button>
      </DialogActions>
    </Dialog>
  </div>;
};

export default withStyles(styles)(VideoSelectionDialog);
