import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container : {
    padding: theme.spacing(2), 
    margin: 0, 
    width: '100%'
  },
  header: {
    display: 'flex', 
    padding: theme.spacing(2), 
    alignItems: 'center'
  },
  title: {
    marginLeft: theme.spacing(2)
  }
}));

export default useStyles;
