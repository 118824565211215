import React, { useEffect, useState } from 'react';
import { 
  Button, 
  Dialog, 
  DialogTitle,
  DialogContent, 
  TextField,
  Grid,
  CircularProgress,
  List,
  Typography,
  IconButton
} from '@material-ui/core';
import { People, ChevronLeft, ChevronRight } from '@material-ui/icons';
import useInput from 'utils/useInput';
import useFilteredPlayers from './useFilteredPlayers';
import PlayerListItem from './playerListItem';
import useStyles from './styles';
import { history } from 'store';
import { EVENT_USER_MODALS } from 'pages/captureEvents/captureEventDetails/eventUsersTable';

const PAGE_SIZE = 10;

function PlayerSearch({ captureEventsMap }) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [page, setPage] = useState(0);

  const { clearValue: clearFirstNameInput, ...firstNameInput } = useInput();
  const { clearValue: clearLastNameInput, ...lastNameInput } = useInput();
  const { clearValue: clearEmailInput, ...emailInput } = useInput();

  const { 
    users, 
    pagination,
    loading 
  } = useFilteredPlayers(
    firstNameInput.value.trim(), 
    lastNameInput.value.trim(), 
    emailInput.value.trim(), 
    page * PAGE_SIZE,
    PAGE_SIZE);

  const totalPages = Math.floor((((pagination?.totalCount ?? 0) - 1) / PAGE_SIZE) + 1);

  useEffect(() => {
    if (dialogOpen) {
      clearFirstNameInput();
      clearLastNameInput();
      clearEmailInput();
      setPage(0);
    }
  }, [dialogOpen, clearFirstNameInput, clearLastNameInput, clearEmailInput]);

  const onEventPress = (captureEventId, userId, captureEventUserId) => {
    const params = {
      userId,
      selectedEventUserId: captureEventUserId,
      modal: EVENT_USER_MODALS.TRAINING_PLAN_TOOLS
    };
    history.push(`/capture-events/${captureEventId}?` + new URLSearchParams(params).toString());
    setDialogOpen(false);
  };

  return <div>
    <Button 
      color='primary' 
      variant='outlined' 
      startIcon={<People />}
      onClick={() => setDialogOpen(true)}
    >
      Players
    </Button>

    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth='lg' fullWidth>
      <DialogTitle>
        <div>
          Player Search
        </div>
        
        <Grid container spacing={2}>
          <Grid item>
            <TextField {...firstNameInput.bind} label='First Name' />
          </Grid>
          <Grid item>
            <TextField {...lastNameInput.bind} label='Last Name' />
          </Grid>
          <Grid item>
            <TextField {...emailInput.bind} label='Email' />
          </Grid>
          <Grid item className={classes.pagination}>
            <IconButton disabled={page <= 0} onClick={() => setPage(prev => prev - 1)}>
              <ChevronLeft />
            </IconButton>
            <Typography>{page + 1}/{totalPages}</Typography>
            <IconButton disabled={page >= totalPages - 1} onClick={() => setPage(prev => prev + 1)}>
              <ChevronRight />
            </IconButton>
          </Grid>
          <Grid item>
            {loading && <CircularProgress size={24} />}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        

        <List className={classes.list}>
          {users.map(user => <PlayerListItem 
            key={user.userId} 
            user={user} 
            captureEventsMap={captureEventsMap} 
            onEventPress={onEventPress}
          />)}
        </List>
      </DialogContent>
    </Dialog>
  </div>;
}

export default PlayerSearch;