import React from 'react';
import { Redirect } from 'react-router-dom';
import Logger from 'js-logger';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getRolesForLoggedInUser, getUserId } from '../../utils/auth.utils';
import { ORG_ROLE, USER_ROLE } from '../../constants/auth.constants';
import { GetOrganization } from '../../network/organizationRequests';
import { setCurrentOrganization } from '../../store/currentOrganization';
import { GetUser } from '../../network/userRequests';
import { isBabeRuthExperience, isPerfectGameExperience } from '../../utils/user.utils';
import { KMotionUrl } from '../../constants/urls.constants'; 

class Home extends React.Component {
  state = {
    url: null
  }
  
  async componentDidMount() {
    var roles = getRolesForLoggedInUser();
    if (roles.includes(ORG_ROLE)) {
      await this._handleOrgNavigation();
    } else if (roles.includes(USER_ROLE)) {
      let userId = getUserId();
      let user = await GetUser(userId);
      if (isPerfectGameExperience(user)) {
        this.setState({ url: '/all-training-plans' });
      } else if(isBabeRuthExperience(user)) {
        this.setState({ url: '/purchase' });
      } else {
        window.location = KMotionUrl;
      }
    } else {
      this.setState({ url: '/login' });
    }
  }

  _handleOrgNavigation = async () => {
    try {
      let userId = getUserId();
      let org = await GetOrganization(userId);
      this.props.setOrganization(org);
      this.setState({ url: '/player-development' });
    } catch (e) {
      Logger.error('Error routing org', e);
      this.setState({ url: '/login' });
    }
  }
  
  render() {
    const { url } = this.state;
    const { location } = this.props;
    return url 
      ? <Redirect to={{
        pathname: url,
        state: { from: location }
      }} />
      : <CircularProgress />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setOrganization: org => {
      dispatch(setCurrentOrganization(org));
    }
  };
};

export default connect(null, mapDispatchToProps)(Home);
