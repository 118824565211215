import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '50px'
  },
  header: {
    marginTop: '35px',
    marginBottom: '30px',
    fontWeight: 'bold'
  }
}));

export default useStyles;
