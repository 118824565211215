import format from 'date-fns/format';

const simpleFormatter = (value) => { return value; };
const decimalFormatter = (value) => { return value.toFixed(2); };
const lmDecimalFormatter = value => { return value != null ? value.toFixed(value % 1 === 0 ? 0 : 2) : '-';};
const dateFormatter = (value) => { 
  var date = new Date(value);
  return format(date, 'MM/dd/yy h:mm aa');
};

const GolfSummaryData = [
  {
    key: 'firstName',
    displayName: 'First Name',
    formatter: simpleFormatter
  },
  {
    key: 'lastName',
    displayName: 'Last Name',
    formatter: simpleFormatter
  },
  {
    key: 'timestamp',
    displayName: 'Timestamp',
    dataType: 'date',
    formatter: dateFormatter
  },
  {
    key: 'backswingTime',
    displayName: 'Backswing Time',
    formatter: decimalFormatter,
    units: 'Seconds'
  },
  {
    key: 'downswingTime',
    displayName: 'Downswing Time',
    formatter: decimalFormatter,
    units: 'Seconds'
  },
  {
    key: 'swingTime',
    displayName: 'Swing Time',
    formatter: decimalFormatter,
    units: 'Seconds'
  },
  {
    key: 'tempo',
    displayName: 'Tempo',
    formatter: decimalFormatter
  },
  {
    key: 'peakSpeedPelvis',
    displayName: 'Pelvis Peak Speed',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'peakSpeedTorso',
    displayName: 'Torso Peak Speed',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'peakSpeedArm',
    displayName: 'Arm Peak Speed',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'peakSpeedHand',
    displayName: 'Hand Peak Speed',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },

  {
    key: 'backspinRpm',
    displayName: 'Backspin',
    formatter: lmDecimalFormatter,
    units: 'rpm'
  },
  {
    key: 'ballspeedMph',
    displayName: 'Ball Speed',
    formatter: lmDecimalFormatter,
    units: 'mph'
  },
  {
    key: 'carryDistanceYards',
    displayName: 'Carry Distance',
    formatter: lmDecimalFormatter,
    units: 'yards'
  },
  {
    key: 'clubSpeedMph',
    displayName: 'Club Speed',
    formatter: lmDecimalFormatter,
    units: 'mph'
  },
  {
    key: 'launchAngleDeg',
    displayName: 'Launch Angle',
    formatter: lmDecimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'attackAngleDeg',
    displayName: 'Attack Angle',
    formatter: lmDecimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'clubFaceAngleDeg',
    displayName: 'Club Face Angle',
    formatter: lmDecimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'clubPathDeg',
    displayName: 'Club Path',
    formatter: lmDecimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'dynamicLoftDeg',
    displayName: 'Dynamic Loft',
    formatter: lmDecimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'transitionFramePelvis',
    displayName: 'Pelvis Transition Frame',
    formatter: simpleFormatter
  },
  {
    key: 'transitionFrameTorso',
    displayName: 'Torso Transition Frame',
    formatter: simpleFormatter
  },
  {
    key: 'transitionFrameArm',
    displayName: 'Arm Transition Frame',
    formatter: simpleFormatter
  },
  {
    key: 'transitionFrameHand',
    displayName: 'Hand Transition Frame',
    formatter: simpleFormatter
  },
  {
    key: 'pelvisBendAtAddress',
    displayName: 'Pelvis Bend at Address',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisBendAtTop',
    displayName: 'Pelvis Bend at Top',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisBendAtImpact',
    displayName: 'Pelvis Bend at Impact',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoBendAtAddress',
    displayName: 'Torso Bend at Address',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoBendAtTop',
    displayName: 'Torso Bend at Top',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoBendAtImpact',
    displayName: 'Torso Bend at Impact',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisSideBendAtAddress',
    displayName: 'Pelvis Side Bend at Address',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisSideBendAtTop',
    displayName: 'Pelvis Side Bend at Top',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisSideBendAtImpact',
    displayName: 'Pelvis Side Bend at Impact',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoSideBendAtAddress',
    displayName: 'Torso Side Bend at Address',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoSideBendAtTop',
    displayName: 'Torso Side Bend at Top',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoSideBendAtImpact',
    displayName: 'Torso Side Bend at Impact',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisRtnJcsAtAddress',
    displayName: 'Pelvis Rotation at Address',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisRtnJcsAtTop',
    displayName: 'Pelvis Rotation at Top',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisRtnJcsAtImpact',
    displayName: 'Pelvis Rotation at Impact',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoRtnJcsAtAddress',
    displayName: 'Torso Rotation at Address',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoRtnJcsAtTop',
    displayName: 'Torso Rotation at Top',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoRtnJcsAtImpact',
    displayName: 'Torso Rotation at Impact',
    formatter: decimalFormatter,
    units: 'Degrees'
  },  
  {
    key: 'pelvisRotationalVelocityAtAddress',
    displayName: 'Pelvis Rotational Velocity at Address',
    formatter: decimalFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'pelvisRotationalVelocityAtTop',
    displayName: 'Pelvis Rotational Velocity at Top',
    formatter: decimalFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'pelvisRotationalVelocityAtImpact',
    displayName: 'Pelvis Rotational Velocity at Impact',
    formatter: decimalFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'torsoRotationalVelocityAtAddress',
    displayName: 'Torso Rotational Velocity at Address',
    formatter: decimalFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'torsoRotationalVelocityAtTop',
    displayName: 'Torso Rotational Velocity at Top',
    formatter: decimalFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'torsoRotationalVelocityAtImpact',
    displayName: 'Torso Rotational Velocity at Impact',
    formatter: decimalFormatter,
    units: 'Degrees/Seconds'
  }
];

// Filter out: firstName, lastName
const nonGraphableValues = ['firstName', 'lastName'];
const graphableValuesList = GolfSummaryData.filter((row) => {
  return !nonGraphableValues.includes(row.key);
});

export const GolfSwingSummaryGraphableValues = graphableValuesList;

const golfSummaryMap = {};

GolfSummaryData.forEach((row) => {  
  golfSummaryMap[row.key] = row;
});

export const GolfSummaryToMap = golfSummaryMap;
// TODO: extras: {key: pelvisRtnJcsAtAddress, displayName: 'Pelvis Rotation at Impact'}}
// TODO: whiteListedMetrics: excludes: timestamp, frames
const metricValueBlacklist = ['timestamp', 'transitionFramePelvis', 'transitionFrameTorso', 'transitionFrameArm', 'transitionFrameHand'];
export const GolfSwingProgressMetrics = graphableValuesList.filter((row) => {
  return !metricValueBlacklist.includes(row.key);
});

export const GolfSummaryUtilList = GolfSummaryData;