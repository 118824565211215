import Palette from '../../styles/palette';

const styles = {
  cssBorderRadius: { borderRadius: 8 },
  cssDarkBackground: {
    background: `radial-gradient(
      rgba(0,0,0,0.2),
      rgba(41,41,41,0.5)
    )`
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column' 
  }
};

export default styles;

export const PopupHeaderStyles = {
  outerContainer: {
    background: Palette.lightGray, 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    padding: '10px'
  },
  noPadding: { padding: 0 }
};