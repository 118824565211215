import React from 'react';
import { connect } from 'react-redux';
import Logger from 'js-logger';
import PropTypes from 'prop-types';
import pLimit from 'p-limit';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import StyledFileInput from '../../../../components/styledFileInput';
import ErrorSnackbar from '../../../../components/errorSnackbar';
import VideoUploadDetails from './videoUploadDetails';
import { UploadMotionVideo, UploadVideo, VimeoTUSVideoUpload } from '../../../../network/videoRequests';
import UploadProgressDialog from './uploadProgressDialog';
import uploadStatusConstants from './uploadStatus.constants';
import { Prompt } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { VIDEO_TYPE } from '../../../../constants/video.constants';
import { PERMISSIONS } from '../../../../constants/permissions.constants';

const MAX_PROMISE_COUNT = 4;
const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 500;
const TITLE = 'title';
const DESCRIPTION = 'description';
const EXTERNAL_ID = 'externalId';
const UPLOAD_PROGRESS = 'uploadProgress';
const STATUS = 'status';
const TYPE = 'type';

const defaultState = {
  selectedFiles: null,
  uploadingVideos: false,
  uploadComplete: false,
  uploadError: null
};

class VideoUploadForm extends React.Component {
  state = defaultState;
  
  componentDidMount() {
    this.props.initialFiles && this._onFileSelection(this.props.initialFiles);
  }

  _resetState = () => {
    this.setState(defaultState);
    this.props.onSelectionCancelled();
  }

  _onFileSelection = files => {
    const { videoType } = this.props;
    const selectedFiles = files.map(file => ({
      file,
      [TITLE]: videoType === VIDEO_TYPE.motion ? '' : file.name,
      [DESCRIPTION]: '',
      [EXTERNAL_ID]: null,
      [UPLOAD_PROGRESS]: 0,
      [STATUS]: uploadStatusConstants.NOT_STARTED,
      [TYPE]: videoType
    }));

    this.setState({ selectedFiles });
    this.props.onVideosSelected(selectedFiles);
  }

  _onFileDetailsChange = (idx, newValues) => {
    const { selectedFiles } = this.state;
    this.setState({ selectedFiles: selectedFiles.map((file, i) => i === idx
      ? { ...file, ...newValues }
      : file ) });
  }

  _onSubmit = async () => {
    const { selectedFiles, uploadingVideos } = this.state;

    // Prevent double click - setState is flushed at browser event boundary.
    // https://github.com/facebook/react/issues/11171#issuecomment-357945371
    if (uploadingVideos) {
      return;
    }

    const limit = pLimit(MAX_PROMISE_COUNT);
    this.setState({ uploadingVideos: true });
    const videos = await Promise.all(
      selectedFiles.map((video, idx) => limit(() => this._uploadVideo(video, idx))));
    this.setState({
      uploadComplete: true
    }, this.props.onUploadComplete(videos.filter(video => video != null)));
  }

  _uploadVideo = async (videoObj, idx) => {
    const { userId, videoType } = this.props;
    const { title, description, file, type } = videoObj;
    let video;
    try {
      this._onFileDetailsChange(idx, { [STATUS]: uploadStatusConstants.POSTING });
      if (videoType === VIDEO_TYPE.motion) {
        video = await UploadMotionVideo(userId, title, description, file);
      } else {
        video = await UploadVideo(title, description, file, type);
      }
    } catch (e) {
      Logger.error(`Error uploading video for ${userId} to cloud: ${e}`);
      this._onFileDetailsChange(idx, { [STATUS]: uploadStatusConstants.FAILURE });
      return;
    }

    try {
      this._onFileDetailsChange(idx, { 
        [STATUS]: uploadStatusConstants.VIMEO_UPLOADING, 
        [EXTERNAL_ID]: video.externalId 
      });
      await VimeoTUSVideoUpload(
        file, 
        video.uploadUrl, 
        progress => this._onFileDetailsChange(idx, { [UPLOAD_PROGRESS]: progress }));
      this._onFileDetailsChange(idx, { [STATUS]: uploadStatusConstants.COMPLETE });
    } catch (e) {
      Logger.error(`Error uploading video with vimeo id ${video.externalId} to vimeo: ${e}`);
      this._onFileDetailsChange(idx, { [STATUS]: uploadStatusConstants.FAILURE });
    }

    return video;
  }
  
  render() {
    const { classes, className, videoType, featurePermissions } = this.props;
    const {
      selectedFiles,
      uploadingVideos,
      uploadError,
      uploadComplete
    } = this.state;

    const selectingFiles = selectedFiles && selectedFiles.length > 0;

    const contentFeedEnabled = featurePermissions.includes(PERMISSIONS.contentFeedEnabled);

    return (
      <React.Fragment>
        <Prompt
          when={selectedFiles && selectedFiles.length > 0}
          message={'You have not finished submitting videos yet. ' +
            'The pending videos will not be uploaded. Are you sure you want to leave.'}
        />
        <div className={className}>
          <ErrorSnackbar 
            message={uploadError} 
            onClose={() => this.setState({ uploadError: null })} 
          />
          <UploadProgressDialog 
            open={uploadingVideos} 
            videoFiles={selectedFiles} 
            uploadComplete={uploadComplete}
            onClose={this._resetState} 
          />
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12}>
              <div className={classes.flexAlignCenter}>
                <StyledFileInput multiple
                  className={classes.fileInput}
                  name='file_data'
                  accept='video/*'
                  label={`${selectingFiles ? 'Select' : 'Upload'} Videos`}
                  onChange={this._onFileSelection}
                  rightAdornment={<ArrowDropDown className={classes.fileInputArrow} />}
                />
              </div>
            </Grid>
            <Collapse in={selectingFiles} className={classes.collapse}>
              {selectedFiles && selectedFiles.map((video, idx) => <VideoUploadDetails 
                className={classes.uploadDetails}
                key={`${video.file.name}-idx`}
                title={video.title}
                description={video.description}
                videoType={video.type}
                file={video.file}
                onTitleChange={e => this._onFileDetailsChange(idx, 
                  { [TITLE]: e.target.value.substring(0, MAX_TITLE_LENGTH) })}
                onDescriptionChange={e => this._onFileDetailsChange(idx, 
                  { [DESCRIPTION]: e.target.value.substring(0, MAX_DESCRIPTION_LENGTH) })}
                onVideoTypeChange={value => this._onFileDetailsChange(idx, 
                  { [TYPE]: value })}
                displayVideoTypeSelect={videoType !== VIDEO_TYPE.motion 
                  && contentFeedEnabled}
              />)}
              <div className={this.props.classes.formActionButtons}>
                <Button variant='contained' 
                  color='primary' 
                  onClick={this._onSubmit} 
                  className={this.props.classes.submitButton}
                >
                  Submit
                </Button>
                <Button variant='outlined' onClick={this._resetState}>Cancel</Button>
              </div>
            </Collapse>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

VideoUploadForm.propTypes = {
  onUploadComplete: PropTypes.func.isRequired,
  // will get called with list of files, not required for video upload functionality
  onVideosSelected: PropTypes.func,
  onSelectionCancelled: PropTypes.func, // called with no args when selection is cancelled
  videoType: PropTypes.oneOf(['Drill', 'Motion']),
  userId: ({ props, propName }) => {
    if (props && props['videoType'] === 'Drill' && 
        (props[propName] === undefined || typeof(props[propName] !== 'string'))) {
      return new Error('userId is required if uploading user videos');
    }
  },
  initialFiles: PropTypes.array // array of initial files to have loaded
};

VideoUploadForm.defaultProps = {
  onVideosSelected: () => {},
  onSelectionCancelled: () => {},
  type: 'Motion'
};

const mapStateToProps = ({ featurePermissions }) => ({
  featurePermissions
});

export default withStyles(styles)(connect(mapStateToProps)(VideoUploadForm));
