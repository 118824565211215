import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(_ => ({
  container: {
    padding: '20px'
  },
  headerContainer: {
    padding: '20px',
    marginTop: '45px',
    display: 'flex',
    alignItems: 'center',
    height: '80px'
  },
  requestIcon: {
    marginLeft: '15px'
  },
  requestButton: {
    float: 'right'
  },
  legendText: {
    marginLeft: '10px'
  }
}));