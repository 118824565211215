import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CaptureEventDetails from '../captureEventDetails';
import CreateCaptureEvent from './createCaptureEvent';
import { history } from '../../../store';
import CaptureEventsList from './captureEventsList';
import useStyles from './styles';
import { fetchCaptureEvents, setSingleCaptureEvent } from '../../../store/captureEvents';
import { fetchCurrentOrganization } from '../../../store/currentOrganization';
import PlayerSearch from './playerSearch';
import useCaptureEventsSplitRecent from './useRecentCaptureEventsSplitRecent';
import CaptureEventActions from './../captureEventActions';

const NOW = new Date();

function CaptureEventsHome({ match }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCaptureEvents());
    dispatch(fetchCurrentOrganization());
  }, [dispatch]);

  const { 
    items: captureEventsMap, 
    loading, 
    error 
  } = useSelector(state => state.captureEvents);
  const captureEvents = Object.values(captureEventsMap)
    .map(({ item }) => item)
    .filter(x => x != null);

  const matchWithEventId = matchPath(history.location.pathname, {
    path: `${match.path}/:eventId`
  });
  const eventId = matchWithEventId ? parseInt(matchWithEventId.params.eventId) : null;
  const selectedEventObj = eventId != null ? captureEventsMap[eventId] : null;

  const allCaptureEventSplits = useCaptureEventsSplitRecent(NOW, captureEvents);

  const { 
    currentEvents,
    pastEvents,
    upcomingEvents
  } = allCaptureEventSplits;

  const [actionsOpened, setActionsOpened] = useState(false);

  const redirectToHome = () => history.push('/');
  const redirectToTrainingPlans = () => history.push('/player-development/training-plans');

  return <Grid container spacing={1}>
    <Grid item xs={12}>
      <Button color='primary' onClick={redirectToHome}>Back to Player Development</Button>
      <Button color='primary' onClick={redirectToTrainingPlans}>
        Training Plans
      </Button>
      <div className={classes.buttonRow}>
        <PlayerSearch captureEventsMap={captureEventsMap} />
        <div className={classes.eventActions}>
          <Button 
            onClick={() => setActionsOpened(true)}
            color='primary' 
            variant='outlined' 
          >
            Event Actions
          </Button>
        </div>
        <CreateCaptureEvent 
          className={classes.createCaptureEventContainer}
          onEventCreated={createdEvent => {
            dispatch(setSingleCaptureEvent(createdEvent));
            history.push(`${match.path}/${createdEvent.id}`);
          }} 
        />
      </div>
    </Grid>
    <Grid item xs={6} md={4} lg={3}>
      <CaptureEventsList 
        selectedEventId={eventId}
        loading={loading}
        error={error}
        currentEvents={currentEvents} 
        upcomingEvents={upcomingEvents}
        pastEvents={pastEvents}
        onChange={captureEvent => history.push(`${match.path}/${captureEvent.id}`)}
      />
    </Grid>
    <Grid item xs={6} md={8} lg={9}>
      {selectedEventObj != null && <CaptureEventDetails 
        captureEvent={selectedEventObj.item} 
        loading={selectedEventObj.loading} 
        error={selectedEventObj.error} />}
    </Grid>

    <CaptureEventActions 
      captureEvents={captureEvents}
      captureEventSplits={allCaptureEventSplits ?? {}}
      open={actionsOpened}
      onCancel={() => setActionsOpened(false)}
    />
  </Grid>;
}

export default CaptureEventsHome;