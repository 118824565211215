import { useCallback } from 'react';
import mixpanel from 'mixpanel-browser';
import useKlaviyo from './useKlaviyo';
import useGoogleAnalytics from './useGoogleAnalytics';
import useFacebookPixel from './useFacebookPixel';


export const EVENT_NAMES = {
  loginPageViewed: 'Login Page Viewed',
  loggedIn: 'Logged In',
  accountCreationPageViewed: 'Account Creation Page Viewed',
  accountCreationClicked: 'Account Creation Clicked',
  accountCreated: 'Account Created',
  detailsEntered: 'Details Entered',
  productsPageVisited: 'Products Page Visited',
  planClicked: 'Plan Clicked',
  paymentAttempted: 'Payment Attempted',
  subscriptionPurchased: 'Subscription Purchased',
  subscriptionCancellationScheduled: 'Subscription Cancellation Scheduled',
  subscriptionCancellationUndone: 'Subscription Cancellation Undone',
  appStoreClicked: 'App Store Clicked',
  logoutClicked: 'Logout Clicked'
};

mixpanel.init(process.env.REACT_APP_MIXPANEL_KGO_API_TOKEN);

function useTrackingServices() {
  const klaviyo = useKlaviyo();
  const facebook = useFacebookPixel();
  useGoogleAnalytics();

  const identify = useCallback((user, customProps = {}, checkForKlaviyoEmailDivergence = false) => {
    if (user == null) return;
    const facebookIdentify = facebook.identify;
    const klaviyoIdentify = klaviyo.identify;

    klaviyoIdentify(user, customProps, checkForKlaviyoEmailDivergence);
    facebookIdentify(user);

    const { userId, firstName, lastName, emailAddress, dateOfBirth } = user;
    mixpanel.identify(userId);
    let convertedBirthday;
    if (dateOfBirth && dateOfBirth.split('T').length === 2) {
      convertedBirthday = dateOfBirth.split('T')[0];
    }
    mixpanel.people.set({
      'User Id': userId,
      '$first_name': firstName,
      '$last_name': lastName,
      '$email': emailAddress,
      Birthday: convertedBirthday,
      ...customProps
    });
  }, [klaviyo.identify, facebook.identify]);
  
  const track = useCallback((eventName, properties) => {
    const klaviyoTrack = klaviyo.track;
    const facebookTrack = facebook.track;

    klaviyoTrack(eventName, properties);
    facebookTrack(eventName, properties);
    mixpanel.track(eventName, properties);
  }, [klaviyo.track, facebook.track]);

  const setProfileProperty = useCallback((key, value) => {
    const setProfile = klaviyo.setProfileProperty;
    setProfile(key, value);
    mixpanel.people.set(key, value);
  }, [klaviyo.setProfileProperty]);

  return { track, identify, setProfileProperty };
}

export default useTrackingServices;
