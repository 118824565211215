import { makeStyles } from '@material-ui/core/styles';
import palette from '../../styles/palette';
import { CHARACTERISTIC_SEVERITY } from '../../constants/videoAnalysis.constants';

const useStyles = makeStyles(_ => ({
  root: ({ selected }) => ({
    borderRadius: '25px',
    padding: '5px 15px 5px 15px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: selected ? palette.midnightBlue : 'white',
    color: selected ? 'white' : 'black',
    '&:hover': {
      filter: 'brightness(90%)'
    },
    '&>div': {
      padding: '0'
    }
  }),
  icon: ({ severity }) => ({
    width: '15px',
    height: '15px',
    marginRight: '5px',
    color: palette.darkGray,
    backgroundColor: severity ? 
      (severity === CHARACTERISTIC_SEVERITY.none ? 
        palette.subtleGreen : palette.subtleRed ) : 'white',
    borderRadius: '100%',
    padding: '2px',
    border: `1px solid ${palette.darkGray}`
  }),
  container: {
    whiteSpace: 'nowrap',
    '-webkit-user-select': 'none', /* Safari */        
    '-moz-user-select': 'none', /* Firefox */
    '-ms-user-select': 'none', /* IE10+/Edge */
    'user-select': 'none' /* Standard */
  }
}));

export default useStyles;

  