import React, { 
  useState, 
  useMemo
} from 'react';

import { 
  BADGES_MAP,
  BADGES
} from './../../badgeUtils';
import useStyles from './styles';

import classnames from 'classnames';

function InningsScreen({ 
  feed, 
  idMap, 
  curInterval = null,
  numberOfIntervals = null
}) {
  const classes = useStyles();

  const totalIntervals = curInterval !== null && numberOfIntervals !== null ?
    Math.min(curInterval, numberOfIntervals - 1) + 1 : null;

  const inningsFeed = useMemo(() => {
    if (!feed || totalIntervals === null) return [];
    const intervals = Array(totalIntervals).fill(null).map(() => []);
    feed.forEach(badgeInfo => {
      intervals[badgeInfo.interval].push(badgeInfo);
    });
    return intervals.reverse();
  }, [feed, totalIntervals]);

  return <div>
    {
      inningsFeed && totalIntervals !== null &&
      inningsFeed.map((inningArray, inning) => {
        const displayInning = totalIntervals - inning;
        return <div
          key={inning}
          className={classes.inningContainer}>
          <div className={classes.inningTitle}>
            <span>Inning { displayInning }</span>
            { 
              curInterval + 1 === displayInning &&
              <span> (Today)</span>
            }
          </div>
          {
            inningArray.length === 0 &&
            <div className={classes.badgeInfo}>
              No badges earned this inning
            </div>
          }
          {
            inningArray.length > 0 &&
            inningArray.map(({ badgeId, id }, idx) => {
              const badge = BADGES_MAP[badgeId];
              const score = idMap[id];
              return <div
                key={idx}
                className={classes.badgeRow}>
                <div className={classes.badgeImage}>
                  <img alt={badge.name} src={badge.img} className={classes.img}/>
                </div>
                <div className={classes.badgeInfo}>
                  <div>
                    <span className={classes.bold}>
                      {score.selected ? 'You' : score.name}
                    </span>
                    <span>&nbsp;recieved&nbsp;</span>
                    <span className={classes.bold}>{badge.name}</span>
                  </div>
                  <div className={classes.italics}>{badge.description}</div>
                </div>
              </div>;
            })
          }
        </div>;
      })
    }
  </div>;
};

function BadgeScreen({ 
  feed, 
  idMap, 
  forYou = false
}) {
  const classes = useStyles();
  const [open, setOpen] = useState({});

  const badgeToIdsMap = useMemo(() => {
    if (!feed) return {};
    return feed.reduce((map, { badgeId, id }) => {
      if (!map[badgeId]) {
        map[badgeId] = [];
      }
      map[badgeId].push(idMap[id]);
      return map;
    }, {});
  }, [feed, idMap]);

  const onToggleExpand = (id) => {
    if (!badgeToIdsMap[id]) return;
    setOpen(prevOpen => {
      const toggled = !Boolean(prevOpen[id]);
      return { ...prevOpen, [id]: toggled };
    });
  };

  const formatMessage = (id, expanded) => {
    const idObjs = badgeToIdsMap[id];

    if (!idObjs) {
      return forYou 
        ? 'You have not earned this badge this game'
        : 'This badge has not been earned this game';
    }

    if (forYou) {
      return 'You have earned this badge!';
    }

    let message = expanded ? '-' : '+';
    message += ` See ${idObjs.length} player${idObjs.length > 1 ? 's' : ''} `;
    message += 'who have this badge';
    return message;
  };

  const formatNames = (id) => {
    const idObjs = badgeToIdsMap[id];
    if (!idObjs) return '';

    return idObjs.map(({ name, selected }) => {
      if (selected) {
        return 'You have earned this badge';
      }
      return `${name} has earned this badge`;
    }).join('\n');
  };

  return <>
    {
      BADGES.map(({ id, name, img, description }) => {
        return <div
          key={id}
          className={classes.badgeRow}>
          <div className={classes.badgeImage}>
            <img alt={name} src={img} className={classes.img}/>
          </div>
          <div className={classes.badgeInfo}>
            <div className={classes.bold}>{name}</div>
            <div className={classes.italics}>{description}</div>
            <div onClick={() => onToggleExpand(id)}>
              <span className={classes.message}>{formatMessage(id, open[id])}</span>
              {
                open[id] &&
                <div className={classnames(classes.message, classes.names)}>
                  {formatNames(id)}
                </div>
              }
            </div>
          </div>
        </div>;
      })
    }
  </>;
};

function BadgeDisplayContents({
  feed = null,
  screen = null,
  filter = null,
  curInterval,
  currentLeaderboard,
  idMap = {},
  SCREENS,
  FILTERS
}) {

  const classes = useStyles();

  const filteredFeed = useMemo(() => {
    if (!feed) return [];
    return filter === FILTERS.YOU ? feed.filter(badge => idMap[badge.id].selected) : feed;
  }, [FILTERS, feed, filter, idMap]);

  return (
    <div className={classes.contentContainer}>
      {
        screen === SCREENS.INNINGS &&
        <InningsScreen 
          feed={filteredFeed}
          idMap={idMap}
          curInterval={curInterval}
          numberOfIntervals={currentLeaderboard ? currentLeaderboard.numberOfIntervals : null}
        />
      }
      {
        screen === SCREENS.BADGES &&
        <BadgeScreen 
          feed={filteredFeed}
          idMap={idMap}
          forYou={filter === FILTERS.YOU}
        />
      }
    </div>


  );
};

export default BadgeDisplayContents;