import { Paper, CircularProgress, Select, MenuItem } from '@material-ui/core';
import useStyles from './styles';
import React, { useState, useEffect } from 'react';
import PercentilesTable from 'pages/captureEvents/captureEventDetails/eventUsersTable/trainingPlanToolsDialog/percentilesTable';
import BodyPercentiles from 'pages/captureEvents/captureEventDetails/eventUsersTable/trainingPlanToolsDialog/bodyPercentiles';
import { GetCaptureEventResults } from 'network/captureEventRequests';
import Logger from 'js-logger';
import axios from 'axios';
import { orderBy } from 'lodash';
import { formatReadableDateLongTime } from 'utils/formatting.utils';
import { GetUser } from 'network/userRequests';

const BLAITrainingPlans = (props) => {
  const classes = useStyles();
  const userId = props?.match?.params?.userId;

  const [results, setResults] = useState([]);
  const [user, setUser] = useState(null);
  const [currentIdx, setCurrentIdx] = useState(0);

  const orderedResults = orderBy(results, x => new Date(x.resultDate), 'desc');

  const result = results.length > 0
    ? results[currentIdx]
    : null;

  const [resultLoading, setResultLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);

  const loading = resultLoading || userLoading;

  useEffect(() => {
    if (userId == null) return;
    const cancelToken = axios.CancelToken.source();
    async function fetchResults() {
      setResultLoading(true);
      setResults([]);
      try {
        const results = await GetCaptureEventResults(userId);
        setCurrentIdx(0);
        setResults(results);
      } 
      catch (e) {
        if (axios.isCancel(e)) return;
        Logger.error(`Error: ${e}`);
      }
      setResultLoading(false);
    }
    fetchResults();
    return cancelToken.cancel;
  }, [userId]);

  useEffect(() => {
    if (userId == null) return;
    const cancelToken = axios.CancelToken.source();
    async function fetchUser() {
      setUserLoading(true);
      setUser(null);
      try {
        const user = await GetUser(userId, cancelToken);
        setUser(user);
      } 
      catch (e) {
        if (axios.isCancel(e)) return;
        Logger.error(`Error: ${e}`);
      }
      setUserLoading(false);
    }
    fetchUser();
    return cancelToken.cancel;
  }, [userId]);

  const onIdxChange = newIdx => setCurrentIdx(newIdx);

  // proxy for what needs to be passed in
  const eventUser = { user };

  return (
    <div className={classes.pageContainer}>      
      <Paper className={classes.orgUserPaper}>
        <div>
          {loading && <CircularProgress />}
          {!loading && <Select 
            value={currentIdx} 
            disableUnderline 
            onChange={(e) => onIdxChange(e.target.value)}
          >
            {orderedResults.map((item, idx) => (
              <MenuItem key={item.id} value={idx}>
                {formatReadableDateLongTime(item.resultDate)}
              </MenuItem>    
            ))}
          </Select>}

          {result != null && !loading && <PercentilesTable 
            captureResult={result} 
            title={`${result?.motionType ?? ''}  ${result?.handedness ?? ''}`}
          />}

          {result != null && !loading && <div className={classes.percentiles}>
            <BodyPercentiles
              captureResult={result} 
              eventUser={eventUser}
            />
          </div>}
        </div>
      </Paper>
    </div>
  );
};


export default BLAITrainingPlans;
