import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(_ => ({
  divider: {
    marginTop: '50px',
    marginBottom: '50px'
  },
  newGroupContainer: {
    display: 'flex'
  }
}));

export default useStyles;