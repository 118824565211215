import React from 'react';
import classNames from 'classnames';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class StyledSelect extends React.Component {
  render() {
    const { 
      classes, 
      className, 
      children, 
      value, 
      onChange,
      color,
      ...rest
    } = this.props;

    return <Select
      value={value} 
      onChange={onChange}
      className={classNames(classes.select, className)}
      classes={{ root: classes.selectRoot, icon: classes.selectIconColor }}
      style={{ '--color': color }}
      {...rest}
    >
      {children}
    </Select>;
  }
}

export default withStyles(styles)(StyledSelect);