import { cyan, blueGrey, green, red } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  averagesRow: {
    backgroundColor: blueGrey[50],
    '& td': {
      position: 'relative'
    }
  },
  bottomBorderThick: {
    borderBottom: '2px solid ' + blueGrey[700]
  },
  leftBorderThick: {
    borderLeft: '2px solid ' + blueGrey[700]
  },
  deltaRow: {
    backgroundColor: cyan[50],
    '& td': {
      position: 'relative'
    }
  },
  positiveItem: {
    color: green[900]
  },
  negativeItem: {
    color: red[900]
  },
  cellContent: {
    padding: '10px',
    textAlign: 'left'
  },
  focusSegment: {
    position: 'absolute',
    top: 2,
    bottom: 2,
    left: 2,
    right: 2,
    border: '2px solid ' + red[700]
  }
}));

export default useStyles;
