import React, { useState, useEffect, useMemo } from 'react';
import {
  FormControl,
  TextField,
  Divider,
  Select,
  InputLabel,
  MenuItem,
  FormGroup,
  Button,
  IconButton
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import { DRILL_VARIATIONS, LESSON_SEGMENT_TYPES } from '../templateEditor.constants';
import useStyles from './styles';

const CHECKPOINT = { name: 'Checkpoint Motions', id: 'checkpoint' };

function SegmentEditor({ segment, drills, onCancel, onSave, onDelete }) {
  const classes = useStyles();

  const initialLessonSegment = useMemo(() => ({ 
    type: LESSON_SEGMENT_TYPES.checkpointMotions, 
    ...segment 
  }), [segment]);
  const [lessonSegment, setLessonSegment] = useState(initialLessonSegment);

  useEffect(() => {
    setLessonSegment(initialLessonSegment);
  }, [initialLessonSegment]);

  const drillOptions = [
    CHECKPOINT,
    ...sortBy(drills, 'name')
  ];

  const getSelectedDrill = () => {
    const { type, drillId } = lessonSegment;
    if (type === LESSON_SEGMENT_TYPES.checkpointMotions) {
      return CHECKPOINT;
    }
    return drillOptions.find(x => x.id === drillId);
  };

  const onVariationChange = (key, value) => {
    setLessonSegment(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const onAutocompleteChange = (_, value) => {
    if (value.id === CHECKPOINT.id) {
      const nullifiedVariations = DRILL_VARIATIONS.reduce((acc, cur) => ({
        ...acc,
        [cur.key]: null
      }), {});
      setLessonSegment(prev => ({
        ...prev,
        ...nullifiedVariations,
        drillId: null,
        type: LESSON_SEGMENT_TYPES.checkpointMotions
      }));
    }
    else {
      setLessonSegment(prev => ({ 
        ...prev, 
        drillId: value.id,
        type: LESSON_SEGMENT_TYPES.drill
      }));
    }
  };

  return <FormGroup className={classes.outerContainer}>
    <div className={classes.innerContainer}>
      <div className={classes.buttonContainer}>
        <Autocomplete 
          value={getSelectedDrill()}
          options={drillOptions}
          onChange={onAutocompleteChange}
          disableClearable
          getOptionLabel={({ name }) => name}
          renderInput={props => <TextField 
            {...props} 
            variant='outlined' 
            label='Select Drill' 
          />}
          getOptionSelected={(option, value) => option.id === value.id}
          className={classes.drillAutocomplete}
        />
        {lessonSegment.id != null && <div>
          <IconButton onClick={() => onDelete(lessonSegment)}>
            <DeleteIcon />
          </IconButton>
        </div>}
      </div>

      <Divider />
      {lessonSegment.type !== LESSON_SEGMENT_TYPES.checkpointMotions 
      && DRILL_VARIATIONS.map(({ key, values }) => <FormControl
        key={key}
        className={classes.variationSelect}
      >  
        <InputLabel id={`${key}_select_label`}>{startCase(key)}</InputLabel>
        <Select
          labelId={`${key}_select_label`}
          label={startCase(key)}
          value={lessonSegment[key] ?? ''}
          onChange={e => onVariationChange(key, e.target.value)}
        >
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
          {values.map(value => <MenuItem key={`${key}_${value}`} value={value}>
            {startCase(value)}
          </MenuItem>)}
        </Select>
      </FormControl>)}
      <div className={classes.buttonContainer}>
        <Button variant='outlined' onClick={onCancel}>Cancel</Button>
        <Button 
          color='primary' 
          variant='outlined' 
          onClick={() => onSave(lessonSegment)}
        >
          Save
        </Button>
      </div>
    </div>
  </FormGroup>;
}

export default SegmentEditor;
