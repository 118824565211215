import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  fullWidth: {
    width: '100%'
  },
  saveButtonContainer: { 
    display: 'flex', 
    justifyContent: 'flex-end', 
    alignItems: 'center' 
  },
  focusCheckboxContainer: {
    display: 'flex'
  },
  noteDescriptionPreview: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3
  }
}));

export default useStyles;
