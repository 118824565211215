import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Grid, Fab } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import useRecentMotions from '../playerDataTabs/liveCapture/useRecentMotions';
import { history } from '../../store';
import SwingFeed from './swingFeed';
import useStyles from './styles';

/* This page is used by internal users that want to 
 * monitor all motions being captures grouped by serial code
 */
function SwingStream({ location }) {
  const [orgMotions, setOrgMotions] = useState([]);
  const classes = useStyles();

  const QueryParams = {
    serialCodes: 'serialCodeColumns'
  };

  let searchParams = new URLSearchParams(location.search);
  const serialCodeColumns = searchParams.getAll(QueryParams.serialCodes);

  // Each motion shows multiple graphs that all hold a lot of data.
  // There are better ways of containing the amount of components
  // that need to get rendered, but in the meantime we'll limit the nuber of
  // motions shown.
  const NUM_MOTIONS_TO_SHOW = 5;

  const currentOrg = useSelector(state => state.currentOrganization);
  let orgId = currentOrg.id;
  let allSerialCodes = [];

  if (currentOrg.organizationSerialCodes) {
    allSerialCodes = [...currentOrg.organizationSerialCodes.map(code => code.serialCode)];
  }
  
  useRecentMotions(setOrgMotions, null, orgId);

  const setSerialCodeColumns = useCallback((serialCodes) => {
    let searchParams = new URLSearchParams(location.search);
    searchParams.delete(QueryParams.serialCodes);
    serialCodes.forEach(code => { searchParams.append(QueryParams.serialCodes, code); });
    history.push({ 
      pathname: history.location.pathname, 
      search: searchParams.toString()
    });
  }, [QueryParams.serialCodes, location.search]);

  useEffect(() => {
    // automatically selects serial code for most 
    // recent motion if none have been selected yet
    if (serialCodeColumns.length === 0 && orgMotions.length > 0) {
      setSerialCodeColumns([orgMotions[0].serialCode]);
    }
  }, [orgMotions, serialCodeColumns, setSerialCodeColumns]);

  function setNewSerialCode(serialCode, idx) {
    const updatedCodes = Object.assign([], serialCodeColumns, { [idx]: serialCode });
    setSerialCodeColumns(updatedCodes);
  }

  function AddFeed(code) {
    let serialCodes = [...serialCodeColumns, code];
    setSerialCodeColumns(serialCodes);
  }

  function RemoveFeed(index) {
    setSerialCodeColumns(serialCodeColumns.filter((_, idx) => idx !== index));
  }

  return (
    <div className={classes.container}>
      <Grid container direction='column'>
        <Grid item>
          <Typography variant='h3'>Swing Stream</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.content} direction='row' justify='center' spacing={2}>
        {serialCodeColumns.map((serialCode, idx) => (
          <Grid item xs={5} key={serialCode}>
            <SwingFeed 
              motions={orgMotions
                .filter((motion) => motion.serialCode === serialCode)
                .slice(0, NUM_MOTIONS_TO_SHOW)}
              serialCode={serialCode}
              allSerialCodes={allSerialCodes}
              onSerialCodeChanged={(code) => setNewSerialCode(code, idx)}
              onClose={() => RemoveFeed(idx)}
            />
          </Grid>
        ))}
        <Grid item xs={2} className={classes.plusIcon}>
          <Fab onClick={() => AddFeed('')}>
            <PlusIcon/>
          </Fab>
        </Grid>
      </Grid>
    </div>
  );
}

export default SwingStream;