import { BLOCK_TYPE, INLINE_STYLE } from 'draftail';

export const MARKDOWN_BLOCK_TYPES = [
  BLOCK_TYPE.HEADER_ONE,
  BLOCK_TYPE.HEADER_TWO,
  BLOCK_TYPE.HEADER_THREE,
  BLOCK_TYPE.HEADER_FOUR,
  BLOCK_TYPE.HEADER_FIVE,
  BLOCK_TYPE.HEADER_SIX,
  BLOCK_TYPE.UNORDERED_LIST_ITEM,
  BLOCK_TYPE.ORDERED_LIST_ITEM
].map(block => ({ type: block }));

export const MARKDOWN_INLINE_STYLES = [
  INLINE_STYLE.BOLD, 
  INLINE_STYLE.ITALIC
].map(style => ({ type: style }));