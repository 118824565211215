import { labelPalette } from '../../utils/kAssessments.utils'; 

function getColorFromTag(tag) {
  return labelPalette[tag] == null ? '#ccc' : labelPalette[tag];
}

// NOTE: selectedLabelId is not the index in the array
function generateLabelPoints(labels = [], selectedLabelId) {

  /*
    const labelPoints = [
        {x: 1200, y: 500, label: 'distance', color: 'red', labelId: 0},
        {x: 1500, y: 400, label: 'jbc', color: 'green', labelId: 1},
    ];
    */
  
  const labelPoints = labels
    // Filter out anything without graph points so our selected index matches 
    .filter(({ graphPoints }) => {
      if(graphPoints == null || graphPoints.length === 0) {
        return false;
      }
      return true;
    })
    .map(({ name, id, tag, graphPoints }, index) => {
      const color = getColorFromTag(tag);
    
      return {
        ...graphPoints[0], // We only want the first graph point by convention
        color,
        labelId: id,
        label: name,
        isSelected: id === selectedLabelId,
        orderIndex: index
      };
    });
  
  let labelPointsHighlightedIndex = null; 
  labelPoints.forEach(({ isSelected, orderIndex }) => {
    if(isSelected) {
      labelPointsHighlightedIndex = orderIndex;
    }
  });
  
  return { labelPoints, labelPointsHighlightedIndex };
}
  
function generateMetricCellsData(labels = [], selectedLabelId){
  const metricTableCellsBackgroundColors = {
    /*
        torsoSideBendAtContact: ['red'],
        xFactorAtContact: ['green', 'red'],
        speedGainHandToArm: ['red', 'yellow', 'green'],
        transitionSequence: ['green'],
        */            
  };
    
  let selectedTableCellMetrics = [];
  labels.forEach((labelData) => {
    const color = getColorFromTag(labelData.tag);
    labelData.tableCells.forEach(({ cellType }) => {
      if(metricTableCellsBackgroundColors[cellType] == null) {
        metricTableCellsBackgroundColors[cellType] = [];
      }
      // Makes sure colors array doesn't contain duplicates of same color:
      // Implementation detail since we only allow 3 colors per cell.
      if(!metricTableCellsBackgroundColors[cellType].includes(color)) {
        metricTableCellsBackgroundColors[cellType].push(color);
      }
    });
    if(labelData.id === selectedLabelId) {    
      selectedTableCellMetrics = labelData.tableCells.map(({ cellType }) => cellType );
    }
  });
  return {
    metricTableCellsBackgroundColors,
    selectedTableCellMetrics
  };
}
    
function generateLabelDefinitions(labels = [], selectedLabelId) {
  let selectedLabelDefinitionIndex = null;
  const labelDefinitions = labels.map(({ id, name, description, tag }, index) => {
    const color = getColorFromTag(tag);
    // Maybe bad form to do both here:
    if (id === selectedLabelId) {
      selectedLabelDefinitionIndex = index;
    }
    return {
      title: name,
      description,
      color,
      labelId: id
    };
  });

  if (selectedLabelDefinitionIndex === null && labelDefinitions.length) {
    selectedLabelDefinitionIndex = 0;
  }

  return {
    labelDefinitions,
    selectedLabelDefinitionIndex
  };
}
  
function transformData(labels, selectedLabelId) {   
   
  const { labelPoints, labelPointsHighlightedIndex } = 
          generateLabelPoints(labels, selectedLabelId);    
  const {
    metricTableCellsBackgroundColors,
    selectedTableCellMetrics
  } = generateMetricCellsData(labels, selectedLabelId);
      
  const {
    labelDefinitions,
    selectedLabelDefinitionIndex
  } = generateLabelDefinitions(labels, selectedLabelId);
  
  return {
    labelPoints,
    labelPointsHighlightedIndex,
    metricTableCellsBackgroundColors,
    selectedTableCellMetrics,
    labelDefinitions,
    selectedLabelDefinitionIndex
  };
}

export default transformData;