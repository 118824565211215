import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import queryString from 'query-string';
import { fetchCaptureEvent, removeEventUser } from '../../../store/captureEvents';
import { formatMMDDYY } from '../../../utils/formatting.utils';
import useStyles from './styles';
import useExponentialBackoff from '../../../utils/useExponentialBackoff';
import EventUsersTable from './eventUsersTable';
import CaptureEventSyncComponent from './../captureEventSyncComponent';
import LargeNumberStat from '../../../components/largeNumberStat';
import { 
  ACTION_STATUSES, 
  CaptureEventTypes, 
  CaptureEventLatestRefreshStatus,
  TEST_CLIENT_USER_ID
} from '../../../constants/captureEvents.constants';
import { history } from '../../../store';
import { DeleteCaptureEventUser } from '../../../network/captureEventRequests';
import { GetMotionsForOrg } from '../../../network/motionRequests';
import { getOrganizationId } from '../../../utils/auth.utils';
import Logger from 'js-logger';
import axios from 'axios';
import { Button, Dialog } from '@material-ui/core';
import QRCode from 'react-qr-code';
import flatMap from 'lodash/flatMap';
import { formatDateWithSeconds } from 'utils/formatting.utils';

function RefreshStatusComponent({ 
  refreshStatus = null, 
  latestRefreshTimestamp = null 
}) {
  const classes = useStyles();

  const formattedDateString = latestRefreshTimestamp != null
    ? '| ' + formatDateWithSeconds(latestRefreshTimestamp)
    : '';

  if (refreshStatus === CaptureEventLatestRefreshStatus.SUCCESS) {
    return <Typography className={classes.successRefresh}>
      Latest Roster Pull Successful {formattedDateString}
    </Typography>;
  }

  if (refreshStatus === CaptureEventLatestRefreshStatus.NONPGTECHEVENT) {
    return <Typography color='error'>
      Event is Marked as Non-PGTech Event {formattedDateString}
    </Typography>;
  }

  if (refreshStatus === CaptureEventLatestRefreshStatus.UNKNOWNERROR) {
    return <Typography color='error'>
      Unknown Error Pulling Latest Rosters {formattedDateString}
    </Typography>;
  }

  return null;
}

function CaptureEventDetails({ captureEvent, loading, error }) {
  const classes = useStyles();
  const [errMsg, setErrMsg] = useState('');
  const [eventMotionsCount, setEventMotionsCount] = useState(null);
  const [showQRCode, setShowQRCode] = useState(false);
  const dashboardUrl = window.location.origin;
  const eventId = captureEvent.id;
  const isProcessing = captureEvent == null 
    || captureEvent.status === 'Processing'
    || (captureEvent.actions && 
        captureEvent.actions.some(action => action.status === ACTION_STATUSES.processing));
  
  const { pathname, search } = history.location;
  const searchParams = queryString.parse(search);
  const expandedUserId = searchParams.userId;

  const dispatch = useDispatch();
  const refreshCaptureEvent = useCallback(() => {
    dispatch(fetchCaptureEvent(eventId));
  }, [eventId, dispatch]);
  useExponentialBackoff(refreshCaptureEvent, { shouldRun: isProcessing, runImmediately: true });

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchEventMotions() {
      try {
        // since we only care about count, we can fetch 
        // a single motion and use the pagination data
        var motions = await GetMotionsForOrg(
          getOrganizationId(), 
          { captureEventId: captureEvent.id, count: 1 }, 
          cancelToken);
        setEventMotionsCount(motions.pagination.totalCount);
      } catch(e) {
        // If this should fail they'll just see a "-" for the stat.
        // This isn't a particularly important part of the page so we can leave
        // that as enough indication of failure with showing error messages.
        Logger.error('Error when attempting to fetch motions for the event', e);
      }
    }

    fetchEventMotions();
    return cancelToken.cancel;
  }, [captureEvent.id]);

  function getFormattedDate(dateString) {
    if (dateString == null) {
      return <Skeleton variant='text' width={50} />;
    }
    return formatMMDDYY(dateString);
  }

  async function deleteEventUser(eventUser) {
    if (window.confirm('Are you sure you want to remove this user from the event?')) {
      try {
        await DeleteCaptureEventUser(eventUser.captureEventId, eventUser.id);
        onUserClicked(eventUser.userId);
        dispatch(removeEventUser(eventUser.id, eventUser.captureEventId));
      } catch (e) {
        Logger.error('Error when attempting to delete a capture event user.', e);
        setErrMsg('The user was unable to be deleted.');
      }
    }
  }

  function onUserClicked(userId) {
    let searchParams = new URLSearchParams(search);
    userId === expandedUserId 
      ? searchParams.delete('userId') 
      : searchParams.set('userId', userId);
    
    history.push({ 
      pathname: pathname, 
      search: searchParams.toString()
    });
  }

  // do not want to count users that did not capture
  const numEventUsers = captureEvent.captureEventUsers
    ?.filter(user => user.captureEventResults.length > 0).length ?? '-';
  const numResults = flatMap(
    captureEvent.captureEventUsers ?? [], 
    user => user?.captureEventResults).length ?? '-';
  const numMotions = eventMotionsCount ?? '-';
  let totEventUsers = (captureEvent?.captureEventUsers ?? [])
    .filter(x => x.userId !== TEST_CLIENT_USER_ID)
    .length;

  const titleEl = () => {
    const externalId = captureEvent?.externalId;
    const title = externalId 
      ? captureEvent?.name + '(' + captureEvent?.type + ', external id: ' + externalId + ')'
      : captureEvent?.name + '(' + captureEvent?.type + ')';
    if(externalId != null 
      && (captureEvent?.type === CaptureEventTypes.perfectGameEvent 
      || captureEvent?.type === CaptureEventTypes.perfectGameTournament)) {
      const eventUrl = 
        `https://www.perfectgame.org/Events/Default.aspx?event=${externalId}`;
      return <a target='_blank' rel='noreferrer' href={eventUrl}>{title}</a>;
    } else {
      return title;
    }
  };

  const signupForCaptureUrl = `${dashboardUrl}/signup-for-capture/${captureEvent?.id}`
    + `/${encodeURIComponent(captureEvent?.name)}`;

  return <Grid container spacing={1} alignItems='stretch'>
    <Dialog open={showQRCode} onClose={() => setShowQRCode(null)}>   
      <div className={classes.qrContainer}>    
        <Typography className={classes.qrHeader} variant='h4'>
          Event Name: 
          <a href={signupForCaptureUrl} target='_blank' rel='noreferrer'>
            {captureEvent?.name}
          </a>
        </Typography>
        <QRCode
          className={classes.qrCode}
          value={signupForCaptureUrl}
        />
      </div>  
    </Dialog>   

    <Typography color='error'>{errMsg}</Typography>
    <Grid item xs={12}>
      <Paper className={classes.paperContainer}>
        <div className={classes.paperContent}>
          <Typography variant='h5'>
            {loading && captureEvent.name == null 
              ? <Skeleton variant='text' />
              : titleEl()}
            <Button onClick={()=> {
              setShowQRCode(true);
            }}>QR Code</Button>
          </Typography>
          <div className={classes.dateContainer}>
            <Typography>{getFormattedDate(captureEvent.startDate)}</Typography>
            <Typography>&nbsp;-&nbsp;</Typography>
            <Typography>{getFormattedDate(captureEvent.endDate)}</Typography>
          </div>
        </div>
        <Grid container justify='space-evenly'>
          <LargeNumberStat title='Total User Records' value={totEventUsers} />
          <LargeNumberStat title='Users Captured' value={numEventUsers} />
          <LargeNumberStat title='Results Created' value={numResults} />
          <LargeNumberStat title='Motions Captured' value={numMotions} />
        </Grid>
        {captureEvent != null 
          && captureEvent.latestRefreshStatus != null
          && <div className={classes.rosterRefreshStatus}>
            <RefreshStatusComponent 
              refreshStatus={captureEvent?.latestRefreshStatus} 
              latestRefreshTimestamp={captureEvent?.latestRefreshAttemptTimestamp}
            />
          </div>}
      </Paper>
    </Grid>
    <Grid item xs={12}>
      <div>
        {captureEvent.captureEventUsers != null 
          && isProcessing 
          && <div>
            <Typography className={classes.status} variant='body2'>
              This event is still processing...
            </Typography>
            <LinearProgress color='secondary' />
          </div>}

        {error && <Typography className={classes.status} variant='body2' color='error'>
          There was an error fetching details for this event.
        </Typography>}
        
        {!error 
          && !loading 
          && !isProcessing 
          && captureEvent.captureEventUsers
          && captureEvent.captureEventUsers.length === 0 
          && <Typography className={classes.status} variant='body2'>
            There are no users associated with this event.
          </Typography>}
      </div>

      {captureEvent.captureEventUsers &&
        <EventUsersTable
          captureEvent={captureEvent}
          captureEventUsers={captureEvent.captureEventUsers} 
          eventActions={captureEvent.actions}
          onNewAction={refreshCaptureEvent}
          onUserClicked={onUserClicked}
          expandedUserId={expandedUserId}
          onDeleteUser={deleteEventUser}
        />
      }
    </Grid>
    <Grid item xs={12}>
      <Paper>
        <CaptureEventSyncComponent
          loading={loading}
          captureEvent={captureEvent}
        />
      </Paper>
    </Grid>
  </Grid>;
}

export default CaptureEventDetails;