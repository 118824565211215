import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DragAndDrop from '../imageDragDrop';
import IconButton from '@material-ui/core/IconButton';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function ImageSelectionDialog({
  classes,
  open,
  onSave,
  onCancel,
  images, 
  attemptUploadImage, 
  setSnackbarSucess 
}) {
  const [ selectedImages, setSelectedImages ] = useState(images);

  const imageUpload = async (files) => {
    if (files.length === 0) return;
    // Helper function handles file upload exceptions
    let newImages = await Promise.all(Array.from(files).map(attemptUploadImage));
    let successfulImages = newImages.filter(x => x != null);
    setSnackbarSucess(successfulImages.length === newImages.length);
    setSelectedImages(prevImages => ([...prevImages, ...successfulImages]));
  };

  return <div>
    <Dialog open={open} fullWidth maxWidth='sm'>
      <DialogContent className={classes.dialogContent}>
        <input id='imageInput' type='file' 
          onChange={(e) => imageUpload(e.target.files)} 
          className={classes.hideButton}
        />
        <DragAndDrop className={classes.uploadImagesContainer}
          onClick={() => document.getElementById('imageInput').click()}
          handleDrop={imageUpload}
        >
          <CloudUploadIcon className={classes.uploadDesc}/>
          <Typography className={classes.uploadDesc}>
            &nbsp;DROP IMAGE HERE OR CLICK TO&nbsp;
            <span className={classes.browseLink}>
              BROWSE
            </span>
          </Typography>
        </DragAndDrop>
        <GridList cellHeight={160} className={classes.selectedImagesContainer} cols={3}>
          {selectedImages.map((tile) => (
            <GridListTile key={tile.id} className={classes.imageTile}>
              <img src={tile.url} alt=''/>
              <GridListTileBar
                titlePosition='top'
                actionIcon={
                  <IconButton 
                    onClick={() => {
                      setSelectedImages(selectedImages.filter(x => x.id !== tile.id));
                    }}
                    className={classes.icon}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                actionPosition='left'
                className={classes.titleBar}
              />
            </GridListTile>
          ))}
        </GridList>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onSave(selectedImages)} color='primary'>Save</Button>
      </DialogActions>
    </Dialog>
  </div>;
};

export default withStyles(styles)(ImageSelectionDialog);
