import Modes from '../constants/modes.constants';

// Actions
export const SET_CURRENT_MODE = 'global/mode';


// Reducer
const initialMode = Modes.baseball;

export default function reducer(state = initialMode, action) {
  switch(action.type) {
    case SET_CURRENT_MODE:
      return action.mode;
    default:
      return state;
  }
};

// Action Creators
export function setCurrentMode (mode) {
  return { type: SET_CURRENT_MODE, mode };
};
