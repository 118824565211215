import { makeStyles } from '@material-ui/core/styles';
import Palette from 'styles/palette';

const useStyles = makeStyles(() => ({
  selectNewStatusContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  option: {
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    paddingLeft: '5px',
    paddingRight: '5px',
    justifyContent: 'space-between'
  },
  selected: {
    backgroundColor: Palette.mediumGray
  }
}));

export default useStyles;
