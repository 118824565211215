import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: ({ height }) => ({
    width: '100%',
    height: '100%',
    /* override custom css here as per draftail docs */
    '& .public-DraftEditor-content': {
      maxHeight: height != null ? `${height}px` : null,
      height: height != null ? `${height}px` : null
    }
  })
}));

export default useStyles;
