import { makeStyles } from '@material-ui/styles';
import palette from '../../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  container: ({ height }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  request: {
    backgroundColor: palette.bluePurple,
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.bluePurple,
      filter: 'brightness(80%)'
    },
    marginRight: '5px'
  },
  create: {
    '&:hover': {
      backgroundColor: palette.lightGray,
      filter: 'brightness(80%)'
    }
  },
  button: {
    padding: '1px'
  },
  buttonText: {
    fontSize: '14px'
  }
}));

export default useStyles;