import { makeStyles } from '@material-ui/core/styles';
import Palette from '../../../../styles/palette';

export default makeStyles(() => ({
  toolbar: {
    paddingLeft: '15px',
    paddingRight: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 3
  },
  headerLabels: {
    display: 'flex',
    alignItems: 'center'
  },
  processingLabelIcon: {
    color: Palette.brightBlue,
    marginLeft: '10px'
  },
  selectedLabel: {
    marginLeft: '30px'
  },
  successIcon: {
    color: Palette.success
  },
  errorIcon: {
    color: Palette.error
  },
  warningIcon: {
    color: Palette.warning
  },
  processingIcon: {
    color: Palette.brightBlue
  },
  reviewIcon: {
    color: Palette.warning
  },
  CreateButton: {
    marginRight: '15px'
  },
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: Palette.lightBlue
    },
    '& > *': {
      borderBottom: 'unset'
    }
  },
  userNameButton: {
    textAlign: 'left',
    whiteSpace: 'nowrap'
  },
  linearProgress: {
    flex: 1,
    margin: '0 20px'
  },
  resultsCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  inspectionIconContainer: {
    '&:hover': {
      backgroundColor: Palette.lightGray,
      cursor: 'pointer'
    },
    marginLeft: '10px'
  },
  inspectionIcon: {
    color: Palette.fadedGrey
  },
  error: {
    backgroundColor: Palette.errorLessSevere
  },
  warning: {
    backgroundColor: Palette.wateredDownMustard
  },
  borderLeft: {
    borderLeft: '2px dashed ' + Palette.midnightBlue
  },
  expandableDetailsCell: {
    paddingBottom: 0,
    paddingTop: 0
  },
  topLeftInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '18px'
  },
  exitButton: {
    marginRight: '15px'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  reviewDialog: {
    height: '90vh'
  }
}));