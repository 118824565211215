import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import {
  Button,
  Grid,
  Collapse,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Tooltip,
  Select,
  MenuItem
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ProgressButton from '../../../../components/progressButton';
import StyledFileInput from './../../../../components/styledFileInput';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';

import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './dateRangeSyncStyleOverrides.css';

import SOURCES from '../../../../constants/thirdPartyDataSources.constants';
import { PostSyncAttempt } from '../../../../network/captureEventRequests';
import { useDispatch } from 'react-redux';
import { addSingleSyncAttempt } from '../../../../store/captureEvents';

import log from 'js-logger';

const SyncTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: '250px',
    fontSize: '14px'
  }
}))(Tooltip);

function TextFieldIntegerInput({
  label,
  value,
  onChange,
  tooltip
}) {
  const classes = useStyles();
  return <>
    <TextField
      className={classes.inputZIndexFix}
      value={value}
      variant='outlined'
      label={label}
      type='number'
      onChange={onChange}
    />
    <SyncTooltip
      placement='top'
      title={tooltip}>
      <HelpIcon
        color='primary'
        className={classes.help}
      />
    </SyncTooltip>
  </>;
}

const INITIAL_DATE_RANGE = { startDate: null, endDate: null };

function CreateSyncAttempt({ captureEvent }) {
  const classes = useStyles();

  const [syncThreshold, setSyncThreshold] = useState('');
  const [adjustMinutes, setAdjustMinutes] = useState('');
  const [adjustSeconds, setAdjustSeconds] = useState('');

  const [creatingSync, setCreatingSync] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedSerialCode, setSelectedSerialCode] = useState(null);
  const [sourceType, setSourceType] = useState(SOURCES.TRACKMAN);
  // date picker
  const [dateFocusedInput, setDateFocusedInput] = useState(null);
  const [dateSelectionRange, setDateSelectionRange] = useState(INITIAL_DATE_RANGE);

  const { 
    organizationSerialCodes, 
    loading: organizationLoading 
  } = useSelector(state => state.currentOrganization) || {};

  const dispatch = useDispatch();

  const createSyncAttempt = async () => {
    if (loading) return;

    setError(null);

    // validate inputs first
    const isInvalidTimeInput = value => !/^-?\d+$/.test(value);

    const adjustMinutesTrimmed = adjustMinutes.trim();
    if (adjustMinutesTrimmed && isInvalidTimeInput(adjustMinutesTrimmed)) {
      return setError('Adjustable Minutes has to be a whole number');
    }

    const adjustSecondsTrimmed = adjustSeconds.trim();
    if (adjustSecondsTrimmed && isInvalidTimeInput(adjustSecondsTrimmed)) {
      return setError('Adjustable Seconds has to be a whole number');
    }

    const syncThresholdTrimmed = syncThreshold.trim();
    if (syncThresholdTrimmed && !/^\d+$/.test(syncThresholdTrimmed)) {
      return setError('Sync Threshold has to be a positive whole number.');
    }

    // send request after input validation
    setLoading(true);

    // set optional parameters
    const optional = {};
    if (syncThresholdTrimmed) optional.syncThreshold = parseInt(syncThresholdTrimmed);
    if (adjustMinutesTrimmed) optional.adjustableMinutes = parseInt(adjustMinutesTrimmed);
    if (adjustSecondsTrimmed) optional.adjustableSeconds = parseInt(adjustSecondsTrimmed); 
    const { startDate, endDate } = dateSelectionRange;
    if (startDate) optional.startDate = startDate.toDate().toISOString();
    if (endDate) optional.endDate = endDate.toDate().toISOString();

    try {
      const newSyncAttempt = await PostSyncAttempt(
        captureEvent.id,
        file,
        selectedSerialCode,
        sourceType,
        optional
      );
      dispatch(addSingleSyncAttempt(newSyncAttempt));
      setCreatingSync(false);
    } catch(e) {
      log.error('Error posting a sync attempt', e);
      setError('There was an error creating a sync attempt');
    }
    setLoading(false);
  };

  // reset form
  useEffect(() => {
    setLoading(false);
    setFile(null);
    setSelectedSerialCode(null);
    setSyncThreshold('');
    setAdjustMinutes('');
    setAdjustSeconds('');
    setDateFocusedInput(null);
    setDateSelectionRange(INITIAL_DATE_RANGE);
    setError(null);
  }, [creatingSync]);

  const serialCodes = useMemo(() => {
    if (!organizationSerialCodes) return [];
    return organizationSerialCodes.map(x => x.serialCode);
  }, [organizationSerialCodes]);

  const saveDisabled = useMemo(() => {
    return !file || !selectedSerialCode;
  }, [file, selectedSerialCode]);

  return <div>
    <Collapse in={creatingSync}>
      <div className={classes.expandedContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.fileInput}>
              <StyledFileInput 
                name='SyncData'
                label={!file ? 'Upload Data File' : 'Upload New Data File'}
                onChange={csv => setFile(csv[0])}
                accept='.xlsx, .xls, .csv'
              />

              <div className={classes.fileName}>
                {file ? file.name : 'No Data File Selected'}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              loading={organizationLoading}
              options={serialCodes}
              value={selectedSerialCode}
              onChange={(_, newValue) => setSelectedSerialCode(newValue)}
              renderInput={params => <TextField 
                {...params} 
                label='Select Serial Code' 
                variant='outlined' 
              /> }
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangePicker 
              startDate={dateSelectionRange.startDate}
              startDateId='sync-start-date'
              endDate={dateSelectionRange.endDate}
              endDateId='sync-end-date'
              onDatesChange={setDateSelectionRange}
              focusedInput={dateFocusedInput}
              onFocusChange={setDateFocusedInput}
              isOutsideRange={() => false}
              minimumNights={0}
            />
          </Grid>
          <Grid className={classes.textField} item xs={12}>
            <TextFieldIntegerInput
              label='Adjust Minutes'
              value={adjustMinutes}
              tooltip=
                {
                  'The amount of minutes to offset the kcoach timestamps when doing ' +
                  'the sync process. You can set this to accommodate differences in ' +
                  'time zones or clocks if needed.'
                }
              onChange={(e) => setAdjustMinutes(e.target.value)}
            />
          </Grid>
          <Grid className={classes.textField} item xs={12}>
            <TextFieldIntegerInput
              label='Adjust Seconds'
              value={adjustSeconds}
              tooltip=
                {
                  'The amount of seconds to offset the kcoach timestamps when doing ' +
                  'the sync process. You can set this to accommodate differences in ' +
                  'time zones or clocks if needed.'
                }
              onChange={(e) => setAdjustSeconds(e.target.value)}
            />
          </Grid>
          <Grid className={classes.textField} item xs={12}>
            <TextFieldIntegerInput
              label='Sync Seconds Threshold'
              value={syncThreshold}
              tooltip=
                {
                  'This determines how close two records need to be in order to ' + 
                  'be considered a match. Default is three seconds.'
                }
              onChange={(e) => setSyncThreshold(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant='outlined'>
              <InputLabel className={classes.dropdownLabel} id='source-type-label'>Source</InputLabel>
              <Select
                labelId='source-type-label'
                value={sourceType}
                onChange={(e) => setSourceType(e.target.value)}
                label='Source'
              >
                {[SOURCES.TRACKMAN, SOURCES.POCKET_RADAR].map(source => (
                  <MenuItem key={source} value={source}>{source}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {error && <Typography color='error'>
              {error}
            </Typography>}
          </Grid>
          <Grid item container spacing={1} justify='flex-end'>
            <Grid item>
              <Button onClick={() => setCreatingSync(false)}>Cancel</Button>
            </Grid>
            <Grid item>
              <ProgressButton 
                onClick={createSyncAttempt} 
                color='primary'
                variant='outlined'
                showProgress={loading}
                disabled={saveDisabled}
              >
                Sync Data
              </ProgressButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Collapse>

    <Collapse in={!creatingSync}>
      <Button onClick={() => setCreatingSync(true)} color='primary' variant='outlined'>
        Sync New Event Data
      </Button>
    </Collapse>
  </div>;
};

export default CreateSyncAttempt;