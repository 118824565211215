import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(_ => ({
  container: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  card: {
    padding: 20,
    margin: 'auto',
    maxWidth: '1000px'
  },
  downloadText: {
    display: 'flex',
    alignItems: 'center'
  },
  manageButton: {
    display: 'flex',
    margin: 'auto'
  },
  planName: {
    fontSize: '1.8rem'
  },
  manageContainer: {
    marginTop: '15px'
  },
  storeImg: {
    width: '100%',
    maxWidth: '250px'
  },
  divider: {
    marginTop: '10px'
  },
  upgradeProducts: {
    marginTop: '10px',
    marginBottom: '40px',
    paddingLeft: '20px',
    paddingRight: '20px'
  }
}));