import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  viewMargin: ({ topPadding }) => ({
    marginTop: topPadding
  }),
  chooseIdContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  circularProgress: {
    top: '50%',
    left: '50%',
    zIndex: 100,
    position: 'absolute',
    marginTop: '-23px',
    marginLeft: '-8px'
  }
}));

export default useStyles;