import { makeStyles } from '@material-ui/core/styles';
import palette from '../../../styles/palette';
import { calculateFontSize } from './../styles';

export const useClasses = makeStyles(() => ({
  dialog: {
    backgroundColor: palette.green,
    color: palette.white
  },
  title: {
    backgroundColor: palette.green,
    color: palette.white,
    borderBottom: `1px solid ${palette.white}`,
    paddingBottom: '5px',
    paddingTop: '5px'
  },
  titleText: {
    fontSize: calculateFontSize(3)
  },
  bold: {
    fontWeight: 'bold'
  },
  content: {
    backgroundColor: palette.green,
    color: palette.white
  },
  closeButton: {
    width: '30px',
    height: '30px',
    color: palette.white
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  titleWithExit: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  selectedRow: {
    backgroundColor: palette.fadedGrey
  }
}));

export default useClasses;