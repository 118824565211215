import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class TeamListItem extends React.Component {
  render() {
    const { classes, team } = this.props;
    return (
      <div className={classes.container}>
        <Typography align='left' className={classes.name}>{team.name}</Typography>
        <Typography align='left' className={classes.description}>{team.description}</Typography>
      </div>
    );
  }
}

TeamListItem.props = {
  team: PropTypes.string.isRequired
};

export default withStyles(styles)(TeamListItem);