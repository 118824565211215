const styles = {
  container: {
    position: 'relative',
    borderRadius: '2px',
    backgroundColor: 'lightgray'
  },
  bar: {
    position: 'absolute', 
    left: 0, 
    top: 0, 
    height: '100%', 
    borderRadius: '2px',
    transition: 'all .5s ease-in-out',

    // overriden by progress bar div
    width: '100%',
    zIndex: 2
  }
};

export default styles;
