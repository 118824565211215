import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(_ => ({
  buttonContainer: {
    alignSelf: 'flex-end'
  },
  button: {
    height: '40px',
    marginLeft: '5px'
  }
}));