import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dialog: {
    width: '50vw',
    maxWidth: '650px'
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  field: {
    marginBottom: '25px'
  },
  smallInput: {
    maxWidth: '150px',
    minWidth: '100px'
  },
  measurements: {
    display: 'flex',
    flexDirection: 'row'
  },
  marginRight: {
    marginRight: '15px'
  },
  communicationEmailButtons: {
    display: 'flex',
    flexDirection: 'row'
  },
  useLoginButton: {
    whiteSpace: 'nowrap',
    marginRight: '10px'
  }
}));

export default useStyles;
