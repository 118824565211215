import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  cardContainer: {
    maxWidth: '500px',
    marginTop: '30px',
    paddingBottom: '40px',
    flex: 1,
    paddingRight: '3%',
    paddingLeft: '3%'
  },
  nextButton: {
    width: '200px'
  },
  cardActions: {
    justifyContent: 'space-around',
    marginBottom: '15px',
    paddingTop: '0px'
  },
  logo: {
    marginTop: '20px',
    marginLeft: '20px'
  },
  resetPasswordText: {
    marginBottom: '25px'
  },
  showResetPwdButton: {
    marginTop: '15px'
  },
  backButton: {
    paddingLeft: '0px'
  },
  introduction: {
    marginBottom: '15px'
  },
  datePicker: {
    margin: 0,
    width: '100%'
  }
}));