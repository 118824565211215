import React, { useState } from 'react';
import useStyles from './styles';

import { 
  Button,
  Typography
} from '@material-ui/core';

import AddLabelDialog from '../../addLabelDialog';

function LabelTag({ children, onDelete, data }) {
  const classes = useStyles();
  return <span className={classes.labelTagContainer}>
    <span className={classes.labelName}>##{children}</span>
    <span 
      className={classes.removeLabelButton} 
      onClick={() => onDelete(data)}>
              X
    </span>
  </span>;
}

function AnalystHeader({ labels = {}, motionData, onLabelChange, userId, disable }) {
  const classes = useStyles();

  const [openAddLabel, setOpenAddLabel] = useState(false);

  const openLabelDialog = () => {
    setOpenAddLabel(true);
  };

  const closeLabelDialog = () => {
    setOpenAddLabel(false);
  };

  const onDelete = (label) => {
    onLabelChange('delete', label);
  };

  const onAdd = (label) => {
    onLabelChange('add', label);
    closeLabelDialog();
  };

  return <div className={classes.container}>
    <Button disabled={disable} className={classes.button} onClick={openLabelDialog}>
      <Typography className={classes.buttonText}>ADD LABEL</Typography>
    </Button>
    <div className={classes.labels}>
      {Object.keys(labels).sort().map((label, idx) => {
        return <LabelTag
          onDelete={onDelete}
          data={labels[label]}
          key={idx}>
          {label}
        </LabelTag>;
      })}
    </div>

    <AddLabelDialog open={openAddLabel}
      userId={userId}
      motionData={motionData}
      onAddLabel={onAdd}
      onClose={closeLabelDialog}/>
  </div>;
}

export default AnalystHeader;