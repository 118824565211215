import React from 'react';
import PropTypes from 'prop-types';
import { flatMap, minBy, maxBy, min, max } from 'lodash';
import { 
  VictoryChart,
  VictoryGroup,
  VictoryLine, 
  VictoryAxis, 
  VictoryLabel, 
  VictoryLegend,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryCursorContainer
} from 'victory';
import styles from './styles';
import VerticalLines from './verticalLines';
import { TimeSeriesColorScale } from './lineGraph.constants';

/* Shows a basic line graph with data and colors passed in as props.
 * This graph is built on top of the VictoryCharts library and is rendered 
 * entirely through svg elements.
 * There are a couple of gotchas with svg being the underlying technology behind these components.
 *  - The styles are not css but instead based on the styling that svg uses
 *  - The height and width are not the css size displayed but 
 *    instead affect the drawing cavas for the elements.
 *    Providing larger numbers will not make the graph bigger but 
 *    will make it appear more zoomed out. 
 *
 * Note: There is a current limitation in that in order for the legend
 * to show properly centered, it expects 4 different lines to be passed in.
 */
const LEGEND_HEIGHT = 30;
const LEGEND_WIDTH = 375; // This is a rough estimate based on 4 body parts
const TITLE_OFFSET = 30;

class LineGraph extends React.Component {
  
  // returns array where first item is the min and second is the max
  _findMinAndMaxYValues = (lines) => {
    let allPoints = flatMap(lines, 'data');
    return [minBy(allPoints, 'y').y, maxBy(allPoints, 'y').y];
  }

  _findMinAndMaxTickValues = (ticks) => [min(ticks), max(ticks)];

  render() {
    try {  
      const { 
        dataLines, 
        verticalLines, 
        title, 
        showLegend,
        svgHeight,
        svgWidth,
        yTickValues,
        // Note: graph onClick and onHover only works when cursorcontainer
        // (showCrosshairs) is enabled/showing.
        
        // Bug: click events are not showing up for scatter plot 
        // when we have the cross hairs enabled.
        // Maybe has to do with order of rendering but probably can't change that for this
        // Kinda weird that VictoryChart is returned for containerComponent when we pass in false
        // but it doesn't work if we pass in a div, etc.
        showCrosshairs,
        showCurrentXY,
        onGraphHover = () => {},
        onGraphClick = () => {},
        // You can:
        // - pass in color per scatter point
        // - pass in which scatter point is shown
        scatteredDots = [],        
        onScatterHover = () => {},
        onScatterClick = () => {}
      } = this.props;

      if (dataLines == null) {
        return <VictoryGroup height={svgHeight} width={svgWidth} />;
      }

      const [minDataY, maxDataY] = this._findMinAndMaxYValues(dataLines);
      const [minTick, maxTick] = this._findMinAndMaxTickValues(yTickValues);

      let tickValues, verticalLineStart, verticalLineEnd;
      if (!yTickValues || maxDataY > maxTick) {
        // not setting tickValues, this will default to let victory handle the axis values
        verticalLineStart = minDataY;
        verticalLineEnd = maxDataY;
      } else {
        tickValues = [...yTickValues];
        verticalLineStart = minTick;
        verticalLineEnd = maxTick;
      }

      const legendData = dataLines.map(line => ({ 
        name: line.name, 
        symbol: { fill: line.color }
      }));

      const cursorProps = {};
      if(!showCrosshairs){
      // This is how we hide the crosshairs but still show the label:
        cursorProps.cursorDimension = null;
      }

      return (
        <div style={styles.container}>
          <VictoryChart 
            events={[{
              target: 'parent',
              eventHandlers: {
                onClick: (eventData) => {
                  onGraphClick(eventData);
                }
              }
            }]}
            theme={VictoryTheme.material}
            height={svgHeight} 
            width={svgWidth} 
            style={{ ...styles.victoryChart, ...this.props.style }} 
            domainPadding={{ y: [30, 40] }}
            padding={{ left: 60, top: 50, right: 50, bottom: 50 }}
            containerComponent={showCrosshairs || showCurrentXY ? <VictoryCursorContainer
              {...cursorProps}
              cursorLabel={({ datum }) => {
                onGraphHover(datum);
                return `${Math.round(datum.x, 2)}ms, ${Math.round(datum.y, 2)}`;}}/>
              : <VictoryChart /> /*this is needed or it breaks onClick above at parent level */}> 
                      
            {/* Y Axis */}
            <VictoryAxis 
              style={styles.axis} 
              dependentAxis 
              crossAxis={false} 
              tickValues={tickValues}
            />
            
            {/* X Axis */}
            <VictoryAxis 
              style={styles.axis} 
              tickFormat={(t) => `${t}ms`} 
              tickLabelComponent={<VictoryLabel textAnchor='start'/>}
              crossAxis={false} 
              offsetY={70} // drop axis below 0 to not overlap with negative values
            />

            {
              title && 
              <VictoryLabel 
                text={title} 
                textAnchor='middle' 
                x={svgWidth / 2} 
                y={TITLE_OFFSET} 
                style={styles.title}
              />
            }
          
            { /* Markers e.g (CON, FM, HS) */
              verticalLines &&
              <VerticalLines
                data={verticalLines.map(line => line)}
                minYValue={verticalLineStart}
                maxYValue={verticalLineEnd}
              />
            }

            { /* Main data lines (toros, pelvis, etc) */
              this.props.dataLines.map((line, idx) => (
                <VictoryLine
                  key={`${line.name}-dataline-${idx}`} 
                  style={{
                    data: { 
                      stroke: line.color ? line.color : TimeSeriesColorScale[idx],
                      strokeWidth: line.strokeWidth
                    },
                    parent: { border: '1px solid #ccc' }
                  }}
                  data={line.data}
                />))
            }

            {showLegend && 
              <VictoryLegend 
                orientation='horizontal' 
                data={legendData}
                width={svgWidth}
                height={svgHeight} 
                x={svgWidth / 2 - (LEGEND_WIDTH / 2)} 
                y={svgHeight - LEGEND_HEIGHT}
                gutter={20}
                style={styles.legend}
              />
            }      
    
            {
              /* Outline for the selected label dot: */
              scatteredDots
                .filter(({ isSelected }) => isSelected)
                .map((data, index) => {
                  return <VictoryScatter
                    key={`scatter-v2-${index}`}
                    style={{ data: { fill: '#000' }}}
                    size={10}
                    data={[{ ...data, label: null }]}
                  />;
                })
            }

            { /* Loop through labels and add dots: */
              scatteredDots.map((data, index) => {
                return <VictoryScatter
                  key={`scatter-${index}`}
                  labelComponent={<VictoryTooltip/>}
                  style={{ data: { 
                    fill: data.color == null ? '#000' : data.color 
                  }}}
                  size={8}
                  events={[{
                    target: 'data',
                    eventHandlers: {
                      onClick: (eventData) => {
                        onScatterClick(eventData, index);                      
                      },
                      onMouseOver: (eventData) => {
                        onScatterHover(eventData, index);
                        return {
                          target: 'labels',
                          mutation: () => ({ active: true })
                        }; },    
                      onMouseOut: (eventData) => {
                        onScatterHover(eventData, null); 
                        return {
                          target: 'labels',
                          mutation: () => ({ active: false })
                        }; }     
                    }
                  }]}
                  data={[data]}
                />;
              })
            }
          </VictoryChart>
        </div>
      );
    } 
    catch(e) {
      return <div> - </div>;
    }
  }
}

/* Example input for complex types
    dataLines: [
      {
        data: [{x: 1, y: 1}, {x: 2, y: 2}],
        name: 'Line Name',
        color: '#FFFFFF'
      }
    ],
    verticalLines: [
      {
        name: 'Line1', 
        x: 0
      }
    ]
*/
LineGraph.propTypes = {
  dataLines: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    })).isRequired, // data to populate data lines
    name: PropTypes.string, // this is the name that will appear in the legend if it shows
    color: PropTypes.string
  })),
  verticalLines: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string, // label that appears above line, should be a few characters max
    x: PropTypes.number // the x value where the vertical line should be drawn 
  })),
  title: PropTypes.string,
  showLegend: PropTypes.bool,
  legendOffset: PropTypes.number, // adjusts the x position of the legend. My be required for different graph sizes. 
  svgHeight: PropTypes.number,
  svgWidth: PropTypes.number,
  yTickValues: PropTypes.array, // what shows on the y axis,
  showCrosshairs: PropTypes.bool,
  showCurrentXY: PropTypes.bool,
  onGraphHover: PropTypes.func,
  onGraphClick:PropTypes.func,
  scatteredDots: PropTypes.arrayOf(PropTypes.object),        
  onScatterHover: PropTypes.func,
  onScatterClick: PropTypes.func
};

LineGraph.defaultProps = {
  svgHeight: 450, // Can be changed but tested with a 1.75 
  svgWidth: 800, //   width to height ratio,
  showCrosshairs: true
};

export default LineGraph;