import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Plan from './plan';
import useStyles from './styles';
import { openActionPlan } from '../../../store/actionPlan';
import NoDataMessage from '../../../components/noDataMessage';
import useCurrentPlayerName from '../../../utils/useCurrentPlayerName';
import VideoPreviewDialog from '../../../components/dialogs/videoPreviewDialog';
import TEXT_TYPES from '../../../constants/actionPlanTextTypes.constants';

function ActionPlans() {
  const dispatch = useDispatch();
  const { loading, data } = useSelector(state => state.notes);
  const playerName = useCurrentPlayerName();
  const [selectedDrillVideo, setSelectedDrillVideo] = useState(null);
  const classes = useStyles();

  const onVideoDialogClose = useCallback(() => setSelectedDrillVideo(null), []);

  return <div>
    <div>
      <Button 
        onClick={() => dispatch(openActionPlan())}
        className={classes.button}
      >
        <Typography className={classes.buttonText}>Create</Typography>
      </Button>
    </div>
    <Typography className={classes.previousPlans}>Previous Action Plans</Typography>
    {loading && <CircularProgress />}
    {data.map(note => {
      return <Plan 
        key={note.id} 
        onClickVideo={setSelectedDrillVideo} 
        {...note} 
        text={note.textFormatType === TEXT_TYPES.MARKDOWN ? note.formattedText : note.text}
      />;
    })}
    {(!loading && data.length === 0) && <NoDataMessage>
      There are currently no action plans for {playerName}.
    </NoDataMessage>}
    <VideoPreviewDialog 
      open={selectedDrillVideo != null} 
      onClose={onVideoDialogClose}
      video={selectedDrillVideo}
    />
  </div>;
}

export default ActionPlans;