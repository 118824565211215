import React from 'react';
import logger from 'js-logger';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { history } from '../../store';
import queryString from 'query-string';
import Analytics from '../../services/analytics';

import KGoAppStoreIcon from '../../assets/KGoAppStoreIcon.png';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { getDeviceType, DEVICE } from '../../utils/device.utils';

const EVENT_NAME = 'Notification Opened';

class KGoRedirect extends React.Component {
  state = { 
    loading: true,
    isDesktop: false,
    redirectFromSetPassword: false
  }

  async componentDidMount() {
    var device = getDeviceType();
    var queryParams = queryString.parse(this.props.location.search, { parseBooleans: true });
    const { redirectFromSetPassword, redirectFromEmailVerification } = queryParams;
    if (device === DEVICE.other) {
      this.setState({ isDesktop: true, redirectFromSetPassword });
      return;
    }

    if (!redirectFromSetPassword && !redirectFromEmailVerification) {
      this._trackOnMixpanel(device, queryParams);
    }

    if (queryParams.download) {
      this._redirectToAppStore(device);
    } else {
      setTimeout(() => this.setState({ loading: false }), 2000);
      window.location = 'kgo://';
    }
  }

  _trackOnMixpanel = (device, params) => {
    var { userEmail, userId, download, data } = params;
    var jsonData = {};
    try {
      jsonData = JSON.parse(data);
    }
    catch {
      logger.error(`Error parsing notification data as json: ${data}`);
    }
    var eventBody = { 
      ...jsonData, 
      'clientSource_': 'email', 
      userEmail, 
      userId, 
      downloadApp: download,
      OS: device
    };

    Analytics.track({ 
      eventName: EVENT_NAME, 
      miscData: eventBody, 
      userEmail, 
      userId,
      project: Analytics.PROJECTS.KGO 
    });
  }

  _redirectToAppStore = (device) => {
    if (device === DEVICE.android) {
      window.location = 'https://play.google.com/store/apps/details?id=com.kmotion.kgo.prod&hl=en_US';
    } else if (device === DEVICE.iOS) {
      window.location = 'https://apps.apple.com/us/app/kgo/id1462670999';
    } else {
      history.replace('/');
    }
  }
  
  render() {
    const { classes } = this.props;
    const { loading, isDesktop, redirectFromSetPassword } = this.state;
    return isDesktop 
      ? <Dialog open={true}>
        <DialogTitle align='center'>Login To KGo</DialogTitle>
        <DialogContent>
          <div className={classes.dialogContent}>
            <div className={classes.imgContainer}>
              <img src={KGoAppStoreIcon} alt='' className={classes.img}/>
            </div>
            <Typography>
              Looks like you're on a computer. At this time data is only available from the KGo 
              mobile application. In order to view your data, login 
              {redirectFromSetPassword ? ' with your new password' : '' } to
              the app directly or click the email links from your mobile device.
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
      : <div className={classes.container}>
        <img src={KGoAppStoreIcon} alt='' className={classes.img}/>
        {loading
          ? <CircularProgress />
          : <Button 
            variant='outlined' 
            color='primary' 
            onClick={() => this._redirectToAppStore(getDeviceType())}
          >Install KGo</Button>
        }
        <div className={classes.placeholder}/>
      </div>;
  }
}

export default withStyles(styles)(KGoRedirect);
