import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/HighlightOff';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { WhiteRadio, CustomFormControlLabel } from './../../styles';
import BadgeDisplayContents from './badgeDisplayContents';

import useStyles from './styles';

function RadioOptions({
  id,
  title = '',
  options = [],
  onChange,
  value = null
}) {
  const classes = useStyles();

  const handleChange = (__, selected) => onChange(id, selected);

  return <div className={classes.radioContainer}>
    <FormControl component='fieldset'>
      <FormLabel classes={{ root: classes.titleRoot }} component='legend'>{title}</FormLabel>
      <RadioGroup row aria-label={title} name={title} value={value} onChange={handleChange}>
        {
          options.map((label) => {
            return <CustomFormControlLabel
              key={label}
              value={label} 
              control={<WhiteRadio />} 
              label={label}
            />;
          })
        }
      </RadioGroup>
    </FormControl>
  </div>;
}

const SCREEN_TYPES = {
  INNINGS: 'Innings',
  BADGES: 'Badges'
};

const FILTER_TYPES = {
  EVERYONE: 'Everyone',
  YOU: 'You'
};

const OPTION_IDS = {
  SCREEN: 'SortBy',
  FILTER: 'ShowFor'
};

const OPTIONS = [
  {
    id: OPTION_IDS.SCREEN,
    title: 'Sort By',
    options: [SCREEN_TYPES.INNINGS, SCREEN_TYPES.BADGES]
  },
  {
    id: OPTION_IDS.FILTER,
    title: 'Show For',
    options: [FILTER_TYPES.EVERYONE, FILTER_TYPES.YOU]
  }
];

function BadgeDisplay({
  currentGameNumber = null,
  displayBadges = false,
  badgeFeed,
  idMap = null,
  curInterval,
  currentLeaderboard,
  onClose = () => {}
}) {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState(() => {
    return OPTIONS.reduce((initialState, { id, options }) => {
      initialState[id] = options[0];
      return initialState;
    }, {});
  });

  const optionSelected = (id, selected) => {
    setSelectedOptions(prevOptions => {
      return { ...prevOptions, [id]: selected };
    });
  };

  return (
    <>
      <Dialog 
        fullScreen
        open={displayBadges}
        className={classes.dialog}
      >
        <DialogTitle disableTypography className={classes.title}>
          <div className={classes.titleContainer}>
            <div className={classes.titleWithExit}>
              <div className={classes.titleText}>
                <span className={classes.bold}>
                  Game {currentGameNumber} Badges
                </span>
              </div>
              <IconButton 
                aria-label='close'
                onClick={onClose}>
                <CloseIcon className={classes.closeButton} />
              </IconButton>
            </div>
            <div className={classes.options}>
              {
                OPTIONS.map(({ id, title, options }) => {
                  return <RadioOptions
                    id={id}
                    key={id}
                    title={title}
                    options={options}
                    onChange={optionSelected}
                    value={selectedOptions[id]}
                  />;
                })
              }
            </div>
          </div>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <BadgeDisplayContents
            feed={badgeFeed}
            idMap={idMap}
            curInterval={curInterval}
            currentLeaderboard={currentLeaderboard}
            screen={selectedOptions[OPTION_IDS.SCREEN]}
            filter={selectedOptions[OPTION_IDS.FILTER]}
            SCREENS={SCREEN_TYPES}
            FILTERS={FILTER_TYPES}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BadgeDisplay;