import React, { useState, useEffect } from 'react';
import KinematicSequenceGraphWithLabels from '../kinematicSequenceGraphWithLabels';
import KinematicDetailsDense from '../kinematicDetailsDense';
import LabelDefinition from '../labelDefinition';
import { Paper } from '@material-ui/core';
import transformData from './transformData';
import styles from './styles';

function LabelViewer(props){
  const { selectedLabelIdInitial, labelData = [] } = props;

  // Outline:
  // 1) When we hover over a graphPoint
  // -  We want to know which table cells to highlight
  // - which definition to focus on

  // 2) When we hover over a table cell, nothing for now.
    
  // 3) When we click over a definition:
  //  - We want to know which graph point to highlight
  //  - Which defnition to highlight

  // 4) We need to have something that generates our data to each component
    
  const [lastLabelIdClicked, setLastLabelIdClicked] = useState(selectedLabelIdInitial);
  const [currentLabelIdHovered, setCurrentLabelIdHovered] = useState(null);
    
  useEffect(()=> {
    setLastLabelIdClicked(selectedLabelIdInitial);
  }, [selectedLabelIdInitial]);

  let selectedLabelId = currentLabelIdHovered;
  if (currentLabelIdHovered == null) {
    selectedLabelId = lastLabelIdClicked;
  } 
  const {
    labelPoints,
    labelPointsHighlightedIndex,
    metricTableCellsBackgroundColors,
    selectedTableCellMetrics,
    labelDefinitions,
    selectedLabelDefinitionIndex
  } = transformData( labelData, selectedLabelId );

  return <Paper style={styles.root}>
    <KinematicSequenceGraphWithLabels
      {...props}
      initialLabelSelected={labelPointsHighlightedIndex}
      labelPoints={labelPoints}     
      onScatterHover={(data) => {
        if(data == null) {
          setCurrentLabelIdHovered(null);
        } else {
          setCurrentLabelIdHovered(data.labelId);
        }
      }}
      onScatterClick={(data) => {
        if(data != null) {
          setLastLabelIdClicked(data.labelId);
        }

      }}                               
    />
    <KinematicDetailsDense
      {...props}
      enableHovering={true}
      selectedMetrics={selectedTableCellMetrics}
      metricsBackgroundColors={metricTableCellsBackgroundColors}
    />
    <LabelDefinition 
      selectedIndex={selectedLabelDefinitionIndex}
      labels={labelDefinitions} 
      onClick={(label) => {
        setLastLabelIdClicked(label.labelId);
      }} />
  </Paper>;
};

export default LabelViewer;
