import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  header: {
    margin: '20px'
  },
  searchBarContainer: {
    width: '100%',
    backgroundColor: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 2
  }
}));

export default useStyles;
