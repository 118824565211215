const styles = {
  header: {
    display: 'flex',
    alignItems: 'center' 
  },
  selectButton: {
    margin: '15px'
  }
};

export default styles;