import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import log from 'js-logger';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlayIcon from '@material-ui/icons/PlayArrow';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import useStyles from './styles';
import { DeleteAnalysis, GetAnalysis } from '../../../../network/videoAnalysisRequests';
import { ANALYSIS_STATUS } from '../../../../constants/videoAnalysis.constants';
import { formatDate } from '../../../../utils/formatting.utils';
import { deleteVideoAnalysis, updateVideoAnalysis } from '../../../../store/videoAnalyses';

function AnalysisHistoryListItem({ 
  selected, 
  onClick, 
  onPreviewVideoClick, 
  analysis, 
  timestamp, 
  status 
}) {
  const analysisId = analysis.id;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const motionAttributes = useSelector(state => state.motionAttributes);
  const selectedAttribute = analysis.selectedMotionAttributeId &&
    motionAttributes.find(attribute => attribute.id === analysis.selectedMotionAttributeId);

  const classes = useStyles();

  useEffect(() => {
    const token = axios.CancelToken.source();
    async function fetchFullAnalysis() {
      setError(false);
      setLoading(true);

      try {
        const fullAnalysis = await GetAnalysis(analysisId);
        dispatch(updateVideoAnalysis(fullAnalysis));
        setLoading(false);
      }
      catch (e) {
        if (axios.isCancel(e)) return;
        setLoading(false);
        setError(true);
        log.error(e, `Error loading analysis with id ${analysisId}`);
      }
    }
    fetchFullAnalysis();

    return token.cancel;
  }, [analysisId, dispatch]);

  function onThumbnailClick(e, video) {
    onPreviewVideoClick(video);
    e.stopPropagation();
  }

  async function onDeleteClick() {
    var result = window.confirm('Are you sure you want to delete this analysis?');
    if (result) {
      try {
        setDeleting(true);
        await DeleteAnalysis(analysisId);
        dispatch(deleteVideoAnalysis(analysisId));
      }
      catch (e) {
        log.error(e, `Error deleting analysis with id ${analysisId}`);
        setDeleting(false);
      }
    }
  }

  function renderVideoTiles() {
    // Default data to show two video skeletons during loading
    let videosToDisplay = [{ id: 0, status: 'Pending' }, { id: 1, status: 'Pending' }];

    let statusText = [];
    let showTitleAsChip = false;

    if (error) {
      return <div className={classes.statusNoteContainer}>
        <Typography variant='caption' color='error'>
          There was an error getting analysis videos, refresh to try again.
        </Typography>    
      </div>;
    }

    if (!loading) {
      switch(status) {
        case (ANALYSIS_STATUS.requested): 
          videosToDisplay = [];
          statusText = [
            'Request has been sent.', 
            'Awaiting swing videos from the player.'
          ];
          break;
        case (ANALYSIS_STATUS.submitted):
          statusText = [
            'Videos from player have been received but are still ' +
            'being processed. Check back in later to start annotating.'
          ];
          videosToDisplay = analysis.videoAnalysisVideos.map(vav => vav.video);
          break;
        case (ANALYSIS_STATUS.readyForAnnotation):
          videosToDisplay = analysis.videoAnalysisVideos.map(vav => vav.video);
          statusText = ['Awaiting', 'Analysis'];
          break;
        case (ANALYSIS_STATUS.annotated):
        case (ANALYSIS_STATUS.readyForActionPlan):
        case (ANALYSIS_STATUS.completed):
          videosToDisplay = analysis.videoAnnotations.map(annotation => annotation.analyzedVideo);
          showTitleAsChip = true;
          break;
        default:
          break;
      };
    }

    let listItems = videosToDisplay.map((video, idx) => <div key={`video-tile-${video.id}-${idx}`}>
      <div className={classNames(classes.thumbnailContainer, { 
        [classes.blackBackground]: video.status === 'Available'
      })}>
        {video.status !== 'Available'
          ? <div className={classes.videoSkeletonContainer}>
            <Skeleton variant='rect' className={classes.thumbnail} animation='wave' />
            {!loading && <div className={classes.processingText}>
              <Typography variant='overline' align='center'>
                Processing...
              </Typography>
            </div>}
          </div>
          : <div 
            onClick={e => onThumbnailClick(e, video)} 
            className={classes.thumbnailImgContainer}
          >
            <img src={video.thumbnailUrl} alt='video thumbnail' className={classes.thumbnail} />
            <div className={classes.playIconContainer}>
              <PlayIcon className={classes.playIcon} />
            </div>
          </div>}
      </div>
      
      <div className={classes.thumbnailTitleContainer}>
        {loading
          ? <Skeleton variant='text' width='60%'/> 
          : showTitleAsChip
            ? <Chip label={video.title} size='small' classes={{ root: classes.titleChip }} />
            : <Typography variant='caption' align='center'>{video.title}</Typography>}
      </div>
    </div>);

    if (statusText.length > 0) {
      listItems.push(<div 
        className={classes.statusNoteContainer} 
        key={`requested-note-${analysisId}`}
      >
        {statusText.map((text, idx) => <Typography
          key={`analysis-${analysisId}-status-text-${idx}`}
          variant='caption'
        >
          {text}
        </Typography>)}     
      </div>);
    }

    return listItems;
  }

  const listItemBodyClassName = classNames(classes.listItemBody, {
    [classes.listItemSelected]: selected
  });

  return <ListItem 
    key={analysisId} 
    onClick={onClick}
    onMouseEnter={() => setHovering(true)}
    onMouseLeave={() => setHovering(false)}
  >
    <div className={listItemBodyClassName}>
      <div className={classes.timestamp}>
        <Typography>
          {formatDate(timestamp)}
        </Typography>
        {selectedAttribute &&
          <Typography className={classes.attributeName}>
            {selectedAttribute.name}
          </Typography>
        }
      </div>

      <div className={classes.tilesContainer}>
        {renderVideoTiles()}
      </div>
    </div>

    <ListItemSecondaryAction onMouseEnter={() => setHovering(true)}>
      {deleting
        ? <CircularProgress />
        : hovering && !loading && <IconButton edge='end' aria-label='delete' onClick={onDeleteClick}>
          <DeleteIcon />
        </IconButton>
      }
    </ListItemSecondaryAction>
  </ListItem>;
}

AnalysisHistoryListItem.propTypes = {
  selected: PropTypes.bool, 
  onClick: PropTypes.func.isRequired, 
  onPreviewVideoClick: PropTypes.func.isRequired,
  analysis: PropTypes.object.isRequired, 
  timestamp: PropTypes.string, 
  status: PropTypes.string
};

export default AnalysisHistoryListItem;
