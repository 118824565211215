import { useState, useEffect } from 'react';
import axios from 'axios';
import logger from 'js-logger';

/*
  This hook will handle making requests to an endpoint that returns paged responses.
  Input:
    networkRequest -> Async function to make the network call.
    params -> Optional object with params for the network call. Paging params will be overwritten.
    pageSize -> The number of items to show per page.
    pageNumber -> The page to get items for.

  NetworkRequest and params are dependencies in the effect and should be 
  memoized using useCallback and useMemo to avoid inifinite loops.

  Returns an object with various properties about the paged data. 
  Check out initial state for more details.
*/
const initialState = {
  items: [],               // Items within the current page
  totalCount: null,        // Total number of items on the server.
  totalPages: null,        // Total number of pages available.
  currentPage: null,       // Current 1-based page number that is being displayed.
  startIndex: 0,           // 1-Based index of the first item in the current page.
  endIndex: 0,             // 1-Based index of the last item in the current page.
  hasMore: false,          // Boolean to indicate a next page exists.
  hasPrev: false,          // Boolean to indicate a previous page exists.
  refreshData: () => {},   // Async function to refresh data and clears cache.
  isFetching: true,        // Boolean that indicates a network request is in progress.
  isInitialFetching: true  // Boolean that indicates the first fetch is in progress.
};

const usePagedEndpoint = (networkRequest, params, pageSize, pageNumber) => {
  const [ itemsByPage, setItemsByPage ] = useState({});
  const [ currentPage, setCurrentPage ] = useState(null);
  const [ totalCount, setTotalCount ] = useState(null);
  const [ totalPages, setTotalPages ] = useState(null);
  const [ isInitialFetching, setIsInitialFetching ] = useState(true);
  const [ isFetching, setIsFetching ] = useState(true);
  const [ pagedData, setPagedData ] = useState(initialState);

  useEffect(() => {
    // clear cache if params have changed
    setItemsByPage({});
  }, [networkRequest, params]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    if (itemsByPage[pageNumber]) {
      // can return early if we already have the page data cached
      setCurrentPage(pageNumber);
      return;
    }

    async function setPage() {
      var startIndex = (pageNumber - 1) * pageSize;
      var requestParams = { ...params, startIndex, count: pageSize };
      setIsFetching(true);
      try {
        const data = await networkRequest(requestParams, cancelToken);
        if (!data.pagination) {
          throw new Error('endpoint did not return a paginated response');
        }
        setItemsByPage(prev => ({ ...prev, [pageNumber]: data.items }));
        setTotalCount(data.pagination.totalCount);
  
        const totalPageNum = Math.ceil(data.pagination.totalCount / pageSize);
        setTotalPages(totalPageNum);
        setCurrentPage(pageNumber);
      } catch (e) {
        if (!axios.isCancel(e)) {
          logger.error('Error fetching items', e);
        }
      } finally {
        setIsFetching(false);
        setIsInitialFetching(false);
      }
    }
    setPage();

    return cancelToken.cancel;
  }, [networkRequest, params, pageSize, pageNumber, itemsByPage]);

  useEffect(() => {
    async function refreshData() {
      setIsInitialFetching(true);
      setItemsByPage({});
    }

    const endIndex = Math.min((currentPage - 1) * pageSize + pageSize, totalCount);
    var newPagedData = {
      items: itemsByPage[currentPage] ? itemsByPage[currentPage] : [],
      totalCount,
      totalPages,
      currentPage: currentPage,
      startIndex: ((currentPage - 1) * pageSize) + 1,
      endIndex,
      hasMore: endIndex < totalCount,
      hasPrev: currentPage !== 1,
      refreshData,
      isFetching,
      isInitialFetching
    };
    setPagedData(newPagedData);
  }, [
    itemsByPage, 
    currentPage, 
    totalCount, 
    totalPages, 
    isInitialFetching, 
    isFetching, 
    pageSize 
  ]);

  return pagedData;
};

export default usePagedEndpoint;