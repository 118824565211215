import format from 'date-fns/format';

const simpleFormatter = (value) => { return value; };
const decimalFormatter = (value) => { return value.toFixed(2); };
const dateFormatter = (value) => { 
  var date = new Date(value);
  return format(date, 'MM/dd/yy h:mm aa');
};

const BaseballSummaryData = [
  {
    key: 'firstName',
    displayName: 'First Name',
    formatter: simpleFormatter
  },
  {
    key: 'lastName',
    displayName: 'Last Name',
    formatter: simpleFormatter
  },
  {
    key: 'timestamp',
    displayName: 'Timestamp',
    dataType: 'date',
    formatter: dateFormatter
  },
  {
    key: 'xFactorStretch',
    displayName: 'X-Factor Stretch',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'heelStrikeToContactTime',
    displayName: 'HS to CON Time',
    formatter: decimalFormatter,
    units: 'Seconds'
  },
  {
    key: 'heelStrikeToFirstMoveTime',
    displayName: 'HS to FM Time',
    formatter: decimalFormatter,
    units: 'Seconds'
  },
  {
    key: 'firstMoveToContactTime',
    displayName: 'FM to CON Time',
    formatter: decimalFormatter,
    units: 'Seconds'
  },
  {
    key: 'maxXFactor',
    displayName: 'Max X-Factor',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'maxXFactorFrame',
    displayName: 'Max X-Factor Frame',
    formatter: simpleFormatter
  },
  {
    key: 'speedGainTorsoToPelvis',
    displayName: 'Peak Speed Gain: Torso to Pelvis',
    formatter: decimalFormatter
  },
  {
    key: 'speedGainArmToTorso',
    displayName: 'Peak Speed Gain: Arm to Torso',
    formatter: decimalFormatter
  },
  {
    key: 'speedGainHandToArm',
    displayName: 'Peak Speed Gain: Hand to Arm',
    formatter: decimalFormatter
  },
  {
    key: 'peakSpeedPelvis',
    displayName: 'Peak Speed Pelvis',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'peakSpeedTorso',
    displayName: 'Peak Speed Torso',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'peakSpeedUpperArm',
    displayName: 'Peak Speed Upper Arm',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'peakSpeedHand',
    displayName: 'Peak Speed Hand',
    formatter: simpleFormatter,
    units: 'Degrees/Seconds'
  },
  {
    key: 'peakSpeedFramePelvis',
    displayName: 'Peak Speed Frame Pelvis',
    formatter: simpleFormatter
  },
  {
    key: 'peakSpeedFrameTorso',
    displayName: 'Peak Speed Frame Torso',
    formatter: simpleFormatter
  },
  {
    key: 'peakSpeedFrameUpperArm',
    displayName: 'Peak Speed Frame Upper Arm',
    formatter: simpleFormatter
  },
  {
    key: 'peakSpeedFrameHand',
    displayName: 'Peak Speed Frame Hand',
    formatter: simpleFormatter
  },
  {
    key: 'xFactorAtHeelStrike',
    displayName: 'HS X Factor',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisBendAtHeelStrike',
    displayName: 'HS Pelvic Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisRotationAtHeelStrike',
    displayName: 'HS Pelvic Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisSideBendAtHeelStrike',
    displayName: 'HS Pelvic Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoBendAtHeelStrike',
    displayName: 'HS Torso Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoRotationAtHeelStrike',
    displayName: 'HS Torso Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoSideBendAtHeelStrike',
    displayName: 'HS Torso Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'xFactorAtFirstMove',
    displayName: 'FM X Factor',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisBendAtFirstMove',
    displayName: 'FM Pelvic Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisRotationAtFirstMove',
    displayName: 'FM Pelvic Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisSideBendAtFirstMove',
    displayName: 'FM Pelvic Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoBendAtFirstMove',
    displayName: 'FM Torso Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoRotationAtFirstMove',
    displayName: 'FM Torso Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoSideBendAtFirstMove',
    displayName: 'FM Torso Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'xFactorAtContact',
    displayName: 'CON X Factor',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisBendAtContact',
    displayName: 'CON Pelvic Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisRotationAtContact',
    displayName: 'CON Pelvic Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'pelvisSideBendAtContact',
    displayName: 'CON Pelvic Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoBendAtContact',
    displayName: 'CON Torso Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoRotationAtContact',
    displayName: 'CON Torso Rotation',
    formatter: decimalFormatter,
    units: 'Degrees'
  },
  {
    key: 'torsoSideBendAtContact',
    displayName: 'CON Torso Side Bend',
    formatter: decimalFormatter,
    units: 'Degrees'
  }
];

// Filter out: firstName, lastName
const nonGraphableValues = ['firstName'];
const graphableValuesList = BaseballSummaryData.filter((row) => {
  return !nonGraphableValues.includes(row.key);
});

export const BaseballSwingSummaryGraphableValues = graphableValuesList;

const baseballSummaryMap = {};

BaseballSummaryData.forEach((row) => {  
  baseballSummaryMap[row.key] = row;
});

export const BaseballSummaryToMap = baseballSummaryMap;
export const BaseballSummaryUtilList = BaseballSummaryData;

const metricValueBlacklist = [
  'timestamp', 
  'maxXFactorFrame', 
  'peakSpeedFramePelvis', 
  'peakSpeedFrameTorso', 
  'peakSpeedFrameUpperArm', 
  'peakSpeedFrameHand', 
  'lastName'];
export const BaseballSwingProgressMetrics = graphableValuesList.filter((row) => {
  return !metricValueBlacklist.includes(row.key);
});