import React from 'react';
import { Redirect } from 'react-router-dom';
import Logger from 'js-logger';
import axios from 'axios';
import { CreateUser, VerifyEmailAddress } from '../../network/userRequests';
import CreateAccountPage from '../../components/createAccountPage';
import queryString from 'query-string';
import AuthenticationService from '../../services/authentication';
import { GetActionToken } from '../../network/actionTokenRequests';
import { history } from '../../store';
import { USER_ROLE } from '../../constants/auth.constants';
import EmailVerificationSuccess from '../emailVerificationSuccess';
import AuthorizationPageWrapper from '../../components/authorizationPageWrapper';

class CreateAccount extends React.Component {
  authenticationService = new AuthenticationService();
  cancelToken = axios.CancelToken.source();
  state = {
    verifyingToken: true,
    email: null,
    token: null,
    isLoading: true,
    hasError: false,
    redirectToLogin: false,
    expiredToken: false,
    isPlayer: false,
    emailVerified: false
  }

  async componentDidMount() {
    const encodedTokenString = this.props.match.params.token;
    let token;
    try {
      token = this.authenticationService.decodeJwtToken(encodedTokenString);
    } catch (e) {
      // token can not be deserialized, bump to login.
      this.setState({ redirectToLogin: true });
      return;
    }

    try {
      // If this call succeeds, there is an existing account with an email to be verified
      // rather than an account needing to be created. A 404 means that no associated
      // user was found.
      await VerifyEmailAddress(token.primarysid, encodedTokenString);
      this.setState({ emailVerified: true });
      return;
    } catch (e) {
      if (e.response && e.response.status !== 404) {
        Logger.error(e, 'Error validating email address.');
      }
    }

    try 
    {  
      const isPlayer = token.role === USER_ROLE;
      const actionTokenRecord = await GetActionToken(
        token.primarysid, encodedTokenString, this.cancelToken);
      if (actionTokenRecord.used) {
        this.setState({ redirectToLogin: true });
        return;
      }

      // Check if token is expired
      var exp = new Date(0);
      exp.setUTCSeconds(token.exp);
      exp < Date.now()
        ? this.setState({ expiredToken: true, token: encodedTokenString, isLoading: false })
        : this.setState({ 
          email: token.email, 
          token: encodedTokenString, 
          isLoading: false, 
          isPlayer 
        });
    } catch (err) {
      if (axios.isCancel) return;
      Logger.error('Error when validating token: ', err);
      this.setState({ redirectToLogin: true });
    }
  }

  _redirect = () => history.push('/');

  createAccountSubmit = async (firstName, lastName, password, dateOfBirth, gender) => {
    this.setState({ isLoading: true });
    var { email, token } = this.state;
    var params = {
      email,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      password,
      dateOfBirth,
      gender 
    };

    try {
      await CreateUser(params, token);
      this.authenticationService.loginProgrammatically(email, password, () => this.setState({
        redirectToLogin: true,
        hasError: false,
        isLoading: false
      }), '/');
    }
    catch (err) {
      this.setState({
        hasError: true,
        isLoading: false
      });
    }
  }

  render() {
    if(this.state.redirectToLogin) {
      return (
        <Redirect to={{
          pathname: '/login',
          search: '?' + queryString.stringify({ email: this.state.email }),
          state: { from: { pathname: '/', hash: '', search: '', state: undefined }}
        }}/>
      );
    }

    if (this.state.emailVerified) {
      return <AuthorizationPageWrapper>
        <EmailVerificationSuccess />
      </AuthorizationPageWrapper>;
    }

    return (
      <CreateAccountPage 
        email={this.state.email} 
        hasError={this.state.hasError}
        resetError={() => this.setState({ hasError: false, expiredToken: false })}
        isLoading={this.state.isLoading}
        expiredToken={this.state.expiredToken}
        onSubmit={this.createAccountSubmit}
        verifyingToken={this.state.verifyingToken} 
        isPlayer={this.state.isPlayer} />
    );
  }
}

export default CreateAccount;