import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import keyBy from 'lodash/keyBy';
import LineGraph from '../lineGraph';
import { FrameNumToMs } from '../../utils/motionGraph.utils';
import MetricSelection from './metricSelection';
import { TimeSeriesColorScale } from '../lineGraph/lineGraph.constants';

function ConfigurableTimeseriesGraph({ 
  motions, 
  verticalLines, 
  graphableMetrics,
  disabled,
  activeMotionId,
  activeStrokeWidth = 5,
  getMotionColor
}) {
  const selectedGraphMetrics = useSelector(state => state.selectedGraphMetrics);
  const [hoveredMetricKey, setHoveredMetricKey] = useState(null); 
  
  const getLineColor = metricKey => {
    const colorIdx = selectedGraphMetrics.indexOf(metricKey);
    return colorIdx === -1 ? 'black' : TimeSeriesColorScale[colorIdx % selectedGraphMetrics.length];
  };

  const getLineDataForMotion = motion => {
    if (motion.analyzedData == null) return null;

    const metricLookup = keyBy(graphableMetrics, 'key');
    return selectedGraphMetrics
      .filter(key => metricLookup[key] != null)
      .map(key => ({ 
        name: motion.id + '-' + metricLookup[key].displayName, 
        color: getMotionColor ? getMotionColor(motion) : getLineColor(key),
        strokeWidth: motion.id === activeMotionId || hoveredMetricKey === key 
          ? activeStrokeWidth 
          : undefined,
        data: motion.analyzedData.map((frame, idx) => ({ 
          y: frame[key], 
          x: FrameNumToMs(idx)
        }))
      }));
  };

  const dataLines = motions.flatMap(getLineDataForMotion).filter(x => x != null);

  return <Paper>
    <LineGraph 
      dataLines={dataLines.length > 0 ? dataLines : null} 
      title='Time Series Graph'
      verticalLines={verticalLines}
    />
    <MetricSelection 
      allMetrics={graphableMetrics}
      isDisabled={disabled}
      useMetricColors={getMotionColor == null}
      onLabelMouseEnter={setHoveredMetricKey}
      onLabelMouseLeave={() => setHoveredMetricKey(null)}
    />
  </Paper>;
}

export default ConfigurableTimeseriesGraph;
