import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../store';
import { withStyles } from '@material-ui/core/styles';
import { 
  AppBar, 
  Typography, 
  Toolbar, 
  Button, 
  Tabs, 
  Tab 
} from '@material-ui/core';
import Styles from './styles';
import MediaQuery from 'react-responsive';

import Logo from '../../assets/KCloud-Connect-white-caps.png';
import MiniLogo from '../../assets/Mini-Logo.png';

import { Logout, IsLoggedIn, GetSportTypes, hasOrgRole } from '../../utils/auth.utils';
import { setCurrentMode } from '../../store/currentMode';
import Modes from '../../constants/modes.constants';

class Header extends React.Component {
  modeChange = (_event, value) => {
    this.props.setMode(value);
  }

  hideModeTab = () => {
    var sportTypes = GetSportTypes();
    if (sportTypes.includes('Golf') && sportTypes.includes('Baseball')) {
      // Hide mode tab if not on one of these pages or if at a path that is
      // explicitely for baseball
      return !(this.props.pathname.indexOf('players') >= 0 || 
        this.props.pathname.indexOf('summaries') >= 0 ||
        this.props.pathname.indexOf('profile') >= 0
      ) || this.props.pathname.indexOf('baseball') >= 0;
    }
    if (sportTypes.includes('Golf')) {
      this.modeChange(null, 'Golf');
    } else if (sportTypes.includes('Baseball')) {
      this.modeChange(null, 'Baseball');
    }
    return true;
  }

  navigateToSettings = () => {
    history.push('/player-management');
  }

  render() {
    const { classes } = this.props;
    return <MediaQuery minWidth={768}>
      {isDesktop => <header className={classes.root}>
        <AppBar className={classes.appBar} position='static'>
          <Toolbar>
            <Typography color='inherit' variant='h4' className={classes.title}>                    
              <Button className={classes.headerLink} onClick={()=>history.push('/')}>                              
                <img src={isDesktop ? Logo : MiniLogo} alt='K-Motion' height={60} className={isDesktop ? classes.img : null}/>
              </Button>                                   
            </Typography>
            {this.hideModeTab() ? null :  
              <Tabs value={this.props.mode} onChange={this.modeChange}>
                <Tab value={Modes.baseball} label={Modes.baseball}/>
                <Tab value={Modes.golf} label={Modes.golf}/>
                <Tab value={Modes.baseballPitch} label={Modes.baseballPitch}/>
              </Tabs> 
            }

            <Button className={classes.link} onClick={()=>history.push('/')}>Home</Button>
            { hasOrgRole() && 
              <Button className={classes.link} onClick={this.navigateToSettings}>
                Settings
              </Button>}
            { IsLoggedIn() && 
              <Button onClick={() => Logout()} className={classes.link}>
                Logout
              </Button>}
          </Toolbar>
        </AppBar>
      </header>}
    </MediaQuery>;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMode: mode => {
      dispatch(setCurrentMode(mode));
    }
  };
};

const mapStateToProps = state => {
  return { mode: state.currentMode };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(Styles)(Header));