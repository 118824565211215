import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import queryString from 'query-string';

import { getRolesForLoggedInUser, IsLoggedIn, getFeaturePermissions } from '../utils/auth.utils';
import { ALL_ROLES } from '../constants/auth.constants';

const PrivateRoute = ({ component: Component, roles, featurePermission, ...rest }) => (
  <Route {...rest} render={props => {
    const { location } = props;

    if (!IsLoggedIn()) {
      // If password token is supplied, route to page to allow user to set password and 
      // finish setting up account rather than login
      const { passwordToken } = queryString.parse(location?.search);
      const redirectPathname = passwordToken ? '/set-password/' + passwordToken : '/login';

      return <Redirect to={{
        pathname: redirectPathname,
        state: { from: location }
      }} />;
    }

    const userRoles = getRolesForLoggedInUser();
    const features = getFeaturePermissions();

    // Checked if passed in roles contains a match with logged in user roles
    if ((roles.includes(ALL_ROLES) || _.intersection(userRoles, roles).length > 0) &&
        (featurePermission == null || features.includes(featurePermission))) {
      return <Component {...props} />; 
    }
    return <Redirect to={{
      pathname: '/forbidden',
      state: { from: location }
    }}
    />;
  }} />
);

PrivateRoute.propTypes = { 
  roles: PropTypes.array.isRequired,
  featurePermission: PropTypes.string 
};

export default PrivateRoute;