import React from 'react';
import PropTypes from 'prop-types';
import { 
  Snackbar, 
  SnackbarContent, 
  Typography, 
  withMobileDialog, 
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Error } from '@material-ui/icons';
import styles from './styles';

const MESSAGE_ID = 'error-message-id';

class ErrorSnackbar extends React.Component {
  render() {
    const { message, onClose, anchorOrigin, classes, fullScreen } = this.props;
    return fullScreen
      ? <Dialog open={message != null} onClose={onClose}>
        <DialogContent>
          <Typography className={classes.dialogContent}>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Ok</Button>
        </DialogActions>
      </Dialog>
      : <Snackbar
        open={message != null}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        key={MESSAGE_ID}
        ContentProps={{ 'aria-describedby': MESSAGE_ID }}
      >
        <SnackbarContent
          className={classes.content}
          message={<div className={classes.container}>
            <Error className={classes.message} />
            <Typography className={classes.message}>{message}</Typography>
          </div>}
        />
      </Snackbar>; 
  }
};

ErrorSnackbar.defaultProps = { anchorOrigin: { vertical: 'bottom', horizontal: 'left' }};

ErrorSnackbar.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.shape({ 
    vertical: PropTypes.string.isRequired,
    horizontal: PropTypes.string.isRequired
  })
};

export default withMobileDialog()(withStyles(styles)(ErrorSnackbar));
