import { useCallback } from 'react';
import ReactPixel from 'react-facebook-pixel';

const PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_BABE_RUTH_KEY;

function useFacebookPixel() {
  const identify = useCallback((user) => {
    const {
      userId, 
      emailAddress, 
      firstName, 
      lastName, 
      gender,
      dateOfBirth
    } = user;
    
    let ge = null;
    if (gender === 'Male') ge = 'm';
    if (gender === 'Female') ge = 'f';

    let db = null;
    if (dateOfBirth != null && typeof dateOfBirth === 'string') {
      const dbParts = dateOfBirth.split('T');
      if (dbParts.length > 0) {
        db = dbParts[0].replaceAll('-', '');
      }
    }
    
    const userProps = {
      em: emailAddress,
      'external_id': userId,
      fn: firstName,
      ln: lastName,
      ge,
      db
    };
    ReactPixel.init(PIXEL_ID, userProps);
  }, []);

  const track = useCallback((eventName, properties) => {
    ReactPixel.trackSingleCustom(PIXEL_ID, eventName, properties);
  }, []);

  return { identify, track };
}

export default useFacebookPixel;