import { makeStyles } from '@material-ui/styles';
import palette from '../../../../styles/palette';

const useStyles = makeStyles(_ => ({
  container: {
    marginBottom: '30px'
  },
  header: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px'
  },
  text: {
    flexGrow: 1,
    paddingRight: '15px',
    whiteSpace: 'pre-wrap'
  },
  referencesContainer: {},
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  characters: {
    color: palette.darkGray,
    fontSize: '14px',
    marginLeft: '15px'
  },
  labels: {
    padding: '15px 15px 10px 15px'
  },
  labelContainer: {
    display: 'inline-block'
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.lightGray
    },
    position: 'relative',
    paddingLeft: '9px'
  },
  labelName: {
    whiteSpace: 'pre'
  },
  dot: ({ color }) => ({
    position: 'absolute',
    left: 0,
    borderRadius: '100%',
    width: '6px',
    height: '6px',
    backgroundColor: color
  })
}));

export default useStyles;
