import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core'; 

import styles from './styles';

export default class SoftwareLicense extends React.Component {
  render() {
    const { 
      dependencyName,
      copyright,
      url, 
      licenseText 
    } = this.props;
    return (
      <div>
        <Typography variant='h7'>{dependencyName}</Typography>
        <Typography variant='h7'>{copyright}</Typography>
        <Typography variant='h7'>{url}</Typography>
        <pre style={styles.licenseText}>{licenseText}</pre>
      </div>
    );
  }
}

SoftwareLicense.propTypes = {
  dependencyName: PropTypes.string.isRequired,
  copyright: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  licenseText: PropTypes.string.isRequired
};