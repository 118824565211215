const VIDEO_STATUSES = {
  available: 'Available',
  uploading: 'Uploading',
  processing: 'Processing',
  uploadingError: 'UploadingError',
  processingError: 'ProcessingError',
  stuckInProcessing: 'StuckInProcessing',
  transferring: 'Transferring',
  addingLogoToVideo: 'AddingLogoToVideo'
};

export default VIDEO_STATUSES;

export const VIDEO_TYPE = {
  drill: 'Drill',
  motion: 'Motion',
  analysis: 'Analysis',
  analysisSource: 'AnalysisSource', 
  content: 'Content'
};

export const CAMERA_ANGLE = {
  faceOn: 'FaceOn',
  catchersView: 'CatchersView',
  pitchersView: 'PitchersView'
};

export const HOSTING_PROVIDER = {
  vimeo: 'Vimeo',
  azureBlobStorage: 'AzureBlobStorage'
};
