const measurementKeys = [
  {
    key: 'weightKilograms',
    title: 'Weight',
    displayUnits: 'lb',
    dataUnits: 'kg'
  },
  {
    key: 'heightCentimeters',
    title: 'Height',
    displayUnits: 'in',
    dataUnits: 'cm',
    showInFeet: true
  },
  {
    key: 'wingspanCentimeters',
    title: 'Wingspan',
    displayUnits: 'in',
    dataUnits: 'cm',
    showInFeet: true
  },
  {
    key: 'gripStrengthKilograms',
    title: 'Grip Strength',
    displayUnits: 'lb',
    dataUnits: 'kg'
  },
  {
    key: 'waistSizeCentimeters',
    title: 'Waist',
    displayUnits: 'in',
    dataUnits: 'cm'
  },
  {
    key: 'shoulderSizeCentimeters',
    title: 'Shoulder',
    displayUnits: 'in',
    dataUnits: 'cm'
  }
];

export default measurementKeys;
