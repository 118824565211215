import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { 
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import Logger from 'js-logger';
import { GetAllVideoAnalyses } from '../../network/videoAnalysisRequests';
import { getOrganizationId } from '../../utils/auth.utils';
import RequestPlayerSelection from './requestPlayerSelection';
import { history } from '../../store';
import { AnnotationTable, ActionPlanTable } from './tables';
import { 
  Icons, 
  getSchedulingStatusForAnalysis, 
  SCHEDULING_STATUS 
} from './analysisUtils';
import useStyles from './styles';
import { ANALYSIS_STATUS } from '../../constants/videoAnalysis.constants';

const TURN_AROUND_TIME_DAYS = 3;

function VideoAnalysisStatus() {
  const [ analyses, setAnalyses ] = useState([]);
  const [ showPlayerSelection, setShowPlayerSelection ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const players = useSelector(state => state.players);
  const playersById = keyBy(players, player => player.userId);
  const classes = useStyles();
  
  useEffect(() => {
    const token = axios.CancelToken.source();
    async function fetchAnalyses() {
      const orgId = getOrganizationId();
      try {
        const analyses = await GetAllVideoAnalyses(null, orgId, false, token);
        setAnalyses(analyses);
      } catch(e) {
        if (!axios.isCancel(e)) {
          Logger.error(e);
          setIsError(true);
        }
      }
    }

    fetchAnalyses();
    return token.cancel;
  }, []);

  function finishSelection(newAnalyses) {
    setShowPlayerSelection(false);
    setAnalyses(prev => [...prev, ...newAnalyses]);
  }

  const ColorLegend = ({ icon, text }) => ( 
    <Grid item xs={4} container alignItems='center' justify='center'>
      {icon}
      <Typography className={classes.legendText}>{text}</Typography>
    </Grid>
  );

  function getDueDate(analysis) {
    let startDate = analysis.submissionTimestamp;
    if (!startDate) startDate = analysis.editedTimestamp;
    let dueDate = new Date(startDate);
    dueDate.setDate(dueDate.getDate() + TURN_AROUND_TIME_DAYS);
    return dueDate;
  }

  function getPlayerDisplayName(player) {
    return player && `${player.firstName} ${player.lastName}`;
  };

  let analysesWithUserDueDates = analyses
    .filter(analysis => analysis.status !== ANALYSIS_STATUS.requested)
    .map(analysis => ({
      ...analysis,
      dueDate: getDueDate(analysis),
      playerName: getPlayerDisplayName(playersById[analysis.userId])
    }));

  analysesWithUserDueDates = orderBy(analysesWithUserDueDates, 'dueDate', 'asc');

  function analysisStatusCount(schedulingStatus) {
    return analysesWithUserDueDates
      .filter(analysis => getSchedulingStatusForAnalysis(analysis) === schedulingStatus)
      .length;          
  }

  function navigateToAnalysis(userId, analysisId) {
    history.push(`/player-development/player/${userId}/video-analysis?analysisId=${analysisId}`);
  }
    
  return (
    <div className={classes.container}>
      <Paper className={classes.headerContainer}>
        <Grid container justify='space-between'>
          <Grid item xs={3}>
            <Typography variant='h5'>Video Analyses</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems='center' className={classes.legend}>
              <ColorLegend 
                icon={Icons.lastDayIcon} 
                text={`Due today: ${analysisStatusCount(SCHEDULING_STATUS.lastDay)}`} 
              />
              <ColorLegend 
                icon={Icons.pastDueIcon} 
                text={`Past due: ${analysisStatusCount(SCHEDULING_STATUS.pastDue)}`}
              />
              <ColorLegend 
                icon={Icons.contactDevIcon} 
                text={`Contact dev: ${analysisStatusCount(SCHEDULING_STATUS.contactDev)}`} 
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => setShowPlayerSelection(true)}
              className={classes.requestButton}
            >
              Request
              <SendIcon className={classes.requestIcon}/>
            </Button>
          </Grid>
        </Grid>
        {isError && 
          <Typography color='error'>
            There was an error getting analyses. Please refresh to try again.
          </Typography>
        }
      </Paper>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={8}>
          <AnnotationTable 
            analyses={analysesWithUserDueDates} 
            navigateToAnalysis={navigateToAnalysis}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ActionPlanTable 
            analyses={analysesWithUserDueDates} 
            navigateToAnalysis={navigateToAnalysis}
          />
        </Grid>
        <Dialog fullWidth maxWidth='lg' 
          onClose={() => setShowPlayerSelection(false)}
          open={showPlayerSelection}>
          <DialogContent>
            <RequestPlayerSelection 
              players={players} 
              activeAnalyses={analyses} 
              onClose={finishSelection}
            />
          </DialogContent> 
        </Dialog>
      </Grid>
    </div>
  );
}

export default VideoAnalysisStatus;