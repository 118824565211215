import { Box } from '@material-ui/core';
import { PERCENTILES_LABELS, PERCENTILE_THRESHOLDS } from './percentiles.constants';
import useStyles from './styles';

function PercentilesTable({ title, captureResult }) {
  const classes = useStyles();

  const percentiles = captureResult.playerPercentiles || {};

  const getClassName = value => {
    if (value < PERCENTILE_THRESHOLDS.LOWER) return classes.fail;
    if (value < PERCENTILE_THRESHOLDS.UPPER) return classes.needsWork;
    return classes.pass;
  };

  const tableRows = [
    {
      label: PERCENTILES_LABELS.avgExitVelo, 
      value: captureResult.averageBallspeedMph
    }, 
    { 
      label: PERCENTILES_LABELS.peakExitVelo, 
      value: captureResult.ballspeedMph
    },
    { 
      label: PERCENTILES_LABELS.exitVeloPercentile, 
      value: percentiles.ballSpeed, 
      className: getClassName(percentiles.ballSpeed) 
    },
    { 
      label: PERCENTILES_LABELS.pelvisSpeedPercentile, 
      value: percentiles.pelvisSpeed, 
      className: getClassName(percentiles.pelvisSpeed)
    },
    { 
      label: PERCENTILES_LABELS.torsoSpeedPercentile, 
      value: percentiles.torsoSpeed, 
      className: getClassName(percentiles.torsoSpeed)
    },
    { 
      label: PERCENTILES_LABELS.armSpeedPercentile, 
      value: percentiles.upperArmSpeed, 
      className: getClassName(percentiles.upperArmSpeed)
    },
    { 
      label: PERCENTILES_LABELS.handSpeedPercentile, 
      value: percentiles.handSpeed, 
      className: getClassName(percentiles.handSpeed)
    },
    { 
      label: PERCENTILES_LABELS.bodySpeedPercentile, 
      value: percentiles.bodySpeed, 
      className: getClassName(percentiles.bodySpeed)
    }
  ];

  return (
    <Box className={classes.tablesContainer}>
      <Box p={1} key={captureResult.id}>
        <table className={classes.table}>
          <tbody className={classes.tableBody}>
            <tr className={classes.primaryHeaderRow}>
              <th colSpan={3} align='left'>Exit Velo/Percentile Data</th>
            </tr>
            <tr className={classes.secondaryHeaderRow}>
              <th align='left'>Data:</th>
              <th />
              <th>KPI Level</th>
            </tr>
            {tableRows.map(({ label, value, className }) => <tr 
              key={`${captureResult.id}-${label}`}
            >
              <td>{label}</td>
              <td align='center' className={classes.value}>
                {value != null ? Math.round(value) : ''}
              </td>
              <td className={className} />
            </tr>)}
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

export default PercentilesTable;