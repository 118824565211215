import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  textEditorContainer: {
    border: '1px solid darkgray'
  },
  videoButtonContainer: {
    paddingTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  titleTextField: {
    width: '500px'
  },
  contentTypeSelect: {
    width: '200px',
    margin: '10px'
  }
}));

export default useStyles;
