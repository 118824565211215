import addMilliseconds from 'date-fns/addMilliseconds';
import { PERMISSIONS } from '../../../constants/permissions.constants';
import UnitConverter from '../../../utils/unitConverter.utils';
import { 
  GetFullMotionMetricsAverages, 
  GetLaunchMonitorAverages, 
  GetMotionsForUserInCurrentOrg, 
  GetForcePlateAverages, 
  GetHittingDeviceSensorAverages 
} from '../../../network/motionRequests';
import { 
  FULL_MOTION_METRICS, 
  LAUNCH_MONITOR, 
  FORCE_PLATE, 
  HITTING_DEVICE_SENSOR 
} from '../../../constants/motionSubresources.constants';

const getMotionDataByDate = (subresource) => 
  async (userId, laterThan, earlierThan, cancelToken) => {
    const inclusiveLaterThan = addMilliseconds(new Date(laterThan), -1).toISOString();
    const params = { 
      laterThan: inclusiveLaterThan, 
      earlierThan, 
      subresources: [subresource]
    };
    
    const motions = await GetMotionsForUserInCurrentOrg(userId, params, cancelToken);
    if (motions.items == null) return [];
    return motions.items.map(motion => motion[subresource]).filter(x => x != null);
  };

class GraphKey {
  constructor(key, title, yAxisMin, yAxisMax, converterFunction) { 
    this.key = key;
    this.title = title;
    this.converterFunction = converterFunction;

    let yAxisDomain = ['auto', 'auto'];
    if (yAxisMin != null) {
      yAxisDomain[0] = dataMin => Math.min(yAxisMin, dataMin);
    }
    if (yAxisMax != null) {
      yAxisDomain[1] = dataMax => Math.max(yAxisMax, dataMax);
    }
    this.yAxisDomain = yAxisDomain;
  }
}

const GraphOptions = [
  {
    title: 'K-Motion',
    keys: [
      new GraphKey('firstMoveToContactTime', 'FM to CON Time (seconds)', 0, 0.5),
      new GraphKey('heelStrikeToContactTime', 'HS to CON Time (seconds)', 0, 0.5),
      new GraphKey('heelStrikeToFirstMoveTime', 'HS to FM Time (seconds)', 0, 0.5),
      new GraphKey('maxXFactor', 'Max X-Factor (degrees)'),
      new GraphKey('peakSpeedPelvis', 'Peak Speed Pelvis (degrees/second)', 0, 2500),
      new GraphKey('peakSpeedTorso', 'Peak Speed Torso (degrees/second)', 0, 2500),      
      new GraphKey('peakSpeedUpperArm', 'Peak Speed Arm (degrees/second)', 0, 2500),
      new GraphKey('peakSpeedHand', 'Peak Speed Hand (degrees/second)', 0, 2500),
      new GraphKey('speedGainTorsoToPelvis', 'Pelvis to Torso Speed Gain', 0, 3),
      new GraphKey('speedGainArmToTorso', 'Torso to Arm Speed Gain', 0, 3),
      new GraphKey('speedGainHandToArm', 'Arm to Hand Speed Gain', 0, 3)
    ],
    averagesFunction: GetFullMotionMetricsAverages,
    dataByDateFunction: getMotionDataByDate(FULL_MOTION_METRICS)
  },
  {
    title: 'Launch Monitor',
    permission: PERMISSIONS.canHandleLaunchMonitorData,
    keys: [
      new GraphKey('ballspeedMph', 'Ball Exit Velocity', 0, 120)
    ],
    averagesFunction: GetLaunchMonitorAverages,
    dataByDateFunction: getMotionDataByDate(LAUNCH_MONITOR)
  },
  {
    title: 'Force Plate',
    permission: PERMISSIONS.canInputThirdPartyData,
    keys: [
      new GraphKey('zBackPeakValue', 'Z-Back Peak Value'),
      new GraphKey('zFrontPeakValue', 'Z-Front Peak Value'),
      new GraphKey('xBackPeakValue', 'X-Back Peak Value'),
      new GraphKey('xFrontPeakValue', 'X-Front Peak Value'),
      new GraphKey('torqueBackPeakValue', 'Torque Back Peak Value'),
      new GraphKey('torqueFrontPeakValue', 'Torque Front Peak Value'),
      new GraphKey('xTiming', 'X Timing')
    ],
    averagesFunction: GetForcePlateAverages,
    dataByDateFunction: getMotionDataByDate(FORCE_PLATE)
  },
  {
    title: 'Bat Sensor',
    permission: PERMISSIONS.canInputThirdPartyData,
    keys: [
      new GraphKey('deviceSpeedMetersPerSecond', 'Bat Speed', null, null, x => UnitConverter.metersPerSecondToMph(x)),
      new GraphKey('timeToImpactSeconds', 'Time to Impact', 0, 0.3),
      new GraphKey('attackAngleDegrees', 'Attack Angle'),
      new GraphKey('rotationalAccelerationGs', 'Rotational Acceleration G\'s'),
      new GraphKey('verticalBatAngleDegrees', 'Vertical Bat Angle')
    ],
    averagesFunction: GetHittingDeviceSensorAverages,
    dataByDateFunction: getMotionDataByDate(HITTING_DEVICE_SENSOR)
  }
];

export default GraphOptions;