import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  withMobileDialog
} from '@material-ui/core';

import PopupHeader from './popupHeader';

import { withStyles } from '@material-ui/core/styles';
import Styles from './styles';

class PopupForm extends React.Component {
  constructor(props) {
    super(props);
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
  }

  render() {
    const { classes, open, title, children, fullScreen, showPGAndKMotionHeader } = this.props;
    return (
      <Dialog open={open} aria-labelledby='scroll-dialog-title'
        fullScreen={fullScreen}
        scroll='paper'
        classes={{
          paper: classes.cssBorderRadius, 
          root: classes.cssDarkBackground
        }}>
        <PopupHeader showPGAndKMotionHeader={showPGAndKMotionHeader} title={title}/>
        {children}
      </Dialog>
    );
  }
}

PopupForm.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  showPGAndKMotionHeader: PropTypes.bool
};

export default withMobileDialog()(withStyles(Styles)(PopupForm));
