export const SyncStatus = {
  PROCESSING: 'Processing',
  COMPLETE: 'Complete',
  SYNC_APPLIED: 'SyncApplied',
  REJECTED: 'Rejected',
  FAILED: 'Failed'
};

export const ACTION_STATUSES = {
  notStarted: 'NotStarted',
  processing: 'Processing',
  failed: 'Failed',
  completed: 'Completed',
  needsReview: 'NeedsReview',
  notApplicable: 'NotApplicable',
  warning: 'Warning'
};

export const PURCHASE_STATUSES = {
  notPurchased: 'NotPurchased',
  purchased: 'Purchased',
  created: 'Created'
};

export const TOOLTIPS = {
  resultsCreatedStatus: {
    NeedsReview: 'Motions need to be reviewed'
  },
  videosReviewedStatus: {
    Failed: 'This user either needs a video manually uploaded ' + 
      'or the current video needs to be replaced.'
  },
  motionsAssignedStatus: {
    NeedsReview: 'This user has motions that potentially belong to someone else.'
  },
  minimumDataRequirementStatus: {
    Failed: 'This user does not have at least 3 motions that ' + 
      'have exit velocity, motion data, and a video synced.'
  }
};

export const EVENT_ASSIGNMENT_STATUSES = {
  onRoster: 'OnRoster',
  success: 'Success',
  needsExternalId: 'NeedsExternalId',
  multipleUsersConflict: 'MultipleUsersConflict',
  multipleEventsConflict: 'MultipleEventsConflict'
};

export const CaptureEventTypes = {
  internalTest: 'InternalTest',
  perfectGameEvent: 'PerfectGameEvent',
  travelOrg: 'TravelOrg',
  recapture: 'Recapture',
  perfectGameTournament: 'PerfectGameTournament',
  pGTechPopUp: 'PGTechPopUp'
};

export const CaptureEventLatestRefreshStatus = {
  SUCCESS: 'Success',
  NONPGTECHEVENT: 'NonPGTechEvent',
  UNKNOWNERROR: 'UnknownError'
};

export const TEST_CLIENT_USER_ID = '5ee81f3d670ede00199ecb59';