import palette from '../../styles/palette';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(_ => ({
  paper: {
    backgroundColor: palette.deepBlue,
    padding: '10px 15px',
    borderRadius: '0 0 5px 5px',
    cursor: 'pointer',
    '&:hover > svg': {
      transform: 'rotate(45deg)',
      transition: 'transform 0.33s'
    }
  },
  menu: {
    backgroundColor: palette.deepBlue,
    color: palette.white,
    top: '0px !important' // anchor calculations add a top which messes up the positioning
  },
  menuItem: {
    '&:hover': {
      backgroundColor: palette.lightenedDeepBlue
    }
  }
}));

export default useStyles;
