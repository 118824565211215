import palette from '../../styles/palette';

const styles = {
  titleContainer: {
    background: 'white',
    fontSize: '26px',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    textAlign: 'center',
    width: '100%',
    height: '75%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  valueContainer: {
    background: '#525467',
    fontSize: '28px',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',
    textAlign: 'center',
    width: '100%',
    height: '25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center'
  },
  valueText: {
    color: palette.white,
    fontSize: '24px'
  },
  circularProgress: {
    color: palette.white
  }
};

export default styles;
