import { useState, useEffect } from 'react';
import {
  FormGroup,
  Grid,
  Typography 
} from '@material-ui/core';
import BodySegmentSelection from './bodySegmentSelection';
import PlayerBodySegmentGraphic from 'components/PlayerBodySegmentGraphics/HittingGraphic';
import CreateTrainingPlanComponent from './createTrainingPlanComponent';
import { bodyPartsFocusedForResults } from 'utils/trainingPlan.utils';

function BodyPercentiles({ 
  title, 
  captureResult, 
  eventUser,
  channelsNetworkRequest,
  onResultUpdate = () => {}
}) {
  const [bodyPartsFocused, setBodyPartsFocused] = useState([]);

  useEffect(() => {
    captureResult && setBodyPartsFocused(bodyPartsFocusedForResults(captureResult));
  }, [captureResult]);

  const checkBodyPart = bodyPart => {
    setBodyPartsFocused(prev => {
      if (prev.includes(bodyPart)) {
        return prev.filter(part => part !== bodyPart);
      } else {
        return [...prev, bodyPart];
      }
    });
  };

  const { playerPercentiles } = captureResult; 
  return (
    <div>
      <Typography variant='h6' align='center'>{title}</Typography>
      <FormGroup>
        <Grid container justify='center'>
          <BodySegmentSelection 
            onBodyPartChecked={part => checkBodyPart(part)}
            bodyPartsFocused={bodyPartsFocused}
          />
        </Grid>
      </FormGroup>
      
      <PlayerBodySegmentGraphic 
        pelvisValue={playerPercentiles?.pelvisSpeed}
        torsoValue={playerPercentiles?.torsoSpeed}
        upperArmValue={playerPercentiles?.upperArmSpeed}
        handValue={playerPercentiles?.handSpeed}
        highlightedBodyParts={bodyPartsFocused}
      />

      <CreateTrainingPlanComponent
        channelsNetworkRequest={channelsNetworkRequest}
        eventUser={eventUser} 
        captureResult={captureResult}
        selectedBodySegments={bodyPartsFocused}
        onResultUpdate={onResultUpdate}
      />
    </div>
  );
};

export default BodyPercentiles;