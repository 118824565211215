import { useState, useEffect, useCallback } from 'react';
import orderBy from 'lodash/orderBy';
import minBy from 'lodash/minBy';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import useNetworkRequest from 'network/useNetworkRequest';
import { GetTrainingPlans } from 'network/trainingPlanRequests';
import { PatchNote } from 'network/notesRequests';
import { PLAN_TYPES } from 'constants/trainingPlans.constants';
import { NOTE_STATUSES } from 'constants/noteStatuses.constants';
import Logger from 'js-logger';

const UPCOMING_DAYS_THRESHOLD = 2;

export const DUE_STATUS = {
  PAST_DUE: 'pastDue',
  DUE_TODAY: 'dueToday',
  UPCOMING: 'upcoming',
  FUTURE: 'future'
};

function useUpcomingPlanNotes(trainingPlanType = PLAN_TYPES.googleDriveTrainingPlan) {
  const [upcomingNotes, setUpcomingNotes] = useState([]);
  const [updatingNoteIds, setUpdatingNoteIds] = useState(new Set());
  const [erroredNoteIds, setErroredNoteIds] = useState(new Set());

  const trainingPlansRequest = useCallback(async cancelToken => GetTrainingPlans({ 
    trainingPlanType,
    subresources: [
      'notes',
      'InitialCaptureEventResult.CaptureEventUser.CaptureEvent',
      'Template'
    ]
  }, cancelToken), [trainingPlanType]);
  const [
    trainingPlans,
    loading,
    error,
    setTrainingPlans
  ] = useNetworkRequest([], 'GetTrainingPlans', trainingPlansRequest);

  useEffect(() => {
    const now = new Date();
    const calculateDueStatus = note => {
      const daysRemaining = differenceInCalendarDays(new Date(note.dateToSend), now);
      if (daysRemaining < 0) {
        return DUE_STATUS.PAST_DUE;
      }
      if (daysRemaining === 0) {
        return DUE_STATUS.DUE_TODAY;
      }
      if (daysRemaining <= UPCOMING_DAYS_THRESHOLD) {
        return DUE_STATUS.UPCOMING;
      }
      return DUE_STATUS.FUTURE;
    };

    const unorderedNotes = trainingPlans
      .map(trainingPlan => {
        const unsentNotes = trainingPlan.notes
          .filter(x => x.status !== NOTE_STATUSES.sent)
          .map(x => ({ ...x, dueStatus: calculateDueStatus(x), trainingPlan }));
        return minBy(unsentNotes, note => new Date(note.dateToSend));
      }).filter(note => note != null);
    const orderedNotes = orderBy(unorderedNotes, note => new Date(note.dateToSend));
    setUpcomingNotes(orderedNotes);
  }, [trainingPlans]);

  const updateNoteStatus = useCallback(async (note, status) => {
    let result = false;

    try {
      setUpdatingNoteIds(prev => new Set(prev).add(note.id));
      setErroredNoteIds(prev => {
        const newNoteIds = new Set(prev);
        newNoteIds.delete(note.id);
        return newNoteIds;
      });

      const patchDoc = [
        {
          op: 'test',
          path: '/status',
          value: note.status
        },
        {
          op: 'replace',
          path: '/status',
          value: status 
        }
      ];
      const updatedNote = await PatchNote(note.id, patchDoc);
      const trainingPlanId = updatedNote.trainingPlanId;
      setTrainingPlans(prev => prev.map(plan => plan.id !== trainingPlanId
        ? plan
        : {
          ...plan,
          notes: plan.notes.map(n => n.id !== updatedNote.id ? n : updatedNote)
        }));
      result = true;
    }
    catch (e) {
      Logger.error(e, `Error updating note ${note.id} status to ${status}.`);
      setErroredNoteIds(prev => new Set(prev).add(note.id));
    }

    setUpdatingNoteIds(prev => {
      const newNoteIds = new Set(prev);
      newNoteIds.delete(note.id);
      return newNoteIds;
    });
    return result;
  }, [setTrainingPlans]);

  const clearErroredNoteIds = useCallback(() => {
    setErroredNoteIds(new Set());
  }, []);

  return {
    upcomingNotes,
    updatingNoteIds,
    erroredNoteIds,
    updateNoteStatus,
    loading,
    error,
    clearErroredNoteIds
  };
}

export default useUpcomingPlanNotes;
