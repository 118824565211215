import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { 
  List, 
  FormControl, 
  InputLabel, 
  Select,
  Divider, 
  MenuItem,
  Grid,
  Typography,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import keyBy from 'lodash/keyBy';
import SwingItem from '../swingItem';
import useStyles from './styles';

function SwingFeed({ 
  motions,             // all motions for the serial code
  serialCode,          // the serial code selected for this feed
  allSerialCodes,      // all potential serial codes
  onSerialCodeChanged, // callback when serial code is changed
  onClose              // callback when close button is called
}) {
  const classes = useStyles();
  const players = useSelector(state => state.players);

  const playersById = keyBy(players, player => player.userId);

  function updateSerialCode(e) {
    onSerialCodeChanged(e.target.value);
  }

  function renderFeedItems() {
    // returns an array of motion elements 
    // with a player identifier in between
    let items = [];
    if (motions.length > 0) {
      let lastMotion;
      motions.forEach((motion, idx) => {
        if (!lastMotion || motion.userId !== lastMotion.userId) {
          items.push(<Divider key={`${motion.id}-div`} className={classes.playerDivider}/>);
          items.push(renderPlayer(motion.userId));
        }
        const nextMotion = idx < motions.length - 2 ? motions[idx + 1] : null;
        items.push(<SwingItem 
          key={motion.id} 
          motion={motion} 
          previousMotion={nextMotion} 
          className={classes.swingItem}
        />);
        lastMotion = motion;
      });
    }
    return items;
  }

  function renderPlayer(id) {
    const player = playersById[id];
    let name = player ? `${player.firstName} ${player.lastName}` : '';
    return <Typography key={id} variant='h4'>{name}</Typography>;
  }

  return (
    <Grid container direction='column' alignItems='center' className={classes.container}>
      <Grid item className={classes.topFeed}>
        <IconButton aria-label='close' className={classes.closeIcon} onClick={onClose}>
          <CloseIcon className={classes.closeIcon}/>
        </IconButton>
        <FormControl className={classes.formControl}>
          <InputLabel id='select-serial-label'>SerialCode</InputLabel>
          <Select
            id='select-serial'
            value={serialCode}
            onChange={updateSerialCode}
            label='SerialCode'
          >
            {allSerialCodes.map(code => (
              <MenuItem key={code} value={code}>{code}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <List>
          <>
            {renderFeedItems()}
          </>
        </List>
      </Grid>
    </Grid>
  );
}

SwingFeed.propTypes = {
  motions: PropTypes.arrayOf(PropTypes.object),
  serialCode: PropTypes.string,
  alLSerialCodes: PropTypes.arrayOf(PropTypes.string),
  onSerialCodeChanged: PropTypes.func, 
  onClose: PropTypes.func
};

export default SwingFeed;