import React from 'react';
import { 
  Typography,
  withStyles,
  Select,
  MenuItem
} from '@material-ui/core';
import classnames from 'classnames';
import find from 'lodash/find';
import { formatDate } from '../../../../utils/formatting.utils';


import styles from './styles';

function AnalysesSelection({ analyses, selectedAnalysisId, onClick, className, classes }) {

  const handleChange = e => {
    var selectedAnalysis = find(analyses, analysis => analysis.id === e.target.value);
    onClick(selectedAnalysis);
  };

  const selectedId = analyses && analyses.some(analysis => analysis.id === selectedAnalysisId)
    ? selectedAnalysisId
    : '';

  return (
    <div className={classnames([className, classes.container])}>
      <Typography variant='body1' className={classes.text}>Videos from: </Typography>
      <Select
        value={selectedId}
        onChange={handleChange}
        disableUnderline
        className={classes.select}
        inputProps={{ className: classes.text }}
      >
        {analyses && analyses.map(analysis => (
          <MenuItem key={analysis.id} value={analysis.id} className={classes.text}>
            {formatDate(analysis.creationTimestamp)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default withStyles(styles)(AnalysesSelection);