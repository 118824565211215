import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Styles from './styles';
import { Typography } from '@material-ui/core';

class IconTextField extends React.Component {
  state = { isFocused: false }

  render() {
    const { icon, error, errorMsg, classes, selectData, ...rest } = this.props;
    const containerClass = classNames(
      classes.outerContainer, 
      { 
        [classes.focused]: this.state.isFocused,
        [classes.error]: error 
      });

    return (
      <div>
        <div className={containerClass}>
          <div className={classes.iconContainer}>
            {icon}
          </div>
          {selectData 
            ? <select className={classNames({ [classes.input]: true, [classes.defaultSelect]: this.props.value === '' })}
              onFocus={() => this.setState({ isFocused: true })} 
              onBlur={() => this.setState({ isFocused: false })}
              {...rest}
            >
              {selectData.map(({ label, value }) => 
                <option value={value} key={value}>{label}</option>)}
            </select>
            : <input
              className={classes.input}
              onFocus={() => this.setState({ isFocused: true })} 
              onBlur={() => this.setState({ isFocused: false })}
              {...rest} 
            />
          }
        </div>
        {errorMsg && <Typography color='error'>{errorMsg}</Typography>}
      </div>
    );
  }
}

IconTextField.propTypes = { icon: PropTypes.node };

export default withStyles(Styles)(IconTextField);