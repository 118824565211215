import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: '15px'
  },
  key: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default useStyles;